import React, { useCallback, useEffect, useRef, useState } from 'react'
import { TApiTag } from 'services/api-client/types'
import { Badge, Popover, Tag, Tooltip, TooltipProps, message } from 'antd'
import { TagOutlined } from '@ant-design/icons'
import { ITagsSelect, TagsSelect } from './tags-select'
import styled from 'styled-components'
import { defer, delay } from 'lodash'
import { tagsToOptions } from 'views/app-views/timer/Show/TagsField'
import ReactJson from 'react-json-view'

const TagsTag = styled(TagOutlined)`
  padding: 2px;

  &:focus:hover {
    outline: none;
  }

  &:focus:not(:hover) {
    outline: none;
    // box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 4px;
  }
`

const INPUT_CLASS_NAME = 'tags-select-popover-input'

type TagsSelectIconProps = ITagsSelect & {
  displayMode?: 'first-tag' | 'icon'
  placement?: TooltipProps['placement']
}

const TagsSelectIcon = (selectProps: TagsSelectIconProps) => {
  const [internalOpen, setInternalOpen] = useState(false)

  const displayMode = selectProps.displayMode || 'icon'

  const open = selectProps.open || internalOpen
  const setOpen = selectProps.setOpen || setInternalOpen

  const SelectInput = TagsSelect
  const [searchValue, setSearchValue] = useState("")

  const selectedTags = (selectProps.value as any || []) as TApiTag[]

  const [canChangeOpen, setCanChangeOpen] = useState(true)

  const handleOpen = (newOpen: boolean) => {
    if (canChangeOpen) {
      setOpen(newOpen)
      setCanChangeOpen(false)
      setTimeout(() => {
        setCanChangeOpen(true)
      }, 300)
    }
  }

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.key == " " || e.key == "Enter")) {
      e.preventDefault()
      e.stopPropagation()

      handleOpen(!open)
    }
  }

  const popoverContent = selectProps.disabled ? (
    <>
      {(selectProps.value as any as TApiTag[]).map((tag) => (
        <Tag key={tag.id} color={tag.color} title={tag.name} style={{ marginTop: '10px' }}>
          {tag.name}
        </Tag>
      ))}

      { (selectProps.value as any as TApiTag[]).length == 0 && (
        <span><i>No tags selected</i></span>
      )}
    </>
  ) : (
    <TagsSelect
      className={INPUT_CLASS_NAME}
      onKeyDown={(e) => {
        if (e.key == 'Escape' || e.key == 'Tab') {
          handleOpen(false)
        }
      }}
      dropdownMatchSelectWidth={270}
      onSearch={setSearchValue}
      searchValue={searchValue}
      autoFocus={true}
      onCreate={() => {
        setOpen(false)
      }}

      {...selectProps}

      open={true}
    />
  )  

  const iconView = (displayMode == 'icon' || selectedTags.length == 0) ? (
    <Badge
      count={selectedTags.length}
      offset={[3, -3]}
      size="default"
    >
      <TagsTag
        tabIndex={0}
        onKeyDown={handleOnKeyDown}
        onFocus={(e) => {
          handleOpen(true)
        }}
        style={{
          fontSize: 28,
          cursor: 'pointer',
          color: selectedTags.length == 0 ? '#ccc' : undefined
        }} />
    </Badge>
  ) : (
    <Badge
      count={selectedTags.length}
      offset={[-3, 0]}
      size="default"
    >
      <Tag
        tabIndex={0}
        onKeyDown={handleOnKeyDown}
        onFocus={(e) => {
          handleOpen(true)
        }}
        color={selectProps.value[0].color}
        style={{
          cursor: 'pointer',
        }}
      >
        {selectProps.value[0].name}
      </Tag>
    </Badge>
  )

  return (
    <>
      <Popover
        placement={selectProps.placement || 'bottom'}
        visible={open}
        trigger={'click'}
        onVisibleChange={handleOpen}
        destroyTooltipOnHide={true}
        overlayInnerStyle={{
          width: '300px'
        }}
        content={popoverContent}
        getPopupContainer={(node) => {
          const modal = document.querySelector('.ant-modal-root')
          if (modal) return modal
          return node
        }}
      >
        {iconView}
     </Popover>
    </>
  );
};
export default TagsSelectIcon
