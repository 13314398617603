import Bus from 'assets/svg/holidays/bus'
import Child from "assets/svg/holidays/child"
import Clock from "assets/svg/holidays/clock"
import Calendar from "assets/svg/holidays/calendar"
import Remote from "assets/svg/holidays/remote"
import Cold from "assets/svg/holidays/cold"
import Holiday from "assets/svg/holidays/holiday"
import Hotel from "assets/svg/holidays/hotel"
import Lunch from "assets/svg/holidays/lunch"
import Plane from "assets/svg/holidays/plane"
import SickLeave from "assets/svg/holidays/sick_leave"
import Smile from "assets/svg/holidays/smile"
import Star from "assets/svg/holidays/star"
import Stop from "assets/svg/holidays/stop"
import Sunny from "assets/svg/holidays/sunny"
import Trip from "assets/svg/holidays/trip"

const AbsenceIcon = (props: React.ComponentProps<"svg"> & { icon: string }) => {
  const IconsMap = {
    bus: Bus,
    calendar: Calendar,
    child: Child,
    clock: Clock,
    cold: Cold,
    holiday: Holiday,
    hotel: Hotel,
    lunch: Lunch,
    plane: Plane,
    remote: Remote,
    sick_leave: SickLeave,
    smile: Smile,
    star: Star,
    stop: Stop,
    sunny: Sunny,
    trip: Trip,
    default: Calendar,
  }

  // @ts-ignore
  let Icon = IconsMap[props.icon] || Sunny

  return (<span className="absence_type_icon" style={{ display: 'flex', height: '17px' }}><Icon {...props} /></span>)
}

export default AbsenceIcon