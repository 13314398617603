import { Card } from "antd"

export default function SummaryCard(props: { 
  title: string,
  value?: string 
  children?: React.ReactNode
}) {
  const { 
    title, 
    value
  } = props

  return (
    <Card
      size="small"
      style={{
        marginTop: '0px',
        margin: '0px',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <div>
          <b>{title}</b>
        </div>
        <div>
          {value}
          {props.children}
        </div>
      </div>
    </Card>
  )
}