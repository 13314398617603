import { useEffect, useState } from "react"
import useApiClient from "./useApiClient"
import { ApiErrors } from "@timenotes/shared/src/services/api-client/types"
import { TApiAlert } from "@timenotes/shared/src/services/api-client/alerts-api"


interface IUseAlerts {
  fetch?: boolean
}

export const useAlerts = (props?: IUseAlerts) => {
  const apiClient = useApiClient()
  const [alerts, setAlerts] = useState<TApiAlert[]>([])
  const [errors, setErrors] = useState<ApiErrors>({})
  const [loading, setLoading] = useState(true)
  const fetchOnUse = props?.fetch === undefined ? true : !!props.fetch

  const fetchAlerts = () => {
    const apiCall = apiClient.getAlerts({ perPage: 1000 }).then((response) => {
      if (response.ok) {
        setAlerts(response.alerts)
      } else {
        setErrors(response.errors || {})
      }

      setLoading(false)
    })
  }

  const deleteAlert = (id: string) => {
    return apiClient.deleteAlert(id)
  }

  useEffect(() => {
    if (fetchOnUse) {
      fetchAlerts()
    }
  }, [])

  return {
    alerts,
    errors,
    loading,
    fetchAlerts,
    deleteAlert,
  }
}