import _filter from 'lodash/filter'
import queryString from 'query-string'
import { useEffect, useState } from "react"
import _map from "lodash/map"

import PageHeader from "components/layout/page-header"
import LoadingSkeleton from "./components/loading-skeleton"
import { Card } from "components/base"

import ProjectsTable from "./components/projects-table"

import { SearchFilter } from "./components/project-filters/search-filter"
import { StateFilter } from "./components/project-filters/state-filter"
import { NewProjectSvg as NewProject } from "assets/svg/icon"
import { IntegrationSvg as Integration } from "assets/svg/icon"
import { useHistory } from "react-router-dom"
import useApiClient from 'hooks/useApiClient'
import { usePagePermissions } from 'hooks/permissions.hooks'
import ClientsFilter from 'components/timenotes/filters/clients-filter'
import TeamFilter from 'components/timenotes/filters/team-filter'
import BillableFilter from 'components/timenotes/filters/billable-filter'
import useNoInitialEffect from 'hooks/useNoInitialEffect'
import { IGetProjectsListParams, TApiProjectsSorting } from '@timenotes/shared/src/services/api-client/projects-api'
import { TApiProject } from '@timenotes/shared/src/services/api-client/types'
import useEnsureUserCanDisplayPage from 'services/pages/use-ensure-user-can-display-page'

interface IState {
  loaded: boolean
}

const PROJECTS_LIST_PER_PAGE = 30

const ProjectsListPage = () => {
  const [pageLoaded, setPageLoaded] = useState(false)

  useEnsureUserCanDisplayPage('projects')
  const queryParams = queryString.parse(window.location.search)

  const apiClient = useApiClient()

  const [sorting, setSorting] = useState<TApiProjectsSorting>({ name: 'asc' })
  const [nameFilter, setNameFilter] = useState<string | undefined>(undefined)
  const [stateFilter, setStateFilter] = useState<'active' | 'archived' | undefined>('active')
  const [billableFilter, setBillableFilter] = useState<boolean | undefined>(undefined)
  const [clientHashIds, setClientHashIds] = useState<string[] | undefined>([])
  const [membersGroupHashIds, setMembersGroupHashIds] = useState<string[] | undefined>([])
  const [usersAccountHashIds, setUsersAccountHashIds] = useState<string[] | undefined>([])

  const [projects, setProjects] = useState<TApiProject[]>([])
  const [currentPage, setCurrentPage] = useState(1)

  const [pagination, setPagination] = useState<TApiPagination>({
    currentPage: currentPage,
    pageSize: PROJECTS_LIST_PER_PAGE,
    totalPages: null,
    totalCount: null,
  })

  const [listParams, setListParams] = useState<IGetProjectsListParams>({
    page: currentPage,
    perPage: PROJECTS_LIST_PER_PAGE,
    sorting: sorting,
    filters: {
      clientHashIds: clientHashIds,
      membersGroupHashIds: membersGroupHashIds,
      state: stateFilter || undefined,
      usersAccountHashIds: usersAccountHashIds,
      isBillable: billableFilter,
      name: nameFilter,
    }
  })

  useEffect(() => {
    setListParams({
      page: 1, // if filters changes - reset pagination to the first page as others might not exist!
      perPage: PROJECTS_LIST_PER_PAGE,
      sorting: sorting,
      filters: {
        clientHashIds: clientHashIds,
        membersGroupHashIds: membersGroupHashIds,
        state: stateFilter || undefined,
        usersAccountHashIds: usersAccountHashIds,
        isBillable: billableFilter,
        name: nameFilter,
      }
    })
  }, [clientHashIds, membersGroupHashIds, stateFilter, usersAccountHashIds, billableFilter, nameFilter])

  useEffect(() => {
    setListParams({
      page: currentPage,
      perPage: PROJECTS_LIST_PER_PAGE,
      sorting: sorting,
      filters: {
        clientHashIds: clientHashIds,
        membersGroupHashIds: membersGroupHashIds,
        state: stateFilter || undefined,
        usersAccountHashIds: usersAccountHashIds,
        isBillable: billableFilter,
        name: nameFilter,
      }
    })
  }, [currentPage, sorting])

  const fetchProjectsList = (updatePagination = false) => {
    apiClient.getProjectsList(listParams).then((response) => {
      setPageLoaded(true)
      setProjects(response.projects)

      // it goes as update as API does not return pageSize
      setPagination((oldPagination) => {
        return {
          ...oldPagination,
          ...response.meta.pagination
        }
      })
    })
  }

  useEffect(() => {
    fetchProjectsList()
  }, [listParams])

  useNoInitialEffect(() => {
    fetchProjectsList()
  }, [])

  const history = useHistory()
  const { permissions } = usePagePermissions()

  const filtersData = listParams.filters
  const canCreateProject = permissions.create
  const canSetTimeLogBillable = permissions.setTimeLogBillable

  const goToNewProjectPath = () => {
    history.push('/projects/new')
  }

  const goToProjectPath = (projectId: string) => {
    if (projectId == null) {
    } else {
      return history.push(`/projects/${projectId}`)
    }
  }

  const goToNewIntegrationProjectPath = () => {
    history.push(`/integrations/new`)
  }

  return (
    <div className="page-content">
      <PageHeader
        title="Projects"
        buttons={[
          { 
            title: 'Import data',
            type: 'default',
            callback: () => {
              history.push('/imports/new') 
            },
          },
        {
          title: 'Add project',
          type: "primary",
          disabled: !canCreateProject,
          disabledTooltip: 'You have no permissions to add a new project!',
          options: [
          {
            title: "New project",
            Icon: NewProject,
            callback: () => {
              return goToNewProjectPath()
        },
        },
        {
          title: "From integration",
          Icon: Integration,
          callback: () => {
            return goToNewIntegrationProjectPath()
        },
        },
        ],
        },
        ]}
      />

      <Card>
        {(() => {
          return (
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <SearchFilter 
                placeholder={'Filter projects'}
                setSearchQuery={setNameFilter} 
              />

              <StateFilter
                value={stateFilter}
                onChange={setStateFilter}
              />

              <div style={{ width: "50%", display: "flex", alignItems: "center", justifyContent: "right", gap: '10px' }}>
                <ClientsFilter
                  values={clientHashIds}
                  onChange={(values) => { setClientHashIds(values) }}
                />
                <TeamFilter
                  values={{
                    memberHashIds: usersAccountHashIds || [],
                    groupHashIds: membersGroupHashIds || [],
                  }}
                  onChange={(values) => {
                    setUsersAccountHashIds(values.memberHashIds)
                    setMembersGroupHashIds(values.groupHashIds)
                  }}
                  width={"100%"}
                />

                {canSetTimeLogBillable && (
                  <BillableFilter
                    style={{
                      width: '180px'
                    }}
                    value={billableFilter === undefined ? undefined : (billableFilter ? 'billable' : 'non-billable')}
                    onChange={(value) => {
                      let newValue: boolean | undefined

                      switch (value) {
                        case undefined:
                          newValue = undefined
                          break
                        case 'billable':
                          newValue = true
                          break
                        case 'non-billable':
                          newValue = false
                          break
                      }

                      setBillableFilter(newValue)
                    }}
                  />
               )}
              </div>
            </div>
          )
        })()}
      </Card>

      {pageLoaded ? (
        <ProjectsTable
          goToProjectPath={goToProjectPath}
          pagination={pagination}
          projects={projects}
          sorting={sorting}

          onCurrentPageChange={setCurrentPage}
          onSortingChange={setSorting}
          onReload={fetchProjectsList}
        />
      ) : (
        <LoadingSkeleton />
      )}
    </div>
  )
}

export default ProjectsListPage