import { InfoCircleOutlined } from "@ant-design/icons"
import { Button, Col, Form, Row, Skeleton, Tooltip, message } from "antd"
import { ThreeDSecureVerifyPayload } from "braintree-web"
import { useGetApiCall } from "hooks/useApiCall"
import { HTMLProps, useEffect, useState } from "react"
import CVV_INFO_IMG from 'assets/img/cvv.png'
import useApiClient from "hooks/useApiClient"

const THREE_D_MODAL_CONTENT_ID = 'three-3-modal-content'
const BRAINTREE_MERCHANT_ID = process.env?.REACT_APP_BRAINTREE_MERCHANT_ID

const formStyles = {
  "input": {
    "color": "#282c37",
    "font-size": "16px",
    "transition": "color 0.1s",
    "line-height": "3"
  },
  // Style the text of an invalid input
  "input.invalid": {
    "color": "#E53A40"
  },
  // placeholder styles need to be individually adjusted
  "::-webkit-input-placeholder": {
    "color": "rgba(185, 185, 196, 1)"
  },
  ":-moz-placeholder": {
    "color": "rgba(185, 185, 196, 1)"
  },
  "::-moz-placeholder": {
    "color": "rgba(185, 185, 196, 1)"
  },
  ":-ms-input-placeholder": {
    "color": "rgba(185, 185, 196, 1)"
  }
}

const formFields = {
  number: {
    selector: "#card-number",
    placeholder: "0000 0000 0000 0000"
  },
  cvv: {
    selector: "#cvv",
    placeholder: "123"
  },
  expirationDate: {
    selector: "#expiration-date",
    placeholder: "12/2025"
  }
}

const HostedInputDiv = (props: HTMLProps<HTMLDivElement>) => (
  <div
    {...props}
    className="ant-input"
    style={{
      height: '45px',
    }}
  >
    {props.children}
  </div>
)

const CreditCardForm = ({
  onSubmit,
  onCancel,
}: {
  onSubmit(): void
  onCancel(): void
}) => {
  const apiClient = useApiClient()

  const braintreeLoading = useBraintreeScripts()
  const [threeDModalVisible, setThreeDModalVisible] = useState<any>(undefined)
  const [paymentMethodDetails, setPaymentMethodDetails] = useState<ThreeDSecureVerifyPayload>()
  const [hostedFieldsInitialising, setHostedFieldsInitialising] = useState(true)

  const [formValid, setFormValid] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const {
    data: paymentToken,
    loading: tokenLoading,
    request: fetchPaymentToken,
  } = useGetApiCall(async () => ((await apiClient.getPaymentMethodToken()).token))

  const loading = braintreeLoading || tokenLoading

  useEffect(() => {
    if (!loading && paymentToken) {
      // Small delay is needed so Braintee library selector logic actually works after render
      setTimeout(() => {
        initBraintreeFields({
          token: paymentToken,
          onSubmitting: () => {
            setErrorMessage(undefined)
            setFormSubmitting(true)
          },
          onSetValid: (isValid) => { setFormValid(isValid) },
          onInitialised: () => setHostedFieldsInitialising(false),
          onBraintreeErrorMessage: (errorMessage) => {
            setFormSubmitting(false)
            setErrorMessage(errorMessage)
          },
          onSetPaymentMethod: (details) => {
            apiClient.createPaymentMethod(details).then(async (response) => {
              if (response.ok) {
                onSubmit()
              } else {
                if (response.errors) {
                  message.error(response.errors?.base || "Something went wrong!")
                  onCancel()
                }
              }
            })
          }
        })
      }, 1)
    }
  }, [loading, paymentToken])

  return (
    <>

      {hostedFieldsInitialising && (
        <Skeleton active paragraph={{ rows: 4 }} />
      )}

      <div
        className="braintree-form"
        style={{
          display: hostedFieldsInitialising ? 'none' : 'block'
        }}
      >
        { errorMessage && (
          <p
            style={{
              color: 'red'
            }}
          >{errorMessage}</p>
        )}

        <Form
          id="payment-method-form"
        >
          <label>
            <h5>Credit Card Number</h5>
            <HostedInputDiv id="card-number" />
          </label>

          <Row>
            <Col span="16">
              <label className="expiration-date">
                <h5>Expiration Date (MM/YY)</h5>
                <HostedInputDiv id="expiration-date" />
              </label>
            </Col>
            <Col span="7" offset="1">
              <label className="cvv">
                <Tooltip title={(
                  <div>
                    <p>Where I can find the CVV?</p>
                    <img src={CVV_INFO_IMG} />
                  </div>
                )}>
                  <div id="cc_cvv">
                    <h5>
                      CVV
                      <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
                    </h5>
                  </div>
                </Tooltip>
                <HostedInputDiv id="cvv" />
              </label>
            </Col>
          </Row>
          <div
            style={{
              width: '100%',
              display: 'flex',
              marginTop: '10px',
              justifyContent: 'space-between',
            }}>
            { BRAINTREE_MERCHANT_ID ? (
              <a href={`https://www.braintreegateway.com/merchants/${BRAINTREE_MERCHANT_ID}/verified`} target="_blank">
                <img src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png" width="280px" height="44px" />
              </a>
            ) : (
              <img src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png" width="280px" height="44px" />
            )}
           <Button
              id='payment-method-save-button'
              htmlType="submit"
              type="primary"
              style={{
                width: '100px'
              }}
              disabled={!formValid}
              loading={formSubmitting}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </>
  )
}

const initBraintreeFields = ({
  token,
  onSetValid,
  onInitialised,
  onSubmitting,
  onBraintreeErrorMessage,
  onSetPaymentMethod,
}: {
  token: string,
  onSetValid(isValid: boolean): void,
  onInitialised(): void,
  onSubmitting(submitting: boolean): void,
  onBraintreeErrorMessage(errorMessage: string): void,
  onSetPaymentMethod(details: ThreeDSecureVerifyPayload): void
}) => {
  // Selectors
  let $form = document.querySelector("#payment-method-form")
  let $form_submit = document.querySelector("#payment-method-save-button")

  if ($form == null || $form_submit == null) {
    console.error("Braintree form not found!")
  }

  let braintree = window.braintree

  let airbrake = {
    notify: (msg: any) => { console.error(msg) }
  }

  braintree.client.create({
    authorization: token
  }, function (err, clientInstance) {
    if (err) {
      airbrake.notify(err)
      return
    }

    braintree.threeDSecure.create({
      client: clientInstance,
      version: 2
    }, function (threeDSecureErr, threeDSecureInstance) {
      if (threeDSecureErr) {
        // Handle error in 3D Secure component creation
        airbrake.notify(threeDSecureErr)
        return
      }

      let threeDSecure = threeDSecureInstance

      // Create input fields and add text styles
      braintree.hostedFields.create({
        client: clientInstance,
        styles: formStyles,
        // Add information for individual fields
        fields: formFields
      }, function (err, hostedFieldsInstance) {
        if (err || !hostedFieldsInstance) {
          airbrake.notify(err);
          return;
        }

        hostedFieldsInstance.on("validityChange", function (event) {
          // Check if all fields are valid, then show submit button
          var formValid = Object.keys(event.fields).every(function (key) {
            // @ts-ignore
            return event.fields[key].isValid
          })

          onSetValid(formValid)

          // Replaced by above state change
          /*
          if (formValid) {
            $($form_submit).addClass("show-button")
          } else {
            $($form_submit).removeClass("show-button")
          }
          */
        })

        /* TODO: whats that?
        hostedFieldsInstance.on("empty", function (event) {
          if (!event.fields.number.isValid) {
            $("#card-image").removeClass();
            $($form).removeClass();
          }
        });
        */

        hostedFieldsInstance.on("cardTypeChange", function (event) {
          // Change card depending on card type
          if (event.cards.length === 1) {

            // Change the CVV length for AmericanExpress cards
            if (event.cards[0].code.size === 4) {
              hostedFieldsInstance.setAttribute({
                field: "cvv",
                attribute: "placeholder",
                value: "1234"
              })
            }
          } else {
            hostedFieldsInstance.setAttribute({
              field: "cvv",
              attribute: "placeholder",
              value: "123"
            })
          }
        });

        if ($form == null) {
          airbrake.notify('Braintree form is not found!')
          return
        }

        $form.addEventListener("submit", function (event) {
          event.preventDefault();
          onSubmitting(true)
          //$($form_submit).addClass("disable-button");

          hostedFieldsInstance.tokenize(function (err, payload) {
            if (err) {
              airbrake.notify(err);
              return;
            }

            if (payload === undefined) {
              airbrake.notify("Hosted fields payload not found!")
              return
            }

            if (!threeDSecure) {
              airbrake.notify('ThreeDSecure not defined!')
              return
            }

            threeDSecure.verifyCard({
              challengeRequested: true,
              amount: 0.01,
              nonce: payload.nonce,
              bin: payload.details.bin,
              // @ts-ignore
              onLookupComplete: function (data: any, next: any) {
                // "Lookup complete"
                // use `data` here, then call `next()`
                next()
              },
              addFrame: function (err, iframe) {
                // onSetThreeDModalVisible(true)
                // 'Add frame'
              },
              removeFrame: function () {
                // 'Remove frame'
                // onSetThreeDModalVisible(false)
              },
            }, function (err, response) {

              // Error case
              if (!response && err) {
                console.error("3D Secure verify error")
                const error: any = err
                const errorMessage = error?.details?.originalError?.details?.originalError?.error?.message || "Something went wrong, if problem persists please contact our customer support!"

                onBraintreeErrorMessage(errorMessage)

                airbrake.notify(err)
                return
              }

              const paymentMethod = response
              onSetPaymentMethod(paymentMethod as ThreeDSecureVerifyPayload)
            })
          })
        }, false);

        onInitialised()
      })
    })
  })
}

const useBraintreeScripts = () => {
  const clientLoaded = useScript('https://js.braintreegateway.com/web/3.85.2/js/client.min.js')
  const fieldsLoaded = useScript('https://js.braintreegateway.com/web/3.85.2/js/hosted-fields.min.js')
  const threeDLoaded = useScript('https://js.braintreegateway.com/web/3.85.2/js/three-d-secure.min.js')

  return !clientLoaded && !fieldsLoaded && !threeDLoaded
}

const useScript = (url: string) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')

    script.src = url // with http(s)
    script.async = true

    script.onload = () => {
      setLoaded(true)
    }
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return loaded
}

export default CreditCardForm