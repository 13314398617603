import React, { useEffect, useRef } from 'react'
import { Card, message } from 'components/base'
import PageHeader from 'components/layout/page-header'
import { Button, Modal, Select, Skeleton } from 'antd'
import _find from 'lodash/find'
import TimeLogsFilters from 'components/time-logs-filters'
import DetailedReportsTable from 'pages/reports/components/detailed-reports-table'
import _isEqual from 'lodash/isEqual'
import { delayFunction } from 'utils/functions'
import ReportsDatePicker from 'pages/reports/components/reports-date-picker'
import moment from 'moment-timezone'
import { DollarIcon, DownloadIcon } from 'components/base/icons'
import ReportsExportModal from 'pages/reports/components/reports-export-modal'
import { defer, filter,  isEqual,  map, omit } from 'lodash'
import ReportsRoundingModal from 'pages/reports/components/reports-rounding-modal'
import DetailedReportsChart from 'pages/reports/components/detailed-reports-chart'
import { GenericTableParams } from 'components/generic-table/generic-table'
import TimeRowForm, { TimeRowFormItemProps } from 'components/forms/time-row-form'
import { FormikConfig } from "formik";
import { toDurationString } from 'components/forms/duration-input'
import TimeLogFormModal from 'components/time-logs/time-log-form-modal'
import { getPageDefaultFilters, saveFiltersToParams, saveGroupingToParams, saveRoundingToParams, saveTimespanToParams } from 'services/reports-filters/reports-filters.utils'
import BulkEditRatesModal from './components/bulk-edit-rates-modal'
import useClassState from 'services/utils/hooks/use-class-state'
import { useTopNavigationLinks } from 'hooks/layout'
import useApiClient from 'hooks/useApiClient'
import { TApiTeamMember } from '@timenotes/shared/src/services/api-client/users-accounts-api'
import { TApiGrouping, TApiResponse, TApiRounding } from '@timenotes/shared/src/services/api-client/types'
import { ICreateTimeLogParams, ICreateTimeLogsResponse } from '@timenotes/shared/src/services/api-client/time-logs-api'
import { getApiErrorMessage, handleResponseWithMessage } from '@timenotes/shared/src/services/api-client'
import { IBulkModifyTimeLogsParams } from '@timenotes/shared/src/services/api-client/bulks-time-logs-api'
import { IGetReportsDetailedExportParams, IGetReportsDetailedParams, IGetReportsDetailedResponse, TApiChartData, TApiExportColumn, TApiReportsDate, TApiReportsDetailedRow, TApiReportsDetailedSorting, TApiReportsFilters } from '@timenotes/shared/src/services/api-client/reports-api'
import useEnsureUserCanDisplayPage from 'services/pages/use-ensure-user-can-display-page'

type CancelIfOutdated = () => boolean;
type Operation = (cancelIfOutdated: CancelIfOutdated) => void;

const makeWithIsCurrent = (): ((operation: Operation) => void) => {
  let latestRequestId = 0

  const cancellable = (operation: Operation) => {
    const requestId = ++latestRequestId;
    const isCurrent = () => requestId === latestRequestId
    operation(isCurrent)
  };

  return cancellable
}

const withIsCurrent = makeWithIsCurrent()

interface IState {
  loaded: boolean
  isTimeRowEditVisible: boolean
  editMember?: TApiTeamMember
  isInviteMembersVisible: boolean
  invitationEmails: string[]
  displayInvitationsTable: boolean
  reportsDate: TApiReportsDate

  timeRows: TApiReportsDetailedRow[]
  timeRowsLoading: boolean
  filters: TApiReportsFilters
  grouping: TApiGrouping
  timeRowsTableParams: GenericTableParams
  totals?: IGetReportsDetailedResponse['totals']

  exportModalVisible: boolean,
  bulkEditModalVisible: boolean,
  bulkEditRatesModalVisible: boolean,
  exportParams: IGetReportsDetailedExportParams['export']
  exportColumns: TApiExportColumn[]
  exportChart: boolean
  roundingParams: TApiRounding
  roundingModalVisible: boolean
  chartData: TApiChartData

  createTimeLogParams?: ICreateTimeLogParams
}

const DetailedReportsPage = () => {
  const apiClient = useApiClient()
  const bulkTimeRowFormikRef = useRef<React.Ref<FormikConfig<TimeRowFormItemProps>>>()
  const editTimeLogFormikRef = useRef<React.Ref<FormikConfig<ICreateTimeLogParams>>>()

  useEnsureUserCanDisplayPage('reports')

  useTopNavigationLinks([
    {
      label: 'DETAILED',
      urlMatcher: "reports",
      onClick: () => { return "" },
    },
  ])

  const defaults = getPageDefaultFilters({
    key: 'report',
    filters: {},
    rounding: {
      type: 'no_rounding',
      precision: 5,
    },
    timespan: {
      timespan: 'month',
      from: moment().format('DD/MM/yyyy'),
      to: moment().format('DD/MM/yyyy'),
    },
    grouping: {
      primary: 'no_group'
    },
  })

  const [state, setState, prevState] = useClassState<IState>({
    loaded: true,
    isTimeRowEditVisible: false,
    isInviteMembersVisible: false,
    invitationEmails: [],
    displayInvitationsTable: false,
    totals: undefined,
    reportsDate: defaults.timespan,
    timeRows: [],
    timeRowsLoading: false,
    timeRowsTableParams: {
      pagination: {
        currentPage: 1,
        pageSize: 20,
        totalCount: 0,
        totalPages: 0,
      },
      sorting: null,
      selectedAll: false,
      selectedRowKeys: [],
    },
    filters: defaults.filters,
    grouping: defaults.grouping,

    exportModalVisible: false,
    exportChart: true,
    exportParams: {
      type: 'csv',
      chartData: "",
      columns: [],
    },

    bulkEditModalVisible: false,
    bulkEditRatesModalVisible: false,

    roundingModalVisible: false,
    roundingParams: defaults.rounding,
    exportColumns: [],
    chartData: {
      color: [],
      info: [],
      labels: [],
      values: [],
    },
    createTimeLogParams: undefined,
  })

  useEffect(() => {
    fetchTimeRows()
    fetchExportColumns()
  }, [])

  const getRequestParams = (): IGetReportsDetailedParams => {

    const sorting: TApiReportsDetailedSorting = {}

    if (state.timeRowsTableParams.sorting) {
      // @ts-ignore
      sorting[state.timeRowsTableParams.sorting.key] = state.timeRowsTableParams.sorting.order
    }

    return {
      filters: {
        ...state.filters,
        ...state.reportsDate,
      },
      sorting: state.timeRowsTableParams.sorting ? sorting : undefined,
      rounding: state.roundingParams,
      grouping: state.grouping,
      page: state.timeRowsTableParams.pagination?.currentPage || 1,
      perPage: state.timeRowsTableParams.pagination?.pageSize || 1,
    }
  }

  const fetchExportColumns = () => {
    apiClient.getReportsDetailedExportColumns({ grouping: { primary: state.grouping.primary } }).then((response) => {
      if (response.ok) {
        setState({
          exportColumns: response.exportColumns,
        })

        let preselectedExportColumns: TApiExportColumn[] = filter(response.exportColumns, (exportColumn) => {
          return exportColumn.preselected
        })

        let initialExportColumns: string[] = map(preselectedExportColumns, (preselectedExportColumn) => {
          return preselectedExportColumn.name
        })

        setState({
          exportParams: {
            ...state.exportParams,
            columns: initialExportColumns,
          }
        })
      }
    })
  }

  const fetchTimeRows = () => {
    // Returning fetch changes the state again, so we need to ensure only the latest update is passed to the state
    // withIsCurrent injects the function which allows to confirm the check of current fuctnion execution. If executed
    // callback is the current function execution, but outdated one, we skip the state update.
    withIsCurrent((isCurrent) => {
      apiClient.getReportsDetailed(getRequestParams()).then((response) => {
        if (!isCurrent()) {
          return
        }

        setState({
          timeRowsLoading: false,
          totals: response.totals,
          timeRows: response.rows,
          timeRowsTableParams: {
            ...state.timeRowsTableParams,
            pagination: {
              ...state.timeRowsTableParams.pagination,
              ...response.meta.pagination,
            }
          }
        })
      })

      apiClient.getReportsDetailedChart(getRequestParams()).then((response) => {
        if (!isCurrent()) {
          return
        }

        setState({
          chartData: {
            color: response.color,
            info: response.info,
            labels: response.labels,
            values: response.values,
          }
        })
      })
    })
  }

  const updateChartImageData = () => {
    // @ts-ignore
    const CHART_IMAGE_BASE64: string = state.exportChart ? document.querySelector('#detailed-reports-chart canvas').toDataURL('image/png') : ""

    setState({
      exportParams: {
        ...state.exportParams,
        chartData: CHART_IMAGE_BASE64,
      }
    })
  }

  const resetPaginationAndSelection = () => {
    setState({
      ...getTimeRowsTableParamsWithCurrentPageReset()
    })
  }

  const getTimeRowsTableParamsWithCurrentPageReset = () => {
    return {
      timeRowsLoading: true,

      timeRowsTableParams: {
        ...state.timeRowsTableParams,

        // Reset selection
        selectedAll: false,
        selectedRowKeys: [],

        // Reset sorting
        sorting: null,

        // Reset pagination
        ...{
          pagination: {
            ...state.timeRowsTableParams.pagination,
            currentPage: 1,
          }
        }
      }
    }
  }

  const applyFilters = (filters: TApiReportsFilters) => {
    setState({
      filters: filters,
      // Reset current page to 1 in case filters changes
      ...getTimeRowsTableParamsWithCurrentPageReset(),
    })
  }

  const triggerExportRequest = () => {
    if (state.exportParams.columns?.length == 0) {
      message.error('You need to select any columns for export!')
    } else {
      apiClient.getReportsDetailedExport({
        export: state.exportParams,
        ...getRequestParams()
      })
      setState({ exportModalVisible: false })
    }
  }

  const triggerRoundingUpdate = (roundingParams: TApiRounding) => {
    setState({ roundingParams: roundingParams, roundingModalVisible: false })
  }

  // TODO: Ensure update/refetch always only once!
  useEffect(() => {
    if (!isEqual(prevState, state)) {
      if (!(_isEqual(state.filters, prevState.filters))) {
        saveFiltersToParams(state.filters)
        // Trigger seaarch with 750ms delay to avoid multiple calls when typing
        delayFunction('updateFilters', fetchTimeRows, 750)
      }

      if (!(_isEqual(state.reportsDate, prevState.reportsDate))) {
        saveTimespanToParams(state.reportsDate)
        fetchTimeRows()
      }

      if (!(_isEqual(state.roundingParams, prevState.roundingParams))) {
        saveRoundingToParams(state.roundingParams)
        fetchTimeRows()
      }

      // If exportModalVisible changed to true
      if (!(_isEqual(state.exportModalVisible, prevState.exportModalVisible)) && state.exportModalVisible == true) {
        updateChartImageData()
      }

      // If exportChart should be included changes
      if (!_isEqual(state.exportChart, prevState.exportChart)) {
        updateChartImageData()
      }

      // If table params changed
      if (!_isEqual(omit(state.timeRowsTableParams, 'selectedRowKeys'), omit(prevState.timeRowsTableParams, 'selectedRowKeys'))) {
        fetchTimeRows()
      }

      // If grouping changed
      if (!_isEqual(state.grouping, prevState.grouping)) {
        saveGroupingToParams(state.grouping)

        defer(() => {
          try {
            window.localStorage.setItem('reportDefaultGrouping', JSON.stringify(state.grouping))
          }
          catch (e) {
          }
        })

        fetchExportColumns()
        fetchTimeRows()
      }
    }
  }, [state])

  const handleOnChange = (params: GenericTableParams) => {
    setState({
      timeRowsTableParams: params,
    })
  }

  const handleEditTimeLog = (timeRow: TApiReportsDetailedRow) => {
    setState({
      isTimeRowEditVisible: true,
      createTimeLogParams: timeRow,
   })
  }

  const getGroupingDetailedTimeLogs = (timeRow: TApiReportsDetailedRow) => {
    const filtersUpdate: Partial<TApiReportsFilters> = {}

    const timeRowKey = timeRow.hashId || timeRow.id

    // If grouping by user - add selected user id's as a more detailed user filter for the usersAccounts
    if (state.grouping.primary == 'user') {
      filtersUpdate.usersAccountHashIds = [timeRowKey]
    }

    // ...
    if (state.grouping.primary == 'project') {
      filtersUpdate.projectHashIds = [timeRowKey]
    }

    // ...
    if (state.grouping.primary == 'client') {
      filtersUpdate.clientHashIds = [timeRowKey]
    }

    // ...
    if (state.grouping.primary == 'task') {
      filtersUpdate.taskName = timeRow.name
    }

    setState({
      grouping: {
        primary: 'no_group',
      },
      timeRowsLoading: true,
      filters: {
        ...state.filters,
        ...filtersUpdate,
      }
    })
  }

  const handleTimeLogUpdate = (timeLog: ICreateTimeLogParams): Promise<ICreateTimeLogsResponse> => {
    return apiClient.updateTimeLog(timeLog.id as string, timeLog).then((response) => {
      if (response.ok) {
        setState({ isTimeRowEditVisible: false })
        fetchTimeRows()
      }
      return response
    })
  }

  const handleDeleteTimeLog = (timeLogId: string) => {
    apiClient.deleteTimeLog(timeLogId).then(handleResponseWithMessage('Time log deleted sucessfully!')).then((response) => {
      if (response.ok) {
        fetchTimeRows()
      }
    })
  }

  const getFiltersParamsForBulkAction = () => {
    // Else, build the params starting with all the selected main page base filters
    const baseParams = {
      filters: {
        ...state.filters, // copy of filters
        ...state.reportsDate, // & repors date filters part
      },
      bulk: { all: true }
    }

    // If select all (including all pages) items selected - apply all the page filters no matter the grouping
    if (!state.timeRowsTableParams.selectedAll) {
      // otherwise, treat selected rows differently depending on the group

      // If grouping is by time log directly - edit selected time logs directly by IDs
      if (state.grouping.primary == 'no_group') {
        return {
          filters: null,
          bulk: { ids: state.timeRowsTableParams.selectedRowKeys } // for grouping no_group those are time log ids'
        }
      }

      // If grouping by user - add selected user id's as a more detailed user filter for the usersAccounts
      if (state.grouping.primary == 'user' && state.timeRowsTableParams.selectedRowKeys.length > 0) {
        baseParams.filters.usersAccountHashIds = state.timeRowsTableParams.selectedRowKeys
      }

      // ...
      if (state.grouping.primary == 'project' && state.timeRowsTableParams.selectedRowKeys.length > 0) {
        baseParams.filters.projectHashIds = state.timeRowsTableParams.selectedRowKeys
      }

      // ...
      if (state.grouping.primary == 'client' && state.timeRowsTableParams.selectedRowKeys.length > 0) {
        baseParams.filters.clientHashIds = state.timeRowsTableParams.selectedRowKeys
      }

      // ...
      if (state.grouping.primary == 'task' && state.timeRowsTableParams.selectedRowKeys.length > 0) {
        baseParams.filters.taskHashIds = state.timeRowsTableParams.selectedRowKeys
      }
    }

    return baseParams
  }

  const bulkDeleteTimeLogs = () => {
    apiClient.bulkDeleteTimeLogs(getFiltersParamsForBulkAction())
      .then((response) => {
        if (response.ok) {
          fetchTimeRows()
          resetPaginationAndSelection()
        } else {
          message.error(getApiErrorMessage(response, 'base'))
        }
      })
  }

  const bulkRecalculateTimeLogs = () => {
    apiClient.bulkRecalculateTimeLogs(getFiltersParamsForBulkAction())
      .then((response) => {
        if (response.ok) {
          message.success('Recalculating rates triggered. It might take a couple minutes...')
          setTimeout(() => {
            fetchTimeRows()
            resetPaginationAndSelection()
          }, 2000)
        } else {
          message.error(getApiErrorMessage(response, 'base'))
        }
      })
  }

  const bulkUpdateTimeLogs = (timeLog: TimeRowFormItemProps): Promise<TApiResponse> => {
    let response: Promise<TApiResponse>
    const bulkModifyTimeLogParams: IBulkModifyTimeLogsParams['timeLog'] = {
      ...timeLog
    }

    response = apiClient.bulkModifyTimeLogs({
      timeLog: bulkModifyTimeLogParams,
      ...getFiltersParamsForBulkAction(),
    })

    return response.then((res) => {
      if (res.ok) {
        fetchTimeRows()
        resetPaginationAndSelection()
      } else {
        message.error(getApiErrorMessage(res, 'base'))
      }

      return res
    })
  }

  const handleBulkDelete = () => {
    Modal.confirm({
      title: false,
      content: `Are you sure you want to delete time logs from all selectes rows?`,
      okText: 'Delete all',
      onOk: () => {
        bulkDeleteTimeLogs()
      }
    })
  }

  const handleBulkRatesRecalculate = () => {
    Modal.confirm({
      title: `Please confirm the recalculation`,
      content: `Ale the rates for the selected time logs will be updated to the current billable and cost rates settings. Previous values will be overwritten and can not be restored.`,
      okText: 'Recalculate',
      onOk: () => {
        bulkRecalculateTimeLogs()
      }
    })
  }

  const handleTimeRowClick = (timeRow: TApiReportsDetailedRow) => {
    if (state.grouping.primary == 'no_group') {
      handleEditTimeLog(timeRow)
    }
  }

  const handleShowBulkEdit = () => {
    getFiltersParamsForBulkAction()
    setState({ bulkEditModalVisible: true })
  }

  const handleShowBulkRatesEdit = () => {
    getFiltersParamsForBulkAction()
    setState({ bulkEditRatesModalVisible: true })
  }

  const hideBulkEditModal = () => {
    setState({ bulkEditModalVisible: false })
  }

  const hideEditTimeRow = () => {
    setState({ isTimeRowEditVisible: false })
  }

  const handleBulkUpdate = (timeLog: TimeRowFormItemProps): Promise<TApiResponse> => {
    const isTimeLogEmpty = filter(Object.values(timeLog), (timeLog) => {
      return timeLog !== undefined
    }).length == 0

    if (isTimeLogEmpty) {
      message.error("You need to change any of the fields to bulk update the time logs!")

      return new Promise(() => {
        return { ok: false }
      })
    } else {
      return bulkUpdateTimeLogs(timeLog).then((response) => {
        if (response.ok) {
          hideBulkEditModal()
          resetPaginationAndSelection()
        }

        return response
      })
    }
  }

  const totalSummaryCard = (title: string, value: string) => {
    return (
      <Card
        size="small"
        style={{
          marginTop: '0px',
          margin: '0px',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <div>
            <b>{title}</b>
          </div>
          <div>
            {value}
          </div>
        </div>
      </Card>
    )
  }

  if (!state.loaded) {
    return (
      <div className="page-content">
        <Card><Skeleton /><Skeleton /></Card>
      </div>
    )
  }

  return (
    <div>
      <div className="page-content">
        <PageHeader
          title="Time Logs Report"
          buttons={[]
          }

          customHeader={(
            <div
              style={{ display: 'flex', gap: '10px' }}
            >
              <Button
                onClick={() => { setState({ roundingModalVisible: true }) }}
                icon={<DollarIcon />}
              >
                <span>
                  Rounding {state.roundingParams.type !== 'no_rounding' ? `- ${state.roundingParams.precision}m` : "- off"}
                </span>
              </Button>

              <ReportsDatePicker
                reportsDate={state.reportsDate}
                onChange={(date) => {
                  setState({
                    reportsDate: date,
                    ...getTimeRowsTableParamsWithCurrentPageReset(),
                  })
                }}
              />

              <Button
                type="primary"
                onClick={() => { setState({ exportModalVisible: true }) }}
                icon={<DownloadIcon />}
              >
                Export
              </Button>

            </div>
          )}
        />

        <Card>
          <TimeLogsFilters
            filters={state.filters}
            onChange={applyFilters}
          />
        </Card>

      </div>

      <div className="page-content" style={{ marginLeft: '5px', marginRight: '5px' }}>
        <DetailedReportsChart
          data={state.chartData}
        />

      </div>

      <div className="page-content" style={{ marginLeft: '5px', marginRight: '5px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{
            display: 'flex',
            justifyItems: 'center',
            alignItems: 'center',
            gap: '10px',
          }}>
            <div>
              <b>Group by</b>
            </div>
            <Select
              style={{ width: '140px' }}
              value={state.grouping.primary}
              onChange={(value) => {
                setState({
                  ...getTimeRowsTableParamsWithCurrentPageReset(),
                  grouping: { primary: value },
                })
              }}
            >
              <Select.Option value={'no_group'}>Time log</Select.Option>
              <Select.Option value={'user'}>Team member</Select.Option>
              <Select.Option value={'client'}>Client</Select.Option>
              <Select.Option value={'project'}>Project</Select.Option>
              <Select.Option value={'task'}>Task</Select.Option>
            </Select>

          </div>

          {!state.totals ? <div /> : (
            <div
              style={{
                display: 'flex',
                gap: '10px',
              }}
            >
              {totalSummaryCard('Total Time:', `${toDurationString(state.totals.worktime)}`)}
              {
                // This allows to not ask for the permissions as API will return null for users who can not see billable amounts
                state.totals.billableAmount && totalSummaryCard('Total Billable:', `${(state.totals.billableAmount.cents / 100).toFixed(2)} ${state.totals.billableAmount.currencyIso}`)
              }
              {
                // same as above here
                state.totals.costAmount && totalSummaryCard('Total Cost:', `${(state.totals.costAmount.cents / 100).toFixed(2)} ${state.totals.costAmount.currencyIso}`)
              }
            </div>
          )}

        </div>

        <div style={{ height: '25px' }} />

        {state.timeRowsLoading ? (
          <Card>
            <Skeleton />
          </Card>
        ) : (
          <DetailedReportsTable
            groupingPrimary={state.grouping.primary}
            timeRows={state.timeRows}
            tableParams={state.timeRowsTableParams}
            onChange={handleOnChange}
            onClick={handleTimeRowClick}
            onBulkDelete={handleBulkDelete}
            onBulkRatesRecalculate={handleBulkRatesRecalculate}
            onEditItem={state.grouping.primary == 'no_group' ? handleEditTimeLog : getGroupingDetailedTimeLogs}
            onDeleteItem={state.grouping.primary == 'no_group' ? handleDeleteTimeLog : undefined}
            onShowBulkEdit={handleShowBulkEdit}
            onShowBulkRatesEdit={handleShowBulkRatesEdit}
          />
        )}
      </div>

      <ReportsExportModal
        exportChart={state.exportChart}
        onChangeExportChart={(exportChart) => { setState({ exportChart }) }}
        exportParams={state.exportParams}
        exportColumns={state.exportColumns}
        onChange={(exportParams) => {
          setState({ exportParams })
        }}
        modalProps={{
          visible: state.exportModalVisible,
          okText: 'Export',
          onOk: () => {
            triggerExportRequest()
          },
          onCancel: () => {
            setState({ exportModalVisible: false })
          }
        }}
      />

      <TimeLogFormModal
        timeLog={state.createTimeLogParams as TApiTimeLog}
        visible={state.isTimeRowEditVisible}
        onCancel={hideEditTimeRow}
        onSuccess={() => {
          fetchTimeRows()
        }}
      />

      <Modal
        destroyOnClose={true}
        visible={state.bulkEditModalVisible}

        onOk={() => {
          return bulkTimeRowFormikRef.current.submitForm()
        }}

        onCancel={hideBulkEditModal}
        title={
          <h1> Edit time logs from selected rows</h1>
        }
      >
        <TimeRowForm
          formikRef={bulkTimeRowFormikRef}
          timeLog={{
            usersAccountId: undefined,
          }}
          onSubmit={handleBulkUpdate}
        />
      </Modal>

      <BulkEditRatesModal
        visible={state.bulkEditRatesModalVisible}
        bulkParams={getFiltersParamsForBulkAction()}
        onCancel={() => setState({ bulkEditRatesModalVisible: false })}
        onSuccess={() => {
          setTimeout(() => {
            fetchTimeRows()
            resetPaginationAndSelection()
          }, 2000)
          setState({ bulkEditRatesModalVisible: false })
        }}
      />

      <ReportsRoundingModal
        visible={state.roundingModalVisible}
        roundingParams={state.roundingParams}
        hideModal={() => {
          setState({ roundingModalVisible: false })
        }}

        onChange={(roundingParams) => {
          triggerRoundingUpdate(roundingParams)
        }}
      />

    </div>
  )
}

export default DetailedReportsPage