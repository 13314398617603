import { Badge, Button, Card, Col, Form, Input, Row, Statistic, Tag, Tooltip, message } from "antd"
import { useEffect, useState } from "react"
import TagsSelect from '../tags/tags-select-icon'
import moment, { Moment } from "moment-timezone"
import BillableFlag from "components/base/billable-flag"
import { ErrorMessageTooltip } from "components/base/error-message"
import { UserOutlined } from "@ant-design/icons"
import ProjectSelectPill from "../projects/project-select-pill"
import { useTimeLogFormik } from "@timenotes/shared/src/services/time-logs/time-logs.hooks"
import TaskSelectPill from "../tasks/task-select-pill"
import { useCurrentUser } from "hooks/current-user"
import DurationInput from "../time-logs/duration-input"
import TimePicker from "../time-logs/time-picker"
import { useDefaultTimeFormat } from "hooks/settings"
import { useCurrentProjectsPermissionsQuery, usePermissionsQuery } from "@timenotes/shared/src/services/permissions/permissions.hooks"
import { getUsersAccountFullName } from "services/users-accounts"
import styled from "styled-components"
import DurationCounter from "../active-tracker/duration-counter"
import { useActiveTrackerQuery } from "@timenotes/shared/src/services/timer/queries/use-active-tracker-query"
import useStopActiveTrackerMutation from "@timenotes/shared/src/services/timer/queries/use-stop-active-tracker-mutation"
import useNoInitialEffect from "hooks/useNoInitialEffect"
import useStartActiveTrackerMutation from "@timenotes/shared/src/services/timer/queries/use-start-active-tracker-mutation"
import UsersAccountSelect from "../users-accounts/users-account-select"
import { TimeLogFormItemProps } from "@timenotes/shared/src/services/time-logs/time-logs.types"
import { TApiTimeLog, TApiUsersAccount } from "@timenotes/shared/src/services/api-client/types"

const DATE_TIME_FORMAT = 'YYYY-MM-DD'

const DisabledInput = styled(Input)`
  background-color: #fff !important; /* Or whatever your normal input background color is */
  color: #000 !important; /* Or whatever your normal input text color is */
  cursor: not-allowed !important;
 
  &.ant-input-disabled, & .ant-input-disabled {
    background-color: #fff !important; /* Or whatever your normal input background color is */
    color: #000 !important; /* Or whatever your normal input text color is */
    cursor: not-allowed !important;
  }

`

export interface TimeLogFormProps {
  mode: 'tracker' | 'manual'
  initialValues?: Partial<TimeLogFormItemProps>
  date?: Moment

  enableUsersAccountSelect: boolean
  onSuccess?(timeLog: TApiTimeLog): void
}

const DividerColl = () => (
  <Col span="1" style={{ display: 'flex', justifyContent: 'center' }}>
    <div
      style={{
        width: '1px',
        height: '40px',
        backgroundColor: '#eee',
      }}
    />
  </Col>
)

const TimeLogForm = (props: TimeLogFormProps = {
  mode: 'manual',
  enableUsersAccountSelect: false,
}) => {

  // TRACKER

  const trackerQuery = useActiveTrackerQuery({
    options: {
      enabled: props.mode == 'tracker'
    }
  }) 

  const stopTrackerMutation = useStopActiveTrackerMutation({
    options: {
      // @ts-ignore
      onSuccess: (data, variables) => {
        formik.resetForm()
      },
      // @ts-ignore
      onError: (error, variables, context) => {
        if (error && error?.base) {
          message.error(error?.base)
          // Refetching instead of reseting form, as can not be sure for all cases if
          // backend actually stoped the timer?
          formik.resetForm()
          trackerQuery.refetch()
        }
      }
    }
  })
  const startTrackerMutation = useStartActiveTrackerMutation()

  const activeTracker = trackerQuery.data?.activeTracker

  useEffect(() => {
    if (activeTracker && props.mode == 'tracker') {
      formik.setValues({
        project: activeTracker.project,
        task: activeTracker.task,
        tags: activeTracker.tags,
        description: activeTracker.description,
        isBillable: activeTracker.isBillable,
      })

    }
  }, [activeTracker, props.mode])

  const trackerStartAt = activeTracker ? moment.tz(activeTracker.startedAt, activeTracker.timeZone).utc() : undefined
  const trackerActive = !!activeTracker && props.mode == 'tracker'
  const [currentTime, setCurrentTime] = useState(moment().utc(true))

  const handleStartTracker = () => {
    if (!formik.values.project || !formik.values.task) {
      formik.setErrors({ 
        project: !!formik.values.project ? undefined : 'Project can\'t be blank.',
        task: !!formik.values.task ? undefined : 'Task can\'t be blank.',
    })

    } else {
      startTrackerMutation.mutate(formik.values)
    }
  }

  useNoInitialEffect(() => {
    if (startTrackerMutation.isSuccess) {
      // tracker is being filled by react query layer itself
      setCurrentTime(moment().utc(true))
    } 

    if (startTrackerMutation.isError) {
      formik.setErrors(startTrackerMutation.error)
    }
  }, [startTrackerMutation.isLoading])

  const handleStopTracker = () => {
    stopTrackerMutation.mutate()
  }

  useEffect(() => {
    const int = setInterval(() => {
      setCurrentTime(moment().utc(true))
    }, 10_000)

    return () => {
      clearInterval(int)
    }
  }, [])

  // TRACKER
  const date = moment(props.date).utc().set({ hour: 8, minute: 0, second: 0}) || moment().utc().set({ hour: 8, minute: 0, second: 0})

  const { currentUser } = useCurrentUser()

  const permissionsQuery = usePermissionsQuery()

  // TODO: Ideally this should be returned from the backend current project permissions scope
  const canSetTimeLogUser = !!permissionsQuery.data?.permissions.setTimeLogUser
  const canSetTimeLogBillable = !!permissionsQuery.data?.permissions.setTimeLogBillable
 
  const initialUsersAccount = props.initialValues?.usersAccount ? props.initialValues.usersAccount : currentUser

  const {
    formik,
    setNewFromDate,
    setNewToDate,
    setNewDuration,
    endsNextDay,
  } = useTimeLogFormik({
    initialValues: {
      duration: 0,
      from: date,
      to: date,
      ...props.initialValues,
      usersAccount: initialUsersAccount,
    },
    onSuccess: (newTimeLog) => {
      if (props.onSuccess) props.onSuccess(newTimeLog)
    }
  })

  useEffect(() => {
    if (props.date) {
      const date = moment(props.date).utc()

      formik.setFieldValue('from', date)
      formik.setFieldValue('to', date)
    }
  }, [props.date])

  const { defaultTimeFormat } = useDefaultTimeFormat()

  const projectPermissionsQuery = useCurrentProjectsPermissionsQuery({
    params: {
      projectId: formik.values.project?.id
    }
  })
 const isProjectBillableAtAll = !!formik.values.project?.billableEnabled

  const projectTimeLogIsBillableDefault = (!!formik.values.project?.billableEnabled && !!formik.values.project?.defaultIsBillableTimeLogs)

  useEffect(() => {
    if (formik.errors.date) {
      message.error(formik.errors.date)
    }

    if (formik.errors.base) {
      message.error(formik.errors.base)
    }

  }, [formik.errors])

  useEffect(() => {
    // Reset is billable after changing the project so latest project settings are reflected
    formik.values.isBillable = projectTimeLogIsBillableDefault
  }, [formik.values.project])

  return (
    <>
     <Form onSubmitCapture={(e) => {
        // TODO: Commented to avoid modal portals triggering main time log form submission
        // check if solvable by keeping this later :/
        // formik.submitForm()
      }}>
        <Card>
          <div style={{ display: 'flex', width: '100%', marginBottom: '15px' }}>
            <div style={{ width: formik.values.project ? '' : '100%' }} >
              <ProjectSelectPill
                disabled={trackerActive}
                value={formik.values.project}
                onChange={(project) => formik.setFieldValue('project', project)}
                onClear={() => {
                  formik.setFieldValue('project', undefined)
                  formik.setFieldValue('task', undefined)
                  formik.setFieldValue('description', undefined)
                }}
              />
              <ErrorMessageTooltip msg={formik.errors.project} />
            </div>

            {formik.values.project && (
              <div
                style={{
                  width: formik.values.task ? 'auto' : '100%',
                  maxWidth: formik.values.task ? '30%' : '100%',
                  // overflowX: 'hidden' : prevents error message display
                }}
              >
                <TaskSelectPill
                  disabled={trackerActive}
                  dropdownStyle={{ backgroundColor: '400px ' }}
                  project={formik.values.project}
                  defaultOpen={true}
                  value={formik.values.task}
                  onChange={(task) => formik.setFieldValue('task', task)}
                  onClear={() => {
                    formik.setFieldValue('task', undefined)
                    formik.setFieldValue('description', "")
                  }}
                />
                <ErrorMessageTooltip msg={formik.errors.task || formik.errors.taskId} />
              </div>
            )}
            {formik.values.project && formik.values.task && (
              <div style={{ width: '100%', marginLeft: '10px' }}>
                <Input
                  placeholder="Description"
                  disabled={trackerActive}
                  style={{ width: '100%' }}
                  allowClear={true}
                  autoFocus={true}
                  value={formik.values.description}
                  onChange={(e) => {
                    formik.setFieldValue('description', e.target.value)
                  }}
                />
                <ErrorMessageTooltip msg={formik.errors.description} />
              </div>
            )}
          </div>
          <Row justify="start" align="middle">
            {props.enableUsersAccountSelect ? (
              <Col span="4">
                <Tooltip 
                  trigger={['hover', 'click']}
                  mouseEnterDelay={0.5}
                  title={!canSetTimeLogUser ? 'You have no permissions to log time as other team members.' : undefined}
                >
                  <UsersAccountSelect
                    value={formik.values.usersAccount}
                    autoFocus={false}
                    allowClear={false}
                    disabled={!canSetTimeLogUser}
                    onChange={(newMember) => {
                      formik.setFieldValue('usersAccount', newMember)
                    }}
                  />
                  <ErrorMessageTooltip msg={formik.errors.usersAccount} />
                </Tooltip>
              </Col>

            ) : (
              <Col span="4" style={{ overflow: 'hidden' }}>
                <Tooltip
                  trigger={['hover', 'click']}
                  mouseEnterDelay={0.5}
                  title={canSetTimeLogUser ? 'You can not log time as other team member in the timer view. Visit timesheet to manage other team members time entries or reports to bulk update multiple entries at once.' : 'You have no permissions to manage other team member time entries.'}
                >
                  <div>
                    <DisabledInput
                      prefix={<UserOutlined style={{ color: '#ccc' }} />}
                      onKeyDown={(e) => e.preventDefault()}
                      disabled={true}
                      inputMode="search"
                      placeholder={getUsersAccountFullName(initialUsersAccount)}
                    />
                  </div>
                </Tooltip>
                {false && (
                  <Tag style={{ width: '100%' }} icon={<UserOutlined />}> {initialUsersAccount?.firstName} {initialUsersAccount?.lastName} </Tag>
                )}
              </Col>
            )}

            <DividerColl />

            <Col span="1" style={{ justifyContent: 'center', display: 'flex' }}>
              <TagsSelect
                disabled={trackerActive}
                // @ts-ignore
                value={formik.values.tags || []}
                onChange={(tags) => {
                  formik.setFieldValue('tags', tags)
                }}
              />
              <ErrorMessageTooltip msg={formik.errors.tags} />
            </Col>

            <DividerColl />

            <Col span="1" style={{ display: 'flex', justifyContent: 'center' }}>
              <BillableFlag
                checked={formik.values.isBillable}
                disabled={!canSetTimeLogBillable || !isProjectBillableAtAll}
                disabledText={
                  !!formik.values.project ? (
                    canSetTimeLogBillable ? "This project is not billable." : "You have no permissions to change the time log billable flag."
                  ) : "Select the project first to fetch it's billable flag settings."
                }
                onChange={() => formik.setFieldValue('isBillable', !formik.values.isBillable)}
              />
              <ErrorMessageTooltip msg={formik.errors.isBillable} />
            </Col>

            <DividerColl />

            {props.mode == 'manual' && (
              <>
                <Col span="2">
                  <DurationInput
                    value={formik.values.duration}
                    onChange={(duration) => {
                      setNewDuration(duration)
                    }}
                  />
                  <ErrorMessageTooltip msg={formik.errors.duration} />
                </Col>

                <DividerColl />

                <Col span="2">
                  <TimePicker
                    allowClear={false}
                    style={{ width: '100%' }}
                    value={formik.values.from}
                    onChange={(newVal) =>
                      setNewFromDate(newVal as Moment)
                    }
                    format={defaultTimeFormat}
                    suffixIcon={false}
                  />
                  <ErrorMessageTooltip msg={formik.errors.startAt} />
                </Col>

                <Col span={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  -
                </Col>

                <Col span="2">
                  <Badge
                    count={endsNextDay ? '+1 day' : undefined}
                  >
                    <TimePicker
                      allowClear={false}
                      style={{ width: '100%' }}
                      value={formik.values.to}
                      onChange={(newVal, newString) => {
                        setNewToDate(newVal as Moment)
                      }}
                      format={defaultTimeFormat}
                      suffixIcon={false}
                    />
                  </Badge>
                </Col>

                <DividerColl />

                <Col span="6">
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      loading={formik.isSubmitting}
                      type="primary"
                      style={{ width: '100%' }}
                      onClick={() => { formik.submitForm() }}
                    >
                      Log time
                    </Button>
                  </div>
                </Col>
              </>
              // END OF MANUAL MODE
            )}

            {props.mode == 'tracker' && (
              <>
                <Col span="2" style={{ display: 'flex', justifyContent: 'center' }}>
                  <DurationCounter
                    active={trackerActive}
                    startedAt={trackerActive ? trackerStartAt : currentTime}
                  />
                </Col>

                <DividerColl />

                <Col span="2" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Statistic
                    className="timer-period-text"
                    value={(trackerActive ? trackerStartAt : currentTime).format(defaultTimeFormat)}
                  />
                </Col>

                <Col span={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  -
                </Col>

                <Col span="2" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Statistic className="timer-period-text" value={currentTime.format(defaultTimeFormat)} />
                </Col>

                <DividerColl />

                <Col span="6">
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    {trackerActive ? (
                      <Button
                        loading={formik.isSubmitting || stopTrackerMutation.isLoading}
                        type="primary"
                        danger={true}
                        style={{
                          width: '100%',
                        }}
                        onClick={handleStopTracker}
                      >
                        Stop timer
                      </Button>
                    ) : (
                      <Button
                        loading={formik.isSubmitting || startTrackerMutation.isLoading}
                        type="primary"
                        style={{ width: '100%' }}
                        onClick={handleStartTracker}
                      >
                        Start timer
                      </Button>
                    )}
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Card>
      </Form>
    </>
  )
}

export default TimeLogForm

