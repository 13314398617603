import React, { useEffect } from 'react';
import { Button, Form, Input, Divider, Alert } from "antd";
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { GoogleSVG, FacebookSVG } from 'assets/svg/icon';
import CustomIcon from 'components/util-components/CustomIcon'

import { useHistory } from "react-router-dom";
import useApiClient from 'hooks/useApiClient';
import { useLoginFormik, useLoginSuccessRedirect } from 'hooks/auth.hooks';
import GoogleButton from './google-button';

export const LoginForm = () => {
  const history = useHistory()
  const apiClient = useApiClient()

  const loginSuccessRedirect = useLoginSuccessRedirect()

  const formik = useLoginFormik({
    onSuccess: (session) => {
      loginSuccessRedirect()
    }
  })

  const onGoogleLogin = () => {
    apiClient.loginWithGoogle()
  }

  const renderOtherSignIn = (
    <div>
      <Divider>
        <span className="text-muted font-size-base font-weight-normal">or sign in with</span>
      </Divider>
      <div className="d-flex justify-content-center">
        <GoogleButton
          onClick={() => onGoogleLogin()}
        >
          Sign in with Google
        </GoogleButton>
      </div>
    </div>
  )

  return (
    <>
      <Form 
        layout="vertical" 
        name="login-form"
        onSubmitCapture={formik.handleSubmit}
      >
        <Form.Item 
          name="email" 
          label="Email" 
        >
          <Input 
            autoFocus={true}
            onChange={formik.handleChange('email')}
            prefix={<MailOutlined className="text-primary" style={{ marginRight: '5px' }} />}
          />
        </Form.Item>
        <Form.Item 
          name="password" 
          label="Password"
        >
          <Input.Password 
            style={{
            }}
            onChange={formik.handleChange('password')}
            prefix={<LockOutlined className="text-primary" style={{ marginRight: '5px' }} />}
          />
        </Form.Item>

        <Form.Item>
          <Button 
            type="primary" 
            htmlType="submit" 
            block
            loading={formik.isSubmitting}
          >
            Sign In
          </Button>

          <p
            style={{
              marginTop: '10px',
                marginBottom: '0px',
                paddingBottom: '0px',
            }}
          >Forgot your password?{" "}
            <a
              onClick={() => {
                history.push('/auth/reset-password')
              }}
            >
              Reset password
            </a>
          </p>

        </Form.Item>
        {
          renderOtherSignIn
        }
      </Form>
    </>
  )
}

LoginForm.propTypes = {
  otherSignIn: PropTypes.bool,
  showForgetPassword: PropTypes.bool,
  extra: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
};

LoginForm.defaultProps = {
  otherSignIn: true,
  showForgetPassword: false
};

export default LoginForm