import Duration from 'utils/duration'
import DashboardProgressCard from './dashboard-progress-card';
import { useDefaultCurrencyIso } from 'hooks/settings';
import { TApiProject } from 'services/api-client/types';

export const ProjectBudgetCard = ({ projectInsights, project }: { projectInsights: any, project: TApiProject }): JSX.Element => {

  // @ts-ignore
  const billableAmount = parseFloat(projectInsights.totals.billableAmount)
  const costAmount = parseFloat(projectInsights.totals.costAmount)
  const duration = projectInsights.totals.duration
  const profitAmount = billableAmount - costAmount

  const budgetEnabled = project.budgetEnabled
  const budgetType = project.budgetType
  const budgetMoney = project.budgetMoney
  const budgetTime = project.budgetTime

  const { defaultCurrencyIso } = useDefaultCurrencyIso()

  if (budgetEnabled && budgetType == 'money') {
    const totalBudget = budgetMoney as number
    const budgetSpent = billableAmount
    const budgetUtilisationPercent = budgetSpent / totalBudget * 100
    const budgetUtilisationPercentString = totalBudget ? `(${(budgetUtilisationPercent).toFixed(2)}%)` : ''

    return (
      <DashboardProgressCard
        title="BILLABLE BUDGET"
        percent={budgetUtilisationPercent}
        description1={`${budgetSpent} ${defaultCurrencyIso} of  ${totalBudget} ${defaultCurrencyIso} ${budgetUtilisationPercentString}`} />
    );
  }

  if (budgetEnabled && budgetType == 'time') {
    const totalBudget = (budgetTime as number * 60) // TODO: get budget in hours?
    const budgetSpent = duration

    const budgetUtilisationPercent = budgetSpent / totalBudget * 100
    const budgetUtilisationPercentString = totalBudget ? `(${(budgetUtilisationPercent).toFixed(2)}%)` : ''

    // @ts-ignore
    const totalBudgetString = new Duration(totalBudget).toDurationString()
    // @ts-ignore
    const budgetSpentString = new Duration(budgetSpent).toDurationString()

    return (
      <DashboardProgressCard
        title="TIME BUDGET"
        percent={budgetUtilisationPercent}
        description1={`${budgetSpentString} of ${totalBudgetString} ${budgetUtilisationPercentString}`} />
    );
  }

  return (
    <DashboardProgressCard
      title="BUDGET"
      percent={0}
      description1="Project has no time nor billable budget." />
  )
}

export default ProjectBudgetCard