
export const COLORS = {
  primaryGreen: '#07C5B7',
  primaryPurple: '#3A3AA3',
  black: '#474D55',
  //backgroundGrey: '#F9F9F9'
  backgroundGrey: '#fff',
  linkGray: 'gray',
  editableHighlightGray: '#efefef',
}

const TimenotesTheme = {
  colors: COLORS,
}

export default TimenotesTheme