import { CreditCardOutlined, DeleteOutlined } from "@ant-design/icons"
import { Button, Card, Divider, Modal, Skeleton, message } from "antd"
import CardWrapper from "components/layout/card-wrapper"
import CreditCardForm from "pages/settings/components/credit-card-form"
import { useApiCall, useGetApiCall } from "hooks/useApiCall"
import { useState } from "react"

import VISA_IMG from 'assets/img/cards/icon_visa.png'
import MASTERCARD_IMG from 'assets/img/cards/icon_mastercard.png'
import AMERICAN_EXPRESS_IMG from 'assets/img/cards/icon_american_express.png'
import styled from "styled-components"
import { useBillingInfo } from "hooks/billing-info"
import { TApiBillingInfo } from "services/api-client/billing-infos-api"
import useApiClient from "hooks/useApiClient"
import BillingInfoForm from "pages/settings/components/billing-info-form"

const BillingPaymentMethodSection = () => {
  const apiClient = useApiClient()

  const {
    data: paymentMethod,
    loading: paymentMethodLoading,
    request: fetchPaymentMethod,
  } = useGetApiCall(async () => (((await apiClient.getPaymentMethod()).paymentMethod)))

  const { 
    billingInfo,
    loading: billingInfoLoading,
    fetchBillingInfo,
   } = useBillingInfo()

  const {
    loading: deleteLoading,
    request: deletePaymentMethod,
  } = useApiCall(async () => (((await apiClient.deletePaymentMethod()))))

  const [addVisible, setAddVisible] = useState(false)
  const [billingInfoVisible, setBillingInfoVisible] = useState(false)

  const loading = billingInfoLoading || paymentMethodLoading

  const handleAddPaymentMethod = () => {
    const isBillingInfoPresent = !!billingInfo

    if (isBillingInfoPresent) {
      setAddVisible(true)
    } else {
      setBillingInfoVisible(true)
    }
  }

  const handleBillingInfoUpdate = async (newBillingInfo: TApiBillingInfo) => {
    const response = await apiClient.updateBillingInfo(newBillingInfo)

    if (response.ok) {
      await fetchBillingInfo()
      setBillingInfoVisible(false)
      setAddVisible(true)
    }

    return response
  }

  const handleCreate = async () => {
    await fetchPaymentMethod()
    setAddVisible(false)
    message.success('Credit card updated successfully!')
  }

  const handleDelete = async () => {
    Modal.confirm({
      title: "Are you sure you want to delete the payment method?",
      onOk: async () => {
        const response = await deletePaymentMethod()
        if (response.ok) message.success("Payment method deleted successfuly!")
        fetchPaymentMethod()
      }
    })
 }

  return (
    <CardWrapper size="small">
      <h2>Payment method</h2>

      <Divider />

      { loading ? (
        <CreditCardWrapper>
          <Skeleton.Avatar active />
          <Skeleton active paragraph={{ rows: 1 }}/>
        </CreditCardWrapper>
      ) : (
        <div>
          {paymentMethod ? (
            <CreditCardView
              cardType={paymentMethod.details?.cardType}
              lastDigits={paymentMethod.details?.lastFour || ""}
              expireMonth={paymentMethod.details?.expirationMonth || ""}
              expireYear={paymentMethod.details?.expirationYear || ""}
            />
          ) : (
            <div style={{ opacity: 0.5, filter: 'blur(1px)'}}>
              <CreditCardView
                lastDigits="XXXX"
                expireMonth="XX"
                expireYear="XX"
              />
            </div>
          )}
        </div>
      )}

      <div style={{
        display: 'flex',
        gap: '10px',
      }}>
        <Button
          type="primary"
          icon={<CreditCardOutlined />}
          onClick={handleAddPaymentMethod}
        >
          { !paymentMethod ? 'Add credit card' : 'Update credit card' }
        </Button>

        { paymentMethod && (
          <Button 
            type="ghost" 
            icon={<DeleteOutlined />}
            onClick={handleDelete}
            loading={deleteLoading}
          >
            Remove
          </Button>
        )}
      </div>

      <Modal
        visible={addVisible}
        onCancel={() => {
          setAddVisible(false)
        }}
        footer={false}
        title="Update credit card"
        destroyOnClose={true}
      >
        <CreditCardForm 
          onSubmit={handleCreate}
          onCancel={() => setAddVisible(false)}
        />
      </Modal>

      <Modal
        title="Please add billing info first"
        onCancel={() => setBillingInfoVisible(false) }
        visible={billingInfoVisible}
        footer={false}
        destroyOnClose={true}
        >
          <p> For a tax reasons, we need you to provide the billing information before linking the payment method to your workspace account. </p>
          <Divider />
          <BillingInfoForm
            initialValues={billingInfo}
            onSubmit={handleBillingInfoUpdate}
          />
      </Modal>
    </CardWrapper>
  )
}

const getCardImage = (schemeName: string) => {
  if (schemeName == 'Visa') return VISA_IMG
  if (schemeName == 'MasterCard') return MASTERCARD_IMG
  if (schemeName == 'American Express') return AMERICAN_EXPRESS_IMG

  return ""
}

const CreditCardWrapper = styled(Card)`
  height: 188px;
  padding: 10px;
  padding-top: 0px;
  box-shadow: 0 0 10px 0 rgba(35,36,39,0.15);
`

const CreditCardView = ({
  cardType,
  lastDigits,
  expireMonth,
  expireYear,
}: {
  cardType?: string,
  lastDigits: string | number
  expireMonth: string | number
  expireYear: string | number
}) => {

  return (
    <CreditCardWrapper>
      <div>
        {cardType? (
          <img src={getCardImage(cardType)} height="60px"/>
        ) : (
          <CreditCardOutlined style={{ fontSize: '25px' }} />
        )}
      </div>
      <div style={{
        fontSize: '20px'
      }}>
        <span>
          **** **** **** {lastDigits}
        </span>
      </div>

      <div>
        <p style={{
          marginTop: '10px',
          marginBottom: '0px',
        }}>
          Expires
        </p>
        <span>
          {expireMonth}/{expireYear}
        </span>
      </div>
    </CreditCardWrapper>
  )

}

export default BillingPaymentMethodSection