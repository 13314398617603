import { useEffect, useRef, useState } from "react"


// When you use CSS webkit-line-clamp: 2,3,.. or any other 
// form of overflow, it will return isTrimmed true
// if overflow actually happens
export const useLineClampTrimmed = () => {
  const [isTrimmed, setIsTrimmed] = useState(false)
  const elRef = useRef<any>(null)

  useEffect(() => {
    if (elRef.current) {
      const el = elRef.current
      if (el.scrollHeight > el.clientHeight) {
        setIsTrimmed(true)
      } 
    }
  }, [elRef.current])

  return {
    elRef,
    isTrimmed,
  }
}