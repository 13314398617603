import { getApiErrorMessage } from '@timenotes/shared/src/services/api-client';
import { IUpdateTaskResponse } from '@timenotes/shared/src/services/api-client/tasks-api';
import { TApiResponse, TApiTask } from '@timenotes/shared/src/services/api-client/types';
import InPlaceTextEditor from 'components/shared/in-place-text-editor'

export default function TaskNameColumn({ task, onChange }: { task: TApiTask; onChange(newValue: string): Promise<IUpdateTaskResponse | TApiResponse>; }) {

  return (
    <InPlaceTextEditor
      value={task.name}
      onChange={(newName) => {
        return onChange(newName).then((response) => {
          return {
            ok: response.ok,
            errorMessage: getApiErrorMessage(response, 'name')
          };
        });
      }}
    >
      <span>
        {task.name}
      </span>
    </InPlaceTextEditor>
  );
};
