import { message } from "antd"
import { FormikBag } from "formik"
import { useEffect } from "react"

  
const useFormikGenericErrorMessage = (formik: any) => {
  const errors: any = formik.errors

  // Generic error handling!
  useEffect(() => {
    if (errors && Object.values(errors).length > 0) {
      message.error(Object.values(errors)[0])
    }
  }, [errors])
}

export default useFormikGenericErrorMessage