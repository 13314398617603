import { Card, Divider, Skeleton, Spin } from "antd"
import CardWrapper from "components/layout/card-wrapper"
import BillingHistoryTable from "pages/settings/components/billing-history-table"
import { useCurrentUser } from "hooks/current-user"
import { useDefaultDateFormat } from "hooks/settings"
import { useGetApiCall } from "hooks/useApiCall"
import moment from "moment-timezone"
import { toAmountWithCurrency } from "utils/string-utils"
import useApiClient from "hooks/useApiClient"

const BillingHistorySection = () => {
  const apiClient = useApiClient()
  const {
    data: periods,
    loading: periodsLoading,
    request: fetchPeriods,
  } = useGetApiCall(async () => (await apiClient.getSubscriptionPeriods()).subscriptionPeriods)

  const {
    data: currentSubscription,
    loading: currentSubscriptionLoading,
    request: fetchCurrentSubscription,
  } = useGetApiCall(async () => (await apiClient.getSubscriptionPeriodsCurrent()).subscriptionPeriod)

  const { currentUser } = useCurrentUser()
  const { defaultDateFormat } = useDefaultDateFormat()

  const timenotesPlanCurrency = 'USD'

  const loading = periodsLoading || currentSubscriptionLoading

  const expireDateString = moment(currentSubscription?.expiresAt).format(defaultDateFormat)

  // @ts-ignore
  const upcomingAmount = currentSubscription?.upcomingAmount || "" as string

  const chargeText = currentSubscription?.trial ? 
    <span>Your trial will end on {expireDateString}.</span> :
    <span>You will be charged <b>{toAmountWithCurrency(upcomingAmount, timenotesPlanCurrency)}</b> on <b>{expireDateString}</b></span>

  return (
    <CardWrapper size="big">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
      <h2>Payment history</h2>
      <div
        style={{
          textAlign: 'right',
        }}
      >
        { loading ? (
          <Spin />
        ) : (
            <>
              <div>
                Current plan: {currentSubscription?.plan.name}
              </div>
              <div>
                Timenotes credits:{" "}
                {toAmountWithCurrency(currentUser?.account?.discountCredits as string, timenotesPlanCurrency)}
              </div>
              <div>
                {chargeText}
              </div>
           </>
          )}
        </div>
      </div>
      <Divider />

      { loading ? (
        <Card>
          <Skeleton />
        </Card>
      ) : (
        <BillingHistoryTable 
          dataSource={periods || []}
        />
      )}
   </CardWrapper>
  )
}

export default BillingHistorySection