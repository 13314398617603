import { Button, Card, Divider, Skeleton } from "antd"
import BillingInfoForm, { BillingInfoFormProps } from "pages/settings/components/billing-info-form"
import { useBillingInfo } from "hooks/billing-info"
import { useApiCall } from "hooks/useApiCall"
import useApiClient from "hooks/useApiClient"
import { useEffect } from "react"
import ReactJson from "react-json-view"
import { TApiBillingInfo } from "services/api-client/billing-infos-api"

export default function BillingInfoSection() {
  const { billingInfo, fetchBillingInfo, loading } = useBillingInfo()
  const apiClient = useApiClient()

  const handleUpdate = async (newBillingInfo: TApiBillingInfo) => {
    const response = await apiClient.updateBillingInfo(newBillingInfo)

    if (response.ok) {
      await fetchBillingInfo()
    }

    return response
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Card
        style={{
          width: '400px',
        }}
      >
        <h2>Billing info</h2>

        <Divider />

        {!loading ? (
          <>
            <BillingInfoForm
              initialValues={billingInfo}
              onSubmit={handleUpdate}
            />
          </>
        ) : (
          <>
            <Skeleton loading={true} />
            <Skeleton loading={true} />
            <Skeleton loading={true} />
          </>
        )}
      </Card>
    </div>
  )
}