import { UseQueryOptions, useQuery } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiParams } from "../../api-client/types"
import { TApiGenericError } from "../../timenotes-query/timenotes-query.types"
import moment from "moment-timezone"
import React, { useEffect, useMemo, useState } from "react"
import { map, omitBy } from "lodash"
import { TApiApprovalRequest, TApiApprovalRequestStatus, TApiPeriod, TApiPeriodRaw, TApiPeriodStatus } from "../approvals-types"
import useApprovalRequestModel from "../hooks/use-approval-request-model"
import { DEFAULT_APPROVAL_REQUESTS_GROUPS } from "../approvals-consts"
import { removeUndefinedValues } from "../../utils/remove-values"

interface Params extends TApiParams {
  // NOT CREATED INTERNALLY AS IT CAN NOT BE DETERMINED AS INTERNAL DEFAULT ANYWAY, NEVER
  // SO THAT IT IS NOT INTERNALLY CONTROLLED FIELD,
  periodId?: string
  filters?: {
    status?: TApiApprovalRequestStatus
  }
}

interface Result {
  approvalRequestGroups: {
    [key in 'open' | 'approved' | 'pending' | 'rejected']: TApiApprovalRequest[]
  }
}

export const useApprovalRequestsGroupsQuery = (params: Params, options?: UseQueryOptions<any, any>) => {
  const apiClient = useApiClient()

  const {
    parseApprovalRequest,
  } = useApprovalRequestModel()

  const paramsFilters = useMemo(() => params.filters, [params.filters])
  const [filters,  setFilters] = useState<Params["filters"]>(paramsFilters)

  useEffect(() => {
    setFilters(paramsFilters)
  }, [JSON.stringify(paramsFilters)])

  const requestParams = removeUndefinedValues({
    id: params.periodId,
    perPage: params.perPage || 1000,
    filters: filters
  })

  const query = useQuery<Result, TApiGenericError<any>>(
    ['approval-request-groups', (requestParams || {})] as const,
    async () => {
      const response = await apiClient.get(`/approvals/periods/${requestParams.id}/requests`, requestParams)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        approvalRequestGroups: response // TODO: add parsing!
      }
    },
    {
      ...options,
      enabled: options?.enabled !== undefined ? options?.enabled : !!params.periodId
    }
  )

  const approvalRequestGroups = {
    ...DEFAULT_APPROVAL_REQUESTS_GROUPS,
    ...query.data?.approvalRequestGroups,
  }

  return {
    approvalRequestGroupsQuery: query,
    approvalRequestGroups,
    approvalRequestGroupsFilters: filters,
    setApprovalRequestGroupsFilters: setFilters,
  }
}