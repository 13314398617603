import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"
import { Button, Divider, Spin } from "antd"
import { useQueryParams } from "hooks/app-router"
import { useLoginSuccessRedirect } from "hooks/auth.hooks"
import { useApiCall } from "hooks/useApiCall"
import useApiClient from "hooks/useApiClient"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import AuthLayout from "views/auth-views/components/auth-layout"

const ConfirmEmailPage = () => {
  const history = useHistory()
  const apiClient = useApiClient()
  const loginSuccessRedirect = useLoginSuccessRedirect()

  const queryParams = useQueryParams<{
    confirmation_token: string
    access_account_id: string
  }>()

  const confirmationToken = queryParams.confirmation_token
  const accountId = queryParams.access_account_id

  if (accountId) {
    apiClient.setAccessCredentials({ accountId: accountId })
  }

  const {
    request: confirmEmail,
    data: response,
    loading
  } = useApiCall(async (confirmationToken: string) => {
    return await apiClient.confirmEmail({ confirmationToken })
  })

  useEffect(() => {
    if (confirmationToken && accountId) {
      confirmEmail(confirmationToken).then((response) => {
        if (response.ok) {
          apiClient.setAccessCredentials({
            accessToken: response.session.token,
          })
          loginSuccessRedirect()
        }
      })
    }
  }, [])

  return (
    <AuthLayout>
      { (confirmationToken && accountId) ? (
        <>
        { (loading || response === undefined) ? (
          <Spin />
        ) : (
          <>
            { response && response.ok ? (
              <div>
                <h1> Email confirmed!</h1>
                <Divider />
                <Button
                  type="primary"
                  onClick={() => {
                    history.push('/app')
                  }}
                >
                  Sign in to your timenotes account!
                </Button>
              </div>
            ) : (
              <div>
                <h2>
                  {getApiErrorMessage(response, 'confirmationToken')}
                </h2>
                <Divider />
                <Button
                  onClick={() => {
                    history.push('/auth/login')
                  }}
                >
                  Go back to Timenotes
                </Button>
              </div>
            )}
          </>
        )}
        </>
      ) : (
        <span>...</span>
      )}
    </AuthLayout>
  )

}

export default ConfirmEmailPage