import { TApiResponse, TApiParams, TApiTag } from "./types"
import BaseApi, { getQueryString, parameterizeArray } from './base'

export type TApiTagsFilters = {
  statuses?: Array<string>
}

export interface ICreateTagParams {
  name: string
  color: string
}

export interface ICreateTagResponse extends TApiResponse {
  tag: TApiTag
}

export interface IGetTagsParams extends TApiParams {
  filters?: TApiTagsFilters
}

export interface IGetTagsResponse extends TApiResponse {
  tags: Array<TApiTag>
}

class TagsApi extends BaseApi {
  async getTags (params?: IGetTagsParams): Promise<IGetTagsResponse> {
    const requestParams = { ...params, 
      perPage: params?.perPage || 1000,
    }

    return this.get('/tags', requestParams)
  }

  async createTag (params: ICreateTagParams): Promise<ICreateTagResponse> {
    return this.post('/tags', params)
  }

  async updateTag (tagId: string, params: ICreateTagParams) {
    return this.patch(`/tags/${tagId}`, params)
  }

  async archiveTag(tagId: string) {
    return this.patch(`/tags/${tagId}/`, {
      status: 'archived'
    })
  }

  async unarchiveTag(tagId: string) {
    return this.patch(`/tags/${tagId}/`, {
      status: 'active'
    })
  }

  async deleteTag (tagId: string) {
    return this.delete(`/tags/${tagId}`)
 }
}

export default TagsApi