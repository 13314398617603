import { Skeleton } from 'components/base'

const LoadingSkeleton = (): JSX.Element => (
  <>
    <Skeleton active={true} />
    <Skeleton active={true} />
    <Skeleton active={true} />
  </>
)

export default LoadingSkeleton