import { Select, SelectProps } from "antd"
import AbsenceIcon from "components/base/absence-icon"
import { useAbsenceTypes } from "hooks/absences"
import useNoInitialEffect from "hooks/useNoInitialEffect"
import { find, map } from "lodash"
import { TApiAbsenceType } from "services/api-client/types"
import styled from "styled-components"

interface AbsenceTypeSelectProps extends Omit<SelectProps<string>, 'onChange'> {
  value?: string
  onChange(value: string, object: TApiAbsenceType): void
  initialOption?: TApiAbsenceType
  autoSelectFirstOption?: boolean
}

const AbsenceTypeSelect = (props: AbsenceTypeSelectProps) => {
  const { absenceTypes } = useAbsenceTypes()

  useNoInitialEffect(() => {
    if (props.autoSelectFirstOption && props.value === undefined && absenceTypes.length > 0) {
      props.onChange(absenceTypes[0].id, absenceTypes[0])
    }
  }, [absenceTypes])

  const options = map(absenceTypes, (absenceType) => {
    return {
      label: (
        <div style={{ display: 'flex', alignItems: 'center', color: absenceType.color}}><AbsenceIcon style={{ marginRight: '8px' }} icon={absenceType.icon} />{absenceType.name}</div>
      ),
      name: absenceType.name,
      value: absenceType.id,
    }
  })

  const handleOnChange = (value: string) => {
    const absenceType: TApiAbsenceType = find(absenceTypes, (absenceType) => { return absenceType.id == value }) as TApiAbsenceType
    props.onChange(value, absenceType)
  }

  return (
    <Wrapper {...(props.style ? { style: props.style } : {})}>
      <Select
        {...props}
        showSearch={true}
        optionFilterProp="name"
        placeholder="Absence Type"
        style={{ width: '100%' }}
        value={props.value}
        options={((options.length == 0 && props.initialOption ) ? [{ label: props.initialOption.name, value: props.initialOption.id}]: options)}
        onChange={handleOnChange}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .ant-select-selector { }
  
`

export default AbsenceTypeSelect