import { Card, Col, Divider, message, Row, Spin } from "antd"
import { backgroundStyle } from "./styles"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"

const AutoRegisterPage = () => {
  const theme = 'light'
  const history = useHistory()

  useEffect(() => {
    window.localStorage.setItem('autoLoginType', 'extension')
    history.push('/auth/register')
  }, [])

  const LoadingView = () => (
    <Spin size="large" />
  )

  return (
    <div className="h-100" style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card>
              <div className="my-2">
                <div className="text-center">
                  <img className="img-fluid" src={`/img/${theme === 'light' ? 'logo.png': 'logo-white.png'}`} alt="" />
                </div>
                <Row justify="center">
                  <LoadingView />
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AutoRegisterPage