import React from 'react'
import { Button, Dropdown, Menu, Space } from 'antd'
import { DownloadIcon } from 'components/base/icons'

const TimesheetExportDropdown = (props: {
  onClick(exportType: 'csv' | 'xls' | 'pdf'): void;
}) => {
  const menu = (
    <Menu
      onClick={(a) => {
        props.onClick(a.key as 'csv' | 'xls' | 'pdf');
      }}
    >
      <Menu.Item key="csv" onClick={() => { }}>CSV</Menu.Item>
      <Menu.Item key="xls" onClick={() => { }}>XLS</Menu.Item>
      <Menu.Item key="pdf" onClick={() => { }}>PDF</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button type="primary">
        <Space>
          <DownloadIcon />
          Export
        </Space>
      </Button>
    </Dropdown>
  )
}

export default TimesheetExportDropdown