import { Button, Form, Input, Checkbox, Divider } from "antd"
import { useFormik } from "formik"
import ErrorMessage from "components/base/error-message"
import styled from "styled-components"
import { TApiResponse, TApiUsersAccount } from "@timenotes/shared/src/services/api-client/types"
import { useCurrentUser } from "hooks/current-user"
import { usePagePermissions } from "hooks/permissions.hooks"
import SettingsCheckbox from "./settings-checkbox"
import { useSettings } from "hooks/settings"

export interface IFormObject extends Partial<TApiUsersAccount> {
  email: string
}

export interface ProfileFormProps {
  initialValues: IFormObject
  onSubmit(values: IFormObject): Promise<TApiResponse>
}

const ProfileForm = (props: ProfileFormProps) => {

  const { settings } = useSettings()
  const { permissions } = usePagePermissions()

  const canManageAbsenceRequests = !!permissions?.manageAbsenceRequests
  const canManageApprovals = !!permissions?.manageApprovals
  const canManageUsers = !!permissions?.manageMembersGroups

  const initialValues = {
    ...props.initialValues,
    email: props.initialValues.user?.email,
  }
  const formik = useFormik<IFormObject>({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values, formik) => {

      let body = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        adminReportsEnabled: values.adminReportsEnabled,
        adminHolidaysRequests: values.adminHolidaysRequests,
        adminApprovalsRequests: values.adminApprovalsRequests,
        reportsEnabled: values.reportsEnabled,
        projectAssignmentNotification: values.projectAssignmentNotification,
      }

      const response = await props.onSubmit(body)

      if (!response.ok && response.errors) {
        formik.setErrors(response.errors)
      }

      if (response.ok) {
        formik.resetForm({ values })
      }
    }
  })

  return (
    <Form onSubmitCapture={formik.handleSubmit} layout="vertical">
      <h2>General</h2>

      <Divider />

      <Form.Item
        label="First Name"
      >
        <Input
          value={formik.values.firstName}
          onChange={formik.handleChange('firstName')}
          autoFocus={true}
        />
        <ErrorMessage msg={formik.errors.firstName} />
      </Form.Item>

      <Form.Item
        label="Last Name"
      >
        <Input
          value={formik.values.lastName}
          onChange={formik.handleChange('lastName')}
        />
        <ErrorMessage msg={formik.errors.lastName} />
      </Form.Item>

      <Form.Item
        label="Email"
      >
        <Input
          value={formik.values.email}
          onChange={formik.handleChange('email')}
        />
        <ErrorMessage msg={formik.errors.user?.email || formik.errors.email} />
      </Form.Item>

      <h2>Email Notifications</h2>

      <Divider />

      <CheckboxWrapper>
        <Checkbox
          checked={formik.values.projectAssignmentNotification}
          onChange={(e) => { formik.setFieldValue('projectAssignmentNotification', e.target.checked)}}
        >
          Receive project assignment notifications?
        </Checkbox>
        <ErrorMessage msg={formik.errors.projectAssignmentNotification} />
      </CheckboxWrapper>

      <CheckboxWrapper>
        <Checkbox
          checked={formik.values.reportsEnabled}
          onChange={(e) => { formik.setFieldValue('reportsEnabled', e.target.checked)}}
        >
          Receive personal weekly timesheet summaries?
        </Checkbox>
        <ErrorMessage msg={formik.errors.reportsEnabled} />
      </CheckboxWrapper>

      { canManageUsers && (
        <CheckboxWrapper>
          <Checkbox
            checked={formik.values.adminReportsEnabled}
            onChange={(e) => { formik.setFieldValue('adminReportsEnabled', e.target.checked) }}
          >
            Receive team weekly timesheet summaries?
          </Checkbox>
          <ErrorMessage msg={formik.errors.adminReportsEnabled} />
        </CheckboxWrapper>
      )}

      { canManageAbsenceRequests && (
        <CheckboxWrapper>
          <Checkbox
            checked={formik.values.adminHolidaysRequests}
            onChange={(e) => { formik.setFieldValue('adminHolidaysRequests', e.target.checked) }}
          >
            Receive team absence requests notifications?
          </Checkbox>
          <ErrorMessage msg={formik.errors.adminHolidaysRequests} />
        </CheckboxWrapper>
      )}

      { canManageApprovals && settings?.approvalsEnabled && (
        <>
          <SettingsCheckbox
            title="Receive team timesheet approvals notifications?"
            checked={formik.values.adminApprovalsRequests}
            onChange={(e) => formik.setFieldValue('adminApprovalsRequests', e.target.checked)}
          />
          <ErrorMessage msg={formik.errors.adminApprovalsRequests} />
        </>
      )}

      <br />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type={formik.dirty ? 'primary' : 'default'}
          disabled={!formik.dirty}
          loading={formik.isSubmitting}
          onClick={() => {
            formik.submitForm()
          }}
        >
          {formik.values.id ? "Update" : "Create"}
        </Button>
      </div>

    </Form>
  )
}

const CheckboxWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`

export default ProfileForm