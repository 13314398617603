import { Button, Card, Col, Divider, message, Row, Spin } from "antd"
import { backgroundStyle } from "./styles"
import PageLoadingSpin from "components/layout/page-loading-spin"
import useApiClient from "hooks/useApiClient"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useCurrentUserQuery } from "@timenotes/shared/src/services/users-accounts/queries/use-current-user-query"

const AutoLoginPage = () => {
  const theme = 'light'
  const history = useHistory()

  const apiClient = useApiClient()
  apiClient.loadAccessCredentials()

  // In order to ensure it is not only loaded to the local storage
  // but also the tokens are actually valid ones and works with the API 
  // NOT needed as we will validate access token and account id via request
  //const isAuthenticated = (apiClient.accessToken && apiClient.accountId) 
  const { currentUserQuery } = useCurrentUserQuery({
    onSuccess: () => {
      // message.success('Current user exists and access token is correct')
      window.postMessage({
        type: "AUTO_LOGIN",
        data: {
          accessToken: apiClient.accessToken,
          accountId: apiClient.accountId
        }
      }, "*");

      // Clear auto login type if exists
      window.localStorage.removeItem('autoLoginType')
    },
    onError: () => {
      //message.error('Current user unable to fetch, access token is incorrect')

      // Mark the session storage so later when user logs in inside this browser session
      // he will be redirected to extension message instead of logging in in the web app
      window.localStorage.setItem('autoLoginType', 'extension')
      history.push('/auth/login')
    }
  })

  const LoadingView = () => (
    <Spin size="large" />
  )

  const SuccessView = () => (<>
    <h1>
      You have successfully logged in!
    </h1>
    <p>
      You can close this window and get back to the application.
    </p>
    <Divider />
    <p>
      <Button 
        type="primary"
        onClick={() => {
          history.push('/timer')
        }}
      >
        Continue in Timenotes
      </Button>
    </p>
  </>)

  return (
    <div className="h-100" style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row justify="center">
          <Col xs={24} sm={22} md={20} lg={13}>
            <Card>
              <div className="my-2">
                <div className="text-center">
                  <img className="img-fluid" src={`/img/${theme === 'light' ? 'logo.png': 'logo-white.png'}`} alt="" />
                </div>
                <Row justify="center">
                  { currentUserQuery.isFetched ? <SuccessView/> : <LoadingView />}
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AutoLoginPage