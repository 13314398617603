import { Select } from "antd"
import React from "react"

interface BillableFilterProps extends React.ComponentProps<typeof Select> { }

const BillableFilter = (props: BillableFilterProps) => {
  return (
    <Select 
      placeholder="Billable & Non-billable" 
      style={{ width: '100%' }}
      {...props}
    >
      <Select.Option value="all">Billable & Non-billable</Select.Option>
      <Select.Option value="billable">Only billable</Select.Option>
      <Select.Option value="non-billable">Only non-billable</Select.Option>
    </Select>
  )
}

export default BillableFilter