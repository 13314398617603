import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"
import { Alert, message, Form, Input, Button, Checkbox} from "antd"
import ErrorMessage from "components/base/error-message"
import { SITE_URL } from "consts"
import { useFormik } from "formik"
import {useQueryParams} from "hooks/app-router"
import { useLoginSuccessRedirect } from "hooks/auth.hooks"
import { useConfirmInvitation } from "hooks/confirm-invitations"
import {useApiCall} from "hooks/useApiCall"
import useApiClient from "hooks/useApiClient"
import {useEffect} from "react"
import { useHistory } from "react-router-dom"
import AuthLayout from "views/auth-views/components/auth-layout"

const ConfirmInvitationPage = () => {
  const history = useHistory()
  const apiClient = useApiClient()
  const loginSuccessRedirect = useLoginSuccessRedirect()

  const queryParams = useQueryParams<{
    invitationToken: string
  }>()

  const invitationToken = queryParams.invitationToken

  const {
    request: peekInvitation,
    data: response,
    loading
  } = useApiCall(async (invitationToken: string) => {
    const response = await apiClient.peekInvitation({ token: invitationToken })
    return response
  })

  const peekedInvitation = response?.peekedInvitation

  useEffect(() => {
    peekInvitation(invitationToken as string).then((response) => {
      if (!response.ok) {
        message.error("Invalid invitation!")
        history.push('/auth/login')
      }
    })
  }, [])

  const {
    confirmInvitation,
    confirmInvitationLoading,
  } = useConfirmInvitation()

  const formik = useFormik<{
    firstName: string
    lastName: string
    termsChecked: boolean
    password: string
  }>({
    initialValues: {
      firstName: peekedInvitation?.firstName || "",
      lastName: peekedInvitation?.lastName || "",
      termsChecked: false,
      password: '',
    },
    onSubmit: async (values, formik) => {
      const response = await confirmInvitation({
        firstName: values.firstName,
        lastName: values.lastName,
        token: invitationToken as string,
        password: values.password,
      })

      if (response.ok) {
        message.success('Invitation accepted!')

        apiClient.removeAccessCredentials()
        apiClient.setAccessCredentials({ 
          accessToken: response.session.token 
        })

        setTimeout(() => {
          loginSuccessRedirect()
        }, 200)

      } else {
        formik.setErrors(response.errors || {})

        if (response.errors?.base) {
          message.error(getApiErrorMessage(response, 'base'))
        }
      }
    }
  })


  if (!invitationToken) {
    message.error("Invalid invitation!")
    history.push("/auth/login")
  }

  const isLoading = !response?.ok || loading

  return (
    <AuthLayout loading={isLoading}>
      {peekedInvitation && (
        <Alert
          style={{
            marginBottom: '30px',
          }}
          message={`Accept the invitation for ${peekedInvitation.email} to workspace "${peekedInvitation.accountName}"`}
        />
      )}

      <Form onSubmitCapture={formik.handleSubmit} layout="vertical">

        <Form.Item label="Email">
          <Input 
            disabled={true}
            value={peekedInvitation?.email}
          />
        </Form.Item>


        <Form.Item label="First name">
          <Input 
            autoFocus={true}
            value={formik.values.firstName}
            onChange={formik.handleChange('firstName')}
          />
          <ErrorMessage msg={formik.errors?.firstName} />
        </Form.Item>

        <Form.Item label="Last name">
          <Input 
            value={formik.values.lastName}
            onChange={formik.handleChange('lastName')}
          />
          <ErrorMessage msg={formik.errors?.lastName} />
        </Form.Item>

        <Form.Item label="Password">
          <Input 
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange('password')}
          />
          <ErrorMessage msg={formik.errors?.password} />
        </Form.Item>


        <Form.Item
          name="termsOfService"
          label="Terms of service"
        >
          <Checkbox
            onChange={(e) => {
              formik.setFieldValue('termsChecked', e.target.checked)
            }}
          >
            Accept <a target="_blank" href={`${SITE_URL}/terms_of_service`}>terms of service</a>
          </Checkbox>
          <ErrorMessage msg={formik.errors.termsChecked} />
        </Form.Item>


        <Button
          type="primary"
          onClick={() => formik.submitForm() }
          disabled={!formik.values.termsChecked}
          loading={confirmInvitationLoading}
        >
          Confirm and register
        </Button>

      </Form>

    </AuthLayout>
  )
}

export default ConfirmInvitationPage
