import _map from 'lodash/map'
import _find from 'lodash/find'
import _filter from 'lodash/filter'

import React from 'react'
import ProjectsListView from './view'
import { TApiIntegrationAccountsProject } from 'services/api-client/types'

interface IProps {
  projects: Array<TApiIntegrationAccountsProject>
  submitText?: string
  onSubmit(projects: Array<TApiIntegrationAccountsProject>): void
}

interface IState {
  selectedProjectOriginIds: Array<string>
}

class ProjectsList extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props)

    this.state = {
      selectedProjectOriginIds: [],
    }
  }

  getAllOriginIds = () => {
    return _map(this.props.projects, (project) => { return project.originIdentifier })
  }

  getSelectedProjects = () => {
    return _map(this.state.selectedProjectOriginIds, (originId) => {
      return _find(this.props.projects, (project) => { return project.originIdentifier == originId }) as TApiIntegrationAccountsProject
    })
  }

  selectAll = () => {
    this.setSelectedProjectOriginIds(this.getAllOriginIds())
  }

  clearSelection = () => {
    this.setState({ selectedProjectOriginIds: [] })
  }

  setSelectedProjectOriginIds = (ids: Array<string>) => {

    // Allow to select only ids that are not yet connected, to ensure disabled state inputs 
    // are not being selected
    const alreadyConnectedProjects = _filter(this.props.projects, (project) => { return project.connected })
    const alreadyConnectedIds = _map(alreadyConnectedProjects, (project) => { return project.originIdentifier })
    const projectOriginIdsToSelect = _filter(ids, (id) => { return !alreadyConnectedIds.includes(id) })

    this.setState({ selectedProjectOriginIds: projectOriginIdsToSelect })
  }

  handleSubmit = () => {
    this.props.onSubmit(this.getSelectedProjects())
  }

  render() {
    return React.createElement(ProjectsListView, {
      onChange: this.setSelectedProjectOriginIds,
      selectAll: this.selectAll,
      clearSelection: this.clearSelection,

      ...this.state,
      ...this.props,

      onSubmit: this.handleSubmit,
    })
  }
}

export default ProjectsList