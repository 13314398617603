import { useEffect } from 'react'

import _sortBy from 'lodash/sortBy'
import _maxBy from 'lodash/maxBy'
import _map from 'lodash/map'
import _cloneDeep from 'lodash/cloneDeep'

import snakeToCamelCaseObject from 'utils/snakeToCamelCaseObject'

import { formatProjectsToBarSegments } from './components/horizontal-bar'
import { filter } from 'lodash'
import { Card, Col, Row, Skeleton, message } from 'antd'
import DashboardSummary from './components/dashboard-summary'
import UserActivityModal from './components/user-activity-modal'
import DashboardBreakdown from './components/dashboard-breakdown'
import DashboardSummaryTable from './components/dashboard-summary-table'
import moment from 'moment-timezone'
import sortActivityByTime from './utils/sort-activity-by-time'
import TeamFilter, { TTeamFilterValues } from 'components/timenotes/filters/team-filter'
import useClassState from 'services/utils/hooks/use-class-state'
import useApiClient from 'hooks/useApiClient'
import { TApiResponse } from '@timenotes/shared/src/services/api-client/types'

const DashboardPage = () => {
  const apiClient = useApiClient()

  const [state, setState] = useClassState({
    activeUsers: [] as any[],
    inactiveUsers: [] as any[],
    totalSummary: undefined as any,
    projectTotals: undefined as any,
    loaded: false,
    selectedUserActivity: undefined,
    filters: {
      memberHashIds: [],
      groupHashIds: [],
    } as TTeamFilterValues
  })

  useEffect(() => {
    fetchUserActivities().then((response) => {
      setState({ loaded: true })
    })
  }, [JSON.stringify(state.filters)])

  const selectUserActivity = (userActivity: any) => {
    setState({
      selectedUserActivity: userActivity
    })
  }

  const clearSelectedUserActivity = () => {
    setState({
      selectedUserActivity: undefined
    })
  }

  const loadResponse = (response: TApiResponse) => {
    const responseBody = snakeToCamelCaseObject(response)

    const activeUsers = sortActivityByTime(responseBody.active)
    const inactiveUsers = sortActivityByTime(responseBody.inactive)

    const totalSummary = {
      topProject: responseBody.tops.project,
      topTask: responseBody.tops.task,
      totalDuration: responseBody.totals.worktime,
    }

    const projectTotals = responseBody.totals.project

    setState({
      loaded: true,
      activeUsers,
      inactiveUsers,
      totalSummary,
      projectTotals,
    })
  }

  const fetchUserActivities = async () => {
    try {
      const response = await apiClient.getActivities({
        filters: {
          usersAccountHashIds: state.filters.memberHashIds,
          membersGroupHashIds: state.filters.groupHashIds,
          timespan: 'day',
          from: moment().format('YYYY-MM-DD')
        }
      })

      if (response.ok) {
        loadResponse(response)
      } else {
        throw response
      }

      return response
    } catch (error) {
      message.error('Can not fetch user activities, something went wrong!')
    }
  }

  const {
    totalSummary,
    activeUsers,
    inactiveUsers,
    projectTotals,
    loaded: pageLoaded,
  } = state

  const projectSegments = formatProjectsToBarSegments(projectTotals)

  const activeRecentlyUsers = filter(inactiveUsers, (userActivity) => {
    return (userActivity.timeLogs.length > 0)
  })

  const noTimeLogsUsers = filter(inactiveUsers, (userActivity) => {
    return (userActivity.timeLogs.length == 0)
  })

  if (!pageLoaded) {
    return (
      <div className="timenotes-page page-content">
        <h1>Today Team Activity Dashboard </h1>

        <Card style={{ width: '100%', height: '84px', textAlign: 'center', overflow: 'hidden' }}>
          <Row gutter={[8, 8]}>

            <Col span={8} >
              <Skeleton active={true} paragraph={false} />
            </Col>

            <Col span={8} >
              <Skeleton active={true} paragraph={false} />
            </Col>

            <Col span={8} >
              <Skeleton active={true} paragraph={false} />
            </Col>

          </Row>
        </Card>
        <Card className="dashboard-breakdown">
          <Skeleton active={true} paragraph={{ rows: 1, width: '100%' }} />
        </Card>

        <br />

        <Card>
          <Skeleton active={true} />
          <Skeleton active={true} />
        </Card>

      </div>
    )
  }

  return (
    <div className="timenotes-page page-content">

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div>
          <h1> Today Team Activity Dashboard </h1>
        </div>

        <div>
          <TeamFilter
            values={state.filters}
            onChange={(values) => {
              setState({ filters: values })
            }}
          />
        </div>
      </div>

      <DashboardSummary {...totalSummary} />
      <DashboardBreakdown segments={projectSegments} />

      {(activeUsers.length > 0) && (
        <DashboardSummaryTable
          title="Active Now"
          active={true}
          activitiesData={activeUsers}
          selectUserActivity={selectUserActivity}
        />
      )}

      {(activeRecentlyUsers.length > 0) && (
        <DashboardSummaryTable
          title="Active Earlier Today"
          activitiesData={activeRecentlyUsers}
          selectUserActivity={selectUserActivity}
          activityColumnTitle="RECENTLY WORKED ON"
        />
      )}

      {(noTimeLogsUsers.length > 0) && (
        <DashboardSummaryTable
          title="No Activity Today"
          activitiesData={noTimeLogsUsers}
          activityColumnTitle=""
        />
      )}

      <UserActivityModal
        userActivity={state.selectedUserActivity}
        onCancel={clearSelectedUserActivity}
      />
    </div>
  )
}

export default DashboardPage