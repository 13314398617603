import { groupBy } from "lodash"
import { TApiTask } from "../../api-client/types"

export default function(tasks: TApiTask[]) {
  let taskOptions = []

  const mapTaskLabel = (labelName: string, tasks: TApiTask[]) => {
    return {
      label: labelName,
      options: tasks.map((task) => ({
        value: task
      }))
    }
  }

  if (tasks.length > 0) {
    // Add bookmarked and not bookmarked tasks as separate labels
    const groupOptions = groupBy(tasks, (task) => {
      if (task.recentlyTracked) {
        return 'recent'
      }

      return task.bookmarked ? 'bookmarked' : 'notBookmarked'
    })

    const recentTasks = groupOptions['recent']
    const bookmarkedTasks = groupOptions['bookmarked']
    const notBookmarkedTasks = groupOptions['notBookmarked']

    // Add recent tasks
    if (recentTasks) {
      const recentOptions = mapTaskLabel('Recently tracked tasks', recentTasks)
      taskOptions.push(recentOptions)
    }

    // Add bookmarked tasks
    if (bookmarkedTasks) {
      const bookmarkedOptions = mapTaskLabel('Bookmarked tasks', bookmarkedTasks)
      taskOptions.push(bookmarkedOptions)
    }

    if (notBookmarkedTasks) {
      const notBookmarkedOptions = mapTaskLabel('Tasks', notBookmarkedTasks)
      taskOptions.push(notBookmarkedOptions)
    }
  }

  return taskOptions
}
