import { TApiClient, TApiResponse, TApiParams, TApiUsersAccount, TApiMembersGroup, TApiAbsenceType, TApiResponseMeta, TApiPeekedInvitation, TApiUser } from "./types"
import BaseApi, { getQueryString, parameterizeArray } from './base'
import _omit from 'lodash/omit'
import { produce } from 'immer'

export type TApiAbsenceAllowance = {
  absenceType: TApiAbsenceType
  value: number | null
  used: number
  limit: number | ""
}

export type TApiTeamMember = TApiUsersAccount & {
  role: string
  billableRate: null | number
  costRate: null | number
  membersGroups: Array<TApiMembersGroup>
  active: boolean
  absenceAllowances: Array<TApiAbsenceAllowance>
  accountOwner: boolean
}

export type TApiUsersAccountsSorting = {
  role?: 'desc' | 'asc'
  firstName?: 'desc' | 'asc'
  lastName?: 'desc' | 'asc'
}

export type TApiUsersAccountsFilters = {
  state?: 'active' | 'inactive'
  name?: string
  role?: 'member' | 'admin'
}

export interface IGetUsersAccountsOwnedParams extends TApiParams {
  filters?: TApiUsersAccountsFilters
}

export interface IGetUsersAccountsParams extends IGetUsersAccountsOwnedParams {
  sorting?: TApiUsersAccountsSorting
}

export interface IGetUsersAccountsOwnedResponse extends TApiResponse {
  usersAccounts: Array<TApiUsersAccount>
}

export interface IGetTeamMembersResponse extends TApiResponse {
  usersAccounts: Array<TApiTeamMember>
  meta: TApiResponseMeta
}

export interface IGetUsersAccountResponse extends TApiResponse {
  usersAccount: TApiUsersAccount
}

export interface IGetUsersAccountTeamMemberResponse extends TApiResponse {
  usersAccount: TApiTeamMember
}

export interface IAbsenceAllowance {
  holidaysAbsenceTypeId: string
  limit: number
}

export interface IUpdateUsersAccountParams {
  firstName?: string
  lastName?: string
  projectAssignmentNotification?: boolean
  reportsEnabled?: boolean
  role?: 'member' | 'admin'
  billableRate?: number
  costRate?: number
  notes?: string
  adminReportsEnabled?: boolean
  absenceAllowances?: Partial<TApiAbsenceAllowance>[] // { absenceTypeId: number }
}


export interface IUpdateShowedHelpersUsersAccountParams {
  showedHelpers: Array<string>
}

class UsersAccountsApi extends BaseApi {
  // UsersAccounts for given user - represents number of available workspaces
  async getUsersAccountsOwned(params?: IGetUsersAccountsOwnedParams): Promise<IGetUsersAccountsOwnedResponse> {
    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/users_accounts/owned${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  // UsersAccounts for filters query
  async getUsersAccountsForFilters(params?: IGetUsersAccountsOwnedParams): Promise<IGetUsersAccountsOwnedResponse> {
    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/users_accounts/batch${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  // UsersAccounts which can been seen by given user based on permissions etc.
  async getUsersAccountsScoped(params?: IGetUsersAccountsOwnedParams): Promise<IGetUsersAccountsOwnedResponse> {
    const requestParams = {
      ...params,
      perPage: 1000,
    }

    const query = getQueryString(this.paramsToSnakeCase(requestParams), true)

    let response = await fetch(`${this.rootUrl}/users_accounts/scoped${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  // UsersAccounts list for Team section
  async getTeamMembers(params?: IGetUsersAccountsParams): Promise<IGetTeamMembersResponse> {

    let queryParams = { q: { s: this.prepareSortingParams((params || {}).sorting) }, ..._omit(params, ['sorting'])}
    const query = getQueryString(this.paramsToSnakeCase(queryParams), true)

    let response = await fetch(`${this.rootUrl}/users_accounts${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async getTeamMembersExport (params: IGetUsersAccountsParams & { export: { type: 'csv' | 'xls', columns: string[] }}) {
    let queryParams = { q: { s: this.prepareSortingParams((params || {}).sorting) }, ..._omit(params, ['sorting'])}
    const query = getQueryString(this.paramsToSnakeCase(queryParams), true)

    let response = await fetch(`${this.rootUrl}/users_accounts/export${query}`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(this.paramsToSnakeCase(params)),
    })

    this.handleBlobDownload(response, `timenotes-users.${params.export.type}`)
  }

  async getTeamMembersExportColumns() {
    return this.get('/users_accounts/export_columns')
  }

  async getUsersAccountListMember(usersAccountId: string): Promise<IGetUsersAccountTeamMemberResponse> {
    let response = await fetch(`${this.rootUrl}/users_accounts/${usersAccountId}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async getCurrentUser(): Promise<TApiResponse & {
    user: TApiUser
  }> {
    return this.get('/users/current')
  }

  async getUsersAccountsCurrent(): Promise<IGetUsersAccountResponse> {
    let response = await fetch(`${this.rootUrl}/users_accounts/current`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async updateUsersAccount(usersAccountId: string, params: IUpdateUsersAccountParams): Promise<{ usersAccount: TApiUsersAccount }> {
    // Adjust absence allowances to the API format reusing existing types 
    const requestParams = produce(params, draft => {
      if (draft.absenceAllowances) {
        draft.absenceAllowances = draft.absenceAllowances?.map((absenceAllowance) => {
          return {
            holidaysAbsenceTypeId: absenceAllowance.absenceType?.id,
            value: absenceAllowance.value,
          }
        })
      }

      return draft
    })

    let response = await fetch(`${this.rootUrl}/users_accounts/${usersAccountId}`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        users_account: this.paramsToSnakeCase(requestParams),
      }),
    })

    return await this.getResponseJson(response)
  }

  async updateUsersAccountShowedHelpers(usersAccountId: string, params: IUpdateShowedHelpersUsersAccountParams) {
    let response = await fetch(`${this.rootUrl}/users_accounts/${usersAccountId}/update_showed_helpers`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        users_account: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async deactivateUsersAccount(usersAccountId: string) {
    let response = await fetch(`${this.rootUrl}/users_accounts/${usersAccountId}/disable`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async activateUsersAccount(usersAccountId: string) {
    let response = await fetch(`${this.rootUrl}/users_accounts/${usersAccountId}/enable`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async deleteUsersAccount(usersAccountId: string) {
    let response = await fetch(`${this.rootUrl}/users_accounts/${usersAccountId}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async resendEmailConfirmation() {
    const response = await this.patch('/users/send_confirmation_email', {})
    return response
  }

  async confirmEmail({
    confirmationToken,
  }: {
    confirmationToken: string,
  }) {
    return this.patch('/users/confirm_email', {
      confirmationToken
    })
  }

  async peekInvitation({
    token,
  }: {
    token: string,
  }): Promise<TApiResponse & { 
    peekedInvitation: TApiPeekedInvitation 
  }> {
    return this.get(`/invitations/peek?token=${token}`)
  }

  async transferOwnershipUsersAccount(usersAccountId: string, targetUsersAccountId: string) {
    let response = await fetch(`${this.rootUrl}/users_accounts/${usersAccountId}/transfer_ownership`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        target_users_account_id: targetUsersAccountId,
      }),
    })

    return await this.getResponseJson(response)
  }
}

export default UsersAccountsApi
