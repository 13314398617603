import { message } from "antd"
import { useQueryParams } from "hooks/app-router"
import { useApiCall } from "hooks/useApiCall"
import useApiClient from "hooks/useApiClient"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import AuthLayout from "views/auth-views/components/auth-layout"

const PeekInvitationPage = () => {
  const history = useHistory()
  const apiClient = useApiClient()

  const queryParams = useQueryParams<{
    'registration[token]': string // name?
  }>()

  const invitationToken = queryParams['registration[token]']

  const redirectToInvalidToken = () => {
    message.error('Invitation token invalid!')
    history.push('/auth/login')
  }

  const {
    request: peekInvitation,
    data: response,
    loading
  } = useApiCall(async (invitationToken: string) => {
    const response = await apiClient.peekInvitation({ token: invitationToken })
    return response
  })

  useEffect(() => {
    if (invitationToken) {
      peekInvitation(invitationToken).then((response) => {
        if (response.ok) {
          const peekedInvitation = response.peekedInvitation

          // this means invited email is already registered in timenotes and user exists
          if (peekedInvitation.userExists) {
            // we save the invitation and redirect
            apiClient.loadAccessCredentials()

            // Save peeked invitation to localstorage only if its existing user one
            window.localStorage.setItem('peekedInvitationJSON', JSON.stringify({ 
              ...peekedInvitation,
              token: invitationToken,
            }))

            // in case user is already logged in
            if (apiClient.accessToken) {

              apiClient.getCurrentUser().then((response) => {
                if (response.ok) {
                  const currentUser = response.user

                  if (currentUser.email == peekedInvitation.email) {
                    //message.success('Correct user logged in - redirect to confirm!')
                    history.push('/auth/auth-confirm-invitation')
                  } else {
                    // If wrong user logged in - log out and ask to log in as a correct user!
                    // apiClient.logout()
                    //message.success('Incorrect user logged in - redirect to login flow!')
                    history.push('/auth/login')
                  }
                }

              })

              // In case user is not logged in - redirect to login page
            } else {
              history.push('/auth/login')
            }

            // this means invited email is not an existing timenotes user
          } else {
            history.push(`/auth/confirm-invitation?invitationToken=${invitationToken}`)

          }

        } else {
          redirectToInvalidToken()
        }
      })
    } else {
      redirectToInvalidToken()
    }
  }, [])

  const peekedInvitation = response?.peekedInvitation

  return (
    <AuthLayout loading={true} />
  )

}

export default PeekInvitationPage
