import React, { useEffect, useState } from 'react'
import _map from 'lodash/map'
import styled from 'styled-components'
import RecordsSelect, { CustomGroupOption, SelectAllLink } from '../records-select'
import useProjectsForFiltersQuery from '@timenotes/shared/src/services/projects/queries/use-projects-for-filters-query'
import FilterInput from './filter-input'
import { TApiProjectsListFilters } from '@timenotes/shared/src/services/api-client/projects-api'
import { isEqual } from 'lodash'
import { useRef } from 'react'
import { TApiProject } from '@timenotes/shared/src/services/api-client/types'

const useHasMounted = () => {
  const hasMounted = useRef(false);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  return hasMounted.current;
};


export interface ProjectsFilterProps {
  style?: React.CSSProperties
  width?: string
  values?: string[]
  filters?: TApiProjectsListFilters
  onChange?(values: string[]): void 
}

const Wrapper = styled.div`
  .ant-popover {
    width: 250px;
  }
`

const ProjectsFilter = ({
  values,
  width,
  filters,
  onChange,
 }: ProjectsFilterProps): JSX.Element => {
  const [showArchived, setShowArchived] = useState(false)
  const [selectOptions, setSelectOptions] = useState<CustomGroupOption<TApiProject>[]>([])

  const projectsQuery = useProjectsForFiltersQuery({
    filters: filters,
    perPage: 2000,
  })

  const handleOnChange = (projects: TApiProject[]) => {
    onChange && onChange(projects.map((p) => p.hashId))
  }

  const allProjects = projectsQuery.data?.projects || []
  const selectedProjects = allProjects.filter((project) => {
    return values?.includes(project.hashId)
  })

  const projectsOptions = projectsQuery.data?.activeOptions || []

  const isLoading = projectsQuery.isLoading

  // Making sure that if filters change and new allProjects will not 
  // contain some of the selected projects, those will get de-selected
  // as are no longer available in the whole projects pool
  useEffect(() => {
    if (projectsQuery.isSuccess) {
      const newSelectedHashIds = selectedProjects.map((p) => p.hashId)

      // TODO: Proper fix? The timeout is needed as there is some weird race condition
      // which I can't find how to fix between client and project change when clearing 
      // projects which does not belong to the new client selected in applyFilters method
      if (!isEqual(values, newSelectedHashIds)) {
        setTimeout(() => {
          onChange && onChange(newSelectedHashIds)
        }, 300)
      }
    }
  }, [filters?.clientHashIds, JSON.stringify(projectsQuery.data)])

  useEffect(() => {
    getSelectOptions()
  }, [projectsQuery.data, showArchived])


  const getSelectOptions = () => {
    const archivedProjectsOptions = projectsQuery.data?.archivedOptions || []
    const archivedOption = !showArchived ? {
      label: <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {archivedProjectsOptions.length} Archived projects hidden
        </div>
        <div>
          <SelectAllLink
            onClick={() => setShowArchived(true)}
          >
            Add to search
          </SelectAllLink>
        </div>
      </div>,
      options: []
    } : {
      label: "Archived projects",
      options: archivedProjectsOptions
    }

    const activeOption = projectsOptions.length > 0 ? {
      label: 'Projects',
      allFilters: true,
      options: projectsOptions,
    } : undefined

    const options = [
      activeOption, 
      archivedProjectsOptions.length > 0 ? archivedOption : undefined,
    ].filter((o) => o !== undefined) as typeof selectOptions

    setSelectOptions(options)
  }

  return (
    <FilterInput
      placeholder="Project"
      count={values?.length}
    >
      <RecordsSelect<TApiProject>
        open={true}
        loading={isLoading}
        disabled={isLoading}
        style={{
          width: '300px',
        }}
        valueProp='hashId'
        labelProp='name'
        searchProp='name'
        placeholder='Project'
        showSearch={true}
        mode='multiple'
        options={selectOptions}
        onChange={handleOnChange as any}
        value={selectedProjects as any}

        autoFocus={true}
        dropdownMatchSelectWidth={true}
        getPopupContainer={(node) => {
          return node
        }}
        filterInputMode={true}
      />
      <div className="options-wrapper"></div>
    </FilterInput>
  )
}

export default ProjectsFilter