import React from 'react'
import { Input, Divider, Select, Popover, Tag, Button, Form } from 'antd'
import { Switch, Card, Col, Row, Checkbox } from 'components/base'
import styled from 'styled-components'
import _map from 'lodash/map'
import ColorPickerInput from 'components/base/ColorPickerInput'

import { FormikProps } from 'formik'
import { usePagePermissions } from 'hooks/permissions.hooks'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { toTitleCase } from 'utils/string-utils'
import { useDefaultCurrencyIso } from 'hooks/settings'
import ErrorMessage from 'components/base/error-message'
import { useClientsQuery } from '@timenotes/shared/src/services/clients/queries/use-clients-query'
import RecordsSelect from 'components/timenotes/records-select'
import { TApiClient, TApiProject } from '@timenotes/shared/src/services/api-client/types'
import SettingsCheckbox from 'pages/settings/components/settings-checkbox'

interface IProps {
  formik: FormikProps<Partial<TApiProject>>
}

export interface IInputFieldWrapper {
  description?: string
  label?: string
  hint?: string
}

export class InputFieldWrapper extends React.Component<any, IInputFieldWrapper> {
  render() {
    const {
      label,
      description,
      hint,
    } = this.props

    return (
      <div>
        {label && (
          <label><b>{label}</b></label>
        )}

        {description && (
          <p>{description}</p>
        )}

        {this.props.children}

      </div>
    )
  }
}


const FormCard = styled(Card)`
  .ant-form-item-explain { //.ant-form-item-explain-error {
    position: absolute;
    margin-top: 40px;
  }
`

const ProjectForm = (props: IProps) => {
  const formik = props.formik

  const { defaultCurrencyIso } = useDefaultCurrencyIso()
  const { permissions } = usePagePermissions()

  const clientsQuery  = useClientsQuery()
  const clientsOptions = clientsQuery.isSuccess ? clientsQuery.data.clientsOptions : []

  const handleKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      formik.submitForm()
    }
  }

  return (
    <FormCard>
      <Form
        onKeyUp={handleKeyUp}
      >
        <div>
          <Row>
            <Col span="10" offset="1">
              <InputFieldWrapper
                label="Project Name"
                description="Name of the new project"
                hint="Working great"
              >

                <Input
                  value={formik.values.name}
                  onChange={(e) => { formik.handleChange('name')(e.target.value) }}
                  placeholder="New projects name"
                  prefix={
                    <Popover
                      trigger={"click"}
                      content={
                        <ColorPickerInput
                          value={formik.values.color || ""}
                          onChange={(newColorString) => { formik.handleChange('color')(newColorString) }}
                        />
                      }
                    >
                      <Tag style={{ cursor: 'pointer' }} color={formik.values.color}>&nbsp;</Tag>
                    </Popover>
                  } />
                <ErrorMessage msg={formik.errors.name} />
              </InputFieldWrapper>
            </Col>

            <Col span="10" offset="2">
              <InputFieldWrapper
                label="Client name"
                description="Name of the new project' client"
              >
                <RecordsSelect<TApiClient>
                  placeholder="Select the client"
                  allowClear={true}
                  onClear={() => {
                    formik.setFieldValue('client', undefined)
                  }}
                  style={{ width: '100%' }}
                  value={formik.values.client || undefined}
                  valueProp="name"
                  labelProp="name"
                  searchProp="name"
                  showSearch={true}
                  onChange={(client) => { 
                    formik.setFieldValue('client', client)
                  }}
                  onKeyUp={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    return false
                  }}
                  options={clientsOptions}
                >
                </RecordsSelect>
              </InputFieldWrapper>
              <ErrorMessage msg={formik.errors.client} />
            </Col>

          </Row>

          <br />
          <br />

          <Row>
            <Col span="10" offset="1">
              <InputFieldWrapper
                label="Access"
                description="Who can see and log time to this project"
              >
                <Select
                  value={formik.values.visibility ? 'public' : 'private'}
                  onChange={(value) => { formik.setFieldValue('visibility', value === 'public') }}
                  style={{ width: '100%' }}
                >
                  <Select.Option value="public"><b>Public</b> - all members of the workspace </Select.Option>
                  <Select.Option value="private"><b>Private</b> - only members added to the project have access</Select.Option>
                </Select>
              </InputFieldWrapper>
              <ErrorMessage msg={formik.errors.visibility} />

            </Col>

            <Col span="10" offset="2">
              <InputFieldWrapper
                label="Task Management Policy"
                description="Who can create and manage tasks?"
              >
                <Select
                  value={formik.values.taskCreator}
                  onChange={(value) => { formik.handleChange('taskCreator')(value) }}
                  style={{ width: '100%' }}
                >
                  <Select.Option value="nobody"><b>Nobody</b> - no one can create or udpate tasks inside timenotes</Select.Option>
                  <Select.Option value="member"><b>Member</b> - all members of the project</Select.Option>
                  <Select.Option value="project_manager"><b>Project Manager</b> - assigned to the project</Select.Option>
                  <Select.Option value="admin"><b>Admin</b> - of the workspace</Select.Option>
                </Select>
              </InputFieldWrapper>
              <ErrorMessage msg={formik.errors.taskCreator} />

            </Col>
          </Row>

          {formik.values.origin && formik.values.origin != 'timenotes' && (
            <>
              <br />
              <br />
              <Row>
                <Col span="10" offset="13">
                  <InputFieldWrapper
                    label="Task Creation Target"
                    description="Where new tasks created in Timenotes should be visible?"
                  >
                    <Select
                      placeholder="Select the client"
                      value={formik.values.taskCreationPolicy}
                      onChange={(value) => { formik.setFieldValue('taskCreationPolicy', value) }}
                      style={{ width: '100%' }}
                      disabled={formik.values.taskCreator == 'nobody'}
                    >
                      <Select.Option value="internal">Only in Timenotes for time tracking purposes</Select.Option>
                      <Select.Option value="external_mandatory">Always in Timenotes and {toTitleCase(formik.values.origin)}</Select.Option>
                      <Select.Option value="external_optional">Always in Timenotes but user decides if task should appear in {toTitleCase(formik.values.origin)} too</Select.Option>
                    </Select>
                  </InputFieldWrapper>
                  <ErrorMessage msg={formik.errors.taskCreator} />
                </Col>
              </Row>
            </>
          )}

          <br />

          <Divider />

          <Row>
            <Col span="10" offset="1">
              <InputFieldWrapper
                label="Billing"
                description="Decide whether you want to track billable time for time entries added to this project. "
              >

                {permissions.manageProjectBillable ? (
                  <Select
                    value={formik.values.billableEnabled === true ? 'true' : 'false'}
                    onChange={(value) => { formik.setFieldValue('billableEnabled', (value === 'true')) }}
                    style={{ width: '100%' }}
                  >

                    <Select.Option value={'false'}> <b>Non billable project</b> </Select.Option>
                    <Select.Option value={'true'}> <b>Billable project</b> </Select.Option>
                  </Select>
                ) : (
                  <Tag icon={<ExclamationCircleOutlined />}>Only admins can manage project billable settings!</Tag>
                )}
              </InputFieldWrapper>
              <ErrorMessage msg={formik.errors.billableEnabled} />
            </Col>

          </Row>

          {formik.values.billableEnabled && permissions.manageProjectBillable && (
            <Row>
              <Col span="14" offset="1">
                <br />

                <InputFieldWrapper
                  label="Project Billable Rate"
                  description="This hourly rate will be applied for calculating billable amount unless you set more specific project member or task rate. "
                >
                  <Input
                    type="number"
                    placeholder="e.g. 100"
                    style={{ width: '130px', marginRight: '15px' }}
                    value={formik.values.billableRate || ""}
                    onChange={(e) => { formik.handleChange("billableRate")(e.target.value) }}
                    suffix={(<span style={{ color: "#B9B9C4" }}>{defaultCurrencyIso}</span>)}
                  />
                  <ErrorMessage msg={formik.errors.billableRate} />
                </InputFieldWrapper>

                <br />

                <div style={{ display: 'flex', padding: '5px' }}>
                  <Switch
                    checked={formik.values.defaultIsBillableTimeLogs}
                    onChange={(value) => { formik.setFieldValue('defaultIsBillableTimeLogs', value) }}
                  />
                  <span style={{ marginLeft: '15px' }}>By default time logs assigned to this project are billable</span>
                </div>
              </Col>
            </Row>
          )}

          <Row>
            {
              /*

                <Col span="24" offset="1">
                  <InputFieldWrapper
                    label="Project cost rate"
                    description="This hourly rate will be applied for calculating cost amount unless you set more specific project member or task rate. "
                    hint="Working great"
                  >
                    <Field
                      name="costRateCents"
                    >
                      {({ field, form: { touched, errors }, meta }: FieldProps) => (
                        <Input 
                          type="number" 
                          placeholder="e.g. 100" 
                          style={{ width: '130px', marginRight: '15px' }} 
                          value={field.value}
                          onChange={(e) => { field.onChange("costRateCents")(e.target.value) }}
                          suffix={(<span style={{ color:"#B9B9C4" }}>USD</span>)}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="costRateCents" />

                  </InputFieldWrapper>
                </Col>
               */
            }
          </Row>

          <Divider />

          <Row>
            <Col span="10" offset="1">
              <InputFieldWrapper
                label="Project budget"
                description="Decide how you would like to track progress of this project"
              >
                {permissions.manageProjectBudget ? (
                  <Select
                    placeholder="Select the budget type"
                    value={(formik.values.budgetEnabled ? (formik.values.budgetType == 'money' ? 'money' : 'time') : 'noBudget')}
                    onChange={(value) => { 
                      if (value == 'noBudget') {
                        formik.setFieldValue('budgetEnabled', false)
                        formik.setFieldValue('budgetType', 'time')
                      } else {
                        formik.setFieldValue('budgetEnabled', true)
                        formik.setFieldValue('budgetType', value) 
                      }
                    }}
                    style={{ width: '100%' }}
                  >
                    <Select.Option value="noBudget"><b>No project budget</b> - project has no budget at all</Select.Option>
                    <Select.Option value="time"><b>Time</b> - project has budget specified based on total amount of tracked time</Select.Option>
                    <Select.Option value="money"><b>Money</b> - project has budget specified based on billable rates of total tracked hours</Select.Option>
                  </Select>
                ) : (
                  <Tag icon={<ExclamationCircleOutlined />}>Only admins can manage project budget settings!</Tag>
                )}
              </InputFieldWrapper>
            </Col>


          </Row>

          {formik.values.budgetEnabled && formik.values.budgetType === "money" && permissions.manageProjectBudget && (
            <Row>
              <Col span="14" offset="1">
                <br />
                <InputFieldWrapper
                  label="Total budget amount "
                  description=""
                >
                  <Input
                    type="number"
                    placeholder="e.g. 100"
                    style={{ width: '130px', marginRight: '15px' }}
                    value={formik.values.budgetMoney || ""}
                    onChange={(e) => { formik.handleChange("budgetMoney")(e.target.value) }}
                    suffix={(<span style={{ color: "#B9B9C4" }}>{defaultCurrencyIso}</span>)}
                  />
                  <ErrorMessage msg={formik.errors.budgetMoney} />
                </InputFieldWrapper>
              </Col>
            </Row>
          )}

          {formik.values.budgetEnabled && formik.values.budgetType === "time" && permissions.manageProjectBudget && (
            <Row>
              <Col span="14" offset="1">
                <br />
                <InputFieldWrapper
                  label="Total time budget"
                  description=""
                >
                  <Input
                    type="number"
                    suffix="hours"
                    placeholder="e.g. 100"
                    style={{ width: '150px', marginRight: '15px', marginLeft: '5px' }}
                    value={formik.values.budgetTime || ""}
                    onChange={(e) => { formik.handleChange("budgetTime")(e.target.value) }}
                  />
                  <ErrorMessage msg={formik.errors.budgetTime} />
                </InputFieldWrapper>
              </Col>
            </Row>
          )}

          {formik.values.budgetEnabled && permissions.manageProjectBudget && (
            <>
              <Row>
                <Col span="14" offset="1">
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <div>
                      Send email to workspace admins after
                    </div>
                    <Input
                      type="number"
                      placeholder="e.g. 70"
                      style={{ width: '100px', margin: '10px 5px 10px 5px' }}
                      value={formik.values.budgetAlertThreshold || ""}
                      onChange={(e) => { formik.handleChange("budgetAlertThreshold")(e.target.value) }}
                      suffix={(<span style={{ color: "#B9B9C4" }}>%</span>)}
                    />

                    <div>
                      of budget is reached.
                    </div>
                  </div>

                  <ErrorMessage msg={formik.errors.budgetAlertThreshold} />
                </Col>
              </Row>

              <Row>
                <Col span="14" offset="1">
                  <SettingsCheckbox
                    title="Forbid time tracking in case there is no project budget left."
                    checked={formik.values.disallowOverbudget}
                    onChange={(event) => { formik.setFieldValue('disallowOverbudget', event.target.checked) }}
                  />
                </Col>
              </Row>
            </>
          )}

          <br />

          { permissions?.manageProjectBudget && (
            <Row>
              <Col span="10" offset="1">
                <SettingsCheckbox 
                  title="Forbid time tracking per team member who has no budget left."
                  info="You can specify the project's specific budget for individual team members in the Project Team subsection. If this is enabled, project members will not be allowed to track time exceeding the configured budget."
                  checked={formik.values.disallowUserOverbudget}
                  onChange={(e) => formik.setFieldValue('disallowUserOverbudget', e.target.checked)}
                />
              </Col>
            </Row>
          )}

          <br />

          <Row>
            <Col offset={"1"}>
              <Button
                type="primary"
                onClick={() => { formik.submitForm() }}
              >
                {formik.values.id ? (
                  <>Update project</>
                ) : (
                  <>Create project</>
                )}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </FormCard>

  )
}

export default ProjectForm