import { useEffect, useState } from "react"

type TCall<TArgs, TReturn> = {
  (...args: TArgs[]): Promise<TReturn>
}


//
// Saves data as response body
// Saves loading as request progress indicator
//
export const useApiCall = <TArgs, TReturn>(func: TCall<TArgs, TReturn>, ...args: TArgs[]) => {
  const [data, setData] = useState<TReturn>()
  const [loading, setLoading] = useState(false)

  const apiCall = func

  const request = async (...args: TArgs[]): Promise<TReturn> => {
    try {
      setLoading(true)
      const response = await apiCall(...args)
      setData(response)
      return response
    }

    finally {
      setLoading(false)
    }
  }

  return {
    data,
    loading,
    request,
  }
}

interface IHookParams {
  fetch?: boolean
  path: string
}

export const useGetApiCall = <TArgs, TReturn>(func: TCall<TArgs, TReturn>, hookParams?: IHookParams) => {
  const { data, loading, request } = useApiCall(func)
  const fetchOnInit = hookParams?.fetch == undefined ? true : hookParams?.fetch

  useEffect(() => {
    if (fetchOnInit) {
      request()
    }
  }, [hookParams?.fetch])

  return {
    data,
    loading,
    request
  }
}