import { TApiReportsDate, TApiReportsFilters } from '@timenotes/shared/src/services/api-client/reports-api';
import { TApiTimeLogsFilters } from '@timenotes/shared/src/services/api-client/time-logs-api';
import { TApiGrouping, TApiRounding } from '@timenotes/shared/src/services/api-client/types';
import { pick } from 'lodash'
import queryString from 'query-string'
import { useMemo, useState } from 'react';

type STORAGE_KEY_TYPE = 'timesheet' | 'report'
const FILTERS_KEYS = [
  'clientHashIds',
  'description',
  'isBillable',
  'projectHashIds',
  'tagIds',
  'taskName',
  'usersAccountHashIds',
  'membersGroupHashIds',
  'withoutDuration',
  'withoutDescription',
  'durationLongerThen',
  'durationShorterThen',
  'descriptionShorterThen',
  'withoutTags',
  'notWorkingDays',
  'timeBeforeHour',
  'timeAfterHour',
]

const TIMESPAN_KEYS = ['timespan', 'from', 'to']
const ROUNDING_KEYS = ['roundingType', 'roundingPrecision']
const GROUPING_KEYS = ['groupingPrimary', 'groupingSecondary']

const getQueryParams = () => {
  const queryParams = queryString.parse(window.location.search, { arrayFormat: 'bracket'})

  return queryParams
}

////// FILTERS 

export const useTimeLogsFiltersWithParams = () => {
  const [filters, setFiltersState] = useState(getFiltersFromParams() as TApiTimeLogsFilters)

  const applyFilters = (filters: TApiTimeLogsFilters) => {
    saveFiltersToParams(filters)
    setFiltersState(filters)
  }

  return [filters, applyFilters]
}

export const getFiltersFromParams = () => {

  const queryParams = getQueryParams()
  let filters = pick(queryParams, FILTERS_KEYS)

  if (filters.isBillable) {
    if (filters.isBillable == 'false') {
      // @ts-ignore
      filters.isBillable = false
    } else {
      // @ts-ignore
      filters.isBillable = true
    }
  }

  return filters as any as TApiReportsFilters
}

export const saveFiltersToParams = (params: any) => {
  let queryParams = { 
    ...getQueryParams(),
    ...params,
  }

  FILTERS_KEYS.forEach(key => {
    if (!params.hasOwnProperty(key)) {
      delete queryParams[key]
    }
  })

  // @ts-ignore
  window.appHistory.push({
    pathname: window.location.pathname,
    search: queryString.stringify(queryParams, { arrayFormat: 'bracket' })
  })
}

///// GROUPING

export const getGroupingFromParams = () => {
  const queryParams = getQueryParams()
  let { 
    groupingPrimary,
    groupingSecondary,
  }= pick(queryParams, GROUPING_KEYS)

  let grouping: Partial<TApiGrouping> = {}

  if (groupingPrimary && typeof(groupingPrimary) === 'string' && ['no_group', 'task', 'project', 'user', 'client'].includes(`${groupingPrimary}`)) {
    grouping.primary = groupingPrimary as any as TApiGrouping['primary']
  }

  if (groupingSecondary && typeof(groupingSecondary) === 'string' && ['no_group', 'task', 'project', 'user', 'client'].includes(`${groupingSecondary}`)) {
    grouping.secondary = groupingSecondary as any as TApiGrouping['primary']
  }

  return grouping
}

export const saveGroupingToParams = (params: TApiGrouping) => {

  let groupingParams: any = {
    groupingPrimary: params.primary
  }

  if (params.secondary) {
    groupingParams.groupingSecondary = params.secondary
  }

  let queryParams = { 
    ...getQueryParams(),
    ...groupingParams,
  }

  GROUPING_KEYS.forEach(key => {
    if (!groupingParams.hasOwnProperty(key)) {
      delete queryParams[key]
    }
  })

  // @ts-ignore
  window.appHistory.push({
    pathname: window.location.pathname,
    search: queryString.stringify(queryParams, { arrayFormat: 'bracket' })
  })
}

export const getPageDefaultGrouping = (key: STORAGE_KEY_TYPE, grouping: TApiGrouping) => {
  // TODO: make it project->task for individual members 
  const groupingParams = getGroupingFromParams()
  let defaultGrouping: TApiGrouping = { ...grouping }

  if (groupingParams.primary) {
    defaultGrouping = {
      ...grouping,
      ...groupingParams
    }
  } else {
    const timesheetDefaultGroupingJSON = window.localStorage.getItem(`${key}DefaultGrouping`)
    if (timesheetDefaultGroupingJSON) {
      defaultGrouping = { ...JSON.parse(timesheetDefaultGroupingJSON) }
    }
  }

  return defaultGrouping
} 

////// TIMESPANS

export const getTimespanFromParams = () => {
  const queryParams = getQueryParams()
  let timespan = pick(queryParams, TIMESPAN_KEYS)

  return timespan as any as TApiReportsDate
}

export const saveTimespanToParams = (params: TApiReportsDate) => {
  let queryParams = { 
    ...getQueryParams(),
    ...params,
  }

  TIMESPAN_KEYS.forEach(key => {
    if (!params.hasOwnProperty(key) && queryParams.hasOwnProperty(key)) {
      // @ts-ignore
      delete queryParams[key]
    }
  })

  // @ts-ignore
  window.appHistory.push({
    pathname: window.location.pathname,
    search: queryString.stringify(queryParams, { arrayFormat: 'bracket' })
  })
}

export const getPageDefaultTimespan = (defaultTimespan: TApiReportsDate) => {
  return {
    ...defaultTimespan,
    ...getTimespanFromParams()
  }
}

// ROUNDING


export const getRoundingFromParams = () => {
  const queryParams = getQueryParams()
  let { 
    roundingType,
    roundingPrecision,
  }= pick(queryParams, ROUNDING_KEYS)

  if (!roundingType || !roundingPrecision) return {}

  let rounding: Partial<TApiRounding> = {}

  if (roundingType && typeof(roundingType) === 'string' && ['up', 'nearest', 'down', 'no_rounding'].includes(`${roundingType}`)) {
    rounding.type = roundingType as any as TApiRounding['type']
  }

  if (roundingPrecision && [5,10,15,30,60].includes(parseInt(`${roundingPrecision}`))) {
    rounding.precision = roundingPrecision as any as TApiRounding['precision']
  }

  return rounding
}

export const saveRoundingToParams = (params: TApiRounding) => {
  let roundingParams: any = {
    roundingType: params.type,
    roundingPrecision: params.precision,
  }

  let queryParams = { 
    ...getQueryParams(),
    ...roundingParams,
  }

  // if new rounding is incomplete or does not exist -> remove rounding from query params at all
  if (!params.type || !params.precision) {
    ROUNDING_KEYS.forEach(key => {
      if (queryParams.hasOwnProperty(key)) {
        delete queryParams[key]
      }
    })
  }

  // @ts-ignore
  window.appHistory.push({
    pathname: window.location.pathname,
    search: queryString.stringify(queryParams, { arrayFormat: 'bracket' })
  })
}

export const getPageDefaultRounding = (defaultRounding: TApiRounding) => {
  return {
    ...defaultRounding,
    ...getRoundingFromParams()
  }
}

export const getPageDefaultFilters = (defaults: {
  key: STORAGE_KEY_TYPE, 
  filters: TApiReportsFilters,
  grouping: TApiGrouping,
  rounding: TApiRounding,
  timespan: TApiReportsDate
}) => {
  return {
    filters: getFiltersFromParams() || defaults.filters,
    grouping: getPageDefaultGrouping(defaults.key, defaults.grouping),
    rounding: getPageDefaultRounding(defaults.rounding),
    timespan: getPageDefaultTimespan(defaults.timespan)
  }
}