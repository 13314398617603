import { useAbsenceRequestsExportColumns } from "@timenotes/shared/src/services/absences/queries/use-absence-requests-export-columns";
import { TApiHolidaysAbsenceRequestsFilters } from "@timenotes/shared/src/services/api-client/holidays-absence-requests-api";
import { TApiExportColumn, TApiReportsExportType } from "@timenotes/shared/src/services/api-client/reports-api";
import { Button, Checkbox, message, Modal } from "antd";
import { DownloadIcon } from "components/base/icons";
import { COLORS } from "components/layout-components/timenotes-theme";
import { useFormik } from "formik";
import useApiClient from "hooks/useApiClient";
import useNoInitialEffect from "hooks/useNoInitialEffect";
import { filter, map } from "lodash";
import { ExportTypeRadioGroup } from "pages/reports/components/reports-export-modal";
import { useCallback, useRef, useState } from "react";
import { toTitleCase } from "utils/string-utils";

type useAbsencesRequestsExportProps = {
  filters: TApiHolidaysAbsenceRequestsFilters
}

export default function useAbsencesRequestsExport(props: useAbsencesRequestsExportProps) {
  const apiClient = useApiClient()

  const {
    absenceRequestsExportColumns
  } = useAbsenceRequestsExportColumns()

  const apiCall = (values: any) => {
    apiClient.getHolidaysAbsenceRequestsExport({
      filters: props.filters,
      export: {
        columns: values.columns,
        type: values.exportType,
      },
    })
  }

  const [visible, setVisible] = useState(false)

  const ExportAbsenceRequestsModal = (
    <ExportColumnsModal
      title="Export absence requests"
      availableExportTypes={['csv', 'xls']}
      visible={visible}
      onSubmit={apiCall}
      exportColumns={absenceRequestsExportColumns}
      onCancel={() => setVisible(false)}
      destroyOnClose={true}
    />
  )

  const absenceRequestsExportButtonItem = {
    title: "Export",
    disabledTooltip: 'Only users list can be exported.',
    icon: DownloadIcon,
    callback: () => { setVisible(true) },
  }

  return {
    absenceRequestsExportButtonItem,
    ExportAbsenceRequestsModal,
  }
}

type ExportColumnsFormProps = {
  exportColumns: TApiExportColumn[]
  availableExportTypes?: TApiReportsExportType[]
  onSubmit(values: any): void
}

const prettyColumnName = (name: string): string => {
  const prettyNames = {
    'date': 'Time log date',
    'from': 'Start time',
    'to': 'End time',
    'description': 'Description',
    'duration': 'Duration',
    'duration_decimal': 'Duration decimal',
    'client': 'Client name',
    'project': 'Project name',
    'task': 'Task name',
    'user': 'Team member name',
    'email': 'Team member email',
    'tags_names': 'Tags names',
    'is_billable': 'Billable status',
    'billable': 'Billable amount',
    'costable': 'Cost amount',
    'user_notes': 'User notes',
  }

  let result: string

  // @ts-ignore
  result = prettyNames[name] ? prettyNames[name] : toTitleCase(name)

  return result
}

type ExportColumnsModalProps = React.ComponentProps<typeof Modal> & ExportColumnsFormProps

export const ExportColumnsModal = (props: ExportColumnsModalProps) => {
  const {
    exportColumns: allExportColumns,
  } = props

  const allColumnNames = map(allExportColumns, (eC) => eC.name)

  const formik = useFormik({
    initialValues: {
      columns: map(filter(allExportColumns, (ec) => ec.preselected), (ec) => ec.name), // column names
      exportType: !!props.availableExportTypes ? props.availableExportTypes[0] : 'csv'
    },
    onSubmit: (values, formikHelpers) => {
      if (values.columns.length == 0) {
        message.error("At least one column should be selected!")
      } else {
        props.onSubmit(values)
      }
    },
    enableReinitialize: true,
  })

  return (
    <>
      <Modal
        okText="Export"
        onOk={() => formik.handleSubmit()}
        {...props}
      >
        <>
          <ExportTypeRadioGroup
            availableExportTypes={props.availableExportTypes}
            value={formik.values.exportType}
            onChange={(value) => formik.setFieldValue('exportType', value)}
          />

          <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <h5>Export Columns</h5>
            </div>
            <div>
              <Button
                type="link"
                style={{ color: COLORS.linkGray }}
                onClick={() => {
                  formik.setFieldValue('columns', allColumnNames)
                }}
              >
                All
              </Button>
              /
              <Button
                type="link"
                style={{ color: COLORS.linkGray }}
                onClick={() => {
                  formik.setFieldValue('columns', [])
                }}
              >
                None
              </Button>
            </div>

          </div>

          <hr />
          <Checkbox.Group
            style={{ display: 'flex', flexWrap: 'wrap' }}
            value={formik.values.columns || []}
            onChange={(values) => {
              formik.setFieldValue('columns', values)
            }}
          >
            {map(allExportColumns, (exportColumn) => {
              const checked = formik.values.columns.includes(exportColumn.name)

              return (
                <div
                  style={{ width: '50%' }}
                  key={exportColumn.name}>
                  <Checkbox
                    value={exportColumn.name}
                    checked={checked}
                  >
                    {prettyColumnName(exportColumn.name)}
                  </Checkbox>
                </div>
              )
            })}
          </Checkbox.Group>
        </>

      </Modal >
    </>
  )
}
