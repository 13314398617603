import styled from 'styled-components'

import { StatusBadge, Row, Col, Card, Button } from 'components/base'
import LogoImg from 'components/base/logo'

export interface IWorkspaceListItemView {
  integrationApp: any

  onConnect(integrationApp: any): void
  onDisconnect(integrationApp: any): void
}

const WorkspaceTitle = styled(Col)`
  cursor: pointer;
  font-size: 16px;
  color: "#222";
`

const WorkspaceInfo = styled(Col)`
  font-size: 14x;
  color: gray;
`

const WorkspaceActions = styled(Col)`
  color: gray;
`

const AppNameSpan = styled.span`
  margin-left: 5px;
  text-transform: capitalize;
`

const CustomRow = styled(Row)`
  align-items: center;
`

const AuthenticationListItemView = ( { 
  integrationApp,

  onConnect,
  onDisconnect,
}: IWorkspaceListItemView): JSX.Element => (
  <Card>
    <CustomRow>
      <WorkspaceTitle span={6}>
        <LogoImg name={integrationApp.name} /> <AppNameSpan>{integrationApp.name} </AppNameSpan>
        { integrationApp.isConnected && (integrationApp.integrationAccountsCount || 0 ) > 0 && (
          <span>
            ({integrationApp.integrationAccountsCount} workspace{ (integrationApp.integrationAccountsCount || 0) > 1 ? "s" : "" } connected)
          </span>

        )}
      </WorkspaceTitle>

      <WorkspaceInfo span={5}>
        <StatusBadge status={integrationApp.isConnected} /> { integrationApp.isConnected ? 'Authenticated' : 'Not authenticated' }
      </WorkspaceInfo>

      <WorkspaceInfo span={10}>
        { integrationApp.isConnected && (
          <span>Authenticated as {integrationApp.externalUsername} ({integrationApp.name}) </span>
        )}
      </WorkspaceInfo>

      <WorkspaceActions span={1}>
        { integrationApp.isConnected ? (
          <Button onClick={ () => { onDisconnect(integrationApp) }}> Disconnect </Button>
        ) : (
          <Button onClick={ () => { onConnect(integrationApp) }} type="primary"> Authenticate </Button>
        )}
      </WorkspaceActions>

    </CustomRow>
  </Card>
)

export default AuthenticationListItemView