import { useHistory, useLocation } from "react-router-dom"
import AuthLayout from "../components/auth-layout"
import { useApiCall } from "hooks/useApiCall"
import useApiClient from "hooks/useApiClient"
import { useEffect } from "react"
import { Button, Divider, Spin, message } from "antd"
import { usePeekedInvitation } from "hooks/confirm-invitations"
import { useThemeConfig } from "services/layout/layout.hooks"
import { TApiAccount } from "@timenotes/shared/src/services/api-client/accounts-api"
import DeleteUserView from "../components/delete-user-view"
import { useAfterLoginRedirect } from "hooks/auth.hooks"

const SelectAccountPage = () => {
  const { themeConfig } = useThemeConfig()

  const theme = themeConfig.currentTheme
  const history = useHistory()
  const location = useLocation()
  const apiClient = useApiClient()

  const {
    resolvePeekedInvitationOnLogin,
  } = usePeekedInvitation()

  const afterLoginRedirect = useAfterLoginRedirect()

  let errorMessage = location.state?.errorMessage || null

  apiClient.loadAccessCredentials()

  if (!apiClient.accessToken) {
    history.push('/auth/login')
  }

  const {
    data: accounts,
    loading,
    request: fetchAccounts,
  } = useApiCall(async () => {
    return (await apiClient.getAccountsList()).accounts
  })

  useEffect(() => {
    fetchAccounts()
  }, [])

  const onSelectAccount = async (account: TApiAccount) => {
    apiClient.setAccessCredentials({ accountId: account.id })

    if (await resolvePeekedInvitationOnLogin()) {
      return
    }

    afterLoginRedirect()
  }

  const onCreateWorkspace = () => {
    history.push('/auth/create-account')
  }

  useEffect(() => {
    if (errorMessage) {
      message.error(errorMessage, 5)
    }
  }, [])

  return (
    <AuthLayout>

      <div className="text-center">
        <img className="img-fluid" src={`/img/${theme === 'light' ? 'logo.png' : 'logo-white.png'}`} alt="" />
        <h3>Select workspace </h3>
      </div>

      <div>
        <div>
          {loading ? (
            <div 
              style={{
                display: 'flex',
                  justifyContent: 'center',
              }}
            >
              <Spin />
            </div>
          ) : (
            <>
              {
                accounts?.length == 0 ? (
                  <DeleteUserView />
                ) : (accounts || []).map(account => {
                return (
                  <div
                    key={account.id}
                    style={{
                      textAlign: 'left',
                    }}
                  >
                    <Divider />
                    <Button
                      onClick={() => { onSelectAccount(account) }}
                      style={{
                        textAlign: 'left',
                          width: '100%',
                      }}
                    >
                      {account.name}
                    </Button>
                  </div>
                )
              })}

            </>
          )}
        </div>
      </div>

      <div>
        <Divider>
          <span className="text-muted font-size-base font-weight-normal">or create new one</span>
        </Divider>
        <div className="d-flex justify-content-center">
          <Button
            onClick={() => onCreateWorkspace()}
            className="mr-2"
            style={{
              width: '100%',
            }}
          >
            Create workspace
          </Button>
        </div>
      </div>
    </AuthLayout>
  )
}

export default SelectAccountPage
