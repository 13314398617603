import moment from 'moment'

class Convert {

  static apiDateToMoment(date, timeZone) {
    if (!timeZone) {
      return moment.utc(date).local(true)
    } else {
      return moment.utc(date).tz(timeZone, true).local()
    }
  }
}

export default Convert
