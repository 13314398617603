import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiProject, TApiTask } from "../../api-client/types"
import { TApiGenericError, TApiGenericResponse } from "../../timenotes-query/timenotes-query.types"
import { TApiProjectsGroup } from "../../api-client/projects-groups-api"

interface Args {
  projectId: string
  projectsGroupId: string
}

interface Result {
  projectsGroup: TApiProjectsGroup
}

export const useDeleteProjectsGroupMutation = (options: any = {}) => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  const query = useMutation<Result, TApiGenericError<TApiProjectsGroup>, Args>(
    ['projects-groups', 'delete'],
    async (args) => {
      const response = await apiClient.deleteProjectsGroup(args.projectId, args.projectsGroupId)

      if (!response.ok) {
        throw response.errors
      }

      return {
        projectsGroup: response.projectsGroup,
      }
    },
    {
      ...options,
      onSuccess: (data: Result, args: Args) => {
        queryClient.invalidateQueries('projects-groups', { exact: false })
        queryClient.invalidateQueries('projects-users', { exact: false })

        if (options.onSuccess) {
          options.onSuccess(data, args)
        }
      },
    },
  )

  return {
    mutation: query
  }
}

export default useDeleteProjectsGroupMutation