import { Button, Checkbox, Pagination, Table, TableColumnType, TableProps, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, Key, SortOrder, TablePaginationConfig, TableRowSelection } from "antd/lib/table/interface";
import GenericButtonsList, { TGenericButton } from "components/generic-buttons-list/generic-buttons-list";
import { map } from "lodash";
import React from "react";
import ReactJson from "react-json-view";
import { TApiPagination } from "services/api-client/types";
import styled from "styled-components";


export interface GenericTableParams {
  pagination: TApiPagination | null
  sorting: {
    key: string
    order: 'asc' | 'desc'
  } | null
  selectedRowKeys: string[]
  selectedAll: boolean
}

interface IGenericTableProps<RecordType> {
  tableProps: TableProps<RecordType>
  tableParams: GenericTableParams

  pageSize?: number

  bulkButtons?: TGenericButton[]
  itemActions?(record: RecordType): TGenericButton[]

  onChange(params: GenericTableParams): void
  rowColorSelector?(record: RecordType): string
  onRowClick?(record: RecordType): void
}

export interface IGenericTableState {
}

interface ITableWrapper {
  rowClickable?: boolean
}

const TableWrapper = styled.div<ITableWrapper>`
  .timenotes-generic-table thead tr th div.ant-table-column-sorters {
    height: 50px; 
  }

  .timenotes-generic-table thead tr th.ant-table-selection-column {
    padding-left: 4px;
    border-left: 6px solid #E5E5EB;
    height: 50px; 
  }

  .timenotes-generic-table .ant-table-title {
    height: 57px;

    padding-left: 5px;
    border-left: 6px solid #E5E5EB;
  }


  .timenotes-generic-table tr.collor-row td:first-child {
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;

    // nasty hack
    height: 1px;
  }

  .timenotes-generic-table tr {
    ${(props) => props.rowClickable ? "cursor: pointer;" : ""}
  }

`

const ColorBorder = ({ children, color }: {
  children: React.ReactNode,
  color: string,
}) => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingLeft: '5px',
    paddingBottom: '6px',
    borderLeft: `6px solid ${color}`
  }}>
    {children}
  </div>
)

class GenericTable<RecordType extends TableProps<RecordType>> extends React.Component<IGenericTableProps<RecordType>, IGenericTableState> {

  constructor(props: IGenericTableProps<RecordType>) {
    super(props)

    this.state = {
    }
  }

  get selectedRowKeys() {
    return this.props.tableParams?.selectedRowKeys || []
  }

  getDataSource = () => {
    return []
  }

  getColumns = () => {
    const COLUMNS: ColumnsType<RecordType> = map(this.props.tableProps.columns, (column, index) => {
      const extendedColumn = { ...column }

      // Apply color border if color selector specified
      if (this.props.rowColorSelector && index == 0 && !this.getRowSelection() ) {
        extendedColumn.render = (t, record, index) => (
          <ColorBorder color={this.props.rowColorSelector!(record)}>
            { column.render!(t, record, index) }
          </ColorBorder>
        )
      }

      // Apply sorting from outside if provided
      if (this.props.tableParams?.sorting || this.props.tableParams?.sorting === null) {
        if (this.props.tableParams.sorting !== null && this.props.tableParams.sorting.key == extendedColumn.key) {
          extendedColumn.sortOrder = this.props.tableParams.sorting.order == 'asc' ? 'ascend' : 'descend'
        } else {
          extendedColumn.sortOrder = undefined
        }
      }

      return extendedColumn
    })

    // Add generic item actions column if itemActions prop is provided
    if (!!this.props.itemActions ) {
      COLUMNS.push({
        title: '', // Actions
        render: (t: string, record: RecordType) => {

          if (this.props.itemActions) {
            return (
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: '10px' }}>
                <GenericButtonsList buttons={this.props.itemActions(record)} />
              </div>
            )
          } else {
            return null
          }
        }
      })
    }


    return COLUMNS
  }

  getOnRow = (record: RecordType, rowIndex: number | undefined) => {
    return {
      onClick: () => { 
        if (this.props.onRowClick) {
          this.props.onRowClick(record)
        }
      },
    }
  }

  getRowSelection = (): TableRowSelection<RecordType> | undefined => {
    //return undefined

    // If bulkButtons not provided, turn off the selection of the table
    if (!this.props.bulkButtons) {
      return undefined
    }

    return {
      selectedRowKeys: this.selectedRowKeys,
      onChange: this.onSelectChange,
      renderCell: (value, record, index, originNode) => {
        if (this.props.rowColorSelector) {
          return (
            <ColorBorder color={this.props.rowColorSelector(record)}>
              {originNode}
            </ColorBorder>
          )
        } else {
          return originNode
        }
      },
    }
  }

  onSelectChange = (selectedRowKeys: Key[], selectedRows: RecordType[]) => {
    const udpatedTableProps = this.getUpdatedTableParams({ selectedRowKeys: selectedRowKeys as string[] })
    const currentSelectedAll = this.props.tableParams.selectedAll

    // If selectedAll including other pagination pages is selected and user is currently deselecting item - unselect all
    if (currentSelectedAll && selectedRowKeys.length < (this.props.tableProps.dataSource?.length || 0)) {
      udpatedTableProps['selectedAll'] = false
    }

    this.props.onChange(udpatedTableProps)
  }

  getUpdatedTableParams = (update: Partial<GenericTableParams>): GenericTableParams => {
    const tableParams: GenericTableParams = {
      pagination: this.props.tableParams?.pagination || null, 
      sorting: this.props.tableParams.sorting || null,
      selectedAll: this.props.tableParams.selectedAll || false,
      selectedRowKeys: this.selectedRowKeys,
      ...update
    }

    return tableParams
  }

  // Support only sorter
  handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any ) => {
    const newSorting = sorter.order ? { 
      key: sorter.columnKey as string,
      order: sorter.order == 'ascend' ? 'asc' : 'desc' as 'asc' | 'desc'
    } : null

    this.props.onChange(this.getUpdatedTableParams({
      sorting: newSorting,
    }))
  }

  bulkAllCheckbox = () => {
    const indeterminate = (this.selectedRowKeys.length > 0) && (this.props.tableParams.selectedAll == false)

    return (
      <Checkbox
        indeterminate={indeterminate}
        checked={ this.props.tableParams.selectedAll ? this.props.tableParams.selectedAll : undefined }
        onChange={(e) => { 
          const newState: Partial<GenericTableParams>= {}

          // If select all including other pagination pages is selected - clean the table on toggle

          if (this.props.tableParams.selectedAll) {
            newState['selectedAll'] = false
            newState['selectedRowKeys'] = []
          //  If select all is not selected, but the whole page still is - also clean the table
          } else if (this.selectedRowKeys.length == this.props.tableProps.dataSource?.length) {
            newState['selectedAll'] = false
            newState['selectedRowKeys'] = []
          // If some or none items are selected, select all items but only on this pagination page
          } else {
            newState['selectedRowKeys'] = map(this.props.tableProps.dataSource, (object) => { return object.id || '' }) || []
          }

          this.props.onChange(this.getUpdatedTableParams({
            ...newState
          }))
        }}
      />
    )
  }

  bulksHeader = () => {
    let bulkButtons: TGenericButton[] = [] // this.props.bulkButtons || ([] as TGenericButton[])

    // If pagination has more pages than the one visible and selectedAll option is not selected yet - display the button to do it
    if (!this.props.tableParams.selectedAll && this.props.tableParams.pagination?.totalPages && this.props.tableParams.pagination.totalPages > 1) {
      const allItemsCount = this.props.tableParams.pagination.totalCount

      bulkButtons = [
        ...this.props.bulkButtons || [],
        {
          title: `Select all ${allItemsCount} items from the table, not only current page`,
          callback: () => { 
            this.props.onChange(this.getUpdatedTableParams({
              selectedAll: true,
              selectedRowKeys: map(this.props.tableProps.dataSource, (object) => { return object.id || '' }) || [],
            }))

          },
          size: 'small',
        }
      ]
    } else {
      bulkButtons = this.props.bulkButtons || []
    }

    return (
      <div style={{
        display: 'flex',
        gap: '20px',
      }}>
        <div>
          {this.bulkAllCheckbox()}
        </div>

        { bulkButtons && (
          <div>
            <GenericButtonsList buttons={bulkButtons}/>
          </div>
        )}
      </div>
    )
  }

  getTitle = () => {
    const CustomTitle = this.props.tableProps.title

    if (this.selectedRowKeys.length == 0) {
      return CustomTitle // nothing if undefined
    } else {
      return () => (
        <div>
          {this.bulksHeader()}
        </div>
      )
    }
  }

  render = () => {

    return (
      <TableWrapper 
        rowClickable={!!this.props.onRowClick}
      >
        <Table<RecordType>
          className="timenotes-generic-table"
          rowSelection={this.getRowSelection()}
          onRow={this.getOnRow}
          rowClassName={(record) => { 
            return this.props.rowColorSelector ? 'collor-row' : ''
          }}
          dataSource={this.getDataSource() || []}
          onChange={this.handleTableChange}
          pagination={false}

          {...this.props.tableProps}

          showHeader = { this.props.tableProps.showHeader == false ? false : this.selectedRowKeys.length == 0 }

          title={ this.getTitle() }

          columns={this.getColumns()}
        />

        <br />

        <div style={{ display: 'flex', justifyContent: 'right' }} >
          { this.props.tableParams?.pagination && (
            <Pagination
              current={this.props.tableParams.pagination.currentPage || undefined}
              pageSize={this.props.tableParams.pagination.pageSize || 10}
              total={this.props.tableParams.pagination.totalCount || undefined}
              showSizeChanger={false}
              onChange={(page) => {
                this.props.onChange(this.getUpdatedTableParams({
                  selectedAll: false,
                  selectedRowKeys: [],
                  pagination: { 
                    ...this.props.tableParams.pagination as TApiPagination,
                    currentPage: page,
                  }
                }))
              }}
            />
          )}
        </div>
      </TableWrapper>
    )
  }

}

export default GenericTable