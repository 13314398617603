import { TApiResponse, TApiParams } from "./types"
import BaseApi, { getQueryString } from './base'

export type TApiTimesheetCell = {
  absences: Array<TTimesheetAbsence>
  date: string
  worktime: number
}

export type TApiTimesheetRow = {
  cells: Array<TApiTimesheetCell>
  groupClass: string
  groupId: string
  rows: Array<TApiTimesheetRow>
  shortTitle: string
  title: string
  totalWorktime: number
}

export interface IGetTimesheetsParams extends TApiParams {
  grouping: null | object
  filters: null | object
  rounding: null | object
}

export interface IGetTimesheetsResponse extends TApiResponse {
  dates: Array<string>
  rows: Array<TApiTimesheetRow>
  summaryRow: TApiTimesheetRow
}

export interface IGetTimesheetsCellParams extends IGetTimesheetsParams {
  cell: {
    groupClass: string
    groupId: string
    parentGroupClass: string
    parentGroupId: string
    date: string
  }
}

export interface IGetTimesheetsExportParams extends IGetTimesheetsParams {
  export: {
    type: string
  }
}

export type TTimesheetAbsence = {
  dateRange: string
  type: string
  icon: string
}

export interface IGetTimesheetsCellResponse extends TApiResponse {
  absences: Array<TTimesheetAbsence>
  cell: TApiTimesheetCell
  projects: Array<object> //id color name total_worktime
  tasks: Array<object> // id name total_worktime project(color id name)
  timeLogs: Array<object> //id project start_at duration description etc
  title: string
  totalWorktime: number
}


class TimesheetsApi extends BaseApi {
  async getTimesheets (params: IGetTimesheetsParams): Promise<IGetTimesheetsResponse> {
    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/timesheets${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async getTimesheetsCell (params: IGetTimesheetsCellParams): Promise<IGetTimesheetsCellResponse> {
    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/timesheets/cell${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async getTimesheetsExport (params: IGetTimesheetsExportParams) {
    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/timesheets/export${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    this.handleBlobDownload(response, `timenotes-timesheet.${params.export.type}`)
  }
}

export default TimesheetsApi
