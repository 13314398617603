import { Card, Col, Divider, message, Row } from "antd"
import useApprovalsNavigation from "./hooks/use-approvals-navigation"
import PageHeader from "components/layout/page-header"
import { useCurrentUser } from "hooks/current-user"
import moment from "moment-timezone"
import { useParams } from "react-router-dom"
import SummaryCard from "./components/summary-card"
import TimeLogsFilters from "components/time-logs-filters"
import DetailedReportsChart from "pages/reports/components/detailed-reports-chart"
import { useCallback, useEffect, useState } from "react"
import useApiClient from "hooks/useApiClient"
import PageLoadingSpin from "components/layout/page-loading-spin"
import getDaysBetweenDatesArray from "pages/projects/insights/utils/get-days-between-dates-array"
import TimerTable from "pages/timer/components/timer-table"
import ProjectTimeCard from "pages/projects/insights/components/project-time-card"
import ProjectBillableCard from "pages/projects/insights/components/project-billable-card"
import { useApprovalRequestDetailsQuery } from "@timenotes/shared/src/services/approvals/queries/use-approval-request-details-query"
import { TApiTimeLog } from "@timenotes/shared/src/services/api-client/types"
import { capitalize, filter, forEach } from "lodash"
import { useTimeLogsFiltersWithParams } from "services/reports-filters/reports-filters.utils"
import ApprovalRequestButtons from "./components/approval-request-buttons"
import { usePagePermissions } from "hooks/permissions.hooks"
import ApprovalNotesIcon from "./components/approval-notes-icon"

const MembersPeriodDetailsPage = () => {
  const { currentUser } = useCurrentUser()

  const [filters, setFilters] = useTimeLogsFiltersWithParams()

  const { permissions } = usePagePermissions()
  const canSeeBillable = !!permissions?.filterBillableTimelogs 

  const {
    periodId,
    usersAccountId,
  } = useParams<{
    periodId: string
    usersAccountId: string
  }>()

  const loadingTotals = {
    billableAmount: '1',
    costAmount: '1',
    billableDuration: 0,
    duration: 0,
  }

  const [totals, setTotals] = useState(loadingTotals)
  const [timeLogs, setTimeLogs] = useState<TApiTimeLog[]>([])

  const {
    approvalRequest,
    approvalRequestDetailsQuery,
  } = useApprovalRequestDetailsQuery({
    usersAccountId: usersAccountId || currentUser?.id,
    periodId: periodId,
  })

  useEffect(() => {
    if (approvalRequestDetailsQuery.isError) {
      message.error(JSON.stringify(approvalRequestDetailsQuery.error))
    }
  },[approvalRequestDetailsQuery.isError])

  useApprovalsNavigation()

  const apiClient = useApiClient()

  const periodStartDate = approvalRequest?.approvalsPeriod?.startDate
  const periodEndDate = approvalRequest?.approvalsPeriod?.endDate
  const periodDates = getDaysBetweenDatesArray(periodStartDate, periodEndDate)

  const timeLogsForDates: any = {}

  forEach(periodDates, (date) => {
    const dateString = date.format('YYYY.MM.DD')
    timeLogsForDates[dateString] = filter(timeLogs, (timeLog) => { return moment(timeLog.startAt).format('YYYY.MM.DD') == dateString }) 
  })

  const [chartData, setChartData] = useState(undefined)

  const requestParams = {
    perPage: 1000,
    filters: {
      ...filters,
      approvals: {
        approvalsPeriodId: periodId,
        usersAccountId: usersAccountId,
      }
    }
  }

  const [isDataLoading, setIsDataLoading] = useState(false)

  const fetchData = useCallback(() => {
    if (periodId && usersAccountId) {

      setIsDataLoading(true)

      const promise1 = apiClient.getReportsDetailedChart(requestParams).then((response) => {
        setChartData(response)
      })

      const promise2 = apiClient.getReportsDetailed(requestParams).then((response) => {
        setTotals({
          billableAmount: `${(response.totals.billableAmount?.cents || 0) / 100}`,
          costAmount: `${(response.totals.costAmount?.cents || 0) / 100}`,
          billableDuration: response.totals.worktimeBillable,
          duration: response.totals.worktime,
        })

        setTimeLogs(response.rows as unknown as TApiTimeLog[])
      })

      Promise.all([promise1, promise2]).finally(() => {
        setIsDataLoading(false)
      })
    }
  }, [periodId, usersAccountId, JSON.stringify(filters)])

  useEffect(() => {
    fetchData()
 }, [periodId, usersAccountId, JSON.stringify(filters)])

  if (chartData === undefined || !approvalRequest) {
    return <PageLoadingSpin />
  }

  const usersAccount = approvalRequest.usersAccount

  const fullName = `${usersAccount.firstName} ${usersAccount.lastName}`
  const title = `${fullName}`

  return (
    <>
      <div className="page-content">
        <PageHeader
          title={(
            <div>
              <div style={{ display: 'flex', gap: '15px' }}>
                <h1>{title}</h1>
                <div style={{ display: 'flex', gap: '15px', height: '42px', alignItems: 'center' }}>
                  <>
                    <SummaryCard
                      title="Period:"
                      value={approvalRequest.approvalsPeriod?.timespanString}
                    />
                    <SummaryCard
                      title="Status:"
                      value={capitalize(approvalRequest.status)}
                    />
                    <div style={{fontSize: '20px' }}><ApprovalNotesIcon notes={approvalRequest.notes} /></div>
                  </>
                </div>
              </div>
            </div>
          )}
          customHeader={(
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '30px',
                width: '100%',
              }}
            >
              <ApprovalRequestButtons 
                approvalRequest={approvalRequest}
              />
           </div>
          )}
        />

        <Divider />

        <Row>
          { !canSeeBillable ? (
            <>
              <Col span="24">
                <ProjectTimeCard projectInsights={{ totals: totals }} />
              </Col>
            </>
          ): (
            <>

              <Col span="12" style={{ paddingRight: '20px' }}>
                <ProjectTimeCard projectInsights={{ totals: totals }} />
              </Col>

              <Col span="12" style={{ paddingLeft: '20px' }}>
                <ProjectBillableCard projectInsights={{ totals: totals }} />
              </Col>
            </>
          )}
       </Row>

        {
          /*
       <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '30px',
        }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '15px',
              }}
            >
              <SummaryCard
                title="Total Time:"
                value="100h 25m"
              />

              <SummaryCard
                title="Billable Time:"
                value="80h"
              />

              <SummaryCard
                title="Non-Billable Time:"
                value="20h 25m"
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '15px',
              }}
            >
              <SummaryCard
                title="Billable Amount:"
                value="5250 USD"
              />

              <SummaryCard
                title="Cost Amount:"
                value="1850 USD"
              />

              <SummaryCard
                title="Profit:"
                value="1250 USD"
              />

            </div>
        </div>
          */
        }

        <br />

        <DetailedReportsChart
          data={chartData}
        />

        <Card>
          <TimeLogsFilters 
            canFilterByTeam={false}
            filters={filters} 
            onChange={setFilters} 
          />
        </Card>

        <Divider />

        {periodDates.map((periodDate) => {
          const timeLogs = timeLogsForDates[periodDate.format('YYYY.MM.DD')]

          return (
            <>
              <TimerTable
                timeLogs={timeLogs}
                date={periodDate}
                skipTracker
                onUpdate={fetchData}
                isLoading={isDataLoading}

                initialValues={{
                  usersAccount: usersAccount
                }}
              />
              <Divider />
            </>
          )
        })}

     </div>
    </>
  )
}


export function DashboardTextCard({ title, description1, description2, color }: {
  title: string,
  description1?: string,
  description2?: string 
  color?: string,
 }) {
 const numberColor = color || '#3A3AA3'

 return (
   <Card style={{ display: 'flex', height: '144px', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
     <h5>{ title }</h5>
     { description1 && (
       <div>{description1 } </div>
     )}

     { description2 && (
       <div>{description2 } </div>
     )}
   </Card>
 )
}

export default MembersPeriodDetailsPage