import React, { useState, useEffect, useRef } from 'react';
import { Statistic } from 'antd';
import moment from 'moment-timezone';

interface DurationCounterProps {
  active: boolean;
  startedAt?: moment.Moment;
}

const DurationCounter: React.FC<DurationCounterProps> = ({ active, startedAt }) => {
  const [duration, setDuration] = useState<number | null>(null);
  const trackerInterval = useRef<null | NodeJS.Timeout>(null);

  const calcDuration = () => {
    if (!startedAt) {
      return null;
    }
    const now = moment().utc(true);
    const timeDifference = now.diff(startedAt, 'seconds');
    return Math.abs(timeDifference);
  };

  const setupCounter = () => {
    if (!trackerInterval.current) {
      trackerInterval.current = setInterval(() => {
        setDuration(calcDuration());
      }, 1000);
    }
  };

  const clearCounter = () => {
    if (trackerInterval.current) {
      clearInterval(trackerInterval.current);
      trackerInterval.current = null;
    }
  };

  useEffect(() => {
    if (active) {
      setupCounter();
    } else {
      clearCounter();
      setDuration(0)
    }

    return () => {
      clearCounter();
    };
  }, [active]);

  let durationString = '00:00:00';
  if (active && duration !== null) {
    durationString = formatTimeNew(duration);
  }

  return <Statistic value={durationString} />;
};

const formatTimeNew = (timeInSeconds: number): string => {
  let seconds = Math.floor(timeInSeconds % 60);
  let minutes = Math.floor((timeInSeconds / 60) % 60);
  let hours = Math.floor(timeInSeconds / 3600);

  const format = (num: number) => (num <= 9 ? '0' : '') + num;

  if (timeInSeconds < 60) {
    return `00:00:${format(seconds)}`;
  } else if (timeInSeconds < 3600) {
    return `00:${format(minutes)}:${format(seconds)}`;
  } else {
    return `${format(hours)}:${format(minutes)}:${format(seconds)}`;
  }
};



export default DurationCounter;
