import { Button, DatePicker, Form, Input, Select, Switch } from "antd"
import { useFormik } from "formik"
import ErrorMessage from "components/base/error-message"
import styled from "styled-components"
import moment from "moment-timezone"
import { IFormObject, WorkspaceSettingsFormProps } from "./workspace-settings-form"
import { LockOutlined, UnlockOutlined } from "@ant-design/icons"
import { useDefaultDateFormat } from "hooks/settings"

const TimesheetLockingForm = (props: WorkspaceSettingsFormProps) => {
  const { defaultDateFormat } = useDefaultDateFormat()

  const initialValues = props.initialValues
  const formik = useFormik<IFormObject>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      loggingLockedTill: initialValues.loggingLockedTill || moment().format('YYYY-MM-DD'),
    },
    onSubmit: async (values, formik) => {
      const response = await props.onSubmit(values)

      if (!response.ok && response.errors) {
        formik.setErrors(response.errors)
      }

      if (response.ok) {
        formik.resetForm({ values })
      }
    }
  })

  return (
    <Form
      onSubmitCapture={formik.handleSubmit}
      layout="vertical"
    >
      <Form.Item
        label="Lock timesheet"
      >
        <Switch
          style={{ width: '200px' }}
          checkedChildren={(
            <>
              <LockOutlined style={{ marginRight: '5px' }} />
              <span>Locked</span>
            </>
          )}
          unCheckedChildren={(
            <>
              <UnlockOutlined style={{ marginRight: '5px' }} />
              <span>Open</span>
            </>
          )}
          checked={formik.values.loggingLocked}
          onChange={(value) => { formik.setFieldValue('loggingLocked', value) }}
        />
      </Form.Item>

      <Form.Item
        label="Lock timesheet entries up to date"
        tooltip="When timesheet is locked, all the team members will not be allowed to add or edit any time entries after this date."
      >
        <DatePicker
          value={moment(formik.values.loggingLockedTill)}
          format={defaultDateFormat}
          onChange={(momentValue) => { 
            formik.setFieldValue('loggingLockedTill', momentValue?.format())
          }}
          allowClear={false}
        />
        <ErrorMessage msg={formik.errors.loggingLockedTill} />
      </Form.Item>

      <Form.Item
        label="Automatically update the timesheet lock date"
      >
        <Select
          value={formik.values.updateTypeLoggingLocked}
          onChange={(value) => { 
            formik.setFieldValue('updateValueLoggingLocked', 1)
            formik.setFieldValue('updateTypeLoggingLocked', value)
          }}
          allowClear={false}
        >
          <Select.Option key="disabled" value="disabled">Disabled</Select.Option>
          <Select.Option key="after_day" value="after_day">Lock time tracking for entries older than N days</Select.Option>
          <Select.Option key="week_day" value="week_day">Lock time tracking in weekly periods</Select.Option>
          <Select.Option key="month_day" value="month_day">Lock time tracking in monthly periods</Select.Option>
        </Select>

       <ErrorMessage msg={formik.errors.updateTypeLoggingLocked} />
      </Form.Item>

      {formik.values.updateTypeLoggingLocked != 'disabled' && (

        <div>
          { formik.values.updateTypeLoggingLocked == 'after_day' && (
            <Form.Item
              label="How many days to the past users can track time?"
            >
              <Input
                type="number"
                value={formik.values.updateValueLoggingLocked ? `${formik.values.updateValueLoggingLocked}` : ""}
                onChange={(e) => {
                  formik.setFieldValue('updateValueLoggingLocked', parseInt(e.target.value))
                }}
                allowClear={false}
              />
              <ErrorMessage msg={formik.errors.updateValueLoggingLocked} />
            </Form.Item>
          )}

          { formik.values.updateTypeLoggingLocked == 'week_day' && (
            <Form.Item
              label="On which week day timesheet should be locked?"
            >
              <Select
                value={formik.values.updateValueLoggingLocked ? `${formik.values.updateValueLoggingLocked}` : ""}
                onChange={(value) => {
                  formik.setFieldValue('updateValueLoggingLocked', parseInt(value as string))
                }}
              >
                <Select.Option key="1" value="1">Monday</Select.Option>
                <Select.Option key="2" value="2">Tuesday</Select.Option>
                <Select.Option key="3" value="3">Wednesday</Select.Option>
                <Select.Option key="4" value="4">Thursday</Select.Option>
                <Select.Option key="5" value="5">Friday</Select.Option>
                <Select.Option key="6" value="6">Saturday</Select.Option>
                <Select.Option key="7" value="0">Sunday</Select.Option>
              </Select>
             <ErrorMessage msg={formik.errors.updateValueLoggingLocked} />
            </Form.Item>
          )}

          { formik.values.updateTypeLoggingLocked == 'month_day' && (
            <Form.Item
              label="On which day of the month timesheet should be locked?"
            >
              <Select
                value={formik.values.updateValueLoggingLocked || ""}
                onChange={(value) => {
                  formik.setFieldValue('updateValueLoggingLocked', value)
                }}
                showSearch={true}
              >
                {Array.from(Array(31).keys()).map((i) => {
                  const dayOfMonth = i+1
                  return (
                    <Select.Option key={dayOfMonth} value={dayOfMonth}>{dayOfMonth}</Select.Option>
                  )
                })}
              </Select>
             <ErrorMessage msg={formik.errors.updateValueLoggingLocked} />
            </Form.Item>
          )}


        </div>
      )}

      <div style={{ 
        marginTop: '20px',
        display: 'flex', 
        justifyContent: 'flex-end' 
      }}>
        <Button
          type={formik.dirty ? 'primary' : 'default'}
          htmlType="submit"
          disabled={!formik.dirty || formik.isSubmitting}
          loading={formik.isSubmitting}
          onClick={() => {
            formik.submitForm()
          }}
        >
          Update
        </Button>
      </div>
    </Form>
  )
}

const CheckboxWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`

export default TimesheetLockingForm
