import { Card, Skeleton } from "antd"
import PageHeader from "components/layout/page-header"
import { useState } from "react"
import { useAbsencesNavigationLinks } from "./shared"
import { useAbsenceRequests, useFreeDays } from "hooks/absences"
import AbsencesCalendar from "pages/absences/components/absences-calendar"
import moment, { Moment } from "moment-timezone"
import useNoInitialEffect from "hooks/useNoInitialEffect"
import AbsencesFilters from "pages/absences/components/absences-filters"
import AbsenceRequestModal from "pages/absences/components/absence-request-modal"
import MonthPicker from "components/base/month-picker"
import { usePagePermissions } from "hooks/permissions.hooks"

const DATE_FORMAT = 'YYYY-MM-DD'

const AbsencesCalendarPage = (props: any) => {
  useAbsencesNavigationLinks(props.history)

  const { permissions } = usePagePermissions()
  const [newAbsenceRequestModalVisible, setNewAbsenceRequestModalVisible] = useState(false)

  const [filtersMonth, setFiltersMonth] = useState<string>(moment().format())
  const filtersMonthMoment = moment(filtersMonth)

  const { freeDays, setFilters: setFreeDaysFilters } = useFreeDays({ filters: { year: filtersMonthMoment.year().toString() }})

  const setFiltersMonthMoment = (momentMonth: Moment) => {
    setFiltersMonth(momentMonth.format(DATE_FORMAT))
    setFreeDaysFilters({ year: momentMonth.year().toString() })
  }


  const { absenceRequests, fetchAbsenceRequests, loading, errors, filters, setFilters, filtersPeriod, setFiltersPeriod } = useAbsenceRequests({
    all: true,
    useMyAbsences: !permissions.manageAbsenceRequests,
    filters: {
      period: momentToMonthPeriod(filtersMonthMoment)
    }
  })

  useNoInitialEffect(() => {
    setFiltersPeriod(momentToMonthPeriod(filtersMonthMoment))
  }, [filtersMonth])

  return (
    <div>
      <div className="page-content">
        <PageHeader
          title="Absences Calendar"
          buttons={[
            {
              title: "Add absence",
              type: 'primary',
              callback: () => {
                setNewAbsenceRequestModalVisible(true)
               }
            }
          ]
          }
          customHeader={(
            <div
              style={{ display: 'flex', gap: '10px' }}
            >
              <MonthPicker 
                value={filtersMonthMoment}
                onChange={(newMoment) => setFiltersMonthMoment(newMoment as Moment)}
              />
            </div>
          )}
        />

        <Card>
          <AbsencesFilters 
            filters={filters} 
            onChange={setFilters}
            myAbsencesOnly={!permissions.manageAbsenceRequests}
          />
       </Card>

        <div style={{ height: '25px' }} />

        {loading ? (
          <Card>
            <Skeleton />
            <Skeleton />
          </Card>
        ) : (
          <Card>
            <AbsencesCalendar 
              monthMoment={filtersMonthMoment}
              absences={absenceRequests} 
              freeDays={freeDays}
              fetchAbsenceRequests={fetchAbsenceRequests}
            />
          </Card>
        )}

        <AbsenceRequestModal 
          visible={newAbsenceRequestModalVisible}
          onCancel={() => { 
            setNewAbsenceRequestModalVisible(false)
          }}
          onSubmit={() => {
            fetchAbsenceRequests()
            setNewAbsenceRequestModalVisible(false)
          }}
        />
      </div>
    </div>
  )
}

const momentToMonthPeriod = (momentDate: Moment): TApiPeriod => {
  return {
    from: moment(momentDate).startOf('month').startOf('week').format(DATE_FORMAT),
    to: moment(momentDate).endOf('month').endOf('week').format(DATE_FORMAT),
  }
}

export default AbsencesCalendarPage