import { Checkbox, Input, Modal, message } from "antd"
import ErrorMessage from "components/base/error-message"
import Flex from "components/shared-components/Flex"
import { useFormik } from "formik"
import { useDefaultCurrencyIso } from "hooks/settings"
import useApiClient from "hooks/useApiClient"
import { useEffect, useState } from "react"
import { IBulkUpdateRatesParams } from "services/api-client/bulks-time-logs-api"

type Props = React.ComponentProps<typeof Modal> & {
  bulkParams: any
  onSuccess(): void
}

const BulkEditRatesModal = (props: Props) => {
  const apiClient = useApiClient()

  const [editBillable, setEditBillable] = useState(false) 
  const [editCost, setEditCost] = useState(false) 

  const { defaultCurrencyIso } = useDefaultCurrencyIso()

  // Reset modal state on modal visibility change
  useEffect(() => {
    setEditBillable(false)
    setEditCost(false)
    formik.resetForm()
  }, [props.visible])

  const formik = useFormik<IBulkUpdateRatesParams['timeLog']>({
    initialValues: {
      billableRate: undefined,
      costRate: undefined,
    },
    onSubmit: async (values) => {
      const params: IBulkUpdateRatesParams['timeLog'] = {}

      if (editBillable) params.billableRate = values.billableRate
      if (editCost) params.costRate = values.costRate

      const response = await apiClient.bulkUpdateRates({
        ...props.bulkParams,
        timeLog: params
      })

      if (response.ok) {
        message.success("Bulk rates update in progress. This might take a couple minutes...")
        if (props.onSuccess) props.onSuccess()
      } else {
        formik.setErrors((response.errors || {}) as any)
      }
    }
  })

  return (
    <Modal
      title="Bulk edit rates"
      className="bulk-modal"
      okText="Update rates"
      onOk={formik.submitForm}
      confirmLoading={formik.isSubmitting}
      {...props}
    >
      <div 
        className="update-modal"
      >
        <p>
          This will update the historical rates for all the selected time logs. Use with caution, the old rates can not be restored.
        </p>

        <br />

        <Flex>
          <div className="label-wrapper">
            <Checkbox checked={editBillable} onChange={(e) => setEditBillable(e.target.checked) }>
              Billable Rate
            </Checkbox>
          </div>

          <Input
            type="number"
            disabled={!editBillable}
            value={formik.values.billableRate}
            placeholder={"e.g. 100.00"}
            suffix={defaultCurrencyIso}
            onChange={(e) => {
              formik.setFieldValue('billableRate', e.target.value)
            }}
          />
          <ErrorMessage msg={formik.errors.billableRate} />
        </Flex>

        <br />

        <Flex>
          <div className="label-wrapper">
            <Checkbox checked={editCost} onChange={(e) => setEditCost(e.target.checked) }>
              Cost Rate
            </Checkbox>
          </div>

          <Input
            type="number"
            disabled={!editCost}
            value={formik.values.costRate}
            suffix={defaultCurrencyIso}
            placeholder={"e.g. 50.00"}
            onChange={(e) => {
              formik.setFieldValue('costRate', e.target.value)
            }}
          />
          <ErrorMessage msg={formik.errors.costRate} />
        </Flex>

      </div>
    </Modal>
  )

}

export default BulkEditRatesModal