import { Modal } from "antd"
import { ZoomInOutlined } from '@ant-design/icons'
import { ColumnsType, TableProps } from "antd/lib/table"
import _map from 'lodash/map'
import { MoreSvg, DeleteSvg, EditSVG } from "assets/svg/icon"
import { IActionItem } from "components/shared/actions-panel"
import GenericTable, { GenericTableParams } from "components/generic-table/generic-table"
import { TGenericButton } from "components/generic-buttons-list/generic-buttons-list"
import { BillableAmountColumn, ClientNameColumn, CostColumn, DurationColumn, ProjectNameColumn, TagsColumn, TaskNameColumn, TeamMemberColumn, TimeLogDescriptionColumn, TimeLogTimeColumn } from "components/table-columns/table-columns"
import BillableFlag from "components/base/billable-flag"
import { useCurrentUser } from "hooks/current-user"
import { usePagePermissions } from "hooks/permissions.hooks"
import { TApiReportsDetailedRow } from "@timenotes/shared/src/services/api-client/reports-api"
import { TApiGrouping } from "@timenotes/shared/src/services/api-client/types"

interface IGetTimeRowActions {
  (teamMember: TApiReportsDetailedRow): IActionItem[]
}

const DetailedReportsTable = ({ 
  timeRows,
  tableProps,
  tableParams,
  groupingPrimary,
  onClick,
  onChange,
  onShowBulkEdit,
  onBulkDelete,
  onBulkRatesRecalculate,
  onShowBulkRatesEdit,
  onEditItem,
  onDeleteItem,
} : { 
  groupingPrimary: TApiGrouping['primary'],
  timeRows: TApiReportsDetailedRow[],
  tableProps?: TableProps<TApiReportsDetailedRow>,
  tableParams: GenericTableParams,
  onChange(params: GenericTableParams): void,
  onClick(member: TApiReportsDetailedRow): void,
  onShowBulkEdit(): void,
  onShowBulkRatesEdit(): void,
  onEditItem(record: TApiReportsDetailedRow): void,
  onDeleteItem?(id: string): void,
  onBulkDelete(): void,
  onBulkRatesRecalculate(): void,
 }) => {
  const { permissions } = usePagePermissions()
  const { currentUser } = useCurrentUser()

  const COLUMN_WIDTH = undefined // || '150px'

  let COLUMNS: ColumnsType<TApiReportsDetailedRow> = []

  // TEAM MEMBER
  // id (usersAccount), firstName, lastName, duration, costAmount, billableAmount

  // Client
  // id, name, duration, costAmount, billableAmount

  // Project
  // id, name, duration, color, costAmount, billableAmount

  // id, name, project*, duration, costAmount, billableAmount


  if (groupingPrimary == 'user') {
    COLUMNS = [
      {
        key: 'userName',
        title: 'Member',
        width: COLUMN_WIDTH,
        sorter: true,
        render: (t, record) => (<TeamMemberColumn firstName={record.firstName as string} lastName={record.lastName as string} />),
      },
   ]
  }

   if (groupingPrimary == 'client') {
    COLUMNS = [
      {
        key: 'clientName',
        title: 'Client',
        width: COLUMN_WIDTH,
        sorter: true,
        render: (t, record) => (<ClientNameColumn name={record.name as string} />),
      },
    ]
  }

  if (groupingPrimary == 'project') {
    COLUMNS = [
      {
        key: 'projectName',
        title: 'Project',
        width: COLUMN_WIDTH,
        sorter: true,
        render: (t, record) => (<ProjectNameColumn name={record.name as string} />),
      },
    ]
  }

  if (groupingPrimary == 'task') {
    COLUMNS = [
      {
        key: 'projectName',
        title: 'Project',
        width: COLUMN_WIDTH,
        sorter: true,
        render: (t, record) => (<ProjectNameColumn name={record.project.name } />),
      },
      {
        key: 'taskName',
        title: 'Task',
        width: COLUMN_WIDTH,
        sorter: true,
        render: (t, record) => (<TaskNameColumn name={record.name as string} />),
      },
    ]
  }


  if (groupingPrimary != 'no_group') {
    COLUMNS.push({
        key: 'totalTime',
        title: 'Duration',
        sorter: true,
        width: COLUMN_WIDTH,
        render: (t, record) => (<DurationColumn duration={record.duration} />),
      },
    )
  }
   
  if (groupingPrimary == 'no_group') {
    COLUMNS = [
      {
        key: 'projectName',
        title: 'Project',
        sorter: true,
        width: COLUMN_WIDTH,
        // onCell: (record) => ({ onClick: () => { onClick(record) } }),
        render: (t, record) => (<ProjectNameColumn name={record.project.name} origin={record.project.origin} />),
      },

      {
        key: 'taskName',
        title: 'Task',
        width: COLUMN_WIDTH,
        sorter: true,
        render: (t, record) => (<TaskNameColumn name={record.task.name} />),
      },

      {
        key: 'description',
        title: 'Description',
        width: COLUMN_WIDTH,
        render: (t, record) => (<TimeLogDescriptionColumn description={record.description} />),
      },

      {
        key: 'tags',
        title: 'Tags',
        width: COLUMN_WIDTH,
        render: (t, record) => (<TagsColumn tags={record.tags} />),
      },

      {
        key: 'totalTime',
        title: 'Duration',
        sorter: true,
        width: COLUMN_WIDTH,
        render: (t, record) => (<DurationColumn duration={record.duration} />),
      },

      {
        key: 'startAt',
        title: 'TIME',
        sorter: true,
        width: '165px',
        render: (t, record) => (<TimeLogTimeColumn startAt={record.startAt} endAt={record.endAt} />),
      }
    ]

    if (permissions?.viewAccessibleProjectsTimeLogs) {
      COLUMNS.splice(2, 0, {
        key: 'userName',
        title: 'Member',
        width: COLUMN_WIDTH,
        sorter: true,
        render: (t, record) => (<TeamMemberColumn firstName={record.usersAccount.firstName} lastName={record.usersAccount.lastName} />),
      })
    }
  }

  if (permissions?.filterBillableTimelogs) {
    COLUMNS.push({
      key: 'billableCents',
      title: 'BILLABLE AMOUNT',
      width: COLUMN_WIDTH,
      sorter: true,
      render: (t, record) => (
        <BillableAmountColumn 
          billableAmount={record.billableAmount}
          isBillable={record.isBillable}
        />),
    })

    COLUMNS.push({
      key: 'costCents',
      title: 'COST AMOUNT',
      width: COLUMN_WIDTH,
      sorter: true,
      render: (t, record) => (<CostColumn costAmount={record.costAmount} />),
    })
  } else if (permissions?.setTimeLogBillable) {
    COLUMNS.push({
      key: 'isBillable',
      title: 'BILLABLE?',
      width: COLUMN_WIDTH,
      sorter: false,
      render: (t, record) => (
        <BillableFlag 
          checked={record.isBillable} 
          clickable={false}
        />
      ),
    })
  }

  const handleTableChange = (p: any, f: any, sorter: any) => {
  }

  const canUpdateBillable = permissions?.setTimeLogBillable 

  const BulkButtons: TGenericButton[] = [
    {
      title: 'Edit all',
      callback: () => {
        onShowBulkEdit()
      },
      size: 'small',
    },
    ...(canUpdateBillable ? [
      {
        title: 'Update rates',
        callback: onShowBulkRatesEdit,
        size: 'small',
      },
      {
        title: 'Recalculate rates',
        callback: onBulkRatesRecalculate,
        size: 'small',
      }
    ] : []) as TGenericButton[],
    {
      title: 'Delete all',
      callback: onBulkDelete,
      size: 'small',
    },
  ]

  const ItemActions = (record: TApiReportsDetailedRow): TGenericButton[] => {
    if (groupingPrimary == 'no_group') {
      if (!permissions?.viewAccessibleProjectsTimeLogs && currentUser?.id !== record.usersAccount.id) {
        return [
          {
            title: '',
            icon: MoreSvg,
            disabled: true,
            type: 'text',
            disabledTooltip: 'You have no permissions to manage this entry!',
            callback: () => {}
          }
        ]
      }

      if (onDeleteItem) {
        return [
          {
            title: '',
            icon: MoreSvg,
            type: 'text',
            options: [
            {
                title: 'Edit',
                Icon: EditSVG,
                callback: () => {
                  onEditItem(record)
                }
              },

              {
                title: 'Delete',
                Icon: DeleteSvg,
                callback: () => {
                  Modal.confirm({
                    icon: null,
                    className: 'danger-modal',
                    title: `Delete the time log`,
                    content: `Are you sure you want to delete this time log?`,
                    okText: 'Delete',
                    onOk: () => { onDeleteItem(record.id) },
                  })
                }
              },
            ]
          },
        ]
      } 

      // default return? - should not really happen
      return []
    } else {
      return [
        {
          title: 'Time logs',
          icon: ZoomInOutlined, 
          callback: () => {
            onEditItem(record)
          }
        },
      ]
    }
  }

  return (
    <div>
      <GenericTable<TApiReportsDetailedRow>
        tableProps={{
          dataSource: timeRows,
          columns: COLUMNS,
          rowKey: (record) => { return record.hashId || record.id },
        }}

        tableParams={tableParams}

        rowColorSelector={(record) => {
          // @ts-ignore
          return record.color || record.project?.color || '#E5E5EB'
        }}

        bulkButtons={BulkButtons}
        itemActions={ItemActions}

        onChange={onChange}
      />
    </div>
  )
}

export default DetailedReportsTable