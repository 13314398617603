import React from 'react'
import { Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types'

const EllipsisDropdown = props => {
	return (
		<Dropdown overlay={props.menu} placement={props.placement} trigger={['click']}>
			<div className="ellipsis-dropdown timenotes-dots" >
        <svg width="25" height="26" viewBox="0 0 25 26" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 3.54167C11 4.05 11.2107 4.53751 11.5858 4.89696C11.9609 5.2564 12.4696 5.45833 13 5.45833C13.5304 5.45833 14.0391 5.2564 14.4142 4.89696C14.7893 4.53751 15 4.05 15 3.54167C15 3.03334 14.7893 2.54582 14.4142 2.18638C14.0391 1.82693 13.5304 1.625 13 1.625C12.4696 1.625 11.9609 1.82693 11.5858 2.18638C11.2107 2.54582 11 3.03334 11 3.54167ZM11 13.125C11 13.6333 11.2107 14.1208 11.5858 14.4803C11.9609 14.8397 12.4696 15.0417 13 15.0417C13.5304 15.0417 14.0391 14.8397 14.4142 14.4803C14.7893 14.1208 15 13.6333 15 13.125C15 12.6167 14.7893 12.1292 14.4142 11.7697C14.0391 11.4103 13.5304 11.2083 13 11.2083C12.4696 11.2083 11.9609 11.4103 11.5858 11.7697C11.2107 12.1292 11 12.6167 11 13.125ZM11 22.7083C11 23.2167 11.2107 23.7042 11.5858 24.0636C11.9609 24.4231 12.4696 24.625 13 24.625C13.5304 24.625 14.0391 24.4231 14.4142 24.0636C14.7893 23.7042 15 23.2167 15 22.7083C15 22.2 14.7893 21.7125 14.4142 21.353C14.0391 20.9936 13.5304 20.7917 13 20.7917C12.4696 20.7917 11.9609 20.9936 11.5858 21.353C11.2107 21.7125 11 22.2 11 22.7083Z" />
        </svg>
     </div>
		</Dropdown>
	)
}

EllipsisDropdown.propTypes = {
	trigger: PropTypes.string,
	placement: PropTypes.string
}

EllipsisDropdown.defaultProps = {
	trigger: 'click',
	placement: 'bottomRight',
	menu: <Menu/>
};

export default EllipsisDropdown
