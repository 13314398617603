import { useState } from 'react'
import { Card, Row, Col, Form, Input, Button, message, Divider } from "antd"
import { MailOutlined } from '@ant-design/icons'
import { backgroundStyle } from '../styles'
import { useFormik } from 'formik'
import useApiClient from 'hooks/useApiClient'
import ErrorMessage from 'components/base/error-message'
import AuthLayout from 'views/auth-views/components/auth-layout'
import { useHistory } from 'react-router-dom'
import { useQueryParams } from 'hooks/app-router'
import ResetTokenForm from './components/reset-token-form'
import { getApiErrorMessage } from '@timenotes/shared/src/services/api-client'

const ResetPasswordPage = () => {
  const apiClient = useApiClient()
  const [resetRequestSent, setResetRequestSent] = useState(false)

  const queryParams = useQueryParams<{ reset_token: string }>()
  const resetToken = queryParams.reset_token || null

  const formik = useFormik<{
    email: string
  }>({
    initialValues: {
      email: ""
    },
    onSubmit: async (values) => {
      const response = await apiClient.requestPasswordReset({ 
        email: values.email,
      })

      if (response.ok) {
        setResetRequestSent(true)
      } else {
        const errorMessage = getApiErrorMessage(response, 'email')
        message.error(errorMessage)
      }
    }
  })

  if (resetToken) return (<ResetTokenForm resetToken={resetToken} />)
  if (resetRequestSent) return (<ResetPasswordSent />)

  return (
    <div className="h-100" style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={9}>
            <Card>
              <div className="my-2">
                <div className="text-center">
                  <img className="img-fluid" src="/img/logo.png" alt="" />
                  <h3 className="mt-3 font-weight-bold">Forgot Password?</h3>
                  <p className="mb-4">Enter your Email to reset password</p>
                </div>
                <Row justify="center">
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <Form layout="vertical" name="forget-password" onSubmitCapture={formik.handleSubmit}>
                      <Form.Item
                        name="email"
                      >
                        <Input
                          placeholder="Email Address"
                          prefix={<MailOutlined className="text-primary" />}
                          onChange={formik.handleChange('email')}
                        />
                        <ErrorMessage msg={formik.errors.email} />
                      </Form.Item>
                      <Form.Item>
                        <Button 
                          loading={formik.isSubmitting}
                          type="primary" htmlType="submit" block>{formik.isSubmitting ? 'Sending' : 'Send'}</Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

const ResetPasswordSent = () => {
  const history = useHistory()

  return (
    <AuthLayout>
      <h1>Password reset!</h1>
      <p>Check your inbox and follow the link there in order to set up the new password.</p>

      <Divider />
      <a onClick={() => { history.push("/") }}>Go back to Timenotes</a>
    </AuthLayout>
  )
}

export default ResetPasswordPage
