import styled from 'styled-components'
import { Checkbox, Card, Button, Tag, Tooltip, Radio } from 'components/base'

import _map from 'lodash/map'
import { TApiIntegrationAccountsProject } from 'services/api-client/types'

const COLORS = {
  primary: '#07c5b7',

  cardHighlight: "#EFEFEF",
}

const AppCard = styled(Card)`
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 195px;
  height: 195px;
  margin-top: 52px;
  padding: 10px;

  & .card-logo {
    display: flex;
    justify-content: center;
  }

  & .card-app-name{
    margin-top: 5px;
    margin-bottom: 14px;
    font-size: 24px;
    text-align: center;
    text-transform: capitalize;
  }

  & img {
    margin: auto;
  }
`

const WorkspaceListViewWrapper = styled.div`
  margin-top: 26px;

  & p {
    color #474D55;
  }

  & label.ant-checkbox-wrapper {
    margin-left: 0px !important;
  }
`

const VerticalCheckboxFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & label {
    margin-bottom: 14px;
  }
`

const SubmitButton = styled(Button)`
  margin-top: 20px;
`

interface IonAppClick {
  (integrationApp: any): void
}

const SelectionSpan = styled.span`
  cursor: pointer;
`

export interface IProps {
  projects: Array<TApiIntegrationAccountsProject>
  selectedProjectOriginIds: Array<string>
  onSubmit?(): void
  submitText?: string
  onChange?(ids: Array<string>): void
  selectAll?(): void
  clearSelection?(): void
}


const ProjectsListView = ({ projects, selectedProjectOriginIds, submitText, onChange, onSubmit, selectAll, clearSelection }: IProps ): JSX.Element => {

  const projectOptions: Array<JSX.Element> = _map(projects, (project) => {
    const isChecked = selectedProjectOriginIds.includes(project.originIdentifier)

    return (
      <Checkbox  value={project.originIdentifier} key={project.originIdentifier} disabled={project.connected}>
        { project.name } 

        { (project.connected && !!project.id) && (
          <Tag style={{ marginLeft: '10px', opacity: '50%' }}><i> Project exists in Timenotes and is already connected. </i> </Tag>
        )}

        { (!project.connected && project.id) && (
          <Tag color="orange" style={{ marginLeft: '10px' }}> Project exists in Timenotes but is disconnected with integration. Check it to reconnect with existing project. </Tag>
        )}

      </Checkbox>
    )
  })

  return (
    <WorkspaceListViewWrapper>

      { projects.length > 0 ? (
        <>
          <p> Select: <SelectionSpan onClick={ () => { if (selectAll) { selectAll() } }}>ALL</SelectionSpan> / <SelectionSpan onClick={ () => { if (clearSelection) { clearSelection() } }}>NONE</SelectionSpan></p>

          <Checkbox.Group value={selectedProjectOriginIds} onChange={(val) => { if (onChange) onChange(val as Array<string>) } }>
            <VerticalCheckboxFlex>
              {projectOptions}
            </VerticalCheckboxFlex>
          </Checkbox.Group>
        </>
      ) : (
        <p>
          No projects
        </p>
      )}

      <br />

      <SubmitButton type="primary" onClick={() => { if (onSubmit) onSubmit() }}> { submitText ? submitText : 'Next' } </SubmitButton>
    </WorkspaceListViewWrapper>
  )
}

export default ProjectsListView