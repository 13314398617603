import { useQuery } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TQueryOptions } from "../../timenotes-query/timenotes-query.types"
import { IGetTimeLogsParams } from "../../api-client/time-logs-api"
import { TApiActiveTracker, TApiTimeLog } from "../../api-client/types"

type Params = {
  params?: any
  options?: TQueryOptions
}

export const useActiveTrackerQuery = ({ params, options }: Params = {}) => {
  const apiClient = useApiClient()

  const requestParams = {
    ...params,
  }

  const query = useQuery(
    ['active-tracker'],
    {
      queryFn: async () => {
        const response = await apiClient.getActiveTracker()

        if (!response.ok && response.status !== 404) {
          throw response.errors
        }

        return {
          activeTracker: response.activeTracker as TApiActiveTracker | undefined
        }
      },
      ...options,
    }
  )

  return query
}