import React, { Suspense, useEffect, useState } from "react"
import { useLocation, useHistory } from "react-router-dom"
import Routes from 'pages/routes'
import Loading from 'components/shared-components/Loading'
import { hotjar } from 'react-hotjar'
import { Alert } from "antd"
import PageLoadingSpin from "components/layout/page-loading-spin"
import ErrorBoundary from "components/layout/error-boundary"
import { useLoginSuccessRedirect } from "hooks/auth.hooks"
import { TimenotesIntercomManager } from "services/timenotes-intercom-provider"
import { NewProjectModalContextProvider } from "components/timenotes/projects/new-project-modal-context"
import { NewTaskModalContextProvider } from "components/timenotes/tasks/new-task-modal-context"
import { NewTagModalContextProvider } from "components/timenotes/tags/new-tag-modal"
import { useApiClient } from "@timenotes/shared/src/services/api-client/api-client.hooks"
import { usePermissionsQuery } from "@timenotes/shared/src/services/permissions/permissions.hooks"
import { useCurrentUserQuery } from "@timenotes/shared/src/services/users-accounts/queries/use-current-user-query"
import { useSettingsQuery } from "@timenotes/shared/src/services/settings/queries/use-settings-query"
import { message } from "components/base"
import moment from "moment-timezone"

const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID || null // 2839831

if (HOTJAR_ID) {
  window.addEventListener('load', function () {
    hotjar.initialize(HOTJAR_ID, 6);
  })
}

export const AppViews = () => {
  const history = useHistory()
  const apiClient = useApiClient()

  // TODO: Debug help
  window.ac = apiClient 

  const urlParams = new URLSearchParams(window.location.search)
  let accessToken = urlParams.get('access_token')
  let accountId = urlParams.get('access_account_id')

  if (!accessToken && !accessToken) {
    apiClient.loadAccessCredentials()
  }

  const [isAuthenticated, setIsAuthenticated] = useState(!!(apiClient.accessToken && apiClient.accountId))

  const {
    currentUserQuery
  } = useCurrentUserQuery({
    enabled: isAuthenticated,
    staleTime: Infinity
  })

  // TODO: Fix as options are not passed correctly!
  const permissionsQuery = usePermissionsQuery({}, {
    enabled: isAuthenticated,
    staleTime: Infinity,
  })

  const {
    settingsQuery
  } = useSettingsQuery({
    enabled: isAuthenticated,
    staleTime: Infinity,
    onSuccess: (data) => {
      // Update moment week start day number
      const weekStartDayNumber = data.weekStartDayNumber

      moment.updateLocale(moment.locale(), {
        week: {
          dow: weekStartDayNumber
        }
      })
    }
  })

  const [dataLoaded, setDataLoaded] = useState(false)

  useEffect(() => {
    if (currentUserQuery.isError && currentUserQuery.error.status >= 400 && currentUserQuery.error.status <= 499) {
      message.error("Your session expired, please login again.")
      apiClient.removeAccessCredentials()
      history.push('/auth/login')
    }

    if (permissionsQuery.isSuccess && settingsQuery.isSuccess && currentUserQuery.isSuccess) {
      setDataLoaded(true)
    }
  }, [permissionsQuery.status, settingsQuery.status, currentUserQuery.status])

  const loginSuccessRedirect = useLoginSuccessRedirect()

  useEffect(() => {
    // Some hack required in refactoring from class to hooks components
    // used in Timesheet and Reports
    // @ts-ignore 
    if (!window.appHistory) window.appHistory = history

    // If accessToken and AccountId is given in the URL 
    //    -> read it and store inside apiClient
    // if not
    //    -> try to load onces from the local storage

    if (accessToken || accountId) {
      apiClient.setAccessCredentials({ accessToken, accountId })
    } 

    if (!apiClient.accessToken) {
      history.push('/auth/login')
    }

    if (apiClient.accessToken && !apiClient.accountId) {
      loginSuccessRedirect().then(() => setIsAuthenticated(true) )
    }

  }, [])

  if (!dataLoaded) {
    return (
      <div>
        <PageLoadingSpin />
      </div>
    )
  }

  return (
    <div>
      {isAuthenticated ? (
        <Suspense fallback={<Loading cover="content" />}>
          <ErrorBoundary
            apiClient={apiClient}
          >
            <WorkspaceWrapper 
              dataLoaded={dataLoaded}
              permissions={permissionsQuery.data.permissions}
              currentUser={currentUserQuery.data.usersAccount}
            >
              <TimenotesIntercomManager>
                <NewProjectModalContextProvider>
                  <NewTaskModalContextProvider>
                    <NewTagModalContextProvider>
                      <Routes 
                        subscriptionValid={!!currentUserQuery.data.usersAccount.account.subscriptionValid}
                      />
                    </NewTagModalContextProvider>
                  </NewTaskModalContextProvider>
                </NewProjectModalContextProvider>
              </TimenotesIntercomManager>
            </WorkspaceWrapper>
          </ErrorBoundary>
        </Suspense>
      ) : (
        <div>
          <PageLoadingSpin />
        </div>
      )}
    </div>
  )
}

const WorkspaceWrapper = ({
  children,
  dataLoaded,
  permissions,
  currentUser,
}) => {
  const history = useHistory()
  const location = useLocation()

  const subscriptionValid = !!currentUser?.account.subscriptionValid

  useEffect(() => {
    if (!subscriptionValid && !location.pathname.match(/billing/) && dataLoaded) {

      // If admin - redirect him to fix workspace subscription
      if (permissions.manageSettings) {
        history.push('/settings/billing/payment-history')
      } else {
      // If user - logout him with appropriate message
        history.push({
          pathname: '/auth/select-account', 
          state: {
            errorMessage: `Workspace "${currentUser.account.name}" subscription is invalid. Please contact your workspace admininistrator to fix the subscription!`,
          }
        })
      }
    }
  }, [JSON.stringify(currentUser), dataLoaded, location.pathname])

  if (!dataLoaded) return <PageLoadingSpin />

  return (
    <>
      { !subscriptionValid && (
        <div style={{
          margin: '10px',
        }}>
          <Alert type="error" message="Your subscription is invalid, please select the plan and add a valid payment method." />
        </div>
      )}

      {children}
    </>
  )

}

export default React.memo(AppViews)
