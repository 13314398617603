import { UseQueryOptions, useQuery } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiParams } from "../../api-client/types"
import { TApiGenericError } from "../../timenotes-query/timenotes-query.types"
import { removeUndefinedValues } from "../../utils/remove-values"
import { TApiExportColumn } from "../../api-client/reports-api"

interface Params extends TApiParams {
}

interface Result {
  exportColumns: TApiExportColumn[]
}

export const useAbsenceRequestsExportColumns = (params?: Params, options?: UseQueryOptions<any, any>) => {
  const apiClient = useApiClient()

  const requestParams = removeUndefinedValues({
    ...params
  })

  const query = useQuery<Result, TApiGenericError<any>>(
    ['absence-requests-export-columns', requestParams] as const,
    async () => {
      const response = await apiClient.get(`/holidays/absence_requests/export_columns`, requestParams)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        exportColumns: response.exportColumns
      }
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      ...options,
    }
  )

  return {
    absenceRequestsExportColumnsQuery: query,
    absenceRequestsExportColumns: query.data?.exportColumns || [],
  }
}
