import { Button, DatePicker, Form, Input, Row, Col } from "antd"
import moment from "moment-timezone"
import { useFormik } from "formik"
import ErrorMessage from "components/base/error-message"
import { ICreateHolidaysAbsenceTypeResponse } from "services/api-client/holidays-absence-types-api"
import { TApiFreeDay } from "services/api-client/holidays-free-days-api"
import useApiClient from "hooks/useApiClient"

export interface IFreeDayFormObject extends Omit<TApiFreeDay, 'id'> {
  id?: string
}

export interface FreeDayFormProps {
  initialValues?: Partial<IFreeDayFormObject>
  onSubmit?(values: IFreeDayFormObject): void
  onSuccess?(response: ICreateHolidaysAbsenceTypeResponse): void
}

const FreeDayForm = (props: FreeDayFormProps) => {
  const apiClient = useApiClient()

  const formik = useFormik<IFreeDayFormObject>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      date: moment().format('YYYY.MM.DD'),
      ...props.initialValues,
    },
    onSubmit: (values) => {
      const apiCall = values.id ? apiClient.updateHolidaysFreeDay(values.id, values) : apiClient.createHolidaysFreeDay(values)

      apiCall.then((response) => {
        if (response.ok) {
          props.onSubmit && props.onSubmit(values)
          props.onSuccess && props.onSuccess(response)
          formik.resetForm()
        } else {
          const errors = {
            ...response.errors,
            holidaysAbsenceTypeId: response.errors?.absenceType,
            usersAccountId: response.errors?.usersAccount,
          }

          formik.setErrors(errors || {})
        }
      })
    },
  })

  return (
    <Form onSubmitCapture={formik.handleSubmit} layout="vertical">

      <Row>
        <Col span={11}>
          <Form.Item
            label="Name"
          >
            <Input
              value={formik.values.name}
              onChange={(e) => {
                formik.setFieldValue('name', e.target.value)
              }}
              autoFocus={true}
            />
            <ErrorMessage msg={formik.errors.name} />
          </Form.Item>
        </Col>

        <Col span={11} offset={2}>
          <Form.Item
            label="Date"
          >
            <DatePicker
              style={{ width: '100%' }}
              value={moment(formik.values.date)}
              onChange={(momentValue) => {
                formik.setFieldValue('date', momentValue?.format('YYYY.MM.DD'))
              }}
              allowClear={false}
            />
            <ErrorMessage msg={formik.errors.date} />
          </Form.Item>
        </Col>
      </Row>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="primary"
          onClick={() => {
            formik.submitForm()
          }}
        >
          {formik.values.id ? "Update" : "Create"}
        </Button>
      </div>

    </Form>
  )
}

export default FreeDayForm