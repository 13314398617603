import { cloneDeep, forEach, map, sortBy } from "lodash"
import Time from "utils/time"

function generateUUID() { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;//random number between 0 and 16
    if (d > 0) {//Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {//Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}


const sortActivityByTime = (array: any[]) => {

  const sortedTimeLogs = map(array, (activity) => {
    const newActivity = cloneDeep(activity)

    let allTimeEntries = cloneDeep(newActivity.timeLogs)

    forEach(allTimeEntries, (timeLog) => {
      if (timeLog.description === null || timeLog.description === undefined) {
        timeLog.description = ""
      }
    })

    allTimeEntries = sortBy(allTimeEntries, (timeLog) => {
      return Time.fromApiDate(timeLog.startAt).moment.format('X')
    }).reverse()

    newActivity.timeLogs = allTimeEntries

    if (newActivity.tracker.startedAt) {
      const tracker = newActivity.tracker

      if (tracker.description == null || tracker.description == undefined) {
        tracker.description = ""
      }

      const startTimeAt = Time.fromApiDate(tracker.startedAt, tracker.timeZone)

      newActivity.lastActivityAt = parseInt(startTimeAt.moment.format('X'))
    } else if (allTimeEntries.length == 0) {
      newActivity.lastActivityAt = 0
    } else {
      const timeLog = newActivity.timeLogs[0]
      const startTimeAt = Time.fromApiDate(timeLog.startAt)

      newActivity.lastActivityAt = parseInt(startTimeAt.moment.format('X'))
    }

    if (!newActivity.id) {
      newActivity.id = generateUUID()
    }

    return newActivity
  })

  const sorted = sortBy(sortedTimeLogs, (activity) => {
    return activity.lastActivityAt
  }).reverse()

  return sorted
}

export default sortActivityByTime