// 1h 5m -> 65

import { toNumber } from "lodash"

const onlyNumbers = (time: string) => /^[\s\d]+$/.test(time)

export const parseDurationStringToMinutes = (time: string): number | undefined => {
  let hours = 0
  let minutes = 0
  const splittedTime = time.split('h')

  if (time.indexOf('h') > -1 && onlyNumbers(splittedTime[0])) {
    hours = Math.abs(toNumber(splittedTime[0]))
  }
  if (time.indexOf('m') > -1) {
    const minutesPart = splittedTime[splittedTime.length - 1].split('m')[0]

    if (onlyNumbers(minutesPart)) minutes = Math.abs(toNumber(minutesPart))
  }

  const result = hours * 60 + minutes

  if (result != 0) {
    return result
  }

  // If nothing got parsed but time is a string with only numbers
  // return this number as minutes
  if (result == 0 && onlyNumbers(time)) {
    return parseInt(time)
  }

  return undefined
}