import { Drawer } from "antd"
import { NAV_TYPE_SIDE } from "consts/theme"
import { Scrollbars } from "react-custom-scrollbars"
import MenuContent from "./MenuContent"
import Logo from "./Logo"
import Flex from "components/shared-components/Flex"
import { ArrowLeftOutlined } from "@ant-design/icons"

export const MobileNav = ({
  sideNavTheme,
  mobileNav,
  toggleMobileNav,
  routeInfo,
  hideGroupTitle,
  localization = true,
}) => {
  const props = { sideNavTheme, routeInfo, hideGroupTitle, localization, toggleMobileNav };

  const onClose = () => {
    toggleMobileNav(false)
  };

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={mobileNav}
      bodyStyle={{ padding: 5 }}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          <Logo mobileLogo={true} />
          <div className="nav-close" onClick={() => onClose()}>
            <ArrowLeftOutlined />
          </div>
        </Flex>
        <div className="mobile-nav-menu">
          <Scrollbars autoHide>
            <MenuContent type={NAV_TYPE_SIDE} {...props} />
          </Scrollbars>
        </div>
      </Flex>
    </Drawer>
  );
};

export default MobileNav
