import { Button, Form, Input, Select, Checkbox } from "antd"
import { TApiAbsenceType } from "services/api-client/types"
import { useFormik } from "formik"
import ErrorMessage from "components/base/error-message"
import { ICreateHolidaysAbsenceTypeResponse } from "services/api-client/holidays-absence-types-api"
import ColorPickerSelect, { TIMENOTES_COLORS } from "components/forms/color-picker-select"
import AbsenceIconSelect, { TIMENOTES_ABSENCE_ICONS } from "components/forms/absence-icon-select"
import { produce } from 'immer'
import styled from "styled-components"
import useApiClient from "hooks/useApiClient"

export interface IAbsenceTypeFormObject extends Omit<TApiAbsenceType, 'id' | 'limit' | 'period'> {
  id?: string
  limit?: number
  period?: string
  //usersAccount?: TApiUsersAccount
  //absenceType?: TApiAbsenceType
}

export interface AbsenceTypeFormProps {
  initialValues?: Partial<IAbsenceTypeFormObject>
  onSubmit?(values: IAbsenceTypeFormObject): void
  onSuccess?(response: ICreateHolidaysAbsenceTypeResponse): void
}

const AbsenceTypeForm = (props: AbsenceTypeFormProps) => {
  const apiClient = useApiClient()

  const formik = useFormik<IAbsenceTypeFormObject>({
    enableReinitialize: true,
    initialValues: {
      name: "",
      icon: TIMENOTES_ABSENCE_ICONS[0],
      approval: true,
      timesheet: true,
      notify: true,
      color: TIMENOTES_COLORS[0],
      limitless: false,
      ...props.initialValues,
      // Those 2 properties can come from the API, but have null value which we transform into limitless
      limit: props.initialValues?.limit ? props.initialValues.limit : 10,
      period: props.initialValues?.period ? props.initialValues.period : 'month',
    },
    onSubmit: (values) => {

      const callValues = produce(values, draft => {
        if (draft.limitless) {
          // Working out around blank-value
          // @ts-ignore
          draft.limit = "" 
          // @ts-ignore
          draft.period = ""
        }

        return draft
      })

      const apiCall = values.id ? apiClient.updateHolidaysAbsenceType(values.id, callValues) : apiClient.createHolidaysAbsenceType(callValues)

      apiCall.then((response) => {
        if (response.ok) {
          props.onSubmit && props.onSubmit(values)
          props.onSuccess && props.onSuccess(response)
          formik.resetForm()
        } else {
          const errors = {
            ...response.errors,
            holidaysAbsenceTypeId: response.errors?.absenceType,
            usersAccountId: response.errors?.usersAccount,
          }

          // @ts-ignore
          formik.setErrors(errors || {})
        }
      })
    },
  })

  return (
    <Form onSubmitCapture={formik.handleSubmit} layout="vertical">

      <Form.Item
        label="Absence Type Name"
      >
        <Input 
          value={formik.values.name}
          onChange={(e) => {
            formik.setFieldValue('name', e.target.value)
          }}
          autoFocus={true}
        />
       <ErrorMessage msg={formik.errors.name} />
      </Form.Item>

      <div style={{ display: 'flex', gap: '25px' }}>
        <Form.Item
          label="Color"
        >
          <ColorPickerSelect
            value={formik.values.color}
            onChange={(value) => {
              formik.setFieldValue('color', value)
            }}
          />
        </Form.Item>

        <Form.Item
          label="Icon"
        >
          <AbsenceIconSelect
            value={formik.values.icon}
            onChange={(value) => {
              formik.setFieldValue('icon', value)
            }}
          />
        </Form.Item>
      </div>

      <div style={{ display: 'flex', gap: '25px' }}>
        <Form.Item
          label="Default limit of available days per person"
        >
          <Input 
            type="number"
            value={formik.values.limit}
            disabled={formik.values.limitless}
            onChange={(e) => {
              const newLimit = e.target.value

              formik.setFieldValue('limit', newLimit)
          }}
          onBlur={() => {
            // @ts-ignore
            if (formik.values.limit == "") {
              formik.setFieldValue("limit", 0)
            }
          }} 
          />
        <ErrorMessage msg={formik.errors.limit} />

        { !formik.errors.limit && formik.values.limit == 0 && !formik.values.limitless && (
          <div style={{ position: 'absolute', width: '800px'}}><i>Limit of 0 means that by default users can't request this absence type.</i></div>
        )}
        </Form.Item>

        <Form.Item
          label="Per period"
        >
          <Select
            disabled={formik.values.limitless}
            style={{ width: '150px' }}
            value={formik.values.period}
            onChange={(value) => {
              formik.setFieldValue('period', value)
            }}
          >
            <Select.Option key="month" value="week">Week</Select.Option>
            <Select.Option key="month" value="month">Month</Select.Option>
            <Select.Option key="month" value="year">Year</Select.Option>
          </Select>

          <ErrorMessage msg={formik.errors.period} />
        </Form.Item>

      </div>

      <CheckboxWrapper>
        <CheckboxFormItem
          label="Turn off the limit of requested days for this absence type?"
        />
        <div style={{ marginTop: '-3px' }}>
          <Checkbox
            checked={formik.values.limitless}
            onChange={(e) => {
              formik.setFieldValue('limitless', e.target.checked)
            }}
          />
        </div>
      </CheckboxWrapper>

      <CheckboxWrapper>
        <CheckboxFormItem
          label="Does this absence type require admin approval?"
        />
        <div style={{ marginTop: '-3px' }}>
          <Checkbox
            checked={formik.values.approval}
            onChange={(e) => {
              formik.setFieldValue('approval', e.target.checked)
            }}
          />
        </div>
      </CheckboxWrapper>

      <CheckboxWrapper>
        <CheckboxFormItem
          label="Display this absence type as an icon in the timesheet view?"
        />
        <div style={{ marginTop: '-3px' }}>
          <Checkbox
            checked={formik.values.timesheet}
            onChange={(e) => {
              formik.setFieldValue('timesheet', e.target.checked)
            }}
          />
        </div>
      </CheckboxWrapper>

      <CheckboxWrapper>
        <CheckboxFormItem
          label="Send email notifications about this absence type requests?"
        />
        <div style={{ marginTop: '-3px' }}>
          <Checkbox
            checked={formik.values.notify}
            onChange={(e) => {
              formik.setFieldValue('notify', e.target.checked)
            }}
          />
        </div>
      </CheckboxWrapper>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="primary"
          onClick={() => {
            formik.submitForm()
          }}
        >
          {formik.values.id ? "Update" : "Create"}
        </Button>
      </div>

    </Form>
  )
}

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
`

const CheckboxFormItem = styled(Form.Item)`
  height: 30px;
`

export default AbsenceTypeForm