import { TApiProjectsUser } from "../../api-client/projects-users-api"
import { TApiProject } from "../../api-client/types"

export default function getProjectsUsersTargetBillableRate(params: {
  projectsUser?: TApiProjectsUser,
  project: TApiProject,
  defaultWorkspaceBillableRate: number,
}) {

  return params.projectsUser?.billableRate || 
         params.project.billableRate || 
         params.projectsUser?.usersAccount?.billableRate || 
         params.defaultWorkspaceBillableRate || 
         0
}