import React from 'react'
import _map from 'lodash/map'
import styled from 'styled-components'
import { Select } from 'antd'

export interface IsBillableSelectProps extends Omit<React.ComponentProps<typeof Select>, 'onChange' | 'value' > {
  style?: React.CSSProperties
  width?: string

  value: boolean | undefined
  onChange(value: boolean | undefined): void 
}

const Wrapper = styled.div`
  .ant-popover {
    width: 250px;
  }
`

type TIsBillableOption = {
  label: string
  value: string
}

const DEFAULT_WIDTH = '170px'

const getBooleanToString = (value: boolean | undefined): string | undefined => {
  if (value !== undefined) {
    return (value ? 'true' : 'false')
  } else {
    return undefined
  }
}

const getStringToBoolean = (value: string | undefined): boolean | undefined => {
  if (value !== undefined) {
    return (value == 'true' ? true : false)
  } else {
    return undefined
  }
}

const IsBillableSelect = ({
  value,
  width,
  onChange,
  ...props
}: IsBillableSelectProps): JSX.Element => {

  const groupsOptions = [
    {
      value: 'true',
      label: `Is billable`,
    },
    {
      value: 'false',
      label: `Non-billable`,
    },
  ]

  return (
    <Wrapper style={{ width: width || DEFAULT_WIDTH }}>
      <Select
        placeholder="Select is billable status"
        style={{ 
          width: width || DEFAULT_WIDTH,
        }}
        options={groupsOptions}

        allowClear={true}

        value={getBooleanToString(value)}

        onChange={(newValue) => {
          // @ts-ignore
          onChange(getStringToBoolean(newValue))
        }}

        // Default options needed to make Select searchable
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => {
          const selectOption = option as TIsBillableOption

          if (selectOption) {
            return selectOption.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } else {
            return false
          }
        }}
        {...props}
      />
    </Wrapper>
  )
}

export default IsBillableSelect