import { Col, Form, Input, Row, Select } from "antd"
import { FormikProps } from "formik"
import { TApiTeamMember, IUpdateUsersAccountParams } from "services/api-client/users-accounts-api"
import _pick from 'lodash/pick'
import { Tooltip } from "components/base"
import ErrorMessage from "components/base/error-message"
import TextArea from "antd/lib/input/TextArea"

const TeamMemberDetailsForm = ({ teamMember, onSubmit, formik }: {
   teamMember: TApiTeamMember
   onSubmit(updateParams: IUpdateUsersAccountParams): void,
   formik: FormikProps<Partial<IUpdateUsersAccountParams>>
}) => {
  const userEmail = teamMember.user.email

  return (
    <>
          <Row gutter={20}>
            <Col span="12">
              <Form.Item label="First name">
                <Input 
                  autoFocus={true}
                  name="firstName" 
                  value={formik.values.firstName || ""} 
                  onChange={formik.handleChange('firstName')}
                  onSubmit={formik.submitForm}
                  onPressEnter={formik.submitForm}
                />
                <ErrorMessage msg={formik.errors.firstName} />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="Last name">
                <Input 
                  name="lastName" 
                  value={formik.values.lastName || ""} 
                  onChange={formik.handleChange('lastName')}
                  onSubmit={formik.submitForm}
                  onPressEnter={formik.submitForm}
                />
                <ErrorMessage msg={formik.errors.lastName} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span="12">
              <Tooltip 
                title="You can not change team members email manually as he might be using his email for other timenotes workspaces. If you need to adjust the email, ask member to do it or invite the team members from the right email address."
                mouseEnterDelay={0.5}
              >
                <Form.Item label="Email">
                  <Input 
                    name="email" 
                    value={userEmail} 
                    disabled={true}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col span="12">
              <Form.Item label="Role">
                <Select<TApiTeamMember['role']>
                  value={formik.values.role || ""} 
                  onChange={formik.handleChange('role')}
                  options={[
                    { value: 'admin', key: "admin", label: 'Admin' },
                    { value: 'member', key: "member", label: 'Member' },
                  ]}
                />
                <ErrorMessage msg={formik.errors.role} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span="24">
                <Form.Item 
                  label="Notes"
                  tooltip="This is a generic field for team member notes. Information is also available in the reports export when grouping by user."
                >
                  <TextArea
                    rows={2}
                    name="userNotes" 
                    value={formik.values.notes}  
                    onChange={formik.handleChange('notes')}
                    placeholder="Member notes"
                  />
                  <ErrorMessage msg={formik.errors.notes} />
                </Form.Item>
            </Col>
         </Row>


</>
)}

export default TeamMemberDetailsForm