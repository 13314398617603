import { UseQueryOptions, useQuery } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiMembersGroup, TApiParams } from "../../api-client/types"
import { TApiGenericError, TApiGenericResponse } from "../../timenotes-query/timenotes-query.types"

interface Params extends TApiParams {}

interface Result {
  membersGroups: TApiMembersGroup[]
}

export const useMembersGroupsQuery = (params?: Params, options?: UseQueryOptions<any, any>) => {
  const apiClient = useApiClient()

  const query = useQuery<Result, TApiGenericError<any>>(
    ['members-groups', (params || {})] as const,
    async () => {
      const response = await apiClient.getMembersGroups(params)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        membersGroups: response.membersGroups || []
      }
    },
    {
      ...options
    }
  )

  return query
}