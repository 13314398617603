import { useMutation, useQuery, useQueryClient } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TQueryOptions } from "../../timenotes-query/timenotes-query.types"
import { IGetTimeLogsParams } from "../../api-client/time-logs-api"
import { TApiActiveTracker, TApiTimeLog } from "../../api-client/types"

type Params = {
  params?: {}
  options?: TQueryOptions
}

export const useStopActiveTrackerMutation = ({ params, options }: Params = {}) => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  const requestParams = {
    ...params,
  }

  const query = useMutation(
    ['active-tracker', 'stop'],
    async () => {
      const response = await apiClient.deleteActiveTracker()

      if (!response.ok) {
        throw response.errors
      }

      return {}
    },
    {
      ...options,
      onSuccess: (data, variables) => {
        queryClient.setQueryData('active-tracker', (oldTracker) => {
          return { activeTracker: undefined }
        })
        queryClient.invalidateQueries('time-logs', { exact: false })

        // @ts-ignore
        if (options && options.onSuccess) options.onSuccess(data, variables)
      }
    }
  )

  return query
}

export default useStopActiveTrackerMutation