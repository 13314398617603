import { Divider, message, Modal, ModalProps } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { capitalize } from "lodash"
import { useState } from "react"

type TDecisionParams = {
  decision: string
  callback(note?: string): void
  buttonType?: 'primary' | 'danger' | 'default'
  danger?: boolean
}

export function useApprovalDecisionModal() {
  const [decisionParams, setDecisionParams] = useState<TDecisionParams | undefined>()

  const openApprovalDecisionModal = (params: TDecisionParams) => {
    setDecisionParams(params)
  } 

  const closeApprovalDecisionModal = () => {
    setNote("")
    setDecisionParams(undefined)
  }

  const visible = !!decisionParams

  const title = `${capitalize(decisionParams?.decision || "")} approval request`
  const [note, setNote] = useState("")

  const handleDecision = () => {
    decisionParams?.callback && decisionParams.callback(note) 
    closeApprovalDecisionModal()
  }

  const ApprovalDecisionModal = (
    <Modal
      visible={visible}
      okText={decisionParams?.decision !== 'Cancel' ? capitalize(decisionParams?.decision || '') : 'Cancel'}
      onOk={handleDecision}
      okButtonProps={{
        type: decisionParams?.buttonType || 'primary',
        danger: decisionParams?.danger,
      }}
      cancelText={decisionParams?.decision == 'Cancel' ? 'Close modal' : 'Cancel'}
      onCancel={closeApprovalDecisionModal}
    >
      <h1>{title}</h1>

      <Divider />

      <TextArea
        rows={5}
        placeholder="Notes"
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />

    </Modal>
  )

  return {
    visible,
    openApprovalDecisionModal,
    closeApprovalDecisionModal,
    ApprovalDecisionModal,
  }
}