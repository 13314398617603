import { Input } from "antd"
import { IInPlaceEditorProps, IInPlaceEditorRenderFieldProps, InPlaceEditor } from "../in-place-editor"

const InPlaceTextEditor = (props: IInPlaceEditorProps<string>): JSX.Element => {
  const renderField = (fieldProps: IInPlaceEditorRenderFieldProps<string>): JSX.Element => {
    return (
      <Input
        autoFocus={true}
        value={fieldProps.value}
        onKeyDown={(e) => {
          if (e.key == 'Enter') {
            fieldProps.submitForm()
          }
        }}

        onChange={(e) => {
          // let newValue: number | null = null
          const targetValue = e.target.value
          return fieldProps.onChange(targetValue)
        }}
      />
    )
  }

  return (
    <div>
      <InPlaceEditor<string>
        {...props}
        renderField={renderField}
      />
    </div>
  )
}

export default InPlaceTextEditor