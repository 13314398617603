import { TApiResponse, TApiParams, TApiProject, TApiMembersGroup, TApiUsersAccount } from "./types"
import BaseApi, { getQueryString } from './base'
import _map from 'lodash/map'

export type TApiListMembersGroup = TApiMembersGroup & {
  usersAccounts: Array<TApiUsersAccount>
  projects: Array<TApiProject>
}

export type TApiUsersMembersGroup = {
  id?: string
  usersAccountId: string
}

export interface IGetMembersGroupsResponse extends TApiResponse {
  membersGroups: Array<TApiMembersGroup>
}

export interface IGetListMembersGroupsResponse extends TApiResponse {
  membersGroups: Array<TApiMembersGroup>
}

export interface ICreateMembersGroupParams {
  name: string
  usersAccountsIds: Array<string>
}

export interface ICreateMembersGroupResponse extends TApiResponse {
  name: string
  usersAccounts: TApiUsersAccount[]
}

class MembersGroupsApi extends BaseApi {
  async getMembersGroups (params?: TApiParams): Promise<IGetMembersGroupsResponse> {
    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/members_groups${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async getMembersGroupsList (params: TApiParams): Promise<IGetListMembersGroupsResponse> {
    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/members_groups/list${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async createMembersGroup (params: ICreateMembersGroupParams): Promise<ICreateMembersGroupResponse> {
    let response = await fetch(`${this.rootUrl}/members_groups`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        members_group: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async updateMembersGroup (membersGroupId: string, params: ICreateMembersGroupParams) {
    let response = await fetch(`${this.rootUrl}/members_groups/${membersGroupId}`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        members_group: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async deleteMembersGroup (membersGroupId: string) {
    let response = await fetch(`${this.rootUrl}/members_groups/${membersGroupId}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
}

export default MembersGroupsApi