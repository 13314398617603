import { UseQueryOptions, useQuery } from "react-query"
import { TApiGenericErrors, TApiGenericResponse, TApiUsersAccount } from "../../api-client/types"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiGenericError } from "../../timenotes-query/timenotes-query.types"


interface IUseUsersAccountsQueryParams {
  usersAccountId?: string
}

type Result = {
  usersAccounts: TApiUsersAccount[]
}

export const useUsersAccountQuery = (params: IUseUsersAccountsQueryParams, options: UseQueryOptions<TApiGenericResponse<TApiUsersAccount>, TApiGenericErrors<null>> = {}) => {
  const apiClient = useApiClient()

  const usersAccountQuery = useQuery<Result, TApiGenericError<null>>(
    ['users-account', params.usersAccountId],
    async () => {
      const response = await apiClient.get(`/users_accounts/${params.usersAccountId}`)

      if (!response.ok) {
        throw response.errors
      }

      return {
        usersAccount: response.usersAccount
      }
    },
    {
      ...options
    }
  )

  return { 
    usersAccountQuery
  }
}
