import { useQuery } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TQueryOptions } from "../../timenotes-query/timenotes-query.types"
import { IGetTimeLogsParams } from "../../api-client/time-logs-api"
import { TApiTimeLog } from "../../api-client/types"

type Params = {
  params: IGetTimeLogsParams
  options?: TQueryOptions
}

const PER_PAGE = 100

export const useTimeLogsQuery = ({ params, options }: Params) => {
  const apiClient = useApiClient()

  const perPage = params.perPage || PER_PAGE
  const requestParams = {
    ...params,
    perPage: perPage
  }

  const query = useQuery(
    ['time-logs', (params.filters || {})],
    {
      queryFn: async () => {
        const response = await apiClient.getTimeLogs(requestParams)

        if (!response.ok) {
          throw response.errors
        }

        return {
          timeLogs: response.timeLogs
        }
      },
      initialData: { timeLogs: [] },
      ...options,
    }
  )

  return query
}