import { TApiAuditFilter } from "@timenotes/shared/src/services/api-client/reports-api"
import { Checkbox, Col, Input, Row, TimePicker, message } from "antd"
import FilterInput from "components/timenotes/filters/filter-input"
import { useDefaultTimeFormat } from "hooks/settings"
import moment from "moment-timezone"
import { useEffect, useState } from "react"

type AuditFilterProps = {
  visible?: boolean
  value: TApiAuditFilter
  onChange(newValue: TApiAuditFilter): void
}

export const AUDIT_KEYS = [
  'notWorkingDays',
  'timeAfterHour',
  'timeBeforeHour',
  'withoutTags',
  'descriptionShorterThen',
  'withoutDescription',
  'durationLongerThen',
  'durationShorterThen',
  'withoutDuration',
]

const AuditFilter = (props: AuditFilterProps) => {
  const [audit, setAuditState] = useState<TApiAuditFilter>(props.value || {})
  const [filtersCount, setFiltersCount] = useState(0)

  const { defaultTimeFormat } = useDefaultTimeFormat()

  const setAudit = (a: TApiAuditFilter) => {
    const newAudit = { ...a }

    Object.keys(newAudit).forEach((key) => {
      // @ts-ignore
      const newKeyValue: any = newAudit[key]
      if (newKeyValue == false) {
        // @ts-ignore
        newAudit[key] = undefined
      }
    })

    setAuditState(newAudit)
  }

  useEffect(() => {
    // @ts-ignore
    setFiltersCount(Object.keys(audit).filter((k) => audit[k] !== undefined).length)
    props.onChange(audit)
  }, [JSON.stringify(audit)])

  useEffect(() => {
    setAuditState(props.value)
  }, [JSON.stringify(props.value)])

  return (
    <FilterInput
      placeholder="Audit"
      count={filtersCount}
    >
      <div
        style={{
          width: '350px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Row>
          <Col span="12">
            Without duration 
          </Col>
          <Col span="12">
            <Checkbox 
              checked={audit.withoutDuration}
              onChange={(e) => {
                const withoutDuration = e.target.checked

                setAudit({
                  ...audit,
                  withoutDuration: e.target.checked,
                })
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col span="12">
            Duration shorter then
          </Col>
          <Col span="12">
            <Input
              type={'number'}
              suffix="minutes"
              value={audit.durationShorterThen}
              allowClear
              onChange={(e) => {
                setAudit({
                  ...audit,
                  durationShorterThen: e.target.value != "" ? parseInt(e.target.value) : undefined
                })
              }}
            />
 
          </Col>
        </Row>


        <Row>
          <Col span="12">
            Duration longer then
          </Col>
          <Col span="12">
            <Input
              type={'number'}
              suffix="minutes"
              value={audit.durationLongerThen}
              allowClear
              onChange={(e) => {
                setAudit({
                  ...audit,
                  durationLongerThen: e.target.value != "" ? parseInt(e.target.value) : undefined
                })
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col span="12">
            Without description
          </Col>
          <Col span="12">
            <Checkbox 
              checked={!!audit.withoutDescription}
              onChange={(e) => setAudit({ ...audit, withoutDescription: e.target.checked})}
            />
          </Col>
        </Row>

        <Row>
          <Col span="12">
            Description shorter then
          </Col>
          <Col span="12">
            <Input
              type={'number'}
              suffix="chars"
              value={audit.descriptionShorterThen}
              allowClear
              onChange={(e) => {
                setAudit({
                  ...audit,
                  descriptionShorterThen: e.target.value != "" ? parseInt(e.target.value) : undefined
                })
              }}
            />
          </Col>
        </Row >

        <Row >
          <Col span="12">
            Without tag
          </Col>
          <Col span="12">

            <Checkbox 
              checked={!!audit.withoutTags} 
              onChange={(e) => setAudit({ ...audit, withoutTags: e.target.checked})}
            />
          </Col>
        </Row>

        <Row>
          <Col span="12">
            Started before hour
          </Col>
          <Col span="12">
            <TimePicker
              style={{
                width: '100%'
              }}
              format={defaultTimeFormat}
              value={
                !!audit.timeBeforeHour ? moment().set({ 
                  hour: parseInt(audit.timeBeforeHour.split(":")[0]),
                  minute: parseInt(audit.timeBeforeHour.split(":")[1]),
                }) : undefined
              }
              onChange={(newMoment) => {
                setAudit({
                  ...audit,
                  timeBeforeHour: (!!newMoment) ? `${newMoment?.format("HH")}:${newMoment?.format("mm")}` : undefined
                })
              }}
            />
         </Col>
        </Row >

        <Row>
          <Col span="12">
            Started after hour
          </Col>
          <Col span="12">
            <TimePicker
              style={{
                width: '100%'
              }}
              format={defaultTimeFormat}
              value={
                !!audit.timeAfterHour ? moment().set({ 
                  hour: parseInt(audit.timeAfterHour.split(":")[0]),
                  minute: parseInt(audit.timeAfterHour.split(":")[1]),
                }) : undefined
              }
              //value={audit.timeAfterHour ? moment(audit.timeAfterHour) : undefined}
              onChange={(newMoment) => {
                setAudit({
                  ...audit,
                  timeAfterHour: (!!newMoment) ? `${newMoment?.format("HH")}:${newMoment?.format("mm")}` : undefined
                })
              }}
            />

          </Col>
        </Row >
        <Row>
          <Col span="12">
            Not business days
          </Col>
          <Col span="12">
            <Checkbox 
              checked={!!audit.notWorkingDays} 
              onChange={(e) => setAudit({ ...audit, notWorkingDays: e.target.checked})}
            />
          </Col>
        </Row>
      </div >
    </FilterInput>
  )
}

export default AuditFilter