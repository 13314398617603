import { Card, Modal, Skeleton, Table, message } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useState } from "react"
import { getUsersAccountFullName } from "services/users-accounts"
import GenericButtonsList from "components/generic-buttons-list/generic-buttons-list"
import { DeleteSvg, EditSVG, MoreSvg } from "assets/svg/icon"
import { TGenericButton } from "components/generic-buttons-list/generic-buttons-list"
import { toDurationString } from "components/forms/duration-input"
import { toTitleCase } from "utils/string-utils"
import { useAlerts } from "hooks/alerts"
import AlertForm from "./alert-form"
import { TApiAlert } from "@timenotes/shared/src/services/api-client/alerts-api"
import { TApiPagination } from "@timenotes/shared/src/services/api-client/types"
import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"

type TAlertsTableItem = TApiAlert

export interface AlertsTableProps {
  alerts: TAlertsTableItem[]
  pagination?: TApiPagination
  loading?: boolean
  onPaginationCurrentPageChange?(page: number): void
  refresh(): void
}

const AlertsTable = (props: AlertsTableProps) => {

  const isLoading = !!props.loading
  const [editItemModalVisible, setEditItemModalVisible] = useState(false)
  const [editItem, setEditItem] = useState<TAlertsTableItem | undefined>() 

  const { deleteAlert } = useAlerts({ fetch: false })

  const handleDelete = (id: string) => {
    deleteAlert(id).then((response) => {
      if (response.ok) {
        message.success("Alert deleted!")
        props.refresh()
      } else {
        message.error(getApiErrorMessage(response, 'base'))
      }
    })
  }

  const columns: ColumnsType<TAlertsTableItem> = [
    {
      title: 'Send Alert To',
      render: (value, record) => (
        <span>{toTitleCase(record.notificationTarget)}</span>
      )
    },

    {
      title: 'When (who?)',
      render: (value, record) => (
        <>
          { record.usersAccounts.map((userAccount) => (
            <>
              <span key={userAccount.id}>{getUsersAccountFullName(userAccount)}</span>
              <br/>
            </>
          ))}
        </>
      )
    },

    {
      title: 'Logged',
      render: (value, record) => (
        <span>{toTitleCase(record.loggingType)} - <b>{toDurationString(record.loggingValue)}</b></span>
      )
    },

    {
      title: 'In',
      render: (value, record) => (
        <>
        { record.scopeTargetType == 'account' ? (
          <span>Total</span>
        ) : (
          <span>{toTitleCase(record.scopeTargetType)} - <b>{record.scopeTarget.name}</b></span>
        )}
        </>
      )
    },

    {
      title: 'Period',
      render: (value, record) => (
        <>
        { record.period == 'total' ? (
          <span>In <b>{toTitleCase(record.period)}</b></span>
        ) : (
          <span>In a <b>{toTitleCase(record.period)}</b></span>
        )}
        </>
      )
    },

    {
      title: '',
      render: (value, record) => {
        let buttons: TGenericButton[] = [
          {
            title: '',
            icon: MoreSvg,
            type: 'text',
            options: [
              {
                title: 'Edit alert',
                Icon: EditSVG,
                callback: () => {
                  setEditItem(record)
                  setEditItemModalVisible(true)
                }
              },
              {
                title: 'Delete alert',
                Icon: DeleteSvg,
                callback: () => {
                  Modal.confirm({
                    title: "Are you sure you want to delete this alert?",
                    okText: 'Delete',
                    onOk: () => {
                      handleDelete(record.id)
                    }
                  })
                }
              },
            ],
          }
        ]

        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GenericButtonsList 
              buttons={buttons}
            />
          </div>
        )
      }
    }
  ]

  if (isLoading) {
    return (
      <div>
        <Card>
          <Skeleton />
          <Skeleton />
        </Card>
      </div>
    )
  }

  return (
    <div>
      <Table<TAlertsTableItem>
        columns={columns}
        dataSource={props.alerts}
        pagination={!!props.pagination && {
          pageSize: props.pagination.pageSize as number,
          current: props.pagination.currentPage as number,
          total: props.pagination.totalCount as number,
          showSizeChanger: false,
          onChange: (page) => {
            props.onPaginationCurrentPageChange && props.onPaginationCurrentPageChange(page)
          }
        }}
      />

      <Modal
        title="Edit alert"
        visible={editItemModalVisible}
        onCancel={() => { setEditItemModalVisible(false) }}
        footer={false}
        destroyOnClose={true}
      >
        <AlertForm
          initialValues={editItem as TApiAlert}
          onSuccess={(response) => {
            props.refresh()
            setEditItemModalVisible(false)
          }}
        />
      </Modal>
    </div>
  )
}

export default AlertsTable