import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "react-query"
import { useApiClient } from "../api-client/api-client.hooks"
import { TApiProject, TApiResponseMeta } from "../api-client/types"
import { TApiGenericError, TApiGenericErrors, TApiGenericResponse } from "../timenotes-query/timenotes-query.types"
import { ICreateProjectParams } from "../api-client/projects-api"
import { useFormik } from "formik"
import { useEffect } from "react"
import { delay } from "lodash"
import { useCurrentProjectsPermissionsQuery, usePermissionsQuery } from "../permissions/permissions.hooks"


type TQueryOptions = {
  enabled?: boolean
}

export const useProjectsQuery = (params: any, options: UseQueryOptions<TApiGenericResponse<TApiProject[]>, TApiGenericErrors<null>> = {}) => {
  const apiClient = useApiClient()

  const query = useQuery<TApiGenericResponse<TApiProject[]>, TApiGenericErrors<null>>(
    ['projects'],
    async () => {
      const response = await apiClient.getProjects({ perPage: 1000 })

      return {
        data: response.projects || [],
        ok: true,
        status: 200,
      }
    },
    {
      initialData: { data: [], ok: true, status: 200 },
      ...options
    }
  )

  return query
}

type useProjectQueryProps = {
  projectId?: string
}

type useProjectQueryResult = {
  project: TApiProject
}

export const useProjectQuery = (params: useProjectQueryProps, options: UseQueryOptions<useProjectQueryResult, TApiGenericErrors<null>> = {}) => {
  const apiClient = useApiClient()

  const query = useQuery<useProjectQueryResult, TApiGenericErrors<null>>(
    ['projects', params.projectId],
    async () => {
      const response = await apiClient.getProject(params.projectId)

      if (!response.ok) {
        throw response.errors
      }

      return { project: response.project }
    },
    {
      enabled: !!params.projectId,
      ...options
    }
  )

  return query
}

interface Args {
  name: string
  color: string
}

interface Result {
  project: TApiProject,
}

type TSimplifiedProject = Args
interface FormikProps {
  initialValues?: Partial<TSimplifiedProject>
  onSuccess?(project: TApiProject): void
}

export const useCreateSimplifiedProjectMutation = (params?: {}, options?: UseQueryOptions<Result, TApiGenericError<Args>> = {}) => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  const query = useMutation<Result, TApiGenericError<Args>, Args>(
    'create-project',
    async (params: Args) => {
      const response = await apiClient.createProject(params as ICreateProjectParams)

      if (!response.ok) {
        throw response.errors
      }

      return {
        project: response.project,
      }
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData<TApiGenericResponse<TApiProject[]>>(["projects"], (state) => {
          state.data.concat(data.project)

          return state
        })
      },
    }
  )

  return query
}

export const useSimplifiedProjectFormik = ({ initialValues, onSuccess }: FormikProps = {}) => {
  const createMutation = useCreateSimplifiedProjectMutation()

  const formik = useFormik<TSimplifiedProject>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      color: '#faf',
      ...initialValues,
    },
    onSubmit: (values, formikConfig) => {
      createMutation.mutate(values)
    }
  })

  useEffect(() => {
    if (createMutation.isSuccess) {
      const project = createMutation.data.project

      onSuccess && onSuccess(project)
    }

    if (createMutation.isError) {
      formik.setSubmitting(false)
      formik.setErrors(createMutation.error)
    } 
  }, [createMutation.status])

  return {
    formik
  }
}


export const useProjectBillablePermissions = ({ project }: { project?: TApiProject }) => {
  const permissionsQuery = usePermissionsQuery()
  const projectPermissionsQuery = useCurrentProjectsPermissionsQuery({
    params: {
      projectId: project?.id
    }
  })

  // TODO: Ideally this should be returned from the backend current project permissions scope
  const canSetTimeLogBillable = !!permissionsQuery.data?.permissions.setTimeLogBillable
  const isProjectBillableAtAll = !!project?.billableEnabled

  const projectTimeLogIsBillableDefault = (!!project?.billableEnabled && !!project?.defaultIsBillableTimeLogs)
  const disabledInputText = project ? (
      !!canSetTimeLogBillable ? "This project is not billable." : "You have no permissions to change the time log billable flag."
    ) : 
      "Select the project first to fetch it's billable flag settings."

  return {
    projectTimeLogIsBillableDefault,
    isProjectBillableAtAll,
    canSetTimeLogBillable,
    disabledInputText,
  }

}