import { Modal, Radio, Select } from "antd"
import { Form, Formik } from "formik"
import _map from 'lodash/map'
import { _allowStateChangesInsideComputed } from "mobx"
import { TApiRounding } from "services/api-client/types"

export interface IReportsRoundingModal {
  roundingParams: TApiRounding, 
  visible: boolean
  onChange(newRoundingParams: TApiRounding): void
  hideModal(): void
}

const ReportsRoundingTypeRadioGroup = ({
  value,
  onChange,
}: {
  value: TApiRounding['type'],
  onChange(value: TApiRounding['type']): void,
}) => {
  return (
    <Radio.Group
      value={value}
      onChange={(event) => { onChange(event.target.value) }}
      style={{ width: '100%' }}
    >
      <Radio.Button value="up">Up</Radio.Button>
      <Radio.Button value="nearest">Nearest </Radio.Button>
      <Radio.Button value="down">Down </Radio.Button>
      <Radio.Button value="no_rounding">No rounding</Radio.Button>
    </Radio.Group>
  )
}

const ReportsRoundingModal = (props: IReportsRoundingModal) => {
  return (
    <Formik<TApiRounding>
      enableReinitialize={true}
      initialValues={props.roundingParams}
      onSubmit={(newValues) => {
        props.onChange(newValues)
      }}
    >
      {(formik) => (
        <Modal
          destroyOnClose={true}
          visible={props.visible}
          title="Rounding Settings"
          onOk={() => {
            props.onChange(formik.values)
          }}
          onCancel={() => {
            formik.resetForm()
            props.hideModal()
          }}
        >
          <Form>

            <ReportsRoundingTypeRadioGroup
              value={formik.values.type}
              onChange={(value) => {
                formik.setFieldValue('type', value)
              }}
            />

            <br />
            <br />

            <Select<string>
              disabled={formik.values.type == "no_rounding"}
              style={{ width: '200px' }}
              value={`${formik.values.precision}`}
              onChange={(value) => {
                const precision = parseInt(value) as IReportsRoundingModal['roundingParams']['precision']
                formik.setFieldValue('precision', precision)
              }}
            >
              <Select.Option value="5">5 minutes</Select.Option>
              <Select.Option value="10">10 minutes</Select.Option>
              <Select.Option value="15">15 minutes</Select.Option>
              <Select.Option value="30">30 minutes</Select.Option>
              <Select.Option value="60">60 minutes</Select.Option>
            </Select>
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

export default ReportsRoundingModal