const Hotel = (props: React.ComponentProps<"svg">) => (
    <svg width="22px" height="15px" viewBox="0 0 22 15" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props} >
        <g id="Holidays-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="9.2.3-Create-absence-type-" transform="translate(-346.000000, -329.000000)">
                <g id="Group" transform="translate(216.000000, 284.000000)">
                    <g id="icon-/-application-/-dark-/-icon-1-copy-3" transform="translate(129.000000, 40.000000)">
                        <g id="baseline-hotel-24px">
                            <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                            <path d="M7,13 C8.66,13 10,11.66 10,10 C10,8.34 8.66,7 7,7 C5.34,7 4,8.34 4,10 C4,11.66 5.34,13 7,13 Z M19,7 L11,7 L11,14 L3,14 L3,5 L1,5 L1,20 L3,20 L3,17 L21,17 L21,20 L23,20 L23,11 C23,8.79 21.21,7 19,7 Z" id="Shape" fill="#474D55" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
export default Hotel