import React from 'react'

import Flex from 'components/shared-components/Flex'

import { Progress, Popover } from 'antd'

import _sumBy from 'lodash/sumBy'
import _map from 'lodash/map'
import _sortBy from 'lodash/sortBy'
import { formatTimeInMinutes } from '@timenotes/shared/src/services/time-logs/utils/time-formatters'
import { TApiProject } from '@timenotes/shared/src/services/api-client/types'


type TProject = TApiProject & {
  worktime: number
}

export const formatProjectsToBarSegments = (projects: TProject[]) => {
  const projectSummaries = _map(projects, (project) => {
    return {
      title: project.name,
      value: project.worktime,
      color: project.color,
      tooltip: `${project.name} - ${formatTimeInMinutes(project.worktime)}`,
    }
  })

  return _sortBy(projectSummaries, (p) => p.value).reverse()
}

const BarPopover = (props: {
  title: string,
  color: string,
  value: number,
  share: number
  children: React.ReactNode
  formatValue?(v: number): string
}) => {
  const {
    title,
    color,
    value,
    share,
    formatValue,
  } = props


  const renderContent = () => {
    const timeValue = formatValue ? formatValue(value) : formatTimeInMinutes(value)
    const percentageShare = `${Math.round(share)}%`

    return (
      <Flex flexDirection="column">
        <span style={{ color: color }}>{title}</span>
        <span><b style={{ marginRight: '5px' }}> {timeValue} </b> <span style={{ color: '#474D55' }}>{percentageShare}</span></span>
      </Flex>
    )
  }

  if (value == undefined || value == 0) {
    return (<>{props.children}</>)
  }

  return (
    <Popover content={renderContent}>
      {props.children}
    </Popover>
  )
}

const HorizontalBarSegment = (props: {
  tooltip: string,
  title?: string,
  share: number,
  color?: string,
  displayTitle: boolean
  value?: number
  formatValue?(v: number): string
}) => {
  const { tooltip, title, share, color, displayTitle } = props

  return (
    <Flex className="horizontal-bar-segment" flexDirection="column" style={{ width: `${share}%` }}>
      {displayTitle && (
        <div className="title" style={{ color: color }}> {title} </div>
      )}

      <div>

        <BarPopover {...props}>
          <Progress showInfo={false} strokeLinecap="square" trailColor={color} />
        </BarPopover>
      </div>
    </Flex>
  )
}

const HorizontalBar = (props: {
  segments: {
    title: string,
    value: number,
    color: string,
    tooltip: string,
  }[],
  displayTitle: boolean
  formatValue?(v: number): string
}) => {
  const { segments, displayTitle } = props

  const totalValue = _sumBy(segments, (s) => s.value)

  let HorizontalBar
  const isEmpty = (segments.length == 0)

  if (isEmpty) {
    HorizontalBar = (
      <HorizontalBarSegment tooltip={""} displayTitle={false} share={100} />
    )
  } else {
    HorizontalBar = segments.map((segment) => {
      const share = segment.value / totalValue * 100 // share in %

      const tooltip = segment.tooltip || `${segment.title} - ${segment.value}`

      return (
        <HorizontalBarSegment 
          key={segment.title} 
          displayTitle={displayTitle} 
          share={share} 
          {...segment} 
          formatValue={props.formatValue}
        />
      )
    })
  }

  return (
    <div style={{ width: '100%' }}>
      <Flex>
        {HorizontalBar}
      </Flex>
    </div>
  )
}

export default HorizontalBar
