import { useEffect, useState } from "react"
import { TApiTagsFilters } from "services/api-client/tags-api"
import { ApiErrors, TApiTag } from "services/api-client/types"
import useApiClient from "./useApiClient"

interface IUseTags {
  fetch?: boolean
  filters?: TApiTagsFilters
}

export const useTags = (props?: IUseTags) => {
  const apiClient = useApiClient()
  const [tags , setTags] = useState<TApiTag[]>([])
  const [errors, setErrors] = useState<ApiErrors>({})
  const [loading, setLoading] = useState(true)
  const fetchOnUse = props?.fetch === undefined ? true : !!props.fetch

  const [filters, setFilters] = useState<IUseTags['filters']>({
    // @ts-ignore
    statuses: ['active']
  })

  const fetchTags = () => {
    const apiCall = apiClient.getTags({ filters }).then((response) => {
      if (response.ok) {
        setTags(response.tags)
      } else {
        setErrors(response.errors || {})
      }

      setLoading(false)
    })
  }

  const deleteTag = (id: string) => {
    return apiClient.deleteTag(id)
  }

  useEffect(() => {
    if (fetchOnUse) {
      fetchTags()
    }
  }, [])

  useEffect(() => {
    if (fetchOnUse) {
      fetchTags()
    }
  }, [filters])

  return {
    tags,
    errors,
    loading,
    filters,
    fetchTags,
    deleteTag,
    setFilters,
  }
}