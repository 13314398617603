import { useApiCall, useGetApiCall } from "./useApiCall"
import { TContactFormObject } from "services/api-client/contacts-api"
import useApiClient from "./useApiClient"

export const useContacts = () => {
  const apiClient = useApiClient()
  const { data, loading, request } = useGetApiCall(() => apiClient.getContacts())

  return {
    contacts: data?.contacts,
    loading,
    fetchContacts: request,
  }
}

export const useCreateContact = () => {
  const apiClient = useApiClient()
  const { data, loading, request } = useApiCall((contact: TContactFormObject) => apiClient.createContact(contact))

  return {
    contact: data?.contact,
    loading,
    createContact: request,
  }
}

export const useDeleteContact = () => {
  const apiClient = useApiClient()
  const { data, loading, request } = useApiCall((contactId: string) => apiClient.deleteContact(contactId))

  return {
    loading,
    deleteContact: request,
  }
}

