import { FormOutlined, ZoomInOutlined } from "@ant-design/icons"
import { TApiApprovalRequest } from "@timenotes/shared/src/services/approvals/approvals-types"
import useApprovalRequestsBulkUpdateStatusMutation from "@timenotes/shared/src/services/approvals/queries/use-approval-requests-bulk-update-status-mutation"
import { Button } from "antd"
import { TGenericButton } from "components/generic-buttons-list/generic-buttons-list"
import { useCurrentUser } from "hooks/current-user"
import { map } from "lodash"
import { useHistory } from "react-router-dom"
import { useApprovalDecisionModal } from "./approval-decision-modal"
import { usePagePermissions } from "hooks/permissions.hooks"

interface ApprovalRequestButtonsProps {
  approvalRequest: TApiApprovalRequest
  withDetailsLink?: boolean

  onChange?(): void
}

export default function ApprovalRequestButtons(props: ApprovalRequestButtonsProps) {
  const {
    approvalRequest,
    withDetailsLink,
    onChange
  } = props

  const { currentUser } = useCurrentUser()
  const history = useHistory()

  const { permissions } = usePagePermissions()
  const canManageApprovals = !!permissions?.manageApprovals 

  const {
    openApprovalDecisionModal,
    closeApprovalDecisionModal,
    ApprovalDecisionModal,
  } = useApprovalDecisionModal()

  const {
    examineUserApprovalRequest,
  } = useApprovalRequestsBulkUpdateStatusMutation({
    onSuccess: onChange
  })

  let buttons: TGenericButton[] = []

  if (approvalRequest.status == 'pending') {

    if (canManageApprovals) {
      // User can approve if has the right permission
      buttons = [
        {
          title: 'Approve',
          type: 'primary',
          callback: (note?: string) => {
            examineUserApprovalRequest({
              usersAccountId: approvalRequest.usersAccount.id,
              periodId: approvalRequest.approvalsPeriodId,
              decision: 'approve',
              note: note,
            })
          }
        }
      ]

      if (approvalRequest.usersAccount.id != currentUser?.id) {
        // User can reject if has permissions and its not his request
        buttons.push({
          title: 'Reject',
          danger: true,
          callback: (note?: string) => {
            examineUserApprovalRequest({
              usersAccountId: approvalRequest.usersAccount.id,
              periodId: approvalRequest.approvalsPeriodId,
              decision: 'reject',
              note: note,
            })
          }
        })
      }
    } // canManageApprovals

    // User can cancel the request if its his request
    if (approvalRequest.usersAccount.id == currentUser?.id) {
      buttons.push({
        title: 'Cancel',
        callback: (note?: string) => {
          examineUserApprovalRequest({
            usersAccountId: approvalRequest.usersAccount.id,
            periodId: approvalRequest.approvalsPeriodId,
            decision: 'cancel',
            note: note,
          })
        }
      })
    }
  }

  if (approvalRequest.status == 'approved' && canManageApprovals) {
    buttons = [
      {
        title: 'Withdraw',
        danger: true,
        callback: (note?: string) => {
          examineUserApprovalRequest({
            usersAccountId: approvalRequest.usersAccount.id,
            periodId: approvalRequest.approvalsPeriodId,
            decision: 'withdraw',
            note: note,
          })
        }
      },
    ]
  }

  if (approvalRequest.status == 'open') {
    buttons = [
      {
        title: 'Submit',
        type: 'primary',
        callback: (note?: string) => {
          examineUserApprovalRequest({
            usersAccountId: approvalRequest.usersAccount.id,
            periodId: approvalRequest.approvalsPeriodId,
            decision: 'submit',
            note: note,
          })
        }
      },
    ]
  }


  if (approvalRequest.status == 'rejected') {
    buttons = [
      {
        title: 'Resubmit',
        type: 'primary',
        callback: (note?: string) => {
          examineUserApprovalRequest({
            usersAccountId: approvalRequest.usersAccount.id,
            periodId: approvalRequest.approvalsPeriodId,
            decision: 'resubmit',
            note: note,
          })
        }
      },
    ]
  }

  if (withDetailsLink) {
    buttons.push({
      title: 'Details',
      icon: ZoomInOutlined,
      callback: () => {
        history.push(`/approvals/periods/${approvalRequest.approvalsPeriodId}/user/${approvalRequest.usersAccount.id}`)
      }
    })
  }

  const buttonComponents = map(buttons, (button) => {

    const hasNoteOption = (button.title != 'Details' && button.title != 'Cancel')

    const title = button.icon ? (
      <div>
        <button.icon /> {button.title}
      </div>
    ) : (
      button.title
    )

    return (
      <>
        <Button.Group
          style={{
            marginLeft: '8px'
          }}
        >
          {hasNoteOption && (
            <Button
              danger={button.danger}
              size="small"
              type={button.type}
              onClick={() => {
                openApprovalDecisionModal({
                  decision: button.title,
                  callback: button.callback,
                  buttonType: button.type,
                  danger: button.danger,
                })
              }}
            >
              <FormOutlined />
            </Button>
          )}
          <Button
            danger={button.danger}
            size="small"
            type={button.type}
            onClick={() => button.callback()}
          >
            {title}
          </Button>
        </Button.Group>
        {ApprovalDecisionModal}
      </>
    )
  })

  return (
    <div
      style={{
        display: 'flex',
        gap: '5px',
        justifyContent: 'center',
      }}
    >
      {buttonComponents}
    </div>
  )
}