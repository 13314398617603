import { TApiResponse, TApiParams, TApiAbsenceType } from "./types"
import BaseApi, { getQueryString } from './base'
import produce from 'immer'

export interface IGetHolidaysAbsenceTypesResponse extends TApiResponse {
  absenceTypes: Array<TApiAbsenceType>
}

export interface ICreateHolidaysAbsenceTypeResponse extends TApiResponse {
  absenceRequest: TApiAbsenceType
}

export interface ICreateHolidaysAbsenceTypeParams {
  name: string
  icon: string
  color: string
  limit?: number
  period?: string
  approval: boolean
  timesheet: boolean
  notify: boolean
}

class HolidaysAbsenceRequestsApi extends BaseApi {
  async getHolidaysAbsenceTypes (params: TApiParams): Promise<IGetHolidaysAbsenceTypesResponse> {
    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/holidays/absence_types${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async createHolidaysAbsenceType (params: ICreateHolidaysAbsenceTypeParams): Promise<ICreateHolidaysAbsenceTypeResponse> {
    let response = await fetch(`${this.rootUrl}/holidays/absence_types`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        absence_type: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async updateHolidaysAbsenceType (absenceTypeId: string, params: ICreateHolidaysAbsenceTypeParams) {
    let response = await fetch(`${this.rootUrl}/holidays/absence_types/${absenceTypeId}`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        absence_type: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async deleteHolidaysAbsenceType (absenceTypeId: string) {
    let response = await fetch(`${this.rootUrl}/holidays/absence_types/${absenceTypeId}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
}

export default HolidaysAbsenceRequestsApi