import { TApiResponse, TApiParams, TApiPlan} from "./types"
import BaseApi, { getQueryString } from './base'

export interface IGetPlansResponse extends TApiResponse {
  plans: Array<TApiPlan>
}

class PlansApi extends BaseApi {
  async getPlans (): Promise<IGetPlansResponse> {
    let response = await fetch(`${this.rootUrl}/plans`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
}

export default PlansApi
