import { IButton, IButtonDropdown, renderHeaderButton } from "components/layout/page-header"
import { map } from "lodash"

export type TGenericButton = IButton | IButtonDropdown

interface GenericButtonsListProps {
  buttons: TGenericButton[]
}

const GenericButtonsList = (props: GenericButtonsListProps): JSX.Element => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    }}>
      {map(props.buttons, (button) => {
        return renderHeaderButton(button)
      })}
    </div>
  )
}

export default GenericButtonsList