import { useMutation, useQueryClient } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiTask } from "../../api-client/types"
import { TApiGenericError, TApiGenericResponse } from "../../timenotes-query/timenotes-query.types"
import { TApiPeriod } from "../approvals-types"
import usePeriodModel from "../hooks/use-period-model"

interface Args extends Partial<TApiPeriod> {}

interface Result {
}

export const usePeriodUpdateMutation = (options?: any) => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  const {
    serializePeriod,
    parsePeriod,
  } = usePeriodModel()

  const query = useMutation<Result, TApiGenericError<TApiTask>, Args>(
    ['period', 'update'],
    async (args: Args) => {
      const response = await apiClient.patch(`/approvals/periods/${args.id}`, { approvalsPeriod: args })

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        // period: parsePeriod(response.approvalsPeriod) TODO: start receiving those
      }
    },
    {
      ...options,
      onSuccess: (data: Result, args: Args) => {
        queryClient.invalidateQueries('periods', { exact: false })
        queryClient.invalidateQueries('period', { exact: false })
        /*
        queryClient.invalidateQueries(['project', args.projectId], { exact: false })
        */

        if (options.onSuccess) {
          options.onSuccess(data, args)
        }
      },
    },
  )

  return query
}

export default usePeriodUpdateMutation