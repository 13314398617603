import { useEffect, useState } from 'react'
import { Modal } from 'antd'
import TaskForm from 'pages/projects/tasks/components/task-form'
import useApiClient from 'hooks/useApiClient'
import PageLoadingSpin from 'components/layout/page-loading-spin'

const NewTaskModal = function(props) {
  const { name, createTask, onSelect, visible } = props

  const apiClient = useApiClient()

  const [taskCreationPolicy, setTaskCreationPolicy] = useState(props.project?.taskCreationPolicy)
  const [loading, setLoading] = useState(false)

  const onClose = props.onClose || (props.setVisible ? (() => { props.setVisible(false) }) : (() => {}))
  const setVisible = props.setVisible
  const onSuccess = props.onSuccess

  const projectId = props.projectId || props.project?.id
  const projectOrigin = props.project?.origin


  useEffect(() => {
    if (projectId) {
      if (props.project?.taskCreationPolicy !== undefined) {
        setTaskCreationPolicy(props.project?.taskCreationPolicy)
      } else {
        setLoading(true)
        apiClient.getProject(projectId).then((response) => {
          if (response.ok) {
            setTaskCreationPolicy(response.project.taskCreationPolicy)
          }
        }).finally(() => {
          setLoading(false)
        })
      }
    }
  }, [props.project, props.projectId, props.taskCreationPolicy])

  const handleSubmit = async (values) => {
    try {
      const { response, task } = await createTask({ 
        projectId,
        ...values,
      })

      if (response.ok) {
        if (onSuccess) onSuccess(task)
        if (onSelect) onSelect(task.id, task)
        if (onClose) onClose()
      } else {
        // it will throw error
      }

      return response
    } 
    catch (errorResponse) {
      return errorResponse
    }
  }

  return (
    <Modal
      title="Create new task"
      visible={visible}
      footer={false}
      destroyOnClose={true}
      onCancel={() => {
        onClose()
      }}
    >
      <div>
        {loading ? (
          <div
            style={{
              paddingBottom: '100px'
            }}
          >
            <PageLoadingSpin
            />
          </div>
        ) : (
          <TaskForm
            name={name}
            onFormSubmit={handleSubmit}
            projectId={projectId}
            projectOrigin={projectOrigin}
            taskCreationPolicy={taskCreationPolicy}
          />
        )}
      </div>
    </Modal>
  )
}

export default NewTaskModal