import styled from 'styled-components'
import { Card, Button, Tooltip } from 'components/base'
import LogoImg from 'components/base/logo'
import { TApiIntegrationApp } from 'services/api-client/types'
import { AppName } from 'consts'

const COLORS = {
  primary: '#07c5b7',

  cardHighlight: "#EFEFEF",
}

const AppCard = styled(Card)`
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 195px;
  height: 195px;
  margin-top: 52px;
  padding: 10px;

  & .card-logo {
    display: flex;
    justify-content: center;
  }

  & .card-app-name{
    margin-top: 5px;
    margin-bottom: 14px;
    font-size: 24px;
    text-align: center;
    text-transform: capitalize;
  }

  & img {
    margin: auto;
  }
`
interface IonAppClick {
  (integrationApp: TApiIntegrationApp): void
}

export interface IAuthenticateIntegrationAppView {
  integrationApp: TApiIntegrationApp
  onAppClick: IonAppClick
}

const AuthenticateIntegrationAppView = ({ integrationApp, onAppClick }: IAuthenticateIntegrationAppView ): JSX.Element => {

  return (
    <AppCard key={integrationApp.service}>
      <div className="card-logo">
        <LogoImg name={integrationApp.service as AppName} size="66px" />
      </div>
      <div className="card-app-name">
        <span>{integrationApp.service}</span>
      </div>
      <Tooltip placement="bottom" title={`Next you will be taken to ${integrationApp.service} to grant permission to Timenotes.`}>
        <Button onClick={() => { onAppClick(integrationApp) }} type="primary"> Authenticate </Button>
      </Tooltip>

    </AppCard>
  )
}

export default AuthenticateIntegrationAppView