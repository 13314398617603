import { FormikHelpers, useFormik } from "formik"
import useApiClient from "./useApiClient"
import moment from "moment-timezone"
import { map, omit } from "lodash"
import { message } from "antd"
import { TApiTimeLog } from "@timenotes/shared/src/services/api-client/types"
import { ICreateTimeLogParams } from "@timenotes/shared/src/services/api-client/time-logs-api"
import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"

const getChangedValues = <T extends Record<string, any>>(values: T, initialValues: T) => {
  return Object.entries(values).reduce((acc: Partial<T>, [key, value]) => {
    const hasChanged = initialValues[key as keyof T] !== value

    if (hasChanged) {
      acc[key as keyof T] = value
    }

    return acc;
  }, {})
};

const DATE_TIME_FORMAT = 'YYYY-MM-DD'
const NEW_TIME_LOG_INITIAL_VALUES = {
  id: '',
}

export type TTimeLogFormObject = Omit<Partial<TApiTimeLog>, 'id'> & {
  id?: string
}

export const useTimeLogFormik = ({ 
  timeLog,
  onSubmit,
  onSuccess,
}: {
  timeLog?: TTimeLogFormObject,
  onSubmit?(timeLog: TApiTimeLog): void,
  onSuccess?(timeLog: TApiTimeLog): void,
}) => {

  const timeLogToRequestParams = (timeLog: TTimeLogFormObject) => {
    const params = {
      ...timeLog,
      projectId: timeLog.project?.id,
      taskId: timeLog.task?.id,
      usersAccountId: timeLog.usersAccount?.id,
      date: timeLog.startAt ? moment.utc(timeLog.startAt).format(DATE_TIME_FORMAT) : undefined,
      startAt: timeLog.startAt ? moment.utc(timeLog.startAt).format("HH:mm") : undefined,
      tags: timeLog.tags ? map(timeLog.tags, tag => tag.id) : undefined,
    } as ICreateTimeLogParams

    // Remove full objects from timeLog as they are not part of the request payload
    return omit(params, [
      'project',
      'task',
      'usersAccount',
    ])
  }
 
  const apiClient = useApiClient()
  const handleSubmit = (values: TTimeLogFormObject, formik: FormikHelpers<TTimeLogFormObject>) => {
    const createTimeLogParams = timeLogToRequestParams(values)

    const result = apiClient.updateTimeLog(values.id as string, createTimeLogParams).then((response) => {
      if (!response.ok) {
        const errors = { ...response.errors }

        if (errors.task) {
          errors.taskId = errors.task
        }

        formik.setErrors(errors)

        if (response.errors.base) {
          message.error(getApiErrorMessage(response, 'base'))
        }

      } else {
        if (onSuccess) {
          onSuccess(response.timeLog)
        }
      }

      return response
    })

    return result
  }

  const initialValues = timeLog || NEW_TIME_LOG_INITIAL_VALUES

  const formik = useFormik<TTimeLogFormObject>({
    onSubmit: (values, formikHelper) => {
      const changedValues = {
        ...getChangedValues(values, initialValues),
        id: initialValues.id,
      }
      handleSubmit(changedValues, formikHelper)
    },
    initialValues: initialValues,
    enableReinitialize: true,
  })

  return formik
}