import { Pagination, Table, TablePaginationConfig } from 'antd'
import { ColumnType, FilterValue } from 'antd/lib/table/interface'
import ActionsPanel, { IActionsPanelProps } from 'components/shared/actions-panel'
import ArchivedLabel from 'components/base/archived-label'
import TaskNameColumn from './task-name-column'
import { TApiPagination, TApiTask } from 'services/api-client/types'
import { toDurationString } from 'components/forms/duration-input'
import { TApiTasksSorting } from 'services/api-client/tasks-api'

interface IRenderCell {
  text: string
  record: any
  index: number
}

interface IProps {
  tasks: TApiTask[]
  pagination: TApiPagination
  perPage: number
  onPageChange(newPage: number): void
  onUpdateTaskName(task: TApiTask, newName: string): any
  getTaskActions(task: TApiTask): IActionsPanelProps
  onSortingChange(sorting: TApiTasksSorting): void
}

const TasksTable = (props: IProps) => {
  const {
    tasks,
    pagination,
    perPage
  } = props

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any) => {

    if (sorter.column) {
      const columnTitle: string = sorter.column.title
      const sortOrder: "ascend" | "descend" = sorter.order

      const apiSortOrder = sortOrder == 'ascend' ? 'asc' : 'desc'

      // Apply sorting by task name if applicable
      if (columnTitle == 'Task') {
        props.onSortingChange({ name: apiSortOrder })
      }
   }
  }

  const COLUMNS: ColumnType<TApiTask>[] = [
    {
      title: 'Task',
      width: '65%',
      sorter: true,
      render: (text: string, record: TApiTask, index: number) => {
        return (<TaskNameColumn 
          key={record.id} 
          task={record} 
          onChange={(newName) => { return props.onUpdateTaskName(record, newName) }} />)
      }
    },
    {
      title: '', //archived badge - not visible in header
      width: '5%',
      render: (text: string, record: TApiTask, index: number) => {
        return (
          <ArchivedLabel
            isArchived={record.state == 'archived'}
          />
        )
      }
    },
    {
      title: 'Tracked',
      width: '20%',

      render: (text: string, record: TApiTask) => {
        return (
          <div>
            {toDurationString(record.worktime || 0)}
          </div>
        )
      }
    },
    {
      title: "", // actions, no title of column displayed
      width: "2%",

      render: (text: string, record: TApiTask, index: number) => {
        return (
          <ActionsPanel {...props.getTaskActions(record)} />
        )
      },
    },
  ]

  return (
    <>
      <Table<TApiTask>
        columns={COLUMNS}
        dataSource={props.tasks}
        pagination={false}
        onChange={handleTableChange}
      />

      <br />

      <div style={{ display: 'flex', justifyContent: 'right' }} >
        <Pagination
          current={pagination.currentPage as number}
          pageSize={perPage}
          total={pagination.totalCount as number}
          showSizeChanger={false}
          onChange={props.onPageChange}
        />
      </div>
    </>
  )

}

export default TasksTable