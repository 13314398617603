import { Card, Divider, Skeleton, message } from "antd"
import WorkspacePermissionsForm from "pages/settings/components/workspace-permissions-form"
import { IFormObject } from "pages/settings/components/workspace-settings-form"
import { useCurrentUser } from "hooks/current-user"
import { useSettings, useUpdateSettings } from "hooks/settings"

const PermissionsSettingsSection = () => {
  const { settings, loading } = useSettings()
  const { updateSettings, loading: updating } = useUpdateSettings()

  const { currentUser } = useCurrentUser()

  if (loading || settings === undefined || !currentUser) return (
    <Card style={{
      width: '400px',
    }}>
      <Skeleton />
    </Card>
  )

  const initialValues = { ...settings, name: currentUser.account?.name }

  const handleSubmit = async (settings: IFormObject) => {
    const response = await updateSettings(settings)

    if (response.ok) {
      message.success("Workspace permissions updated!")
    }

    return response
  }

  return (
    <Card
      style={{
        width: '400px',
      }}
    >
        <h2>
          Permissions Settings
        </h2>

        <Divider style={{ marginTop: 0 }}/>

        <WorkspacePermissionsForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
 
    </Card>
  )
}

export default PermissionsSettingsSection