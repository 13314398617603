// @ts-ignore
import TagManager from 'react-gtm-module'

const GTAG_ID = process.env.REACT_APP_GTAG_ID
const GTAG_CONVERSION_ID = process.env.REACT_APP_GTAG_CONVERSION_ID

function gtag(){
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push(arguments);
}

export default function reportGtagConversion(gclid: string) {
  // @ts-ignore
  if (!GTAG_CONVERSION_ID || !window.TagManager || !GTAG_ID) {
    console.warn("No tag configuration")
    return
  }

  setTimeout(() => {
    try {
      console.info('Conv')
      // @ts-ignore
      gtag('event', 'conversion', {
        'send_to': GTAG_CONVERSION_ID,
        'gclid': gclid,
      });
    } catch (error) {
      console.error("Something went wrong", error)
    }

  }, 100)
}