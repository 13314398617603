import { useEffect, useState } from 'react'

import _filter from 'lodash/filter'

import { message } from 'components/base'
import { useQueryParams } from 'hooks/app-router'
import { useHistory } from 'react-router-dom'
import NewIntegrationStepsView, { NewIntegrationStep } from '../shared/components/new-integration-steps'
import { Card, Skeleton } from 'antd'
import PageHeader from 'components/layout/page-header'
import AutoConnectNewProjectsSwitch from './components/auto-connect-new-projects-switch'
import ProjectsList from './components/projects-list'
import ConnectAllProjects from './components/connect-all-projects'
import { useIntegrationAccountQuery } from 'services/integrations/integration-accounts/use-integration-account-query'
import { TApiIntegrationAccount, TApiIntegrationAccountsProject } from 'services/api-client/types'
import { map } from 'lodash'
import { getApiErrorMessage } from 'services/api-client'
import useApiClient from 'hooks/useApiClient'
import { useIntegrationAccountsExternalProjectsQuery } from 'services/integrations/integration-accounts-projects/use-integration-accounts-external-projects-query'
import useIntegrationAccountsActions from 'services/integrations/integration-accounts/use-integration-accounts-actions'

const NewSelectProjectsPage = () => {
  const history = useHistory()
  const [loaded, setLoaded] = useState(false)
  const [isDetailedSelect, setIsDetailedSelect] = useState(false)
  const apiClient = useApiClient()

  const queryParams = useQueryParams<{
    integrationAccountId: string
  }>()

  const integrationAccountId = queryParams.integrationAccountId || ""
  const {
    query: integrationAccountQuery
  } = useIntegrationAccountQuery({
    integrationAccountId: integrationAccountId
  })

  const integrationAccount = integrationAccountQuery.data?.integrationAccount as TApiIntegrationAccount

  const {
    query: integrationAccountsProjectsQuery
  } = useIntegrationAccountsExternalProjectsQuery ({
    integrationAccountId: integrationAccountId
  })

  const integrationAccountsProjects = integrationAccountsProjectsQuery.data?.integrationAccountsProjects || []

  useEffect(() => {
    if (integrationAccountQuery.isSuccess, integrationAccountsProjectsQuery.isSuccess) {
      setLoaded(true)
    }
  }, [integrationAccountQuery.isSuccess, integrationAccountsProjectsQuery.isSuccess])

  const integrationAccountActions = useIntegrationAccountsActions()

  const connectIntegrationAccountProjects = async (projects: TApiIntegrationAccountsProject[]) => {
    return integrationAccountActions.createIntegrationAccountProjects(integrationAccountId, projects)
  }
 
  const handleCreateProjects = (projects: Array<TApiIntegrationAccountsProject>) => {
    if (projects.length == 0) {
      redirectToNextStep()
    } else {
      const promise = connectIntegrationAccountProjects(projects)

      promise.then((returnValue) => {
        const response: any = returnValue

        if (response.ok) {
          redirectToNextStep()  
        }
      })
    }
  }

  const updateAutoSyncProjects = (newValue: boolean) => {
    const updateRequestBody = {
      integrationAccountId: integrationAccountId,
      integrationAccount: {
        autoSyncProjects: newValue
      }
    }

    return apiClient.updateIntegrationAccount(updateRequestBody)
  }

  const handleConnectAll = () => {
    const projects = _filter(integrationAccountsProjects, (integrationAccountProject) => {
      return !integrationAccountProject.connected
    })

    updateAutoSyncProjects(true)

    // Update workspace auto-sync to true
    // Create all available projects
    handleCreateProjects(projects)
  }

  const redirectToNextStep = () => {
    history.push(`/integrations/new-settings?integrationAccountId=${integrationAccountId}`)
  }

  const handleAutoSyncProjectChange = (newValue: boolean) => {
    const updatePromise: any = updateAutoSyncProjects(newValue)

    // @ts-ignore
    updatePromise.then((response) => {
      if (response.ok) {
        message.success('Auto sync setting updated!')
      } else {
        message.error('Something went wrong!')
      }
    })

  }

  const SELECT_ALL_HEADER_TEXT = "Select projects"
  const SELECT_DETAILED_HEADER_TEXT = "Select projects you’d like to connect"
  const HINT_TEXT = "Choose integration projects that you want to track time. Even after completing the integration process, you can always connect missing projects later on."

  return (
    <div className="page-content">
      {loaded ? (
        <>
          <NewIntegrationStepsView currentStep={NewIntegrationStep.SelectProjects} />

          {isDetailedSelect ? (
            <Card>
              <PageHeader
                title={SELECT_DETAILED_HEADER_TEXT}
                titleHint={HINT_TEXT}
              />

              <AutoConnectNewProjectsSwitch
                value={integrationAccount.autoSyncProjects}
                onChange={handleAutoSyncProjectChange}
              />

              <ProjectsList
                projects={integrationAccountsProjects}
                onSubmit={handleCreateProjects}
              />
            </Card>
          ) : (
            <Card>
              <PageHeader
                title={SELECT_ALL_HEADER_TEXT}
                titleHint={HINT_TEXT}
              />

              <ConnectAllProjects
                onSelectProjects={() => { setIsDetailedSelect(true) }}
                onConnectAll={handleConnectAll}
              />
            </Card>
          )}

        </>
      ) : (
        <Skeleton />
      )}
    </div>
  )
}

export default NewSelectProjectsPage