import { MoreOutlined } from "@ant-design/icons"
import { TApiPeriod, TApiPeriodStatus } from "@timenotes/shared/src/services/approvals/approvals-types"
import usePeriodUpdateMutation from "@timenotes/shared/src/services/approvals/queries/use-period-update-mutation"
import { Button, Dropdown, Menu, message, Skeleton } from "antd"

export interface PeriodStatusDropdownProps {
  period?: TApiPeriod
  isLoading: boolean
}

export default function PeriodStatusDropdown(props: PeriodStatusDropdownProps) {
  const {
    period,
    isLoading,
  } = props

  const periodUpdateMutation = usePeriodUpdateMutation()

  const handlePeriodStatusUpdate = (status: TApiPeriodStatus) => {
    periodUpdateMutation.mutate({ id: period?.id , status: status })
  }

  let periodStatusLabel = "Closed for all"

  if (period?.status == 'approvers') {
    periodStatusLabel = "Closed for members"
  }

  if (period?.status == "open") {
    periodStatusLabel = "Open for all"
  }

  const menu = (
    <Menu 
      onClick={(e) => {
      handlePeriodStatusUpdate(e.key as TApiPeriodStatus)
    }}>
      <Menu.Item key="closed"><b>Close for all</b> - close period for all users, even admins can not change the time.</Menu.Item>
      <Menu.Item key="approvers"><b>Close for members</b> - close period only for regular members, admins can still manage time.</Menu.Item>
      <Menu.Item key="open"><b>Open for all</b> - open period for all admins and regular members, all users can manage time.</Menu.Item>
    </Menu>
  )

  if (isLoading) {
    return (
      <Skeleton.Button
        active={true}
        style={{ width: '280px' }}
      />
    )
  }

  return (
    <div
      style={{
        width: '280px',
      }}
    >
      <Dropdown
        overlay={menu}
        trigger={'click'}
      >
        <Button
          // loading={true} moves button too hard, needs styling adjustment
          onKeyUp={(e) => {
            if (e.key == "Escape") {
            }
          }}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div>
            <b>Period Status: </b>
            {periodStatusLabel}
          </div>

          {
          /*
                  <div style={{ paddingRight: '30px' }}>
                    <MoreOutlined />
                  </div>
          */
        }
        </Button>
      </Dropdown>
    </div>
  )
}