import React from 'react'
import PropTypes from 'prop-types'

export class OutsideAlerter extends React.Component {
  constructor(props) {
    super(props)

    this.wrapperRef = React.createRef()
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', this.handleEscape);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleEscape);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   *  Esc detection
   */
  handleEscape = (event) => {
    if (event.code === 'Escape') {
      if (this.props.onEsc) {
        this.props.onEsc()
      } else {
        this.props.onClick()
      }
    }
  }

  /**
   * Using component div wrapper or props function to get the node
   * used to check if click happened outside of given area
   */
  innerNode() {
    const wrapperNode = this.wrapperRef.current

    return this.props.getInnerNode ? this.props.getInnerNode(wrapperNode) : wrapperNode
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    const node = this.innerNode()

    if (this.wrapperRef && node && !node.contains(event.target)) {

      this.props.onClick()
    }
  }

  render() {
    return <div style={this.props.wrapperStyle || {} } ref={this.wrapperRef}>{this.props.children}</div>;
  }
}

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired,
  onEsc: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  getInnerNode: PropTypes.func,
}

export default OutsideAlerter
