import { Route, Switch, Redirect } from "react-router-dom";
import AppLayout from "../layouts/app-layout";
import AuthLayout from '../layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { AUTH_PREFIX_PATH } from 'consts/theme-config'
import useBodyClass from 'hooks/useBodyClass';
import { useThemeConfig } from "services/layout/layout.hooks";

export const Views = (props) => {
  const { themeConfig } = useThemeConfig()
  const { theme, direction, locale } = themeConfig
  const location = props.location

  const currentAppLocale = AppLocale[locale];

  useBodyClass(`dir-${direction}`);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>

          <Route exact path="/">
            <Redirect to={'/timer'} />
          </Route>

          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>

          <Route path={""}>
            <AppLayout direction={direction} location={location}/>
          </Route>

        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}


export default Views
