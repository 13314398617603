import React, { useCallback, useEffect, useState } from 'react'
import { TApiUsersAccount } from 'services/api-client/types'
import _map from 'lodash/map'
import { debounce } from 'lodash'
import { useApiClient } from '@timenotes/shared/src/services/api-client/api-client.hooks'
import { useQueryClient } from 'react-query'
import RecordsSelect from '../records-select'
import { useUsersAccountsQuery } from '@timenotes/shared/src/services/users-accounts/queries/use-users-accounts-query';
import { getUsersAccountFullName } from 'services/users-accounts';

type UsersAccountSelectProps = Partial<React.ComponentProps<typeof RecordsSelect<TApiUsersAccount>>> & {
  filter?(usersAccounts: TApiUsersAccount[]): TApiUsersAccount[]
}

const UsersAccountSelect = (selectProps: UsersAccountSelectProps) => {
  const [searchValue, setSearchValue] = useState("")

  // this is needed to store searchValue to modal before it gets cleared
  const [initialName, setInitialName] = useState(searchValue)

  const queryClient = useQueryClient()
  const apiClient = useApiClient()

  const [nameFilter, setNameFilter] = useState<string>(searchValue)

  const updateNameFilterBasedoOnSearch = useCallback(
    debounce((name: string) => {
      setNameFilter(name)
    }, 300)
    ,[]
  )

  useEffect(() => {
    updateNameFilterBasedoOnSearch(searchValue)
  }, [searchValue])

  const usersAccountsQuery = useUsersAccountsQuery({
    filters: {
      name: nameFilter,
      state: 'active',
    }
  })

  const isDisabled = usersAccountsQuery.isFetching || usersAccountsQuery.isError

  const style = { 
    width: "100%",
    ...selectProps.style
  }

  const apiOptions = !usersAccountsQuery.isSuccess ? [] : usersAccountsQuery.data.usersAccounts

  const filteredOptions = !selectProps.filter ? apiOptions : selectProps.filter(apiOptions)

  const options = filteredOptions.map((user) => {
    return {
      label: getUsersAccountFullName(user),
      value: {
        ...user,
        fullName: getUsersAccountFullName(user),
      }
    }
  })

  return (
    <>
      <RecordsSelect<TApiUsersAccount>
        loading={usersAccountsQuery.isFetching}
        //dropdownRender={renderDropdown}
        dropdownMatchSelectWidth={false}
        placeholder="Select member"
        // disabled={isDisabled}
        style={style}
        showSearch={true}
        onSearch={(searchValue) => { setSearchValue(searchValue)}}
        searchValue={searchValue}
        options={options}

        valueProp="id"
        searchProp="fullName"
        labelProp={(user) => { 
         return getUsersAccountFullName(user)
        }}

        {...selectProps}
      />
   </>
  )
}

export default UsersAccountSelect