const Bus = (props: React.ComponentProps<"svg">) => (
    <svg width="17px" height="19px" viewBox="0 0 17 19" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props} >
        <g id="Holidays-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="9.2.3-Create-absence-type-" transform="translate(-274.000000, -326.000000)">
                <g id="Group" transform="translate(216.000000, 284.000000)">
                    <g id="icon-/-application-/-dark-/-icon-1-copy" transform="translate(54.333333, 40.000000)">
                        <g id="baseline-directions_bus-24px">
                            <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                            <path d="M4,16 C4,16.88 4.39,17.67 5,18.22 L5,20 C5,20.55 5.45,21 6,21 L7,21 C7.55,21 8,20.55 8,20 L8,19 L16,19 L16,20 C16,20.55 16.45,21 17,21 L18,21 C18.55,21 19,20.55 19,20 L19,18.22 C19.61,17.67 20,16.88 20,16 L20,6 C20,2.5 16.42,2 12,2 C7.58,2 4,2.5 4,6 L4,16 Z M7.5,17 C6.67,17 6,16.33 6,15.5 C6,14.67 6.67,14 7.5,14 C8.33,14 9,14.67 9,15.5 C9,16.33 8.33,17 7.5,17 Z M16.5,17 C15.67,17 15,16.33 15,15.5 C15,14.67 15.67,14 16.5,14 C17.33,14 18,14.67 18,15.5 C18,16.33 17.33,17 16.5,17 Z M18,11 L6,11 L6,6 L18,6 L18,11 Z" id="Shape" fill="#474D55" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Bus