import { TApiProject } from "@timenotes/shared/src/services/api-client/types";
import { useSimplifiedProjectFormik } from "@timenotes/shared/src/services/projects/projects.hooks";
import { Col, Form, Input, Modal, Row, message } from "antd";
import ColorPickerSelect from "components/forms/color-picker-select";
import { useEffect } from "react";
import styled from "styled-components";


const TimenotesForm = styled(Form)`
  .ant-form-item-explain {
    position: absolute !important;
    top: 40px;
    z-index: 1000;
  }
`

interface NewProjectModalProps extends React.ComponentProps<typeof Modal> {
  initialValues: Partial<{ color: string, name: string }>
  onSuccess(project: TApiProject): void
  setVisible?(visible: boolean): void
}

const NewProjectModal = (props: NewProjectModalProps) => {
  const { formik } = useSimplifiedProjectFormik({
    initialValues: props.initialValues,
    onSuccess: (project) => {
      if (props.onSuccess) props.onSuccess(project)
      if (props.setVisible) props.setVisible(false)
    }
  })

  // Generic base error handling
  useEffect(() => {
    if (formik.errors.base) {
      message.error(formik.errors.base)
    }
  }, [formik.errors?.base])

  return (
    <Modal
      title="Create new project"
      okText="Create"
      onOk={formik.submitForm}
      confirmLoading={formik.isSubmitting}
      destroyOnClose={true}
      {...props}
      onCancel={(e) => {
        formik.resetForm()
        if (props.setVisible) props.setVisible(false)
        if (props.onCancel) props.onCancel(e)
      }}
    >
      <TimenotesForm onSubmitCapture={formik.submitForm} layout="vertical">
        <Row>
          <Col span="2">
            <Form.Item
              label="Color"
              validateStatus={formik.errors.color && 'error'}
              help={formik.errors.color}
            >
              <ColorPickerSelect
                value={formik.values.color}
                onChange={(color) => {
                  formik.setFieldValue('color', color)
                }}
              />
            </Form.Item>
          </Col>
          <Col span="20" offset="2">
            <Form.Item
              label="Name"
              validateStatus={formik.errors.name && 'error'}
              help={formik.errors.name}
            >
              <Input
                onPressEnter={formik.submitForm}
                value={formik.values.name}
                onChange={(e) => {
                  formik.setFieldValue('name', e.target.value)
                }}
                autoFocus={true}
              />
            </Form.Item>
          </Col>
        </Row>
      </TimenotesForm>
    </Modal >
  )
}

export default NewProjectModal