// @ts-nocheck
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import { goTo as legacyGoTo } from 'utils/history'
import AbsenceSettingsPage from "pages/absences/absence-settings-page"
import PageLoadingSping from "components/layout/page-loading-spin"
import IntegrationsRoutes from "./integrations/integrations-routes"
import NewImportPage from "./imports/new-import-page"
import ConfigureImportColumnsPage from "./imports/configure-import-columns-page"
import ConfigureImportProjectsPage from "./imports/configure-import-projects-page"
import ConfigureImportUsersPage from "./imports/configure-import-users-page"
import ImportSummaryPage from "./imports/import-summary-page"
import ProjectsTasksPage from "pages/projects/tasks/projects-tasks-page"
import ProjectsTeamPage from "./projects/team/projects-team-page"
import ProjectsSettingsPage from "./projects/settings/projects-settings-page"
import ProjectsInsightsPage from "./projects/insights/projects-insights-page"
import ProjectsListPage from "./projects/list/projects-list-page"
import NewProjectPage from "./projects/new/new-project-page"
import DashboardPage from "pages/dashboard/dashboard-page"
import TimerPage from "./timer/timer-page"
import MembersGroupsListPage from "./team/members-groups-list-page"
import DetailedReportsPage from "./reports/detailed-reports-page"
import TimesheetsPage from "./timesheets/timesheets-page"
import MembersListPage from "./team/members-list-page"
import AbsencesCalendarPage from "./absences/absences-calendar-page"
import MyAbsences from "pages/absences/my-absences-page"
import AbsenceRequestsPage from "pages/absences/absence-requests-page"
import AlertsPage from "pages/alerts/alerts-page"
import TagsPage from "./tags/tags-page"
import ClientsPage from "./clients/clients-page"
import SettingsLayoutPage from "pages/settings/settings-layout-page"
import { useCurrentProjectsPermissionsQuery } from "@timenotes/shared/src/services/permissions/permissions.hooks"
import ApprovalsPage from "./approvals/approvals-periods-page"
import ApprovalsMembersPage from "./approvals/approvals-members-page"
import MembersPeriodDetailsPage from "./approvals/members-period-details-page"
import ApprovalsRootRedirectPage from "./approvals/approvals-root-redirect-page"
import PeriodsPage from "./approvals/periods-page"

const ProjectDetailsRoutes = () => {
  const routeMatch = useRouteMatch()
  const projectId = routeMatch.params.projectId as string

  const isNewProject = projectId == 'new'

  const currentProjectsPermissionsQuery = useCurrentProjectsPermissionsQuery({
    params: {
      projectId: projectId
    },
    options: {
      enabled: !isNewProject 
    }
  })

  if (isNewProject) return null

  const loaded = currentProjectsPermissionsQuery.isSuccess

  if (!loaded) {
    return <PageLoadingSping />
  }
  const canManageCurrentProject = currentProjectsPermissionsQuery.data?.permissions.manageCurrentProject

  return (
    <Switch>
      <Route exact path={`/projects/:projectId`}>
        <Redirect to={canManageCurrentProject ? `/projects/${projectId}/insights` : `/projects/${projectId}/tasks`} />
      </Route>

      <Route exact path={`/projects/:projectId/tasks`} component={ProjectsTasksPage} />
      <Route exact path={`/projects/:projectId/team`} component={ProjectsTeamPage} />

      {canManageCurrentProject ? (
        <>
          <Route exact path={`/projects/:projectId/settings`} component={ProjectsSettingsPage} />
          <Route exact path={`/projects/:projectId/insights`} component={ProjectsInsightsPage} />
        </>
      ) : (
        <Redirect to={`/projects/${projectId}/tasks`} />
      )}
    </Switch>
  )
}

const Routes = (props: {
  subscriptionValid: boolean
}) => {
  return (
    <Switch>

      <Route exact path={`/settings/:sectionKey`} component={SettingsLayoutPage} />
      <Route exact path={`/settings/:sectionKey/:activeTab`} component={SettingsLayoutPage} />

      {props.subscriptionValid && (
        <>

          <Route exact path={`/projects`} component={ProjectsListPage} />
          <Route exact path={`/projects/new`} component={NewProjectPage} />
          <Route path={`/projects/:projectId`}>
            <ProjectDetailsRoutes />
          </Route>

          <Route exact path={`/activities/dashboard`} component={DashboardPage} />
          <Route exact path={`/timer`} component={TimerPage} />

          <Route exact path={`/team/groups`} component={MembersGroupsListPage} />
          <Route exact path={`/team/members`} component={MembersListPage} />

          <Route exact path={`/reports`} component={DetailedReportsPage} />
          <Route exact path={`/timesheets`} component={TimesheetsPage} />

          <Route exact path={`/approvals`} component={ApprovalsRootRedirectPage} />
          <Route exact path={`/approvals/periods`} component={PeriodsPage} />
          <Route exact path={`/approvals/period-overview`} component={ApprovalsPage} />
          <Route exact path={`/approvals/members`} component={ApprovalsMembersPage} />
          <Route exact path={`/approvals/history`} component={() => (<ApprovalsMembersPage isMyApproval />)} />

          <Route exact path={`/approvals/periods/:periodId/user/:usersAccountId`} component={MembersPeriodDetailsPage} />

          <Route exact path={`/absences/calendar`} component={AbsencesCalendarPage} />
          <Route exact path={`/absences/my-absences`} component={MyAbsences} />
          <Route exact path={`/absences/requests`} component={AbsenceRequestsPage} />
          <Route exact path={`/absences/settings`} render={props => (<AbsenceSettingsPage {...props} activeTab="ABSENCE_TYPES" />)} />
          <Route exact path={`/absences/holidays`} render={props => (<AbsenceSettingsPage {...props} activeTab="HOLIDAYS" />)} />

          <Route exact path={`/alerts`} component={AlertsPage} />

          <Route exact path={`/tags`} component={TagsPage} />

          <Route exact path={`/clients`} component={ClientsPage} />
          <Route exact path="/imports/new" render={(props) => <NewImportPage />} />
          <Route exact path="/imports/:importId/configure-columns" render={(props) => <ConfigureImportColumnsPage />} />
          <Route exact path="/imports/:importId/configure-projects" render={(props) => <ConfigureImportProjectsPage />} />
          <Route exact path="/imports/:importId/configure-users" render={(props) => <ConfigureImportUsersPage />} />
          <Route exact path="/imports/:importId/summary" render={(props) => <ImportSummaryPage />} />


          <Route path="/integrations">
            <IntegrationsRoutes />
          </Route>
        </>
      )}

      <Redirect from={"/"} to={`/timer`} />
      <Redirect from={"/imports"} to={`/imports/new`} />
    </Switch>
  )
}

// TODO -> this one and above Routes component should rather be generated from the same codebase configuration
export class Router {
  constructor(history) {
    this.history = history
  }

  goTo(path) {
    return this.history.push(path)
  }

  goToPath(path: string) {
    return this.goTo(`/${path}`)
  }

  goToTeamPath = () => {
    return this.goToTeamMembersListPath()
  }

  goToTeamMembersListPath = () => {
    return this.goTo(`/team/members`)
  }

  goToTeamMembersGroupsListPath = () => {
    return this.goTo(`/team/groups`)
  }

  goToProjectsListPath = () => {
    return this.goTo(`/projects`)
  }

  goToNewProjectPath = () => {
    return this.goTo(`/projects/new`)
  }

  goToProjectPath = (projectId: string) => {
    if (projectId == null) {
    } else {
      return this.goTo(`/projects/${projectId}`)
    }
  }

  goToProjectsTasks = (projectId: string) => {
    return this.goTo(`/projects/${projectId}/tasks`)
  }

  goToProjectsTeam = (projectId: string) => {
    return this.goTo(`/projects/${projectId}/team`)
  }

  goToProjectsSettings = (projectId: string) => {
    return this.goTo(`/projects/${projectId}/settings`)
  }

  goToNewIntegrationProjectPath = () => {
    return this.goTo(`/integrations/new`)
  }

  goToImportDataPath = () => {
    window.top.path = "/imports/new"
  }

  goToIntegrationsList = () => {
    return legacyGoTo(`/integrations/workspaces`)
  }

  goToUserAuthentications = () => {
    return legacyGoTo(`/integrations/authentications`)
  }

  goToAbsencesCalendar = () => {
    return this.goTo(`/absences/calendar`)
  }

  goToMyAbsences = () => {
    return this.goTo(`/absences/my-absences`)
  }

  goToAbsenceRequests = () => {
    return this.goTo(`/absences/requests`)
  }

  goToAbsenceSettings = () => {
    return this.goTo(`/absences/settings`)
  }

  goToAbsenceHolidays = () => {
    return this.goTo(`/absences/holidays`)
  }

}

export default Routes
