import PageTabsHeader from "components/layout/page-tabs-header"
import { useAppRouter } from "hooks/app-router"
import { useParams } from "react-router-dom"
import GeneralSettingsSection from "./workspace/general-settings-section"
import PermissionsSettingsSection from "./workspace/permissions-settings-section"
import TimesheetLockingSection from "./workspace/timesheet-locking-section"
import { usePagePermissionsCheck } from "hooks/permissions.hooks"
import ApprovalsSection from "./workspace/approvals-section"

interface ISettingsWorkspacePageParams {
  activeTab: 'general' | 'permissions' | 'timesheet-locking'
}

const SettingsWorkspacePage = () => {
  usePagePermissionsCheck('manageSettings')

  const { activeTab } = useParams<ISettingsWorkspacePageParams>()
  const appRouter = useAppRouter()

  const AcitveTabComponentMap = {
    'general': <GeneralSettingsSection />,
    'permissions': <PermissionsSettingsSection />,
    'approvals': <ApprovalsSection />,
    'timesheet-locking': <TimesheetLockingSection />,
  }

  const ActiveTabComponent = AcitveTabComponentMap[activeTab]

  return (
    <div>
      <PageTabsHeader
        title="Workspace Settings"
        tabs={[
          {
            label: 'General',
            urlMatcher: "settings/workspace/general",
            onClick: () => { 
              return appRouter.goToPath('settings/workspace/general') 
            },
          },
          {
            label: 'Permissions',
            urlMatcher: "settings/workspace/permissions",
            onClick: () => { 
              return appRouter.goToPath("settings/workspace/permissions") 
            },
          },
          {
            label: 'Approvals',
            urlMatcher: "settings/workspace/approvals",
            onClick: () => { 
              return appRouter.goToPath("settings/workspace/approvals") 
            },
          },
          {
            label: 'Timesheet Locking',
            urlMatcher: "settings/workspace/timesheet-locking",
            onClick: () => { 
              return appRouter.goToPath('settings/workspace/timesheet-locking') 
            },
          },
        ]}
      />
      <div
        className="page-content"
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {ActiveTabComponent}
      </div>
    </div>
  )
}

export default SettingsWorkspacePage