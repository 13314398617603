import { TApiTimesheetCell, TApiTimesheetRow } from 'services/api-client/timesheets-api'
import TimesheetStylesContainer from './timesheet-styles-container'
import { Card, Table, Tooltip } from 'antd'
import { map, sum, sumBy } from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'

import Duration from 'utils/duration'
import { useState } from 'react'
import AbsenceIcon from 'components/base/absence-icon'
import { COLORS } from 'components/layout-components/timenotes-theme'
import { useRecommendedWorktime, useWorkingWeekDays } from 'hooks/settings'
import Empty from 'antd/es/empty'

interface TimesheetProps {
  rows: TApiTimesheetRow[]
  onCellClick(row: TApiTimesheetRow, cell: TApiTimesheetCell, parentRow?: TApiTimesheetRow): void
}

const isWeekend = (date: Moment, workingWeekDays: number[]): boolean => {
  const day = date.day()
  return !workingWeekDays.includes(day)
}

export const toWatchString = (minutes: number): string => {
  return new Duration(minutes as any).toWatchString() as any as string 
}

const getCellClassName = (cell: TApiTimesheetCell, workingWeekDays: number[]): string => {
  const momentDate = moment(cell.date)

  const emptyClassName = cell.worktime == 0 ? "is_empty" : ""
  const weekendClassName = isWeekend(moment(cell.date), workingWeekDays) ? "col_weekend" : ""

  let todayClassName = ""

  if (momentDate.isSame(moment(), 'day')) {
    todayClassName = 'col_today'
  } else if (momentDate.isSame(moment().subtract(1, 'day'), 'day')) {
    todayClassName = 'col_yesterday'
  }

  return `${emptyClassName} ${weekendClassName} ${todayClassName}`
}

const SummaryRow = (props: {
  cells: TApiTimesheetCell[]
}) => {

  const { workingWeekDays } = useWorkingWeekDays()

  const dayCells = map(props.cells, (cell) => {
    const watchString: string =  toWatchString(cell.worktime)

    return (
      <td 
        className={getCellClassName(cell, workingWeekDays)}
      >{cell.worktime == 0 ? "" : watchString}</td>
    )
  })

  const totalString = toWatchString(sumBy(props.cells, 'worktime'))

  return (
    <tr className="sum_important_row timesheets_summary_row">
        <td className="col_person">
          <div className="row_expander"></div>
          <div>∑</div>
          <div className="sum_wide">{totalString}</div>
        </td>
        <td className="col_sum">{totalString}</td>
        {dayCells}
      </tr>
  )
}

const TimesheetRow = (props: 
  { 
    row: TApiTimesheetRow,
    onClick: TimesheetProps['onCellClick'],
    isNested?: boolean,
    recommendedWorktime: number,
  }) => {

  const { row, onClick } = props

  const isExpandable = row.rows.length > 0
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const { workingWeekDays, loaded: workingWeekDaysLoaded } = useWorkingWeekDays()

  const rowTotalDuration: string = new Duration(row.totalWorktime as any).toWatchString() as any as string 

  const dayCells = map(row.cells, (cell) => {
    const hours: number = new Duration(cell.worktime as any).getHours() as any as number
    const watchString: string =  toWatchString(cell.worktime)

    let className = ""

    const weekendClassName = isWeekend(moment(cell.date), workingWeekDays) ? "col_weekend" : ""

    if (row.groupClass == 'UsersAccount' && !props.isNested) {
      const emptyClassName = cell.worktime == 0 ? "is_empty" : "is_added"
      // recommendedWorktime is in minutes 
      const fullClassName = (hours > 0 && (hours*60) < props.recommendedWorktime) ? "not_enough" : ""

      className = `${emptyClassName} ${fullClassName}`
    }
    className = `${className} ${getCellClassName(cell, workingWeekDays)}`

    const absences = cell.absences

    const cellContent = absences.length > 0 ? (
      <div className="icon_container">
        <span className="duration">{cell.worktime == 0 ? "" : watchString}</span>
          <Tooltip 
            key={`${cell.date}-${row.groupId}`}
            placement='bottom'
            color="white" 
            title={(
              <div>
                {map(absences, (absence) => (
                  <div 
                    key={`${absence.dateRange}-${absence.type}`}
                  >
                    <p>
                      <b>{absence.type}</b>
                    </p>
                    <p>
                      {absence.dateRange}
                    </p>
                  </div>
                ))}
              </div>
            )}>
            <AbsenceIcon icon={absences.length > 1 ? 'default' : absences[0].icon} />
          </Tooltip>
      </div>
    ) : (
      <span className="duration">{cell.worktime == 0 ? "" : watchString}</span>
    )
    
    return (
      <td 
        onClick={() => { onClick(row, cell) }}
        className={`day-report user-daily ${className} ${weekendClassName}`}
      >
        {cellContent}
      </td>
    )
  })

  const rowClassName = props.isNested ? 'timesheets_secondary_row' : 'timesheets_primary_row'

  return (
    <>
      <tr className={`timesheets_row ${rowClassName}`}>
        <td className="col_person project_name"
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
          style={{
            ...(isExpandable ? { cursor: 'pointer' } : {})
          }}
        >
          <div
            className="row_expander"
          >
            {isExpandable && (
              <>
                {isExpanded ? (
                  <CaretUpOutlined />
                ) : (
                  <CaretDownOutlined />
                )}
              </>
            )}
          </div>

          <div className="row_title_wrapper">
            <Tooltip
              overlayInnerStyle={{
                backgroundColor: 'white',
                color: COLORS.linkGray,
              }}
              title={(
                <div className="details_info_person">
                  <span className="data_full_name" style={{
                    display: 'flex',
                    gap: '5px',
                  }}>
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {row.title}
                    </span>
                    -
                    <span
                      style={{
                        color: COLORS.primaryGreen,
                      }}
                    >
                      {rowTotalDuration}
                    </span>
                  </span>
                </div>
              )}
            >

              <div className="name_user_tr">
                <span className="full_name">
                  {row.title}
                </span>

                <span className="short_name">
                  {row.shortTitle}
                </span>
              </div>

            </Tooltip>
        </div>
      </td>

      <td className="col_sum">
        {rowTotalDuration}
      </td>

      {dayCells}
    </tr>
    { isExpandable && isExpanded && (
      <>
        {map(row.rows, (row) => { return (
          <TimesheetRow 
            row={row} 
            onClick={(nestedRow, nestedCell) => { onClick(nestedRow, nestedCell, props.row) }}  // This passed the parent row from props to all nested row onClick functions
            isNested={true} 
            recommendedWorktime={props.recommendedWorktime}
          />
        )
      })}
      </>
    )}
    </>
  )
}

const Timesheet = ({ rows, onCellClick } : TimesheetProps) => {

  const { recommendedWorktime } = useRecommendedWorktime()
  const { workingWeekDays } = useWorkingWeekDays()

  if (rows.length == 0) {
    return (
      <Table 
        locale={{emptyText: (<Empty description="No data to display" image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
      />
    )
  }

  const refCells = rows[0].cells
  const numberOfDays = refCells.length

  const dayCells = map(refCells, (cell) => {
    const momentDate = moment(cell.date)
    const weekendClassName = isWeekend(momentDate, workingWeekDays) ? 'col_weekend' : ""
    const todayClassName = momentDate.isSame(moment(), 'day') ? 'is_today' : ""

    return (
      <th className={`${weekendClassName} ${todayClassName}`}>
        {momentDate.date()}
      </th>
    )
  })

  const timesheetRows = map(rows, (row) => {
    return (
      <TimesheetRow 
        key={row.groupId} 
        row={row} 
        onClick={onCellClick}
        recommendedWorktime={recommendedWorktime}
      />
    )
  })

  const summaryCells = map(rows[0].cells, (cell, index) => {
    const cellClone = { ...cell }

    cellClone.worktime = sumBy(rows, (row) => { return row.cells[index].worktime })

    return cellClone
  })

  const summaryRow = (
    <TimesheetRow 
      key={rows[0].groupId} 
      row={{
        ...rows[0],
        cells: summaryCells,
      }} 
      recommendedWorktime={recommendedWorktime}
      onClick={onCellClick}
    />
  )

  return (
    <Card>
      <TimesheetStylesContainer>
        <div className="calendar_table">
          <table className="base_table">
            <thead>
              <tr>
                <th className="col_person"></th>
                <th className="col_sum">∑</th>
                {dayCells}

              </tr>
            </thead>
            <tbody>
              {timesheetRows}
              <SummaryRow cells={summaryCells}/>
            </tbody>
          </table>
        </div>
      </TimesheetStylesContainer>
    </Card>
  )
}

export default Timesheet
