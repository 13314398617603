import { useState } from 'react'

import _find from "lodash/find"
import { TApiIntegrationAccount, TApiIntegrationApp } from 'services/api-client/types'
import styled from 'styled-components'
import { Button, Card, Radio } from 'antd'
import { map } from 'lodash'

const COLORS = {
  primary: '#07c5b7',
  cardHighlight: "#EFEFEF",
}

const AppCard = styled(Card)`
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 195px;
  height: 195px;
  margin-top: 52px;
  padding: 10px;

  & .card-logo {
    display: flex;
    justify-content: center;
  }

  & .card-app-name{
    margin-top: 5px;
    margin-bottom: 14px;
    font-size: 24px;
    text-align: center;
    text-transform: capitalize;
  }

  & img {
    margin: auto;
  }
`

const WorkspaceListViewWrapper = styled.div`
  margin-top: 26px;

  & p {
    color #474D55;
  }
`

const VerticalRadioFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & label {
    margin-bottom: 14px;
  }
`

const SubmitButton = styled(Button)`
  margin-top: 20px;
`


export interface IWorkspaceList {
  integrationApp: TApiIntegrationApp
  integrationAccounts: Array<TApiIntegrationAccount>
  onAppClick: {
    (app: TApiIntegrationApp, integrationAccount: TApiIntegrationAccount): void
  }
}

const WorkspaceList = (props: IWorkspaceList) => {
  const [selectedIntegrationAccountId, setSelectedIntegrationAccountId] = useState<string | undefined>()

  const handleSubmit = (): void => {
    const selectedIntegrationAccount = _find(props.integrationAccounts, (integrationAccount) => {
      return integrationAccount.originIdentifier == selectedIntegrationAccountId
    })

    props.onAppClick(props.integrationApp, selectedIntegrationAccount as TApiIntegrationAccount)
  }

  const integrationAccountRadios: Array<JSX.Element> = map(props.integrationAccounts, (integrationAccount) => {

    let additionalInfo: string = ""

    if (!!integrationAccount.id) {
      if (integrationAccount.isConnected) {
        additionalInfo = "workspace already connected, select to connect more projects in scope of existing workspace"
      } else {
        additionalInfo = "workspace already exists but it is disconnected, selected to re-connect the workspace using your integration account"
      }
    }

    return (
      <Radio value={integrationAccount.originIdentifier} key={integrationAccount.originIdentifier}>
        {integrationAccount.name} <i> {additionalInfo}</i>
      </Radio>
    )
  })

  return (
    <WorkspaceListViewWrapper>

      <p style={{display: 'none' }}>Listed workspaces for Damian (Asana user: damian@gmail.com) </p>

      <Radio.Group value={selectedIntegrationAccountId} onChange={(e) => { setSelectedIntegrationAccountId(e.target.value)}}>
        <VerticalRadioFlex>
          {integrationAccountRadios}
        </VerticalRadioFlex>
      </Radio.Group>

      <br />

      <SubmitButton disabled={selectedIntegrationAccountId == null } type="primary" onClick={() => { handleSubmit() }}> Next </SubmitButton>
    </WorkspaceListViewWrapper>
  )
}

export default WorkspaceList