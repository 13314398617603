import React from 'react'
import PropTypes from 'prop-types'

// import styled from 'styled-components'
import { Card, Row, Col } from 'antd'
import Flex from 'components/shared-components/Flex'
import { formatTimeInMinutes } from '@timenotes/shared/src/services/time-logs/utils/time-formatters'
import { TApiProject, TApiTask } from 'services/api-client/types'

export const SummarySection = (props: {
  title: string,
  subTitle: string,
}) => {
  return (
    <Flex className="dashboard-summary-view" flexDirection="column">
      <span className="title"> {props.title} </span>
      <span className="value"> {props.subTitle} </span>
    </Flex>
  )
}

const DashboardSummary = (props: {
  topProject: TApiProject,
  topTask: TApiTask,
  totalDuration: number,
}) => {
    const projectName = props?.topProject?.name || '-'
    const taskName = props?.topTask?.name || '-'
    const totalDurationString = formatTimeInMinutes(props?.totalDuration || 0)

    return (
      <Card style={{ width: '100%', height: '84px', textAlign: 'center' }}>
        <Row gutter={[8, 8]}>

          <Col span={8} >
            <SummarySection
              title="Total Time Logged"
              subTitle={totalDurationString}
            />
          </Col>

          <Col span={8} >
            <SummarySection
              title="Top Project"
              subTitle={projectName}
            />
          </Col>

          <Col span={8} >
            <SummarySection
              title="Top Task"
              subTitle={taskName}
            />
          </Col>

        </Row>
      </Card>
    )
  }

export default DashboardSummary