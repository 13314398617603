import { TApiPeekedInvitation } from "services/api-client/types"
import useApiClient from "./useApiClient"
import { message } from "antd"
import { useHistory } from "react-router-dom"
import { useApiCall } from "./useApiCall"


export const getPeekedInvitation = () => {
	const peekedInvitationJSON = window.localStorage.getItem('peekedInvitationJSON')
	let peekedInvitation: TApiPeekedInvitation | undefined = undefined

	if (peekedInvitationJSON) {
		peekedInvitation = JSON.parse(peekedInvitationJSON)
	}

  return peekedInvitation
}

export const usePeekedInvitation = () => {
  const apiClient = useApiClient()
  const history = useHistory()

  const resolvePeekedInvitationOnLogin = async () => {
    apiClient.loadAccessCredentials()
    const peekedInvitation = getPeekedInvitation()

    if (peekedInvitation) {
      const response = await apiClient.getCurrentUser()

      if (response.ok) {
        const currentUser = response.user

        if (currentUser.email == peekedInvitation?.email) {
          //message.success('Correct user logged in - redirect to confirm!')
          history.push('/auth/auth-confirm-invitation')

          return true
        } else {
          // User logged in to the different email, just clear the peeked invitation so he needs to do it again.
          //message.success('Wrong user - remove peeked invitation')
          window.localStorage.removeItem('peekedInvitationJSON')

          return false
        }
      }
    }

    return false
  }

  return {
    peekedInvitation: getPeekedInvitation(),
    resolvePeekedInvitationOnLogin,
  }
}

export const useAuthorizedConfirmInvitation = () => {
  const apiClient = useApiClient()

  const authorizedConfirmInvitationCall = async ({
    token,
    firstName,
    lastName,
  }: {
    token: string,
    firstName: string,
    lastName: string
  }) => {
    return apiClient.patch('/invitations/authorized_confirm', {
      token: token,
      usersAccount: {
        firstName: firstName,
        lastName: lastName,
      }
    })
  }

  const {
    data: response,
    request: authorizedConfirmInvitation,
    loading: authorizedConfirmInvitationLoading,
  } = useApiCall(authorizedConfirmInvitationCall)

  return {
    data: response,
    authorizedConfirmInvitation,
    authorizedConfirmInvitationLoading,
  }
}

export const useConfirmInvitation = () => {
  const apiClient = useApiClient()

  const confirmInvitationCall = async ({
    token,
    firstName,
    lastName,
    password,
  }: {
    token: string,
    firstName: string,
    lastName: string,
    password: string,
  }) => {
    return apiClient.patch('/invitations/confirm', {
      token: token,
      usersAccount: {
        firstName: firstName,
        lastName: lastName,
      },
      user: {
        password: password,
      }
    })
  }

  const {
    data: response,
    request: confirmInvitation,
    loading: confirmInvitationLoading,
  } = useApiCall(confirmInvitationCall)

  return {
    data: response,
    confirmInvitation,
    confirmInvitationLoading,
  }
}
