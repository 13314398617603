import moment from 'moment'
import Convert from 'utils/convert'
import Duration from 'utils/duration'

export const formatTimeInMinutes = (timeInMinutes) => {
  let minutes = parseInt(timeInMinutes % 60)
  let hours = parseInt(timeInMinutes / 60)

  if (timeInMinutes < 60) {
    return `${minutes}m`
  } else {
    return `${hours}h ${minutes}m`
  }
}

export const formatTimeInSeconds = (timeInSeconds) => {
  let seconds = parseInt(timeInSeconds % 60)
  let minutes = parseInt((timeInSeconds / 60) % 60)
  let hours = parseInt(timeInSeconds / 3600)

  if (timeInSeconds < 60) {
    return `${seconds}s`
  } else if (timeInSeconds < 3600) {
    return `${minutes}m ${seconds}s`
  } else {
    return `${hours}h ${minutes}m ${seconds}s`
  }
}

export const formatTimeCounter = timeInSeconds => {

  if (timeInSeconds < 0) {
    return '-00:00:00'
  }

  let seconds = parseInt(timeInSeconds % 60)
  let minutes = parseInt((timeInSeconds / 60) % 60)
  let hours = parseInt(timeInSeconds / 3600)

  let addedSecond = (seconds <= 9) ? '0' : ''
  let addedMinute = (minutes <= 9) ? '0' : ''
  let addedHour = (hours <= 9) ? '0' : ''

  if (timeInSeconds < 60) {
    return `00:00:${addedSecond}${seconds}`
  } else if (timeInSeconds < 3600) {
    return `00:${addedMinute}${minutes}:${addedSecond}${seconds}`
  } else {
    return `${addedHour}${hours}:${addedMinute}${minutes}:${addedSecond}${seconds}`
  }
}

//
// DurationString - e.g. 2h 45m
//
class Time {

  constructor(momentDate = undefined) {
    if (momentDate == undefined) {
      momentDate = moment.utc()
    }

    this.moment = momentDate
  }

  toNowDurationString = () => {
    const now = new Time()

    if (this.moment == now.moment) {
      return "now"
    } else if (now.moment > this.moment) {
      const minutesAgo = now.moment.diff(this.moment, 'minutes')
      const durationStringAgo = Duration.fromMinutes(minutesAgo).toDurationString()

      return `${durationStringAgo} ago`
    } else {
      const inMinutes = this.moment.diff(now.moment, 'minutes')
      const inDurationString = Duration.fromMinutes(inMinutes).toDurationString()

      return `at ${this.toDateTime()}`
    }
  }

  toNowSeconds = () => {
    const now = new Time()
    const secondsAgo = now.moment.diff(this.moment, 'seconds')

    return secondsAgo
  }

  addMinutes = (numberOfMinutes) => {
    this.moment.add(numberOfMinutes, 'minutes')

    return this
  }

  toDateTime = () => {
    return this.moment.format('HH:mm')
  }

  static fromApiDate(date, timeZone) {
    return new Time(Convert.apiDateToMoment(date, timeZone))
  }

  static fromMoment(momentDate) {
    return new Time(momentDate)
  }

}

export default Time

