import { useAbsencesNavigationLinks } from "./shared"
import PageTabsHeader from "components/layout/page-tabs-header"
import AbsenceTypesTable from "pages/absences/components/absence-types-table"
import FreeDaysTable from "pages/absences/components/free-days-table"
import { useAppRouter } from "hooks/app-router"
import { usePagePermissionsCheck } from "hooks/permissions.hooks"

const DATE_FORMAT = 'YYYY-MM-DD'

type TAbsenceSettingsTab = 'ABSENCE_TYPES' | "HOLIDAYS"

// props activeTab: ABSENCE_TYPES | HOLIDAYS
const AbsenceSettingsPage = (props: any) => {

  useAbsencesNavigationLinks(props.history)
  usePagePermissionsCheck('manageAbsenceTypes')

  const activeTab = props.activeTab as TAbsenceSettingsTab
  const appRouter = useAppRouter()

  return (
    <div>
      <PageTabsHeader
        title="Absence Settings"
        tabs={[
          {
            label: 'Absence Types',
            urlMatcher: "absences/settings",
            onClick: () => { return appRouter.goToAbsenceSettings() },
          },
          {
            label: 'Holidays',
            urlMatcher: "absences/holidays",
            onClick: () => { return appRouter.goToAbsenceHolidays() },
          },
        ]}
      />
    
      <div className="page-content">
        {activeTab == 'ABSENCE_TYPES' ? (
          <AbsenceTypesTable />
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <FreeDaysTable />
          </div>
        )}
     </div>
    </div>
  )
}

export default AbsenceSettingsPage