import { useEffect, DependencyList } from 'react';

type Key = string;
type Callback = (event: KeyboardEvent) => void;

const useOnKeyDown = (keys: Key[], callback: Callback, deps?: DependencyList) => {
  const keyDownHandler = (e: KeyboardEvent) => {
    if (keys.includes(e.key)) {
      callback(e);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [keys, callback, ...(deps || [])]);
};

export default useOnKeyDown;