import moment, { Moment } from "moment-timezone";
import { TApiPeriod, TApiPeriodRaw } from "../approvals-types";
import useSettings from "../../settings/queries/use-settings";
import { TApiApprovalsPeriodType } from "../../api-client/settings-api";

const API_DATE_FORMAT = 'YYYY-MM-DD'

export default function usePeriodModel() {
  const { dateFormat, approvalsPeriod: periodType } = useSettings()

  function parsePeriod(approvalRaw: TApiPeriodRaw): TApiPeriod {
    const approval: TApiPeriod = {
      ...approvalRaw,
      startDate: moment.utc(approvalRaw.startDate),
      endDate: moment.utc(approvalRaw.endDate),
      timespanString: '', // left for typescript and assignment below
    }
    approval.timespanString = getPeriodTimespanString({...approval, dateFormat, periodType})

    return approval
  }

  function serializePeriod(approval: TApiPeriod): TApiPeriodRaw {
    return {
      ...approval,
      startDate: approval.startDate.format(API_DATE_FORMAT),
      endDate: approval.endDate.format(API_DATE_FORMAT),
    }
  }

  return {
    parsePeriod,
    serializePeriod,
  }
}

// PRIVATE

const getPeriodTimespanString = (args: { 
  periodType: TApiApprovalsPeriodType,
  dateFormat: string,
  startDate: Moment,
  endDate: Moment,
}): string => {
  const {
    startDate,
    endDate,
    periodType,
    dateFormat,
  } = args

  if (periodType == 'monthly_TEST') {
    // if both start and end are from the same month and year
    if (startDate.format('YYYY-MM') == endDate.format('YYYY-MM')) {
      // and start and end of month are equal to month start and end dates
      if (
        startDate.startOf('month').format(API_DATE_FORMAT) == startDate.format(API_DATE_FORMAT) &&
        endDate.endOf('month').format(API_DATE_FORMAT) == endDate.format(API_DATE_FORMAT)
      ) {
        // return month human format string
        return startDate.format('MMMM YYYY')
      }
    }
  }

  // Default
  return `${startDate.format(dateFormat)} - ${endDate.format(dateFormat)}`
}