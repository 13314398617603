import { TApiChartData } from "@timenotes/shared/src/services/api-client/reports-api"
import { Card } from "antd"
import { map } from "lodash"
import { Bar } from "react-chartjs-2"

export interface IDetailedReportsChart {
  data: TApiChartData
}

type TTooltipItem = {
  datasetIndex: number
  index: number
  label: string
  value: string
  x: number
  xLabel: string
  y: number
  yLabel: number
}

const DetailedReportsChart = (props: IDetailedReportsChart) => {

  const labels = map(props.data.labels, (label) => {
    return label
  })

  const dataEntries = map(props.data.values, (value) => {
    return value
  })

  const legend = {
    display: false,
  }

  const options = {
    tooltips: {
      callbacks: {
        label: (tooltipItem: TTooltipItem) => {
          const infoLabel = props.data.info[tooltipItem.index]

          return `Duration: ${infoLabel}`
        },

        title: () => {
          return "Details"
        },
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          callback: (value: any) => {
            return `${value}h` //new Duration(value).toHoursString()
          },
          min: 0,
        }
      }],
    }
  }

  const data = {
    labels: labels,
    datasets: [{
      data: dataEntries,
      backgroundColor: (context: any) => {
        let index = context.dataIndex
        // var value = context.dataset.data[index]
        return props.data.color[index]
      }
    }],
  }

  return (
    <Card
      id="detailed-reports-chart"
      style={{ height: '380px' }}
    >
      <h3>Time Logged</h3>
      <Bar
        legend={legend}
        height={70}
        data={data}
        options={options}
        type="bar"
      />
    </Card>
  )
}

export default DetailedReportsChart