import { FC } from "react"
import styled from "styled-components"
import { ArchiveSvg, DeleteSvg, MoreSvg } from "assets/svg/icon"
import { Dropdown, Menu, Modal } from "antd"
import { CopyOutlined } from "@ant-design/icons"

const { confirm } = Modal

interface IProps {
  isArchived: boolean
  triggerArchive: () => void
  triggerRestore: () => void
  triggerDelete: () => void
  triggerCopy: () => void
  projectName: string
}

const MenuWrapper = styled.a`
  &:hover > svg > path,
  &:hover > svg > circle {
    fill: #07c5b7;
  }
`

//@ts-ignore
const ItemWrapper = styled(Menu.Item)`
  display: flex;
  align-items: center;
`

const Item = styled.a`
  display: flex !important;
  align-items: center;
`

const IconWrapper = styled.span`
  display: flex;
  margin-right: 5px;
`

export const MoreMenuAction: FC<IProps> = ({
  isArchived,
  triggerArchive,
  triggerRestore,
  triggerDelete,
  triggerCopy,
  projectName,
}) => {
  const showDeleteConfirm = () => {
    confirm({
      title: `Are you sure you want to delete the project - '${projectName}'?`,
      content: `It will couse also deleteing all time logs \n assigned to this project.`,
      okText: "Delete",
      okType: "primary",
      okButtonProps: {
        danger: true,
      },
      cancelText: "Cancel",
      onOk() {
        triggerDelete()
      },
      onCancel() {},
      centered: true,
      width: "490px",
      style: { whiteSpace: "pre-line" },
    })
  }

  const menu = (
    <Menu>
      <ItemWrapper onClick={triggerCopy}>
        <Item >
          <IconWrapper>
            <CopyOutlined />
          </IconWrapper>
            Copy
        </Item>
      </ItemWrapper>
      <ItemWrapper>
        <Item onClick={() => (isArchived ? triggerRestore() : triggerArchive())}>
          <IconWrapper>
            <ArchiveSvg />
          </IconWrapper>
          {isArchived ? "Restore" : "Archive"}
        </Item>
      </ItemWrapper>
      <ItemWrapper onClick={showDeleteConfirm}>
        <Item>
          <IconWrapper>
            <DeleteSvg />
          </IconWrapper>
          Delete
        </Item>
      </ItemWrapper>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement="bottomCenter" trigger={["click"]}>
      <MenuWrapper>
        <MoreSvg />
      </MenuWrapper>
    </Dropdown>
  )
}
