import React, { useEffect, useState } from 'react'
import { TApiClient } from 'services/api-client/types'
import _map from 'lodash/map'
import styled from 'styled-components'
import { Select } from 'antd'
import { find } from 'lodash'
import { useApiClient } from '@timenotes/shared/src/services/api-client/api-client.hooks'

export interface ClientSelectProps {
  style?: React.CSSProperties
  width?: string
  value?: string
  allowClear?: boolean
  autoFocus?: boolean
  initialClient?: TApiClient
  placeholder?: string
  onChange?(value: string, project: TApiClient): void 
}

const Wrapper = styled.div`
  width: 100%;

  .ant-popover {
    width: 250px;
  }
`

const ClientSelect = ({
  value,
  width,
  placeholder,
  allowClear,
  autoFocus,
  initialClient,
  onChange,
 }: ClientSelectProps ): JSX.Element => {
  const apiClient = useApiClient()
  const [clients, setClients] = useState<TApiClient[]>(initialClient ? [initialClient] : [])

  useEffect(() => {
    apiClient.getClients({ perPage: 200 }).then((response) => {
      if (response.ok) {
        setClients(response.clients)
      }
    })
  }, [])

  const groupsOptions = _map(clients, (client) => {
    return {
      value: client.id,
      name: client.name,
      label: (
        <span>
          {client.name}
        </span>
      )
    }
  })

  const handleOnChange = !onChange ? () => {} : (id: string) => {
    const client: TApiClient = find(clients, (client) => { return client.id == id}) as TApiClient
    onChange(id, client)
  }

  return (
    <Wrapper className="client-select-wrapper">
      <Select
        style={{ width: '100%'}}
        getPopupContainer={(node) => {return node.closest('.client-select-wrapper')} }
        allowClear={allowClear == false ? false : true }
        autoFocus={autoFocus}
        placeholder={ placeholder || 'Client' }
        showSearch={true}
        optionFilterProp='name'
        options={groupsOptions}
        onChange={handleOnChange}
        value={value}
      />
    </Wrapper>
  )
}

export default ClientSelect