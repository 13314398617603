import { UseQueryOptions, useQuery } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiPagination, TApiParams } from "../../api-client/types"
import { TApiGenericError, TApiGenericResponse } from "../../timenotes-query/timenotes-query.types"
import { TApiInvitation } from "../../api-client/invitations-api"
import { IGetUsersAccountsParams, TApiTeamMember } from "../../api-client/users-accounts-api"
import { useEffect, useState } from "react"

interface Params extends IGetUsersAccountsParams {}

interface Result {
  teamMembers: TApiTeamMember[]
  pagination: TApiPagination
}

const PAGE_SIZE = 30

export const useTeamMembersListQuery = (params: Params = {}, options?: UseQueryOptions<any, any>) => {
  const apiClient = useApiClient()

  const [page, setPage] = useState(params.page || 1)
  const [sorting, setSorting] = useState<Params['sorting']>(params.sorting)
  const [filters, setFilters] = useState<Params['filters']>(params.filters || {})

  const [requestParams, setRequestParams] = useState({
    filters: filters,
    sorting: sorting,
    page: page,
    perPage: params.perPage || PAGE_SIZE
  })

  useEffect(() => {
    setRequestParams({
      filters: filters,
      sorting: sorting,
      page: page,
      perPage: params.perPage || PAGE_SIZE
    })
  }, [page, sorting, filters])

  useEffect(() => {
    setPage(1)
  }, [filters])

  const query = useQuery<Result, TApiGenericError<any>>(
    ['team-members', (requestParams || {})] as const,
    async () => {
      const response = await apiClient.getTeamMembers(requestParams)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        teamMembers: response.usersAccounts || [],
        pagination: {
          ...response.meta.pagination,
          pageSize: requestParams.perPage || PAGE_SIZE,
          setPage: setPage,
        },
      }
    },
    {
      keepPreviousData: true,
      ...options
    }
  )

  return {
    query,
    setSorting,
    setFilters,
    filters,
    sorting,
  }
}