import { Button, Card, Modal, Skeleton, Table, Tag, message } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useState } from "react"
import AbsenceIcon from "components/base/absence-icon"
import GenericButtonsList from "components/generic-buttons-list/generic-buttons-list"
import { DeleteSvg, EditSVG, MoreSvg } from "assets/svg/icon"
import { TGenericButton } from "components/generic-buttons-list/generic-buttons-list"
import { useAbsenceTypes } from "hooks/absences"
import AbsenceTypeForm from "./absence-type-form"
import useApiClient from "hooks/useApiClient"
import { TApiAbsenceRequest } from "@timenotes/shared/src/services/api-client/holidays-absence-requests-api"
import { TApiAbsence, TApiAbsenceType, TApiPagination } from "@timenotes/shared/src/services/api-client/types"
import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"

type TAbsenceTableItem = TApiAbsenceRequest | TApiAbsence

export interface AbsenceTypesTableProps {
  pagination?: TApiPagination
  myAbsencesOnly?: boolean,
  onPaginationCurrentPageChange?(page: number): void
  refresh?(): void
  onEditAbsenceRequestVisibleChange?(visible: boolean): void
}

const AbsenceTypesTable = (props: AbsenceTypesTableProps) => {
  const apiClient = useApiClient()

  const { absenceTypes, loading, fetchAbsenceTypes } = useAbsenceTypes()

  const [newModalVisible, setNewModalVisible] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)

  const [absenceTypeToEdit, setAbsenceTypeToEdit] = useState<TApiAbsenceType | undefined>()

  const handleDelete = (id: string) => {
    apiClient.deleteHolidaysAbsenceType(id).then((response) => {
      if (response.ok) {
        message.success("Absence type deleted!")
        fetchAbsenceTypes()
      } else {
        message.error(getApiErrorMessage(response, 'base'))
      }
    })
  }

  const columns: ColumnsType<TApiAbsenceType> = [

    {
      title: 'Name',
      render: (value, record) => (
        <div style={{display: 'flex', alignItems: 'center' }}>
          <span style={{marginLeft: '5px', marginRight: '5px' }}>{record.name}</span>
      </div>
      )
    },

    {
      title: 'Icon',
      render: (value, record) => (
        <div style={{display: 'flex', alignItems: 'center' }}>
          <AbsenceIcon icon={record.icon} /> 
        </div>
      )
    },

    {
      title: 'Color',
      render: (value, record) => (
        <div style={{display: 'flex', alignItems: 'center' }}>
          <Tag color={record.color} style={{height: '23px' }} />
        </div>
      )
    },

    {
      title: 'Default limit per member',
      render: (value, record) => (
        <div style={{display: 'flex', alignItems: 'center' }}>
          {record.limitless ? (
            <span>No limit</span>
          ) : (
            <span>{record.limit} days per {record.period}</span>
          )}
        </div>
      )
    },

    {
      title: 'Needs approval?',
      render: (value, record) => (
        <div style={{display: 'flex', alignItems: 'center' }}>
          <span>{record.approval ? "Yes" : "No" }</span>
        </div>
      )
    },

    {
      title: 'Display on Timesheet?',
      render: (value, record) => (
        <div style={{display: 'flex', alignItems: 'center' }}>
          <span>{record.timesheet ? "Yes" : "No"}</span>
        </div>
      )
    },

    {
      title: 'Notify via email?',
      render: (value, record) => (
        <div style={{display: 'flex', alignItems: 'center' }}>
          <span>{record.notify ? "Yes" : "No"}</span>
        </div>
      )
    },

    {
      title: '',
      render: (value, record) => {
        let buttons: TGenericButton[] = [
          {
            title: '',
            icon: MoreSvg,
            type: 'text',
            options: [
              {
                title: 'Edit absence type',
                Icon: EditSVG,
                callback: () => {
                  setAbsenceTypeToEdit(record)
                  setEditModalVisible(true)
                }
              },
              {
                title: 'Delete absence type',
                Icon: DeleteSvg,
                callback: () => {
                  Modal.confirm({
                    title: "Are you sure you want to delete this absence type?",
                    okText: 'Delete',
                    onOk: () => {
                      handleDelete(record.id)
                    }
                  })
                }
              },
            ],
          }
        ]

        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GenericButtonsList 
              buttons={buttons}
            />
          </div>
        )
      }
    }
  ]

  if (loading) {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}><Skeleton.Button style={{ width: '140px' }}/></div>
        <br />
        <Card>

          <Skeleton />
          <Skeleton />
        </Card>
      </>
   )
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button 
          type="primary"
          onClick={() => {
            setNewModalVisible(true)
          }}
        >
          Add absence type
        </Button>
      </div>

      <br />

      <Table<TApiAbsenceType>
        columns={columns}
        dataSource={absenceTypes}
        pagination={!!props.pagination && {
          pageSize: props.pagination.pageSize as number,
          current: props.pagination.currentPage as number,
          total: props.pagination.totalCount as number,
          showSizeChanger: false,
          onChange: (page) => {
            props.onPaginationCurrentPageChange && props.onPaginationCurrentPageChange(page)
          }
        }}
      />

      <Modal
        title="Add new absence type"
        visible={newModalVisible}
        onCancel={() => { setNewModalVisible(false) }}
        footer={false}
        destroyOnClose={true}
      >
        <AbsenceTypeForm 
          onSuccess={(response) => {
            fetchAbsenceTypes()
            setNewModalVisible(false)
          }}
        />
      </Modal>

      <Modal
        title="Edit absence type"
        visible={editModalVisible}
        onCancel={() => { setEditModalVisible(false) }}
        footer={false}
        destroyOnClose={true}
      >
        <AbsenceTypeForm 
          initialValues={absenceTypeToEdit}
          onSuccess={(response) => {
            fetchAbsenceTypes()
            setEditModalVisible(false)
          }}
        />
      </Modal>
 
   </>
  )
}

export default AbsenceTypesTable