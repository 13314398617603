import React, { useEffect, useState } from 'react'
import { TApiUsersAccount } from 'services/api-client/types'
import _map from 'lodash/map'
import styled from 'styled-components'
import { Select } from 'antd'
import { filter, find } from 'lodash'
import useApiClient from 'hooks/useApiClient'

// TODO: Streamline and reuse views with UsersAccountSelect

export interface UsersAccountsMultiSelectProps {
  style?: React.CSSProperties
  width?: string
  disabled?: boolean

  value: string[]
  allowClear?: boolean
  showArrow?: boolean
  initialUsersAccounts?: TApiUsersAccount[]
  onChange(value: string[], usersAccounts: TApiUsersAccount[]): void 
  autoFocus?: boolean
  placeholder?: string
}

const Wrapper = styled.div`
  .ant-popover {
    width: 250px;
  }
`

type TUsersAccountOption = {
  label: string
  value: string
}

const DEFAULT_WIDTH = '170px'

const UsersAccountsMultiSelect = ({
  value,
  width,
  style,
  placeholder,
  allowClear,
  showArrow,
  initialUsersAccounts,
  autoFocus,
  disabled,
  onChange,
 }: UsersAccountsMultiSelectProps): JSX.Element => {
  const apiClient = useApiClient()

  const [usersAccounts, setUsersAccounts] = useState<TApiUsersAccount[]>(initialUsersAccounts ? initialUsersAccounts : [])

  useEffect(() => {
    apiClient.getUsersAccountsScoped().then((response) => {
      if (response.ok) {
        setUsersAccounts(response.usersAccounts)
      }
    })
  }, [])

  const groupsOptions = _map(usersAccounts, (usersAccount) => {
    return {
      value: usersAccount.id,
      label: `${usersAccount.firstName} ${usersAccount.lastName}`,
    }
  })

  const handleOnChange = !onChange ? () => {} : (usersAccountIds: string[]) => {
    const selectedUsersAccounts: TApiUsersAccount[] = filter(usersAccounts, (usersAccount) => { return usersAccountIds.includes(usersAccount.id) }) as TApiUsersAccount[]
    onChange(usersAccountIds, selectedUsersAccounts)
  }

  return (
    <Wrapper 
      className="users-account-select-wrapper" 
      style={{
          width: width || DEFAULT_WIDTH,
          ...style,
      }}
    >
      <Select<string[]>
        autoFocus={autoFocus}
        defaultOpen={autoFocus}
        placeholder={ placeholder || "Select member" }
        getPopupContainer={(node) => {return node.closest('.users-account-select-wrapper')} }
        showArrow={showArrow === undefined ? true : showArrow}
        style={{ width: '100%' }}
        options={[
          {
            label: 'Members',
            options: groupsOptions,
          }
        ]}

        mode="multiple"
        allowClear={allowClear !== undefined ? allowClear : true }

        value={value}
        onChange={handleOnChange}
        disabled={disabled}

        // Default options needed to make Select searchable
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => {
          const selectOption = option as TUsersAccountOption

          if (selectOption) {
            return selectOption.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } else {
            return false
          }
        }}
      />
    </Wrapper>
  )
}

export default UsersAccountsMultiSelect