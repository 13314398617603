import { TApiExportColumn } from "@timenotes/shared/src/services/api-client/reports-api"
import { IGetUsersAccountsParams } from "@timenotes/shared/src/services/api-client/users-accounts-api"
import { message } from "antd"
import { DownloadIcon } from "components/base/icons"
import useApiClient from "hooks/useApiClient"
import { ExportColumnsModal } from "pages/absences/hooks/use-absences-requests-export"
import { useEffect, useState } from "react"

type useMembersExportProps = IGetUsersAccountsParams & {
  disabled?: boolean
}

export default function useMembersExport(props: useMembersExportProps) {
  const apiClient = useApiClient()

  const disabled = !!props.disabled

  const [availableColumns, setAvailableColumns] = useState<TApiExportColumn[]>([])
  useEffect(() => {
    apiClient.getTeamMembersExportColumns().then((response) => {
      if (response.ok) {
        setAvailableColumns(response.exportColumns)
      }
    })
  }, [])

  const apiCall = (values: any) => {
    message.loading("Generating users list export, it might take a couple minutes.")

    apiClient.getTeamMembersExport({
      filters: props.filters,
      sorting: props.sorting,
      export: {
        type: values.exportType,
        columns: values.columns,
      }
    })
  }

  const [visible, setVisible] = useState(false)

  const ExportMembersModal = (
    <ExportColumnsModal
      title="Export team members"
      availableExportTypes={['csv', 'xls']}
      visible={visible}
      onSubmit={apiCall}
      exportColumns={availableColumns}
      onCancel={() => setVisible(false)}
      destroyOnClose={true}
    />
  )

  const membersExportButtonItem = {
    title: "Export",
    disabled: disabled,
    disabledTooltip: 'Only users list can be exported.',
    icon: DownloadIcon,
    callback: () => { setVisible(true) },
  }

  return {
    membersExportButtonItem,
    ExportMembersModal,
  }
}
