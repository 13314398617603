import React, { useCallback, useEffect, useState } from 'react'
import _map from 'lodash/map'
import { Spin, message } from 'antd'
import { debounce, groupBy, isEmpty } from 'lodash'
import { useApiClient } from '@timenotes/shared/src/services/api-client/api-client.hooks'
import { LoadingOutlined, LockOutlined, StarFilled, StarOutlined } from '@ant-design/icons'
import RecordsSelect from '../records-select'
import { useTasksQuery } from '@timenotes/shared/src/services/tasks/tasks.hooks'
import { useBookmarkTaskMutation, useUnbookmarkTaskMutation } from '@timenotes/shared/src/services/tasks/queries/use-bookmark-task-mutation';
import { useCurrentProjectsPermissionsQuery } from '@timenotes/shared/src/services/permissions/permissions.hooks';
import { useNewTaskModal } from './new-task-modal-context';
import { TApiProject, TApiTask } from '@timenotes/shared/src/services/api-client/types'
import { ICreateTaskParams } from '@timenotes/shared/src/services/api-client/tasks-api'
import { getApiErrorMessage } from '@timenotes/shared/src/services/api-client'

import { default as mapTaskOptionsShared } from '@timenotes/shared/src/services/tasks/utils/map-task-options'
export const mapTaskOptions = mapTaskOptionsShared

interface ITaskSelect extends Partial<React.ComponentProps<typeof RecordsSelect<TApiTask>>> {
  project?: TApiProject
}

const TaskSelect = ({ project, ...selectProps }: ITaskSelect) => {
  const currentProjectsPermissionsQuery = useCurrentProjectsPermissionsQuery({
    params: {
      projectId: project?.id
    }
  })

  const newTaskModal = useNewTaskModal()
  const [searchValue, setSearchValue] = useState("")

  const bookmarkTaskMutation = useBookmarkTaskMutation()
  const unbookmarkTaskMutation = useUnbookmarkTaskMutation()

  const apiClient = useApiClient()

  const canCreateTasks = currentProjectsPermissionsQuery.data?.permissions.createCurrentProjectTasks

  const [nameFilter, setNameFilter] = useState<string>(searchValue)

  const updateNameFilterBasedoOnSearch = useCallback(
    debounce((name: string) => {
      setNameFilter(name)
    }, 300)
    ,[]
  )

  useEffect(() => {
    updateNameFilterBasedoOnSearch(searchValue)
  }, [searchValue])

  const tasksQuery = useTasksQuery({
    projectId: project?.id,
    perPage: 100, // Increased a bit to fix no pagination issues during task select
    filters: {
      name: nameFilter
    }
  })

  const isDisabled = tasksQuery.isLoading || tasksQuery.isError

  const style = { 
    width: "100%",
    ...selectProps.style
  }

  const apiOptions = !tasksQuery.isSuccess ? [] : mapTaskOptions(tasksQuery.data.data)

  const emptyLabel = "Start typing to create a new task name"
  const createTaskLabel = isEmpty(searchValue) ? emptyLabel : `Create new task - "${searchValue}"`
  const createTaskOption = {
    label: canCreateTasks ? "Create a new task" : "Can't create a new task",
    options: [{
     disabled: !canCreateTasks || isEmpty(searchValue),
      value: {
        id: 'CREATE_NEW',
        name: createTaskLabel,
      } as TApiTask
    }]
  }

  const handleCreateTask = async (params: ICreateTaskParams) => {
    const response = await apiClient.createTask(project?.id || "", params)

    /* // cache update
    if (response.ok) {
      queryClient.setQueryData<TApiGenericResponse<TApiTask[]>>(['tasks', project?.id, { name: searchValue }], (state) => {
        state?.data.concat(response.task)

        return state
      })
    }
    */

    if (response.ok) {
      if (selectProps.onChange) selectProps.onChange(response.task, <></>)
    }

    return response
  }

  /* 
  // IT IS POSSIBLE TO CREATE PURE LABEL WITH EMPTY OPTIONS TO DISPLAY A INFORMATIVE TEXT ABOUT PAGINATION TOTAL SIZE
   TODO: Add nice pagination reminder
  const paginationInfoOption = tasksQuery.data?.meta?.pagination ? undefined :  
  ({
    label: "PAGINATION INFO",
    options: [{
      disabled: true,
      value: {
        id: 'PAGINATION_INFO',
        name: searchValue
      } as TApiTask
    }]
  })
  */

  const options = [...apiOptions, createTaskOption]

  const renderTaskItem = ({ task }: { task: TApiTask }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <span>{task.name}</span>
      <span 
        style={{
          width: '30px',
          textAlign: 'center',
        }}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          if (task.bookmarked) {
            unbookmarkTaskMutation.mutate({ projectId: task.project.id, taskId: task.id })
          } else {
            bookmarkTaskMutation.mutate({ projectId: task.project.id, taskId: task.id })
          }
        }}
      >
        { ((unbookmarkTaskMutation.isLoading && unbookmarkTaskMutation.variables?.taskId == task.id) || (bookmarkTaskMutation.isLoading && bookmarkTaskMutation.variables?.taskId == task.id)) ? (
          <LoadingOutlined />
        ): (
          <>
          {task.bookmarked ? <StarFilled /> : <StarOutlined />}
          </>
        )}
      </span>
    </div>
  )

  const renderCreateNewTaskLabel = () => (
    <div>
      {canCreateTasks ? (
        <span>{createTaskLabel}</span>
      ) : (
        <i><LockOutlined /> You have no permissions to create new tasks</i>
      )}
    </div>
  )

  const Loader = (
    <div style={{
      width: '100%',
      height: '100%',
      padding: '50px',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center'
    }}>
      <Spin />
    </div>
  )

  return (
    <>
      <RecordsSelect<TApiTask>
        loading={tasksQuery.isFetching}
        //dropdownRender={renderDropdown}
        dropdownMatchSelectWidth={false}
        placeholder="Start typing or click to select the task"
        autoFocus={true}
        disabled={isDisabled}
        style={style}
        showSearch={true}
        onSearch={(searchValue) => { setSearchValue(searchValue)}}
        searchValue={searchValue}

        // Hacking to display loader without dropdown render
        // options={tasksQuery.isFetching ? [] : options}
        //notFoundContent={tasksQuery.isFetching ? Loader : undefined}

        options={options}

        valueProp="id"
        searchProp="name"
        labelProp={(task) => { 
          if (task.id == 'CREATE_NEW') {
            return renderCreateNewTaskLabel()
          }
         return renderTaskItem({ task })
        }}

        {...selectProps}

        // IMPORTANT TO BE BELOW ...selectProps to not be overwritten
        onCreate={async () => {
          if (project?.origin !== 'timenotes') {
            newTaskModal.setInitialValues({
              name: searchValue
            })

            newTaskModal.setProject(project as TApiProject)
            newTaskModal.setVisible(true)
            newTaskModal.setOnSuccess((task) => {
              if (selectProps.onChange) {
                selectProps.onChange(task, undefined as any)
              }
            })

            if (selectProps.onCreate) selectProps.onCreate(searchValue)
          } else {
            const response = await handleCreateTask({
              name: searchValue,
            })

            if (!response.ok) {
              message.error(getApiErrorMessage(response, 'name'))
            }
          }
        }}


      />
   </>
  )
}

export default TaskSelect