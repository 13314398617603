import { Button, Modal, ModalProps, Skeleton, Tabs } from "antd"
import styled from "styled-components"
import { Card } from "components/base"
import { TApiGrouping, TApiRounding, TApiTimeLog } from "services/api-client/types"
import React, { useEffect, useRef, useState } from "react"
import _omit from 'lodash/omit'
import _reduce from 'lodash/reduce'
import { TApiTimesheetCell, TApiTimesheetRow } from "services/api-client/timesheets-api"
import { IGetReportsDetailedParams, TApiReportsDetailedRow, TApiReportsDetailedSorting, TApiReportsFilters } from "services/api-client/reports-api"
import moment from "moment-timezone"
import TimesheetCellTable from "./timesheet-cell-table"
import { toDurationString } from "components/forms/duration-input"
import { TApiPagination } from "services/api-client/types"
import { GenericTableParams } from "components/generic-table/generic-table"
import { ICreateTimeLogParams } from "services/api-client/time-logs-api"
import TimesheetAbsencesTable from "./timesheet-absences-table"
import { useDefaultDateFormat } from "hooks/settings"
import TimeLogsTable from "components/timenotes/time-logs/time-logs-table"
import TimeLogForm from "components/timenotes/timer/time-log-form"
import { useProjectQuery } from "@timenotes/shared/src/services/projects/projects.hooks"
import useApiClient from "hooks/useApiClient"

const PAGINATION_PAGE_SIZE = 8 

type TTimesheetSectionGrouping = TApiGrouping['primary'] | 'absences'

const StyledModal = styled(Modal)`
  & .ant-modal-body {
    padding: 0px;
  }

  & .modal-content-section {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 24px;
  }

  & .ant-tabs-nav {
    margin-bottom: 0px !important;
  }

  & .ant-tabs-tabpane.ant-tabs-tabpane-active {
    background-color: #f9f9f9;
  }

  & .timenotes-tabs .ant-tabs-tab {
    background-color: #ECECEC;
  }

  & .timenotes-tabs .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #f9f9f9;
    border-bottom-color: #f9f9f9 !important;
  }
`
const getFiltersUpdateFromResource = (groupingType: TApiGrouping['primary'], filterKey: string ): Partial<TApiReportsFilters> => {
    const filtersUpdate: Partial<TApiReportsFilters> = {}

    const timeRowKey = filterKey

    // If grouping by user - add selected user id's as a more detailed user filter for the usersAccounts
    if (groupingType == 'user') {
      filtersUpdate.usersAccountHashIds = [timeRowKey]
    }

    // ...
    if (groupingType == 'project') {
      filtersUpdate.projectHashIds = [timeRowKey]
    }

    // ...
    if (groupingType == 'client') {
      filtersUpdate.clientHashIds = [timeRowKey]
    }

    // ...
    if (groupingType == 'task') {
      filtersUpdate.taskHashIds = [timeRowKey]
    }

  return filtersUpdate
}

const getRequestParams = (cell: TApiTimesheetCell, row: TApiTimesheetRow, filters: TApiReportsFilters, grouping: TApiGrouping, rounding: TApiRounding, pagination: TApiPagination | null, parentRow?: TApiTimesheetRow, ): IGetReportsDetailedParams => {
    const sorting: TApiReportsDetailedSorting = {}

    const rowGroupingType: string = ""

    const GroupCallToGroupingTypeMap = {
      UsersAccount: 'user',
      Project: 'project',
      Client: 'client',
      Task: 'task',
    }

    const filtersUpdate: Partial<TApiReportsFilters> = {
      // @ts-ignore
      ...getFiltersUpdateFromResource(GroupCallToGroupingTypeMap[row.groupClass], (row.groupHashId || row.groupId)),
      // @ts-ignore
      ...(parentRow ? getFiltersUpdateFromResource(GroupCallToGroupingTypeMap[parentRow.groupClass], (parentRow.groupHashId || parentRow.groupId )) : {}),
    }

    return {
      filters: {
        ...filters,
        ...filtersUpdate,
        timespan: 'day',
        from: cell.date,
      },
      sorting: grouping.primary == 'no_group' ? { startAt: 'desc' } : undefined, // default sorting
      rounding: rounding,
      grouping: grouping,
      page: pagination?.currentPage || 1,
      perPage: PAGINATION_PAGE_SIZE,
    }
  }


const TimesheetCellModal = (props: ModalProps & { 
  cell?: TApiTimesheetCell
  row?: TApiTimesheetRow
  parentRow?: TApiTimesheetRow
  filters: TApiReportsFilters
  grouping: TApiGrouping
  rounding: TApiRounding
} ) => {
  const apiClient = useApiClient()
  const { cell, row, filters, grouping, rounding, parentRow } = props

  const absencesTabVisible = row?.groupClass == 'UsersAccount'

  const [logTimeVisible, setLogTimeVisible] = useState(false)
  const [totalWorktime, setTotalWorktime] = useState(cell?.worktime || 0)

  const [tableParams, setTableParams] = useState<GenericTableParams>({
    selectedRowKeys: [],
    selectedAll: false,
    sorting: null,
    pagination: {
      currentPage: null,
      totalCount: null,
      totalPages: null,
      pageSize: PAGINATION_PAGE_SIZE,
    }
  })

  const [timeRows, setTimeRows] = useState<TApiReportsDetailedRow[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const { defaultDateFormat } = useDefaultDateFormat()

  const [tableGrouping, setTableGrouping] = useState<TTimesheetSectionGrouping>('no_group')
  // Needed to ensure no conflict mismatch between time rows of wrong type and table grouping
  const tableGroupingRef = useRef(tableGrouping)
  useEffect(() => { tableGroupingRef.current = tableGrouping }, [tableGrouping])

  const handleGoToReports = () => {
    const requestParams: IGetReportsDetailedParams = getRequestParams(cell as TApiTimesheetCell, row as TApiTimesheetRow, filters, { primary: tableGrouping as TApiGrouping['primary'] }, rounding, tableParams.pagination, parentRow)
  }

  const fetchTable = (params?: { silent?: boolean } ) => {
    // TOTAL will not refresh but its still ok
    if ((!cell || !row) || tableGrouping == 'absences') {
      return
    }

    if (!params?.silent) {
      setLoading(true)
    }

    const requestParams: IGetReportsDetailedParams = getRequestParams(cell as TApiTimesheetCell, row as TApiTimesheetRow, filters, { primary: tableGrouping as TApiGrouping['primary'] }, rounding, tableParams.pagination, parentRow)

    // Fetch Time Rows
    apiClient.getReportsDetailed(requestParams).then((response) => {
      // Stop time rows assignement in case current table grouping has changed in the meantime
      // to prevent assignment of incorrect type of time rows into wrong grouping type and crash application.
      // Allow it only if grouping type from request matches the one which is displayed in the table.
      if (tableGroupingRef.current != tableGrouping) return

      setTimeRows(response.rows)
      setTotalWorktime(response.totals.worktime)
      setTableParams({
        ...tableParams,
        pagination: {
          ...response.meta.pagination,
          pageSize: PAGINATION_PAGE_SIZE,
        }
      })

      setLoading(false)
    })
  }

  // Reset bulk selection on any preloading
  useEffect(() => {
    setTableParams((prev) => {
      return {
        ...prev,
        selectedRowKeys: [],
        selectedAll: false,
      }
    })
  }, [loading])

  useEffect(() => {
    if (tableGrouping != 'absences') {
      fetchTable()
    }
  }, [filters, grouping, tableGrouping, cell, row, tableParams.pagination?.currentPage])

  useEffect(() => {
    if (absencesTabVisible == false && tableGrouping == 'absences') {
      setTableGrouping('no_group')
    }
  }, [absencesTabVisible, tableGrouping])

  // As component is always moounted due to modal show/hide animation, extra cleaning is needed to not
  // display "cached" data.
  useEffect(() => {
    // Modal is being hidden
    if (props.visible == false) {
      setTotalWorktime(0)
    }
  }, [props.visible])

  const modalProps = _omit(props, ['teamMember'])

  const formRef = useRef(null)

  // for loading default
  let timesheetCellTable: React.ReactNode

  // Detecting if somehow timeRows have old data records despite grouping is already changed to the new value
  if (loading && (tableGrouping != 'no_group' || (timeRows.length > 0 && !timeRows[0].project))) {
    timesheetCellTable = <Card><Skeleton /></Card>
  } else { 
    if (tableGrouping != 'no_group') {
      timesheetCellTable = (
        <TimesheetCellTable
          timeRows={timeRows}
          groupingPrimary={tableGrouping as TApiGrouping['primary']}

          onChange={(params: GenericTableParams) => {
            setTableParams(params)
          }}

          tableParams={tableParams}
        />
      )
    } else {
      timesheetCellTable = (
        <TimeLogsTable
          loading={loading}
          skipTracker
          noQueryMode
          dataSource={timeRows as TApiTimeLog[]}
          refetch={() => { fetchTable({ silent: true })}}

          tableParams={tableParams}
          onChange={(params: GenericTableParams) => {
            setTableParams(params)
          }}
          customHeader={() => <></>}
        />
      )
    }
  }

  const timeLogsRowsData: Partial<ICreateTimeLogParams> = {}

  if (row?.groupClass == 'UsersAccount' || parentRow?.groupClass == 'UsersAccount') {
    const sourceRow = row?.groupClass == 'UsersAccount' ? row : parentRow
    timeLogsRowsData['usersAccountId'] = sourceRow?.groupId
    timeLogsRowsData['usersAccount'] = {
      id: sourceRow?.groupId as string,
      firstName: sourceRow?.title.split(" ")[1] as string,
      lastName: sourceRow?.title.split(" ")[0] as string,

      // Just typescript compatibility
      hashId: 'not needed',
      user: {} as any,

    }
  }

  if (row?.groupClass == 'Project' || parentRow?.groupClass == 'Project') {
    const sourceRow = row?.groupClass == 'Project' ? row : parentRow
    timeLogsRowsData['projectId'] = sourceRow?.groupId

    timeLogsRowsData['project'] = {
      id: sourceRow?.groupId as string,
      name: sourceRow?.title as string,
    } as any
  }

  if (row?.groupClass == 'Task' || parentRow?.groupClass == 'Task') {
    const sourceRow = row?.groupClass == 'Task' ? row : parentRow
    timeLogsRowsData['taskId'] = sourceRow?.groupId

    timeLogsRowsData['task'] = {
      name: sourceRow?.title as string,
      id: sourceRow?.groupId as string,
    } as any
  }

  const totalDurationString = toDurationString(totalWorktime)


  function TimesheetTimeLogForm() {

    const projectId = timeLogsRowsData.projectId

    const projectQuery = useProjectQuery({
      projectId: projectId
    })

    const initialValues = {
      project: projectQuery.data?.project,
      task: timeLogsRowsData.task,
      usersAccount: timeLogsRowsData.usersAccount,
      date: moment(cell?.date),
    }

    if (projectId && projectQuery.isLoading) {
      return null
    }

    return (
      <TimeLogForm
        mode="manual"
        date={moment(cell?.date).utc().set({ hour: 8, minute: 0, second: 0})}
        initialValues={initialValues}
        enableUsersAccountSelect={true}
        onSuccess={() => {
          setLogTimeVisible(false)
          fetchTable()
        }}
      />
    )
  }

  return (
    <StyledModal
      {...modalProps}
      destroyOnClose={true}
      width={1100}
      title={null}
      footer={(<></>)}
      onOk={() => {}}
      onCancel={(e) => {
        setLogTimeVisible(false)
        setTableParams({
          ...tableParams,
          // @ts-ignore
          pagination: {
            ...tableParams.pagination,
            currentPage: 1,
          }
        })
        modalProps.onCancel && modalProps.onCancel(e)
      }}
    >
      <div className="modal-content-section">
        <h3>
          { parentRow ? `${parentRow.title} /` : "" } { row?.title } <span style={{ fontWeight: 50 }}>Total:</span> <span style={{ fontWeight: 400 }}>{totalDurationString}</span>
        </h3>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div>
            {moment(cell?.date).format(defaultDateFormat)}
          </div>
          <div>
            { logTimeVisible ? (
              <Button onClick={() => { setLogTimeVisible(false) }}>
                Close time log form
              </Button>
            ) : (
              <Button 
                type="primary"
                onClick={() => { setLogTimeVisible(true)}}
              >
                New time log
              </Button>
            )}
          </div>
        </div>

        <div style={{ marginTop: '5px' }}>
          { logTimeVisible && (
            <TimesheetTimeLogForm />
         )}
        </div>
      </div>

      <div 
        style={{
          width: '100%',
          paddingLeft: '0px', paddingRight: '0px',
          paddingBottom: '-1px',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',
        }}
        className="timenotes-tabs"
      >

        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>

          <Tabs
            tabBarExtraContent={({ 
              left: (<div style={{ width: '30px' }} />),
           })}
            type="card"
            tabBarGutter={5}
            style={{ width: '100%' }}
            destroyInactiveTabPane={true}
            activeKey={tableGrouping}
            onChange={(activeKey) => {
              // Enforce loading to avoid rendering table with grouping not reloaded
              setLoading(true)
              setTableParams({ 
                ...tableParams,
                // @ts-ignore
                pagination: {
                  ...tableParams.pagination,
                  currentPage: 1,
                },
              })
              setTableGrouping(activeKey as TTimesheetSectionGrouping)
            }}
          >
            <Tabs.TabPane tab="Time Logs" key="no_group">
              <div className="modal-content-section">
                {timesheetCellTable}
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Members" key="user">
              <div className="modal-content-section">
                {timesheetCellTable}
              </div>
            </Tabs.TabPane>


            <Tabs.TabPane tab="Tasks" key="task">
              <div className="modal-content-section">
                {timesheetCellTable}
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Projects" key="project">
              <div className="modal-content-section">
                {timesheetCellTable}
              </div>
            </Tabs.TabPane>

            {absencesTabVisible && (
              <Tabs.TabPane tab="Absences" key="absences">
                <div className="modal-content-section">
                  <TimesheetAbsencesTable
                    absences={cell?.absences || []}
                  />
                </div>
              </Tabs.TabPane>
            )}

          </Tabs>
       </div>
      </div>

    </StyledModal>
  )
}

export default TimesheetCellModal
