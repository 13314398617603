import { capitalize } from "lodash"
import { TApiPerrmissionKey } from "../api-client/permissions-api"
import { TApiHiddenPage } from "../api-client/settings-api"

export const API_HIDDEN_PAGES: TApiHiddenPage[] = ['reports', 'team', 'clients', 'projects', 'approvals']

export const getHiddenPagePermissionName = (page: TApiHiddenPage): TApiPerrmissionKey => {
  return `display${capitalize(page)}Page` as TApiPerrmissionKey
}

// WEB ONLY!
export const storeSetHiddenPages = (pages) => {
  window.localStorage.setItem('hiddenPagesArrayCache', pages.join(','))
}

export const storeGetHiddenPages = () => {
  const cachedPages = window.localStorage.getItem('hiddenPagesArrayCache')

  if (cachedPages !== null) {
    return cachedPages.split(',')
  } else {
    return API_HIDDEN_PAGES // Return all pages as hidden by default
  }
}
