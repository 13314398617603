import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Modal, message } from "antd";
import { TApiAbsence } from "services/api-client/types"
import { filter, map } from "lodash";
import { DateSelectArg, EventClickArg } from "@fullcalendar/core";
import { useEffect, useRef, useState } from "react"
import AbsencesListModal from "./absences-list-modal";
import moment, { Moment, months } from "moment-timezone";
import AbsenceRequestModal from "./absence-request-modal";
import { TApiAbsenceRequest } from "services/api-client/holidays-absence-requests-api";
import { getUsersAccountFullName } from "services/users-accounts";
import useNoInitialEffect from "hooks/useNoInitialEffect";
import { TApiFreeDay } from "services/api-client/holidays-free-days-api";
import { useDefaultWeekStartDayNumber } from "hooks/settings";

interface AbsencesCalendarProps  {
  monthMoment: Moment
  absences: TApiAbsenceRequest[]
  freeDays: TApiFreeDay[]
  fetchAbsenceRequests(): void
}

const AbsencesCalendar = (props: AbsencesCalendarProps) => {

  // TODO: Move to page
  const [newAbsenceRequestModalVisible, setNewAbsenceRequestModalVisible] = useState(false)
  const [dateModalVisible, setDateModalVisible] = useState(false)
  const [selectedAbsences, setSelectedAbsences] = useState<TApiAbsenceRequest[]>(props.absences)
  const [selectedStartDate, setSelectedStartDate] = useState<Moment | undefined>()
  const [selectedEndDate, setSelectedEndDate] = useState<Moment | undefined>()
  const calendarComponentRef = useRef<FullCalendar>()
  const { weekStartDayNumber } = useDefaultWeekStartDayNumber()

  const calendarEvents = mapCalendarEvents(props.absences, props.freeDays)

  const displayAbsencesListModalForRange = (start: Moment, end: Moment) => {
    setSelectedStartDate(start)
    setSelectedEndDate(end)
    setDateModalVisible(true)
  }

  const handleDateSelect = (date: DateSelectArg) => {
    // FullCalendar always return 00:00:00 of next day even if click on the same day
    displayAbsencesListModalForRange(moment(date.start), moment(date.end).subtract(1, 'day'))
  }

  const handleEventClick = (eventClick: EventClickArg) => {
    displayAbsencesListModalForRange(moment(eventClick.event.start), moment(eventClick.event.end || eventClick.event.start)) // fallback to start for single day events
  }

  useEffect(() => {
    if (calendarComponentRef.current) {
      const calendarApi = calendarComponentRef.current?.getApi()
      calendarApi?.gotoDate(props.monthMoment.toDate())
    }
  }, [props.monthMoment.format()])

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      setSelectedAbsences(filterAbsencesFromDateRange(props.absences, selectedStartDate, selectedEndDate))
    }
  }, [selectedStartDate, selectedEndDate, props.absences])

  return (
    <>
      <FullCalendar
        // @ts-ignore
        ref={calendarComponentRef}
        initialDate={props.monthMoment.toDate()}
        selectable={true}
        headerToolbar={false}
        select={handleDateSelect}
        eventClick={handleEventClick}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        weekends={true}
        events={calendarEvents}
        nextDayThreshold={"00:00"}
        displayEventTime={false}
        firstDay={weekStartDayNumber}
      />

      <AbsencesListModal
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        absences={selectedAbsences}
        visible={dateModalVisible}
        onCancel={() => { setDateModalVisible(false) }}
        fetchAbsenceRequests={props.fetchAbsenceRequests}
      />

      <AbsenceRequestModal 
        visible={newAbsenceRequestModalVisible}
      />
    </>
  )
}

const filterAbsencesFromDateRange = (absences: TApiAbsenceRequest[], start: Moment, endDate: Moment): TApiAbsenceRequest[] => {
  const end = start.isSame(endDate, 'day') ? moment(endDate) : moment(endDate).subtract(1, 'day')

  return filter(absences, (absence) => {
    const absenceStartDate = moment(absence.fromDate)
    const absenceEndDate = moment(absence.toDate)

    return (start.isSameOrBefore(absenceEndDate, 'day') && end.isSameOrAfter(absenceStartDate, 'day'))
  })
}

const mapCalendarEvents = (absences: TApiAbsenceRequest[], freeDays: TApiFreeDay[]) => {
  const absenceEvents = map(absences, (absence) => {
    return {
      interactive: true,
      title: `${getUsersAccountFullName(absence.usersAccount)} - ${absence.absenceType.name} - ${absence.formattedStatus}`,
      start: `${absence.fromDate} 10:00`, // Extra hours added so calendar display full days against midnight correctly 
      end: `${absence.toDate} 10:00`, // ... same
      color: absence.absenceType.color,
      absence: absence,
    }
  })

  const freeDaysEvents = map(freeDays, (freeDay) => {
    return {
      interactive: false,
      title: `Holiday: ${freeDay.name}`,
      start: `${freeDay.date} 10:00`, // Extra hours added so calendar display full days against midnight correctly 
      end: `${freeDay.date} 10:00`, // ... same
      freeDay: freeDay,
    }
  })

  return [...absenceEvents, ...freeDaysEvents]
}

export default AbsencesCalendar