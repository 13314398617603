import { Avatar, Table, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import _map from 'lodash/map'
import ActionsPanel, { IActionItem, IActionsPanelProps } from "components/shared/actions-panel"
import { TApiInvitation } from "services/api-client/invitations-api"
import { MailOutlined } from '@ant-design/icons'
import { Button } from "components/base"

interface IGetInvitationsActions {
  (invitation: TApiInvitation): IActionItem[]
}

const InvitationsTable = ({ invitations, getInvitationsActions, resendInvitation } : {
   invitations: TApiInvitation[],
   getInvitationsActions: IGetInvitationsActions,
   resendInvitation(invitation: TApiInvitation): any,
  }) => {
  const COLUMNS: ColumnsType<TApiInvitation> = [
    {
      title: 'NAME',
      key: 'email',
      sorter: (a,b) => { return a.email.localeCompare(b.email) },
      render: (t, record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <Avatar style={{ marginRight: '10px', backgroundColor: '#3E79F7', opacity: '0.35' }}>
                <MailOutlined />
              </Avatar>
              {record.email}
            </div>
            <div>
              <Tag>Pending invitation</Tag> 
              <Button type="link" onClick={() => { resendInvitation(record) }}>
                Resend invitation
              </Button>
            </div>
          </div>
        )
      }
    },
    {
      title: 'ROLE',
      render: (t, record) => {
        return (
          <span>
            Member
          </span>
        )
      }
    },
    {
      title: '', // Actions
      render: (t: string, record) => {

        const actions = getInvitationsActions(record)

        return (
          <ActionsPanel actions={actions} />
        )
      }
    },
  ]

  return (
    <>
      <Table<TApiInvitation>
        dataSource={invitations || []}
        columns={COLUMNS}
        pagination={false}
      />
    </>
  )
}

export default InvitationsTable