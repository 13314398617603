import _map from 'lodash/map'
import Duration from 'utils/duration'
import { Card,  Table } from 'antd'
import _sortBy from 'lodash/sortBy'
import { toAmountWithCurrency } from "utils/string-utils"
import { useCurrentProjectsPermissionsQuery } from "@timenotes/shared/src/services/permissions/permissions.hooks"
import { TApiProject } from '@timenotes/shared/src/services/api-client/types'
import { TProjectInsightsUsersData } from '@timenotes/shared/src/services/api-client/projects-api'
import { useDefaultCurrencyIso } from 'hooks/settings'

const TeamMembersOverview = ({ projectInsights, project }: { projectInsights: any, project: TApiProject }): JSX.Element => {
  const query = useCurrentProjectsPermissionsQuery({
    params: {
      projectId: project.id
    }
  })
  const { defaultCurrencyIso } = useDefaultCurrencyIso()

  const manageCurrentProjectBillable = !!query.data?.permissions.manageCurrentProjectBillable

  const teamMembers = _sortBy(projectInsights.usersData, (data) => -data.duration)

  const BASE_COLUMNS = [
    {
      title: 'Name',
      render: (record: TProjectInsightsUsersData) => {
        const usersAccount = record.usersAccount

        const fullName = `${usersAccount.firstName} ${usersAccount.lastName}`

        return (
          <span>{fullName}</span>
        )
      }
    },
    {
      title: 'Time logged',
      render: (record: TProjectInsightsUsersData) => {
        const duration = new Duration(record.duration as any).toDurationString()

        const budgetTime = record.budgetTime
        let budgetConfigured = false

        let budgetInHours = 0
        let budgetPercentage = 0

        if (budgetTime !== null && budgetTime !== undefined) {
          budgetConfigured = true

          budgetInHours = parseInt(record.budgetTime as string)

          if (budgetInHours == 0) {
            budgetPercentage = 100
          } else {
            budgetPercentage = Math.floor((record.duration / (budgetInHours*60))*100) // duration is in minutes!
          }
        }

        const alertStyle = budgetPercentage >= 100 ? { color: 'red' } : {}

        if (!budgetConfigured) {
          return (
            <span><b>{duration}</b></span>
          )
        } else {
          return (
            <span style={alertStyle}>
              <b>{duration}</b> out of {budgetInHours}h budget ({budgetPercentage}%)
            </span>
          )
        }
     }
    },
  ]

  const BILLABLE_COLUMNS = [
    {
      title: 'Billable',
      render: (record: TProjectInsightsUsersData) => (
        <span>{toAmountWithCurrency(record.billableAmount, defaultCurrencyIso)}</span>
      )
    },
    {
      title: 'Cost',
      render: (record: TProjectInsightsUsersData) => (
        <span>{toAmountWithCurrency(record.costAmount, defaultCurrencyIso)}</span>
      )
    },
  ]

  const COLUMNS = (manageCurrentProjectBillable && project.billableEnabled) ? [...BASE_COLUMNS, ...BILLABLE_COLUMNS] : BASE_COLUMNS

  return (
    <Card>
      <h3>Team Members Overview</h3>

        <Table
          columns={COLUMNS}
          dataSource={teamMembers}
          pagination={false}
        />
    </Card>
  )
}


export default TeamMembersOverview