const Trip = (props: React.ComponentProps<"svg">) => (
    <svg width="20px" height="19px" viewBox="0 0 20 19" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props} >
        <g id="Holidays-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="9.2.3-Create-absence-type-" transform="translate(-235.000000, -358.000000)">
                <g id="Group" transform="translate(216.000000, 284.000000)">
                    <g id="icon-/-application-/-dark-/-icon-1-copy-4" transform="translate(17.000000, 72.000000)">
                        <g id="baseline-card_travel-24px">
                            <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                            <path d="M20,6 L17,6 L17,4 C17,2.89 16.11,2 15,2 L9,2 C7.89,2 7,2.89 7,4 L7,6 L4,6 C2.89,6 2,6.89 2,8 L2,19 C2,20.11 2.89,21 4,21 L20,21 C21.11,21 22,20.11 22,19 L22,8 C22,6.89 21.11,6 20,6 Z M9,4 L15,4 L15,6 L9,6 L9,4 Z M20,19 L4,19 L4,17 L20,17 L20,19 Z M20,14 L4,14 L4,8 L7,8 L7,10 L9,10 L9,8 L15,8 L15,10 L17,10 L17,8 L20,8 L20,14 Z" id="Shape" fill="#474D55" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
export default Trip