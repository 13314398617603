import { FC } from 'react'
import styled from 'styled-components'

import { 
  InfoCircleOutlined,
  SyncOutlined,
  SwapOutlined,
  DeleteOutlined,
  DisconnectOutlined,
  PushpinTwoTone,
  DownloadOutlined,
  DollarOutlined,
} from '@ant-design/icons'
import { RemoveSvg } from 'assets/svg/icon'
import React from 'react'
import { Subtract } from 'views/PageStoreContext'

const COLORS = {
  gray: '#B9B9C4',
  danger: '#EE7476'
}

//
// Without props className svg or any other custom component can not be styled!
//
interface IwithClassName{
  className?: string
}

export function withClassName<T extends IwithClassName>(Component: React.ComponentType<T>) {
  return class extends React.Component<T> {

    render() {
      return (
        // @ts-ignore
        <span className={this.props.className as string}>
          <Component {...this.props as T} />
        </span>
      )
    }
  };
}

export const InfoIcon = styled(InfoCircleOutlined)`
  cursor: pointer;
  font-size: 15px;
`

export const SyncIcon = styled(SyncOutlined)`
  cursor: pointer;
  font-size: 15px;
`
export const ReconnectIcon = styled(SwapOutlined)`
  cursor: pointer;
  font-size: 15px;
`

export const DeleteIcon = styled(DeleteOutlined)`
  cursor: pointer;
  font-size: 15px;
`

export const RemoveIcon = styled(withClassName(RemoveSvg))`
  cursor: pointer;

  & > svg {
    fill: ${COLORS.gray};
  }

  &:hover > svg {
    fill: ${COLORS.danger};
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.disabled > svg {
    opacity: 0.50;
    fill: ${COLORS.gray};
  }

  &.disabled:hover > svg {
    opacity: 0.50;
    fill: ${COLORS.gray};
  }

`

export const DisconnectIcon = styled(DisconnectOutlined)`
  cursor: pointer;
  font-size: 15px;
`

export const DownloadIcon = DownloadOutlined
export const DollarIcon = DollarOutlined

export const PinningIcon = styled(PushpinTwoTone)`
  cursor: pointer;
  font-size: 25px;

  & > svg > path:first-child {
    fill: transparent;
  }
  & > svg > path:last-child {
    fill: #B9B9C4;
  }
`