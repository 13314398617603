import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Moment } from 'moment'

import { DATE_FORMAT, IntegrationAppInterface } from 'consts'

import { Menu, StatusBadge, Row, Col, Card, Button, Popover, Tooltip } from 'components/base'
import { InfoIcon, SyncIcon, DisconnectIcon, DeleteIcon, ReconnectIcon } from 'components/base/icons'
import LogoImg from 'components/base/logo'
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown'

export interface WorkspaceListItemViewInterface {
  workspaceId: string
  workspaceIntegrationApp: IntegrationAppInterface
  workspaceName: string
  isConnected: boolean
  connectionOwnerName: string
  lastSyncAt: Moment

  onClick(integrationAccountId: string): void
  onSynchronise (): void
  onReconnect(): void
  onDisconnect(): void
  onDelete(): void
}

const WorkspaceTitle = styled(Col)`
  cursor: pointer;
  font-size: 20px;
  color: "#222";
`

const WorkspaceInfo = styled(Col)`
  font-size: 14x;
  color: gray;
`

const WorkspaceActions = styled(Col)`
  color: gray;
`

const AppNameSpan = styled.span`
  margin-left: 5px;
  text-transform: capitalize;
`

const CustomRow = styled(Row)`
  align-items: center;
`

interface WorkspaceListItemActionsViewInterface {
}

const WorkspaceListItem = ( { 
  workspaceId,
  workspaceIntegrationApp,
  workspaceName,
  isConnected,
  connectionOwnerName,
  lastSyncAt,
  onClick,
  onSynchronise,
  onReconnect,
  onDisconnect,
  onDelete,
}: WorkspaceListItemViewInterface): JSX.Element => (
  <Card>
    <CustomRow>
      <WorkspaceTitle span={8} onClick={ () => { onClick(workspaceId) }}>
        <LogoImg name={workspaceIntegrationApp.name} /> <AppNameSpan>{workspaceIntegrationApp.name}</AppNameSpan> ({ workspaceName })
      </WorkspaceTitle>

      <WorkspaceInfo span={5}>
        <StatusBadge status={isConnected} /> { isConnected ? 'Connected' : 'Disconnected' }
      </WorkspaceInfo>

      <WorkspaceInfo span={5}>
        { isConnected && (
          <div>
            Connected by: { connectionOwnerName }
          </div>
        )}
      </WorkspaceInfo>

      <WorkspaceInfo span={5}>
        Last sync: { lastSyncAt.format(DATE_FORMAT) }
      </WorkspaceInfo>

      <WorkspaceActions span={1}>
        <EllipsisDropdown
          menu={(
            <Menu>

              { isConnected && (
                <Menu.Item key={0} onClick={onSynchronise}>
                  <SyncIcon /> Synchronise
                </Menu.Item>
              )}

              { !isConnected && (
                <Menu.Item key={1} onClick={onReconnect}>
                  <ReconnectIcon /> Reconnect
                </Menu.Item>
              )}

              { isConnected && (
                <Menu.Item key={2} onClick={onDisconnect}>
                  <DisconnectIcon /> Disconnect
                </Menu.Item>
              )}

              <Menu.Item key={3} onClick={onDelete}>
                <DeleteIcon /> Delete
              </Menu.Item>
            </Menu>
          )}
        />
      </WorkspaceActions>

    </CustomRow>
  </Card>
)

WorkspaceListItem.propTypes = {
}

export default WorkspaceListItem