import { useEffect, useRef, useState } from "react"
import { TApiProjectsUser } from "@timenotes/shared/src/services/api-client/projects-users-api"
import { TApiProject } from "@timenotes/shared/src/services/api-client/types"
import { Input } from "antd"
import styled from "styled-components"
import useUpdateOrCreateProjectsUserMutation from "@timenotes/shared/src/services/projects-users/queries/use-update-or-create-projects-user-mutation"
import { LoadingOutlined } from "@ant-design/icons"

type ProjectsUsersBudgetTimeInputProps = {
  //React.ComponentProps<typeof Input>
  project: TApiProject
  projectsUser: TApiProjectsUser
}

const InPlaceInput = styled(Input)`
  &.inactive:hover {
    background-color: #eee;
    border-radius: 8px;
  }
`

const ProjectsUsersBudgetTimeInput = (props: ProjectsUsersBudgetTimeInputProps) => {
  const {
    project,
    projectsUser,
  } = props

  const [isFocus, setIsFocus] = useState(false)

  const currentBudgetTime = projectsUser.budgetTime
  const currentBudgetTimeInputValue = projectsUser.budgetTime == null ? "" : `${projectsUser.budgetTime}`

  const [inputValue, setInputValue] = useState(currentBudgetTimeInputValue)

  const inputRef = useRef<Input>(null)

  const triggerBlur = () => {
    if (inputRef.current && isFocus) {
      inputRef.current.blur()
    }
  }

  const {
    mutation: updateOrCreateProjectsUserMutation
  } = useUpdateOrCreateProjectsUserMutation({
    onSuccess: () => {
      triggerBlur()
    }
  })

  // Update and reinitialize input value with props budgetTime if needed
  useEffect(() => {
    setInputValue(projectsUser.budgetTime == null ? "" : `${projectsUser.budgetTime}`)
  }, [props.projectsUser.budgetTime])

  const triggerUpdateOrCreate = (projectsUser: TApiProjectsUser, values: any): void => {
    const params = projectsUser.id ? values : {
      usersAccountId: projectsUser.usersAccount.id,
      projectRole: values.projectRole || 'member',
      billableRate: values.billableRate || null,
      budgetTime: values.budgetTime || null,
    }

    updateOrCreateProjectsUserMutation.mutate({
      projectId: project.id,
      projectsUserId: projectsUser.id || undefined,
      projectsUser: params,
    })
  }

  const handleUpdate = async () => {
    const updateValue = inputValue == "" ? null : parseInt(inputValue)

    // Do not send any requests if value did not change!
    if (updateValue == currentBudgetTime) return

    triggerUpdateOrCreate(projectsUser, {
      budgetTime: updateValue
    })
  }

  return (
    <InPlaceInput 
      style={{
        width: '120px',
      }}
      ref={inputRef}
      className={isFocus ? 'active' : 'inactive'}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onBlur={() => {
        handleUpdate()
        setIsFocus(false)
      }}
      onPressEnter={() => {
        handleUpdate()
      }}
      onKeyUp={(e) => {
        if (e.key == "Escape") {
          setInputValue(currentBudgetTimeInputValue)
          setTimeout(() => {
            triggerBlur()
          }, 10)
        }
      }}
      placeholder={currentBudgetTime === null ? "(No limit)" : undefined}
      suffix="h"
      onFocus={() => setIsFocus(true)}
      bordered={isFocus}
    />
  )

}

export default ProjectsUsersBudgetTimeInput