import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"
import { Button, Divider, Modal, message } from "antd"
import useApiClient from "hooks/useApiClient"
import { useIntercomShutdown } from "services/timenotes-intercom-provider"

const DeleteUserView = () => {
  const apiClient = useApiClient()
  const shutdown = useIntercomShutdown()

  const deleteWorkspace = async () => {
    const response = await apiClient.delete('/users/remove')

    if (response.ok) {
      message.success('User deleted successfully!')

      shutdown()
      apiClient.logout()
      window.location.pathname = '/auth/register'

    } else {
      message.error(getApiErrorMessage(response, 'base'))
    }
  }

  const handleDelete = () => {
    Modal.confirm({
      width: '600px',
      title: 'Are you sure you want to delete your user account?',
      content: (
        <div>
          <br/>
          <p>Clicking delete will cancel your user account and automatically delete all the data related to your user from Timenotes servers.</p>
          <p>This action happens instantly and can not be reverted!</p>
        </div>
      ),
      onOk: deleteWorkspace,

      okText: 'Delete',
      okButtonProps: {
        type: 'default',
      },
      cancelButtonProps: {
        type: 'text',
      }
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Divider />

      <p>You have no workspaces available.</p>

      <Button
        type="ghost"
        onClick={handleDelete}
      >
        Delete user account 
      </Button>

    </div>
  )
}

export default DeleteUserView