import React from "react"
import { Badge, Checkbox, Input, Popover, Tag } from "antd"
import { ChangeEvent } from "react"
import styled from "styled-components"
import { DownOutlined, SearchOutlined } from "@ant-design/icons"
import OutsideAlerter from "components/view-utils/outside-alerter"

import _map from "lodash/map"
import _uniq from "lodash/uniq"
import _cloneDeep from "lodash/cloneDeep"
import _remove from "lodash/remove"
import _isEqual from "lodash/isEqual"
import { isTransitionDefined } from "framer-motion/types/animation/utils/transitions"

interface IGroups {
  hasAllHelpers?: boolean
  label?: string
  options: { id: string; title: string, color?: string }[]
}

interface ISelectWrapper {
  width?: string
}

const SelectWrapper = styled.div<ISelectWrapper>`
  width: ${(props: ISelectWrapper) => props.width ?? "120px"};
`

//@ts-ignore
const SearchInput = styled(Input)`
  padding: 5px;
  margin-bottom: 10px;
`

const OptionGroup = styled.div`
  max-height: 265px;
  overflow: scroll;
`

const OptionGroupTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const OptionGroupTitle = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #dbdbdb;
  margin: 5px 0;
`

const Option = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #474d55;
  margin-bottom: 8px;
  cursor: pointer;

  &.no-checkboxes:hover {
    background-color: #f8f8f8;
  }
`
const SelectAll = styled.button`
  border: none;
  outline: none;
  background: transparent;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: #474d55;
  cursor: pointer;
`

const GenericSelectInput = styled(Input)`
  cursor: pointer; 

  & input.ant-input {
    cursor: pointer;
  }
`

interface IState {
  selectVisible: boolean
  searchPhrase: string
  selectedItems: string[]
}

interface IProps {
  placeholder: string
  activePlaceholder?: string
  emptyPlaceholder?: string

  selectedItems?: string[]
  groups: IGroups[]
  width?: string
  showCheckboxes?: boolean
  disabled?: boolean

  onChange: (ids: string[]) => void
  onSelect?(idOrIds: string | string[]): any
  onDeselect?(idOrIds: string | string[]): any
}

export class GenericSelect extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props)

    this.state = {
      selectVisible: false,
      searchPhrase: "",
      selectedItems: this.props.selectedItems || [],
    }
  }

  get activePlaceholder() {
    return this.props.activePlaceholder || this.props.placeholder
  }

  get emptyPlaceholder() {
    return this.props.emptyPlaceholder || this.props.placeholder
  }

  toggleSelectVisible = () => {
    this.setState({ selectVisible: !this.state.selectVisible })
  }

  hideSelect = () => {
    this.setState({ selectVisible: false })
  }

  onSearch = (data: IGroups["options"]) =>
    data.filter((item: { title: string }) => {
      return item.title.toLowerCase().includes(this.state.searchPhrase.toLowerCase())
    })

  setSelectedIds = (ids: string[]) => {
    this.setState({ selectedItems: _uniq(ids) })
  }

  onSelect = (idOrIds: string | string[]) => {
    if (Array.isArray(idOrIds)) {
      const ids = idOrIds
      this.setSelectedIds(_uniq([...this.state.selectedItems, ...ids]))
    } else {
      const id = idOrIds
      this.setSelectedIds(_uniq([...this.state.selectedItems, id]))
    }

    if (this.props.onSelect) {
      this.props.onSelect(idOrIds)
    }
  }

  onDeselect = (idOrIds: string | string[]) => {
    const newSelectedIds = _cloneDeep(this.state.selectedItems)

    if (Array.isArray(idOrIds)) {
      const ids = idOrIds
      _remove(newSelectedIds, (selectedId) => {
        return ids.includes(selectedId)
      })
    } else {
      const id = idOrIds
      _remove(newSelectedIds, (selectedId) => {
        return selectedId === id
      })
    }

    if (this.props.onDeselect) {
      this.props.onDeselect(idOrIds)
    }

    this.setSelectedIds(newSelectedIds)
  }

  toggleSelect = (id: string) => {
    this.state.selectedItems.includes(id) ? this.onDeselect(id) : this.onSelect(id)
  }

  triggerOnChange = () => {
    if (this.props.onChange) {
      this.props.onChange(this.state.selectedItems)
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
    if (!_isEqual(prevState.selectedItems, this.state.selectedItems)) {
      this.triggerOnChange()
    }

    if (!_isEqual(prevProps.selectedItems, this.props.selectedItems)) {
      this.setState({ selectedItems: this.props.selectedItems || []})
    }

  }

  render() {
    const showCheckboxes = (this.props.showCheckboxes === undefined || this.props.showCheckboxes) ? true : false

    return (
      <div className="generic-select-wrapper">
        <OutsideAlerter
          onClick={this.hideSelect}
          getInnerNode={(wrapperNode: any) => { return wrapperNode.closest('.generic-select-wrapper') }}
        >
          <Popover
            // style={style}
            getPopupContainer={(node) =>{ return node.closest('.generic-select-wrapper') || document.body }}
            placement="bottomLeft"
            trigger="click"
            visible={(this.props.disabled ? false : this.state.selectVisible)}
            content={
              <>
                <SearchInput
                  placeholder="Find..."
                  prefix={<SearchOutlined style={{ color: "#DBDBDB" }} />}
                  autoFocus={true}
                  allowClear={true}
                  value={this.state.searchPhrase}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    this.setState({ searchPhrase: e.target.value })
                  }}
                />

                <OptionGroup>
                  {this.props.groups.map((val: IGroups, index: number) => {
                    return (
                      <div key={index}>
                        {this.onSearch(val.options).length === 0 ? (
                          <div>No results</div>
                        ) : (
                          <div>
                            <OptionGroupTitleWrapper>
                              <OptionGroupTitle key={index}>{val.label ?? ""}</OptionGroupTitle>
                              {val.hasAllHelpers && (
                                <div>
                                  <SelectAll
                                    onClick={() => {
                                      this.onSelect(_map(val.options, "id"))
                                    }}
                                  >
                                    ALL
                                  </SelectAll>
                                  <SelectAll
                                    onClick={() => {
                                      this.onDeselect(_map(val.options, "id"))
                                    }}
                                  >
                                    NONE
                                  </SelectAll>
                                </div>
                              )}
                            </OptionGroupTitleWrapper>
                            {this.onSearch(val.options).map((item: { id: string; title: string, color?: string }) => {
                              return (
                                <Option
                                  key={`${item.id}`}
                                  onClick={() => {
                                    this.toggleSelect(item.id)
                                  }}
                                  className={ showCheckboxes ? '' : 'no-checkboxes'}
                                >
                                  { showCheckboxes && (
                                    <Checkbox
                                      checked={this.state.selectedItems.includes(item.id)}
                                      style={{ marginRight: "8px" }}
                                    />
                                  )}
                                  { item.color && (
                                    <Badge color={item.color} status="success"/>
                                  )}
                                  {`${item.title}`}
                                </Option>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </OptionGroup>
              </>
            }
            destroyTooltipOnHide={true}
          >
            <SelectWrapper
              width={this.props.width}
              onClick={this.toggleSelectVisible}
            >
              { this.props.children ? (
                this.props.children
              ) : (
                <Badge count={this.state.selectedItems.length}>
                  <GenericSelectInput
                    disabled={this.props.disabled}
                    value={this.state.selectedItems.length > 0 ? this.activePlaceholder : ""}
                    placeholder={this.emptyPlaceholder}
                    suffix={<DownOutlined style={{ color: "#DBDBDB" }} />}
                  />
                </Badge>
              )}
            </SelectWrapper>
          </Popover>
        </OutsideAlerter>
      </div>
    )
  }
}
