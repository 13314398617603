import { Card, Skeleton } from "components/base"

const TeamSkeletonPage = () => (
  <div className='page-content'>
    <Skeleton paragraph={false} />
    <br />
    <Card>
      <Skeleton paragraph={false} />
    </Card>

    <Card>
      <Skeleton paragraph={{ rows: 5 }} />
    </Card>
  </div>
)

export default TeamSkeletonPage