import { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { Field, FieldProps, Formik, FormikHelpers, FormikProps } from "formik"
import OutsideAlerter from "components/view-utils/outside-alerter"
import { Form } from "antd"
import ErrorMessage from "components/base/error-message"

type TUpdateFieldResponse = {
  ok: boolean,
  errorMessage?: string
}

interface IResetEditor { (): any }
interface ISubmitEditorForm { (): any }

export interface IInPlaceEditorProps<TValue> {
  value: TValue
  placeholder?: string
  renderField?(props: IInPlaceEditorRenderFieldProps<TValue>): JSX.Element

  onChange(value: TValue): Promise<TUpdateFieldResponse>
  onIsEditingChange?(isEditing: boolean): void
  children?: JSX.Element | string
  submitOnBlur?: boolean
  getInnerNode?(node: any): any
}

export interface IInPlaceEditorRenderFieldProps<TValue> {
  value: TValue
  onChange(value: TValue): any
  resetEditor: IResetEditor
  submitForm: ISubmitEditorForm
}

const PreviewWrapper = styled.div`
  cursor: pointer !important;
  padding: 4px;

  :hover {
    border-radius: 5px;
    background-color: #efefef;
  }
`

export const InPlaceEditor = <TValue,>(props: IInPlaceEditorProps<TValue>): JSX.Element => {
  const { value, placeholder, onChange } = props

  const [isEditing, setIsEditing] = useState(false)
  const [editorValue, setEditorValue] = useState(value)
  const formRef = useRef<any>()

  useEffect(() => {
    if (props.onIsEditingChange) {
      props.onIsEditingChange(isEditing)
    }
  }, [isEditing])

  const resetEditor = () => {
    formRef.current?.resetForm()
    setIsEditing(false)
  }

  const handleValueChange = (newValue: any, formikBag: FormikHelpers<any>) => {
    onChange(newValue).then((response) => {
      if (response.ok) {
        resetEditor()
      } else {
        formikBag.setFieldError('value', response.errorMessage)
      }
    })
  }

  const defaultRenderField = (renderProps: IInPlaceEditorRenderFieldProps<TValue>): JSX.Element => {
    return (<span onClick={renderProps.resetEditor}>IN-PLACE-EDITOR-FIELD</span>)
  }

  const renderField = props.renderField ? props.renderField : defaultRenderField // TODO: add props.renderField assignment

  const defaultRenderPreview = () => {
    return (
      <PreviewWrapper onClick={() => { setIsEditing(true) }}>
        { props.children ? (
          <div>
            { props.children }
          </div>
        ) : (
          <span> 
            { props.value } 
          </span>
        )}
      </PreviewWrapper>
    )
  }

  const renderPreview = defaultRenderPreview

  if (isEditing) {
    return (
      <OutsideAlerter

        // getInnerNode={(wrapperNode) => { return wrapperNode.closest('.ant-popover-content') }}
        // getInnerNode={props.getInnerNode ? props.getInnerNode : undefined}

        onClick={() => { 
         if(props.submitOnBlur) {
            formRef.current.submitForm()
          } else {
            resetEditor()
          }
        }}
      >
        <Formik
          innerRef={formRef}

          initialValues={{
            value: value,
          }}

          onSubmit={(values, formikBag) => {
            const newValue = values.value
            handleValueChange(newValue, formikBag)
          }}

          enableReinitialize={true}
        >
          { (formikProps: FormikProps<any>) => (
            <Form className="in-place-edit-field">
              <Field name="value">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }: FieldProps) => {
                  const renderFieldProps: IInPlaceEditorRenderFieldProps<TValue> = {
                    value: formikProps.values.value,
                    onChange: (newValue: TValue) => { 
                      return formikProps.setFieldValue('value', newValue)
                     }, 
                    resetEditor: () => {},
                    submitForm: () => {
                      return formikProps.submitForm()
                    }
                  }

                  return renderField(renderFieldProps)
                }}
              </Field>
              <ErrorMessage msg={formikProps.errors.value} />
            </Form>
          )}
        </Formik>
      </OutsideAlerter>
    )
  } else {
    return defaultRenderPreview()
  }
}