import { Select, Tag, Tooltip } from 'antd'
import OutsideAlerter from 'components/view-utils/outside-alerter'
import React, { useState } from 'react'
import { BlockPicker, ColorResult } from 'react-color'
import styled from 'styled-components'

interface IProps {
  value: string
  onChange(value: string): void
}

export const TIMENOTES_COLORS = [
  "#76C290",
  "#613131",
  "#F6A07E",
  "#9A82DE",
  "#3A3AA3",
  "#D6C1D4",
  "#7C2F75",
  "#1C8A7D",
  "#C7D08B",
  "#C76767",
//  "#C76767",
]

export const getRandomColor = () => {
  return TIMENOTES_COLORS[Math.floor(Math.random() * TIMENOTES_COLORS.length)]
}

const StyledSelect = styled(Select)`
`

const ColorPickerSelect = (props: IProps ) => {

  const [open, setOpen] = useState(false)

  const handleOnChange = (colorResult: ColorResult) => {
    props.onChange(`${colorResult.hex}`)
  }

  const options = [
    {
      label: 'Color',
      value: props.value,
    }
  ]

  return (
    <Tooltip
      placement='bottom'
      color="white"
      title={() => (
        <div style={{ paddingTop: '10px', width: '100%', display: 'flex', justifyContent: 'center' }}>
          <OutsideAlerter
            onClick={() => { setOpen(false) }}
          >
            <BlockPicker
              onChangeComplete={(value) => {
                handleOnChange(value)
              }}
              colors={TIMENOTES_COLORS}
              color={props.value}
            // onChange={handleOnChange}
            />
          </OutsideAlerter>
        </div>
      )}
      visible={open}
    >
      <StyledSelect
        style={{ width: '70px' }}
        value={props.value}
        open={false}
        onClick={() => { setOpen(true) }}
        dropdownMatchSelectWidth={false}
        dropdownStyle={{
          boxShadow: 'none',
          background: 'none',
        }}
      >
        <Select.Option key={props.value} value={props.value}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <Tag color={props.value} style={{ height: '60%', width: '100%', marginLeft: '5px' }} />
          </div>
        </Select.Option>
      </StyledSelect>
    </Tooltip>
  )
}

export default ColorPickerSelect