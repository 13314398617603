import { UseQueryOptions, useMutation, useQuery } from "react-query"
import { useApiClient } from "../api-client/api-client.hooks"
import { TApiProject, TApiResponseMeta, TApiTask } from "../api-client/types"
import { TApiGenericError, TApiGenericErrors, TApiGenericResponse } from "../timenotes-query/timenotes-query.types"
import { IGetAllTasksParams } from "../api-client/tasks-api"


interface IUseTasksQueryParams extends IGetAllTasksParams {
  projectId?: string
}

const PER_PAGE = 50

export const useTasksQuery = (params: IUseTasksQueryParams, options: UseQueryOptions<TApiGenericResponse<TApiTask[]>, TApiGenericErrors<null>> = {}) => {
  const apiClient = useApiClient()

  const query = useQuery<TApiGenericResponse<TApiTask[]>, TApiGenericErrors<null>>(
    ['tasks', params.projectId, params.filters],
    async () => {
      const response = await apiClient.getTasks(params.projectId, { ...params, perPage: params.perPage || PER_PAGE })

      return {
        data: response.tasks || [],
        ok: true,
        status: 200,
      }
    },
    {
      placeholderData: { data: [], ok: true, status: 200 },
      enabled: !!params.projectId,
      ...options
    }
  )

  return query
}