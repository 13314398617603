import { TApiResponse, TApiParams } from "./types"
import BaseApi from './base'

// Required as an array for some implementation
export type TApiHiddenPage = 'reports' | 'team' | 'clients' | 'projects' | 'approvals'
export type TApiApprovalsPeriodType = "weekly" | "biweekly" | "semi_monthly" | "monthly"

export type TApiSetting = {
  accountLogoUrl?: string
  approvalsEnabled: boolean
  approvalsPeriod: TApiApprovalsPeriodType

  name?: string
  defaultBillableRateCurrency: string
  allowFutureLogs: boolean
  billableSetter: string
  dateFormat: string
  date_format?: string
  defaultBillableRate: null | number
  defaultCostRate: null | number
  defaultProjectVisibility: boolean
  hiddenPages: TApiHiddenPage[]

  includeTimenotesLogoPdf: boolean
  loggingLocked: boolean
  loggingLockedTill: null | string
  memberTimelogsVisibility: string
  projectClientManager: string
  ratesRecalculationMethod: string
  tagsManager: string
  timeFormat: string


  timeLogDescriptionMandatory: boolean
  timeLogTagsMandatory: boolean

  timeLogsOverlapping: boolean
  timeZone: string
  updateTypeLoggingLocked: string
  updateValueLoggingLocked: null | number
  weekStart: string
  workingWeekDays: Array<string>
  worktime: number
  maxWorktime: number

  // DECORATED PROPERTY
  weekStartDayNumber: number
}

export interface IGetSettingsResponse extends TApiResponse {
  setting: TApiSetting
}

export interface IUpdateSettingsParams extends TApiParams {
  name?: string
  accountLogo?: object // ???
  dateFormat: string
  timeFormat: string
  worktime: number
  weekStart: string
  defaultBillableRate: null | number
  defaultBillableRateCurrency: string
  defaultCostRate: null | number
  loggingLocked: boolean
  loggingLockedTill: null | string
  defaultProjectVisibility: boolean
  projectClientManager: string
  timeLogsOverlapping: boolean
  updateTypeLoggingLocked: string
  updateValueLoggingLocked: null | number
  timeZone: string
  tagsManager: string
  billableSetter: string
  ratesRecalculationMethod: string
  memberTimelogsVisibility: string
  includeTimenotesLogoPdf: boolean
}

class SettingsApi extends BaseApi {
  async getSettings (): Promise<IGetSettingsResponse> {
    let response = await fetch(`${this.rootUrl}/setting`, {
      method: 'GET',
      headers: this.headers,
    })

    const parsedResponse = await this.getResponseJson(response)

    if (parsedResponse.ok) {
      const DayStringToNumber = {
        'monday': 1,
        'tuesday': 2,
        'wednesday': 3,
        'thursday': 4,
        'friday': 5,
        'saturday': 6,
        'sunday': 7,
      }

      // @ts-ignore
      parsedResponse.setting.weekStartDayNumber = DayStringToNumber[parsedResponse.setting.weekStart]
    }

    return parsedResponse
  }

  async updateSettings(params: IUpdateSettingsParams) {

    const settingsBody = {
      ...this.paramsToSnakeCase(params),
      ...(params.name ? { account: { name: params.name }} : {}),
    }

    let response = await fetch(`${this.rootUrl}/setting`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        setting: settingsBody,
      }),
    })

    return await this.getResponseJson(response)
  }

  async uploadLogo(logoFile: any) {
    const data = new FormData()

    data.append('setting[account_logo]', logoFile)

    const response = await fetch(this.getUploadLogoUrl(), {
      method: 'PATCH',
      headers: {
        'AuthorizationToken': this.accessToken as string,
        'AccountId': this.accountId as string,
      },
      body: data,
    })
  }

  getUploadLogoUrl = () => {
    return `${this.rootUrl}/setting`
  }

  async removeSettingsAccountLogo () {
    let response = await fetch(`${this.rootUrl}/setting/remove_account_logo`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
}

export default SettingsApi
