import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"
import { Button, Divider, Form, Input, message } from "antd"
import ErrorMessage from "components/base/error-message"
import { useFormik } from "formik"
import useApiClient from "hooks/useApiClient"
import { useHistory } from "react-router-dom"
import AuthLayout from "views/auth-views/components/auth-layout"

const ResetTokenForm = ({
  resetToken
}: {
  resetToken: string,
}) => {
  const apiClient = useApiClient()
  const history = useHistory()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    onSubmit: async (values) => {
      const response = await apiClient.confirmPasswordReset({
        resetToken: resetToken,
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
      })

      if (response.ok) {
        message.success("Password reset successful. You can sign in to your account.", 5)
        history.push('/auth/login')
      } else {
        const errorMessage = getApiErrorMessage(response, 'email')
        message.error(errorMessage)

        if (response.errors) {
          formik.setErrors(response.errors || {})
        }
      }
    }
  })

  return (
    <AuthLayout>
      <h1> Configure new password </h1>

      <Divider />
      <Form layout="vertical" name="register-form" onSubmitCapture={formik.handleSubmit}>

        <Form.Item
          name="password"
          label="New Password"
        >
          <Input
            type="password"
            autoFocus={true}
            onChange={formik.handleChange('password')}
          />
          <ErrorMessage msg={formik.errors.password} />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password Confirmation"
        >
          <Input
            type="password"
            onChange={formik.handleChange('passwordConfirmation')}
          />
          <ErrorMessage msg={formik.errors.passwordConfirmation} />
        </Form.Item>

        <br />

        <Form.Item>
          <Button 
            loading={formik.isSubmitting}
            type="primary" htmlType="submit" block>
            Save
          </Button>
        </Form.Item>
      </Form>
    </AuthLayout>
  )
}

export default ResetTokenForm
