import React, { useState } from 'react'
import _map from 'lodash/map'
import { Badge } from 'antd'
import { isEmpty } from 'lodash'
import { LockOutlined } from '@ant-design/icons'
import RecordsSelect from '../records-select'
import { useTagsQuery } from '@timenotes/shared/src/services/tags/tags.hooks'
import { useNewTagModal } from './new-tag-modal'
import { usePermissionsQuery } from '@timenotes/shared/src/services/permissions/permissions.hooks'
import { TApiTag } from '@timenotes/shared/src/services/api-client/types'

export interface ITagsSelect extends Partial<React.ComponentProps<typeof RecordsSelect<TApiTag>>> {
  open?: boolean
  setOpen?(open: boolean): void
}

export const TagsSelect = (selectProps: ITagsSelect) => {

  const newTagModalProps = useNewTagModal()

  const permissionsQuery  = usePermissionsQuery()
  const [internalSearchValue, internalSetSearchValue] = useState("")

  const searchValue = selectProps.searchValue || internalSearchValue

  const onSearch = selectProps.onSearch || internalSetSearchValue

  const canCreateTags = !!permissionsQuery.data?.permissions.manageTags

  const tagsQuery = useTagsQuery()

  const isDisabled = tagsQuery.isLoading || tagsQuery.isError
  const apiOptions = !tagsQuery.isSuccess ? [] : tagsQuery.data.data.map((tag) => ({ value: tag, search: tag.name }))

  const createTagLabel = isEmpty(searchValue) ? `Create new tag` : `Create new tag - "${searchValue}"`
  const createTagOption = {
    label: canCreateTags ? "Create a new tag" : "Can't create a new tag",
    options: [{
     disabled: !canCreateTags,
      value: {
        id: 'CREATE_NEW',
        name: createTagLabel,
      } as TApiTag
    }]
  }
  const options = [...apiOptions, createTagOption]

  const renderTagItem = ({ tag }: { tag: TApiTag }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Badge status={"success"} color={tag.color || "green"} text={tag.name} />
    </div>
  )

  const renderCreateNewTagLabel = () => (
    <>
      {canCreateTags ? (
        <span>{createTagLabel}</span>
      ) : (
        <i><LockOutlined /> No permissions </i>
      )}
    </>
  )

  // Prevent Backspace to remove "non-existing" tagRender elements in the empty search bar
  // we display selected items only in the dropdown
  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && searchValue === '') {
      e.preventDefault();
      e.stopPropagation();

      return false
    }
  };

  return (
    <RecordsSelect<TApiTag>
      placeholder="Select tags"
      onInputKeyDown={handleKeyDown}
      showArrow={false}
      loading={tagsQuery.isFetching}
      disabled={isDisabled}
      mode='multiple'
      showSearch={true}
      searchValue={searchValue}
      onSearch={onSearch}
      tagRender={() => (<></>)}
      autoClearSearchValue={true}
      allowClear={true}
      onClear={() => {
        if(selectProps.onChange) {
          selectProps.onChange([] as any, undefined as any)
        }
      }}
      options={options}
      valueProp="id"
      searchProp="name"
      labelProp={(tag) => {
        if (tag.id == 'CREATE_NEW') {
          return renderCreateNewTagLabel()
        }
        return renderTagItem({ tag })
      }}

      {...selectProps}

      onCreate={() => {
        newTagModalProps.setTagName(searchValue)
        newTagModalProps.setVisible(true)

        // When new tag is created it will automatically be added to the select list
        newTagModalProps.setOnSuccess((tag) => {
          // @ts-ignore
          selectProps.onChange([...selectProps.value as TApiTag[], tag])
          onSearch("")
          tagsQuery.refetch()
        })
        if (selectProps.onCreate) selectProps.onCreate(searchValue)
      }}

      style={{
        width: '270px',
        ...selectProps.style
      }}
    />

  )
}

export default TagsSelect