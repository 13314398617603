import React, { useState } from 'react'
import { Badge, Popover, Select } from 'antd'

export interface FilterInputProps {
  placeholder?: string
  count?: number
  children: React.ReactNode
}

export const FilterInput = (props: FilterInputProps) => {
  const [open, setOpen] = useState(false)

  return (
    <div
      style={{
        display: 'flex'
      }}
      // Hacky implementation to figure out if the Escape key was clicked inside
      // the filter and handle popup closing only if there is no input with some value inside
      // It's done to make sure user can still click Escape inside the search input inside filter 
      // popup to clear the input, but stay inside the poup and be able to continue the search
      onKeyDownCapture={(e: any) => {
        if (e.key === 'Escape') {
          if (e.target && (e.target?.value || e.target.value == '')) {
            const text = `${e.target?.value}`
            if (text == '') {
              setOpen(false)
            }
          } else {
            setOpen(false)
          }
        }
      }}
    >
      <Popover
        trigger={'click'}
        placement='bottomLeft'
        content={props.children}
        getPopupContainer={(node) => node.parentNode}
        destroyTooltipOnHide={true}
        visible={open}
        onVisibleChange={(visibility) => {
          setOpen(visibility);
        }}
      >
        <Select
          placeholder={props.placeholder || 'Find...'}
          style={{
            width: '100%',
          }}
          onFocus={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          dropdownStyle={{
            display: 'none'
          }} />
      </Popover>
      <Badge
        count={props.count}
        offset={[10, -8]}
        style={{
          position: 'absolute'
        }} 
      />
    </div>
  );
};

export default FilterInput
