import { TApiResponse, TApiParams } from "./types"
import BaseApi, { getQueryString } from './base'

export interface ICreateSubscriptionParams extends TApiParams {
  planId: string
}

class SubscriptionsApi extends BaseApi {
  async createSubscription (params: ICreateSubscriptionParams) {
    let response = await fetch(`${this.rootUrl}/subscriptions`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        subscription: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }
}

export default SubscriptionsApi
