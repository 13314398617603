import _ from 'lodash'
import moment from 'moment-timezone'
import queryString from 'query-string'
import snakecaseKeys from 'snakecase-keys'
// @ts-ignore
import camelcaseKeysDeep from 'camelcase-keys-deep'
import { TApiReportsDate } from './reports-api'

const API_URL = process.env.REACT_APP_API_URL || 'http://api.lvh.me'

const oldRootUrl = `${API_URL}/v1`

export const parameterizeArray = (key: any, arr: any) => {
  arr = arr.map(encodeURIComponent)
  return key+'[]=' + arr.join('&'+key+'[]=')
}

export const getQueryString = function(obj: any, encode: any) {

  function getPathToObj(obj: any, path = []) {
    let result: any = [];

    for (let key in obj) {
      if (!obj.hasOwnProperty(key)) return;

      //deep copy
      let newPath = path.slice();

      // @ts-ignore
      newPath.push(key);

      let everyPath = [];

      if (typeof obj[key] === "object") {
        if (Array.isArray(obj[key])) {
          everyPath.push({
            path: newPath,
            val: obj[key]
          });
        } else {
          everyPath = getPathToObj(obj[key], newPath);
        }
      } else {
        everyPath.push({
          path: newPath,
          val: obj[key]
        });
      }

      everyPath.map((item: any) => result.push(item))
    }

    return result;
  }

  function composeQueryString(paths: any) {
    let result = "";

    // @ts-ignore
    paths.map((item) => {
      let pathString = "";
      if (item.path.length > 1) {
        // @ts-ignore
        pathString = item.path.reduce((a, b, index) => {
          return a + '['+ b +']';
        })
      } else {
        pathString = item.path[0];
      }

      let joinChar = "?"

      if (result) {
        joinChar = "&"
      }

      if (Array.isArray(item.val)) {
        pathString = joinChar + item.val.map((element: any) => pathString + '[]' + '=' + element).join('&')
      } else {
        pathString = joinChar + pathString + '=' + item.val;
      }

      if (pathString != joinChar) {
        result += pathString;
      }
    });

    return result;
  }

  const str = composeQueryString(getPathToObj(obj));
  return encode === true ? encodeURI(str) : str;
}

class ApiClient {
  rootUrl: string
  accessToken: null | string
  accountId: null | string
  integrationType: null | string
  clientName: string

  constructor(rootUrl: string, clientName: string) {
    this.rootUrl = rootUrl
    this.accessToken = null
    this.accountId = null
    this.integrationType = null
    this.clientName = clientName
  }

  cameliseKeys(obj: any): any {
    return _.mapKeys(obj, (val, key) => {
      return _.camelCase(key)
    })
  }

  loadAccessCredentials() {
    let accessToken = window.localStorage.getItem('accessToken')
    let accountId = window.localStorage.getItem('accountId')

    if (accessToken && accountId) {
      this.accessToken = accessToken
      this.accountId = accountId
    }
  }

  setAccessCredentials({ 
    accessToken, 
    accountId
  }: {
    accessToken?: string,
    accountId?: string,
  }) {
    if (accessToken) {
      this.accessToken = accessToken
      window.localStorage.setItem('accessToken', accessToken)
    }

    if (accountId) {
      this.accountId = accountId
      window.localStorage.setItem('accountId', accountId)
    }
  }

  removeAccessToken() {
    window.localStorage.removeItem('accessToken')
  }

  removeAccountId() {
    window.localStorage.removeItem('accountId')
  }

  removeAccessCredentials() {
    window.localStorage.removeItem('accessToken')
    window.localStorage.removeItem('accountId')
  }

  async getResponseJson(response: any) {
    try {
      const { status } = response

      const result = await response.json()

      // console.log("STATUS - ", status)

      if (status == 402) {
        throw { status: 402, error: "Account blocked, payment required" }
      }

      if (status < 300 && status >= 200 && result.ok == undefined) {
        result.ok = true
      }

      // console.warn("FULL RESPONSE", JSON.stringify({ status, ...result }))
      return { status, ...(this.toCamelCase(result)) }

    } catch (e) {

      // @ts-ignore
      if (e.status && e.status == 402) {
      // @ts-ignore
        if (this.paymentRequiredCallback) {
          // @ts-ignore
          this.paymentRequiredCallback()
        }

        throw e
      }


      return response
    }
  }

  paramsToSnakeCase = (params = {}) => {
    return snakecaseKeys(params)
  }

  toCamelCase = (params = {}) => {
    return camelcaseKeysDeep(params)
  }

  prepareSortingParams = (params = {}) => {
    return Object.entries(this.paramsToSnakeCase((params || {}))).map(obj => { return obj[0] + ' ' + obj[1]; })
  }

  get isAuthenticated() {
    return this.accessToken && this.accountId
  }

  get headers() {
    let h = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      TimeZone: moment.tz.guess(),
    }

    if (this.clientName) {
      // @ts-ignore
      h.ClientName = this.clientName
    }

    if (this.accessToken) {
      // @ts-ignore
      h.AuthorizationToken = this.accessToken
    }

    if (this.accountId) {
      // @ts-ignore
      h.AccountId = this.accountId
    }

    if (this.integrationType) {
      // @ts-ignore
      h.IntegrationType = this.integrationType
    }

    return h
  }

  async logout() {
    console.log('API_LIB: LOGOUT REQUEST')

    let res = await fetch(`${this.rootUrl}/session`, {
      method: 'DELETE',
      headers: this.headers,
    })

    const response = await this.getResponseJson(res)

    // No matter if it worked or not we want to clear the browser apiClient data
    this.removeAccessCredentials()

    return response
  }

  async loginOld(attrs = {}, callback = {}) {
    // @ts-ignore
    attrs.params = attrs.params || {}

    // @ts-ignore
    let { email, password } = attrs.params

    // TODO: Login API should not be case sensitive
    email = email.toLowerCase()

    let response = await fetch(`${this.rootUrl}/sessions`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        email,
        password,
      }),
    })

    if (response.status !== 200 && response.status !== 201) {
      console.log(
        'Looks like there was a problem. Status Code: ' + response.status
      )
    }

    return await this.getResponseJson(response)
  }

  async getAccounts() {
    // console.log('GET ACCOUNTS', this)

    let response = await fetch(`${this.rootUrl}/users_accounts?per_page=1001`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async get(path: string, params?: object) {
    let queryString = ''
    if (params) {
      queryString = getQueryString(this.paramsToSnakeCase({ ...params }), true)
    }

    let response = await fetch(`${this.rootUrl}${path}${queryString}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  } 

  async patch(path: string, params: object, noSnakeCase?: boolean) {
    let response = await fetch(`${this.rootUrl}${path}`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify(noSnakeCase ? params : this.paramsToSnakeCase(params)),
    })

    return await this.getResponseJson(response)
  } 

  async put(path: string, params: object) {
    let response = await fetch(`${this.rootUrl}${path}`, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify(this.paramsToSnakeCase(params)),
    })

    return await this.getResponseJson(response)
  } 

  async delete(path: string) {
    let response = await fetch(`${this.rootUrl}${path}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  } 

  async post(path: string, params: object) {
    return this.create(path, params)
  }

  async create(path: string, params: any) {
    let response = await fetch(`${this.rootUrl}${path}`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(this.paramsToSnakeCase(params)),
    })

    return await this.getResponseJson(response)
  }

  handleBlobDownload = (response: any, defaultFileName: string): void => {
    const fileName = this.getResponseFileName(response, defaultFileName)

    response.blob().then((blob: any) => {
        // Creating new object of type file
        const fileURL = window.URL.createObjectURL(blob)
        // Setting various property values
        let alink = document.createElement('a')
        alink.href = fileURL
        alink.download = fileName
        alink.click()
    })
  }

  getResponseFileName = (response: any, defaultFileName: string): string => {
    let fileName: string = defaultFileName

    const contentDispositionHeader = response.headers.get('content-disposition')
    if (contentDispositionHeader) {
      fileName = this.getContentDispositionFileName(contentDispositionHeader)
    } else {
      console.warn("No content disposition header found!")
    }

    return fileName
  }

  getContentDispositionFileName = (disposition: string): string => {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    let fileName: string = "";
    if (utf8FilenameRegex.test(disposition)) {
      fileName = decodeURIComponent((utf8FilenameRegex.exec(disposition) || [])[1]);
    } else {
      // prevent ReDos attacks by anchoring the ascii regex to string start and
      //  slicing off everything before 'filename='
      const filenameStart = disposition.toLowerCase().indexOf('filename=');
      if (filenameStart >= 0) {
        const partialDisposition = disposition.slice(filenameStart);
        const matches = asciiFilenameRegex.exec(partialDisposition);
        if (matches != null && matches[2]) {
          fileName = matches[2];
        }
      }
    }
    return fileName;
  }

}

export default ApiClient
