import { useCurrentProjectsPermissionsQuery } from "@timenotes/shared/src/services/permissions/permissions.hooks"
import useDeleteProjectsGroupMutation from "@timenotes/shared/src/services/projects-groups/queries/use-delete-projects-group-mutation"
import useProjectsGroupsQuery from "@timenotes/shared/src/services/projects-groups/queries/use-projects-groups-query"
import { Col, List, Row, Skeleton, Spin, Tooltip } from "antd"
import { RemoveIcon } from "components/base/icons"

interface IProps {
  projectId: string

  onDeleteGroup?(): void
  onAddGroup?(): void
}

const ProjectsGroupsTable = ({ projectId }: IProps ) => {

  const currentProjectsPermissionsQuery = useCurrentProjectsPermissionsQuery({
    params: {
      projectId: projectId
    }
  })

  const currentProjectsPermissions = currentProjectsPermissionsQuery.data?.permissions

  const { 
    query: projectsGroupsQuery
  } = useProjectsGroupsQuery({
    projectId: projectId
  })

  const {
    mutation: deleteProjectsGroupMutation
  } = useDeleteProjectsGroupMutation()

  const deleteGroup = (id: string): void => {
    deleteProjectsGroupMutation.mutate({
      projectId,
      projectsGroupId: id,
    })
  }

  if (!projectsGroupsQuery.isSuccess) {
    return <Skeleton />
  }

  const projectsGroups = projectsGroupsQuery.data.projectsGroups

  return (
    <Row>
      <Col span="24">
        { projectsGroups.length == 0 ? (
          <span><i> No groups added </i></span>
        ) : (
          <List 
            itemLayout="horizontal"
            dataSource={projectsGroups}
            renderItem={item => (
              <List.Item
                actions={!currentProjectsPermissions?.manageCurrentProject ? [] : [
                  <Tooltip 
                    mouseEnterDelay={0.5}
                    title="All the users who has access to the project only via this group will lose it. Do not worry, all time logged so far will not be deleted."
                  >
                    <a key="remove-group" 
                      onClick={() => { deleteGroup(item.id) }}
                    >
                      <RemoveIcon />
                    </a>
                  </Tooltip>
                ]}
              >
                {item.membersGroup.name}
              </List.Item>
            )}
          />
        )}
      </Col>
    </Row>
  )
}

export default ProjectsGroupsTable