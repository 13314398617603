import { Spin } from "antd"

const PageLoadingSpin = () => (
  <div
    className="page-content"
    style={{
      display: 'flex',
      justifyContent: 'center',
      marginTop: '75px',
    }}
  >
    <Spin size="large" />
  </div>

)

export default PageLoadingSpin