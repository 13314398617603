import { TApiResponse, TApiParams } from "./types"
import _cloneDeep from 'lodash/cloneDeep'
import BaseApi, { getQueryString, parameterizeArray } from './base'

export type TApiTaskParentsFilters = {
  task_type?: 'to_do' | 'card'
}

export interface IGetTaskParentsParams extends TApiParams {
  filters?: TApiTaskParentsFilters
}

export type TApiTaskParent = {
  id: string
  name: string
  projectId: string
  taskType: string
}

export interface IGetTasksResponse extends TApiResponse {
  task_parents: Array<TApiTaskParent>
}

class TaskParentsApi extends BaseApi {
  async getTaskParents(projectId: string, requestParams?: IGetTaskParentsParams): Promise<IGetTasksResponse> {
    const params = _cloneDeep(requestParams)
    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/projects/${projectId}/task_parents${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
}

export default TaskParentsApi