import { randomUUID } from "crypto"

interface IDELAY_FUNCTIONS {
  [id: string]: number 
}
const DELAY_FUNCTIONS: IDELAY_FUNCTIONS = {}

export const delayFunction = (id: string, callback: Function, delay: number) => {

  if (DELAY_FUNCTIONS[id]) {
    clearTimeout(DELAY_FUNCTIONS[id])
  }

  const timeoutRef = window.setTimeout(() => { 
    callback() 
  }, delay)

  DELAY_FUNCTIONS[id] = timeoutRef

  return timeoutRef
}