import styled from 'styled-components'
import React from 'react'
import _omit from 'lodash/omit'

import { 
  ButtonProps,
  Badge as AntBadge,
  BadgeProps as AntBadgeProps,
  Button as AntButton,
  Card as AntCard,
  Checkbox as AntCheckbox,
  Col as AntCol,
  Menu as AntMenu,
  message as antMessage,
  Popover as AntPopover,
  Radio as AntRadio,
  Row as AntRow,
  Skeleton as AntSkeleton,
  Steps as AntSteps,
  Switch as AntSwitch,
  Tag as AntTag,
  Tooltip as AntTooltip,
} from 'antd'
import { AntTreeNodeBaseEvent } from 'antd/lib/tree/Tree'
import ConditonalWrap from './conditional-wrap'

export const StatusBadge = (props: any): JSX.Element => {
  return React.createElement(Badge, {...props, status: props.status ? "success" : "error" }, props.children) 
}

export const Radio = styled(AntRadio)`
`

interface BadgeProps extends AntBadgeProps {
  children?: JSX.Element
}

export const Badge = (props: BadgeProps)  => {
  return (
    <AntBadge {...props} />
  )
}

export const StyledButton = styled(AntButton)``

export interface IButton extends ButtonProps {
  disabledTooltip?: string
}

export const Button = (props: IButton): JSX.Element => (
  <ConditonalWrap
    condition={!!(props.disabled && props.disabledTooltip)}
    wrapper={(children) => (
      <Tooltip title={props.disabledTooltip as string}> {children}</Tooltip>
    )}
  >
    <StyledButton {..._omit(props, ['disabledText']) } />
  </ConditonalWrap>
)

export const InPlaceInputWrapper = styled.div`
  .ant-input {
    border: none;
  }

  .ant-select-selector {
    border: none !important;
  }

  .ant-input-affix-wrapper {
    border: none;
  }

  &:hover .ant-select-selector {
    cursor: pointer;
    background-color: #efefef;
  }

  &:hover span.ant-input-affix-wrapper {
    cursor: pointer;
    background-color: #efefef;
  }

  &:hover .ant-input:not(:focus) {
    cursor: pointer;
    background-color: #efefef;
  }
`

export const Card = styled(AntCard)``
export const Checkbox = AntCheckbox
export const Col = AntCol
export const message = antMessage
export const Menu = AntMenu
export const Row = AntRow
export const Popover = AntPopover
export const Skeleton = AntSkeleton
export const Switch = AntSwitch
export const Steps = AntSteps as any
export const Step = AntSteps.Step
export const Tag = AntTag
export const Tooltip = styled(AntTooltip)``