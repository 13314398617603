import { Form, Select } from "antd"
import { map } from "lodash"
import { useFormik } from "formik"
import ErrorMessage from "components/base/error-message"
import GoogleButton from "views/auth-views/components/google-button"
import useApiClient from "hooks/useApiClient"

const COUNTRIES_MAP = {
  af: "Afghanistan",
  al: "Albania",
  dz: "Algeria",
  as: "American Samoa",
  ad: "Andorra",
  ao: "Angola",
  ai: "Anguilla",
  ag: "Antigua and Barbuda",
  ar: "Argentina",
  am: "Armenia",
  aw: "Aruba",
  australian: "Australia",
  austrian: "Austria",
  az: "Azerbaijan",
  bs: "Bahamas",
  bh: "Bahrain",
  bd: "Bangladesh",
  bb: "Barbados",
  by: "Belarus",
  be: "Belgium",
  bz: "Belize",
  bj: "Benin",
  bm: "Bermuda",
  bt: "Bhutan",
  bo: "Bolivia",
  ba: "Bosnia and Herzegovina",
  bw: "Botswana",
  brazilian: "Brazil",
  vg: "British Virgin Islands",
  bn: "Brunei Darussalam",
  bulgarian: "Bulgaria",
  bf: "Burkina Faso",
  bi: "Burundi",
  kh: "Cambodia",
  cm: "Cameroon",
  canadian: "Canada",
  cv: "Cape Verde",
  ky: "Cayman Islands",
  cf: "Central African Republic",
  td: "Chad",
  cl: "Chile",
  china: "China",
  co: "Colombia",
  km: "Comoros",
  cg: "Congo",
  ck: "Cook Islands",
  cr: "Costa Rica",
  ci: "Côte d'Ivoire",
  croatian: "Croatia",
  cu: "Cuba",
  cw: "Curaçao",
  cy: "Cyprus",
  czech: "Czechia",
  kp: "Democratic People's Republic of Korea",
  danish: "Denmark",
  dj: "Djibouti",
  dm: "Dominica",
  do: "Dominican Republic",
  ec: "Ecuador",
  eg: "Egypt",
  sv: "El Salvador",
  gq: "Equatorial Guinea",
  er: "Eritrea",
  ee: "Estonia",
  et: "Ethiopia",
  fk: "Falkland Islands (Malvinas)",
  fo: "Faroe Islands",
  fm: "Federated States of Micronesia",
  fj: "Fiji",
  finnish: "Finland",
  french: "France",
  gf: "French Guiana",
  pf: "French Polynesia",
  ga: "Gabon",
  gm: "Gambia",
  ge: "Georgia",
  german: "Germany",
  gh: "Ghana",
  gi: "Gibraltar",
  greek: "Greece",
  gl: "Greenland",
  gd: "Grenada",
  gu: "Guam",
  gt: "Guatemala",
  gg: "Guernsey",
  gn: "Guinea",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  ht: "Haiti",
  va: "Holy See (Vatican City State)",
  hn: "Honduras",
  hong_kong: "Hong Kong",
  hungarian: "Hungary",
  is: "Iceland",
  indian: "India",
  indonesian: "Indonesia",
  iq: "Iraq",
  irish: "Ireland",
  ir: "Islamic Republic of Iran",
  im: "Isle of Man",
  jewish: "Israel",
  italian: "Italy",
  jm: "Jamaica",
  japanese: "Japan",
  je: "Jersey",
  jo: "Jordan",
  kz: "Kazakhstan",
  ke: "Kenya",
  ki: "Kiribati",
  kw: "Kuwait",
  kg: "Kyrgyzstan",
  la: "Lao People's Democratic Republic",
  latvian: "Latvia",
  lb: "Lebanon",
  ls: "Lesotho",
  lr: "Liberia",
  ly: "Libya",
  li: "Liechtenstein",
  lithuanian: "Lithuania",
  lu: "Luxembourg",
  mo: "Macao",
  mg: "Madagascar",
  mw: "Malawi",
  malaysia: "Malaysia",
  mv: "Maldives",
  ml: "Mali",
  mt: "Malta",
  mh: "Marshall Islands",
  mq: "Martinique",
  mr: "Mauritania",
  mu: "Mauritius",
  yt: "Mayotte",
  mexican: "Mexico",
  md: "Moldova",
  mc: "Monaco",
  mn: "Mongolia",
  me: "Montenegro",
  ms: "Montserrat",
  ma: "Morocco",
  mz: "Mozambique",
  mm: "Myanmar",
  na: "Namibia",
  nr: "Nauru",
  np: "Nepal",
  dutch: "Netherlands",
  nc: "New Caledonia",
  new_zealand: "New Zealand",
  ni: "Nicaragua",
  ne: "Niger",
  ng: "Nigeria",
  mp: "Northern Mariana Islands",
  norwegian: "Norway",
  om: "Oman",
  pk: "Pakistan",
  pw: "Palau",
  pa: "Panama",
  pg: "Papua New Guinea",
  py: "Paraguay",
  pe: "Peru",
  philippines: "Philippines",
  polish: "Poland",
  portuguese: "Portugal",
  pr: "Puerto Rico",
  qa: "Qatar",
  south_korea: "Republic of Korea",
  re: "Réunion",
  romanian: "Romania",
  russian: "Russian Federation",
  rw: "Rwanda",
  bl: "Saint Barthélemy",
  sh: "Saint Helena",
  kn: "Saint Kitts and Nevis",
  lc: "Saint Lucia",
  mf: "Saint Martin (French part)",
  pm: "Saint Pierre and Miquelon",
  vc: "Saint Vincent and the Grenadines",
  ws: "Samoa",
  sm: "San Marino",
  st: "Sao Tome and Principe",
  saudiarabian: "Saudi Arabia",
  sn: "Senegal",
  rs: "Serbia",
  sc: "Seychelles",
  sl: "Sierra Leone",
  singapore: "Singapore",
  sx: "Sint Maarten (Dutch part)",
  slovak: "Slovakia",
  slovenian: "Slovenia",
  sb: "Solomon Islands",
  so: "Somalia",
  sa: "South Africa",
  ss: "South Sudan",
  spain: "Spain",
  lk: "Sri Lanka",
  sd: "Sudan",
  sr: "Suriname",
  sz: "Swaziland",
  swedish: "Sweden",
  ch: "Switzerland",
  sy: "Syrian Arab Republic",
  taiwan: "Taiwan",
  tj: "Tajikistan",
  th: "Thailand",
  cd: "The Democratic Republic of the Congo",
  mk: "The Former Yugoslav Republic of Macedonia",
  tl: "Timor-Leste",
  tg: "Togo",
  to: "Tonga",
  tt: "Trinidad and Tobago",
  tn: "Tunisia",
  turkish: "Turkey",
  tm: "Turkmenistan",
  tc: "Turks and Caicos Islands",
  tv: "Tuvalu",
  vi: "U.S. Virgin Islands",
  ug: "Uganda",
  ukrainian: "Ukraine",
  ae: "United Arab Emirates",
  uk: "United Kingdom",
  tz: "United Republic of Tanzania",
  usa: "United States",
  uy: "Uruguay",
  uz: "Uzbekistan",
  vu: "Vanuatu",
  ve: "Venezuela",
  vietnamese: "Vietnam",
  wf: "Wallis and Futuna",
  ye: "Yemen",
  zm: "Zambia",
  zw: "Zimbabwe",
}

type TFreeDaysGoogleImportFormObject = {
  country: string
  holidaysYear: string
}

// it just redirecs to google and get back to the current URL
const FreeDaysGoogleImportForm = ({
  holidaysYear,
}: {
  holidaysYear: string,
}) => {
  const apiClient = useApiClient()

  const formik = useFormik<TFreeDaysGoogleImportFormObject>({
    enableReinitialize: true,
    initialValues: {
      country: 'af',
      holidaysYear: holidaysYear,
    },
    onSubmit: (values) => {
      const apiCall = apiClient.importGoogleAbsences(values)
   },
  })

  const countriesOptions = map(COUNTRIES_MAP, (value, key) => {
    return {
      label: value,
      value: key,
    }
  })

  return (
    <Form onSubmitCapture={formik.handleSubmit} layout="vertical">

      <p>
        When you will click import, we will ask you to authenticate with your Google account in order to allow the import of holidays from the Google service.
      </p>

      <Form.Item
        label="Country"
      >
        <Select
          value={formik.values.country == '' ? undefined : formik.values.country}
          placeholder="Select the country"
          optionFilterProp="label"
          options={countriesOptions}
          showSearch={true}
          onChange={(value) => {
            formik.setFieldValue('country', value)
          }}
          autoFocus={true}
        />
        <ErrorMessage msg={formik.errors.country} />
      </Form.Item>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <GoogleButton
          disabled={!(!!formik.values.country || formik.values.country != "")}
          onClick={() => {
            formik.submitForm()
          }}
        >
          Continue with Google
        </GoogleButton>
      </div>

    </Form>
  )
}

export default FreeDaysGoogleImportForm