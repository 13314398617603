import { Card, Modal, Skeleton, Table, Tag, message } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useState } from "react"
import GenericButtonsList from "components/generic-buttons-list/generic-buttons-list"
import { ArchiveSvg, DeleteSvg, EditSVG, MoreSvg } from "assets/svg/icon"
import { TGenericButton } from "components/generic-buttons-list/generic-buttons-list"
import { useTags } from "hooks/tags"
import TagForm from "./tag-form"
import { usePagePermissions } from "hooks/permissions.hooks"
import useApiClient from "hooks/useApiClient"
import { TApiPagination, TApiTag } from "@timenotes/shared/src/services/api-client/types"
import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"

type TTagsTableItem = TApiTag

export interface TagsTableProps {
  tags: TTagsTableItem[]
  pagination?: TApiPagination
  loading?: boolean
  onPaginationCurrentPageChange?(page: number): void
  refresh(): void
}

const TagsTable = (props: TagsTableProps) => {
  const apiClient = useApiClient()

  const isLoading = !!props.loading
  const [editItemModalVisible, setEditItemModalVisible] = useState(false)
  const [editItem, setEditItem] = useState<TTagsTableItem | undefined>() 
  const { permissions } = usePagePermissions()

  const { deleteTag } = useTags({ fetch: false })

  const handleDelete = (id: string) => {
    deleteTag(id).then((response) => {
      if (response.ok) {
        message.success("Tag deleted!")
        props.refresh()
      } else {
        message.error(getApiErrorMessage(response, 'base'))
      }
    })
  }

  const columns: ColumnsType<TTagsTableItem> = [

    {
      title: 'Name',
      render: (value, record) => (
        <span>{record.name}</span>
      )
    },

    {
      title: 'Color',
      render: (value, record) => (
        <span><Tag style={{ height: '25px', width: '25px' }} color={record.color} /></span>
      )
    },

    {
      title: '',
      render: (value, record) => {
        let buttons: TGenericButton[] = [
          {
            title: '',
            icon: MoreSvg,
            type: 'text',
            disabled: !permissions.manageTags,
            disabledTooltip: 'You have no permissions to manage tags!',
            options: [
              {
                title: 'Edit tag',
                Icon: EditSVG,
                callback: () => {
                  setEditItem(record)
                  setEditItemModalVisible(true)
                }
              },
              // Get archive or unarchive option depending on status
              (record.status == 'active' ? {
                title: 'Archive tag',
                Icon: ArchiveSvg,
                callback: () => {
                  apiClient.archiveTag(record.id).then((response) => {
                    if (response.ok) {
                      message.success('Tag archived!')
                      props.refresh()
                    }
                  })
                }
              } : {
                title: 'Restore tag',
                Icon: ArchiveSvg,
                callback: () => {
                  apiClient.unarchiveTag(record.id).then((response) => {
                    if (response.ok) {
                      message.success('Tag restored!')
                      props.refresh()
                    }
                  })
                }
              }),
              {
                title: 'Delete tag',
                Icon: DeleteSvg,
                callback: () => {
                  Modal.confirm({
                    title: "Are you sure you want to delete this tag?",
                    okText: 'Delete',
                    onOk: () => {
                      handleDelete(record.id)
                    }
                  })
                }
              },
            ],
          }
        ]

        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GenericButtonsList 
              buttons={buttons}
            />
          </div>
        )
      }
    }
  ]

  if (isLoading) {
    return (
      <div>
        <Card>
          <Skeleton />
          <Skeleton />
        </Card>
      </div>
    )
  }

  return (
    <div>
      <Table<TTagsTableItem>
        columns={columns}
        dataSource={props.tags}
        pagination={!!props.pagination && {
          pageSize: props.pagination.pageSize as number,
          current: props.pagination.currentPage as number,
          total: props.pagination.totalCount as number,
          showSizeChanger: false,
          onChange: (page) => {
            props.onPaginationCurrentPageChange && props.onPaginationCurrentPageChange(page)
          }
        }}
      />

      <Modal
        title="Edit tag"
        visible={editItemModalVisible}
        onCancel={() => { setEditItemModalVisible(false) }}
        footer={false}
        destroyOnClose={true}
      >
        <TagForm
          initialValues={editItem as TTagsTableItem}
          onSuccess={(response) => {
            props.refresh()
            setEditItemModalVisible(false)
          }}
        />
      </Modal>
    </div>
  )
}

export default TagsTable