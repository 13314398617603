import { useEffect, useState } from 'react'

import _filter from 'lodash/filter'

import { message } from 'components/base'
import { useHistory, useParams } from 'react-router-dom'
import useApiClient from 'hooks/useApiClient'
import { useTopNavigationLinks } from 'hooks/layout'
import { useProjectQuery } from '@timenotes/shared/src/services/projects/projects.hooks'
import ProjectDetailsHeader from '../shared/components/project-details-header'
import { Button, Card, Col, Popover, Select, Tooltip } from 'antd'
import ProjectGroupsTable from 'pages/projects/team/components/projects-groups-table'
import ProjectsMemberSelect from 'pages/projects/team/components/projects-member-select'
import ProjectsUsersTable from 'pages/projects/team/components/projects-users-table'
import LoadingSkeleton from './components/loading-skeleton'
import { useProjectsUsersQuery } from '@timenotes/shared/src/services/projects-users/queries/use-projects-users-query'
import useUpdateProjectMutation from '@timenotes/shared/src/services/projects/queries/use-update-project-mutation'
import MembersGroupSelect from 'components/timenotes/members-groups/members-group-select'
import useCreateProjectsGroupMutation from '@timenotes/shared/src/services/projects-groups/queries/use-create-projects-group-mutation'
import useProjectsGroupsQuery from '@timenotes/shared/src/services/projects-groups/queries/use-projects-groups-query'
import { TApiMembersGroup, TApiUsersAccount } from 'services/api-client/types'
import useCreateProjectsUserMutation from '@timenotes/shared/src/services/projects-users/queries/use-create-projects-user-mutation'
import { useCurrentProjectsPermissionsQuery } from '@timenotes/shared/src/services/permissions/permissions.hooks'

const ProjectsTeamPage = () => {
  const history = useHistory()

  const apiClient = useApiClient()

  const [addGroupVisible, setAddGroupVisible] = useState(false)

  const params = useParams<{
    projectId: string
  }>()

  const projectId = params.projectId

  const currentProjectsPermissionsQuery = useCurrentProjectsPermissionsQuery({
    params: {
      projectId: projectId
    }
  })
  const currentProjectsPermissions = currentProjectsPermissionsQuery.data?.permissions

  const [pageLoaded, setPageLoaded] = useState(false)

  useTopNavigationLinks([
    {
      label: "← BACK",
      onClick: () => {
        history.push('/projects')
      }
    }
  ])

 
  const projectQuery = useProjectQuery({ projectId: projectId })
  const updateProjectMutation = useUpdateProjectMutation({
    onSuccess: () => {
      message.success("Project updated successfuly!")
    }
  })
  const project = projectQuery.data?.project

  const { query: projectsUsersQuery } = useProjectsUsersQuery({ projectId: projectId })
  const projectsUsers = projectsUsersQuery.data?.projectsUsers

  const {
    query: projectsGroupsQuery
  } = useProjectsGroupsQuery({
    projectId: projectId
  })

  const filterAvailableMembersGroups = (membersGroups: TApiMembersGroup[]) => {
    if (!projectsGroupsQuery.isSuccess) return membersGroups
    const projectsGroups = projectsGroupsQuery.data.projectsGroups

    const alreadyAddedMembersGroupsIds = projectsGroups.map((projectsGroup) => projectsGroup.membersGroup.id)

    return membersGroups.filter((membersGroup) => !alreadyAddedMembersGroupsIds.includes(membersGroup.id))
  }

  const filterAvailableUsersAccounts = (usersAccounts: TApiUsersAccount[]) => {
    if (!projectsUsersQuery.isSuccess) return usersAccounts

    const alreadyAddedUsersAccountsIds = projectsUsersQuery.data.projectsUsers.map((projectsUser) => projectsUser.usersAccount.id)

    return usersAccounts.filter((usersAccount) => !alreadyAddedUsersAccountsIds.includes(usersAccount.id))
  }

  const { mutation: createProjectsGroupMutation } = useCreateProjectsGroupMutation({
    onSuccess: () => {
      message.success('Projects group added!')
    }
  })

  const handleAddProjectsGroup = (membersGroupId: string) => {
    createProjectsGroupMutation.mutate({
      projectId: projectId,
      membersGroupId: membersGroupId
    })
  }

  const { mutation: createProjectsUserMutation } = useCreateProjectsUserMutation({
    onSuccess: () => {
      message.success('Projects user added!')
    }
  })

  const handleAddProjectsUser = (usersAccountId: string) => {
    createProjectsUserMutation.mutate({
      projectId: projectId,
      usersAccountId: usersAccountId
    })
  }

  const handleUpdateVisibilityString = (value: string) => {
    const newVisibility = (value == 'public')

    updateProjectMutation.mutate({
      projectId: projectId,
      visibility: newVisibility
    })
  }

  useEffect(() => {
    if (projectQuery.isSuccess, projectsUsersQuery.isSuccess) {
      setPageLoaded(true)
    }
  }, [projectQuery.isSuccess, projectsUsersQuery])

  return (
    <>
      <ProjectDetailsHeader
        projectId={projectId}
        activeSection="team"
      />

      <div className="page-content">
        {(pageLoaded && project && projectsUsers) ? (
          <div>
              <>
                <Card>
                  <Col span="12">
                    <h2>
                      <label htmlFor="project-access-input">
                        Access to project
                      </label>
                    </h2>

                  <Tooltip title={currentProjectsPermissions?.manageCurrentProject ? "" : "You have no permissions to manage this project!"}>
                    <Select
                      id="project-access-input"
                      value={project.visibility ? 'public' : 'private'}
                      onChange={(value) => { handleUpdateVisibilityString(value) }}
                      disabled={!currentProjectsPermissions?.manageCurrentProject}
                      style={{ width: '100%' }}
                    >
                      <Select.Option value="public"><b>Public</b> - all members of the workspace </Select.Option>
                      <Select.Option value="private"><b>Private</b> - only members added to the project have access</Select.Option>
                    </Select>
                  </Tooltip>
                </Col>
              </Card>

                <Card>
                  <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    <div>
                      <h2>
                        <label>
                          Groups
                        </label>
                      </h2>
                    </div>

                    { currentProjectsPermissions?.manageCurrentProject && (
                      <div style={{ marginRight: '24px' }}>
                        <Popover
                          trigger={"click"}
                          visible={addGroupVisible}
                          destroyTooltipOnHide={true}
                          onVisibleChange={(visible) => {
                            if (!visible) {
                              setAddGroupVisible(false)
                            }
                          }}
                          placement='bottom'
                          content={project.visibility ? null : (
                            <MembersGroupSelect 
                              placeholder={'Add group to the project'}
                              style={{
                                width: '220px'
                              }}
                              open={true}
                              autoFocus={true}
                              showSearch={true}
                              onSelect={handleAddProjectsGroup}
                              filter={filterAvailableMembersGroups}
                            />
                          )}
                        >
                       <Button
                         disabled={project.visibility}
                         onClick={() => setAddGroupVisible(!addGroupVisible)}
                       >
                          Add group
                        </Button>
                      </Popover>
                      </div>
                    )}
                  </div>
                  <div>
                    {(project.visibility) ? (
                      <span><i> Project is public and accessible for all workspace members. </i></span>
                    ) : (
                      <ProjectGroupsTable
                        projectId={projectId}
                      />
                    )}
                  </div>
                </Card>
              </>
            <Card>
              <div style={{ display: 'flex', justifyContent: "space-between" }}>
                <div>
                  <h2>
                    <label>
                      Members
                    </label>
                  </h2>
                </div>

                { currentProjectsPermissions?.manageCurrentProject && (
                  <div style={{ marginRight: '24px' }}>
                    <ProjectsMemberSelect
                      disabled={project.visibility} 
                      filter={filterAvailableUsersAccounts}
                      selectProps={{
                        onChange: (usersAccount) => {
                          handleAddProjectsUser(usersAccount.id)
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              <div>
                <ProjectsUsersTable
                  project={project}
                  projectsUsers={projectsUsers}
                />
              </div>
            </Card>


          </div>
        ) : (
          <LoadingSkeleton />
        )}
      </div>
    </>
  )
}

export default ProjectsTeamPage