import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { Button, DatePicker } from "antd"
import moment, { Moment } from "moment-timezone"
import styled from "styled-components"

interface MonthPickerProps extends Omit<React.ComponentProps<typeof DatePicker.MonthPicker>, 'value'> {
  value: Moment
}

const StyledMonthPicker = styled(DatePicker.MonthPicker)`
  & input {
    text-align: center;
  }
`

const MonthPicker = (props: MonthPickerProps) => {
  return (
    <Button.Group>
      <Button
        onClick={() => {
          if (props.onChange) {
            const newValue = moment(props.value).subtract(1, 'month') 
            props.onChange(newValue, newValue.format(props.format as any))
          }
        }}
      >
        <LeftOutlined style={{ color: '#ababab' }} />
      </Button>
      <StyledMonthPicker
        allowClear={false}
        style={{ borderRadius: 0, width: '150px' }}
        format={"MMMM YYYY"}
        {...props}
      />
      <Button
        onClick={() => { 
          if (props.onChange) {
            const newValue = moment(props.value).add(1, 'month') 
            props.onChange(newValue, newValue.format(props.format as any))
          }
        }}
      ><RightOutlined style={{ color: '#ababab' }} /></Button>
    </Button.Group>
  )
}

export default MonthPicker