import { Button, DatePicker, Modal, ModalProps, message, Form, Input, Select, Checkbox, Row, Col } from "antd"
import { find, map } from "lodash"
import { TApiTag } from "services/api-client/types"
import { useFormik } from "formik"
import ErrorMessage from "components/base/error-message"
import { useEffect, useState } from "react"
import { ICreateTagParams } from "services/api-client/tags-api"
import TagStatusSelect from "./tag-status-select"
import ColorPickerSelect from "components/forms/color-picker-select"
import { COLORS } from "components/layout-components/timenotes-theme"
import useApiClient from "hooks/useApiClient"

export interface IFormObject extends Omit<TApiTag, 'id'> {
  id?: string,
}

export interface TagFormProps {
  initialValues?: Partial<IFormObject>
  onSubmit?(values: IFormObject): void
  onSuccess?(response: TApiTag, addAnother?: boolean): void
}

const TagForm = (props: TagFormProps) => {
  const apiClient = useApiClient()

  const initialValues = {
    name: '',
    color: COLORS['primaryPurple'],
    status: 'active',
    ...props.initialValues,
  }

  const [addAnother, setAddAnother] = useState(false) 

  const formik = useFormik<IFormObject>({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: (values) => {

      const callValues: ICreateTagParams = {
        ...values,
      }

      const apiCall = values.id ? apiClient.updateTag(values.id, callValues) : apiClient.createTag(callValues)

      apiCall.then((response) => {
        if (response.ok) {
          props.onSubmit && props.onSubmit(values)
          props.onSuccess && props.onSuccess(response.tag, addAnother)
          formik.resetForm()
        } else {
          const errors = {
            ...response.errors,
          }

          if (errors.base) {
            message.error(errors.base)
          }

          formik.setErrors(errors || {})
        }
      })
    },
  })

  return (
    <Form onSubmitCapture={formik.handleSubmit} layout="vertical">

      {
        /* // not supported by backend
          <Form.Item
            label="Status"
          >
            <TagStatusSelect
              value={formik.values.status}
              onChange={(value) => {
                formik.setFieldValue('status', value)
              }}
            />
          <ErrorMessage msg={formik.errors.status} />
          </Form.Item>
        */
      }

      <Row>
        <Col span="2">
          <Form.Item
            label="Color"
          >
            <ColorPickerSelect
              value={formik.values.color}
              onChange={(color) => {
                formik.setFieldValue('color', color)
              }}
            />
            <ErrorMessage msg={formik.errors.color} />
          </Form.Item>
        </Col>
        <Col span="20" offset="2">
          <Form.Item
            label="Name"
          >
            <Input
              onPressEnter={formik.submitForm}
              value={formik.values.name}
              onChange={(e) => {
                formik.setFieldValue('name', e.target.value)
              }}
              autoFocus={true}
            />
            <ErrorMessage msg={formik.errors.name} />
          </Form.Item>
        </Col>
      </Row>

      { false && !formik.values.id && (
        <Row>
          <Col span="24">
            <Checkbox 
              value={addAnother}
              onChange={(e) => {
                setAddAnother(e.target.checked)
              }}
            >
              Add another tag after adding this one?
            </Checkbox>
          </Col>
        </Row>
      )}

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="primary"
          onClick={() => {
            formik.submitForm()
          }}
        >
          {formik.values.id ? "Update" : "Create"}
        </Button>
      </div>

    </Form>
  )
}

export default TagForm