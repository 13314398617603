import { useHistory } from 'react-router-dom'
import { useTopNavigationLinks } from 'hooks/layout'
import PageHeader from 'components/layout/page-header'
import ProjectForm from '../shared/components/project-form'
import { useFormik } from 'formik'
import useCreateProjectMutation from '@timenotes/shared/src/services/projects/queries/use-create-project-mutation'
import { useEffect } from 'react'
import { message } from 'antd'
import { useSettings } from 'hooks/settings'
import { TApiProject } from '@timenotes/shared/src/services/api-client/types'

const NewProjectPage = () => {
  const history = useHistory()

  const createProjectMutation = useCreateProjectMutation()
  const { settings, loading: settingsLoading } = useSettings({ fetch: true })

  useTopNavigationLinks([
    {
      label: "← BACK",
      onClick: () => {
        history.push('/projects')
      }
    }
  ])

  useEffect(() => {
    if (createProjectMutation.isSuccess) {
      message.success('Project created successfully!')
      history.push(`/projects/${createProjectMutation.data.project.id}/team`)
    }

    if (createProjectMutation.isError) {
      formik.setErrors(createProjectMutation.error)

      if (createProjectMutation.error.base) {
        message.error(createProjectMutation.error.base)
      }
    }

  }, [createProjectMutation.status])

  const formik = useFormik<Partial<TApiProject>>({
    enableReinitialize: true,
    initialValues: {
      color: '#76C290',
      taskCreator: 'member',
      budgetEnabled: false,
      visibility: settings?.defaultProjectVisibility,
      disallowUserOverbudget: true,
    },
    onSubmit: (values) => {
      createProjectMutation.mutate(values as TApiProject)
    }
  })

  return (
    <div className="page-content">
      <PageHeader
        title="New Project"
     />

      <br />
      <div>
        <ProjectForm
          formik={formik}
        />
      </div>
    </div>
  )
}

export default NewProjectPage