import { isEqual } from "lodash"
import { useEffect, useRef, useState } from "react"

export default function useClassState<T>(defaultState: T) {
  const [state, setFullState] = useState<T>(defaultState)

  const oldStateRef = useRef(state)

  // Implemented for refactoring compatibility of class component setState and useState hook setState partial
  // states updates
  const setState = (newState: Partial<T>) => {
    setFullState((oldState: T) => ({
      ...oldState as T,
      ...newState,
    } as T))
  }

  useEffect(() => {
    if (!isEqual(oldState, state)) {
      oldStateRef.current = state
    }
  }, [state])

  const oldState = oldStateRef.current

  return [state, setState, oldState] as [T, React.Dispatch<React.SetStateAction<Partial<T>>>, T]
}