import { useMutation, useQueryClient } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiGenericError } from "../../timenotes-query/timenotes-query.types"
import { ICreateProjectsUserParams, TApiProjectsUser } from "../../api-client/projects-users-api"
import ApiClient from "../../api-client"
import { cloneDeep } from "lodash"

interface Args {
  projectId: string
  projectsUser: ICreateProjectsUserParams
  projectsUserId?: string
}

interface Result {
  projectsUser: TApiProjectsUser
}

export const useUpdateOrCreateProjectsUserMutation = (options: any = {}) => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  const query = useMutation<Result, TApiGenericError<TApiProjectsUser>, Args>(
    ['projects-users', 'update-or-create'],
    async (args) => {
      const response = args.projectsUserId ?
        await apiClient.updateProjectsUser(args.projectId, args.projectsUserId, args.projectsUser)
        :
        await apiClient.createProjectsUser(args.projectId, args.projectsUser)

      if (!response.ok) {
        throw response.errors
      }

      return {
        projectsUser: response.projectsUser,
      }
    },
    {
      ...options,
      onSuccess: (data: Result, args: Args) => {
        queryClient.invalidateQueries('projects-users', { exact: false })

        if (options.onSuccess) {
          options.onSuccess(data, args)
        }
      },
    },
  )

  return {
    mutation: query
  }
}

export default useUpdateOrCreateProjectsUserMutation