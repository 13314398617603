import moment, { Moment } from 'moment';
import { parseDurationStringToMinutes } from './utils/time-formatters'
import { formatTimeInMinutes } from './utils/time-formatters'

const TIME_FORMAT = 'HH:mm';

const subtractMinutes = (date: Moment, minutes: number): Moment => {
  return date.clone().subtract(minutes, 'minutes');
};

const addMinutes = (date: Moment, minutes: number): Moment => {
  return date.clone().add(minutes, 'minutes');
};

interface TimeLog {
  duration: number;
  previousLastUpdateField: string | null;
  lastUpdateField: string;
  startAt: Moment;
}

interface TimeLogPeriodCalculatorProps {
  startAt: Moment;
  duration: number;
  lastUpdateField: string;
  previousLastUpdateField: string | null;
}

class TimeLogPeriodCalculator {
  public timeLog: TimeLog;

  constructor({ startAt, duration, lastUpdateField, previousLastUpdateField }: TimeLogPeriodCalculatorProps) {

    if (!startAt || duration === undefined) {
      console.error('Wrong attributes passed to TimeLogPeriodCalculator!', startAt, duration);
    }

    const newStartAt = startAt.clone().set({ second: 0 });
    this.timeLog = { duration, previousLastUpdateField, lastUpdateField, startAt: newStartAt };
  }

  getStartOfDay = (): Moment => {
    return this.timeLog.startAt.clone().startOf('day').set({ second: 0 });
  };

  getEndAt = (): Moment => {
    return this.timeLog.startAt.clone().set({ second: 0 }).add(this.timeLog.duration, 'minutes');
  };

  setDuration = (duration: string): string => {
    let stateUpdate = {
      duration: parseDurationStringToMinutes(duration || '')
    } as any

    let refField = this.timeLog.lastUpdateField != 'duration' ? this.timeLog.lastUpdateField : this.timeLog.previousLastUpdateField

    if (!refField) {
      refField = 'startAt'
    }

    if (refField == 'startAt') {
      // Do nothing, just update duration
    } else if (refField == 'endAt') {

      // try subtract
      const newStartAt = subtractMinutes(this.getEndAt(), stateUpdate.duration)

      if (newStartAt < this.getStartOfDay()) {
        // Do nothing, just update duration and keep current startAt date to prevent
        // moving time log to the previous date
      } else {
        // Update start at if it fits the current day
        stateUpdate.startAt = newStartAt
      }

    }

    if (this.timeLog.lastUpdateField !== 'duration') {
      this.timeLog.previousLastUpdateField = this.timeLog.lastUpdateField
      this.timeLog.lastUpdateField = 'duration'
    }

    this.timeLog = { ...this.timeLog, ...stateUpdate }

    return formatTimeInMinutes(stateUpdate.duration)
  }

  setStartAt = (startAt: string): string | false => {
    const startAtMoment = moment(startAt, 'hh:mm')

    if (!startAtMoment.isValid()) {
      //message.error('Wrong format!')
      return false
    }

    const newStartAt = this.timeLog.startAt.clone().set({
      hour: startAtMoment.hour(),
      minute: startAtMoment.minute(),
      second: 0,
    })

    let stateUpdate = {
      startAt: newStartAt,
    } as any

    let refField = this.timeLog.lastUpdateField != 'startAt' ? this.timeLog.lastUpdateField : this.timeLog.previousLastUpdateField

    if (!refField) {
      refField = 'endAt'
    }

    if (refField == 'duration') {
      // Do nothing, just update start at and end at will recalculate to the right value
    } else if (refField == 'endAt') {
      // Recalculate duration based on new start at
      let currentEndAt = this.getEndAt()

      if (newStartAt > currentEndAt) {
        stateUpdate.duration = 0
      } else {
        stateUpdate.duration = currentEndAt.diff(newStartAt, 'minutes')
      }
    }

    if (this.timeLog.lastUpdateField !== 'startAt') {
      this.timeLog.previousLastUpdateField = this.timeLog.lastUpdateField
      this.timeLog.lastUpdateField = 'startAt'
    }

    this.timeLog = { ...this.timeLog, ...stateUpdate }
    console.error("TIME LOG AFTER SET START AT", this.timeLog.startAt.format('YYYY-MM-DD HH:mm'))

    return newStartAt.format(TIME_FORMAT)
  }

  setEndAt = (endAt: string): string | false => {
    const endAtMoment = moment(endAt, 'hh:mm')

    if (!endAtMoment.isValid()) {
      //message.error('Wrong format!')
      return false
    }

    const newEndAt = this.timeLog.startAt.clone().set({
      hour: endAtMoment.hour(),
      minute: endAtMoment.minute(),
      second: 0,
    })

    // EndAt is not part of the state as it is stored as startAt + duration pair
    let stateUpdate = {} as any

    let refField = this.timeLog.lastUpdateField != 'endAt' ? this.timeLog.lastUpdateField : this.timeLog.previousLastUpdateField

    if (!refField) {
      refField = 'startAt'
    }

    if (refField == 'duration') {
      // Recalculate start at as end at - duration

      const newStartAt = subtractMinutes(newEndAt, this.timeLog.duration)

      if (newStartAt < this.getStartOfDay() ) {
        // Update based on start at period, as duration would push out start at to the previous day
        // TODO: Remove duplication
        if (newEndAt < this.timeLog.startAt) {
          stateUpdate.startAt = newEndAt.clone()
          stateUpdate.duration = 0
        } else {
          stateUpdate.duration = newEndAt.diff(this.timeLog.startAt, 'minutes')
        }

      } else {
        stateUpdate.startAt = newStartAt
      }

    } else if (refField == 'startAt') {
      // Recalculate duration as a diff between end at and start at

      // TODO: Remove duplication
      if (newEndAt < this.timeLog.startAt) {
        stateUpdate.startAt = newEndAt.clone()
        stateUpdate.duration = 0
      } else {
        stateUpdate.duration = newEndAt.diff(this.timeLog.startAt, 'minutes')
      }

    }

    if (this.timeLog.lastUpdateField !== 'endAt') {
      this.timeLog.previousLastUpdateField = this.timeLog.lastUpdateField
      this.timeLog.lastUpdateField = 'endAt'
    }

    this.timeLog = { ...this.timeLog, ...stateUpdate }

    return newEndAt.format(TIME_FORMAT)
  }
}

export default TimeLogPeriodCalculator
