import { useEffect, useState } from 'react'
import _filter from 'lodash/filter'
import { useTopNavigationLinks } from 'hooks/layout'
import { useHistory, useParams } from 'react-router-dom'
import useApiClient from 'hooks/useApiClient'
import ProjectDetailsHeader from '../shared/components/project-details-header'
import ProjectTimeCard from './components/project-time-card'
import { Col, Row } from 'antd'
import ProjectBudgetCard from './components/project-budget-card'
import ProjectBillableCard from './components/project-billable-card'
import CumulativeTimeChart from './components/cumulative-time-chart'
import CumulativeBillableChart from './components/cumulative-billable-chart'
import TeamMembersOverview from './components/team-members-overview'
import LoadingSkeleton from './components/loading-skeleton'
import { useCurrentProjectsPermissionsQuery } from '@timenotes/shared/src/services/permissions/permissions.hooks'
import { TApiProject } from '@timenotes/shared/src/services/api-client/types'

const ProjectsInsightsPage = () => {
  const history = useHistory()
  const params = useParams<{
    projectId: string 
  }>()
  const apiClient = useApiClient()

  const [pageLoaded, setPageLoaded] = useState(false)
  const [projectsInsights, setProjectsInsights] = useState<any>({})

  const projectId = params.projectId as string

  const [project, setProject] = useState<TApiProject>()

  useTopNavigationLinks([
    {
      label: "← BACK",
      onClick: () => { history.push("/projects") }
    }
  ])

  useEffect(() => {
    const projectCall = apiClient.getProject(projectId).then((response) => {
      if (response.ok) {
        setProject(response.project)
      }
    })

    const insightsCall = apiClient.getProjectInsights(projectId).then((response) => {
      if (response.ok) {
        setProjectsInsights(response)
      }
    })

    Promise.all([
      projectCall,
      insightsCall
    ]).then(([
      projectResponse,
      insightsResponse
    ]) => {
      setPageLoaded(true)
    })
  }, [])

  const currentProjectsPermissionsQuery = useCurrentProjectsPermissionsQuery({
    params: {
      projectId: projectId
    }
  })

  const permissions = currentProjectsPermissionsQuery.data?.permissions

  const hasProjectBudget = project?.budgetEnabled === true
  const isProjectBillable = project?.billableEnabled === true

  return (
    <>
      <ProjectDetailsHeader
        projectId={projectId}
        activeSection="insights"
      />

      <div className="page-content">
        <div>

          {project && pageLoaded ? (
            <>
              <Row>
                <Col span="8" style={{ padding: '5px' }}>
                  <ProjectTimeCard projectInsights={projectsInsights} />
                </Col>

                {hasProjectBudget && permissions?.manageCurrentProjectBudget && (
                  <Col span="8" style={{ padding: '5px' }}>
                    <ProjectBudgetCard 
                      project={project}
                      projectInsights={projectsInsights} 
                    />
                  </Col>
                )}

                {isProjectBillable && permissions?.manageCurrentProjectBillable && (
                  <Col span="8" style={{ padding: '5px' }}>
                    <ProjectBillableCard projectInsights={projectsInsights} />
                  </Col>
                )}
              </Row>

              {projectsInsights.totals?.duration > 0 && (
                <div>
                  <CumulativeTimeChart 
                    projectInsights={projectsInsights} 
                    project={project}
                  />

                  <br />

                  {project.billableEnabled && permissions?.manageCurrentProjectBillable && (
                    <CumulativeBillableChart 
                      projectInsights={projectsInsights} 
                      project={project}
                    />
                  )}

                  <br />

                  <TeamMembersOverview 
                    projectInsights={projectsInsights}
                    project={project}
                  />
                </div>

              )}
            </>
          ) : (
            <LoadingSkeleton />
          )}
        </div>
      </div>
    </>
  )
}

export default ProjectsInsightsPage