import { useSettingsQuery } from "@timenotes/shared/src/services/settings/queries/use-settings-query"
import { useEffect, useState } from "react"
import { IUpdateSettingsParams, TApiSetting } from "services/api-client/settings-api"
import useApiClient from "./useApiClient"

const DEFAULT_WORKTIME = 480 // 8h in minutes
const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY'
const DEFAULT_TIME_FORMAT_KEY: '12h' | '24h' = '12h'
const DEFAULT_WEEK_START_DAY_NUMBER = 0

export const useDefaultWeekStartDayNumber = () => {
  const { settingsQuery } = useSettingsQuery()

  if (settingsQuery.data) {
    return {
      weekStartDayNumber: settingsQuery.data.settings.weekStartDayNumber || DEFAULT_WEEK_START_DAY_NUMBER,
      loaded: true,
    }
  } else {
    return {
      weekStartDayNumber: DEFAULT_WEEK_START_DAY_NUMBER,
      loaded: false
    }
  }
}

export const useRecommendedWorktime = () => {
  const { settingsQuery } = useSettingsQuery()

  if (settingsQuery.data) {
    return {
      recommendedWorktime: settingsQuery.data.settings.worktime || DEFAULT_WORKTIME,
      loaded: true,
    }
  } else {
    return {
      recommendedWorktime: DEFAULT_WORKTIME,
      loaded: false
    }
  }
}

export const useDefaultCurrencyIso = () => {
  const { settingsQuery } = useSettingsQuery()

  if (settingsQuery.data) {
    return {
      defaultCurrencyIso: settingsQuery.data.settings.defaultBillableRateCurrency,
      loaded: true,
    }
  } else {
    return {
      defaultCurrencyIso: '???',
      loaded: false,
    }
  }
}


export const useDefaultTimeFormat = () => {
  const { settingsQuery } = useSettingsQuery()

  if (settingsQuery.data) {
    const settings = settingsQuery.data.settings
    const timeFormat = settings.timeFormat

    return {
      defaultTimeFormat: timeFormat == '24h' ? 'HH:mm' : 'h:mm A',
      defaultTimeFormatKey: timeFormat,
      loaded: true,
    }
  } else {
    return {
      defaultTimeFormat: 'HH:mm',
      defaultTimeFormatKey: DEFAULT_TIME_FORMAT_KEY,
      loaded: false
    }
  }
}

export const useDefaultDateFormat = () => {
  const { settingsQuery } = useSettingsQuery()

  if (settingsQuery.data) {
    return {
      defaultDateFormat: settingsQuery.data.settings.dateFormat || DEFAULT_DATE_FORMAT,
      loaded: true,
    }
  } else {
    return {
      defaultDateFormat: DEFAULT_DATE_FORMAT,
      loaded: false
    }
  }
}


export const useWorkingWeekDays = () => {
  const { settingsQuery } = useSettingsQuery()

  if (settingsQuery.data) {
    return {
      workingWeekDays: (settingsQuery.data.settings.workingWeekDays || []).map((day) => parseInt(day)),
      loaded: true,
    }
  } else {
    return {
      workingWeekDays: [],
      loaded: false
    }
  }
}

export const useSettings = ({
  fetch,
}: {
  fetch?: boolean
} = {}) => {
  const fetchOnInit = fetch === undefined ? true : fetch

  const { settingsQuery } = useSettingsQuery()

  const fetchSettings = async () => {
    settingsQuery.refetch()
  }

  useEffect(() => {
    if (fetchOnInit) fetchSettings()
  }, [])

  return {
    settings: settingsQuery.data?.settings,
    loading: settingsQuery.isLoading,
    fetchSettings,
  }
}

export const useUpdateSettings = () => {
  const apiClient = useApiClient()
  const [loading, setLoading] = useState(false)

  const { settingsQuery } = useSettingsQuery()

  const updateSettings = async (
    settings: TApiSetting & {
      accountLogo?: IUpdateSettingsParams['accountLogo'],
    }
  ) => {
    const params: IUpdateSettingsParams = {
      ...settings,
    }
    setLoading(true)
    const response = await apiClient.updateSettings(params)

    if (response.ok) {
      settingsQuery.refetch()
    }

    setLoading(false)
    return response
  }

  return {
    loading,
    updateSettings,
  }
}