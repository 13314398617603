import React from 'react'
import styled from 'styled-components'
import _map from 'lodash/map'

import { Card } from 'components/base'
import LogoImg from 'components/base/logo'
import { TApiIntegrationApp } from 'services/api-client/types'
import { AppName } from 'consts'

interface IonAppClick {
  (app: TApiIntegrationApp): void
}

export interface IIntegrationAppListview {
  integrationApps: Array<TApiIntegrationApp>
  onAppClick: IonAppClick
}

const COLORS = {
  primary: '#07c5b7',

  cardHighlight: "#EFEFEF",
}

const AppListFlex = styled.div`
  margin-top: 40px;
  display: flex;
`

const AppCard = styled(Card)`
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.cardHighlight};
  }

  display: flex;
  justify-content: center;
  align-items: center;

  width: 120px;
  height: 120px;
  margin-right: 40px;

  & .card-logo {
    display: flex;
    justify-content: center;
  }

  & .card-app-name{
    margin-top: 14px;
    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
  }

  & img {
    margin: auto;
  }
`

const IntegrationAppItemView = ({integrationApp, onAppClick}: { integrationApp: TApiIntegrationApp, onAppClick: IonAppClick}): JSX.Element => {
  return (
    <AppCard key={integrationApp.service} onClick={() => { onAppClick(integrationApp) }}>
      <div className="card-logo">
        <LogoImg name={integrationApp.service as AppName} size="48px" />
      </div>
      <div className="card-app-name">
        <span>{integrationApp.service}</span>
      </div>
    </AppCard>
  )
}

const IntegrationAppListView = ( { 
  integrationApps,
  onAppClick,
 }: IIntegrationAppListview): JSX.Element => {

  const listOfIntegrationApps: Array<JSX.Element> = _map(integrationApps, (integrationApp) => {
    return React.createElement(IntegrationAppItemView, { integrationApp, onAppClick, key: integrationApp.service })
  })

  return (
    <AppListFlex>
      { listOfIntegrationApps }
    </AppListFlex>
  )
 }

export default IntegrationAppListView