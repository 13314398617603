import { Button, Checkbox, Select, Table, message} from "antd"
import {map} from "lodash"
import {useConfirmImportColumnsFormik} from "services/timenotes/imports/imports.hooks"
import {TApiAccountImport} from "services/timenotes/imports/imports.types"
import ImportFailedRowsModal from "./import-failed-rows-modal"

const ImportColumnsConfigurationTable = ({
  importId,
  accountImport,
}: {
  importId: string,
  accountImport: TApiAccountImport,
}) => {

  const { formik, columnMappingOptions, failedColumns, clearFailedColumns} = useConfirmImportColumnsFormik({ 
    importId: importId,
    accountImport: accountImport,
  })

  const firstRow = Object.values(accountImport.sampleRows[0])
  const mappingRow = {} // empty row which will be rendered as headers below, not as data source directly

  console.error(columnMappingOptions)
  
  // Using first row just to understand the row lenght and iterate throught the items
  const columns: any = map(firstRow, (row, columnIndex) => {
    return {
      render: (text: any, record: any, rowIndex: any) => {

        // Mapping Row Column Render
        if (rowIndex === 0) {
          return <Select 
            dropdownAlign={"top"}
            showSearch={true}
            style={{
              width: '175px',
            }}
            value={formik.values.columnsMapping[columnIndex]}
            onChange={(value) => {
              formik.setFieldValue('columnsMapping', {
                ...formik.values.columnsMapping,
                [columnIndex]: value,
              })
            }}
            options={columnMappingOptions}
          />
        }

        // Sample Row Column Render
        return (
          <span>{record[columnIndex]}</span>
        )
      }
    }
  })

  const dataSource = [mappingRow, ...accountImport.sampleRows]

  return (
    <div className="page-content-long">
      <p>
        <Checkbox 
          checked={formik.values.ignoreHeader}
          onChange={(e) => formik.setFieldValue('ignoreHeader', e.target.checked)}
        >
          Exclude first row from the import data as it is a header.
        </Checkbox>
      </p>

      <Table 
        showHeader={false}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{
          x: true
        }}
      />
      
      <br />

      <Button
        type="primary"
        loading={formik.isSubmitting}
        onClick={formik.submitForm}
      >
        Confirm columns mapping
      </Button>

      <ImportFailedRowsModal
        failedColumns={failedColumns}
        clearFailedColumns={clearFailedColumns}
      />

    </div>
  )
}

export default ImportColumnsConfigurationTable
