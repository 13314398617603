import { TApiResponse, TApiParams, TApiUsersAccount } from "./types"
import BaseApi, { getQueryString } from './base'

export type TApiAlert = {
  id: string
  loggingType: string
  loggingValue: number
  notificationTarget: string
  period: string
  scopeTargetType: string
  scopeTarget: {
    id: string
    name: string
  }
  usersAccounts: Array<TApiUsersAccount>
}

export interface IGetAlertsResponse extends TApiResponse {
  alerts: Array<TApiAlert>
}

export interface ICreateAlertParams {
  loggingType: string
  loggingValue: number
  notificationTarget: string
  period: string
  scopeTargetType: string
  scopeTargetId: string
  usersAccountIds?: Array<string>
}

export interface ICreateAlertResponse extends TApiResponse {
  alert: TApiAlert
}

class AlertsApi extends BaseApi {
  async getAlerts(params: TApiParams): Promise<IGetAlertsResponse> {
    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/alerts${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async createAlert(params: ICreateAlertParams): Promise<ICreateAlertResponse> {
    let response = await fetch(`${this.rootUrl}/alerts`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        alert: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async updateAlert(alertId: string, params: ICreateAlertParams) {
    let response = await fetch(`${this.rootUrl}/alerts/${alertId}`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        alert: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async deleteAlert(alertId: string) {
    let response = await fetch(`${this.rootUrl}/alerts/${alertId}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
}

export default AlertsApi