import { useState } from "react"
import { Card, Modal, Skeleton, message } from "antd"
import Table, { ColumnsType } from "antd/lib/table"
import { DeleteSvg, EditSVG, MoreSvg } from "assets/svg/icon"
import GenericButtonsList, { TGenericButton } from "components/generic-buttons-list/generic-buttons-list"
import { useClients } from "hooks/clients"
import ClientForm from "./client-form"
import { usePagePermissions } from "hooks/permissions.hooks"
import { TApiClient, TApiPagination } from "@timenotes/shared/src/services/api-client/types"
import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"

type TClientsTableItem = TApiClient

export interface ClientsTableProps {
  clients: TClientsTableItem[]
  pagination?: TApiPagination
  loading?: boolean
  onPaginationCurrentPageChange?(page: number): void
  refresh(): void
}

const ClientsTable = (props: ClientsTableProps) => {

  const isLoading = !!props.loading
  const [editItemModalVisible, setEditItemModalVisible] = useState(false)
  const [editItem, setEditItem] = useState<TClientsTableItem | undefined>() 
  const { permissions } = usePagePermissions()

  const { deleteClient } = useClients({ fetch: false })

  const handleDelete = (id: string) => {
    deleteClient(id).then((response) => {
      if (response.ok) {
        message.success("Client deleted!")
        props.refresh()
      } else {
        message.error(getApiErrorMessage(response, 'base'))
      }
    })
  }

  const columns: ColumnsType<TClientsTableItem> = [

    {
      title: 'Name',
      render: (value, record) => (
        <span>{record.name}</span>
      )
    },

    {
      title: '',
      render: (value, record) => {
        let buttons: TGenericButton[] = [
          {
            title: '',
            icon: MoreSvg,
            type: 'text',
            disabled: !permissions.manageClients,
            disabledTooltip: 'You have no permissions to manage clients!',
            options: [
              {
                title: 'Edit client',
                Icon: EditSVG,
                callback: () => {
                  setEditItem(record)
                  setEditItemModalVisible(true)
                }
              },
              {
                title: 'Delete client',
                Icon: DeleteSvg,
                callback: () => {
                  Modal.confirm({
                    title: "Are you sure you want to delete this client?",
                    okText: 'Delete',
                    onOk: () => {
                      handleDelete(record.id)
                    }
                  })
                }
              },
            ],
          }
        ]

        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GenericButtonsList
              buttons={buttons}
            />
          </div>
        )
      }
    }
  ]

  if (isLoading) {
    return (
      <div>
        <Card>
          <Skeleton />
          <Skeleton />
        </Card>
      </div>
    )
  }

  return (
    <div>
      <Table<TClientsTableItem>
        columns={columns}
        dataSource={props.clients}
        pagination={!!props.pagination && {
          pageSize: props.pagination.pageSize as number,
          current: props.pagination.currentPage as number,
          total: props.pagination.totalCount as number,
          showSizeChanger: false,
          onChange: (page) => {
            props.onPaginationCurrentPageChange && props.onPaginationCurrentPageChange(page)
          }
        }}
      />

      <Modal
        title="Edit client"
        visible={editItemModalVisible}
        onCancel={() => { setEditItemModalVisible(false) }}
        footer={false}
        destroyOnClose={true}
      >
        <ClientForm
          initialValues={editItem as TClientsTableItem}
          onSuccess={(response) => {
            props.refresh()
            setEditItemModalVisible(false)
          }}
        />
      </Modal>
    </div>
  )
}

export default ClientsTable