import { TApiResponse, TApiParams } from "./types"
import BaseApi, { getQueryString } from './base'

export type TApiInvitation = {
  id: string
  email: string
  token: string
}

export interface IGetInvitationsResponse extends TApiResponse {
  invitations: Array<TApiInvitation>
}

export interface IBulkCreateInvitationsParams extends TApiParams {
  emails: Array<string>
}

export interface IBulkValidateInvitationsResponse extends TApiResponse {
  usedEmails: Array<string>
}

class InvitationsApi extends BaseApi {
  async getInvitations (params?: TApiParams): Promise<IGetInvitationsResponse> {
    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/invitations${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async bulkValidateInvitations(params: IBulkCreateInvitationsParams): Promise<IBulkValidateInvitationsResponse> {
    let response = await fetch(`${this.rootUrl}/invitations/bulk_validate`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        ...this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async bulkCreateInvitations(params: IBulkCreateInvitationsParams) {
    let response = await fetch(`${this.rootUrl}/invitations/bulk_create`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        ...this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async deleteInvitation(invitationId: string) {
    let response = await fetch(`${this.rootUrl}/invitations/${invitationId}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async resendInvitation (invitationId: string) {
    let response = await fetch(`${this.rootUrl}/invitations/${invitationId}/resend`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
}

export default InvitationsApi
