import { Button, Select, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useConfirmImportUsersFormik } from "services/timenotes/imports/imports.hooks"
import { TApiImportConfirmProjectsResponse, TImportUsersMapping } from "services/timenotes/imports/imports.types"

const ImportUsersConfigurationTable = ({
  importId,
  usersMatchings,
}: {
  importId: string,
  usersMatchings: TApiImportConfirmProjectsResponse['usersMatchings'],
}) => {

  const formik = useConfirmImportUsersFormik({ 
    importId: importId,
    usersMatchings: usersMatchings,
  })

  const columns: ColumnsType<TImportUsersMapping> = [
    {
      title: 'CSV File User Email',
      width: '50%',
      render: (value, record) => (
        <span>{record.email}</span>
      )
    },
    {
      title: 'Timenotes user',
      width: '50%',
      render: (value, record) => {
        return (
          <Select 
            style={{
              width: '100%',
            }}
            value={record.mapping}
            options={record.options}
            onChange={(value) => {
              const newMappings = formik.values.usersMatchings.map((usersMapping: TImportUsersMapping) => {
                if (usersMapping.email == record.email) {
                  return {
                    ...usersMapping,
                    mapping: value,
                  }
                }

                return usersMapping
              })

              formik.setFieldValue('usersMatchings', newMappings)
            }}
          />
        )
      }
    }
  ]

  const dataSource = formik.values.usersMatchings

  return (
    <div className="page-content-long">
      <Table 
        defaultExpandAllRows
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />

      <br />

      <Button
        type="primary"
        loading={formik.isSubmitting}
        onClick={formik.submitForm}
      >
        Confirm users mapping
      </Button>

    </div>
  )
}

export default ImportUsersConfigurationTable
