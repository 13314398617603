const Holiday = (props: React.ComponentProps<"svg">) => (
    <svg width="19px" height="18px" viewBox="0 0 19 18" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props} >
        <g id="Holidays-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="9.2.3-Create-absence-type-" transform="translate(-273.000000, -295.000000)">
                <g id="Group" transform="translate(216.000000, 284.000000)">
                    <g id="Icon-/-application-/-dark-/-holiday" transform="translate(54.333333, 8.000000)">
                        <g id="baseline-beach_access-24px">
                            <path d="M13.127,14.56 L14.557,13.13 L20.997,19.573 L19.57,21 L13.127,14.56 Z M17.42,8.83 L20.28,5.97 C16.33,2.02 9.93,2.01 5.98,5.95 C9.91,4.65 14.29,5.7 17.42,8.83 Z M5.95,5.98 C2.01,9.93 2.02,16.33 5.97,20.28 L8.83,17.42 C5.7,14.29 4.65,9.91 5.95,5.98 Z M5.97,5.96 L5.96,5.97 C5.58,8.98 7.13,12.85 10.26,15.99 L15.99,10.26 C12.86,7.13 8.98,5.58 5.97,5.96 Z" id="Shape" fill="#474D55" fill-rule="nonzero"></path>
                            <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Holiday