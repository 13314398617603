import { TApiResponse, TApiParams, TApiTimeLog, TApiGrouping, TApiProject, TApiTask, TApiUsersAccount} from "./types"
import BaseApi, { getQueryString } from './base'

export type TApiTimeLogsFilters = {
  from?: string
  to?: string
}

export interface IGetTimeLogsParams extends TApiParams {
  filters?: TApiTimeLogsFilters
}

export interface IGetTimeLogsResponse extends TApiResponse {
  timeLogs: Array<TApiTimeLog>
}

export interface ICreateTimeLogParams {
  id?: string
  projectId: string
  duration: number
  date: string
  description?: string
  taskId: string
  isBillable?: boolean
  tags?: Array<string>
  usersAccountId?: string
  startAt?: string
  endAt?: string

  // Added mostly for errors handling from taskId & projectId to project & task
  project?: TApiProject
  task?: TApiTask
  usersAccount?: TApiUsersAccount
}

export interface ICreateTimeLogsResponse extends TApiResponse {
  timeLog: TApiTimeLog
}

class TimeLogsApi extends BaseApi {
  async getTimeLogs (params: IGetTimeLogsParams): Promise<IGetTimeLogsResponse> {
    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/time_logs${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async createTimeLog(params: ICreateTimeLogParams): Promise<ICreateTimeLogsResponse> {
    let response = await fetch(`${this.rootUrl}/time_logs`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        time_log: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async updateTimeLog(timeLogId: string, params: Partial<ICreateTimeLogParams>) {
    let response = await fetch(`${this.rootUrl}/time_logs/${timeLogId}`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        time_log: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async deleteTimeLog (timeLogId: string) {
    let response = await fetch(`${this.rootUrl}/time_logs/${timeLogId}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

}

export default TimeLogsApi
