import { Button, Select, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import ReactJson from "react-json-view"
import { useConfirmImportProjectsFormik } from "services/timenotes/imports/imports.hooks"
import { TImportProjectMapping } from "services/timenotes/imports/imports.types"
import {confirmProjectsPayloadFromProjectsMappings} from "services/timenotes/imports/imports.utils"

const ImportProjectsConfigurationTable = ({
  importId,
  projectsMatchings,
}: {
  importId: string,
  projectsMatchings: any,
}) => {

  const formik = useConfirmImportProjectsFormik({ 
    importId: importId,
    projectsMatchings: projectsMatchings,
  })

  const columns: ColumnsType<TImportProjectMapping> = [
    {
      title: 'CSV File Project Name',
      render: (value, record) => (
        <span>{record.projectName} ({ record.clientName == 'no_client' ? 'No client' : record.clientName})</span>
      )
    },
    {
      title: 'Timenotes project mapping',
      render: (value, record) => {
        return (
          <Select 
            style={{
              width: '100%',
            }}
            value={record.mapping}
            options={record.projectsOptions}
            onChange={(value) => {
              const newMappings = formik.values.projectsMappings.map((projectMapping) => {
                if (projectMapping.key == record.key) {
                  return {
                    ...projectMapping,
                    mapping: value,
                  }
                }

                return projectMapping
              })

              formik.setFieldValue('projectsMappings', newMappings)
            }}
          />
        )
      }
    }
  ]

  const dataSource = formik.values.projectsMappings

  return (
    <div className="page-content-long">
      <Table 
        defaultExpandAllRows
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />

      <br />

      <Button
        type="primary"
        loading={formik.isSubmitting}
        onClick={formik.submitForm}
      >
        Confirm projects mapping
      </Button>

    </div>
  )
}

export default ImportProjectsConfigurationTable
