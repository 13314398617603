import { DatePicker, Radio, Table } from 'antd';
import { ZoomInOutlined } from '@ant-design/icons';
import GenericButtonsList from 'components/generic-buttons-list/generic-buttons-list';
import { useHistory } from 'react-router-dom';
import { ColumnType } from 'antd/lib/table';
import { TApiPeriod } from '@timenotes/shared/src/services/approvals/approvals-types';
import usePeriodUpdateMutation from '@timenotes/shared/src/services/approvals/queries/use-period-update-mutation';
import moment, { Moment } from 'moment-timezone';
import { usePeriodsQuery } from '@timenotes/shared/src/services/approvals/queries/use-periods-query';
import PeriodProgressBar from './period-progress-bar';

type PeriodsTableProps = {
  periodsYear?: Moment
}

const PeriodsTable = (props: PeriodsTableProps) => {
  const history = useHistory()

  const propsParams = props.periodsYear ? {
    filters: {
      year: props.periodsYear.format('YYYY')
    }
  } : undefined

  const {
    periodsQuery,
    periods,
    periodsYear,
    setPeriodsYear,
  } = usePeriodsQuery(propsParams, {
    keepPreviousData: true,
  })

  const periodUpdateMutation = usePeriodUpdateMutation()
  const currentMoment = moment.utc()

  const COLUMNS: ColumnType<TApiPeriod>[] = [
    {
      title: 'Timespan',
      dataIndex: 'timespanString',
      key: 'timespanString',
      render: (_, record) => {
        // Bold to highlight the current period
        if (currentMoment.isBetween(record.startDate, record.endDate)) {
          return <b>{record.timespanString}</b>
        }

        return record.timespanString
      }
    },
    {
      title: 'Open for members',
      dataIndex: 'openForAll',
      key: 'openForAll',
      render: (text, record) => (
        <Radio
          checked={record.status == 'open'}
          onChange={(e) => {
            if (e.target.checked) periodUpdateMutation.mutate({ id: record.id, status: 'open' })
          }}
        />
      ),
    },
    {
      title: 'Open for admins',
      dataIndex: 'openForApprovers',
      key: 'openForApprovers',
      render: (text, record) => (
        <Radio
          checked={record.status == 'approvers'}
          onChange={(e) => {
            if (e.target.checked) periodUpdateMutation.mutate({ id: record.id, status: 'approvers' })
          }}
        />
      )
    },
    {
      title: 'Closed for all',
      dataIndex: 'closedForAll',
      key: 'closedForAll',
      render: (text, record) => (
        <Radio
          checked={record.status == 'closed'}
          onChange={(e) => {
            if (e.target.checked) periodUpdateMutation.mutate({ id: record.id, status: 'closed' })
          }}
        />
      ),
    },
    {
      title: "Approval Status",
      key: 'summary',
      render: (_, record) => (
        <PeriodProgressBar period={record} />
      )
    },
    {
      title: '',
      render: (t, record) => (
        <GenericButtonsList buttons={[{
          title: 'Manage Approvals',
          icon: ZoomInOutlined,
          callback: () => {
            history.push(`/approvals/period-overview?periodDate=${record.startDate.format('YYYY-MM-DD')}`)
          },
        }]}
        />
      )
    }
  ];

  return (
    <>
      {!props.periodsYear /* leftover, if no periods year, add datepicker inside */ && (
        <>
          <DatePicker.YearPicker
            value={periodsYear}
            onChange={(val) => setPeriodsYear(val as Moment)}
            allowClear={false}
          />

          <br />
          <br />
        </>
      )}

      <Table
        loading={periodsQuery.isFetching}
        columns={COLUMNS}
        dataSource={periods}
        pagination={false}
      />
    </>
  );
};

export default PeriodsTable