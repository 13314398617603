import { UseQueryOptions, useQuery } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiParams } from "../../api-client/types"
import { TApiGenericError } from "../../timenotes-query/timenotes-query.types"
import moment from "moment-timezone"
import React, { useEffect, useState } from "react"
import { map } from "lodash"
import { TApiPeriod, TApiPeriodRaw } from "../approvals-types"
import usePeriodModel from "../hooks/use-period-model"

interface Params extends TApiParams {
  filters: {
    year: string // 2024
  }
}

interface Result {
  periods: TApiPeriod[]
}

export const usePeriodsQuery = (params?: Params, options?: UseQueryOptions<any, any>) => {
  const apiClient = useApiClient()

  const {
    parsePeriod,
    serializePeriod,
  } = usePeriodModel()

  const defaultYear = React.useMemo(() => moment(), [params?.filters?.year])

  const paramsYear = params?.filters?.year ? moment(params?.filters?.year) : undefined
  const [periodsYear, setPeriodsYear] = useState(paramsYear || defaultYear)

  useEffect(() => {
    setPeriodsYear(paramsYear || defaultYear)
  }, [paramsYear ? paramsYear.format() : ""])

  const requestParams = {
    filters: {
      year: periodsYear.format('YYYY')
    },
    perPage: params?.perPage || 1000
    /*
    filters: {
      ...params?.filters,
      year: params?.filters?.year || moment().format() // Current year by default
    }
    */
  }

  const query = useQuery<Result, TApiGenericError<any>>(
    ['periods', (requestParams || {})] as const,
    async () => {
      const response = await apiClient.get('/approvals/periods', requestParams)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        periods: response.approvalsPeriods ? map(response.approvalsPeriods, (a: TApiPeriodRaw) => parsePeriod(a)) : []
      }
    },
    {
      ...options
    }
  )

  return {
    periodsQuery: query,

    periods: query.data?.periods || [],

    periodsYear,
    setPeriodsYear, 
  }
}