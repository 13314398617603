import { TApiReportsFilters } from "@timenotes/shared/src/services/api-client/reports-api"
import { Button, Col, Input, Row } from "antd"
import BillableFilter from "components/timenotes/filters/billable-filter"
import ClientsFilter from "components/timenotes/filters/clients-filter"
import ProjectsFilter from "components/timenotes/filters/projects-filter"
import TagsFilter from "components/timenotes/filters/tags-filter"
import TeamFilter from "components/timenotes/filters/team-filter"
import { Formik, Form, FormikProps } from "formik"
import { useFiltersPermissions } from "hooks/permissions.hooks"
import { defaults, isEqual, omit, pick } from "lodash"
import _isEmpty from "lodash/isEmpty"
import AuditFilter, { AUDIT_KEYS } from "./audit-filter"
import { removeUndefinedValues } from "@timenotes/shared/src/services/utils/remove-values"

interface IProps {
  filters: TApiReportsFilters
  onChange(newFilters: TApiReportsFilters): void
  canFilterByTeam?: boolean
}

const TimeLogsFilters = (props: IProps): JSX.Element => {
  const {
    filters,
    onChange
  } = props

  const { 
    canFilterByTeam: canFilterByTeamPermission, 
    canFilterByIsBillable 
  } = useFiltersPermissions()

  // Take it from props and fallback to users account permissions
  const canFilterByTeam = props.canFilterByTeam !== undefined ? props.canFilterByTeam : canFilterByTeamPermission

  const setIfNotEmpty = (values: string[]): string[] | undefined => {
    return _isEmpty(values) ? undefined : values
  }

  const handleClearFilters = (formik: FormikProps<TApiReportsFilters>) => {
    const emptyFilters = {}
    onChange(emptyFilters)
  }

  const pickAuditFromFilters = (filters: TApiReportsFilters): TApiReportsFilters => {
    return pick(filters, AUDIT_KEYS)
  }

  return (
    <Formik<TApiReportsFilters>
      initialValues={filters}
      onSubmit={(newValues) => {
        if (!isEqual(newValues, filters)) {
          onChange(newValues)
        }
      }}
      enableReinitialize={true}
    >

      {(formik) => (
        <Form>
          <Row gutter={16}>
            { canFilterByTeam && (
              <Col span="2">
                <TeamFilter
                  values={{
                    memberHashIds: formik.values.usersAccountHashIds || [],
                    groupHashIds: formik.values.membersGroupHashIds || [],
                  }}
                  onChange={(values) => {
                    formik.setFieldValue('usersAccountHashIds', setIfNotEmpty(values.memberHashIds))
                    formik.setFieldValue('membersGroupHashIds', setIfNotEmpty(values.groupHashIds))
                    formik.submitForm()
                  }}
                  width={"100%"}
                />
              </Col>
            )}

            <Col span="2">
              <ClientsFilter 
                values={formik.values.clientHashIds}
                onChange={(values) => { formik.setFieldValue('clientHashIds', setIfNotEmpty(values)); formik.submitForm() }}
                width={"100%"} 
              />
            </Col>

            <Col span="3">
              <ProjectsFilter 
                filters={
                  formik.values.clientHashIds ? {
                    clientHashIds: formik.values.clientHashIds 
                  } : {}
                }
                values={formik.values.projectHashIds}
                onChange={(values) => { formik.setFieldValue('projectHashIds', setIfNotEmpty(values)); formik.submitForm() }}
                width={"100%"} 
              />
            </Col>

            <Col span="3">
              <Input 
                placeholder="Task" 
                style={{ width: '100%' }}
                allowClear={true}
                value={formik.values.taskName || ""}
                onChange={(e) => {
                  const newValue = e.target.value 
                  formik.setFieldValue('taskName', newValue == "" ? undefined : newValue)
                  if (newValue.length < 3 && newValue != "") {
                    return
                  }
                  formik.submitForm() 
                }}
              />
            </Col>


            <Col span="3">
              <Input 
                placeholder="Description" 
                style={{ width: '100%' }}
                allowClear={true}
                value={formik.values.description || ""}
                onChange={(e) => {
                  const newValue = e.target.value 
                  formik.setFieldValue('description', newValue == "" ? undefined : newValue)
                  if (newValue.length < 3 && newValue != "") {
                    return
                  }
                  formik.submitForm() 
                }}
              />
            </Col>

            <Col span="2">
              <TagsFilter
                values={formik.values.tagIds}
                onChange={(values) => { formik.setFieldValue('tagIds', setIfNotEmpty(values)); formik.submitForm() }}
                width={"100%"} 
              />
            </Col>

            {canFilterByIsBillable && (
              <Col span="4" >
                <BillableFilter 
                  value={formik.values.isBillable === undefined ? undefined : (formik.values.isBillable ? 'billable' : 'non-billable')}
                  onChange={(value) => {
                    let newValue: boolean | undefined

                    switch (value) {
                      case undefined:
                        newValue = undefined
                        break
                      case 'billable':
                        newValue = true
                        break
                      case 'non-billable':
                        newValue = false
                        break
                    }

                    formik.setFieldValue('isBillable', newValue)
                    formik.submitForm()
                  }}
                />
              </Col>
            )}

            <Col span="2">
              <AuditFilter
                value={pickAuditFromFilters(formik.values)}
                onChange={(values) => { 
                  const auditValues = removeUndefinedValues(values)

                  console.error('Values passed', formik.values, auditValues)
                  const newFilters = { 
                    ...auditValues,
                    ...omit(formik.values, AUDIT_KEYS)
                  }

                  formik.setValues(newFilters)
                  formik.submitForm()
                }}
              />
            </Col>


            <Col span={1}>
              { !_isEmpty(formik.values) && (
                <Button 
                  type="text" 
                  style={{ color: 'gray' }} 
                  onClick={() => {
                    handleClearFilters(formik)
                  }}>
                    Χ Clear filters
                </Button>
              )}
            </Col>

          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default TimeLogsFilters