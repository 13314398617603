import { useCurrentUser } from "hooks/current-user"
import { useEffect } from "react"
import { IntercomProvider, useIntercom } from "react-use-intercom"
import { getUsersAccountFullName } from "./users-accounts"

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID

export const TimenotesIntercomProvider = (props: React.PropsWithChildren) => {
  if (!INTERCOM_APP_ID) {
    console.log('INTERCOM APP ID MISSING - Intercom not initiated')
    return <>{props.children}</>
  }

  return <IntercomProvider appId={INTERCOM_APP_ID}>{props.children}</IntercomProvider>
}

// Conditionally make TimenotesIntercomManager blank component if no INTERCOM MANAGER
export const TimenotesIntercomManager = !INTERCOM_APP_ID ? (props: React.PropsWithChildren) => {return <>{props.children}</>} : (props: React.PropsWithChildren) => {
  const { currentUser } = useCurrentUser()
  const { boot, shutdown } = useIntercom()

  if (!currentUser) {
    throw "INTERCOM INITIATED WITH NO CURRENT USER"
  }

  const email = currentUser.user.email
  const name = getUsersAccountFullName(currentUser)
  const userId = currentUser.id
  // const mainUserId = currentUser.user.id

  useEffect(() => {
    // Boot intercom only if user ID exists and it is not a super admin session user id
    if (currentUser.session?.superAdmin) {
      return
    }

    if (userId && currentUser) {
      boot({
        email: email,
        userId: userId,
        name: name,
      })
    } else {
      shutdown()
    }
    // this solves the trigger for changing different accounts && logging in
  }, [userId])

  return <>{props.children}</>
}

export const useIntercomShutdown = !INTERCOM_APP_ID ? () => { return () => {}} : () => {
  const { shutdown } = useIntercom()

  return shutdown
}

export default TimenotesIntercomProvider