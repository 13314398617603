import { IGetReportsDetailedExportParams, TApiExportColumn, TApiReportsExportType } from "@timenotes/shared/src/services/api-client/reports-api"
import { Button, Checkbox, Modal, ModalProps, Radio } from "antd"
import { COLORS } from "components/layout-components/timenotes-theme"
import { Form, Formik } from "formik"
import { toUpper } from "lodash"
import _map from 'lodash/map'

export interface IReportsExportModal {
  exportColumns: TApiExportColumn[]
  exportParams: IGetReportsDetailedExportParams['export']
  modalProps: ModalProps
  exportChart: boolean
  onChangeExportChart(value: boolean): void
  onChange(newExportParams: IGetReportsDetailedExportParams['export']): void
}

export const ExportTypeRadioGroup = ({
  value,
  onChange,
  availableExportTypes,
}: {
  value: TApiReportsExportType,
  onChange(value: TApiReportsExportType): void,
  availableExportTypes?: TApiReportsExportType[]
}) => {
  const exportTypes = availableExportTypes || ['csv', 'pdf', 'xls']

  const buttons = exportTypes.map((eType) => (
    <Radio.Button key={eType} value={eType}>{toUpper(eType)}</Radio.Button>
  ))

  return (
    <Radio.Group 
      value={value} 
      onChange={(event) => { onChange(event.target.value) }}
      style={{ width: '100%' }}
    >
      {buttons}
    </Radio.Group>
  )
}

const prettyColumnName = (name: string): string => {
  const prettyNames = {
    'date': 'Time log date',
    'from': 'Start time',
    'to': 'End time',
    'description': 'Description',
    'duration': 'Duration',
    'duration_decimal': 'Duration decimal',
    'client': 'Client name',
    'project': 'Project name',
    'task': 'Task name',
    'user': 'Team member name',
    'email': 'Team member email',
    'tags_names': 'Tags names',
    'is_billable': 'Billable status',
    'billable': 'Billable amount',
    'costable': 'Cost amount',
    'user_notes': 'User notes',
  }

  let result: string 

  // @ts-ignore
  result = prettyNames[name] ? prettyNames[name] : name

  return result
}

const ReportsExportModal = (props: IReportsExportModal) => {
  return (
    <Modal
      title="Export Report"
      { ...props.modalProps}
    >
      <Formik<IGetReportsDetailedExportParams['export']>
        initialValues={props.exportParams}
        onSubmit={(newValues) => { 
          props.onChange(newValues) 
        }}
      >
        {(formik) => (
          <Form>
            <ExportTypeRadioGroup 
              value={props.exportParams.type}
              onChange={(value) => {
                props.onChange({
                  ...props.exportParams,
                  type: value,
                })
              }}
            />

            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <h5>Export Columns</h5>
              </div>
              <div>
                <Button 
                  type="link"
                  style={{ color: COLORS.linkGray}}
                  onClick={() => {
                    props.onChange({
                      ...props.exportParams,
                      columns: _map(props.exportColumns, (column) => column.name),
                    })
                  }}
                >
                  All
                </Button>
                /
                <Button
                  type="link"
                  style={{ color: COLORS.linkGray}}
                  onClick={() => {
                    props.onChange({
                      ...props.exportParams,
                      columns: [],
                    })
                  }}
                >
                  None
                </Button>
              </div>

            </div>
            <hr />
            <Checkbox.Group 
              style={{ display: 'flex', flexWrap: 'wrap' }}
              value={props.exportParams.columns}
              onChange={(values) => {
                props.onChange({
                  ...props.exportParams,
                  columns: (values as string[]) || [],
                })
              }}
            >
              {_map(props.exportColumns, (exportColumn) => {
                const checked = props.exportParams.columns?.includes(exportColumn.name)

                return (
                  <div 
                    style={{ width: '50%' }} 
                    key={exportColumn.name}>
                    <Checkbox 
                      value={exportColumn.name}
                    >
                    {prettyColumnName(exportColumn.name)}
                    </Checkbox>
                  </div>
                )
              })}
            </Checkbox.Group>

            {props.exportParams.type == "pdf" && (
              <div>
                <hr />
                <div>
                  <Checkbox
                    checked={props.exportChart}
                    onChange={(e) => { 
                      props.onChangeExportChart(e.target.checked)
                    }}
                  >
                    Display chart in export file
                  </Checkbox>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ReportsExportModal