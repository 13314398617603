import { TApiResponse, TApiParams, TApiUsersAccount, TApiAbsenceType, TApiAbsence, TApiPagination } from "./types"
import BaseApi, { getQueryString } from './base'
import { omit } from "lodash"
import { TApiPeriod } from "./reports-api"

// Needed for not v2 api url 
const ROOT_API_URL = process.env.REACT_APP_API_URL || 'http://api.lvh.me'

export type TApiHolidaysAbsencesFilters = {
  absenceTypeIds?: Array<string>
  statuses?: Array<string>
  period: TApiPeriod
}

export interface IGetHolidaysAbsencesParams extends TApiParams {
  filters?: TApiHolidaysAbsencesFilters
}

export interface IGetHolidaysAbsencesResponse extends TApiResponse {
  absences: Array<TApiAbsence>
  meta: {
    pagination: TApiPagination
  }
}

export interface ICreateHolidaysAbsenceResponse extends TApiResponse {
  absence: TApiAbsence
}

export interface ICreateHolidaysAbsenceParams {
  holidaysAbsenceTypeId?: string
  usersAccountId?: string
  fromDate?: string
  toDate?: string
  comment?: string
}

class HolidaysAbsencesApi extends BaseApi {
  async getHolidaysAbsences(params: IGetHolidaysAbsencesParams): Promise<IGetHolidaysAbsencesResponse> {
    let filters: any = {}

    if (params?.filters?.period) {
      filters = { ...params.filters, ...params.filters.period }
      delete filters.period
    }

    const requestParams = {
      filters: filters,
      ...omit(params, ['filters'])
    }

    const query = getQueryString(this.paramsToSnakeCase(requestParams), true)

    let response = await fetch(`${this.rootUrl}/holidays/absences${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  importGoogleAbsences(params: {
    country: string,
    holidaysYear: string,
  }) {
    const requestParams = {
      country: params.country,
      holidays_year: params.holidaysYear,
      action: 'import_holidays',
      session_token: this.accessToken,
      account_id: this.accountId,
      web_redirect_url: window.location.href,
      scope: 'email calendar.events.readonly',
    }

    let queryString = ''
    if (params) {
      queryString = getQueryString(this.paramsToSnakeCase({ ...requestParams }), true)
    }

    window.location = `${ROOT_API_URL}/auth/google_oauth2${queryString}` as any

    return { ok: true, status: 200 }
  }

  async createHolidaysAbsence(params: ICreateHolidaysAbsenceParams): Promise<ICreateHolidaysAbsenceResponse> {
    let response = await fetch(`${this.rootUrl}/holidays/absences`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        holidays_absence: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async updateHolidaysAbsence (absenceId: string, params: ICreateHolidaysAbsenceParams) {
    let response = await fetch(`${this.rootUrl}/holidays/absences/${absenceId}`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        holidays_absence: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async deleteHolidaysAbsence (absenceId: string) {
    let response = await fetch(`${this.rootUrl}/holidays/absences/${absenceId}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
}

export default HolidaysAbsencesApi