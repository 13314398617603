import { useEffect, useState } from 'react'
import { Card, message } from 'components/base'
import PageHeader from 'components/layout/page-header'
import TeamSkeletonPage from './team-skeleton-page'
import TeamMembersFilters, { TTeamMembersListFilters, useTeamMembersFiltersFormik } from 'pages/team/components/team-members-filters'
import TeamMembersTable from 'pages/team/components/team-members-table'
import { IActionItem } from 'components/shared/actions-panel'
import { Input, Modal, ModalFuncProps, Skeleton } from 'antd'
import InvitationsForm from 'pages/team/components/invitations-form'
import InvitationsTable from 'pages/team/components/invitations-table'
import _find from 'lodash/find'
import { LinkOutlined } from '@ant-design/icons'
import { WEB_APP_URL } from 'pages/settings/components/const'
import { useCurrentUser } from 'hooks/current-user'
import { usePagePermissions } from 'hooks/permissions.hooks'
import { useInvitationsListQuery } from '@timenotes/shared/src/services/invitations/queries/use-invitations-list-query'
import { useTeamMembersListQuery } from '@timenotes/shared/src/services/team-members/queries/use-team-members-list-query'
import { omit } from 'lodash'
import TeamMemberModal from 'pages/team/components/team-member-modal'
import useTeamNavigationLinks from './hooks/use-team-navigation-links'
import useApiClient from 'hooks/useApiClient'
import { IUpdateUsersAccountParams, TApiTeamMember } from '@timenotes/shared/src/services/api-client/users-accounts-api'
import { getApiErrorMessage, handleResponseWithMessage } from '@timenotes/shared/src/services/api-client'
import { getQueryString } from '@timenotes/shared/src/services/api-client/base'
import { TApiInvitation } from '@timenotes/shared/src/services/api-client/invitations-api'
import useEnsureUserCanDisplayPage from 'services/pages/use-ensure-user-can-display-page'
import useMembersExport from './hooks/use-members-export'

const MembersListPage = () => {
  const apiClient = useApiClient()

  const [loaded, setLoaded] = useState(false)
  const [isMemberEditVisible, setIsMemberEditVisible] = useState(false)
  const [isInviteMembersVisible, setIsInviteMembersVisible] = useState(false)
  const [invitationEmails, setInvitationEmails] = useState<string[]>([])
  const [displayInvitationsTable, setDisplayInvitationsTable] = useState(false)
  const [editMember, setEditMember] = useState<undefined | TApiTeamMember>()

  const { permissions, loaded: pagePermissionsLoaded } = usePagePermissions()
  const { currentUser, loading: currentUserLoading } = useCurrentUser()

  useEnsureUserCanDisplayPage('team')
  useTeamNavigationLinks()

  const teamMembersFiltersFormik = useTeamMembersFiltersFormik({
    onSubmit: (filters) => {
      applyFilters(filters)
    }
  })

  const { query: invitationsListQuery } = useInvitationsListQuery()
  const { 
    query: teamMembersListQuery,
    setSorting: setTeamMembersListSorting,
    setFilters: setTeamMembersListFilters,
    filters: teamMembersListFilters,
    sorting: teamMembersListSorting,
   } = useTeamMembersListQuery({
    filters: {
      state: 'active',
    }
   })

  const {
    ExportMembersModal,
    membersExportButtonItem,
  } = useMembersExport({
    disabled: !!displayInvitationsTable,
    sorting: teamMembersListSorting,
    filters: teamMembersListFilters,
  })

  useEffect(() => {
    if ( !loaded && pagePermissionsLoaded && !currentUserLoading && currentUser?.id && invitationsListQuery.isFetched && teamMembersListQuery.isFetched) {
      setLoaded(true)
    }
  }, [pagePermissionsLoaded, currentUserLoading, invitationsListQuery.isFetched, teamMembersListQuery.isFetched])

  const sendInvitations = () => {
    apiClient.bulkCreateInvitations({ emails: invitationEmails })
      .then(handleResponseWithMessage("Invitations sent successfully!", { errorField: 'emails' }))
      .then((response) => {
        if (response.ok) {
          invitationsListQuery.refetch()

          setIsInviteMembersVisible(false)
          setInvitationEmails([])
        }
      })
    //
  }

  const updateTeamMember = (params: IUpdateUsersAccountParams) => {
    const response = apiClient.updateUsersAccount(editMember?.id as string, params).then((response) => {
      if (response.ok) {
        message.success('Team member updated successfully!')
        teamMembersListQuery.refetch()

        setIsMemberEditVisible(false)
        setEditMember(undefined)
      }

      return response
    })

    return response
  }

  const handleEditMember = (member: TApiTeamMember) => {
    setIsMemberEditVisible(true)
    setEditMember(member)
  }

  const handleInviteMembers = () => {
    apiClient.bulkValidateInvitations({ emails: invitationEmails })
      .then((response) => {
        if (response.ok) {
          const usedEmails = response.usedEmails || []
          if (usedEmails.length > 0) {
            message.error(`Following emails are already used by users of this workspace: ${usedEmails.join(', ')}`)
          } else {
            // Invitations valid
            sendInvitations()
          }
        } else {
          message.error(`Something went wrong! ${getApiErrorMessage(response, 'emails')}`)
        }
      })
  }

  const showInvitationLink = (invitation: TApiInvitation) => {
    const queryString = getQueryString({
      invitationToken: invitation.token
    }, true)
    const confirmationUrl = `${WEB_APP_URL}/auth/confirm-invitation${queryString}`

    Modal.info({
      title: `Invitation link for ${invitation.email}`,
      width: '800px',
      okText: 'Close',
      content: (
        <div>
          <h4>Below link can be used by invited person to sign up directly to the workspace.</h4>
          <p>Caution! This manual flow will automatically confirms the email address on your responsibility, so make sure you share it with the correct person.</p>
          <Input
            prefix={(
              <LinkOutlined onClick={() => {
                navigator.clipboard.writeText(confirmationUrl)
                message.success('Link copied to clipboard!')
              }} />
            )}
            value={confirmationUrl}
          />
        </div>
      )

    })
  }

  const deleteInvitation = (invitation: TApiInvitation) => {
    apiClient.deleteInvitation(invitation.id)
      .then(handleResponseWithMessage(`Invitation for ${invitation.email} deleted successfully!`)).then((response) => {
        if (response.ok) {
          invitationsListQuery.refetch()
        }
      })
  }

  const resendInvitation = (invitation: TApiInvitation) => {
    apiClient.resendInvitation(invitation.id)
      .then(handleResponseWithMessage(`Invitation for ${invitation.email} resent successfully!`))
  }

  const getInvitationsActions = (invitation: TApiInvitation) => {
    const actions: IActionItem[] = []

    if (canManageTeamSection) {
      actions.push({
        title: 'Resend invitation',
        icon: 'mail',
        action: () => { 
          resendInvitation(invitation) 
        }
      })

      actions.push({
        title: 'Copy invitation link',
        icon: 'activate',
        action: () => { 
          showInvitationLink(invitation) 
        }
      })

      actions.push({
        title: 'Delete invitation',
        icon: 'delete',
        action: () => { 
          deleteInvitation(invitation) 
        }
      })

    } else {
      actions.push(
        getNoPermissionsAction()
      )
    }

    return actions
  }

  const canManageTeamSection = permissions.manageMembersGroups

  const getTeamMemberActions = (teamMember: TApiTeamMember) => {
    const actions: IActionItem[] = []

    const isActiveUser = teamMember.active

    // Permissions
    // is Owner
    const canTransferOwnership = permissions.transferAccountOwnership

    // "is Admin"
    const canManageTeamSection =  permissions.manageMembersGroups
    const canActivateUsers = canManageTeamSection
    const canEditProfile = canManageTeamSection
    const canDeleteUser = canManageTeamSection

    if (canEditProfile) {
      actions.push({
        title: 'Edit profile',
        action: () => {
          handleEditMember(teamMember)
        },
        icon: 'edit',
      })
    }

    // allow ownership transfer by permissions and only not to owner himself 
    if (canTransferOwnership && currentUser?.id !== teamMember.id) {
      actions.push({
        title: 'Transfer ownership',
        confirm: {
          title: 'Are you sure you want to transfer the workspace ownership to this team member?',
          content: `This action can only be reversed by the new owner. Your role will be downgraded to the 'admin' role afterwards.`,
          okText: 'Transfer!',
          cancelText: 'Cancel',
        },

        action: () => {
          apiClient.transferOwnershipUsersAccount(currentUser.id, teamMember.id)
            .then(handleResponseWithMessage(`Workspace ownership has been transferd to ${teamMember.firstName} ${teamMember.lastName}`))
            .then((response) => {
              window.location.reload()
            })
          //
        },
        icon: 'secure',
      })
    }

    if (canActivateUsers && isActiveUser) {
      actions.push({
        title: 'Deactivate',
        action: () => {
          apiClient.deactivateUsersAccount(teamMember.id)
            .then(handleResponseWithMessage('Team member deactivated successfully!'))
            .then((response) => {
              teamMembersListQuery.refetch()
            })
          //
        },
        icon: 'deactivate',
      })
    }

    if (canActivateUsers && !isActiveUser) {
      actions.push({
        title: 'Activate',
        action: () => {
          apiClient.activateUsersAccount(teamMember.id)
            .then(handleResponseWithMessage("Team member activated again successfully!"))
            .then((response) => { 
              teamMembersListQuery.refetch()
            })
          //
        },
        icon: 'activate',
      })
    }

    if (canDeleteUser) {
      actions.push({
        title: 'Delete',
        confirm: {
          title: 'Are you sure you want to delete this team member?',
          content: `This action can not be reversed and all the time logs of this user will be permamently deleted! ${isActiveUser ? "Please consider deactivating this user instead." : ""}`,
          okText: 'Delete',
          cancelText: 'Cancel',
        },
        action: () => {
          apiClient.deleteUsersAccount(teamMember.id)
            .then(handleResponseWithMessage("Team member has been deleted!"))
            .then((response) => { 
              teamMembersListQuery.refetch()
            })
          //
        },
        icon: 'delete',
      })
    }

    if (actions.length == 0) {
      actions.push(getNoPermissionsAction())
    }

    return actions
  }

  const getNoPermissionsAction = () => {
    return {
      title: 'You have no permissions to manage the team section! (Read more)',
      confirm: {
        title: 'You have no permissions to manage the team section',
        content: 'If you are expected to be able to manage the users profiles and sensitive data in your team and to invite / delete the team members, ask other admin user of the workspace to grant you the admin role.'
      },
      action: () => { },
      icon: 'secure',
    } as IActionItem
  }

  const applyFilters = (filters: TTeamMembersListFilters) => {
    setTeamMembersListFilters(omit(filters, 'displayInvitations'))
    setDisplayInvitationsTable(filters.displayInvitations)
  }

  const displayMembersTable = !displayInvitationsTable

  if (!loaded) {
    return <TeamSkeletonPage />
  }

  return (
    <div className="page-content">
      <PageHeader
        title="Members"
        buttons={[
          {
            title: "Invite members",
            type: 'primary',
            callback: () => {
              if (canManageTeamSection) {
                setIsInviteMembersVisible(true)
              } else {
                Modal.confirm(getNoPermissionsAction().confirm as ModalFuncProps)
              }
            },
          },
          membersExportButtonItem,
       ]}
      />

      <Card>
        <TeamMembersFilters 
          formik={teamMembersFiltersFormik}
        />
     </Card>

      {displayMembersTable && teamMembersListQuery.data && (
        <>
          {loaded ? (
            <TeamMembersTable
              loading={teamMembersListQuery.isLoading}
              teamMembers={teamMembersListQuery.data.teamMembers}
              pagination={teamMembersListQuery.data.pagination}
              setSorting={setTeamMembersListSorting}
              getTeamMembersActions={getTeamMemberActions}
              sensitiveVisible={canManageTeamSection}
              onClick={(teamMember) => {
                if (canManageTeamSection) {
                  return handleEditMember(teamMember)
                } else {
                  return Modal.confirm(getTeamMemberActions(teamMember)[0].confirm as ModalFuncProps)
                }
              }}
            />
          ) : (
            <Card>
              <Skeleton />
            </Card>
          )
          }
        </>
      )}

      {displayInvitationsTable && (
        <>
          {(loaded && invitationsListQuery.isSuccess) ? (
            <InvitationsTable
              invitations={invitationsListQuery.data.invitations}
              getInvitationsActions={getInvitationsActions}
              resendInvitation={resendInvitation}
            />
          ) : (
            <Card>
              <Skeleton />
            </Card>
          )}
        </>
      )}

      <Modal
        destroyOnClose={true}
        visible={isInviteMembersVisible}
        onCancel={() => { setIsInviteMembersVisible(false) }}
        onOk={() => { handleInviteMembers() }}
        okText="Invite"
        title="Invite members"
      >
        <InvitationsForm onChange={
          (invitationEmails) => { setInvitationEmails(invitationEmails) }}
        />
      </Modal>

      <TeamMemberModal
        destroyOnClose={true}
        teamMember={editMember}
        visible={isMemberEditVisible}
        onCancel={() => { 
          setIsMemberEditVisible(false)
          setEditMember(undefined)
        }}
        onSubmit={updateTeamMember}
      />

      {ExportMembersModal}
    </div>
  )
}

export default MembersListPage