import { useApiClient } from "@timenotes/shared/src/services/api-client/api-client.hooks"
import { TApiGenericError } from "@timenotes/shared/src/services/timenotes-query/timenotes-query.types"
import { map } from "lodash"
import { UseQueryOptions, useQuery } from "react-query"
import { TApiIntegrationAccountsProject } from "services/api-client/types"

interface IParams {
  integrationAccountId?: string
}

interface IResult {
  integrationAccountsProjects: TApiIntegrationAccountsProject[]
}

interface IOptions extends UseQueryOptions<IResult, TApiGenericError<null>> {}

export const useIntegrationAccountsExternalProjectsQuery = (params: IParams = {}, options?: IOptions) => {
  const apiClient = useApiClient()

  const requestParams = {
    integrationAccountId: params.integrationAccountId || "", 
  }

  const query = useQuery<IResult, TApiGenericError<null>>(
    ['integration-accounts-projects-external', requestParams],
    async () => {
      const response = await apiClient.getAllIntegrationAccountProjects({ params: requestParams })

      if (!response.ok) { throw response.errors }

      return {
        integrationAccountsProjects: map(response.projects, (project) => {
          return {
            ...project,
            connected: !!project.connected
          }
        }),
      }
    },
    {
      ...options
    }
  )

  return { 
    query,
  }
}