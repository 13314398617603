import { TApiResponse, TApiParams } from "./types"
import BaseApi, { getQueryString } from './base'

export interface IGetStorageParams extends TApiParams {
  target?: string // 'all', 'user', 'users_account'
  keys?: Array<string>
}

export interface IUpdateStorageParams extends TApiParams {
  target: null | string // 'user', 'users_account'
  data: null | object
}

class StoragesApi extends BaseApi {
  async getStorage (params: IGetStorageParams): Promise<TApiResponse> {
    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/storage${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async updateStorage (params: IUpdateStorageParams) {
    let response = await fetch(`${this.rootUrl}/storage`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        storage: this.paramsToSnakeCase(params)
      }),
    })

    return await this.getResponseJson(response)
  }
}

export default StoragesApi