const Calendar = (props: React.ComponentProps<"svg">) => (
    <svg width="19px" height="20px" viewBox="0 0 19 20" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props} >
        <g id="Holidays-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="9.2.3-Create-absence-type-" transform="translate(-273.000000, -389.000000)">
                <g id="Group" transform="translate(216.000000, 284.000000)">
                    <g id="icon-/-application-/-dark-/-icon-1-copy-9" transform="translate(54.333333, 104.000000)">
                        <g id="baseline-event_note-24px">
                            <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                            <path d="M17,10 L7,10 L7,12 L17,12 L17,10 Z M19,3 L18,3 L18,1 L16,1 L16,3 L8,3 L8,1 L6,1 L6,3 L5,3 C3.89,3 3.01,3.9 3.01,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,8 L19,8 L19,19 Z M14,14 L7,14 L7,16 L14,16 L14,14 Z" id="Shape" fill="#474D55" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Calendar