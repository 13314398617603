import React from 'react'
import { Card, Row, Col, Spin } from "antd";
import { useHistory } from 'react-router-dom'
import { backgroundStyle } from '../authentication/styles'

const AuthLayout = (props: any) => {
  return (
    <div className="h-100" style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card>
              { props.loading ? (
                <div
                  style={{
                    marginTop: '30px',
                    marginBottom: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Spin />
                </div>
              ) : (
                <>
                  {props.children}
                </>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AuthLayout
