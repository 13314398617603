import { useEffect, useState } from "react"
import { API_HIDDEN_PAGES, getHiddenPagePermissionName, storeGetHiddenPages, storeSetHiddenPages } from "./hidden-pages.utils"
import { usePermissionsQuery } from "../permissions/permissions.hooks"

// Returns user hidden pages based on his permissions
// Until permissions are not fetched, it returns previous call
// cached permissiosn from local storage and updates this cache
// after next cache. By default returns all the pages as hidden.
export default function useHiddenUsersPages() {
  const hiddenPagesArray = storeGetHiddenPages()

  const permissionsQuery = usePermissionsQuery()
  const [hiddenUsersPages, setHiddenUsersPages] = useState(hiddenPagesArray)

  useEffect(() => {
    if (permissionsQuery.isFetched && permissionsQuery.isSuccess) {
      let PAGES_ONE_CAN_NOT_SEE = API_HIDDEN_PAGES.filter((page) => {
        const permissionName = getHiddenPagePermissionName(page)

        return !permissionsQuery.data.permissions[permissionName]
      })

      storeSetHiddenPages(PAGES_ONE_CAN_NOT_SEE)
      setHiddenUsersPages(PAGES_ONE_CAN_NOT_SEE)
    }
  }, [permissionsQuery.isFetched, permissionsQuery.isSuccess])

  return hiddenUsersPages
}