import { useTimeLogFormik } from "@timenotes/shared/src/services/time-logs/time-logs.hooks"
import { Empty, Row, Table, message } from "antd"
import { GenericTableParams } from "components/generic-table/generic-table"
import { useState } from "react"
import useTimeLogsColumns from "./time-logs-columns"
import styled from "styled-components"
import GenericTable2 from "components/generic-table/generic-table2"
import { formatTimeInMinutes } from "@timenotes/shared/src/services/time-logs/utils/time-formatters"
import { sumBy } from "lodash"
import { useBulkDeleteTimeLogs } from "./bulks/bulk-delete-time-logs"
import { useBulkCopyTimeLogs } from "./bulks/bulk-copy-time-logs"
import { useBulkEditTimeLogs } from "./bulks/bulk-edit-time-logs"
import { TApiTimeLog } from "@timenotes/shared/src/services/api-client/types"

type TTimeLogFormikReturnType = ReturnType<typeof useTimeLogFormik>
type TRecord = TTimeLogFormikReturnType['formik']
type TOnSuccessCallback = (timeLog: TApiTimeLog) => void

export type TimeLogsTableProps = React.ComponentProps<typeof Table<TApiTimeLog>> & {
  noQueryMode?: boolean
  skipTracker?: boolean
  refetch?(): void

  onChange?(params: GenericTableParams): void
  customHeader?(): React.ReactNode
  tableParams?: GenericTableParams
}

const TableWrapper = styled.div`
  td.ant-table-cell {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 9px;
    padding-right: 9px;
  }

  td.ant-table-cell.tags-column {
    width: 80px;
  }
`

const TimeLogsTable = (props: TimeLogsTableProps) => {
  const timeLogs = props.dataSource
  const { columns, cleanEdit } = useTimeLogsColumns({
    noQueryMode: props.noQueryMode,
    skipTracker: props.skipTracker,
    refetch: props.refetch,
  })

  const [internalTableParams, setInternalTableParams] = useState<GenericTableParams>({
    pagination: null,
    sorting: null,
    selectedAll: false,
    selectedRowKeys: [],
  })

  const tableParams = props.tableParams || internalTableParams
  const setTableParams = props.onChange || setInternalTableParams

  const resetBulkSelect = () => {
    cleanEdit()

    if (!!props.noQueryMode && props.refetch) {
      props.refetch()
    }

    setTableParams({
      ...tableParams,
      selectedAll: false,
      selectedRowKeys: [],
    })
  }

  const bulkDeleteModal = useBulkDeleteTimeLogs({
    ids: tableParams.selectedRowKeys,
    onOk: resetBulkSelect,
  })

  const bulkCopyModal = useBulkCopyTimeLogs({
    ids: tableParams.selectedRowKeys,
    onOk: resetBulkSelect,
  })

  const bulkUpdateModal = useBulkEditTimeLogs({
    ids: tableParams.selectedRowKeys,
    onOk: resetBulkSelect,
  })

  return (
    <TableWrapper>
      <GenericTable2<TApiTimeLog>
        tableProps={{
          loading: props.loading,
          columns: columns,
          dataSource: timeLogs,
          pagination: false,
          locale: { emptyText: (<Empty description="No time entries on that day." image={Empty.PRESENTED_IMAGE_SIMPLE} />) },
          rowKey: (record) => record.id,
        }}

        customHeader={props.customHeader ? props.customHeader : () => {
          return (
            <div style={{}}>
              <Row justify="end" align="right">
                <span className="timesheet-table-total" >Total: <b>{formatTimeInMinutes(sumBy(timeLogs, 'duration'))}</b></span>
              </Row>
            </div>
          )
        }}

        tableParams={tableParams}
        onChange={setTableParams}

        bulkButtons={[
          {
            title: 'Bulk edit',
            size: 'small',
            callback: () => {
              bulkUpdateModal.setOpen(true)
            }
          },
          {
            title: 'Copy to other day(s)',
            size: 'small',
            callback: () => {
              bulkCopyModal.setOpen(true)
            },
          }, 
          {
            title: 'Delete all',
            danger: true,
            size: 'small',
            callback: () => {
              bulkDeleteModal.setOpen(true)
            }
          }
        ]}

        rowColorSelector={(record) => {
          return record.project?.color || '#E5E5EB'
        }}


      />

      {bulkDeleteModal.modal}
      {bulkCopyModal.modal}
      {bulkUpdateModal.modal}
    </TableWrapper>
  )
}

export default TimeLogsTable