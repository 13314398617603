import { useSettingsQuery } from "./use-settings-query"
import { DEFAULT_SETTINGS } from "../settings-consts"

// Difference to query is that it always returns the settings
// with fallback to defaults if no settings present somehow
// which should basically never be the case!
export default function useSettings() {
  const { settingsQuery } = useSettingsQuery()

  const settings = settingsQuery.data?.settings || DEFAULT_SETTINGS
  return {
    ...settings,
  }
}