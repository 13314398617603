import { BrowserRouter as Router } from 'react-router-dom'
import Views from './views'
import { Route, Switch } from 'react-router-dom'
import { ThemeSwitcherProvider, useThemeSwitcher } from "react-css-theme-switcher"
import { THEME_CONFIG } from './consts/theme-config'

import history from './history'
import { ThemeConfigProvider } from 'services/layout/layout.hooks'
import TimenotesIntercomProvider from 'services/timenotes-intercom-provider'
import { LayoutContextProvider } from 'hooks/layout'
import { TimenotesQueryProvider } from '@timenotes/shared/src/services/timenotes-query/timenotes-query'
import { TimenotesApiClientProvider } from '@timenotes/shared/src/services/api-client/api-client.hooks'
import Loading from 'components/shared-components/Loading'

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const API_URL = process.env.REACT_APP_API_URL || 'http://api.lvh.me'

function LoadingWrapper(props) {
  const themeSwitcher = useThemeSwitcher()

  if (themeSwitcher.status !== 'loaded')
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loading />
      </div>
    )

  return <>{props.children}</>
} 

function App() {
  const apiRootUrl = `${API_URL}/v2`

  return (
    <div className="App">
      <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
        <LoadingWrapper>
          <TimenotesApiClientProvider
            rootUrl={apiRootUrl}
            clientName='web'
          >
            <TimenotesQueryProvider>
              <LayoutContextProvider>
                <ThemeConfigProvider>
                  <TimenotesIntercomProvider>
                    <Router history={history}>
                      <Switch>
                        <Route path="/" component={Views} />
                      </Switch>
                    </Router>
                  </TimenotesIntercomProvider>
                </ThemeConfigProvider>
              </LayoutContextProvider>
            </TimenotesQueryProvider>
          </TimenotesApiClientProvider>
        </LoadingWrapper>
      </ThemeSwitcherProvider>
    </div>
  );
}


export default App
