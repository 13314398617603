import { Button, Form, Input } from "antd"
import { TApiClient } from "services/api-client/types"
import { useFormik } from "formik"
import ErrorMessage from "components/base/error-message"
import { ICreateClientParams } from "services/api-client/clients-api"
import useApiClient from "hooks/useApiClient"

export interface IFormObject extends Omit<TApiClient, 'id' | 'hashId' > {
  id?: string,
}

export interface ClientFormProps {
  initialValues?: IFormObject
  onSubmit?(values: IFormObject): void
  onSuccess?(response: TApiClient): void
}

const ClientForm = (props: ClientFormProps) => {
  const apiClient = useApiClient()

  const initialValues = props.initialValues || {
    name: '',
  }

  const formik = useFormik<IFormObject>({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: (values, formikHelpers) => {

      const callValues: ICreateClientParams = {
        ...values,
      }

      const apiCall = values.id ? apiClient.updateClient(values.id, callValues) : apiClient.createClient(callValues)

      apiCall.then((response) => {
        if (response.ok) {
          props.onSubmit && props.onSubmit(values)
          props.onSuccess && props.onSuccess(response)
          formik.resetForm()
        } else {
          const errors = {
            ...response.errors,
          }

          formik.setErrors(errors || {})
        }
      })

      apiCall.finally(() => {
        formikHelpers.setSubmitting(false)
      })
    },
  })

  return (
    <Form onSubmitCapture={formik.handleSubmit} layout="vertical">
      <Form.Item
        label="Name"
      >
        <Input
          value={formik.values.name}
          onChange={(e) => {
            formik.setFieldValue('name', e.target.value)
          }}
          autoFocus={true}
        />
        <ErrorMessage msg={formik.errors.name} />
      </Form.Item>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          loading={formik.isSubmitting}
          type="primary"
          onClick={() => {
            formik.submitForm()
          }}
        >
          {formik.values.id ? "Update" : "Create"}
        </Button>
      </div>

    </Form>
  )
}

export default ClientForm