import styled from 'styled-components'
import { Switch } from 'components/base'

const ExplainerText = styled.span`
  margin-left: 14px;
`

interface IView {
  value: boolean
  onChange(val: boolean): void
}

const AutoConnectNewProjectsSwitchView = ({ value, onChange }: IView ) => {

  return (
    <div>
      <Switch checked={ value } onChange={(checked) => { onChange(checked) }} />

      <ExplainerText>
        Auto connect all new projects from the workspace 
      </ExplainerText>
    </div>
  )
}

export default AutoConnectNewProjectsSwitchView