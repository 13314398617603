import { AutoComplete, Input, Select, message } from "antd"
import { toDurationString } from "components/forms/duration-input"
import { defer } from "lodash"
import { parse } from "path"
import { forwardRef, useEffect, useState } from "react"
import { parseDurationStringToMinutes } from "utils/parseDurationStringToMinutes"

interface IDurationInput extends React.ComponentProps<typeof Select<number>>{
}

const DURATIONS_HELPER: number[] = [
  5,
  10,
  15,
  30,
  45,
  60,
  90,
  180,
  240,
]

const DurationInput = forwardRef((props: IDurationInput, ref) => {
  const [text, setText] = useState<string | undefined>(props.value ? toDurationString(props.value) : undefined)
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const [isFocus, setIsFocus] = useState<boolean>(false)

  const setTextBasedOnValue = () => {
    if (!props.value || props.value == 0) {
      setText("")
    } else {
      setText(toDurationString(props.value || 0))
    }
  }

  useEffect(() => {
    if (!isFocus) {
      setTextBasedOnValue()
    }
  }, [props.value])

  const triggerOnChangeWithTextValue = (textValue: string) => {
    if (props.onChange) {
      props.onChange(parseDurationStringToMinutes(textValue) || 0, undefined as any)
    }
  }

  const handleSearch = (value: string | undefined) => {
    // If user is not typing, we ignore Antd Select Search modification to keep the input value all the time
    // and prevent Antd to clear it
    let sanitizedValue = `${value}`

    // If the string contains ":", remove any occurrence of "m" or "h".
    if (sanitizedValue.includes(':')) {
      sanitizedValue = sanitizedValue.replace(/[mh]/g, '');
    }
    // If the string contains "m" or "h", remove any occurrence of ":".
    else if (/[mh]/.test(sanitizedValue)) {
      sanitizedValue = sanitizedValue.replace(/:/g, '');
    }

    // allow only one h and one m and whitespaces
    sanitizedValue = sanitizedValue.replace(/(^[^0-9mh:])|[^0-9mh:\s]|(m.*m)|(h.*h)|(:.*:)/g, '');


    // Check for the number of spaces in sanitizedValue
    const spaceMatches = sanitizedValue.match(/\s/g);
    const numberOfSpaces = spaceMatches ? spaceMatches.length : 0;

    // Use sanitizedValue for further operations and allow value only if it was not changed by all the validation rules before
    if (value == sanitizedValue && numberOfSpaces <= 1) {
      setText(sanitizedValue)
      triggerOnChangeWithTextValue(sanitizedValue)
    }
  }

  const helperOptions = DURATIONS_HELPER.map((minutes) => {
    return {
      label: toDurationString(minutes),
      value: toDurationString(minutes),
      search: `${toDurationString(minutes)}`
    }
  })

  return (
    <AutoComplete
      placeholder="0h 0m"
      options={helperOptions}
      filterOption={true}
      optionFilterProp="search"
      searchValue={text}
      onFocus={(e) => {
        if (props.onFocus) props.onFocus(e)
        setIsFocus(true)
      }}
      onBlur={(e) => {
        setIsFocus(false)
        if (props.onChange) {
          triggerOnChangeWithTextValue(text || "")
        }
        setTextBasedOnValue()
        if (props.onBlur) props.onBlur(e)
      }}
      value={text}
      onChange={handleSearch}
      onKeyDown={(e) => {
        if (props.onKeyDown) props.onKeyDown(e)
      }}
      disabled={props.disabled}
      style={props.style}
    >
      <Input
        ref={ref}
        className={props.className}
        bordered={props.bordered}
      />
    </AutoComplete>
  )
})

export default DurationInput