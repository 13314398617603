import useBulkUpdateTimeLogsMutation from "@timenotes/shared/src/services/time-logs/queries/use-bulk-update-time-logs-mutation"
import { Badge, Checkbox, DatePicker, Modal, ModalProps, message } from "antd"
import Flex from "components/shared-components/Flex"
import { useDefaultDateFormat } from "hooks/settings"
import moment from "moment-timezone"
import { useEffect, useState } from "react"
import useQueryGenericErrorMessage from "services/utils/hooks/use-query-generic-error-message"
import React from 'react'
import { Input, Tooltip } from 'antd'
import useBulkUpdateTimeLogsFormik from "@timenotes/shared/src/services/time-logs/hooks/use-bulk-update-time-logs-formik"
import ProjectSelect from "components/timenotes/projects/project-select"
import TaskSelect from "components/timenotes/tasks/task-select"
import TagsSelect from "components/timenotes/tags/tags-select"
import IsBillableSelect from "components/timenotes/timer/is-billable-select"
import DurationInput from "../duration-input"
import useNoInitialEffect from "hooks/useNoInitialEffect"
import useFormikGenericErrorMessage from "services/utils/hooks/use-formik-generic-error-message"
import { isEmpty } from "lodash"
import { useCurrentUser } from "hooks/current-user"
import { usePermissionsQuery } from "@timenotes/shared/src/services/permissions/permissions.hooks"
import UsersAccountSelect from "components/timenotes/users-accounts/users-account-select"

type useBulkEditProps = ModalProps & {
  ids: string[]
}

export const useBulkEditTimeLogs = ({ ids, ...props }: useBulkEditProps) => {
  const [open, setOpen] = useState(false)
  const bulkUpdateTimeLogsMutation = useBulkUpdateTimeLogsMutation()

  useEffect(() => {
    if (open && ids.length == 0) {
      setOpen(false)
      message.error('No time logs selected for bulk edit!')
    }
  }, [open])

 useQueryGenericErrorMessage(bulkUpdateTimeLogsMutation)

  const handleBulkUpdate = async () => {
    if (isEmpty(formik.values) || (formik.values.project && isEmpty(formik.values.project) || (formik.values.task && isEmpty(formik.values.task))) ) {
      message.error('Configure the fields to perform the update!')
    } else {
      formik.submitForm()
   }
  }

  const { formik } = useBulkUpdateTimeLogsFormik({
    initialValues: {},
    params: {
      filters: null,
      bulk: {
        ids: [...ids]
      }
    }
  })

  useNoInitialEffect(() => {
    if (formik.isSubmitting == false) {
      setOpen(false)
      if (props.onOk) props.onOk(undefined as any)
    }
  }, [formik.isSubmitting])


  useFormikGenericErrorMessage(formik)
  const BulkEditModal = (
    <Modal
      className="bulk-modal"
      visible={open}
      okText="Update all"

      confirmLoading={bulkUpdateTimeLogsMutation.isLoading}
      title={`Update ${ids.length} selected time logs`}
      {...props}

      destroyOnClose={true}
      onOk={handleBulkUpdate}
      onCancel={(e) => {
        if (props.onCancel) props.onCancel(e)
        formik.resetForm()
        setOpen(false)
      }}
   >
    <BulkUpdateModalBody 
      formik={formik}
    />
   </Modal>
  )

  return {
    modal: BulkEditModal,
    open: open,
    setOpen: setOpen,
  }
}

interface Props {
  formik: (ReturnType<typeof useBulkUpdateTimeLogsFormik>)['formik']
}

const BulkUpdateModalBody: React.FC<Props> = ({ formik }) => {
  const [state, setState] = useState({
    editProject: false,
    editTask: false,
    editTags: false,
    editDescription: false,
    editDuration: false,
    editDate: false,
    editUsersAccount: false,
    editIsBillable: false,
  });

  const { defaultDateFormat } = useDefaultDateFormat()
  const { currentUser } = useCurrentUser()
  const permissionsQuery = usePermissionsQuery()
  const canSetTimeLogUser = !!permissionsQuery.data?.permissions.setTimeLogUser
  const canSetTimeLogBillable = !!permissionsQuery.data?.permissions.setTimeLogBillable

  useEffect(() => {
    if (!editProject) {
      formik.setFieldValue('project', undefined)
      formik.setFieldValue('task', undefined)
    } 

    if (!editTask) {
      formik.setFieldValue('task', undefined)
    }

    if (!editTags) {
      formik.setFieldValue('tags', undefined)
    }

    if (!editDescription) {
      formik.setFieldValue('description', undefined)
    }

    if (!editDuration) {
      formik.setFieldValue('duration', undefined)
    }

    if (!editDate) {
      formik.setFieldValue('date', undefined)
    }

    if (!editUsersAccount) {
      formik.setFieldValue('usersAccount', undefined)
    }

    if (!editIsBillable) {
      formik.setFieldValue('isBillable', undefined)
    }
  }, [state])

  useEffect(() => {
    if (!editProject) {
      setState((s) => ({
        ...s,
        editTask: false,
      }))
    }
  }, [state.editProject])

  useEffect(() => {
    if (editTags) {
      formik.setFieldValue('tags', [])
    }
  }, [state.editTags])

  useEffect(() => {
    if (editDescription) {
      formik.setFieldValue('description', '')
    }
  }, [state.editDescription])

  useEffect(() => {
    if (editTask) {
      formik.setFieldValue('task', {})
    }
  }, [state.editTask])

  useEffect(() => {
    if (editUsersAccount) {
      formik.setFieldValue('usersAccount', currentUser)
    }
  }, [state.editUsersAccount])


  useEffect(() => {
    if (editDate) {
      formik.setFieldValue('date', moment())
    }
  }, [state.editDate])


  const { editProject, editTask, editTags, editDescription, editDuration, editDate, editUsersAccount, editIsBillable } = state

  const tags = formik.values.tags || []
  const projectId = formik.values.project?.id 

  const cantUpdateTask = !editProject || !projectId || projectId === ''

  return (
    <div className="update-modal">
      <br />

      <Flex>
        <div className="label-wrapper">
          <Checkbox checked={editProject} onChange={(e) => setState({ ...state, editProject: e.target.checked })}>
            Project
          </Checkbox>
        </div>

        <ProjectSelect
          disabled={!editProject}
          value={formik.values.project}
          onChange={(p) => {
            formik.setFieldValue('project', p)
          }}
        />
     </Flex>

      <br />

      <Flex>
        <div className="label-wrapper">
          <Tooltip 
            title={cantUpdateTask ? "You need to select the project in order to bulk update the task." : undefined}
            placement="left"
          >
            <Checkbox disabled={cantUpdateTask} checked={editProject && editTask} onChange={(e) => setState({ ...state, editTask: e.target.checked })}>
              Task
            </Checkbox>
          </Tooltip>
        </div>


        <TaskSelect 
          project={formik.values.project}
          disabled={cantUpdateTask || !editTask}
          value={formik.values.task}
          onChange={(t) => {
            formik.setFieldValue('task', t)
          }}
        />
     </Flex>

      <br />

      <Flex>
        <div className="label-wrapper">
          <Checkbox checked={editTags} onChange={(e) => setState({ ...state, editTags: e.target.checked })}>
            Tags
          </Checkbox>
        </div>

        <div 
          style={{ 
            width: '100%',
            display: 'flex',
          }}
        >
           <TagsSelect
              style={{
                width: '100%',
              }}
              disabled={!editTags}
              value={tags || []}
              onChange={(ts) => {
                formik.setFieldValue('tags', ts)
              }}
            />
            <div>
              <Badge
                style={{ position: 'absolute' }}
                count={tags.length}
                offset={[10, -20]}
                size="default"
              />
            </div>
        </div>
     </Flex>

      <br />

      <Flex>
        <div className="label-wrapper">
          <Checkbox checked={editDescription} onChange={(e) => setState({ ...state, editDescription: e.target.checked })}>
            Description
          </Checkbox>
        </div>

        <Input.TextArea 
          rows={2} 
          disabled={!editDescription} 
          value={formik.values.description || ""} 
          onChange={(e) => formik.setFieldValue('description', e.target.value)}
          placeholder="Add description"
        />
      </Flex>

      <br />

      <Flex>
        <div className="label-wrapper">
          <Checkbox checked={editDuration} onChange={(e) => setState({ ...state, editDuration: e.target.checked })}>
            Duration
          </Checkbox>
        </div>

        <DurationInput
          disabled={!editDuration}
          style={{
            width: '100%'
          }}
          value={formik.values.duration}
          onChange={(val) => {
            formik.setFieldValue('duration', val)
          }}
        />
     </Flex>

      <br />

      <Flex>
        <div className="label-wrapper">
          <Checkbox checked={editDate} onChange={(e) => setState({ ...state, editDate: e.target.checked })}>
            Date
          </Checkbox>
        </div>

        <DatePicker
          disabled={!editDate}
          style={{
            width: '100%'
          }}
          value={editDate ? (formik.values.date || moment()) : undefined}
          allowClear={false}
          format={defaultDateFormat}
          onChange={(val) => {
            formik.setFieldValue('date', val)
          }}
        />
     </Flex>

      <br />

      {canSetTimeLogUser && (
        <>
          <Flex>
            <div className="label-wrapper">
              <Checkbox checked={editUsersAccount} onChange={(e) => setState({ ...state, editUsersAccount: e.target.checked })}>
                Member
              </Checkbox>
            </div>

            <UsersAccountSelect
              disabled={!editUsersAccount}
              style={{
                width: '100%'
              }}
              value={editUsersAccount ? (formik.values.usersAccount) : undefined}
              allowClear={false}
              onChange={(val) => {
                formik.setFieldValue('usersAccount', val)
              }}
            />
          </Flex>
          <br />
        </>
      )}

      {canSetTimeLogBillable && (
        <>
          <Flex>
            <div className="label-wrapper">
              <Checkbox checked={editIsBillable} onChange={(e) => setState({ ...state, editIsBillable: e.target.checked })}>
                Is Billable?
              </Checkbox>
            </div>

            <IsBillableSelect
              width="100%"
              disabled={!editIsBillable}
              value={formik.values.isBillable}
              onChange={(val) => {
                formik.setFieldValue('isBillable', !!val)
              }}
            />
          </Flex>
        </>
      )}
    </div>
  );
};