import { DatePicker, Divider, message } from "antd"
import { Button } from "components/base"
import TimeLogForm from "components/timenotes/timer/time-log-form"
import PageHeader from "components/layout/page-header"
import { useCurrentUser } from "hooks/current-user"
import { useDefaultDateFormat } from "hooks/settings"
import moment, { Moment } from "moment-timezone"
import { useEffect, useState } from "react"
import { NewTagModalContextProvider } from "components/timenotes/tags/new-tag-modal"
import { NewProjectModalContextProvider } from "components/timenotes/projects/new-project-modal-context"
import { NewTaskModalContextProvider } from "components/timenotes/tasks/new-task-modal-context"
import TimerTable from "./components/timer-table"
import Flex from "components/shared-components/Flex"
import { useActiveTrackerQuery } from "@timenotes/shared/src/services/timer/queries/use-active-tracker-query"

const TimerPage = () => {
  const [currentDate, setCurrentDate] = useState(moment().utc().set({ hour: 8, minute: 0 }))
  const humanDay = (currentDate.format('YYYY-M-D') == moment().format('YYYY-M-D')) ? `Today, ${currentDate.format('D MMMM')}` : currentDate.format('ddd, D MMMM')

  const [trackingMode, setTrackingMode] = useState<'manual' | 'tracker'>('manual')

  const [numberOfDaysToDisplay, setNumberOfDaysToDisplay] = useState(0)
  const [days, setDays] = useState<Moment[]>([])

  const { currentUser } = useCurrentUser()
  const { defaultDateFormat } = useDefaultDateFormat()

  const trackerQuery = useActiveTrackerQuery()
  const activeTracker = trackerQuery.data?.activeTracker

  const finalTrackingMode = !!activeTracker ? 'tracker' : trackingMode

  // Recalculate days table
  useEffect(() => {
    // Create an array of dates from day to number of days to display
    const currentMoment = currentDate.clone().subtract(numberOfDaysToDisplay, 'days')
    const endMoment = currentDate
    const daysTimespan = []

    while (currentMoment.isBefore(endMoment, 'day')) {
      daysTimespan.unshift(currentMoment.clone())
      currentMoment.add(1, 'days');
    }

    setDays(daysTimespan)
  }, [numberOfDaysToDisplay])

  useEffect(() => {
    setNumberOfDaysToDisplay(0)
  }, [currentDate])

  const addMoreDays = () => {
    setNumberOfDaysToDisplay(numberOfDaysToDisplay + 7)
  }

  return (
         <div className="page-content">
            <PageHeader
              customTitle={(
                <div style={{
                  display: 'flex',
                  gap: '20px',
                  alignItems: 'center',
                  alignSelf: 'flex-start',
                  justifyContent: 'center',
                }}>
                  <div>
                    <DatePicker
                      value={currentDate}
                      onChange={(newDate) => {
                        setCurrentDate(newDate || moment().utc().set({ hour: 8, minute: 0, second: 0 }))
                      }}
                      format={defaultDateFormat}
                      allowClear={false}
                    />
                  </div>
                  <h2 style={{ paddingTop: '5px' }}>{humanDay}</h2>
                </div>
              )}
              customHeader={(
                <>
                  {finalTrackingMode == 'manual' ? (
                    <Button
                      onClick={() => setTrackingMode('tracker')}
                    >
                      Switch to tracking time by starting trackers
                    </Button>
                  ) : (
                    // if tracker
                    <Button
                      disabled={!!activeTracker}
                      disabledTooltip="You can not switch to manual mode when active tracker is running. Stop the tracker to track time manually or do it from the timesheet section."
                      onClick={() => setTrackingMode('manual')}
                    >
                      Switch to adding time entries manually
                    </Button>
                  )}
                </>
              )}
            />

            <br />

            <div style={{ marginTop: '5px' }}>
              <TimeLogForm
                mode={finalTrackingMode}
                date={currentDate}
                enableUsersAccountSelect={false}
              />
            </div>

            <div>
              <TimerTable 
                date={currentDate}
                skipHeader={true}
                onLatestEndAtChange={(date) => {
                  setCurrentDate(date)
                }}
              />
            </div>

            <Divider />

            { days.map((day) => (
              <>
                <div key={`timer-day-${day.format()}`}>
                  <TimerTable 
                    date={day}
                  />
                </div>

                <Divider />
              </>
            ))}

            <Flex justifyContent="center">
              <Button
                className="timer-more-days-button"
                type="default"
                onClick={addMoreDays}
              >
                Display previous days
              </Button>
            </Flex>

          </div>
 )
}

export default TimerPage