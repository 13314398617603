import { DatePicker, Form } from "antd"
import { FormikConfig, FormikProps } from "formik"
import React from "react"
import UsersAccountSelect from "../forms/users-account-select"
import IsBillableSelect from "../forms/is-billable-select"
import TagsSelect from "../forms/tags-select"
import DescriptionInput from "../forms/description-input"
import moment, { Moment } from "moment-timezone"
import ErrorMessage from "components/base/error-message"
import DurationInput from "../forms/duration-input"
import ProjectSelect from "../forms/project-select"
import TaskSelect from "../forms/task-select"
import { TTimeLogFormObject } from "hooks/time-logs.hooks"
import { useDefaultDateFormat, useDefaultTimeFormat } from "hooks/settings"
import TimePicker from "components/forms/fields/time-picker"
import { useTimeLogPermissions } from "hooks/permissions.hooks"
import { TApiTag } from "@timenotes/shared/src/services/api-client/types"
import { ICreateTimeLogParams, ICreateTimeLogsResponse } from "@timenotes/shared/src/services/api-client/time-logs-api"

const DATE_TIME_FORMAT = 'YYYY-MM-DD'

export type TimeLogFormItemProps = {
  usersAccountId?: string
  isBillable?: boolean
  tags?: TApiTag[]
  description?: string
  date?: Moment
  duration?: number
  projectId?: string
  taskId?: string
}

interface TimeLogFormState {
}

export interface TimeLogFormProps {
  timeLog: ICreateTimeLogParams
  formikRef: React.Ref<FormikConfig<ICreateTimeLogParams>>
  onSubmit(timeLog: ICreateTimeLogParams): Promise<ICreateTimeLogsResponse>
}

const TimeLogForm = ({
  formik,
}: {
  formik: FormikProps<TTimeLogFormObject>
}) => {
  const { defaultDateFormat } = useDefaultDateFormat()
  const { defaultTimeFormat } = useDefaultTimeFormat()

  const { 
    canEditTimeLogIsBillable,
    canManageOtherTimeLogs,
  } = useTimeLogPermissions()

  //
  return (
    <Form
      labelCol={{ span: 6, }}
      wrapperCol={{ span: 14, offset: 1 }}
    >

      <Form.Item
        label="Project"
      >
        <ProjectSelect
          value={formik.values.project?.id}
          onChange={(value, project) => {
            formik.setFieldValue('project', project)
            formik.setFieldValue('task', undefined)
          }}
          allowClear={false}
          initialProject={formik.values.project}
        />
        <ErrorMessage msg={formik.errors.project as string} />
      </Form.Item>

      <Form.Item
        label="Task"
      >
        <TaskSelect
          projectId={formik.values.project?.id}
          value={formik.values.task?.id}
          onChange={(value, task) => {
            formik.setFieldValue('task', task)
          }}
          allowClear={false}
          initialTask={formik.values.task}
        />
        <ErrorMessage msg={formik.errors.task as string} />
      </Form.Item>

      {canManageOtherTimeLogs && (

        <Form.Item
          label="Team Member"
        >
          <UsersAccountSelect
            width="100%"
            value={formik.values.usersAccount?.id}
            onChange={(value, usersAccount) => {
              formik.setFieldValue('usersAccount', usersAccount)
            }}
            allowClear={false}
            initialUsersAccount={formik.values.usersAccount}
          />
          <ErrorMessage msg={formik.errors.usersAccount} />
        </Form.Item>

      )}

      <Form.Item
        label="Tags"
      >
        <TagsSelect
          values={formik.values.tags?.map(tag => tag.id)}
          onChange={(tagIds, tags) => {
            formik.setFieldValue('tags', tags.length == 0 ? [] : tags)
          }}
        />
        <ErrorMessage msg={formik.errors.tags} />
      </Form.Item>

      <Form.Item
        label="Date"
      >
        <DatePicker
          style={{
            width: '150px',
          }}
          format={defaultDateFormat}
          value={moment.utc(formik.values.startAt)}
          onChange={(value) => {
            formik.setFieldValue('startAt', (value === null) ? undefined : value)
          }}
          allowClear={false}
        />
        { /* @ts-ignore */ }
        <ErrorMessage msg={formik.errors.date as string} />
      </Form.Item>

      <Form.Item
        label="From"
      >
        <TimePicker
          style={{ 
            width: '150px',
          }}
          format={defaultTimeFormat}
          bordered={true}
          allowClear={false}
          value={moment.utc(formik.values.startAt)}
          onChange={(newTimeMoment) => {
            if (newTimeMoment) {
              const newStartAt = moment.utc(formik.values.startAt)
              newStartAt.set('h', newTimeMoment.hour())
              newStartAt.set('m', newTimeMoment.minute())

              formik.setFieldValue('startAt', newStartAt.format())
            }
          }}
        />
        <ErrorMessage msg={formik.errors.startAt} />
      </Form.Item>
 
      <Form.Item
        label="Duration"
      >
        <DurationInput
          width={"150px"}
          value={formik.values.duration}
          onChange={(value) => {
            formik.setFieldValue('duration', (value === undefined) ? undefined : value)
          }}
          allowClear={false}
        />
        <ErrorMessage msg={formik.errors.duration} />
      </Form.Item>


      {canEditTimeLogIsBillable && (
        <Form.Item
          label="Is Billable"
        >
          <IsBillableSelect
            width="150px"
            value={formik.values.isBillable}
            onChange={(value) => {
              formik.setFieldValue('isBillable', value)
            }}
            allowClear={false}
          />
          <ErrorMessage msg={formik.errors.isBillable} />
        </Form.Item>
      )}

      <Form.Item
        label="Description"
      >
        <DescriptionInput
          placeholder="Description"
          allowClear={true}
          value={formik.values.description || ""}
          onChange={(e) => {
            formik.setFieldValue('description', e.target.value)
          }}
        />
        <ErrorMessage msg={formik.errors.description} />
      </Form.Item>

    </Form>
  )

}

export default TimeLogForm