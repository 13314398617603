import { useEffect, useState } from 'react'

import _filter from 'lodash/filter'
import _find from 'lodash/find'

import PageHeader from 'components/layout/page-header'
import MembersGroupsTable from 'pages/team/components/members-groups-table'
import { IActionItem } from 'components/shared/actions-panel'
import GroupForm from 'pages/team/components/group-form'
import { usePagePermissions } from 'hooks/permissions.hooks'
import { useTeamMembersListQuery } from '@timenotes/shared/src/services/team-members/queries/use-team-members-list-query'
import { useMembersGroupsListQuery } from '@timenotes/shared/src/services/members-groups/queries/use-members-groups-list'
import useTeamNavigationLinks from './hooks/use-team-navigation-links'
import useApiClient from 'hooks/useApiClient'
import { TApiMembersGroup } from '@timenotes/shared/src/services/api-client/types'
import { handleResponseWithMessage } from '@timenotes/shared/src/services/api-client'
import useEnsureUserCanDisplayPage from 'services/pages/use-ensure-user-can-display-page'

const MembersGroupsListPage = () => {
  const apiClient = useApiClient()

  const [loaded, setLoaded] = useState(false)
  const [isCreateGroupVisible, setIsCreateGroupVisible] = useState(false)
  const [isEditGroupVisible, setIsEditGroupVisible] = useState(false)
  const [editGroup, setEditGroup] = useState<TApiMembersGroup | undefined>(undefined)

  const { permissions, loaded: permissionsLoaded } = usePagePermissions()
  const { query: teamMembersQuery } = useTeamMembersListQuery({
    filters: {
      state: 'active'
    },
    perPage: 2000,
  })
  const membersGroupsListQuery = useMembersGroupsListQuery()

  useEnsureUserCanDisplayPage('team')
  useTeamNavigationLinks()

  useEffect(() => {
    if (teamMembersQuery.isSuccess, membersGroupsListQuery.isSuccess, permissionsLoaded) {
      setLoaded(true)
    }
  }, [teamMembersQuery, membersGroupsListQuery, permissionsLoaded])

  const canManageMembersGroups = permissions.manageMembersGroups

  const deleteMembersGroup = (group: TApiMembersGroup) => {
    apiClient.deleteMembersGroup(group.id)
      .then(handleResponseWithMessage('Group deleted successfully!'))
      .then((response) => {
        if (response.ok) {
          membersGroupsListQuery.refetch()
        }
      })
  }

  const triggerEditGroup = (group: TApiMembersGroup) => {
    setEditGroup(group)
    setIsEditGroupVisible(true)
  }

  const getMembersGroupsActions = (group: TApiMembersGroup) => {
    const actions: IActionItem[] = []

    actions.push({
      title: 'Edit',
      action: () => {
        triggerEditGroup(group)
      },
      icon: 'edit',
    })

    actions.push({
      title: 'Delete',
      confirm: {
        title: 'Are you sure you want to delete this group?',
        content: `This action is safe and will not delete the users, it will delete the group only. All the users who belongs to the group will lose the access to the projects assigned to this group.`,
        okText: 'Delete',
        cancelText: 'Cancel',
      },
      action: () => {
        deleteMembersGroup(group)
      },
      icon: 'delete',
    })

    return actions
  }

  return (
    <div className="page-content">
      <PageHeader
        title="Groups"
        buttons={[
          {
            title: "Create group",
            type: 'primary',
            disabled: !canManageMembersGroups,
            disabledTooltip: "You have no permissions to manage and create the new group.",
            callback: () => {
              setIsCreateGroupVisible(true)
            },
          }
        ]}
      />

      <GroupForm
        // UPDATE GROUP FORM
        group={editGroup}
        workspaceTeamMembers={teamMembersQuery.data?.teamMembers || []}
        visible={isEditGroupVisible}
        close={() => { 
          setIsEditGroupVisible(false)
        }}
        onSubmit={(formGroup) => {
          return apiClient.updateMembersGroup(editGroup?.id as string, formGroup).then((response) => {
            if (response.ok) {
              membersGroupsListQuery.refetch()
            }

            return response
          })
        }}
      />


      <GroupForm
        // NEW GROUP FORM
        workspaceTeamMembers={teamMembersQuery.data?.teamMembers || []}
        visible={isCreateGroupVisible}
        close={() => { 
          setIsCreateGroupVisible(false)
        }}
        onSubmit={(group) => {
          return apiClient.createMembersGroup(group).then((response) => {
            if (response.ok) {
              membersGroupsListQuery.refetch()
            }

            return response
          })
        }}
      />


      <MembersGroupsTable
        editGroup={triggerEditGroup}
        membersGroups={membersGroupsListQuery.data?.membersGroups || []}
        getMembersGroupsActions={getMembersGroupsActions}
        allowAction={canManageMembersGroups}
      />
    </div>
  )
}

export default MembersGroupsListPage