import { Card, Checkbox, Divider, Radio, RadioChangeEvent, Skeleton, message } from "antd"
import { IFormObject } from "pages/settings/components/workspace-settings-form"
import { useCurrentUser } from "hooks/current-user"
import { useSettings, useUpdateSettings } from "hooks/settings"
import { DEFAULT_SETTINGS } from "@timenotes/shared/src/services/settings/settings-consts"
import { TApiApprovalsPeriodType } from "@timenotes/shared/src/services/api-client/settings-api"
import { useQueryClient } from "react-query"
import SettingsCheckbox from "../components/settings-checkbox"

const ApprovalsSection = () => {
  const { settings, loading, fetchSettings } = useSettings()
  const { updateSettings, loading: updating } = useUpdateSettings()
  const queryClient = useQueryClient()

  const { currentUser } = useCurrentUser()

  const periodType = settings?.approvalsPeriod || DEFAULT_SETTINGS.approvalsPeriod
  const handleOnChangePeriodType = (e: RadioChangeEvent) => {
    const newValue = e.target.value as TApiApprovalsPeriodType
    handleSubmit({
      approvalsPeriod: newValue
    })
  }

  if (settings === undefined || !currentUser) return (
    <Card style={{
      width: '400px',
    }}>
      <Skeleton />
    </Card>
  )

  const handleSubmit = async (settings: Partial<IFormObject>) => {
    const response = await updateSettings(settings)

    if (response.ok) {
      message.success("Settings updated!")

      // Invalidate approvals periods after period type change!
      queryClient.invalidateQueries('periods', { exact: false })
      queryClient.invalidateQueries('settings', { exact: false })
      await fetchSettings()
    }

    return response
  }

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  return (
    <Card>

      <h2>
         Approvals Configuration
      </h2>

      <Divider style={{ marginTop: 0 }} />

      <SettingsCheckbox
        title="Enable approvals feature?"
        info="Turning this off will disable whole approvals feature across whole workspace. Hide approvals navigation and does not allow users to lock time by periods management."
        checked={settings.approvalsEnabled}
        onChange={(e) => {
          handleSubmit({
            approvalsEnabled: e.target.checked
          })
        }}
      />

      <Divider />

      <h3>Period Type Configuration</h3>

      <p>
        Configure periods used in your workspace for timesheet reporting, planning and approvals process.
      </p>

      <Radio.Group
        value={periodType} 
        onChange={handleOnChangePeriodType}
        disabled={!settings.approvalsEnabled}
      >
        <Radio style={radioStyle} value={'monthly'}>
          Monthly
        </Radio>
        <Radio style={radioStyle} value={'semi_monthly'}>
          Semi-monthly
        </Radio>
        <Radio style={radioStyle} value={'biweekly'}>
          Bi-weekly
        </Radio>
        <Radio style={radioStyle} value={'weekly'}>
          Weekly
        </Radio>
      </Radio.Group>

    </Card>
  )
}



export default ApprovalsSection