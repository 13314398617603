import { useEffect, useState } from 'react'
import queryString from 'query-string'

import { Card, Skeleton, message } from 'antd'
import { useQueryParams } from 'hooks/app-router'
import { useHistory } from 'react-router-dom'
import NewIntegrationStepsView, { NewIntegrationStep } from '../shared/components/new-integration-steps'
import PageHeader from 'components/layout/page-header'
import WorkspaceList from './components/workspace-list'
import { useIntegrationAccountsQuery } from 'services/integrations/integration-accounts/use-integration-accounts-query'
import { useAvailableIntegrationAppsQuery } from 'services/integrations/integration-apps/use-available-integration-apps-query'
import { find } from 'lodash'
import useApiClient from 'hooks/useApiClient'

const NewSelectWorkspacePage = () => {
  const history = useHistory()
  const apiClient = useApiClient()

  const [loaded, setLoaded] = useState(false)

  const queryParams = useQueryParams<{
    integrationAppId: string 
    integration_id: string
  }>()

  const integrationAppId: string = queryParams.integrationAppId || queryParams.integration_id || ""

  const {
    query: integrationAccountsQuery
  } = useIntegrationAccountsQuery({
    integrationId: integrationAppId
  })
  const integrationAccounts = integrationAccountsQuery.data?.integrationAccounts || []

  const {
    query: availableIntegrationAppsQuery
  } = useAvailableIntegrationAppsQuery()

  const availableIntegrationApps = availableIntegrationAppsQuery.data?.integrationApps || []
  const integrationApp = find(availableIntegrationApps, (intApp) => intApp.id == integrationAppId) || null

  useEffect(() => {
    if (integrationAccountsQuery.isSuccess, availableIntegrationAppsQuery.isSuccess) {
      setLoaded(true)
    }
  }, [integrationAccountsQuery.status, availableIntegrationAppsQuery.status])

  const getQueryParams = (): any => {
    const tempQuery = queryString.parse(window.location.search)

    if (tempQuery.integration_id) {
      tempQuery.integrationAppId  = tempQuery.integration_id
    }

    return tempQuery
  }

  const connectIntegrationAccount = (integrationAccount: TApiIntegrationAccount) => {
    const params = {
      integrationId: integrationAppId,
      integrationAccountName: integrationAccount.name,
      integrationAccountOriginId: integrationAccount.originIdentifier,
      integrationAccountAutoSyncProjects: false,
      integrationAccountWebhookable: integrationAccount.webhookable,
    }

    return apiClient.createIntegrationAccount(params)
  }

  const handleAppClick = (app: TApiIntegrationApp, integrationAccount: TApiIntegrationAccount) => {

    if (integrationAccount.isConnected) {
      history.push(`/integrations/integration-account/${integrationAccount.id}/projects/new`)

    } else {

      // For existing but disconnected app we trigger re-connect
      if (!!integrationAccount.id && integrationAccount.status?.toString() == "disconnected") {
        message.info("Reconnect triggered, it might take a moment...")

        const response = apiClient.reconnectIntegrationAccount({
          integrationAccountId: integrationAccount.id,
        }).then((response) => {
          if ((response as any).ok) {
            history.push(`/integrations/new-select-projects?integrationAccountId=${integrationAccount.id}`)
          } else {
            message.error("Something went wrong!")
          }
        })
      } else {
        // For non-existing app - we connect to obtain the ID
        const connectPromise = connectIntegrationAccount(integrationAccount)

        connectPromise.then((response) => {
          const newIntegrationAccount = response.integrationAccount

          history.push(`/integrations/new-select-projects?integrationAccountId=${newIntegrationAccount.id}`)
        })
      }
    }
  }

  return (
      <div className="page-content">
        <NewIntegrationStepsView currentStep={ NewIntegrationStep.SelectWorkspace } />

      <Card>
        <PageHeader
          title="Select Workspace"
        />
        <p>
          {`Choose ${integrationApp?.service} workspaces that you want to connect with Timenotes. Each integration workspace process connects one workspace, you can connect multiple workspaces with Timenotes via completing a separate processes.`}
        </p>

        { loaded && integrationApp ? (
          <WorkspaceList
            integrationApp={integrationApp}
            integrationAccounts={integrationAccounts}
            onAppClick={handleAppClick}
          />
        ) : (
          <Skeleton />
        )}
      </Card>

    </div>
 )
}

export default NewSelectWorkspacePage