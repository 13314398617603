import React from 'react'
import { Modal } from 'antd'

import _isEmpty from 'lodash/isEmpty'
import SimpleTimeLogsTable from './simple-time-logs-table'

const UserActivityModal = (props: {
  userActivity: any,
  onCancel(): void,
}) => {
  const {
    userActivity,
    onCancel,
  } = props

  const isVisible = !_isEmpty(userActivity)

  // This ensures modal unmounting if not visible
  if (!isVisible) {
    return null
  }

  return (
    <Modal
      footer={null}
      visible={isVisible}
      onCancel={onCancel}
      className="user-activity-modal"
    >
      <h2 className="title"> {userActivity.name} </h2>
      <p className="sub-title"> Todays Time Logs </p>

      <SimpleTimeLogsTable tracker={userActivity.tracker} timeLogs={userActivity.timeLogs} />
    </Modal>
  )
}

export default UserActivityModal