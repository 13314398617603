//
// LEGACY COMPONENT !! USE the color-picker-input one
//
// import OutsideAlerter from 'components/view-utils/OutsideAlerter'
import React from 'react'
import { BlockPicker, ColorResult } from 'react-color'

interface IProps {
  value: string
  onChange(value: string): void
}

export const TIMENOTES_COLORS = [
  "#76C290",
  "#F6A07E",
  "#613131",
  "#9A82DE",
  "#3A3AA3",
  "#D6C1D4",
  "#7C2F75",
  "#1C8A7D",
  "#C7D08B",
  "#C76767",
//  "#C76767",
]

class ColorPickerInput extends React.Component<IProps> {

  handleOnChange = (colorResult: ColorResult) => {
    this.props.onChange(`${colorResult.hex}`)
  }

  render() {
    return (
      <BlockPicker 
        colors={TIMENOTES_COLORS}
        color={this.props.value}
        onChange={this.handleOnChange}
      />
    )
  }

}

export default ColorPickerInput