import styled from "styled-components"
import PageHeader from "components/layout/page-header"
import { Skeleton, Tabs } from "antd"

import { isActiveUrl, TopNavLinkItem } from "./top-nav-links"
import { find, map } from "lodash"

const TopBarHeader = styled.div`
  width: 100%;
  height: 140px;
  padding: 20px 0px 0px 0px;
  background-color: white;
  box-shadow: 0px 0px 0px 0px #e5e5eB inset;
  border-bottom: 1px #e5e5eB inset;
`

const TopBarContent = styled.div`
  width: 100%;
  height: 50px;
`

const TopBarFooter = styled.div`
  width: 100%;
  height: 70px;
  bottom: 0;

  & .ant-tabs-nav {
    margin-bottom: 0px;
  }
`

const TopBarWrapper = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  margin: auto;

  position: relative;
  max-width: 1380px;
  width: 100%;
`

interface PageTabsHeaderProps {
  tabs: TopNavLinkItem[]
  rightTabs?: TopNavLinkItem[]
  loaded?: boolean
}

const PageTabsHeader = (props: PageTabsHeaderProps) => {

  const activeSection = "tasks"
  const loaded = props.loaded == false ? false : true

  if (!loaded) {
    return <TopBarHeader style={{ height: '140px ' }}><Skeleton paragraph={{ rows: 2 }} /></TopBarHeader>
  }

  const tabsComponents = map(props.tabs, (tab) => (
    <Tabs.TabPane tab={tab.label} key={tab.label} />
  ))

  const rightTabsComponents = map(props.rightTabs || [], (tab) => (
    <Tabs.TabPane tab={tab.label} key={tab.label} />
  ))

  const handleTabClick = (key: string, tabs: TopNavLinkItem[]) => {
    const selectedTab = find(tabs, (tab) => { return tab.label == key }) as TopNavLinkItem

    selectedTab.onClick()
  }

  const allTabs = [...props.tabs, ...(props.rightTabs || [])]
  const activeKey = find(allTabs, (tab) => {
    return isActiveUrl(tab.urlMatcher)
  })?.label || ""

  return (
    <TopBarHeader>
      <TopBarContent>
        <div className="page-content" style={{ marginTop: '0px', paddingLeft: '12px', paddingRight: '12px' }}>
          <PageHeader {...props}/>
        </div>
      </TopBarContent>
      <TopBarFooter>
        <div className="page-content" style={{
          marginTop: '0px', paddingLeft: '12px', paddingRight: '12px', height: '70px',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'
        }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }} >
            <Tabs type="card" tabBarGutter={5} onTabClick={(key) => { handleTabClick(key, props.tabs) }} activeKey={activeKey}>
              {tabsComponents}
           </Tabs>

            <Tabs type="card" tabBarGutter={5} onTabClick={(key) => { handleTabClick(key, props.rightTabs || []) }} activeKey={activeKey}>
              {rightTabsComponents}
            </Tabs>
          </div>
        </div>
      </TopBarFooter>
    </TopBarHeader>
  )
}

export default PageTabsHeader