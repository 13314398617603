import { UseQueryOptions, useQuery } from "react-query"
import { TApiGenericError, TApiGenericErrors, TApiGenericResponse } from "../timenotes-query/timenotes-query.types"
import { useApiClient } from "../../api-client/api-client.hooks"
import { IGetAllTasksParams } from "../../api-client/tasks-api"
import { useEffect, useState } from "react"
import { TApiPagination, TApiParams, TApiTask } from "../../api-client/types"
import { isUndefined, omitBy } from "lodash"

interface IParams extends IGetAllTasksParams {
  projectId?: string
}

interface IResult {
  tasks: TApiTask[]
  pagination: TApiPagination
}

const PER_PAGE = 30

export const usePaginatedTasksQuery = (params: IParams, options: UseQueryOptions<TApiGenericResponse<TApiTask[]>, TApiGenericError<null>> = {}) => {
  const apiClient = useApiClient()

  const [page, setPage] = useState<number>(params.page || 1)

  const perPage = params.perPage || PER_PAGE
  const requestFilters = omitBy(params.filters || {}, isUndefined)

  const defaultSorting = { name: 'asc' }
  const requestSorting = params.sorting || defaultSorting

  const requestParams = {
    projectId: params.projectId,
    filters: requestFilters,
    sorting: requestSorting,
    perPage,
    page,
  }


  const query = useQuery<IResult, TApiGenericErrors<null>>(
    ['tasks', requestParams],
    async () => {
      const response = await apiClient.getAllTasks(params.projectId, requestParams)

      if (!response.ok) { throw response.errors }

      return {
        tasks: response.tasks,
        pagination: response.meta.pagination,
      }
    },
    {
      enabled: !!params.projectId,
      ...options
    }
  )

  return { 
    query,
    page,
    setPage,
    perPage,
  }
}