import { Card } from "antd"
import { ReactChildren } from "react"

const CardWrapper = ({
  children,
  size,
}: {
  children: string | JSX.Element | JSX.Element[]
  size?: 'small' | 'medium' | 'big' | 'full'
}) => {

  let cardWidth: string

  cardWidth = {
    'small': '400px',
    'medium': '800px',
    'big': '1000px',
    'full': '100%',
  }[size || 'full']

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Card
        style={{
          width: cardWidth,
        }}
      >
        {children}
      </Card>
    </div>
  )
}

export default CardWrapper