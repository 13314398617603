import { Col, Form, Input, Row, Skeleton } from "antd"
import { FormikProps } from "formik"
import { TApiTeamMember, IUpdateUsersAccountParams } from "services/api-client/users-accounts-api"
import _pick from 'lodash/pick'
import { Tooltip } from "components/base"
import { InfoIcon } from "components/base/icons"
import ErrorMessage from "components/base/error-message"
import { useSettings } from "hooks/settings"

const TeamMemberRatesForm = ({ teamMember, onSubmit, formik }: {
   teamMember: TApiTeamMember,
   onSubmit(updateParams: IUpdateUsersAccountParams): void,
   formik: FormikProps<Partial<IUpdateUsersAccountParams>>
}) => {

  const {
    settings,
    loading: settingsLoading
  } = useSettings()

  const defaultBillableRate = settings?.defaultBillableRate
  const defaultCostRate = settings?.defaultCostRate
  const workspaceCurrency = settings?.defaultBillableRateCurrency

  if (settingsLoading) {
    return <Skeleton />
  }

  return (
    <Row gutter={20}>
      <Col span="12">
        <Form.Item
          label={(
            <Tooltip title={`This is the user specific default rate for all the billable projects. In case user has no billable rate set, default workspace rate will be used: ${defaultBillableRate || '-'} ${workspaceCurrency}`}>
              Billable rate
              <InfoIcon style={{ marginLeft: '10px' }} />
            </Tooltip>
          )}
        >
          <Input
            autoFocus={true}
            name="billableRate"
            value={formik.values.billableRate || ""}
            onChange={(e) => { 
              const newValue = (e.target.value == "" ? null : e.target.value)
              formik.setFieldValue('billableRate', newValue, false)
            }}
            onSubmit={formik.submitForm}
            onPressEnter={formik.submitForm}
            suffix={workspaceCurrency}
          />
          <ErrorMessage msg={formik.errors.billableRate} />
        </Form.Item>
      </Col>
      <Col span="12">
        <Form.Item
          label={(
            <Tooltip title={`This is the user specific default cost rate for all the projects. In case user has no cost rate set, default workspace rate will be used: ${defaultCostRate || '-'} ${workspaceCurrency}`}>
              Cost rate
              <InfoIcon style={{ marginLeft: '10px' }} />
            </Tooltip>
          )}
        >
          <Input
            name="costRate"
            value={formik.values.costRate || ""}
            onChange={(e) => { 
              const newValue = (e.target.value == "" ? null : e.target.value)
              formik.setFieldValue('costRate', newValue, false)
            }}
            onSubmit={formik.submitForm}
            onPressEnter={formik.submitForm}
            suffix={workspaceCurrency}
          />
          <ErrorMessage msg={formik.errors.costRate} />
        </Form.Item>
      </Col>
    </Row>
)}

export default TeamMemberRatesForm