import { TApiResponse, TApiParams } from "./types"
import BaseApi, { getQueryString } from './base'

export type TApiFreeDay = {
  id: string
  date: string
  name: string
}

export type TApiHolidaysFreeDaysFilters = {
  year?: string
}

export interface IGetHolidaysFreeDaysParams {
  filters?: TApiHolidaysFreeDaysFilters
}

export interface ICreateHolidaysFreeDayParams {
  name: string
  date: string
}

export interface IGetHolidaysFreeDaysResponse extends TApiResponse {
  freeDays: Array<TApiFreeDay>
}

export interface ICreateHolidaysFreeDayResponse extends TApiResponse {
  freeDay: TApiFreeDay
}

class HolidaysFreeDaysApi extends BaseApi {
  async getHolidaysFreeDays (params: IGetHolidaysFreeDaysParams): Promise<IGetHolidaysFreeDaysResponse> {
    const requestParams = {
      ...params,
      perPage: 1000,
    }

    const query = getQueryString(this.paramsToSnakeCase(requestParams), true)

    let response = await fetch(`${this.rootUrl}/holidays/free_days${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async createHolidaysFreeDay (params: ICreateHolidaysFreeDayParams): Promise<ICreateHolidaysFreeDayResponse> {
    let response = await fetch(`${this.rootUrl}/holidays/free_days`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        free_day: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async updateHolidaysFreeDay (freeDayId: string, params: ICreateHolidaysFreeDayParams) {
    let response = await fetch(`${this.rootUrl}/holidays/free_days/${freeDayId}`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        free_day: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async deleteHolidaysFreeDay (freeDayId: string) {
    let response = await fetch(`${this.rootUrl}/holidays/free_days/${freeDayId}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  // TO DO: IMPORT API
}

export default HolidaysFreeDaysApi