import { groupBy } from "lodash"
import { TApiProject } from "../../api-client/types"

export default function mapProjectOptions(projects: TApiProject[]) {
  let projectOptions = []

  const mapProjectLabel = (labelName: string, projects: TApiProject[]) => {
    return {
      label: labelName,
      options: projects.map((project) => ({
        value: project
      }))
    }
  }

  if (projects.length > 0) {
    // Add bookmarked and not bookmarked projects as separate labels
    const groupOptions = groupBy(projects, (project) => {
      if (project.recentlyTracked) {
        return 'recent'
      }

      return project.bookmarked ? 'bookmarked' : 'notBookmarked'
    })

    const recentProjects = groupOptions['recent']
    const bookmarkedProjects = groupOptions['bookmarked']
    const notBookmarkedProjects = groupOptions['notBookmarked']

    // Add recent projects
    if (recentProjects) {
      const recentOptions = mapProjectLabel('Recently tracked projects', recentProjects)
      projectOptions.push(recentOptions)
    }

    // Add bookmarked projects
    if (bookmarkedProjects) {
      const bookmarkedOptions = mapProjectLabel('Projects with bookmarked tasks', bookmarkedProjects)
      projectOptions.push(bookmarkedOptions)
    }

    if (notBookmarkedProjects) {
      const notBookmarkedOptions = mapProjectLabel('Projects', notBookmarkedProjects)
      projectOptions.push(notBookmarkedOptions)
    }
  }

  return projectOptions
}