import { TApiSubscriptionPeriod } from "@timenotes/shared/src/services/api-client/subscription-periods-api"
import { Button, Tag, Tooltip } from "antd"
import Table, { ColumnsType } from "antd/lib/table"
import { DownloadIcon } from "components/base/icons"
import { COLORS } from "components/layout-components/timenotes-theme"
import { useDefaultDateFormat, useDefaultTimeFormat } from "hooks/settings"
import moment from "moment-timezone"
import { toTitleCase } from "utils/string-utils"

const BillingHistoryTable = ({
  dataSource,
}: {
  dataSource: TApiSubscriptionPeriod[]
}) => {

  const { defaultDateFormat } = useDefaultDateFormat()
  const { defaultTimeFormat } = useDefaultTimeFormat()

  const COLUMNS: ColumnsType<TApiSubscriptionPeriod> = [
    {
      title: 'Subscription period',
      render: (value, record) => record.formattedPeriod,
    },
    {
      title: 'Plan',
      render: (value, record) => record.plan.name,
    },
    {
      title: 'Payment Method',
      render: (value, record) => `${record.paymentMethod?.name || '-'}`
    },
    {
      title: 'Amount',
      render: (value, record) => record.payments.length > 0 ? `$${parseFloat(`${record.payments[0].amount}`).toFixed(2)}` : '$0.00'
    },
    {
      title: 'Invoice',
      render: (value, record) => record.invoice?.number || 'Unavailable'
    },
    {
      title: 'Status',
      render: (value, record) => record.state == 'paid' ? (
        <Tag color={COLORS.primaryGreen}>Paid</Tag>
      ) : (
        <Tag color='red'>{toTitleCase(record.status || "")}</Tag>
      )
    },
    {
      title: '',
      render: (value, record) => (
        <Tooltip title={!record.invoice?.fileUrl ? "Invoice not available." : undefined}>
          <Button
            href={record.invoice?.fileUrl || ""}
            target="_blank"
            size="small"
            type="text"
            icon={<DownloadIcon />}
            disabled={!record.invoice?.fileUrl}
          />
        </Tooltip>
      )
    }
  ]

  return (
    <Table
      rowKey="createdAt"
      columns={COLUMNS}
      pagination={false}
      expandable={{
        expandedRowRender: (record) => (
          <>
            <p>Transactions</p>
            <Table
              style={{
                margin: '20px',
              }}
              pagination={false}
              columns={[
                {
                  title: 'Payment Date',
                  render: (value, record) => moment(record.createdAt).format(`${defaultDateFormat} ${defaultTimeFormat}`)
                },
                {
                  title: 'Amount',
                  render: (value, record) => `$${parseFloat(`${record.amount}`).toFixed(2)}`
                },
                {
                  title: 'Status',
                  render: (value, record) => record.status == 'error' ? <Tag color="red">{record.errorMessage || "Unsuccessful"}</Tag> : <Tag color="green">Successful</Tag>
                }
              ]}
              dataSource={record.payments}
            />
          </>
        ),
        rowExpandable: (record) => record.payments.length > 0
      }}
      dataSource={dataSource}
    />
  )
}

export default BillingHistoryTable