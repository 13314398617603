import { Select, Tag, Tooltip } from 'antd'
import AbsenceIcon from 'components/base/absence-icon'
import OutsideAlerter from 'components/view-utils/outside-alerter'
import { map } from 'lodash'
import React, { useState } from 'react'
import { BlockPicker, ColorResult } from 'react-color'
import styled from 'styled-components'

interface IProps {
  value: string
  onChange(value: string): void
}

export const TIMENOTES_ABSENCE_ICONS = [
  "bus",
  "calendar",
  "child",
  "clock",
  "cold",
  "holidays",
  "hotel",
  "lunch",
  "plane",
  "remote",
  "sick_leave",
  "smile",
  "star",
  "stop",
  "sunny",
  "trip",
]

const StyledSelect = styled(Select)`
`

const IconOptionsList = styled.div`
  paddingTop: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
`

const IconOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;

  :hover {
    background-color: #ddd;
    cursor: pointer;
  }
  
`

const AbsenceIconSelect = (props: IProps ) => {

  const [open, setOpen] = useState(false)

  const handleOnChange = (icon: string) => {
    props.onChange(icon)
    setOpen(false)
  }

  const options = [
    {
      label: '',
      value: props.value,
    }
  ]

  const iconsList = map(TIMENOTES_ABSENCE_ICONS, (icon) => {
    return (
      <IconOption
        key={icon}
        onClick={() => { handleOnChange(icon) }}
      >
        <AbsenceIcon icon={icon} />
      </IconOption>
    )
  })

  return (
    <Tooltip
      color='white'
      placement='bottom'
      visible={open}
      title={() => (
        <div style={{ width: '120px' }}>
          <OutsideAlerter
            onClick={() => { setOpen(false) }}
          >
            <IconOptionsList>
              {iconsList}
            </IconOptionsList>
          </OutsideAlerter>
        </div>
      )}
    >
      <StyledSelect
        style={{ width: '70px'}}
        value={props.value}
        open={false}
        onClick={() => { if (!open) { setOpen(true) }} }
        dropdownMatchSelectWidth={false}
      >
        <Select.Option key={props.value} value={props.value}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <AbsenceIcon icon={props.value} />
          </div>
        </Select.Option>
      </StyledSelect>
    </Tooltip>
  )
}

export default AbsenceIconSelect