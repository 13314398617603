import { Tag } from "antd"
import TaskSelect from "./task-select"

const TaskSelectPill = (props: React.ComponentProps<typeof TaskSelect>) => {
  // Display initial project
  if (props.value) {
    const task = props.value

    let handleOnClick = !props.disabled ? props.onClear : undefined

    if (task) {
      return (
        <Tag
          tabIndex={0}
          color={'blue'}
          onClick={handleOnClick}
          onClose={handleOnClick}
          onFocus={handleOnClick}
          closable={!props.disabled}
          style={{
            cursor: 'pointer',
            fontSize: '14px',
            paddingTop: '5px',
            paddingBottom: '5px',
          }}
        >
          Task: {task.name}
        </Tag>
      )
    }
  }

  return (
    <TaskSelect { ...props } />
  )
}

export default TaskSelectPill