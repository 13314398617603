import { TApiParams, TApiResponse, TApiProject, TApiUsersAccount, TApiResponseMeta } from "./types"
import BaseApi, { getQueryString } from './base'
import moment from "moment-timezone"
import _omit from 'lodash/omit'

export type TApiListProject = TApiProject & {
  totalTime: number
  usersAccounts: null | Array<TApiUsersAccount>
  usersAccountTotalCount: null | number
}

export type TApiProjectsListFilters = {
  clientHashIds?: Array<string>
  membersGroupHashIds?: Array<string>
  state?: string // active/archived
  usersAccountHashIds?: Array<string>
  isBillable?: boolean
  name?: string
}

export type TApiProjectsSorting = {
  name?: 'desc' | 'asc'
}

export interface IGetProjectsListParams extends TApiParams {
  sorting?: TApiProjectsSorting
  filters?: TApiProjectsListFilters
}


export interface IGetProjectsListResponse extends TApiResponse {
  projects: Array<TApiListProject>
  meta: TApiResponseMeta
}

export interface ICreateProjectParams extends TApiParams {
  name: string
  clientName: null | string
  visibility?: boolean
  color: string
  originIdentifier?: string
  origin?: string
  pinned?: boolean
  billableEnabled: boolean
  billableType: 'equal' | 'custom'
  billableRate: number
  budgetEnabled?: boolean
  budgetType?: 'money' | 'time'
  budgetMoney: number
  budgetTime: number
  budgetAlertThreshold?: number // integer
  taskCreator?: 'nobody' | 'member' | 'project_manager' | 'admin' | string
  taskCreationPolicy?: 'internal' | 'external_mandatory' | 'external_optional' | string
  disallowOverbudget?: boolean
  disallowUserOverbudget?: boolean
  archivedAt: null | string
  defaultIsBillableTimeLogs?: boolean
  additionalDetails?: object
}

export interface ICreateProjectResponse extends TApiResponse {
  project: TApiProject
}

export interface IUpdateProjectResponse extends ICreateProjectResponse {}

type TTimeDataRecord = {
  duration: number,
  costAmount: string,
  billableAmount: string,
}

type TProjectInsightsTotals = {
  billableAmount: string,
  costAmount: string,
  duration: number,
  billableDuration: number,
}

type TTimeDataMap = {
  [index: number]: TTimeDataRecord
}

export type TProjectInsightsUsersData = {
  billableAmount: null | string,
  budgetTime: null | string,
  costAmount: null | string,
  duration: number,
  usersAccount: TApiUsersAccount,
}

export interface IGetProjectInsightsResponse extends TApiResponse {
  timeData: TTimeDataMap,
  totals: TProjectInsightsTotals,
  usersData: TProjectInsightsUsersData[],
}

function removeUndefinedKeys<T extends Record<string, any>>(obj: T): Partial<T> {
  const newObj: Partial<T> = {}
  Object.keys(obj).forEach(key => {
      if (obj[key] !== undefined) {
          newObj[key] = obj[key]
      }
  })

  return newObj
}

class ProjectsApi extends BaseApi {
  async getProjectsList (params: IGetProjectsListParams): Promise<IGetProjectsListResponse> {
    let queryParams = { q: { s: this.prepareSortingParams(params.sorting) }, ..._omit(params, ['sorting'])}

    const queryWithFiltersCleaned = {
      ...queryParams,
      filters: removeUndefinedKeys(queryParams.filters || {})
    }

    const query = getQueryString(this.paramsToSnakeCase(queryWithFiltersCleaned), true)

    let response = await fetch(`${this.rootUrl}/projects/list${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async getProjectInsights(projectId: string): Promise<IGetProjectInsightsResponse> {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}/insights`, {
      method: 'GET',
      headers: this.headers,
    })

    // TRANSLATE BUDGET TIME FROM MINUTES TO HOURS FORMAT
    const json = await this.getResponseJson(response)

    if (json.usersData) {
      json.usersData= json.usersData.map((puser) => {
        if (puser.budgetTime !== null && puser.budgetTime !== undefined) {
          puser.budgetTime = puser.budgetTime / 60
        } 

        return puser
      })
    }

    return json
  }

  async getProject(projectId: string) {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async getProjects(params?: TApiParams): Promise<IGetProjectsListResponse> {
    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/projects${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async createProject (params: ICreateProjectParams): Promise<ICreateProjectResponse> {
    const requestParams = _omit(params, ['pinned']) // TODO: API returns 500 with pinned inside

    return this.post('/projects', {
      project: requestParams,
    })
  }

  async synchroniseProject (projectId: string) {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}/synchronise`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async disconnectProject (projectId: string) {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}/disconnect`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async reconnectProject (projectId: string) {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}/reconnect`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async deleteProject (projectId: string) {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async updateProject (projectId: string, params: Partial<ICreateProjectParams>): Promise<IUpdateProjectResponse> {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        project: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async pinProject(projectId: string) {
    return this.patch(`/projects/${projectId}`, { project: { pinned: true }} )
  }

  async unpinProject(projectId: string) {
    return this.patch(`/projects/${projectId}`, { project: { pinned: false }} )
  }

  async archiveProject(projectId: string) {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        project: {
          archived_at: moment().format(),
        }
      }),
    })

    return await this.getResponseJson(response)
  }

  async restoreProject(projectId: string) {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}/restore`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
}

export default ProjectsApi
