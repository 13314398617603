import { Checkbox, Tooltip } from "antd"
import { InfoIcon } from "components/base/icons"
import React from "react"

const SettingsCheckbox = (props: React.ComponentProps<typeof Checkbox> & { 
  title: string
  info?: string
}) => {

  const title = props.title
  const info = props.info

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: info ? 'space-between' : 'flex-start',
        alignItems: 'center',
      }}
    >
      <Checkbox
        {...props}
      >
        <span style={{marginLeft: '0px' }}>{title}</span>
      </Checkbox>
      { info && (
        <Tooltip title={info}>
          <InfoIcon />
        </Tooltip>
      )}
    </div>
  )
}

export default SettingsCheckbox