import { DatePicker, DatePickerProps, Popover } from "antd";
import { useDefaultDateFormat } from "hooks/settings";
import useOnKeyDown from "hooks/use-on-key-down";
import { useState } from "react";
import styled from "styled-components";


type DatePickerPopoverProps = DatePickerProps & {
  children?: React.ReactNode
  setOpen(open: boolean): void
}

const Wrapper = styled.div`
  padding-left: 8px;
  font-size: 14px;
  opacity: 0.7;
  cursor: pointer;
  width: 85px;

  &:hover {
    background-color: #eee;
    border-radius: 4px;
  }
`

const DatePickerPopover = (allProps: DatePickerPopoverProps) => {
  const { children, ...props} = allProps
  const [internalOpen, setInternalOpen] = useState(false)

  const open = props.open || internalOpen
  const setOpen = props.setOpen || setInternalOpen

  const { defaultDateFormat } = useDefaultDateFormat()

  useOnKeyDown(['Escape'], (e) => {
    setOpen(false)
  })

  const value = props.value

  const datePickerContent = (
    <DatePicker 
      format={defaultDateFormat}
      open={open}
      {...props}
    />
  )

  const timeView = children || (
    <Wrapper>
      <span>{value?.format(defaultDateFormat)}</span>
    </Wrapper>
  )

  return (
    <Popover
      trigger={'click'}
      placement="bottom"
      visible={open}
      onVisibleChange={setOpen}
      content={datePickerContent}
    >
      {timeView}
    </Popover>
  )
}

export default DatePickerPopover