import { GenericSelect } from "components/forms/generic-select"
import { useAbsenceTypes } from "hooks/absences"
import { map } from "lodash"
import styled from "styled-components"

interface AbsenceTypesFilterProps {
  value: string[]
  onChange(value: string[]): void
}

const Wrapper = styled.div`
  .ant-popover {
    min-width: 200px;
  }
`

const AbsenceTypesFilter = (props: AbsenceTypesFilterProps) => {
  const { absenceTypes } = useAbsenceTypes()

  const options = map(absenceTypes, (absenceType) => {
    return {
      id: absenceType.id,
      title: absenceType.name,
      color: absenceType.color,
    }
  })

  const groups = [{
    label: 'Types',
    hasAllHelpers: true,
    options: options,
  }]

  return (
    <Wrapper>
      <GenericSelect 
        width="100%"
        selectedItems={props.value}
        groups={groups}
        onChange={props.onChange}
        placeholder="Absence Types"
      />
    </Wrapper>
  )
}

export default AbsenceTypesFilter