import React, { FC } from "react";
import styled from "styled-components";
import { Tooltip } from "antd";

interface IProps {
  data: any[];
  maxCount: number;
}

const UsersWrapper = styled.div`
  display: flex;
`;

const UserWrapperCircles = styled.div``;

const Avatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const User = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #d8c1f4;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #8f3ef7;
  margin: 0 2px 0 0;
  overflow: hidden;
`;

const HideUsers = styled(User)`
  background: #e6e6f7;
  color: #3a3aa3;
`;

const AllMembers = styled.p`
  color: rgb(255, 255, 255);
  margin-bottom: 5px;
  &.last {
    margin-bottom: 0;
  }
`;

export const TeamAvatarGroup: FC<IProps> = ({ data, maxCount }) => {
  const hasMoreUsers = data.length > maxCount;
  const grupTooltipText = data.map((user: any, index: number) => {
    const isLast = index === data.length - 1;
    return (
      <AllMembers key={index} className={isLast ? "last" : ""}>
        {user.firstName + " " + user.lastName}
      </AllMembers>
    );
  });

  return (
    <React.Fragment>
      <UsersWrapper>
        <UserWrapperCircles>
          <UsersWrapper>
            {data.slice(0, maxCount).map((user: any, index: number) => {
              const firstName = user.firstName.split(" ")[0].charAt(0).toUpperCase();
              const lastName = user.lastName.split(" ")[0].charAt(0).toUpperCase()

              return (
                <div key={index}>
                  <Tooltip placement="top" title={user.firstName + " " + user.lastName}>
                    <User>
                      {user.avatar ? (
                        <Avatar src={user.avatar} />
                      ) : (
                        firstName + lastName
                      )}
                    </User>
                  </Tooltip>
                </div>
              );
            })}
            {hasMoreUsers && (
              <Tooltip
                placement="top"
                overlayClassName={"test"}
                overlayStyle={{ lineBreak: "anywhere" }}
                overlayInnerStyle={{ maxHeight: "100px", minWidth: "150px", overflow: "scroll" }}
                title={grupTooltipText}
              >
                <HideUsers>+{data.length - maxCount}</HideUsers>
              </Tooltip>
            )}
          </UsersWrapper>
        </UserWrapperCircles>
      </UsersWrapper>
    </React.Fragment>
  );
};
