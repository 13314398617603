const Child = (props: React.ComponentProps<"svg">) => (
    <svg width="19px" height="20px" viewBox="0 0 19 20" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props} >
        <g id="Holidays-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="9.2.3-Create-absence-type-" transform="translate(-235.000000, -390.000000)">
                <g id="Group" transform="translate(216.000000, 284.000000)">
                    <g id="icon-/-application-/-dark-/-icon-1-copy-8" transform="translate(17.000000, 104.000000)">
                        <g id="baseline-child_friendly-24px">
                            <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                            <path d="M13,2 L13,10 L21,10 C21,5.58 17.42,2 13,2 Z M19.32,15.89 C20.37,14.54 21,12.84 21,11 L6.44,11 L5.49,9 L2,9 L2,11 L4.22,11 C4.22,11 6.11,15.07 6.34,15.42 C5.24,16.01 4.5,17.17 4.5,18.5 C4.5,20.43 6.07,22 8,22 C9.76,22 11.22,20.7 11.46,19 L13.54,19 C13.78,20.7 15.24,22 17,22 C18.93,22 20.5,20.43 20.5,18.5 C20.5,17.46 20.04,16.53 19.32,15.89 Z M8,20 C7.17,20 6.5,19.33 6.5,18.5 C6.5,17.67 7.17,17 8,17 C8.83,17 9.5,17.67 9.5,18.5 C9.5,19.33 8.83,20 8,20 Z M17,20 C16.17,20 15.5,19.33 15.5,18.5 C15.5,17.67 16.17,17 17,17 C17.83,17 18.5,17.67 18.5,18.5 C18.5,19.33 17.83,20 17,20 Z" id="Shape" fill="#474D55" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Child