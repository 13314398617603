import { Suspense } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import Loading from 'components/shared-components/Loading'
import { AUTH_PREFIX_PATH } from 'consts/theme-config'
import Login from "./authentication/login"
import RegisterPage from "./authentication/register"
import ResetPasswordPage from "./authentication/reset-password/reset-password-page"
import SelectAccountPage from "./authentication/select-account-page"
import CreateAccountPage from "./authentication/create-account/create-account-page"
import ConfirmEmailPage from "./authentication/confirm-email/confirm-email-page"
import ConfirmContactEmailPage from "./authentication/confirm-contact-email-page"
import PeekInvitationPage from "./authentication/invitation/peek-invitation-page"
import AuthConfirmInvitationPage from "./authentication/invitation/auth-confirm-invitation-page"
import ConfirmInvitationPage from "./authentication/invitation/confirm-invitation-page"
import AutoLoginPage from "./authentication/auto-login-page"
import AutoRegisterPage from "./authentication/auto-register-page"

export const AuthViews = () => {
  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        <Route path={`${AUTH_PREFIX_PATH}/auto-login`} component={AutoLoginPage} />
        <Route path={`${AUTH_PREFIX_PATH}/auto-register`} component={AutoRegisterPage} />

        <Route path={`${AUTH_PREFIX_PATH}/login`} component={Login} />
        <Route path={`${AUTH_PREFIX_PATH}/register`} component={RegisterPage} />
        <Route path={`${AUTH_PREFIX_PATH}/reset-password`} component={ResetPasswordPage} />
        <Route path={`${AUTH_PREFIX_PATH}/select-account`} component={SelectAccountPage} />
        <Route path={`${AUTH_PREFIX_PATH}/create-account`} component={CreateAccountPage} />
        
        { /* Confirming users email, e.g. after users email change */ }
        <Route path={`${AUTH_PREFIX_PATH}/confirm-email`} component={ConfirmEmailPage} />

        <Route path={`${AUTH_PREFIX_PATH}/invitation`} component={PeekInvitationPage} />
        <Route path={`${AUTH_PREFIX_PATH}/auth-confirm-invitation`} component={AuthConfirmInvitationPage} />
        <Route path={`${AUTH_PREFIX_PATH}/confirm-invitation`} component={ConfirmInvitationPage} />

        { /* Confirming users email, e.g. after users email change */ }
        <Route path={`${AUTH_PREFIX_PATH}/confirm-contact-email`} component={ConfirmContactEmailPage} />

        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  )
}

export default AuthViews
