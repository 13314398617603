import {message} from "antd"
import {useFormik} from "formik"
import useApiClient from "hooks/useApiClient"
import {useHistory} from "react-router-dom"
import { TApiAccountImport, TConfirmImportColumnsFormObject, TNewImportFormObject, TApiConfirmImportColumnsResponse, TConfirmImportProjectsFormObject, TApiImportConfirmProjectsResponse, TConfirmImportUsersFormObject, TFailedImportRow, TFailedImportColumn } from "./imports.types"
import _forEach from 'lodash/forEach'
import _find from 'lodash/find'
import {importProjectsMappingsFromProjectsMatchings} from "./imports.utils"
import findMostSimilarWord from "../utils/find-most-similar-word"
import {useState} from "react"
import { flatMap } from "lodash"
import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"

export const useNewImportFormik = () => {
  const apiClient = useApiClient()
  const history = useHistory()

  const formik = useFormik<TNewImportFormObject>({
    initialValues: {
      file: undefined,
      separator: ',',
    },
    onSubmit: async (values) => {
      const response = await apiClient.createImport(values)

      // TODO: standard error handling?
      if (!response.ok) {
        if (response.errors) {
          formik.setErrors(response.errors)

          if (response.errors?.base) {
            message.error(getApiErrorMessage(response, 'base'))
          }
        }
      }

      if (response.ok && response.accountImport) {
        history.push({
          pathname: `/imports/${response.accountImport.id}/configure-columns`,
          state: {
            accountImport: response.accountImport
          },
        })
      }

    }
  })

  return formik
}

export const useConfirmImportColumnsFormik = ({
  importId,
  accountImport,
}: {
  importId: string,
  accountImport: TApiAccountImport,
}) => {
  const apiClient = useApiClient()
  const history = useHistory()

  const [failedColumns, setFailedColumns] = useState<TFailedImportColumn[] | undefined>(undefined)
  const clearFailedColumns = () => setFailedColumns(undefined)

  // Convert column mappings array to Select compatible options prop
  const columnMappingOptions = accountImport.columnMappings.map(item => ({
    value: item.identifier,
    label: item.name
  }))

  // Create initial columns mapping as { 0: 'ignore', 1: 'ignore' etc... ) based on sample row length
  const columnsMapping: TConfirmImportColumnsFormObject['accountImport']['columnsMapping'] = {}

  // Assigning the default columnsMapping value based on words similiarity between the import data header
  // and available column mappings options
  const defaultMappingValue = columnMappingOptions[0].value
  _forEach(accountImport.sampleRows[0], (value, index) => {

    /* TODO: Improve matching to be correct, now comment out as is missleading sometimes 
    e.g. matching Date for Name column name

    const availableLabels = columnMappingOptions.map(mappingOption => mappingOption.label)
    const columnMappingLabel = findMostSimilarWord(value, availableLabels)

    const matchedMappingValue = _find(columnMappingOptions, (option) => option.label == columnMappingLabel)?.value


    columnsMapping[parseInt(index)] = matchedMappingValue || defaultMappingValue
    */

    // By default always use ignore as a default column value
    columnsMapping[parseInt(index)] = defaultMappingValue
  })

  const formik = useFormik<TConfirmImportColumnsFormObject['accountImport']>({
    initialValues: {
      columnsMapping: columnsMapping,
      ignoreHeader: true,
    },
    onSubmit: async (values) => {
      const response = await apiClient.confirmImportColumns(importId, values)

      // TODO: standard error handling?
      if (!response.ok) {
        // @ts-ignore
        if (!!response.failedRows) {
          // assign failed rows to state and extend with rowIndex value
          const apiFailedRows = response.failedRows as TFailedImportRow[]

          const failedRows = flatMap(apiFailedRows, (failedRow) => (
            failedRow.failedColumns.map((failedColumn) => ({
              ...failedColumn,
              rowIndex: failedRow.index,
            }))
          ))

          setFailedColumns(failedRows)
        }

        if (response.errors) {
          formik.setErrors(response.errors)

          if (response.errors?.base) {
            message.error(getApiErrorMessage(response, 'base'))
          }
        }
      }

      if (response.ok && response.projectsMatchings) {
        history.push({
          pathname: `/imports/${importId}/configure-projects`,
          state: {
            projectsMatchings: response.projectsMatchings,
          },
        })
      }

    }
  })

  return { 
    formik, 
    columnMappingOptions,
    failedColumns,
    clearFailedColumns,
  }
}


export const useConfirmImportProjectsFormik = ({
  importId,
  projectsMatchings,
}: {
  importId: string,
  projectsMatchings: TApiConfirmImportColumnsResponse['projectsMatchings'],
}) => {
  const apiClient = useApiClient()
  const history = useHistory()

  const formik = useFormik<TConfirmImportProjectsFormObject>({
    initialValues: {
      projectsMappings: importProjectsMappingsFromProjectsMatchings(projectsMatchings)
    },
    onSubmit: async (values) => {
      const response = await apiClient.confirmImportProjects(importId, values)

      // TODO: standard error handling?
      if (!response.ok) {
        if (response.errors) {
          formik.setErrors(response.errors)

          if (response.errors?.base) {
            message.error(getApiErrorMessage(response, 'base'))
          }
        }
      }

      if (response.ok && response.usersMatchings) {
        history.push({
          pathname: `/imports/${importId}/configure-users`,
          state: {
            usersMatchings: response.usersMatchings,
          },
        })
      }
    }
  })

  return formik 
}

export const useConfirmImportUsersFormik = ({
  importId,
  usersMatchings,
}: {
  importId: string,
  usersMatchings: TApiImportConfirmProjectsResponse['usersMatchings'],
}) => {
  const apiClient = useApiClient()
  const history = useHistory()

  const formik = useFormik<TConfirmImportUsersFormObject>({
    initialValues: {
      usersMatchings,
    },
    onSubmit: async (values) => {
      const response = await apiClient.confirmImportUsers(importId, values)

      // TODO: standard error handling?
      if (!response.ok) {

        if (response.errors) {
          formik.setErrors(response.errors)

          if (response.errors?.base) {
            message.error(getApiErrorMessage(response, 'base'))
          }
        }
      }

      if (response.ok && response.summary) {
        history.push({
          pathname: `/imports/${importId}/summary`,
          state: {
            summary: response.summary,
          },
        })
      }
    }
  })

  return formik
}
