import { useTopNavigationLinks } from "hooks/layout"
import { usePagePermissions } from "hooks/permissions.hooks"
import { Router } from "pages/routes"
import { useEffect, useState } from "react"

// Setup the top navigation for Absences Pages
export const useAbsencesNavigationLinks = (history: any) => {
  const pageRouter = new Router(history)
  const { permissions, loaded } = usePagePermissions()

  const baseLinks = [
    {
      label: 'CALENDAR',
      urlMatcher: "absences/calendar",
      onClick: () => { return pageRouter.goToAbsencesCalendar() },
    },
    {
      label: 'MY ABSENCES',
      urlMatcher: "absences/my-absences",
      onClick: () => { return pageRouter.goToMyAbsences() },
    },
  ]

  const managementLinks = [{
      label: 'TEAM ABSENCES',
      urlMatcher: "absences/requests",
      onClick: () => { return pageRouter.goToAbsenceRequests() },
    },
    {
      label: 'SETTINGS',
      urlMatcher: "absences/settings|absences/holidays",
      onClick: () => { return pageRouter.goToAbsenceSettings() },
    },
  ]

  const buildLinks = () => {
    return permissions.manageAbsenceRequests ? baseLinks.concat(managementLinks) : baseLinks
  }
  const [links, setLinks] = useState<any[]>(buildLinks())

  useEffect(() => {
    setLinks(buildLinks)
  }, [permissions.manageAbsenceRequests, loaded])

  useTopNavigationLinks(links)
}