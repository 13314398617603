import { QueryClient, useMutation, useQueryClient } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiProject, TApiTask } from "../../api-client/types"
import { TApiGenericError, TApiGenericResponse } from "../../timenotes-query/timenotes-query.types"
import { IBulkModifyTimeLogsParams } from "../../api-client/bulks-time-logs-api"

interface Args extends IBulkModifyTimeLogsParams {}

type Params = {}

interface Result {
}

export const useBulkUpdateTimeLogsMutation = (params?: Params) => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  const query = useMutation<Result, TApiGenericError<any>, Args>(
    ['time-logs', 'bulk-update'],
    async (args: Args) => {
      const response = await apiClient.bulkModifyTimeLogs(args)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {}
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('time-logs', { exact: false })
      }
    },
  )

  return query
}

export default useBulkUpdateTimeLogsMutation