import { TApiResponse, TApiParams, TApiGrouping, TApiRounding, TApiProject, TApiTag, TApiTask, TApiResponseMeta, TApiUsersAccount } from "./types"
import BaseApi, { getQueryString } from './base'
import _omit from 'lodash/omit'

export type TApiExportColumn = {
  name: string
  preselected: boolean
}

export type TApiReportsExportType = 'csv' | 'xls' | 'pdf'

export type TApiReportsDetailedRow = {
  id: string
  hashId?: string

  // User grouping
  firstName?: string
  lastName?: string

  // Client, Project, Task grouping
  name?: string

  // Is billable for time log
  isBillable?: boolean

  billableAmount: null | number
  costAmount: null | number
  description: null | string
  duration: number
  project: TApiProject
  tags: Array<TApiTag>
  task: TApiTask
  startAt: string
  endAt: string
  usersAccount: TApiUsersAccount
}

export type TApiReportsDetailedSorting = {
  projectName?: 'desc' | 'asc'
  taskName?: 'desc' | 'asc'
  clientName?: 'desc' | 'asc'
  userName?: 'desc' | 'asc'
  startAt?: 'desc' | 'asc'
  totalTime?: 'desc' | 'asc'
  costCents?: 'desc' | 'asc'
  billableCents?: 'desc' | 'asc'
}

export type TApiReportsDate = {
  from: string
  to?: string
  timespan: 'day' | 'week' | 'month' | 'year' | 'custom'
}

export type TApiPeriod = {
  from: string // Date 
  to: string // Date
}

export type TApiChartData = {
  color: Array<string>
  info: Array<string>
  labels: Array<string>
  values: Array<number>
}

export type TApiAuditFilter = {
  withoutDuration?: boolean
  withoutDescription?: boolean
  durationLongerThen?: number
  durationShorterThen?: number
  descriptionShorterThen?: number
  withoutTags?: boolean
  notWorkingDays?: boolean
  timeBeforeHour?: string
  timeAfterHour?: string
}

export type TApiReportsFilters = TApiAuditFilter & {
  usersAccountHashIds?: Array<string>
  membersGroupHashIds?: Array<string>
  clientHashIds?: Array<string>
  projectHashIds?: Array<string>
  taskHashIds?: Array<string>
  taskName?: string
  from?: string
  to?: string
  timespan?: string // day week month custom
  tagIds?: Array<string>
  description?: string
  isBillable?: boolean
}

export interface IGetReportsDetailedParams extends TApiParams {
  grouping?: TApiGrouping
  filters?: TApiReportsFilters
  rounding?: TApiRounding
  sorting?: TApiReportsDetailedSorting
}

export interface IGetReportsDetailedResponse extends TApiResponse {
  meta: TApiResponseMeta
  totals: {
    worktime: number
    worktimeBillable: number
    worktimeNonBillable: number
    billableAmount: null | {
      cents: number
      currencyIso: string
    }
    costAmount: null | {
      cents: number
      currencyIso: string
    }
  }
  rows: Array<TApiReportsDetailedRow>
}

export interface IGetReportsDetailedChartResponse extends TApiResponse, TApiChartData {
}

export interface IGetReportsDetailedExportColumnsResponse extends TApiResponse {
  exportColumns: Array<TApiExportColumn>
}

export interface IGetReportsDetailedExportParams extends IGetReportsDetailedParams {
  export: {
    type: TApiReportsExportType
    chartData?: string
    columns?: Array<string>
  }
}

class ReportsApi extends BaseApi {
  async getReportsDetailed(params?: IGetReportsDetailedParams): Promise<IGetReportsDetailedResponse> {

    const responseParams = { ...params }

    if (responseParams.filters?.timespan && responseParams.filters.timespan != 'custom') {
      responseParams.filters.to = responseParams.filters.from
    }

    let queryParams = { q: { s: this.prepareSortingParams((responseParams || {}).sorting) }, ..._omit(responseParams, ['sorting']) }
    const query = getQueryString(this.paramsToSnakeCase(queryParams), true)

    let response = await fetch(`${this.rootUrl}/reports/detailed${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async getReportsDetailedChart(params: IGetReportsDetailedParams): Promise<IGetReportsDetailedChartResponse> {
    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/reports/detailed/chart${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async getReportsDetailedExportColumns(params: IGetReportsDetailedParams = {}): Promise<IGetReportsDetailedExportColumnsResponse> {
    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/reports/detailed/export_columns${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async getReportsDetailedExport(params: IGetReportsDetailedExportParams) {
    let response = await fetch(`${this.rootUrl}/reports/detailed/export`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(this.paramsToSnakeCase(params)),
    })

    this.handleBlobDownload(response, `timenotes-report.${params.export.type}`)
  }
}

export default ReportsApi
