import { useEffect, useState } from 'react'

import _filter from 'lodash/filter'

import { message } from 'components/base'
import { Button, Card, Modal, Tooltip } from 'antd'
import { IActionsPanelProps } from 'components/shared/actions-panel'
import { useHistory, useParams } from 'react-router-dom'
import { useProjectQuery } from '@timenotes/shared/src/services/projects/projects.hooks'
import { useTopNavigationLinks } from 'hooks/layout'
import useApiClient from 'hooks/useApiClient'
import ProjectDetailsHeader from '../shared/components/project-details-header'
import LoadingSkeleton from './components/loading-skeleton'
import { SearchFilter } from '../list/components/project-filters/search-filter'
import { StateFilter } from '../list/components/project-filters/state-filter'
import TasksTable from './components/tasks-table/tasks-table'
import TaskForm from './components/task-form'
import { usePaginatedTasksQuery } from '@timenotes/shared/src/services/tasks/queries/use-paginated-tasks-query'
import useNoInitialEffect from 'hooks/useNoInitialEffect'
import { useCurrentProjectsPermissionsQuery } from '@timenotes/shared/src/services/permissions/permissions.hooks'
import { ICreateTaskParams, TApiTasksFilters, TApiTasksSorting } from '@timenotes/shared/src/services/api-client/tasks-api'
import { TApiTask } from '@timenotes/shared/src/services/api-client/types'
import { getApiErrorMessage } from '@timenotes/shared/src/services/api-client'

interface IState {
  loaded: boolean
  newTaskModalVisible: boolean
}

const ProjectsTasksPage = () => {
  const history = useHistory()
  const apiClient = useApiClient()

  const params = useParams<{
    projectId: string
  }>()
  const projectId = params.projectId as string

  const currentProjectsPermissionsQuery = useCurrentProjectsPermissionsQuery({
    params: {
      projectId: projectId
    }
  })
  const currentProjectsPermissions = currentProjectsPermissionsQuery.data?.permissions

  const [pageLoaded, setPageLoaded] = useState(false)
  const [newTaskModalVisible, setNewTaskModalVisible] = useState(false)

  useTopNavigationLinks([
    {
      label: "← BACK",
      onClick: () => {
        history.push('/projects')
      }
    }
  ])

  const projectQuery = useProjectQuery({ projectId: projectId })

  const [nameFilter, setNameFilter] = useState<string>()
  const [stateFilter, setStateFilter] = useState<'active' | 'archived' | undefined >()
  const [sorting, setSorting] = useState<TApiTasksSorting>()

  const filters: TApiTasksFilters = {
    name: nameFilter,
    state: stateFilter,
  }

  const {
    query: tasksQuery,
    page: page,
    perPage: perPage,
    setPage: setPage,
  } = usePaginatedTasksQuery({
    projectId: projectId,
    filters: filters,
    sorting: sorting,
  })

  useNoInitialEffect(() => {
    setPage(1)
  }, [nameFilter, stateFilter])

  useEffect(() => {
    if (projectQuery.isSuccess && tasksQuery.isSuccess) {
      setPageLoaded(true)
    }
  }, [projectQuery.isSuccess, tasksQuery.isSuccess])

  const getTaskActions = (task: TApiTask) => {
    const isArchived = task.state == 'archived'

    let actions: IActionsPanelProps = {
      icons: [],
      actions: [
        {
          title: 'Delete',
          icon: "delete",
          confirm: {
            title: 'Are you sure you want to delete this task?',
            content: 'This action can not be reversed!',
            okText: 'Delete',
            cancelText: 'Cancel',
          },
          action: () => {
            handleDeleteTask(task)
          }
        }
      ],
    }

    if (!isArchived) {
      actions.actions?.unshift({
        title: 'Archive',
        icon: "archive",
        action: () => {
          handleArchiveTask(task)
        }
      })
    } else {
      actions.actions?.unshift({
        title: 'Unarchive',
        icon: "archive",
        action: () => {
          handleUnarchiveTask(task)
        }
      })
    }

    return actions
  }

  const handleDeleteTask = (task: TApiTask) => {
    const isArchived = task.state == 'archived'

    apiClient.deleteTask(task.project.id, task.id).then((response) => {
      if (response.ok) {
        message.success('Task deleted successfully!')
        tasksQuery.refetch()
      } else {
        if (response.errors?.base) {
          const errorMessage: string = response.errors?.base[0]

          if (!isArchived) {
            Modal.confirm({
              title: 'Task can not be deleted!',
              content: `${errorMessage}.\n\n Would you like to archive this task instead?`,
              okText: 'Archive',
              onOk: () => { handleArchiveTask(task) }
            })
          } else {
            message.error(errorMessage)
          }
        } else {
          message.error('Something went wrong!')
        }
      }
    })
  }

  const handleUpdateTaskName = (task: TApiTask, newName: string) => {
    return apiClient.updateTask(task.project.id, task.id, { name: newName }).then((response) => {
      if (response.ok) {
        message.success('Task name has been updated!')
        tasksQuery.refetch()
      } else {
        if (response.status !== 422) {
          message.error('Something went wrong.')
        }
      }

      return response
    })
  }

  const handleArchiveTask = (task: TApiTask) => {
    apiClient.updateTask(task.project.id, task.id, { state: 'archived' }).then((response) => {
      if (response.ok) {
        message.success('Task has been archived!')
        tasksQuery.refetch()
      } else {
        message.error('Something went wrong.')
      }
    })
  }

  const handleUnarchiveTask = (task: TApiTask) => {
    apiClient.updateTask(task.project.id, task.id, { state: 'active' }).then((response) => {
      if (response.ok) {
        message.success('Task has been activated!')
        tasksQuery.refetch()
      } else {
        message.error('Something went wrong.')
      }
    })
  }

  const handleCreateTask = (values: ICreateTaskParams) => {
    const apiCall = apiClient.createTask(projectId, values)

    apiCall.then((response) => {
      if (response.ok) {
        tasksQuery.refetch()
        closeNewTaskModal()
        message.success(`New task "${values.name}" added!`)
      } else {
        message.error(getApiErrorMessage(response, 'name'))
      }

      return response
    })

    return apiCall
  }

  const onFormSubmit = (values: ICreateTaskParams) => {
    return handleCreateTask(values).then((response) => {
      if (response.ok) {
        closeNewTaskModal()
        tasksQuery.refetch()
      }

      return response
    })
  }

  const openNewTaskModal = () => {
    setNewTaskModalVisible(true)
  }

  const closeNewTaskModal = () => {
    setNewTaskModalVisible(false)
  }

  const project = projectQuery.data?.project
  const tasks = tasksQuery.data?.tasks
  const pagination = tasksQuery.data?.pagination

  return (
    <>
      <ProjectDetailsHeader
        projectId={projectId}
        activeSection="tasks"
      />

      <div className="page-content">
        <div>
          <Card>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <SearchFilter
                placeholder={'Filter tasks'}
                setSearchQuery={(val) => setNameFilter(val || "")}
              />

              <StateFilter
                value={stateFilter}
                onChange={setStateFilter}
              />

              <div style={{ display: 'flex', width: '50%', justifyContent: 'end' }}>
                <Tooltip title={!currentProjectsPermissions?.createCurrentProjectTasks ? "You have no permissions to create tasks in this project. This can be changed in the project settings." : undefined}>
                  <Button
                    type="primary"
                    onClick={openNewTaskModal}
                    disabled={!currentProjectsPermissions?.createCurrentProjectTasks}
                  >
                    Add new task
                  </Button>
                </Tooltip>
              </div>
            </div>

            {pageLoaded && tasks && project && pagination ? (
              <>
                <div style={{ marginTop: '20px' }}>
                  <TasksTable
                    tasks={tasks}
                    pagination={pagination}
                    perPage={perPage}
                    getTaskActions={getTaskActions}
                    onUpdateTaskName={handleUpdateTaskName}
                    onPageChange={setPage}
                    onSortingChange={setSorting}
                  />
                </div>

                <Modal
                  visible={newTaskModalVisible}
                  footer={null}
                  title={'Add new task'}
                  onCancel={closeNewTaskModal}
                  destroyOnClose={true}
                >
                  <TaskForm
                    onFormSubmit={onFormSubmit}
                    projectId={projectId}
                    projectOrigin={project.origin}
                    taskCreationPolicy={project.taskCreationPolicy}
                  />
                </Modal>

              </>
            ) : (
              <LoadingSkeleton />
            )}

          </Card>

        </div>
      </div>
    </>
  )
}

export default ProjectsTasksPage