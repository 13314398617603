import { FC } from "react";
import { formatTimeInMinutes } from "utils/time";

interface IProps {
  totalTime: number;
}

export const TotalTime: FC<IProps> = (totalTime) => {
  return <div>{formatTimeInMinutes(totalTime.totalTime)}</div>;
};
