import ApiClient from "@timenotes/shared/src/services/api-client"
import { Component, ErrorInfo, ReactNode } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import errorNotifier from "utils/error-notifier"
import ErrorTwo from "views/auth-views/errors/error-page-2"

type Props = RouteComponentProps & {
  children?: ReactNode
  apiClient?: ApiClient
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidUpdate(prevProps: Props) {
    // Reset error when user navigates to different page url
    if (this.props.location !== prevProps.location) {
      // Reset error state when the location changes
      this.setState({ hasError: false });
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo)

    errorNotifier.notify({
      error: error,
      params: {
        info: errorInfo,
        accessToken: `${this.props.apiClient?.accessToken}`,
        accountId: `${this.props.apiClient?.accountId}`,
      }
    })
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorTwo />
    } 

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary)