const Lunch = (props: React.ComponentProps<"svg">) => (
    <svg width="20px" height="19px" viewBox="0 0 20 19" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g id="Holidays-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="9.2.3-Create-absence-type-" transform="translate(-310.000000, -327.000000)">
                <g id="Group" transform="translate(216.000000, 284.000000)">
                    <g id="icon-/-application-/-dark-/-icon-1-copy-2" transform="translate(91.666667, 40.000000)">
                        <g id="baseline-local_dining-24px">
                            <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                            <path d="M8.1,13.34 L10.93,10.51 L3.91,3.5 C2.35,5.06 2.35,7.59 3.91,9.16 L8.1,13.34 Z M14.88,11.53 C16.41,12.24 18.56,11.74 20.15,10.15 C22.06,8.24 22.43,5.5 20.96,4.03 C19.5,2.57 16.76,2.93 14.84,4.84 C13.25,6.43 12.75,8.58 13.46,10.11 L3.7,19.87 L5.11,21.28 L12,14.41 L18.88,21.29 L20.29,19.88 L13.41,13 L14.88,11.53 Z" id="Shape" fill="#474D55" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Lunch