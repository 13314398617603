import { useHistory } from "react-router-dom"
import AuthLayout from "../../components/auth-layout"
import { useApiCall } from "hooks/useApiCall"
import useApiClient from "hooks/useApiClient"
import { useEffect } from "react"
import { Button, Divider, Spin } from "antd"
import { CreateAccountForm } from "./create-account-form"
import { useThemeConfig } from "services/layout/layout.hooks"
import { TApiAccount } from "@timenotes/shared/src/services/api-client/accounts-api"

const CreateAccountPage = () => {
  const { themeConfig } = useThemeConfig()
  const theme = themeConfig.currentTheme
  const history = useHistory()
  const apiClient = useApiClient()

  apiClient.loadAccessCredentials()

  if (!apiClient.accessToken) {
    history.push('/auth/login')
  }

  const {
    data: accounts,
    loading,
    request: fetchAccounts,
  } = useApiCall(async () => {
    return (await apiClient.getAccountsList()).accounts
  })

  useEffect(() => {
    fetchAccounts()
  }, [])

  const onSelectAccount = (account: TApiAccount) => {
    apiClient.setAccessCredentials({ accountId: account.id })
    history.push('/timer')
  }

  const onCreateWorkspace = () => {
    history.push('/auth/create-account')
  }

  return (
    <AuthLayout>
      <div className="text-center">
        <img className="img-fluid" src={`/img/${theme === 'light' ? 'logo.png' : 'logo-white.png'}`} alt="" />
        <h3>Create workspace</h3>
      </div>
      <div>
        <div>
          {loading ? (
            <div 
              style={{
                display: 'flex',
                  justifyContent: 'center',
              }}
            >
              <Spin />
            </div>
          ) : (
            <>
              <CreateAccountForm />
            </>
          )}
        </div>
      </div>

      <div>
        <Divider>
          <span className="text-muted font-size-base font-weight-normal">or select existing one</span>
        </Divider>
        <div className="d-flex justify-content-center">
          <Button
            onClick={() => history.push('/auth/select-account')}
            className="mr-2"
            style={{
              width: '100%',
            }}
          >
            Select workspace
          </Button>
        </div>
      </div>
    </AuthLayout>
  )
}

export default CreateAccountPage
