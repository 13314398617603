import { TApiClient, TApiResponse, TApiProject, TApiIntegrationAccount } from "./types"
import BaseApi from './base'

export interface ICreateProjectParams extends TApiProject {
  clientId: null | string
}

export interface ICreateProjectResponse extends TApiResponse {
  project: TApiProject
}

class IntegrationsApi extends BaseApi {
  async createIntegrationAccount(params: { 
    integrationId: string
    integrationAccountName: string
    integrationAccountOriginId: string
    integrationAccountAutoSyncProjects: boolean
    integrationAccountWebhookable: boolean
  }) {
    const requestBody = {
      integration_account: {
        name: params.integrationAccountName,
        origin_identifier: params.integrationAccountOriginId,
        auto_sync_projects: params.integrationAccountAutoSyncProjects,
        webhookable: params.integrationAccountWebhookable
      }
    }

    let response = await fetch(
      `${this.rootUrl}/integrations/${params.integrationId}/integration_accounts`,
      {
        method: 'POST',
        headers: this.headers,
        body: JSON.stringify(requestBody)
      }
    )

    return await this.getResponseJson(response)
  }

  // @ts-ignore
  async createIntegrationAccountProjects({ params }) {
    // console.log('Create Integration Account Projects from integration', this)

    const requestBody = { 
      projects: params.projects,
    }

    let response = await fetch(
      `${this.rootUrl}/integration_accounts/${params.integrationAccountId}/projects/bulk_create_external`,
      {
        method: 'POST',
        headers: this.headers,
        body: JSON.stringify(requestBody)
      }
    )

    return await this.getResponseJson(response)
  }

  async getConnectedIntegrationAccountProjects(params: { integrationAccountId: string } ) {
    let response = await fetch(
      `${this.rootUrl}/integration_accounts/${params.integrationAccountId}/projects/all`,
      {
        method: 'GET',
        headers: this.headers,
      }
    )

    return await this.getResponseJson(response)
  }

  async updateIntegrationAccount(params: { 
    integrationAccountId: string
    integrationAccount: Partial<TApiIntegrationAccount>
  }) {

    const requestBody = { integrationAccount: params.integrationAccount }
    return this.patch(`/integration_accounts/${params.integrationAccountId}`, requestBody)
  }

  // @ts-ignore
  async getAllIntegrationAccounts(params: { integrationId: null | string }) {
    // console.log('GET All Integration Workspaces', this)

    const requestUrl = params.integrationId ? 
        `${this.rootUrl}/integrations/${params.integrationId}/integration_accounts/all_with_external`
      :
        `${this.rootUrl}/integration_accounts`

    let response = await fetch(
      requestUrl,
      {
        method: 'GET',
        headers: this.headers,
      }
    )

    return await this.getResponseJson(response)
  }

  // @ts-ignore
  async synchroniseIntegrationAccount({ integrationAccountId }) {
    // console.log('INTEGRATION ACCOUNT SYNC REQUEST', this)

    let response = await fetch(`${this.rootUrl}/integration_accounts/${integrationAccountId}/synchronise`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  // @ts-ignore
  async disconnectIntegrationAccount({ integrationAccountId }) {
    // console.log('INTEGRATION ACCOUNT DISCONNECT REQUEST', this)

    let response = await fetch(`${this.rootUrl}/integration_accounts/${integrationAccountId}/disconnect`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  // @ts-ignore
  async reconnectIntegrationAccount({ integrationAccountId }) {
    // console.log('INTEGRATION ACCOUNT RECONNECT REQUEST', this)

    let response = await fetch(`${this.rootUrl}/integration_accounts/${integrationAccountId}/reconnect`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  // @ts-ignore
  async createIntegrationAccountProjects({ params }) {
    // console.log('Create Integration Account Projects from integration', this)

    const requestBody = { 
      projects: params.projects,
    }

    let response = await fetch(
      `${this.rootUrl}/integration_accounts/${params.integrationAccountId}/projects/bulk_create_external`,
      {
        method: 'POST',
        headers: this.headers,
        body: JSON.stringify(requestBody)
      }
    )

    return await this.getResponseJson(response)
  }

  async getIntegrationAccount(params: { integrationAccountId: string }) {
    // console.log('GET Integration Account by id', this)

    let response = await fetch(
      `${this.rootUrl}/integration_accounts/${params.integrationAccountId}`,
      {
        method: 'GET',
        headers: this.headers,
      }
    )

    return await this.getResponseJson(response)
  }

  // @ts-ignore
  async synchroniseIntegrationAccount({ integrationAccountId }) {
    // console.log('INTEGRATION ACCOUNT SYNC REQUEST', this)

    let response = await fetch(`${this.rootUrl}/integration_accounts/${integrationAccountId}/synchronise`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  // @ts-ignore
  async disconnectIntegrationAccount({ integrationAccountId }) {
    // console.log('INTEGRATION ACCOUNT DISCONNECT REQUEST', this)

    let response = await fetch(`${this.rootUrl}/integration_accounts/${integrationAccountId}/disconnect`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  // @ts-ignore
  async reconnectIntegrationAccount({ integrationAccountId }) {
    // console.log('INTEGRATION ACCOUNT RECONNECT REQUEST', this)

    let response = await fetch(`${this.rootUrl}/integration_accounts/${integrationAccountId}/reconnect`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  // @ts-ignore
  async deleteIntegrationAccount({ integrationAccountId }) {
    // console.log('INTEGRATION ACCOUNT DELETE REQUEST', this)

    let response = await fetch(`${this.rootUrl}/integration_accounts/${integrationAccountId}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  // @ts-ignore
  async getAllIntegrationAccountProjects({ params }) {
    // console.log('GET All Integration Account Projects', this)

    let response = await fetch(
      `${this.rootUrl}/integration_accounts/${params.integrationAccountId}/projects/all_with_external`,
      {
        method: 'GET',
        headers: this.headers,
      }
    )

    return await this.getResponseJson(response)
  }

  async deleteIntegration(params = {}) {
    console.error('detele attrs', params)
    // @ts-ignore
    let integrationId = params.integrationId

    // console.log('DELETE INTEGRATION REQUEST', this)

    let response = await fetch(`${this.rootUrl}/integrations/${integrationId}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async getConnectedIntegrationApps({ params = {} } = { params: {} }) {
    // console.log('GET Connected Integration Apps', this)

    let response = await fetch(
      `${this.rootUrl}/integrations?per_page=1000`,
      {
        method: 'GET',
        headers: this.headers,
      }
    )

    return await this.getResponseJson(response)
  }

  async getAvailableIntegrationApps({ params = {} } = { params: {} }) {
    let response = await fetch(
      `${this.rootUrl}/integrations/available?per_page=1000`,
      {
        method: 'GET',
        headers: this.headers,
      }
    )

    return await this.getResponseJson(response)
  }
}

export default IntegrationsApi
