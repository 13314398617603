import { Button, Card, Divider, Modal, Skeleton, message } from "antd"
import { COLORS } from "components/layout-components/timenotes-theme"
import BillingInfoForm from "pages/settings/components/billing-info-form"
import CreditCardForm from "pages/settings/components/credit-card-form"
import { useBillingInfo } from "hooks/billing-info"
import { useApiCall, useGetApiCall } from "hooks/useApiCall"
import useApiClient from "hooks/useApiClient"
import { map } from "lodash"
import { useEffect, useState } from "react"
import { ICreateSubscriptionParams } from "@timenotes/shared/src/services/api-client/subscriptions-api"
import { TApiPlan } from "@timenotes/shared/src/services/api-client/types"
import { TApiBillingInfo } from "@timenotes/shared/src/services/api-client/billing-infos-api"
import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"
import DeleteWorkspaceButton from "../components/delete-workspace-button"

const BillingPlanSection = () => {
  const apiClient = useApiClient()

  const {
    data: plans,
    loading: plansLoading,
    request: fetchPlans,
  } = useGetApiCall(async () => (await apiClient.getPlans()).plans)

  const {
    data: currentSubscription,
    loading: currentSubscriptionLoading,
    request: fetchCurrentSubscription,
  } = useGetApiCall(async () => (await apiClient.getSubscriptionPeriodsCurrent()).subscriptionPeriod)

  const {
    data: paymentMethod,
    loading: paymentMethodLoading,
    request: fetchPaymentMethod,
  } = useGetApiCall(async () => (await apiClient.getPaymentMethod()).paymentMethod)

  const [forceReloading, setForceReloading] = useState(false)

  const {
    billingInfo,
    loading: billingInfoLoading,
    fetchBillingInfo,
  } = useBillingInfo()

  const {
    data: createdSubscription,
    loading: createSubscriptionLoading,
    request: createSubscription,
  } = useApiCall(async (params: ICreateSubscriptionParams) => (await apiClient.createSubscription(params)))

  const [billingInfoVisible, setBillingInfoVisible] = useState(false)
  const [paymentMethodVisible, setPaymentMethodVisible] = useState(false)

  // state variable to keep selected new plan in case the billing info or payment plan is missing
  // and a multi step flow is necessary
  const [newSelectedPlan, setNewSelectedPlan] = useState<TApiPlan>()

  const loading = plansLoading || currentSubscriptionLoading || paymentMethodLoading || billingInfoLoading || forceReloading
  const currentPlan = currentSubscription?.plan

  const handleBillingInfoUpdate = async (newBillingInfo: TApiBillingInfo) => {
    const response = await apiClient.updateBillingInfo(newBillingInfo)

    if (response.ok) {
      await fetchBillingInfo()
      setBillingInfoVisible(false)
    }

    return response
  }

  const handleAddPaymentMethod = async () => {
    setPaymentMethodVisible(false)
    await fetchPaymentMethod()
  }

  const handleSelectPlan = async (plan: TApiPlan) => {
    setNewSelectedPlan(plan)
  }

  const triggerPlanChange = async (plan: TApiPlan) => {
    // If billing info and payment method exists - subscribe for the plan
    const response = await createSubscription({ planId: plan.id })

    if (response.ok) {
      message.success(`Successfully switched to the ${plan.name} plan!`)
    } else {
      message.error(getApiErrorMessage(response, 'base'))
    }

    setForceReloading(true)

    setTimeout(() => {
      fetchCurrentSubscription()
      fetchPlans()
      fetchPaymentMethod()
      setForceReloading(false)
    }, 2500)
 }

  useEffect(() => {
    if (newSelectedPlan) {
      if (!billingInfo) {
        setBillingInfoVisible(true)
        return
      }

      if (!paymentMethod) {
        setPaymentMethodVisible(true)
        return
      }

      // if new plan is the same as current - do nothing
      if (newSelectedPlan.id == currentPlan?.id) {
        return
      }

      // Finally trigger actual change if all other conditions are met
      triggerPlanChange(newSelectedPlan)
   }
  }, [newSelectedPlan, paymentMethod?.id, billingInfo])

  return (
    <div>
      {loading ? (
        <>
          <Skeleton active paragraph={{ rows: 0}} />
          <Divider/>
          <Skeleton active />
        </>
      ) : (
        <>
          <h2 style={{ textAlign: 'center' }}>
            Now you are using <b>{currentPlan?.name}</b>.
          </h2>

          <Divider />

          <div
            style={{
              display: 'flex',
              gap: '60px',
              justifyContent: 'center',
              marginTop: '40px',
            }}
          >
            {map(plans, (plan) => (
              <Card
                key={plan.id}
                style={{
                  boxShadow: `0 0 30px 0 ${plan.id == currentPlan?.id ? COLORS.primaryPurple : '#ccc'}`,
                  border: 'none',
                  width: '300px',
                  textAlign: 'center',
                }}
              >
                <h2>
                  {plan.name}
                </h2>
                <h3>
                  { plan.timeSpan && (
                    <>${parseInt(`${plan.price}`)}/{plan.timeSpan.timeSpanPeriod}</>
                  )}
                </h3>
                <div
                  style={{
                    textAlign: 'center',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: plan.description,
                  }}
                />

                {currentPlan?.id == plan.id ? (
                  <>
                  {!paymentMethod ? (
                    <>
                     <Button 
                      type="primary"
                      onClick={() => setNewSelectedPlan(plan) }
                    >
                      Add Payment Method
                    </Button>
                      <p style={{
                        color: 'red'
                      }}> 
                        Payment method missing!
                      </p>
                    </>
                  ) : (
                    <Button disabled>Current Plan</Button>
                  )}
                  </>
                ) : (
                  <Button 
                    type="primary"
                    loading={createSubscriptionLoading}
                    onClick={() => handleSelectPlan(plan) }
                  >
                    Select Plan
                  </Button>
                )}
              </Card>
            ))}
          </div>

          <Divider />
          
          <div>
            <DeleteWorkspaceButton />
          </div>
 
        </>
      )}

      <Modal
        title="Please add billing info first"
        onCancel={() => {
          setBillingInfoVisible(false) 
          setNewSelectedPlan(undefined)
        }}
        visible={billingInfoVisible}
        footer={false}
        destroyOnClose={true}
        >
          <p> For a tax reasons, we need you to provide the billing information before selecting the plan. </p>
          <Divider />
          <BillingInfoForm
            initialValues={billingInfo}
            onSubmit={handleBillingInfoUpdate}
          />
      </Modal>

       <Modal
        visible={paymentMethodVisible}
        onCancel={() => {
          setPaymentMethodVisible(false)
          setNewSelectedPlan(undefined)
        }}
        footer={false}
        title="Add a payment method"
        destroyOnClose={true}
      >
        <p>Please add a payment method in order to subscribe to the new plan.</p>
        <CreditCardForm
          onSubmit={handleAddPaymentMethod}
          onCancel={() => {
            setPaymentMethodVisible(false)
            setNewSelectedPlan(undefined)
          }}
        />
      </Modal>
   </div>
  )
}

export default BillingPlanSection