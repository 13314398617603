import moment from 'moment'
import Conver from 'utils/convert'

import Time, { formatTimeInMinutes } from 'utils/time'
import { pad } from 'components/forms/time-input'

class Duration {

  constructor(minutes = undefined) {
    if (minutes == undefined) {
      minutes = 0
    }

    this.minutes = minutes
  }

  getHours() {
    return (this.minutes / 60).toFixed(2)
  }

  getMintues() {
    return this.minutes
  }

  toDurationString() {
    return formatTimeInMinutes(this.minutes)
  }

  toWatchString() {
    const hours = Math.floor(this.minutes / 60)
    const minutes = this.minutes % 60

    return `${hours}:${pad(minutes, 2)}`
  }

  toHoursString() {
    return `${(this.minutes / 60).toFixed(0)}h`
  }

  static fromMinutes(minutes) {
    return new Duration(minutes)
  }

}

export default Duration
