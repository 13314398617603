import { ZoomInOutlined } from "@ant-design/icons"
import { DatePicker, Divider, message, Select, Tag } from "antd"
import GenericButtonsList, { TGenericButton } from "components/generic-buttons-list/generic-buttons-list"
import GenericTable, { GenericTableParams } from "components/generic-table/generic-table"
import { COLORS } from "components/layout-components/timenotes-theme"
import PageHeader from "components/layout/page-header"
import moment from "moment-timezone"
import useApprovalsNavigation from "./hooks/use-approvals-navigation"
import UsersAccountSelect from "components/timenotes/users-accounts/users-account-select"
import { useCurrentUser } from "hooks/current-user"
import ApprovalRequestsTable from "./components/approval-requests-table"
import PeriodStatusSelect from "./components/period-status-select"
import { useApprovalRequestsQuery } from "@timenotes/shared/src/services/approvals/queries/use-approval-requests-query"
import { useEffect, useState } from "react"
import { TApiUsersAccount } from "@timenotes/shared/src/services/api-client/types"
import { usePagePermissions } from "hooks/permissions.hooks"
import { TApiApprovalRequestStatus } from "@timenotes/shared/src/services/approvals/approvals-types"
import useSearchParams from "hooks/use-search-params"
import useNoInitialEffect from "hooks/useNoInitialEffect"
import { useUsersAccountQuery } from "@timenotes/shared/src/services/users-accounts/queries/use-users-account-query"

const ApprovalsMembersPage = () => {

  const { permissions } = usePagePermissions()
  const canManageApprovals = permissions?.manageApprovals || false

  useApprovalsNavigation()

  const [searchParams, setSearchParams] = useSearchParams<{ 
    year?: string 
    userId?: string
    status?: TApiApprovalRequestStatus
  }>()

  const { currentUser } = useCurrentUser()

  const [filterUser, setFilterUser] = useState<TApiUsersAccount>(currentUser as TApiUsersAccount)

  const { usersAccountQuery } = useUsersAccountQuery({
    usersAccountId: searchParams.userId
  }, {
    enabled: false,
    onSuccess: (data) => {
      if (data.usersAccount) {
        setFilterUser(data.usersAccount)
      }
    }
  })

  // On start if searchParams.userId exists, try to fetch the user and select him in filters
  useEffect(() => {
    usersAccountQuery.refetch()
  },[])

  useNoInitialEffect(() => {
    setSearchParams({
      userId: filterUser.id
    })
  }, [filterUser.id])

  const title = (!canManageApprovals || (currentUser?.id == filterUser?.id)) ? 'My Approvals Summary' : 'Member Approvals Summary'

  const filterYear = searchParams.year || moment().format('YYYY')
  const filterStatus = searchParams.status
  const filterUserId = searchParams.userId || currentUser?.id

  const {
    approvalRequests,
    approvalRequestsQuery,

    approvalRequestsFilters: filters,
    updateApprovalRequestsFilters,
  } = useApprovalRequestsQuery({
    filters: {
      year: filterYear,
      usersAccountId: filterUserId,
      status: filterStatus,
    }
  })

  useEffect(() => {
    updateApprovalRequestsFilters({
      year: filterYear,
      status: filterStatus,
      usersAccountId: filterUserId,
    })
  }, [filterYear, filterStatus, filterUserId])

  const isLoading = approvalRequestsQuery.isFetching && approvalRequests.length == 0

  return (
    <>
      <div className="page-content">
        <PageHeader
          title={title}
          titleHint={""
            //"Your workspace is configured to work with 'monthly' periods. All the timesheets can be reviewed and approved in the 'monthly' intervals. You can change the settings, including locking individual periods in the workspace settings section. All the approved time logs per team member will be locked and can not be modified, without reopening."
          }
          customHeader={(
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                gap: '15px',
                width: '100%',
              }}
            >
              <PeriodStatusSelect 
                value={filters?.status}
                onChange={(val) => {
                  setSearchParams({ status: val as TApiApprovalRequestStatus })
                }}
              />

              { canManageApprovals && (
                <UsersAccountSelect
                  loading={usersAccountQuery.isLoading}
                  style={{
                    width: '200px',
                  }}
                  value={filterUser}
                  onChange={setFilterUser}
                />
              )}

              <DatePicker.YearPicker
                value={moment(filters?.year)}
                onChange={(val) => {
                  if (moment.isMoment(val)) {
                    setSearchParams({ year: val.format('YYYY') })
                  }
                }}
              />
           </div>
          )}
        />

        <Divider />

        <ApprovalRequestsTable 
          isSingleUserTable
          status="open"
          approvalRequests={approvalRequests}
          isLoading={isLoading}
        />

      </div>
    </>
  )
}

export default ApprovalsMembersPage