import { UseQueryOptions, useMutation, useQuery } from "react-query"
import { TApiProject, TApiResponseMeta, TApiTask } from "../api-client/types"
import { TApiGenericError, TApiGenericErrors, TApiGenericResponse } from "../timenotes-query/timenotes-query.types"
import { IGetUsersAccountsOwnedParams, IGetUsersAccountsParams } from "../../api-client/users-accounts-api"
import { TApiUsersAccount } from "../../api-client/types"
import { useApiClient } from "../../api-client/api-client.hooks"


interface IUseUsersAccountsQueryParams extends IGetUsersAccountsOwnedParams {
}

type Result = {
  usersAccounts: TApiUsersAccount[]
}

const PER_PAGE = 50

export const useUsersAccountsQuery = (params: IUseUsersAccountsQueryParams, options: UseQueryOptions<TApiGenericResponse<TApiUsersAccount[]>, TApiGenericErrors<null>> = {}) => {
  const apiClient = useApiClient()

  const query = useQuery<Result, TApiGenericError<null>>(
    ['users-accounts', params.filters],
    async () => {
      const response = await apiClient.getUsersAccountsForFilters({ ...params, perPage: PER_PAGE })

      if (!response.ok) {
        throw response.errors
      }

      return {
        usersAccounts: response.usersAccounts
      }
    },
    {
      ...options
    }
  )

  return query
}
