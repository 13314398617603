import { TApiClient, TApiResponse, TApiActiveTracker, TApiTimeLog, TApiProject, TApiTask } from "./types"
import BaseApi from './base'

export const importProjectsMappingsFromProjectsMatchings = (projectsMatchings: TApiConfirmImportColumnsResponse['projectsMatchings']) => {
  const importProjectsMappings = projectsMatchings.reduce((acc: any, cur: any) => {
    const { projects, givenClientName, projectsOptions } = cur

    projects.forEach((project: any) => {
      const key = `${givenClientName}##${project.name}`

      acc.push({
        projectName: project.name,
        clientName: givenClientName,
        mapping: project.mapping,
        projectsOptions,
        key,
      })
    })
    return acc
  }, [])

  return importProjectsMappings as TImportProjectMapping[]
}

export const confirmProjectsPayloadFromProjectsMappings = (projectsMappings: TImportProjectMapping[]) => {
  return projectsMappings.reduce((payload, currentMapping) => {

    // @ts-ignore
    const currentMappingClientPayload = payload[currentMapping.clientName] || {}

    return { 
      ...payload,
      [currentMapping.clientName]: {
        ...currentMappingClientPayload,
        [currentMapping.projectName]: currentMapping.mapping,
      }
    }
  }, {})
}

export type TNewImportFormObject = {
  file?: any,
  separator: string,
}

export type TConfirmImportColumnsFormObject = {
  accountImport: {
    columnsMapping: Record<number, string>
    ignoreHeader: boolean
  }
}

// Confirm Projects 
//
export type ImportProjectOption = {
  value: string
  label: string
}

export type ImportProject = {
  name: string
  mappingText: string
  mapping: string
}

export type ImportProjectMatching = {
  projects: ImportProject[]
  projectsOptions: ImportProjectOption[]
  givenClientName: string
}

export type TImportProjectMapping = {
  key: string
  projectName: string
  clientName: string
  mapping: string
  projectsOptions: ImportProjectOption[]
}

export type TConfirmImportProjectsFormObject = {
  projectsMappings: TImportProjectMapping[]
}

export type TApiConfirmImportColumnsResponse = TApiResponse & {
  projectsMatchings: {
    projects: ImportProject[];
    projectsOptions: ImportProjectOption[];
    givenClientName: string;
  }[]
}

//

export type TApiAccountImport = {
  id: string,
  columnMappings: {
    identifier: string,
    name: string,
  }[],
  sampleRows: any // table of hashes 0: 'string', 1: 'string'
}

export type TImportUsersMapping = {
  mapping: string
  options: Array<{ value: string; label: string }>
  email: string
}

export type TConfirmImportUsersFormObject = Pick<TApiImportConfirmProjectsResponse, 'usersMatchings'>

export type TApiConfirmUsersMatchingsBody = {
  [email: string]: string
}

export type TApiImportConfirmProjectsResponse = TApiResponse & {
  usersMatchings: TImportUsersMapping[]
}

export type TApiImportsSummary = {
  tasks: number
  clients: number
  projects: number
  users: number
  timeLogs: number
}

export type TApiImportConfirmUsersResponse = TApiResponse & {
  summary: TApiImportsSummary
}

export type TFailedImportColumn = {
  reasonDescription: string
  columnName: string
  mapping: string
  optionMapping: string
  value: string
  rowIndex?: number
}

export type TFailedImportRow = {
  failedColumns: TFailedImportColumn[]
  index: number
}

export type TApiFailedImportRowsResponse = TApiResponse & {
  failedRows: TFailedImportRow[]
}


export type TApiActivityUser = {
  name: string
  tracker: TApiActiveTracker // or {}
  timeLogs: Array<TApiTimeLog>
  project?: TApiProject
  client?: TApiClient
}

export type TApiActivityTotals = {
  worktime: number
  client?: Array<TApiClient>
  project?: Array<TApiProject>
}

export type TApiActivityTops = {
  project: TApiProject
  client?: TApiClient
  task?: TApiTask
}

export type TApiActivitiesFilters = {
  from: string
  timespan: 'day' | 'week' | 'month'
  membersGroupHashIds?: Array<string>
  usersAccountHashIds?: Array<string>
}

export interface IGetActivitiesParams {
  filters?: TApiActivitiesFilters
  groupBy?: 'project' | 'client'
}

export interface IGetActivitiesResponse extends TApiResponse {
  active: Array<TApiActivityUser>
  inactive: Array<TApiActivityUser>
}

class ActivitiesApi extends BaseApi {
  async getActivities(params: IGetActivitiesParams) {
    return this.get(`/activities/dashboard`, params)
  }

  async getImportsSampleFile(): Promise<any> {
    const response = await fetch(`${this.rootUrl}/imports/sample`, {
      // headers: this.headers,
      headers: {
        'AuthorizationToken': this.accessToken as string,
        'AccountId': this.accountId as string,
      },
    })

    return this.handleBlobDownload(response, 'timenotes_sample_import_file.csv')
  }

  async createImport(params: TNewImportFormObject): Promise<TApiResponse & {
    accountImport: TApiAccountImport
  }> {
    const data = new FormData()

    data.append('account_import[file]', params.file)
    data.append('account_import[separator]', params.separator)

    const response = await fetch(`${this.rootUrl}/imports`, {
      method: 'POST',

      // headers: this.headers,
      headers: {
        'AuthorizationToken': this.accessToken as string,
        'AccountId': this.accountId as string,
      },

      body: data,
    })

    return this.getResponseJson(response)
  }

  async confirmImportColumns(importId: string, params: TConfirmImportColumnsFormObject['accountImport']): Promise<TApiConfirmImportColumnsResponse & Partial<TApiFailedImportRowsResponse>> {

    const response: TApiConfirmImportColumnsResponse = await this.patch(`/imports/${importId}/confirm_columns`, {
      accountImport: params,
    })

    if (!response.ok) return response

    const parsedPayload = {
      ...response,
      projectsMatchings: response.projectsMatchings.map(matching => ({
        ...matching,
        projectsOptions: matching.projectsOptions.map(option => ({
          // @ts-ignore
          value: option[0],
          // @ts-ignore
          label: option[1]
        }))
      }))
    };

    return parsedPayload 
  }

  async confirmImportProjects(importId: string, params: TConfirmImportProjectsFormObject): Promise<TApiImportConfirmProjectsResponse> {

    const projectsMatchings = confirmProjectsPayloadFromProjectsMappings(params.projectsMappings)

    const response: any = await this.patch(`/imports/${importId}/confirm_projects`, {
      account_import: projectsMatchings,
      // turning off snake case as this particular API uses JSON keys to determine the values
    }, true)

    if (!response.ok) return response

    const adjustedPayload = {
      ...response,
      usersMatchings: response.usersMatchings.map((userMatching: any) => ({
        ...userMatching,
        // @ts-ignore
        options: userMatching.options.map(([value, label]) => ({ value, label }))
      }))
    }

    return adjustedPayload
  }

  async confirmImportUsers(importId: string, params: TConfirmImportUsersFormObject): Promise<TApiImportConfirmUsersResponse> {
    const usersMatchings = params.usersMatchings

    const confirmUsersBody = usersMatchings.reduce<TApiConfirmUsersMatchingsBody>((acc, { email, mapping }) => {
      acc[email] = mapping
      return acc
    }, {})

    const response: any = await this.patch(`/imports/${importId}/confirm_users`, {
      account_import: confirmUsersBody,
      // turning off snake case as this particular API uses JSON keys to determine the values
    }, true)

    return response
  }



}

export default ActivitiesApi
