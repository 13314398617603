import useBulkDeleteTimeLogsMutation from "@timenotes/shared/src/services/time-logs/queries/use-bulk-delete-time-logs-mutation"
import { Modal, ModalProps, message } from "antd"
import useApiClient from "hooks/useApiClient"
import { useEffect, useState } from "react"
import useQueryGenericErrorMessage from "services/utils/hooks/use-query-generic-error-message"

type useBulkDeleteProps = ModalProps & {
  ids: string[]
}

export const useBulkDeleteTimeLogs = ({ ids, ...props }: useBulkDeleteProps) => {
  const [open, setOpen] = useState(false)
  const apiClient = useApiClient()

  const bulkDeleteTimeLogsMutation = useBulkDeleteTimeLogsMutation()

  useEffect(() => {
    if (open && ids.length == 0) {
      setOpen(false)
      message.error('No time logs selected for bulk delete!')
    }
  }, [open])

  useEffect(() => {
    if (bulkDeleteTimeLogsMutation.isSuccess) {
      if (props.onOk) props.onOk(undefined as any)
      setOpen(false)
    }
  }, [bulkDeleteTimeLogsMutation.isLoading])
  useQueryGenericErrorMessage(bulkDeleteTimeLogsMutation)

  const handleBulkDelete = async () => {
   bulkDeleteTimeLogsMutation.mutate({ ids: ids }) 
  }

  const BulkDeleteModal = (
    <Modal 
      className="danger-modal"
      visible={open}
      confirmLoading={bulkDeleteTimeLogsMutation.isLoading}
      okType="danger"
      okText="Delete"
      title='Delete selected time logs'
      {...props}

      onOk={handleBulkDelete}
      onCancel={(e) => {
        if (props.onCancel) props.onCancel(e)
        setOpen(false)
      }}
    >
      <span>Are you sure you want to delete {ids.length} selected time logs?</span>
    </Modal>
  )

  return {
    modal: BulkDeleteModal,
    open: open,
    setOpen: setOpen,
  }
}