import { Badge } from "antd"
import TagsFilter, { TagsFilterProps } from "../timenotes/filters/tags-filter"
import { TagOutlined } from '@ant-design/icons'

const TagsSelect = TagsFilter

export const TagsSelectIcon = (props: TagsFilterProps) => {
  return (
    <TagsFilter {...props}>
      <Badge count={props.values?.length} offset={[3, -3]} size="default">
        <TagOutlined
          style={{ fontSize: '25px', marginTop: '0px', cursor: 'pointer' }}
        />
      </Badge>
    </TagsFilter>
  )
}

export default TagsSelect