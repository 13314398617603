import { Select } from "antd"
import { GenericSelect } from "components/forms/generic-select"
import { useAbsenceTypes } from "hooks/absences"
import { map } from "lodash"
import styled from "styled-components"

interface AbsenceStatusesFilterProps {
  value: string[]
  onChange(value: string[]): void
}

const Wrapper = styled.div`
  .ant-popover {
    min-width: 200px;
  }
`

const AbsenceStatusesFilter = (props: AbsenceStatusesFilterProps) => {
  const absenceStatuses = [
    {
      title: 'Waiting for approval',
      id: 'waiting',
    },
    {
      title: 'Approved',
      id: 'approved'
    },
    {
      title: 'Rejected',
      id: 'rejected'
    },
 ]

  const groups = [{
    label: 'Statuses',
    hasAllHelpers: true,
    options: absenceStatuses,
  }]

  return (
    <Wrapper>
      <GenericSelect 
        width="100%"
        selectedItems={props.value}
        groups={groups}
        onChange={props.onChange}
        placeholder="Approval status"
      />
    </Wrapper>
  )
}

export default AbsenceStatusesFilter