const ONBOARDING_COOKIE_NAME = "TIMENOTES_ONBOARDING_COOKIE"
const COOKIE_ROOT_DOMAIN = 'timenotes.io'

export type TOnboardingCookieData = {
  utmMedium: string,
  utmSource: string,
  utmCampaign: string,
  gclid: string,
  timenotesReferrer: string,
  timenotesLandingPage: string
}

export function setOnboardingCookieData(val: TOnboardingCookieData) {
  const name = ONBOARDING_COOKIE_NAME
  const days = 90

  const value = JSON.stringify(val)

  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
  }
  // Set the cookie to be accessible across the entire .acme.com domain, including subdomains
  document.cookie = name + "=" + (value || "") + expires + `; path=/; domain=.${COOKIE_ROOT_DOMAIN}; Secure; SameSite=None`;
}

export function getOnboardingCookieData(): null | TOnboardingCookieData {
  const name = ONBOARDING_COOKIE_NAME
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return JSON.parse(c.substring(nameEQ.length, c.length));
  }
  return null;
}