import  { useEffect, useRef } from 'react'
import { Card, message } from 'components/base'
import PageHeader from 'components/layout/page-header'
import { Button, Select, Skeleton } from 'antd'
import _find from 'lodash/find'
import TimeLogsFiltes from 'components/time-logs-filters'
import _isEqual from 'lodash/isEqual'
import { delayFunction } from 'utils/functions'
import { ReportsDatePickerInput } from 'pages/reports/components/reports-date-picker'
import moment from 'moment-timezone'
import { DollarIcon } from 'components/base/icons'
import ReportsRoundingModal from 'pages/reports/components/reports-rounding-modal'
import Timesheet from 'pages/timesheets/components/timesheet'
import TimesheetCellModal from './components/timesheet-cell-modal'
import { defer, isEqual } from 'lodash'
import { getPageDefaultFilters, saveFiltersToParams, saveGroupingToParams, saveRoundingToParams, saveTimespanToParams } from 'services/reports-filters/reports-filters.utils'
import TimesheetExportDropdown from './components/timesheet-export-dropdown'
import { useHistory } from 'react-router-dom'
import { useTopNavigationLinks } from 'hooks/layout'
import useClassState from 'services/utils/hooks/use-class-state'
import useApiClient from 'hooks/useApiClient'
import { IGetTimesheetsParams, TApiTimesheetCell, TApiTimesheetRow } from '@timenotes/shared/src/services/api-client/timesheets-api'
import { IGetReportsDetailedParams, TApiReportsDate, TApiReportsDetailedRow, TApiReportsDetailedSorting, TApiReportsFilters } from '@timenotes/shared/src/services/api-client/reports-api'
import { TApiGrouping, TApiRounding } from '@timenotes/shared/src/services/api-client/types'
import { ICreateTimeLogParams } from '@timenotes/shared/src/services/api-client/time-logs-api'

interface IState {
  loaded: boolean

  timesheetRows: TApiTimesheetRow[]
  timesheetLoading: boolean

  timesheetDate: TApiReportsDate
  filters: TApiReportsFilters
  grouping: TApiGrouping
  sorting: TApiReportsDetailedSorting

  roundingParams: TApiRounding
  roundingModalVisible: boolean

  createTimeLogParams?: ICreateTimeLogParams

  cellModalData?: {
    cell: TApiTimesheetCell
    row: TApiTimesheetRow
    parentRow?: TApiTimesheetRow
  }

  cellTimeRows: TApiReportsDetailedRow[]
  cellParams: IGetReportsDetailedParams
  timesheetCellModalVisible: boolean
}

const TimesheetsPage = () => {
  const apiClient = useApiClient()

  const defaults = getPageDefaultFilters({
    key: 'timesheet',
    filters: {},
    rounding: {
      type: 'no_rounding',
      precision: 5,
    },
    timespan: {
      timespan: 'month',
      from: moment().format('DD/MM/yyyy'),
      to: moment().format('DD/MM/yyyy'),
    },
    grouping: {
      primary: 'user'
    },
  })

  useTopNavigationLinks([
    {
      label: 'TIMESHEETS',
      urlMatcher: "timesheets",
      onClick: () => { return "" },
    },
  ])

  const [state, setState] = useClassState<IState>({
    loaded: true,
    timesheetDate: defaults.timespan,
    timesheetRows: [],
    timesheetLoading: true,

    filters: defaults.filters,
    grouping: defaults.grouping,
    sorting: {},

    roundingModalVisible: false,
    roundingParams: defaults.rounding,
    createTimeLogParams: undefined,

    cellTimeRows: [],
    cellParams: {},
    timesheetCellModalVisible: false,
  })

  const oldStateRef = useRef(state)
  const oldState = oldStateRef.current

  useEffect(() => {
    if (!isEqual(oldState, state)) {
      oldStateRef.current = state
    }
  }, [state])

  const history = useHistory()

  // onMount
  useEffect(() => {
    fetchTimesheet()

    return () => {
    }
  }, [])

  const getRequestParams = (): IGetTimesheetsParams => {
    const sorting: TApiReportsDetailedSorting = {}

    return {
      filters: {
        ...state.filters,
        ...state.timesheetDate,
      },
      rounding: state.roundingParams,
      grouping: state.grouping,
   }
  }

  const fetchTimesheet = () => {
    setState({ timesheetLoading: true })

    apiClient.getTimesheets(getRequestParams()).then((response) => {
      setState({
        timesheetLoading: false,

        timesheetRows: response.rows || [],
     })
    })
  }

  const getTimeRowsTableParamsWithCurrentPageReset = () => {
    return {
      timeRowsTableParams: {
        // Reset selection
        selectedAll: false,
        selectedRowKeys: [],

        sorting: null,
     }
    }
  }

  const applyFilters = (filters: TApiReportsFilters) => {
    const newFilters = {
      filters: filters,
      // Reset current page to 1 in case filters changes
      ...getTimeRowsTableParamsWithCurrentPageReset(),
    }

    saveFiltersToParams(filters)
    setState(newFilters)
  }

  const triggerExportRequest = (exportType: 'pdf' | 'xls' | 'csv') => {
    message.loading("Generating timesheet report, it might take a couple minutes.")

    apiClient.getTimesheetsExport({
      export: {
        type: exportType,
      },
      ...getRequestParams(),
    })
  }

  const triggerRoundingUpdate = (roundingParams: TApiRounding) => {
    setState({ roundingParams: roundingParams, roundingModalVisible: false })
  }

  const handleCellClick = (row: TApiTimesheetRow, cell: TApiTimesheetCell, parentRow?: TApiTimesheetRow) => {
    setState({ 
      timesheetCellModalVisible: true,
      cellModalData: {
        row: row,
        cell: cell,
        parentRow: parentRow,
      }
    })

    const cellParams = getTimesheetCellFilters(row, cell)
    fetchCellTimeRows(cellParams)
  }

  const fetchCellTimeRows = (params?: IGetReportsDetailedParams) => {
    apiClient.getReportsDetailed(params).then((response) => {
      setState({
        cellTimeRows: response.rows,
      })
    })
  }

  const getTimesheetCellFilters = (row: TApiTimesheetRow, cell: TApiTimesheetCell): IGetReportsDetailedParams => {
    let cellFilters: TApiReportsFilters = {
      from: cell.date,
      timespan: 'day',
    }

    // If grouping by user - add selected user id's as a more detailed user filter for the usersAccounts
    if (state.grouping.primary == 'user') {
      cellFilters.usersAccountHashIds = [row.groupId]
    }

    // ...
    if (state.grouping.primary == 'project') {
      cellFilters.projectHashIds = [row.groupId]
    }

    // ...
    if (state.grouping.primary == 'client') {
      cellFilters.clientHashIds = [row.groupId]
    }

    cellFilters = {
      ...state.filters,
      ...cellFilters,
    }

    const cellParams: IGetReportsDetailedParams = {
      grouping: { primary: 'no_group' },
      filters: cellFilters,
      rounding: state.roundingParams,
      sorting: undefined, // default?
    }

    setState({ cellParams })

    return cellParams
  }


  useEffect(() => {
    if (!isEqual(oldState, state)) {
      const prevState = oldState

      if (!(_isEqual(state.filters, prevState.filters))) {
        // Trigger seaarch with 750ms delay to avoid multiple calls when typing
        delayFunction('updateFilters', fetchTimesheet, 750)
      }

      if (!(_isEqual(state.timesheetDate, prevState.timesheetDate))) {
        saveTimespanToParams(state.timesheetDate)
        fetchTimesheet()
      }

      if (!(_isEqual(state.roundingParams, prevState.roundingParams))) {
        saveRoundingToParams(state.roundingParams)
        fetchTimesheet()
      }

      // If grouping changed
      if (!_isEqual(state.grouping, prevState.grouping)) {
        saveGroupingToParams(state.grouping)

        defer(() => {
          try {
            window.localStorage.setItem('timesheetDefaultGrouping', JSON.stringify(state.grouping))
          }
          catch (e) {
          }
        })

        fetchTimesheet()
      }
    }
  }, [state])

  if (!state.loaded) {
    return (
      <div className="page-content">
        <Card><Skeleton /><Skeleton /></Card>
      </div>
    )
  }

  return (
    <div>
      <div className="page-content">
        <PageHeader
          title="Time Logs Report"

          customHeader={(
            <div
              style={{ display: 'flex', gap: '10px' }}
            >
              <Button
                onClick={() => { setState({ roundingModalVisible: true }) }}
                icon={<DollarIcon />}
              >
                <span>
                  Rounding {state.roundingParams.type !== 'no_rounding' ? `- ${state.roundingParams.precision}m` : "- off"}
                </span>
              </Button>

              <ReportsDatePickerInput
                allowClear={false}
                reportsDate={state.timesheetDate}
                renderExtraFooter={() => (
                  <Button
                    type="link"
                    onClick={() => {
                      setState({
                        timesheetDate: {
                          from: moment().format('DD.MM.yyyy'),
                          timespan: 'month',
                        },
                        ...getTimeRowsTableParamsWithCurrentPageReset(),
                      })
                    }}
                  >
                    Current month
                  </Button>
                )}
                onChange={(date) => {
                  setState({
                    timesheetDate: date,
                    ...getTimeRowsTableParamsWithCurrentPageReset(),
                  })
                }}
              />

              <TimesheetExportDropdown
                onClick={triggerExportRequest}
              />

            </div>
          )}
        />

        <Card>
          <TimeLogsFiltes
            filters={state.filters}
            onChange={applyFilters}
          />
        </Card>

        <div style={{
          display: 'flex',
          justifyItems: 'center',
          alignItems: 'center',
          gap: '10px',
        }}>
          <div>
            <b>Group by</b>
          </div>

          <Select
            style={{ width: '140px' }}
            value={state.grouping.primary}
            onChange={(value) => {
              setState({
                ...getTimeRowsTableParamsWithCurrentPageReset(),
                grouping: { primary: value },
              })
            }}
          >
            <Select.Option value={'user'}>Team member</Select.Option>
            <Select.Option value={'project'}>Project</Select.Option>
            <Select.Option value={'client'}>Client</Select.Option>
          </Select>

          <div> and </div>

          <Select
            style={{ width: '140px' }}
            value={state.grouping.secondary || 'no_group'}
            onChange={(value) => {

              // TODO: Implement nicer, if secondary was just set as undefined, it was still sent to backend and got 500 error
              if (value == 'no_group') {
                setState({
                  ...getTimeRowsTableParamsWithCurrentPageReset(),
                  grouping: { primary: state.grouping.primary },
                })
              } else {
                setState({
                  ...getTimeRowsTableParamsWithCurrentPageReset(),
                  grouping: { primary: state.grouping.primary, secondary: value },
                })
              }

            }}
          >
            <Select.Option value={'no_group'}>No sub-group</Select.Option>

            {state.grouping.primary != 'user' && (
              <Select.Option value={'user'}>Team member</Select.Option>
            )}

            <Select.Option value={'task'}>Task</Select.Option>


            {state.grouping.primary != 'project' && (
              <Select.Option value={'project'}>Project</Select.Option>
            )}

            {state.grouping.primary != 'client' && state.grouping.primary != 'project' && (
              <Select.Option value={'client'}>Client</Select.Option>
            )}
          </Select>


        </div>


      </div>

      <div className="page-content-wide" style={{ marginLeft: '5px', marginRight: '5px' }}>
        <div style={{ height: '25px' }} />

        {state.timesheetLoading ? (
          <Card>
            <Skeleton />
            <Skeleton />
          </Card>
        ) : (
          <Timesheet
            rows={state.timesheetRows}
            onCellClick={handleCellClick}
          />
        )}
      </div>

      <ReportsRoundingModal
        visible={state.roundingModalVisible}
        roundingParams={state.roundingParams}
        hideModal={() => {
          setState({ roundingModalVisible: false })
        }}

        onChange={(roundingParams) => {
          triggerRoundingUpdate(roundingParams)
        }}
      />

      <TimesheetCellModal
        visible={state.timesheetCellModalVisible}
        onCancel={() => {
          setState({
            timesheetCellModalVisible: false,
            cellModalData: undefined,
          })
          fetchTimesheet()
        }}

        row={state.cellModalData?.row}
        parentRow={state.cellModalData?.parentRow}
        cell={state.cellModalData?.cell}

        filters={state.filters}
        grouping={state.grouping}
        rounding={state.roundingParams}
      />

    </div>
  )
}

export default TimesheetsPage