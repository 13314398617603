import moment, { Moment } from 'moment-timezone'
import _map from 'lodash/map'
import _max from 'lodash/max'
import _min from 'lodash/min'
import { Card } from 'antd'
import { Line } from 'react-chartjs-2'
import getDaysBetweenDatesArray from '../utils/get-days-between-dates-array'
import { useDefaultCurrencyIso } from 'hooks/settings'

const CumulativeBillableChart = ({ projectInsights, project }: { projectInsights: any, project: TApiProject }): JSX.Element => {

  const timeData = projectInsights.timeData
  const { defaultCurrencyIso, loaded: currencyLoaded }= useDefaultCurrencyIso()

  // Ensure chart is not displayed if currency is not loaded -> it should basically never happen!
  if (!currencyLoaded) return (<></>)

  const entriesArray = Object.keys(timeData).map((time) => {
    return {
      key: time,
      date: moment(time),
      ...timeData[time as any],
    }
  })
  const entriesDates = _map(entriesArray, (dateEntry) => dateEntry.date )

  const firstDate = _min(entriesDates) as Moment
  const lastDate = _max(entriesDates) as Moment

  let xUnit = 'month' // day by default
  const daysDiff = lastDate.diff(firstDate, 'd')

  if (daysDiff <= 60) xUnit = 'day' // display days on xAxe if time span is smaller than 60 days
  if (daysDiff >= 1095) xUnit = 'year' // display years on xAxe if time span is bigger than 3 years

  const datesRange = getDaysBetweenDatesArray(firstDate, lastDate)

  const labels = _map(datesRange, (date) => {
    return date
  })

  let chartValue = 0
  const dataEntries = _map(datesRange, (date: any) => {
    const entryValue = timeData[date.format("YYYYMMDD")]

    if (entryValue?.duration) {
      chartValue = chartValue + parseFloat(entryValue.billableAmount)
    } 

    return chartValue
  })

  const datasets = []
  const projectBudgetMoney = project.budgetMoney

  datasets.unshift({
      label: '',
      borderColor: '#6060CB',
      fill: false,
      data: dataEntries,
      pointRadius: 0,
  })

  if (project.budgetType == 'money') {
    datasets.unshift({
      fill: false,
      borderColor: '#ccc',
      data: _map(datesRange, (date: any) => { return projectBudgetMoney }),
      pointRadius: 0,
    })
  }

  const data = {
    labels: labels,
    datasets: datasets,
  }

  const legend = {
    display: false,
  }

  const options = {
    tooltips: {
      callbacks: {
        label: (tooltipItem: any) => {

          if (tooltipItem.datasetIndex == 0) {
            // @ts-ignore
            return `Project budget: ${projectBudgetMoney} ${defaultCurrencyIso}`
          }

          const value = dataEntries[tooltipItem.index]
          // @ts-ignore
          return `${value.toFixed(2)} ${defaultCurrencyIso}`
        },

        title: function(tooltipItem: any, data: any) {
          const date = datesRange[tooltipItem[0].index]
          return date.format("DD.MM.YYYY")
        }
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          callback: (value: any) => {
            return `${value} ${defaultCurrencyIso}`
          },
        }
      }],

      xAxes: [{
        type: 'time',
        time: {
          unit: xUnit,
        }
      }]
    }
  }  

  return (
    <Card style={{ height: '380px' }}>
      <h3>Cumulative Billable Amount </h3>

      <Line 
        legend={legend}
        height={70} 
        data={data} 
        options={options}
        type="line" 
      />
    </Card>
  )
}

export default CumulativeBillableChart