import { TimePicker as AntdTimePicker, message } from "antd"
import { COLORS } from "components/layout-components/timenotes-theme"
import moment, { Moment } from "moment-timezone"
import React, { FocusEventHandler, useEffect, useState } from "react"
import styled from "styled-components"

interface TimePickerProps extends React.ComponentProps<typeof AntdTimePicker> {
}

const AntdTimePickerStyled = styled(AntdTimePicker)`
  &.ant-picker.ant-picker-borderless:hover {
    background-color: ${COLORS.editableHighlightGray} !important;
  }
`

const TimePicker = (props: TimePickerProps) => {
  const [isValid, setIsValid] = useState(true)

  // default input value based on 
  const defaultInputValue = props.value ? props.value.format(props.format as any) : ""
  const [inputValue, setInputValue] = useState(defaultInputValue)
  const [isFocus, setIsFocus] = useState<boolean>(false)

  useEffect(() => {
    if (!isFocus) {
      setInputValue(props.value?.format(props.format as string || "HH:mm") || "")
    }
  }, [props.value])


  let keyUpBinded = false
  const handleKeyUp = (e: any) => {
    const input = e.target as HTMLInputElement
    setInputValue(input.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!keyUpBinded) {
      e.target.addEventListener('keyup', (event) => { handleKeyUp(event) })
      keyUpBinded = true
    }
  }

  const handleOnBlur = (e: any) => {
    if (isValid) {
      const inputMoment = moment.utc(inputValue, props.format as any, false)
      // format does not matter as long as they both match
      if (props.value?.format("HH:mm") !== inputMoment.format("HH:mm")) {
        // if input value changed from the initial value on blur
        // and is a valid one - trigger onChange event!

        //TODO: if value is provided by props and onChange is happening
        // we would like to keep the input value for the time of change process
        if (props.value && props.onChange) {
          props.onChange && props.onChange(inputMoment, inputMoment.format(props.format as any))
        }
      }
    }

    // on blur input value always goes to valid state anyway
    setIsValid(true)
    setIsFocus(false)
  }

  const handleOnSelect = (value: moment.Moment) => {
    setInputValue(value.format(props.format as any))
  }

  useEffect(() => {
    const inputMoment = moment(inputValue, props.format as any, false)
    setIsValid(inputMoment.isValid())
  }, [inputValue])

  const onChange = (value: moment.Moment | null, dateString: string) => {
    if (props.onChange) {
      props.onChange(value, dateString)
    }
  }

  return (
    <AntdTimePickerStyled
      {...props}
      onSelect={handleOnSelect}
      onKeyDown={handleKeyDown}
      onChange={onChange}
      onBlur={(e) => {
        handleOnBlur(e)
        if (props.onBlur) props.onBlur(e)
      }}
      onFocus={(e) => {
        if (props.onFocus) props.onFocus(e)
        setIsFocus(true)
      }}
    />
  )
}

export default TimePicker