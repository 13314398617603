import { Card, Modal, Skeleton, message } from "antd"
import PageHeader from "components/layout/page-header"
import { useState } from "react"
import { useTopNavigationLinks } from "hooks/layout"
import { useAlerts } from "hooks/alerts"
import AlertsTable from "pages/alerts/components/alerts-table"
import AlertForm from "pages/alerts/components/alert-form"
import {useGetPagePermissions} from "hooks/permissions.hooks"

const AlertsPage = () => {
  useTopNavigationLinks([{
    label: 'ALERTS',
    urlMatcher: "alerts",
    onClick: () => {},
  }])

  const [newItemModalVisible, setNewItemModalVisible] = useState(false)
  const { alerts, loading, fetchAlerts } = useAlerts()

  const { permissionsLoaded } = useGetPagePermissions()

  const pageLoading = loading || !permissionsLoaded

  return (
    <div>
      <div className="page-content">
        <PageHeader
          title="Alerts"
          buttons={[
            {
              title: "Add alert",
              type: 'primary',
              callback: () => {
                setNewItemModalVisible(true)
              }
            }
          ]
          }
        />

        <div style={{ height: '25px' }} />

        {pageLoading ? (
          <Card>
            <Skeleton />
            <Skeleton />
          </Card>
        ) : (
          <AlertsTable
            alerts={alerts}
            refresh={fetchAlerts}
          />
        )}

        <Modal
          title={"Add alert"}
          destroyOnClose={true}
          visible={newItemModalVisible}
          onCancel={() => { setNewItemModalVisible(false) }}
          footer={false}
        >
          <AlertForm
            onSuccess={() => {
              setNewItemModalVisible(false)
              message.success('New alert added!')
              fetchAlerts()
            }}
          />
        </Modal>
      </div>
    </div>
  )
}

export default AlertsPage
