import { useState } from "react"
import { Layout } from "antd"
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import Logo from './Logo'
import NavPanel from './NavPanel'
import NavSearch  from './NavSearch'
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'consts/theme'
import utils from 'utils'

import TimenotesTheme from 'components/layout-components/timenotes-theme'
import TopNavLinks from "components/layout/top-nav-links"
import { useThemeConfig } from "services/layout/layout.hooks"

const { Header } = Layout;

export const HeaderNav = props => {
  const isMobile = props.isMobile
  const { themeConfig, toggleCollapsedNav, toggleMobileNav } = useThemeConfig()
  const { navCollapsed, mobileNav, navType, headerNavColor, currentTheme, direction } = themeConfig

  const [searchActive, setSearchActive] = useState(false)

  const onSearchClose = () => {
    setSearchActive(false)
  }

  const onToggle = () => {
    if(!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      toggleMobileNav(!mobileNav)
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false

  const mode = () => {
    if(!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff' )
    }
    return utils.getColorContrast(headerNavColor)
  }

  const navMode = mode()

  const getNavWidth = () => {
    if(isNavTop || isMobile) {
      return '0px'
    }
    if(navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }

  return (
    <Header className={`app-header ${navMode}`} style={{backgroundColor: TimenotesTheme.colors.backgroundGrey }}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode} {...themeConfig }/>
        <div className="nav" style={{width: `calc(100% - ${getNavWidth()})`}}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">          
              {
                isNavTop && !isMobile ?
                null
                :
                <>
                  <li className="ant-menu-item ant-menu-item-only-child" onClick={() => {onToggle()}}>
                    {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                  </li>

                  <TopNavLinks />
                </>
              }
            </ul>
          </div>
          <div className="nav-right">
            <NavPanel direction={direction} />
          </div>
          <NavSearch active={searchActive} close={onSearchClose}/>
        </div>
      </div>
    </Header>
  )
}

export default HeaderNav