import { useFormik } from "formik"
import useApiClient from "./useApiClient"
import { message } from "antd"
import { useHistory } from "react-router-dom"
import { usePeekedInvitation } from "./confirm-invitations"
import { TApiSession } from "@timenotes/shared/src/services/api-client/types"
import { usePermissionsQuery } from "@timenotes/shared/src/services/permissions/permissions.hooks"

export const useLoginFormik = ({
  onSuccess,
}: {
  onSuccess(session: TApiSession): void
}) => {
  const apiClient = useApiClient()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      const response = await apiClient.login(values)

      if (response.ok) {
        apiClient.setAccessCredentials({ 
          accessToken: response.session.token,
        })

        onSuccess(response.session)
      } else {
        if (response.errors) {
          formik.setErrors(response.errors)
        }
        if (response.errors?.base) {
          message.error(response.errors.base)
        }
      }
    }
  })

  return formik
}

export const useLoginSuccessRedirect = () => {
  const apiClient = useApiClient()
  const history = useHistory()


  const { 
    resolvePeekedInvitationOnLogin,
  } = usePeekedInvitation()

  const afterLoginRedirect = useAfterLoginRedirect()

  return async () => {
    const accountsResponse = await apiClient.getAccountsList()
    const accounts = accountsResponse.accounts

    if (!accountsResponse.ok) {
      message.error('Invalid credentials!')
      history.push('/auth/login')
      return
    }
    
    // Check if there is a peeked invitation and redirect there inside if it is
    if (await resolvePeekedInvitationOnLogin()) {
      // stop the flow to block further redirects
      return
    }

    if (accounts.length == 1) {
      const targetAccount = accounts[0]
      apiClient.setAccessCredentials({
        accountId: targetAccount.id
      })

      afterLoginRedirect()
   } else {
      history.push('/auth/select-account')
    }
  }
}

export const useAfterLoginRedirect = () => {
  const history = useHistory()

  return () => {
    const autoLoginType = window.localStorage.getItem('autoLoginType')

    if (autoLoginType) {
      // HANDLE EXTERNAL LOGIN
      // if (autoLoginType == 'extension') {
      history.push('/auth/auto-login')
      //}
    } else {
      // HANDLE WEB LOGIN
      // TODO: this forces reload of the entire page even if authentication happens on the /timer itself
      history.push(`/timer`)
    }
  }
}
