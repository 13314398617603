import { Col, Input, Row, Select, Table, Tooltip } from "antd"
import { useEffect, useState } from "react"
import styled from "styled-components"
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import { RemoveIcon } from "components/base/icons"
import { useDefaultCurrencyIso, useSettings } from "hooks/settings"
import getProjectsUsersTargetBillableRate from "@timenotes/shared/src/services/projects-users/utils/get-projects-users-target-billable-rate"
import useDeleteProjectsUserMutation from "@timenotes/shared/src/services/projects-users/queries/use-delete-projects-user-mutation"
import { getUsersAccountFullName } from "services/users-accounts"
import useUpdateOrCreateProjectsUserMutation from "@timenotes/shared/src/services/projects-users/queries/use-update-or-create-projects-user-mutation"
import { useCurrentProjectsPermissionsQuery } from "@timenotes/shared/src/services/permissions/permissions.hooks"
import { TApiProjectsUser } from "@timenotes/shared/src/services/api-client/projects-users-api"
import { TApiProject, TApiResponse } from "@timenotes/shared/src/services/api-client/types"
import ProjectsUsersBudgetTimeInput from "./projects-users-budget-time-input"

interface IProps {
  project: TApiProject
  projectsUsers: TApiProjectsUser[]

  onDeleteGroup?(): void
  onAddGroup?(): void
}

const TableWrapper = styled.div`
  .ant-table-cell {
    padding: 0px;
    height: 60px;
    padding-left: 15px;
    padding-right: 15px;
  }
`

const ROLE_OPTIONS = [
  {
    value: 'member',
    label: "Member"
  },
  {
    value: 'project_manager',
    label: 'Project Manager'
  }, 
]

const InPlaceSelect = styled(Select)`
  :hover {
    background-color: #eee;
    border-radius: 8px;
  }
`

const InPlaceInput = styled(Input)`
  &.inactive:hover {
    background-color: #eee;
    border-radius: 8px;
  }
`

const RoleColumn = ({ projectsUser, placeholder, onChange }: { projectsUser: TApiProjectsUser, placeholder?: string, onChange(newValue: TApiProjectsUser['role']): Promise<TApiResponse>}) => {
  return (
    <InPlaceSelect
      bordered={false}
      showArrow={false}
      value={ projectsUser.role }
      options={ROLE_OPTIONS}
      onChange={(newValue) => {
        onChange(newValue)
      }}
      dropdownMatchSelectWidth={false}
    >
      {startCase(toLower(projectsUser.role))}
    </InPlaceSelect>
  )
}

const BillableRateColumn = ({ 
  projectsUser, 
  project,
  currencyIso, 
  defaultWorkspaceBillableRate,
  onChange 
}: { 
  projectsUser: TApiProjectsUser, 
  project: TApiProject,
  defaultWorkspaceBillableRate: number, 
  currencyIso: string, 
  onChange(newValue: number | null): Promise<TApiResponse> 
}) => {

  const targetBillableRate = getProjectsUsersTargetBillableRate({
    projectsUser: { billableRate: undefined } as TApiProjectsUser, // hack to make sure the projectsUser rate is not used for default rate calculation placeholder
    project: project,
    defaultWorkspaceBillableRate: defaultWorkspaceBillableRate,
  })

  const placeholder = `(${targetBillableRate})`
  const userBillableRateValue = projectsUser.billableRate ? projectsUser.billableRate.toString() : ""

  const tooltipInfo = userBillableRateValue == "" ? "No custom projects members billable rate is set. The rate from a rate hierarchy is applied, default project rate, default members rate or default workspace rate accordingly." : undefined

  const [isActive, setIsActive] = useState(false)
  const [inputValue, setInputValue] = useState<string>(userBillableRateValue)

  useEffect(() => {
    setInputValue(projectsUser.billableRate ? projectsUser.billableRate.toString() : "")
  }, [projectsUser.billableRate])

  const handleSubmit = (value: string) => {
    const finalValue = value == "" ? null : parseFloat(value)
    onChange(finalValue)
  }

  return (
    <Tooltip
      title={tooltipInfo}
      mouseEnterDelay={0.5}
    >
      <InPlaceInput
        style={{
          width: '120px',
        }}
        className={isActive ? '' : 'inactive'}
        onFocus={() => setIsActive(true)}
        onBlur={() => {
          setIsActive(false)
          handleSubmit(inputValue)
        }}
        bordered={isActive}
        suffix={currencyIso}
        value={inputValue}
        placeholder={placeholder}
        onKeyUp={(e) => {
          if (e.key == "Enter") {
            handleSubmit(inputValue)
          }
        }}
        onChange={(e) => {
          setInputValue(e.target.value)
          /*
          const newValue = e.target.value
          const finalValue = newValue == "" ? null : parseFloat(newValue)
          onChange(finalValue)
          */
        }}
      />
    </Tooltip >
  )
}

const ProjectsUsersTable = ({ project, projectsUsers }: IProps ) => {

  const { defaultCurrencyIso } = useDefaultCurrencyIso()
  const { settings } = useSettings({ fetch: true })
  const currentProjectsPermissionsQuery = useCurrentProjectsPermissionsQuery({
    params: {
      projectId: project.id
    }
  })
  const currentProjectsPermissions = currentProjectsPermissionsQuery.data?.permissions

  const workspaceCurrency = defaultCurrencyIso
  const defaultWorkspaceBillableRate = settings?.defaultBillableRate

  const {
    mutation: deleteProjectsUserMutation
  } = useDeleteProjectsUserMutation()

  const deleteProjectsUser = (id: string): void => {
    deleteProjectsUserMutation.mutate({
      projectId: project.id,
      projectsUserId: id,
    })
  }

  const {
    mutation: updateOrCreateProjectsUserMutation
  } = useUpdateOrCreateProjectsUserMutation()

  const triggerUpdateOrCreate = (projectsUser: TApiProjectsUser, values: any): void => {

    const params = projectsUser.id ? values : {
      usersAccountId: projectsUser.usersAccount.id,
      projectRole: values.projectRole || 'member',
      billableRate: values.billableRate || null,
    }

    updateOrCreateProjectsUserMutation.mutate({
      projectId: project.id,
      projectsUserId: projectsUser.id || undefined,
      projectsUser: params,
    })
  }

  const COLUMNS = [
    {
      title: 'Name',
      width: '20%',
      render: (text: string, record: TApiProjectsUser, index: number) => {
        return (
          <span>{getUsersAccountFullName(record.usersAccount)} </span>
        )
      }
    },
    {
      title: 'Role',
      width: '20%',
      render: (text: string, record: TApiProjectsUser, index: number) => {
        if (currentProjectsPermissions?.manageCurrentProject) {
          return (
            <RoleColumn
              projectsUser={record}
              onChange={(value) => {
                return triggerUpdateOrCreate(record, {
                  projectRole: value,
                })
              }}
            />
          )
        } else {
          return <span>{startCase(toLower(record.role))}</span>
        }
      }
    },
   {
      title: 'ACCESS',
      width: '20%',
      render: (text: string, record: TApiProjectsUser, index: number) => {
        if (project.visibility) {
          return (
            <Tooltip title="User has access to the project as the project is public - all users in the workspace has access to it.">
              <div style={{ cursor: 'pointer' }}>
                workspace member
              </div>
            </Tooltip>
          )
        } else {
          const accessSource = record.accessSource

          if (accessSource == 'manual') {
            return (
              <Tooltip title="User added directly to the project.">
                <div style={{ cursor: 'pointer' }}>
                  added manually
                </div>
              </Tooltip>
            )
          }

          if (accessSource == 'group') {
            return (
              <Tooltip title="User has access to the project via a members group he belongs to.">
                <div style={{ cursor: 'pointer' }}>
                  group membership
                </div>
              </Tooltip>
            )
          }
        }
      }
    },
    { 
      title: "",
      width: '10%',
      render: (text: string, record: TApiProjectsUser) => {
        if (!currentProjectsPermissions?.manageCurrentProject) return null

        if (project.visibility)
          return (
            <Tooltip title="Can not remove a member from a public project." >
              <div style={{ width: '20px' }}>
                <RemoveIcon className="disabled" />
              </div>
            </Tooltip>
          )

        if (record.accessSource == 'group')
          return (
            <Tooltip title="This member was added via Group membership. Remove the specific group from the project in order to remove this member." >
              <div style={{ width: '20px' }}>
                <RemoveIcon className="disabled" />
              </div>
            </Tooltip>
          )

        return (
          <Tooltip 
            mouseEnterDelay={0.5}
            title="Remove the member from the project. Do not worry, all the time tracked till now will not be deleted."
          >
            <a 
              onClick={() => { 
                deleteProjectsUser(record.id as string)
              }}>
                <RemoveIcon />
            </a>
          </Tooltip>
        )
      }
    }
  ]

  if (currentProjectsPermissions?.manageCurrentProjectBillable) {
    COLUMNS.splice(2, 0,
      {
        title: `BILLABLE RATE (${workspaceCurrency}/hour)`,
        width: '15%',
        render: (text: string, record: TApiProjectsUser) => (
          <>
            {project.billableEnabled ? (
              <BillableRateColumn
                project={project}
                projectsUser={record}
                currencyIso={workspaceCurrency}
                defaultWorkspaceBillableRate={defaultWorkspaceBillableRate}
                onChange={(value) => {
                  return triggerUpdateOrCreate(record, {
                    billableRate: value,
                  })
                }}
              />
            ) : (
              <Tooltip title="Project is not billable. Go to project settings if you want to make it billable and enable setting up custom user billable rates.">
                n/a
              </Tooltip>
            )}
          </>
        )
      }
    )
  }

  if (!!currentProjectsPermissions?.manageCurrentProject) {
    COLUMNS.splice((COLUMNS.length-2), 0,
      {
        title: "TIME BUDGET",
        width: '15%',
        render: (text: string, record: TApiProjectsUser) => (
            <ProjectsUsersBudgetTimeInput
              projectsUser={record}
              project={project}
            />
        )
      }
    )
 
  }

  return (
    <Row>
      <Col span="24" style={{ marginTop: '24px' }}>
        { projectsUsers.length == 0 ? (
          <span><i> No team members added </i></span>
        ) : (
          <TableWrapper>
            <Table
              tableLayout="fixed"
              columns={COLUMNS}
              // @ts-ignore
              dataSource={projectsUsers}
              pagination={false}
            />
          </TableWrapper>
        )}
      </Col>
    </Row>
  )
}

export default ProjectsUsersTable