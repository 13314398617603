import { Mixin } from 'ts-mixer'

import ActiveTrackersApi from './active-trackers-api'
import ActivitiesApi from './activities-api'
import BulksTimeLogsApi from './bulks-time-logs-api'
import ClientsApi from './clients-api'
import MembersGroupsApi from './members-groups-api'
import IntegrationsApi from './integrations-api'
import ProjectsApi from './projects-api'
import ReportsApi from './reports-api'
import TagsApi from './tags-api'
import TasksApi from './tasks-api'
import TimeLogsApi from './time-logs-api'
import UsersAccountsApi from './users-accounts-api'
import StoragesApi from './storages-api'
import SettingsApi from './settings-api'
import PlansApi from './plans-api'
import AccountsApi from './accounts-api'
import TimesheetsApi from './timesheets-api'
import AlertsApi from './alerts-api'
import BillingInfosApi from './billing-infos-api'
import InvitationsApi from './invitations-api'
import PaymentMethodsApi from './payment-methods-api'
import PermissionsApi from './permissions-api'
import ProjectsUsersApi from './projects-users-api'
import SubscriptionPeriodsApi from './subscription-periods-api'
import SubscriptionsApi from './subscriptions-api'
import IntegrationsAccountProjectsApi from './integrations-account-projects-api'
import ProjectsGroupsApi from './projects-groups-api'
import HolidaysAbsenceRequestsApi from './holidays-absence-requests-api'
import HolidaysAbsenceTypesApi from './holidays-absence-types-api'
import HolidaysFreeDaysApi from './holidays-free-days-api'
import { TApiErrors, TApiResponse } from './types'
import { message } from 'antd'
import TaskParentsApi from './task-parents-api'
import HolidaysAbsencesApi from './holidays-absences-api'
import ContactsApi from './contacts-api'
import RegistrationsApi from './registrations-api'
//import ImportsApi from 'services/timenotes/imports/imports.api'

class ApiClientPart1 extends Mixin(
  ActiveTrackersApi,
  ActivitiesApi,
  BulksTimeLogsApi,
  ClientsApi,
  MembersGroupsApi,
  IntegrationsApi,
  ProjectsApi,
  ReportsApi,
  TagsApi,
  TasksApi,
) {}

class ApiClientPart2 extends Mixin(
  ApiClientPart1,
  TimeLogsApi,
  UsersAccountsApi,
  StoragesApi,
  SettingsApi,
  PlansApi,
  AccountsApi,
  TimesheetsApi,
  AlertsApi,
  BillingInfosApi,
) {}

class ApiClientPart3 extends Mixin(
  ApiClientPart2,
  InvitationsApi,
  PaymentMethodsApi,
  PermissionsApi,
  ProjectsUsersApi,
  SubscriptionPeriodsApi,
  SubscriptionsApi,
  IntegrationsAccountProjectsApi,
  ProjectsGroupsApi,
  HolidaysAbsenceRequestsApi
) {}

// Mixin allows 10 arguments only
class ApiClientPart4 extends Mixin(
  ApiClientPart3,
  HolidaysAbsenceTypesApi,
  HolidaysFreeDaysApi
) {}

// Mixin allows 10 arguments only
class ApiClient extends Mixin(
  ApiClientPart4,
  TaskParentsApi,
  HolidaysAbsencesApi,
  ContactsApi,
  RegistrationsApi,
  //ImportsApi,
) {}

export type TApiErrorsArray = {
}

export const errorsObjectToArray = (errorsObject: TApiErrors) => {
}

export const getApiErrorMessage = (response: TApiResponse, attribute: string): string => {
  if (response.errors) {
    const attributeErrors = response.errors[attribute]

    if (attributeErrors) {
      return Array.isArray(attributeErrors) ? attributeErrors[0] : attributeErrors
    }

    if (response.errors.base) {
      const baseError = response.errors.base
      return Array.isArray(baseError) ? baseError[0] : baseError
    }

    // @ts-ignore
    if (response.error) {
      // @ts-ignore
      const statusError = response.error
      return Array.isArray(statusError) ? statusError[0] : statusError
    }

    // If can't find an error for attribute and any other - return error message
    // for the first field found as still more meaningfull than something went wrong!
    const firstErrorKey = Object.keys(response.errors)[0]
    if (firstErrorKey) return `${firstErrorKey.toLocaleUpperCase()}: ${response.errors[firstErrorKey]}`
  }

  return "Something went wrong."
}

interface IHandleResponseWithMessage {
  successMessage: string
  opts?: {
    errorField?: string
  }
}

interface IApiResponseFunction {
  (response: TApiResponse): TApiResponse
}

export const handleResponseWithMessage = (successMessage: IHandleResponseWithMessage['successMessage'], opts?: IHandleResponseWithMessage['opts']): IApiResponseFunction => {
  const responseHandleFunction = (response: TApiResponse) => {
    if (response.ok) {
      message.success(successMessage)
    } else {
      message.error(getApiErrorMessage(response, (opts?.errorField || 'base')))
    }

    return response
  }

  return responseHandleFunction
}

export default ApiClient
