import { Input,  } from "antd"
import { FC } from "react"
import { delayFunction } from "utils/functions";
const { Search } = Input


interface IProps {
  placeholder: string
  searchQuery?: string | null;
  setSearchQuery: (value: string | null) => void;
  style?: any;
}

export const SearchFilter: FC<IProps> = ({placeholder, searchQuery, setSearchQuery, style}) => {

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value

    delayFunction('search-filter', () => {
      setSearchQuery(searchQuery)
    }, 500)
  }

  return (
    <Search
      placeholder={placeholder}
      onSearch={(value: string) => setSearchQuery(value)}
      onChange={handleOnChange}
      value={searchQuery}
      style={style ?? { width: "30%" }}
    />
  )
}
