const Clock = (props: React.Component<"svg">) => (
    <svg width="12px" height="20px" viewBox="0 0 12 20" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props} >
        <g id="Holidays-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="9.2.3-Create-absence-type-" transform="translate(-239.000000, -326.000000)">
                <g id="Group" transform="translate(216.000000, 284.000000)">
                    <g id="icon-/-application-/-dark-/-icon-1" transform="translate(17.000000, 40.000000)">
                        <g id="baseline-hourglass_empty-24px">
                            <path d="M6,2 L6,8 L6.01,8 L6,8.01 L10,12 L6,16 L6.01,16.01 L6,16.01 L6,22 L18,22 L18,16.01 L17.99,16.01 L18,16 L14,12 L18,8.01 L17.99,8 L18,8 L18,2 L6,2 Z M16,16.5 L16,20 L8,20 L8,16.5 L12,12.5 L16,16.5 Z M12,11.5 L8,7.5 L8,4 L16,4 L16,7.5 L12,11.5 Z" id="Shape" fill="#474D55" fill-rule="nonzero"></path>
                            <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Clock