import { Tooltip, Upload } from "antd"
import { useState } from "react"
import { DeleteOutlined } from '@ant-design/icons'
import styled from "styled-components"
import { COLORS } from "components/layout-components/timenotes-theme"
import useApiClient from "hooks/useApiClient"

const LogoUploadWrapper = styled.div`
  width: 120px;
  height: 120px;

  .logo-upload-delete {
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 30px;
    height: 30px;
    z-index: 1000;
    border: 1px solid ${COLORS.backgroundGrey};
    background-color: white;
    cursor: pointer;
  }

  .logo-upload-delete:hover {
    background-color: ${COLORS.primaryGreen};
    border-color: ${COLORS.primaryGreen};
  }

  :hover .logo-upload-delete {
    display: flex;
  }
`

const LogoUpload = ({
  accountLogoUrl,
}: {
  accountLogoUrl?: string
}) => {
  const apiClient = useApiClient()
  const [previewFile, setPreviewFile] = useState<any>()

  const fileUrl = previewFile === null ? null : (previewFile || accountLogoUrl)

  const handleDelete = async () => {
    await apiClient.removeSettingsAccountLogo()
    setPreviewFile(null)
  }

  return (
    <LogoUploadWrapper>
      { fileUrl && (
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            <Tooltip title="Click to remove the logo">
              <div className="logo-upload-delete" onClick={handleDelete}>
                <DeleteOutlined />
              </div>
            </Tooltip>
          </div>
        </div>
      )}

    <Upload
      listType="picture-card"
      className="avatar-uploader"
      method="PATCH"
      showUploadList={false}
      action={apiClient.getUploadLogoUrl()}
      customRequest={(request) => {
        const headers = apiClient.headers

        const data = new FormData()

        data.append('setting[account_logo]', request.file)

        fetch(apiClient.getUploadLogoUrl(), {
          method: 'PATCH',
          headers: {
            'AuthorizationToken': apiClient.accessToken as string,
            'AccountId': apiClient.accountId as string,
          },
          body: data,
        }).then(() => {
          getBase64(request.file, (img: any) => {
            setPreviewFile(img)
          })
        })
      }}
    >
      <>
        { fileUrl ? (
          <Tooltip title="Click to upload a new logo">
           <img 
              style={{
                width: '90%',
                height: '90%',
              }}
              src={previewFile || accountLogoUrl} 
            />
          </Tooltip>
        ) : (
          <p>Upload </p>
        )}
     </>
    </Upload>
  </LogoUploadWrapper>
  )
}

const getBase64 = (img: any, callback: any) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default LogoUpload