import { Avatar, Pagination, Table, Tag } from "antd"
import { ColumnsType, TableProps } from "antd/lib/table"
import { IGetUsersAccountsParams, TApiTeamMember } from "services/api-client/users-accounts-api"
import _map from 'lodash/map'
import ActionsPanel, { IActionItem } from "components/shared/actions-panel"
import { TApiPagination } from "services/api-client/types"

interface IGetTeamMembersActions {
  (teamMember: TApiTeamMember): IActionItem[]
}

const TeamMembersTable = ({ 
  loading,
  teamMembers,
  pagination,
  setSorting,
  getTeamMembersActions,
  onClick,
  tableProps,
  sensitiveVisible,
} : { 
  loading: boolean,
  teamMembers: TApiTeamMember[],
  pagination: TApiPagination,
  setSorting(sorting: IGetUsersAccountsParams['sorting']): void,
  getTeamMembersActions: IGetTeamMembersActions,
  onClick(member: TApiTeamMember): void,
  sensitiveVisible: boolean,
  tableProps?: TableProps<TApiTeamMember>,
 }) => {

  let COLUMNS: ColumnsType<TApiTeamMember> = [
    {
      title: 'NAME',
      sorter: true,
      onCell: (record) => ({ onClick: () => { onClick(record) } }),
      render: (t, record) => {
        return (
          <span>
            <Avatar style={{ marginRight: '10px', backgroundColor: '#3E79F7', opacity: '0.35' }}>
              {record.firstName[0]}{record.lastName[0]}
            </Avatar>
            {record.firstName} {record.lastName}
          </span>
        )
      }
    },
    {
      title: 'ROLE',
      sorter: true,
      onCell: (record) => ({ onClick: () => { onClick(record) } }),
      render: (t, record) => {
        const role = record.accountOwner ? 'owner' : record.role

        return (
          <span style={{ textTransform: 'capitalize' }}>
            {role}
          </span>
        )
      }
    }
  ]

  if (sensitiveVisible) {

    COLUMNS.push({
      title: 'BILLABLE RATE',
      onCell: (record) => ({ onClick: () => { onClick(record) } }),
      render: (t, record) => {
        return (
          <span>
            {record.billableRate ? parseFloat(record.billableRate).toFixed(2) : '0.00'}
          </span>
        )
      }
    })

    COLUMNS.push({
      title: 'COST RATE',
      onCell: (record) => ({ onClick: () => { onClick(record) } }),
      render: (t, record) => {
        return (
          <span>
            {record.costRate ? parseFloat(`${record.costRate}`).toFixed(2) : '0.00'}
          </span>
        )
      }
    })
  }

  COLUMNS.push({
    title: 'GROUPS',
    onCell: (record) => ({ onClick: () => { onClick(record) } }),
    render: (t, record) => {
      return (
        <span>
          {_map(record.membersGroups, (group) => {
            return (
              <Tag key={group.name} color="#3A3AA3">{group.name}</Tag>
            )
          })}
        </span>
      )
    }
  })

  COLUMNS.push({
    title: 'NOTES',
    onCell: (record) => ({ onClick: () => { onClick(record) } }),
    render: (t, record) => (<span>{record.notes}</span>)
  })

  COLUMNS.push({
    title: '', // Actions
    render: (t: string, record) => {

      const actions = getTeamMembersActions(record)

      return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: '10px' }}>
          <ActionsPanel actions={actions} />
        </div>
      )
    }
  })

  const handleTableChange = (p: any, f: any, sorter: any) => {
    if (sorter.column) {
      const columnTitle = sorter.column.title
      const sortOrder = sorter.order

      const apiSortOrder = sortOrder == 'ascend' ? 'asc' : 'desc'

      if (columnTitle == "NAME") {
        setSorting({ firstName: apiSortOrder })
      }

      if (columnTitle == "ROLE") {
        setSorting({ role: apiSortOrder })
      }

    } else {
      setSorting({ firstName: 'asc' })
    }
  }

  return (
    <>
      <Table<TApiTeamMember>
        loading={loading}
        rowClassName={'table-clickable-row'}
        dataSource={teamMembers}
        columns={COLUMNS}
        onChange={handleTableChange}
        pagination={false}
        { ...tableProps }
      />

      <br />

      <div style={{ display: 'flex', justifyContent: 'right' }} >
        <Pagination
          current={pagination.currentPage || 1}
          pageSize={pagination.pageSize || 1}
          total={pagination.totalCount || 0}
          showSizeChanger={false}
          onChange={(page) => {
            pagination.setPage && pagination.setPage(page)
          }}
        />
      </div>
    </>
  )
}

export default TeamMembersTable