import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { Checkbox, Select, message } from 'antd'
import { SelectValue } from 'antd/lib/select'
import styled from 'styled-components';
import { COLORS } from 'components/layout-components/timenotes-theme';
import { uniqBy } from 'lodash';

export type CustomOption<ValueType> = {
  label?: React.ReactNode
  value: ValueType
  disabled?: boolean
};

export type CustomGroupOption<ValueType> = {
  label?: React.ReactNode
  allFilters?: boolean,
  options: CustomOption<ValueType>[]
};

export type CustomOptions<ValueType> = Array<CustomOption<ValueType> | CustomGroupOption<ValueType>>;

// !! remove optionFilterProp as RecordsSelect uses sarch via searchProp
interface RecordsSelectProps<ValueType> extends Omit<React.ComponentProps<typeof Select>, 'options' | 'onChange' | 'onSelect' | 'onDeselect' | 'value' | 'defaultValue' | 'optionFilterProp'> {
  options: CustomOptions<ValueType>;
  valueProp: keyof ValueType;
  labelProp: ((option: ValueType) => React.ReactNode) | keyof ValueType;
  searchProp: ((option: ValueType) => React.ReactNode) | keyof ValueType;
  onChange?: (value: ValueType, option: React.ReactElement) => void;
  onSelect?: (value: ValueType, option: React.ReactElement) => void;
  onDeselect?: (value: ValueType) => void;
  filterInputMode?: boolean
  value?: ValueType;
  defaultValue?: ValueType;
  onCreate?(search: string): void
}

const CREATE_NEW_KEY = 'CREATE_NEW'

const RecordsSelect = <ValueType extends { [key: string]: any }>({
  options,
  valueProp,
  labelProp,
  searchProp,
  onChange,
  onSelect,
  onDeselect,
  value,
  defaultValue,
  onCreate,
  filterInputMode,
  ...restProps
}: RecordsSelectProps<ValueType>) => {


  const [internalSearchValue, setInternalSearchValue] = useState(restProps.searchValue || '')

  const searchValue = restProps.searchValue !== undefined ? restProps.searchValue : internalSearchValue
  const onSearch = restProps.onSearch || setInternalSearchValue

  // Those 2 functions allows to not display tags in mulitple mode and allow the search to be just an input, while tags are being displayed only
  // inside the dropdown list and potentially as a badge in other components
  const handleKeyDownOnFilterInputMode= (e: React.KeyboardEvent) => {
    if (e.key === 'Backspace' && searchValue === '') {
      e.preventDefault();
      e.stopPropagation();

      return false
    }

    if (e.key === 'Enter') {
      onSearch('')
    }

    if (restProps.onKeyDown) {
      restProps.onKeyDown(e as any)
    }
  }
  const tagRenderOnFilterInputMode = () => (<></>)

  const getSelectedObject = (selectedValue: any) => {
    for (const option of options) {
      if ('options' in option) {
        const found = option.options.find(subOption => subOption.value[valueProp] === selectedValue)
        if (found) return found.value
      } else {
        if (option.value[valueProp] === selectedValue) return option.value
      }
    }
  };

  const handleChange = (selectedValue: ValueType, option: React.ReactElement) => {
    const selectedObject = getSelectedObject(selectedValue);

    if (selectedObject && selectedObject[valueProp] == CREATE_NEW_KEY && restProps.mode?.toString() != 'multiple') {
      if (onCreate) onCreate('a')
      return
    }

    // This seems to happen only for mode != multiple selects
    if (onChange && selectedObject && restProps.mode?.toString() != 'multiple') {
      onChange(selectedObject, option);
    }
  };

  const handleSelect = (selectedValue: ValueType, option: React.ReactElement) => {
    const selectedObject = getSelectedObject(selectedValue);

    if (selectedObject && selectedObject[valueProp] == CREATE_NEW_KEY && restProps.mode?.toString() == 'multiple') {
      if (onCreate) onCreate('a')

      return
    }

    if (onSelect && selectedObject) {
      onSelect(selectedObject, option);
    }
  
    if (onChange && selectedObject) {
      // Check if it's multiple mode
      if (restProps.mode === 'multiple') {
        const newValue = Array.isArray(value) ? [...value, selectedObject] : [selectedObject];
        // @ts-ignore
        onChange(newValue, option);
      } else {
        /*
        // THIS SEEMS TO NOT HAPPEN AT ALL?
        onChange(selectedObject, option);
        message.success('on change select')
        */
      }
    }
  };
  
  const handleDeselect = (deselectedValue: ValueType) => {
    const selectedObject = getSelectedObject(deselectedValue);
    if (onDeselect && selectedObject) {
      onDeselect(selectedObject);
    }
  
    if (onChange && selectedObject) {
      // Only makes sense to update onChange in multiple mode
      if (restProps.mode === 'multiple' && Array.isArray(value)) {
        const records = value as ValueType[]
        const newValue = records.filter(v => v[valueProp] !== selectedObject[valueProp])
        // @ts-ignore
        onChange(newValue, null);  // Assuming you're okay with passing null as the second argument
      }
    }
  }

  const selectAllFromOption = (option: CustomGroupOption<ValueType>) => {
    const currentValues = ((value as unknown) || []) as ValueType[] 

    // All values from given option
    const optionValues = option.options.map((o) => o.value)

    // Selected values should be all current ones + the ones from the option
    // added and grouped by unique given value prop, usually record id
    const selectedValues = uniqBy([...currentValues, ...optionValues], (value) => resolveValueProp(value)) 
    onChange && onChange(selectedValues as any, undefined as any)
  }

  const selectNoneFromOption = (option: CustomGroupOption<ValueType>) => {
    const currentValues = ((value as unknown) || []) as ValueType[] 

    // Array of all options resolved value props, usually ids of records
    const optionValuePropsArray = option.options.map((o) => resolveValueProp(o.value))

    // None values are just current values with filtered out all values with id from given option
    const filteredValues = currentValues.filter((value) => !optionValuePropsArray.includes(resolveValueProp(value)))

    onChange && onChange(filteredValues as any, undefined as any)
  }

  const allowGroupHelpers = (restProps.mode && restProps.mode == 'multiple')
  const GroupLabel = (props: React.PropsWithChildren & {
    option: CustomGroupOption<ValueType>
  }) => {
    const option = props.option

    if (!allowGroupHelpers || !option.allFilters) return option.label

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <div>
          {option.label}
        </div>

        <div>
          <SelectAll
            onClick={() => selectAllFromOption(option)}
          >
            ALL
          </SelectAll>
          <SelectAll
            onClick={() => selectNoneFromOption(option)}
          >
            NONE
          </SelectAll>
        </div>
      </div>
    )
  }


  const resolveLabelProp = (option: CustomOption<ValueType>) => {
    if (typeof labelProp === 'function') {
      return labelProp(option.value)
    } else {
      return option.value[labelProp]
    }
  }

  const resolveSearchProp = (option: CustomOption<ValueType>) => {
    if (typeof searchProp === 'function') {
      return searchProp(option.value)
    } else {
      return option.value[searchProp]
    }
  }

  const resolveValueProp = (value: ValueType | ValueType[] | undefined) => {
    let returnValue: ValueType | ValueType[] | undefined | null

    if (restProps.mode == 'multiple' && Array.isArray(value)) {
      returnValue = value ? value.map((v) => (v[valueProp])) : []
    } else {
      if (!Array.isArray(value)) {
        returnValue = value ? value[valueProp] : null
      }
    }

    return returnValue
  }

  // Ensure the value selected record is actually an available option and can be rendered inside the select input
  let optionsWithValue: typeof options = [...options]
  if (value && !restProps.searchValue) {
    if (restProps.mode && restProps.mode == 'multiple') {
      let selectedValueObjects: typeof options = []

      selectedValueObjects = value.map((val: ValueType) => {
        const optionOfValue = getSelectedObject(val[valueProp])

        if (!optionOfValue) {
          return {
            value: val,
          }
        }

        return false
      }).filter((obj: { value: ValueType }) => obj)

      optionsWithValue = [...options, ...selectedValueObjects]
    } else {
      const optionOfValue = getSelectedObject(value[valueProp])

      if (!optionOfValue) {
        optionsWithValue = [...options, {
          value: value,
        }]
      }
    }
  }


  const antSelectValue = resolveValueProp(value)

  const isValueSelected = (value: string) => {
    if (Array.isArray(antSelectValue)) {
      return !!antSelectValue.find((valSelected) => value == valSelected as any as string)
    } else {
      return antSelectValue as any as string == value
    }
  }

  const showCheckboxes = restProps.mode && restProps.mode == 'multiple'

  const OptionWrapper = !showCheckboxes ? (props: React.PropsWithChildren & { value: string }) => (
    <>{props.children}</>
  ) : (props: React.PropsWithChildren & { value: string }) => {
    if (props.value == CREATE_NEW_KEY) {
      return <>{props.children}</>
    }

    return (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            whiteSpace: 'normal',
          }}
        >
          <Checkbox
            style={{
              marginTop: '-3px',
            }}
            checked={isValueSelected(props.value)}
          />
          {props.children}
        </div>
      </>
    )
  }


  return (
    <Select
      onChange={handleChange as any}
      onSelect={handleSelect as any}
      // @ts-ignore
      onDeselect={handleDeselect}
      // @ts-ignore
      value={antSelectValue}
      defaultValue={defaultValue ? defaultValue[valueProp] : undefined}
      optionFilterProp='search'
      menuItemSelectedIcon={false}
      {...restProps}

      onInputKeyDown={filterInputMode ? handleKeyDownOnFilterInputMode : (restProps?.onKeyDown)}
      tagRender={filterInputMode ? tagRenderOnFilterInputMode : (restProps?.tagRender)}

      onSearch={onSearch}
      searchValue={searchValue}
    >
      {optionsWithValue.map((option, index) => {
        if ('options' in option) {
          return (
            <Select.OptGroup 
              key={index}
              label={
                <GroupLabel
                  option={option}
                />
              } 
            >
              {option.options.map((subOption, subIndex) => (
                <Select.Option key={subOption.value[valueProp]} value={subOption.value[valueProp] as any} search={resolveSearchProp(subOption)} disabled={subOption.disabled}
                  style={{
                    paddingLeft: showCheckboxes ? '12px' : undefined
                  }}
                >
                  <OptionWrapper value={subOption.value[valueProp]}>
                    {resolveLabelProp(subOption)}
                  </OptionWrapper>
                </Select.Option>
              ))}
            </Select.OptGroup>
          )
        } else {
          return (
            <Select.Option key={option.value[valueProp]} value={option.value[valueProp] as any} search={resolveSearchProp(option)}>
              <OptionWrapper value={option.value[valueProp]}>
                {resolveLabelProp(option)}
              </OptionWrapper>
            </Select.Option>
          )
        }
      })}
    </Select>
  )
}

const SelectAll = styled.button`
  border: none;
  outline: none;
  background: transparent;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: #999;
  cursor: pointer;

  &:hover {
    color: ${COLORS.primaryGreen};

  }
`

export const SelectAllLink = SelectAll

export default RecordsSelect