import { QueryClient, useMutation, useQueryClient } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiProject, TApiTask } from "../../api-client/types"
import { TApiGenericError, TApiGenericResponse } from "../../timenotes-query/timenotes-query.types"
import { TApiProjectsGroup } from "../../api-client/projects-groups-api"
import { TApiProjectsUser } from "../../api-client/projects-users-api"

interface Args {
  projectId: string
  projectsUserId: string
}

interface Result {
  projectsUser: TApiProjectsUser
}

export const useDeleteProjectsUserMutation = (options: any = {}) => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  const query = useMutation<Result, TApiGenericError<TApiProjectsUser>, Args>(
    ['projects-users', 'delete'],
    async (args) => {
      const response = await apiClient.deleteProjectsUser(args.projectId, args.projectsUserId)

      if (!response.ok) {
        throw response.errors
      }

      return {
        projectsUser: response.projectsUser,
      }
    },
    {
      ...options,
      onSuccess: (data: Result, args: Args) => {
        queryClient.invalidateQueries('projects-users', { exact: false })

        if (options.onSuccess) {
          options.onSuccess(data, args)
        }
      },
    },
  )

  return {
    mutation: query
  }
}

export default useDeleteProjectsUserMutation