import Duration from 'utils/duration'
import DashboardNumberCard from './dashboard-number-card';

export const ProjectTimeCard = ({ projectInsights }: { projectInsights: any; }): JSX.Element => {
  const totals = projectInsights.totals
  const toDurationString = (duration: number) => { return new Duration(duration as any).toDurationString(); };
  const nonBillableTime = totals.duration - totals.billableDuration;

  const billableTimePercentage = totals.duration != 0 ? `(${(totals.billableDuration / totals.duration * 100).toFixed(2)}%)` : "";
  const nonBillableTimePercentage = totals.duration != 0 ? `(${(nonBillableTime / totals.duration * 100).toFixed(2)}%)` : "";

  return (
    <DashboardNumberCard
      title="TOTAL TIME LOGGED"
      numberFormatted={toDurationString(totals.duration)}
      description1={`Billable time: ${toDurationString(totals.billableDuration)} ${billableTimePercentage}`}
      description2={`Non-billable time: ${toDurationString(nonBillableTime)} ${nonBillableTimePercentage}`} />
  );
};

export default ProjectTimeCard