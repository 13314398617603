import { TApiParams, TApiClient, TApiResponse } from "./types"
import BaseApi, { getQueryString, parameterizeArray } from './base'

export interface IGetClientsResponse extends TApiResponse {
  clients: Array<TApiClient>
}

export interface ICreateClientParams {
  name: string
}

export interface ICreateClientResponse extends TApiResponse {
  client: TApiClient
}

class ClientsApi extends BaseApi {
  async getClients(params?: TApiParams): Promise<IGetClientsResponse> {
    const requestParams = { ...params, perPage: 1000 }
    const query = getQueryString(this.paramsToSnakeCase(requestParams), true)

    let response = await fetch(`${this.rootUrl}/clients${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async createClient(params: ICreateClientParams): Promise<ICreateClientResponse> {
    let response = await fetch(`${this.rootUrl}/clients`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        client: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async updateClient(clientId: string, params: ICreateClientParams) {
    let response = await fetch(`${this.rootUrl}/clients/${clientId}`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        client: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async deleteClient (clientId: string) {
    let response = await fetch(`${this.rootUrl}/clients/${clientId}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
}

export default ClientsApi
