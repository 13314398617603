import React, { FC } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { Button, Menu, Tooltip } from "components/base"
import { InfoIcon } from "components/base/icons"

import _map from "lodash/map"
import { Dropdown } from "antd"
import LogoImg from "components/base/logo"
import { timenotesLogo } from "assets/logos"

const ICON_SIZE = '28px'

const HeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
`

const InfoIconWithMargin = styled(InfoIcon)`
  margin-left: 10px;
`

const Icon = styled.div`
  margin-left: 8px;
`

export interface IButton {
  title: string
  size?: 'small' | 'middle' | 'large'
  icon?: string | FC
  type?: "link" | "text" | "default" | "ghost" | "primary" | "dashed"
  danger?: boolean
  disabled?: boolean
  disabledTooltip?: string

  callback(): void
}

export interface IDropdownOption {
  title: string
  Icon?: any
  size?: 'small' | 'middle' | 'large'
  // Icon?: JSX.Element
  // Icon?: React.ReactNode
  type?: "link" | "text" | "default" | "ghost" | "primary" | "dashed"

  callback(): void
}

export interface IButtonDropdown {
  title: string
  disabled?: boolean
  disabledTooltip?: string
  danger?: boolean
  icon?: string | FC
  type?: "link" | "text" | "default" | "ghost" | "primary" | "dashed"

  options: IDropdownOption[]
}

export interface PageHeaderInterface {
  origin?: string
  color?: string
  title?: string
  customTitle?: React.ReactNode
  titleHint?: string
  buttonLabel?: string
  buttonCallback?(): void
  customHeader?: React.ReactNode

  buttons?: Array<IButton | IButtonDropdown>
}

export const ButtonsRow = styled.div`
  & .ant-btn {
    margin-left: 20px;
  }
`

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  & svg {
    margin-right: 8px;
  }
`

enum IconType {
  ASANA = "asana",
  TODOIST = "todoist",
  TIMENOTES = "timenotes",
  BASECAMP = "basecamp",
  GITHUB = "github",
  TRELLO = "trello",
}

const setIcon = (type: string) => {
  const size = ICON_SIZE

  const data: any = {
    [IconType.ASANA]: <LogoImg name={IconType.ASANA} style={{ width: size, height: size }} />,
    [IconType.BASECAMP]: <LogoImg name={IconType.BASECAMP} style={{ width: size, height: size }} />,
    [IconType.GITHUB]: <LogoImg name={IconType.GITHUB} style={{ width: size, height: size }} />,
    [IconType.TODOIST]: <LogoImg name={IconType.TODOIST} style={{ width: size, height: size }} />,
    [IconType.TRELLO]: <LogoImg name={IconType.TRELLO} style={{ width: size, height: size }} />,
    // [IconType.TIMENOTES]: <LogoImg name={IconType.TIMENOTES} style={{ width: "size", height: "size" }} />,
  }
  return data[type]
}

export const renderHeaderButton = (buttonConfig: IButton | IButtonDropdown): JSX.Element => {

  const mainButtonIcon = buttonConfig.icon ? <buttonConfig.icon /> : null

  // Standard Button
  if (buttonConfig.hasOwnProperty("callback")) {
    const button = buttonConfig as IButton

    return (
      <Button key={button.title} onClick={button.callback} type={button.type ? button.type : "default"} disabled={button.disabled} disabledTooltip={button.disabledTooltip} danger={button.danger}
        size={button.size}
      >
        {mainButtonIcon}
        {button.title}
      </Button>
    )

    // Dropdown button
  } else {
    const button = buttonConfig as IButtonDropdown
    const menu = (
      <Menu>
        {button.options.map(({ title, Icon, callback }) => {
          return (
            <Menu.Item key={title} onClick={() => callback()}>
              <ItemWrapper>
                <Icon />
                {`${title}`}
              </ItemWrapper>
            </Menu.Item>
          )
        })}
      </Menu>
    )

    return (
      <Dropdown disabled={button.disabled} key={button.title} overlay={menu} trigger={["click"]} placement="bottomCenter">
        <Button type={button.type ? button.type : "default"} onClick={() => {}} disabled={button.disabled} disabledTooltip={button.disabledTooltip}>
          {mainButtonIcon}
          {button.title}
        </Button>
      </Dropdown>
    )
  }
}

const PageHeader = ({
  color,
  origin,
  title,
  customTitle,
  titleHint,
  buttons,
  buttonLabel,
  buttonCallback,
  customHeader,
}: PageHeaderInterface): JSX.Element => {
  return (
    <React.Fragment>
      <HeaderLayout>
        {title && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h1 style={color ? { paddingLeft: '12px', borderLeft: `8px ${color || 'gray'} solid` } : {}}>
              {title}
              {titleHint && (
                <Tooltip title={titleHint}>
                  <InfoIconWithMargin />
                </Tooltip>
              )}
            </h1>
            {origin && (
              <>
                {origin == 'timenotes' ? (
                  <img src={timenotesLogo} style={{ width: ICON_SIZE, height: ICON_SIZE, marginLeft: '24px', marginBottom: '10px' }} />
                ) : (
                  <Icon style={{ marginLeft: '24px', marginBottom: '10px' }}>{setIcon(origin)}</Icon>
                )}
              </>
            )}

          </div>
        )}

        {customTitle}

        <div style={{ display: 'flex' }}>
          <div>
            {customHeader}
          </div>

          {buttons && buttons.length > 0 && <ButtonsRow>{_map(buttons, renderHeaderButton)}</ButtonsRow>}

          {buttonLabel && buttonCallback && (
            <div>

              <Button type="primary" onClick={buttonCallback}>
                {buttonLabel}
              </Button>
            </div>
          )}
        </div>
      </HeaderLayout>
    </React.Fragment>
  )
}

export default PageHeader