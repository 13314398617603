import { Card, Progress } from "antd"

export default function DashboardProgressCard({ title, percent, description1, description2 }: {
  title: string,
  percent: number,
  description1?: string,
  description2?: string 
 }) {

 const color = percent > 100 ? 'red' : '#86D688'

 return (
   <Card style={{ display: 'flex', width: '100%', height: '144px', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
     <h5>{ title }</h5>

     <div style={{ width: '100%', marginBottom: '5px' }}>
       <Progress percent={percent} showInfo={false} style={{ width: '100%' }} strokeColor={color} />
     </div>

     { description1 && (
       <div>{description1 } </div>
     )}

     { description2 && (
       <div>{description2 } </div>
     )}
   </Card>
 )
}
