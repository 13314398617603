import { message } from "antd"
import { useEffect } from "react"

 
const useQueryGenericErrorMessage = (mutation: any) => {
  const errors: any = mutation.error

  // Generic error handling!
  useEffect(() => {
    if (mutation.isError) {
      message.error(Object.values(mutation.error)[0] || 'Something went wrong.')
    }
  }, [mutation.isError])
}

export default useQueryGenericErrorMessage