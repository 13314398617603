import { UseQueryOptions, useQuery } from "react-query"
import { useApiClient } from "../api-client/api-client.hooks"
import { TApiTag, TApiParams } from "../api-client/types"
import { TApiGenericErrors, TApiGenericResponse } from "../timenotes-query/timenotes-query.types"

interface Params extends TApiParams {}

export const useTagsQuery = (params: Params = {}, options: UseQueryOptions<TApiGenericResponse<TApiTag[]>, TApiGenericErrors<null>> = {}) => {
  const apiClient = useApiClient()

  const query = useQuery<TApiGenericResponse<TApiTag[]>, TApiGenericErrors<null>>(
    ['tags', params],
    async () => {
      const response = await apiClient.getTags({ ...params, perPage: 1000 })

      return {
        data: response.tags || [],
        ok: true,
        status: 200,
      }
    },
    {
      initialData: { data: [], ok: true, status: 200 },
      ...options
    }
  )

  return query
}
