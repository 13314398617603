import { useCurrentUserQuery } from "@timenotes/shared/src/services/users-accounts/queries/use-current-user-query"
import { omitBy } from "lodash"
import { useQueryClient } from "react-query"
import useApiClient from "./useApiClient"
import { TApiUsersAccount } from "@timenotes/shared/src/services/api-client/types"
import { IUpdateUsersAccountParams } from "@timenotes/shared/src/services/api-client/users-accounts-api"

export const useCurrentUser = () => {
  const apiClient = useApiClient()

  const {
    currentUserQuery
  } = useCurrentUserQuery({
    enabled: !!(apiClient.accessToken && apiClient.accountId)
  })

  const queryClient = useQueryClient()

  const loading = currentUserQuery.isLoading
  const loaded = currentUserQuery.isFetched
  const currentUser = currentUserQuery.data?.usersAccount

  const updateCurrentUser = async (user: TApiUsersAccount) => {
    const params: IUpdateUsersAccountParams = {
      ...omitBy(user, ['billableRate', 'costRate']),
    }

    const response = await apiClient.updateUsersAccount(currentUser?.id as string, params)

    // @ts-ignore
    if (response.ok) {
      queryClient.setQueryData(['current-user'], () => {
        return {
          usersAccount: response.usersAccount
        }
      })
    }

    return response
  }

  return {
    currentUser,
    loading,
    currentUserLoaded: loaded,
    updateCurrentUser,
  }
}