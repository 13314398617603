import { useTopNavigationLinks } from "hooks/layout"
import { useHistory } from "react-router-dom"


export default function useTeamNavigationLinks() {
  const history = useHistory()

  useTopNavigationLinks([
    {
      label: 'MEMBERS',
      urlMatcher: "team/members",
      onClick: () => { 
        history.push('/team/members')
      },
    },
    {
      label: 'GROUPS',
      urlMatcher: "team/groups",
      onClick: () => { 
        history.push('/team/groups')
      },
    }
  ])
}