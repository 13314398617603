import _map from 'lodash/map'
import { useLayoutContext } from 'hooks/layout'

export interface TopNavLinkItem {
  label: string,
  urlMatcher?: RegExp | string
  onClick(): void
}

const TopNavLinksComponent = () => {
  const { links } = useLayoutContext()
  const href = window.location.href

  const linksMap: JSX.Element[] = _map(links as TopNavLinkItem[], (link) => { 
    const isActive = link.urlMatcher ? !!href.match(link.urlMatcher) : false

    return (
      <li key={link.label} className={`ant-menu-item ant-menu-item-only-child ${isActive ? "ant-menu-item-active" : ""}`} onClick={link.onClick}>
        {link.label}
      </li>
    )
  })

  return (
    <>
      {linksMap}
    </>
  )
}

export const isActiveUrl = (urlMatcher?: string | RegExp): boolean => {
  if (!urlMatcher) {
    return false
  }

  const href = window.location.href

  return urlMatcher ? !!href.match(urlMatcher) : false
}

export default TopNavLinksComponent