import { Button, Form, Input, Select, Checkbox, Divider } from "antd"
import { map } from "lodash"
import { useFormik } from "formik"
import ErrorMessage from "components/base/error-message"
import { toDurationString } from "components/forms/duration-input"
import { CURRENCIES, DATE_FORMATS } from "./const"
import moment from "moment-timezone"
import LogoUpload from "./logo-upload"
import { IUpdateSettingsParams, TApiSetting } from "@timenotes/shared/src/services/api-client/settings-api"
import { TApiResponse } from "@timenotes/shared/src/services/api-client/types"

export interface IFormObject extends TApiSetting {
  accountLogo?: IUpdateSettingsParams['accountLogo'],
}

export interface WorkspaceSettingsFormProps {
  initialValues: IFormObject
  onSubmit(values: IFormObject): Promise<TApiResponse>
}

const getTimeZoneOptions = () => {
  return moment.tz.names().map((timeZone) => {
    const utcOffset = (moment().tz(timeZone).utcOffset()/60)
    const utcLabel = `(UTC ${utcOffset >= 0 ? '+' : ''}${utcOffset})`

    return (
      <Select.Option 
        key={timeZone}
        value={timeZone}
      >
        {timeZone} {utcLabel}
      </Select.Option>
    )
  })
}

const WorkspaceSettingsForm = (props: WorkspaceSettingsFormProps) => {
  const initialValues = props.initialValues
  const formik = useFormik<IFormObject>({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values, formik) => {
      const response = await props.onSubmit(values)

      if (!response.ok && response.errors) {
        formik.setErrors(response.errors)
      }

      if (response.ok) {
        formik.resetForm({ values })
      }
    }
  })

  return (
    <Form 
      onSubmitCapture={formik.handleSubmit} 
      layout="vertical"
    >

      <Form.Item label="Logo">
        <LogoUpload
          accountLogoUrl={formik.values.accountLogoUrl}
        />
      </Form.Item>

      <Divider />

      <Form.Item
        label="Workspace name"
      >
        <Input
          width="100%"
          value={formik.values.name}
          onChange={(e) => { 
            formik.setFieldValue('name', e.target.value)
          }}
        />
        <ErrorMessage msg={formik.errors.name} />
      </Form.Item>

      <Form.Item
        label="Time format"
      >
        <Select
          value={formik.values.timeFormat}
          onChange={(value) => { 
            formik.setFieldValue('timeFormat', value)
          }}
          allowClear={false}
        >
          <Select.Option key="12h" value="12h">12h - e.g. 3PM  </Select.Option>
          <Select.Option key="24h" value="24h">24h - e.g. 15:00 </Select.Option>
        </Select>

        <ErrorMessage msg={formik.errors.timeFormat} />
      </Form.Item>

      <Form.Item
        label="Workspace Time Zone"
      >
        <Select
          value={formik.values.timeZone}
          onChange={(value) => { 
            formik.setFieldValue('timeZone', value)
          }}
          showSearch={true}
          allowClear={false}
        >
          {getTimeZoneOptions()}
        </Select>

        <ErrorMessage msg={formik.errors.timeZone} />
      </Form.Item>

      <Form.Item
        label="Date format"
      >
        <Select
          value={formik.values.dateFormat}
          onChange={(value) => { 
            formik.setFieldValue('dateFormat', value)
          }}
          allowClear={false}
        >
          {map(DATE_FORMATS, (dateFormat) => (
            <Select.Option key={dateFormat} value={dateFormat}>{dateFormat}</Select.Option>
          ))}
        </Select>

        <ErrorMessage msg={formik.errors.dateFormat} />
      </Form.Item>

      <Form.Item
        label="Workweek start"
      >
        <Select
          value={formik.values.weekStart}
          onChange={(value) => { 
            formik.setFieldValue('weekStart', value)
          }}
          allowClear={false}
        >
          <Select.Option key="monday" value="monday">Monday</Select.Option>
          <Select.Option key="saturday" value="saturday">Saturday</Select.Option>
          <Select.Option key="sunday" value="sunday">Sunday</Select.Option>
       </Select>

        <ErrorMessage msg={formik.errors.weekStart} />
      </Form.Item>

      <Form.Item
        label="Workweek days"
      >
        <Select
          value={formik.values.workingWeekDays}
          placeholder="No workweek days"
          tagRender={(item) => {
            const lenght = formik.values.workingWeekDays.length

            if (formik.values.workingWeekDays[0] == item.value) {
              return (
                <div>{lenght} workweek day{lenght > 1 ? 's' : ''}</div>
              )
            } else {
              return <div></div>
            }
         }}
          mode="multiple"
          onChange={(value) => { 
            formik.setFieldValue('workingWeekDays', value)
          }}
        >
          <Select.Option key="monday" value="1">Monday</Select.Option>
          <Select.Option key="tuesday" value="2">Tuesday</Select.Option>
          <Select.Option key="wednesday" value="3">Wednesday</Select.Option>
          <Select.Option key="thursday" value="4">Thursday</Select.Option>
          <Select.Option key="friday" value="5">Friday</Select.Option>
          <Select.Option key="saturday" value="6">Saturday</Select.Option>
          <Select.Option key="sunday" value="7">Sunday</Select.Option>
 
        </Select>

        <ErrorMessage msg={formik.errors.weekStart} />
      </Form.Item>


      <Form.Item
        label="Recommended workday time in minutes"
        tooltip="Based on this number timesheet cells of team members will become green or red to highlight the amount."
      >
        <Input
          width="100%"
          type="number"
          suffix={`(${toDurationString(formik.values.worktime)})`}
          value={formik.values.worktime}
          onChange={(e) => { 
            formik.setFieldValue('worktime', e.target.value)
          }}
          allowClear={false}
        />
        <ErrorMessage msg={formik.errors.worktime} />
      </Form.Item>

      <Form.Item
        label="Max worktime in minutes in day per team member"
        tooltip="Team members will not be allowed to log more time per day than this number. Leave it empty for no limits."
      >
        <Input
          width="100%"
          type="number"
          suffix={`(${toDurationString(formik.values.maxWorktime)})`}
          value={formik.values.maxWorktime}
          placeholder="No max work time - 24h per day (1440 minutes)"
          onChange={(e) => { 
            formik.setFieldValue('maxWorktime', e.target.value)
          }}
          allowClear={false}
        />
        <ErrorMessage msg={formik.errors.worktime} />
      </Form.Item>


      <Form.Item
        label="Workspace currency"
      >
        <Select
          value={formik.values.defaultBillableRateCurrency}
          showSearch={true}
          onChange={(value) => { 
            formik.setFieldValue('defaultBillableRateCurrency', value)
          }}
          allowClear={false}
        >
          {map(CURRENCIES, (dateFormat) => (
            <Select.Option key={dateFormat} value={dateFormat}>{dateFormat}</Select.Option>
          ))}
        </Select>

        <ErrorMessage msg={formik.errors.defaultBillableRateCurrency} />
      </Form.Item>

      <Form.Item
        label="Default billable rate"
      >
        <Input
          placeholder="(Default: 0.0)"
          type="number"
          width="100%"
          suffix={`${formik.values.defaultBillableRateCurrency} per hour`}
          value={`${formik.values.defaultBillableRate}`}
          onChange={(e) => { 
            formik.setFieldValue('defaultBillableRate', e.target.value)
          }}
        />
        <ErrorMessage msg={formik.errors.defaultBillableRate} />
      </Form.Item>

      <Form.Item
        label="Default cost rate"
      >
        <Input
          placeholder="(Default: 0.0)"
          type="number"
          width="100%"
          suffix={`${formik.values.defaultBillableRateCurrency} per hour`}
          value={`${formik.values.defaultCostRate}`}
          onChange={(e) => { 
            formik.setFieldValue('defaultCostRate', e.target.value)
          }}
        />
        <ErrorMessage msg={formik.errors.defaultCostRate} />
      </Form.Item>

      <Checkbox
        checked={formik.values.includeTimenotesLogoPdf}
        onChange={(e) => {
          formik.setFieldValue('includeTimenotesLogoPdf', e.target.checked)
        }}
      >
        Include Timenotes logo in PDF reports?
      </Checkbox>

      <br />
      <br />


      <div style={{ 
        marginTop: '20px',
        display: 'flex', 
        justifyContent: 'flex-end' 
      }}>
        <Button
          type={formik.dirty ? 'primary' : 'default'}
          htmlType="submit"
          disabled={!formik.dirty || formik.isSubmitting}
          loading={formik.isSubmitting}
          onClick={() => {
            formik.submitForm()
          }}
        >
          Update
        </Button>
      </div>
    </Form>
  )
}

export default WorkspaceSettingsForm