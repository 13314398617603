import {useApiCall} from "hooks/useApiCall"
import useApiClient from "hooks/useApiClient"
import {useState} from "react"

export const useTaskParents = () => {
  const apiClient = useApiClient()
  const [taskParentsError, setTaskParentsError] = useState<string | undefined>(undefined)

  const {
    data: taskParents,
    request: getTaskParents,
    loading: taskParentsLoading,
  } = useApiCall(async ({
    projectId,
    taskType,
  }: {
    projectId: string,
    taskType?: string | null,
  }) => {
    const response = await apiClient.get(`/projects/${projectId}/task_parents`, {
      filters: {
        taskType: taskType ? taskType : null
      }
    })

    if (!response.ok) {
      setTaskParentsError("Can not fetch integration data!")
    } else {
      setTaskParentsError(undefined)
    }

    return response.taskParents || []
  })

  return {
    getTaskParents,
    taskParents,
    taskParentsLoading,
    taskParentsError,
  }
}

export default {}
