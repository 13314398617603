import { ReactNode, createContext, useContext, useRef, useState } from "react"
import { Modal, message } from "antd"
import React from "react"
import TagForm from "pages/tags/components/tag-form"
import { TApiTag } from "services/api-client/types"

type TOnSuccessCallback = (tag: TApiTag) => void
export type NewTagModalContextProps = {
  visible: boolean
  setVisible(v: boolean): void

  tagName: string
  setTagName(n: string): void

  onSuccess: TOnSuccessCallback
  setOnSuccess(fn: TOnSuccessCallback): void
}

export type NewTagModalContextProviderProps = {
  children: ReactNode
}

const NewTagModalContext = createContext<NewTagModalContextProps | undefined>(undefined)

export const NewTagModalContextProvider : React.FC<NewTagModalContextProviderProps> = (props) => {
  const { children } = props

  const onSuccessRef = useRef<TOnSuccessCallback>((tag) => { message.success(`New tag "${tag.name}" added!`) })

  const onSuccess = (tag: TApiTag) => {
    if (onSuccessRef.current) {
      return onSuccessRef.current(tag)
    }
  }

  const setOnSuccess = (fn: TOnSuccessCallback) => {
    onSuccessRef.current = fn
  }

  const [visible, setVisible] = useState(false)
  const [tagName, setTagName] = useState("")

  const modalProps = {
    visible: visible,
    setVisible: setVisible,
    tagName: tagName,
    setTagName: setTagName,
    onSuccess,
    setOnSuccess,
  }

  return (
    <NewTagModalContext.Provider value={modalProps}>
      <>
        {children}
        <NewTagModal {...modalProps}/>
      </>
    </NewTagModalContext.Provider>
  )
}

export const useNewTagModal = (): NewTagModalContextProps => {
  const context = useContext(NewTagModalContext)
  if (!context) {
    throw new Error('useNewTagModal should be used within a NewTagModalContext provider')
  }

  return context
}

export const NewTagModal = (props: NewTagModalContextProps) => {

  return (
    <Modal
      title={"Add tag"}
      destroyOnClose={true}
      visible={props.visible}
      onCancel={() => { props.setVisible(false) }}
      footer={false}
    >
      <TagForm
        initialValues={{
          name: props.tagName
        }}
        onSuccess={(tag, addAnother) => {
          if (!addAnother) {
            props.setVisible(false)
          }

          props.onSuccess(tag)
        }}
      />
    </Modal>

  )
}

export default NewTagModal