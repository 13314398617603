import { Card, Divider } from "antd"
import { TApiImportsSummary } from "services/timenotes/imports/imports.types"
import { toTitleCase } from "utils/string-utils"

const ImportSummaryTable = ({
  importId,
  summary,
}: {
  importId: string,
  summary: TApiImportsSummary,
}) => {
  const summaryItems = Object.entries(summary).map(([key, value]) => ({key, value }))

  return (
    <Card>
      <div className="page-content-long">
        <h3>Data Records</h3>

        <Divider />

        {summaryItems.map((item) => (
          <div id={item.key} >
            {toTitleCase(item.key == 'timeLogs' ? 'time logs' : item.key)} - {item.value} new 

            <Divider />
          </div>
        ))}

      </div>
    </Card>
  )
}

export default ImportSummaryTable
