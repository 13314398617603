import { useMutation, useQuery, useQueryClient } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TQueryOptions } from "../../timenotes-query/timenotes-query.types"
import { TimeLogFormItemProps } from "../../time-logs/time-logs.types"
import { FormikErrors } from "formik"

type Params = {
  params?: any
  options?: TQueryOptions
}

export const useStartActiveTrackerMutation = ({ params, options }: Params = {}) => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  const query = useMutation(
    ['active-tracker', 'start'],
    async (args: TimeLogFormItemProps) => {

      const requestParams = {
        taskId: args.task?.id,
        description: args.description,
        tags: (args.tags || []).map((tag) => tag.id),
        isBillable: args.isBillable,
      }

      const response = await apiClient.createActiveTracker(requestParams)

      if (!response.ok) {
        throw response.errors as FormikErrors<TimeLogFormItemProps>
      }

      return {
        activeTracker: response.activeTracker
      }
    },
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData('active-tracker', (oldTracker) => {
          // if old tracker is running, it will be stopped producing a new time long entry
          // so that time logs should be invalidated too
          if (oldTracker) {
            queryClient.invalidateQueries('time-logs', { exact: false })
          }
          return data
        })
      }
    }
  )

  return query
}

export default useStartActiveTrackerMutation