import {Card, Steps} from "antd"
import {Step} from "components/base"
import PageHeader from "components/layout/page-header"

const ImportHeader = ({
  step,
}: {
  step: number,
}) => {

  return (
    <div className="page-content">
      <PageHeader title="Data Import" />

      <Card>
        <Steps current={step}>
          <Step title="Upload File" />
          <Step title="Configure Columns" />
          <Step title="Map Projects" />
          <Step title="Map Users" />
          <Step title="Import Summary" />
        </Steps>
      </Card>
    </div>
  )
}

export default ImportHeader
