import styled from 'styled-components'
import { Card, Button } from 'components/base'

import PROJECTS_DIRECTORY_IMAGE from './assets/connect-all-projects.png'

const COLORS = {
  primary: '#07c5b7',

  cardHighlight: "#EFEFEF",
}

const AppCard = styled(Card)`
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 195px;
  height: 195px;
  margin-top: 52px;
  padding: 10px;

  & .card-logo {
    display: flex;
    justify-content: center;
  }

  & .card-app-name{
    margin-top: 5px;
    margin-bottom: 14px;
    font-size: 24px;
    text-align: center;
    text-transform: capitalize;
  }

  & img {
    margin: auto;
  }
`

const WorkspaceListViewWrapper = styled.div`
  margin-top: 26px;

  & p {
    color #474D55;
  }
`

const VerticalFlex = styled.div`
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
`

const SubmitButton = styled(Button)`
  margin-top: 20px;
`

const ConnectAllButton = styled(Button)`
  margin-left: 19px;
`


interface IonAppClick {
  (integrationApp: any): void
}

export interface IView {
  onSelectProjects(): void
  onConnectAll(): void
}

const ConnectAllProjectsView = ({ onSelectProjects, onConnectAll }: IView): JSX.Element => {

  return (
    <VerticalFlex>
      <img src={PROJECTS_DIRECTORY_IMAGE} />

      <ButtonsRow>
        <Button onClick={onSelectProjects}>
          Select from list
        </Button>

        <ConnectAllButton 
          type="primary"

          onClick={onConnectAll} 
        >
          Connect all
        </ConnectAllButton>
      </ButtonsRow>
    </VerticalFlex>
  )
}

export default ConnectAllProjectsView