import React from 'react'
import styled from 'styled-components'

import DOLLAR_IMAGE from './assets/dollar.png'
import DOLLAR_IMAGE_FILLED from './assets/dollar-filled.png'
import { HTMLProps, ReactNode } from 'react'
import { Tooltip } from 'antd'

const DollarImg = styled.div`
  background-image: url("${DOLLAR_IMAGE}");
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  padding: 18px;

  &.clickable {
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  //TODO: Revise better hover status. When its filled on hover 
  //user does not get a feedback of chaning the flag to unchecked at all &.clickable:hover, 
  &.checked {
    background-image: url("${DOLLAR_IMAGE_FILLED}");
  }

  &:focus, &:hover {
    outline: none;
    // box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 4px;
  }
`

interface IBillableFlag extends Omit<React.ComponentType<typeof DollarImg>, 'onChange'> {
  checked?: boolean
  clickable?: boolean
  disabled?: boolean
  disabledText?: ReactNode | string
  onChange?(checked: boolean): void
  onClick?(e: any): void
}

const BillableFlag = ({ clickable, checked = false, onChange, ...props }: IBillableFlag) => {
  let isClickable = clickable === false ? false : true 

  if (props.disabled) {
    isClickable = false
  }

  const imgSrc = checked ? DOLLAR_IMAGE_FILLED : DOLLAR_IMAGE
  const className = checked ? 'checked' : ''

  let onClick = props.onClick
  if (!onClick && onChange) {
    onClick = (e: any) => {
      onChange(!checked)
    }
  }

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.key == " " || e.key == "Enter") && onChange) {
      e.preventDefault()
      e.stopPropagation()

      onChange(!checked)
    }
  }

  if (props.disabled) {
    onClick = () => {}
  }

  return (
    <Tooltip title={props.disabled && props.disabledText}>
      <DollarImg 
        onKeyDown={handleOnKeyDown}
        tabIndex={props.disabled ? undefined : 0}
        className={`${className} ${isClickable ? 'clickable' : ''} ${props.disabled ? 'disabled' : ''}`}
        {...props}
        onClick={onClick}
      />
    </Tooltip>
  )
}

export default BillableFlag