import { useState } from 'react'

import { useQueryParams } from 'hooks/app-router'
import { useHistory } from 'react-router-dom'
import NewIntegrationStepsView, { NewIntegrationStep } from '../shared/components/new-integration-steps'
import PageHeader from 'components/layout/page-header'
import { Button, Card } from 'antd'
import ExtensionLink from './components/extension-link'

const NewIntegrationSettingsPage = () => {
  const history = useHistory()
  const [loaded, setLoaded] = useState(false)

  const queryParams = useQueryParams<{
    integrationAccountId: string
  }>()

  const integrationAccountId = queryParams.integrationAccountId || ""

  const handleExit = () => {
    history.push('/integrations/workspaces')
  }

  const HEADER_TEXT = "Chrome Extension Reminder"
  const DESCRIPTION_TEXT = "Extension is necessary to insert timenotes time tracking buttons inside of integration app interface and to be able to track time directly from the tool. Without the extension, it is only possible to track time for the sychronised projects inside of Timenotes."

  return (
      <div className="page-content">
        <NewIntegrationStepsView currentStep={ NewIntegrationStep.BrowserExtension } />

          <Card>
            <PageHeader
              title={HEADER_TEXT}
            />
            <p>
              {DESCRIPTION_TEXT}
            </p>

            <br />

            <ExtensionLink />

            <br />

            <Button type="primary" onClick={handleExit}>
              Finish
            </Button>
          </Card>
      </div>
  )
}

export default NewIntegrationSettingsPage