import { UseQueryOptions, useQuery } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiGenericError } from "../../timenotes-query/timenotes-query.types"
import moment, { Moment } from "moment-timezone"
import React, { useEffect, useState } from "react"
import { TApiPeriod } from "../approvals-types"
import usePeriodModel from "../hooks/use-period-model"

interface Params {
  date?: Moment // 2024
}

interface Result {
  period: TApiPeriod
}

export const usePeriodByDateQuery = (params?: Params, options?: UseQueryOptions<any, any>) => {
  const apiClient = useApiClient()

  const {
    parsePeriod,
  } = usePeriodModel()

  const defaultDate = moment()
  const paramsDate = params?.date
  const [periodDate, setPeriodDate] = useState(paramsDate || defaultDate)

  useEffect(() => {
    if (!!params?.date) {
      setPeriodDate(params?.date)
    }
  }, [params?.date?.format('YYYY-MM-DD')])

  const requestParams = {
    date: periodDate.format('YYYY-MM-DD')
  }

  const query = useQuery<Result, TApiGenericError<any>>(
    ['period', (requestParams || {})] as const,
    async () => {
      const response = await apiClient.get('/approvals/periods/fetch', requestParams)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        period: parsePeriod(response.approvalsPeriod)
      }
    },
    {
      ...options
    }
  )

  return {
    periodQuery: query,
    period: query.data?.period,

    periodDate,
    setPeriodDate, 
  }
}