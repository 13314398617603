import { TApiResponse, TApiParams, TApiPlan} from "./types"
import BaseApi, { getQueryString } from './base'

export type TApiContact = {
  id: string
  firstName: string
  lastName: string
  confirmedAt: string
  email: string
  owner: boolean
}

export type TContactFormObject = Omit<TApiContact, 'id' | 'confirmedAt' | 'owner'>

export interface IGetContactsResponse extends TApiResponse {
  contacts: TApiContact[]
}

export interface ICreateContactResponse extends TApiResponse {
  contact: TApiContact
}

class ContactsApi extends BaseApi {
  async getContacts(): Promise<IGetContactsResponse> {
    return this.get('/contacts')
  }

  async createContact(contact: TContactFormObject): Promise<ICreateContactResponse> {
    return this.create('/contacts', { contact: contact })
  }

  async deleteContact(contactId: string): Promise<TApiResponse> {
    return this.delete(`/contacts/${contactId}`)
  }

  async confirmContactEmail(confirmationToken: string): Promise<TApiResponse> {
    return this.patch(`/contacts/confirm_email`, { confirmationToken })
  }

}

export default ContactsApi