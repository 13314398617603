import React, { useEffect, useState } from 'react'

import { addQueryStringParameter } from 'utils/history'
import { Card, Skeleton, message } from 'antd'
import { WEB_APP_URL } from 'pages/settings/components/const'
import { map } from 'lodash'
import AuthenticationListItemView from './components/authentication-list-item/view'
import ExtensionLink from '../new-settings/components/extension-link'
import PageHeader from 'components/layout/page-header'
import useApiClient from 'hooks/useApiClient'
import { useQuery } from 'react-query'

const AuthenticationsPage = () => {
  const [loaded, setLoaded] = useState(false)
  const apiClient = useApiClient()

  const connectedIntegrationAppsQuery = useQuery(
    ['connected-integration-apps'],
    async () => {
      const response = await apiClient.getConnectedIntegrationApps()

      if (!response.ok) {
        throw response.errors
      }

      return {
        connectedIntegrationApps: map(response.integrations, (int) => {
          return {
            id: int.id || null,
            name: int.service,
            authUrl: int.authUrl,
            externalUsername: int.externalName,
            integrationAccountsCount: int.integrationAccountsCount,
            status: int.status,
            isConnected: !!int.id,
          }
        })
      }
    }
  )

  useEffect(() => {
    if (connectedIntegrationAppsQuery.isSuccess) {
      setLoaded(true)
    }
  }, [connectedIntegrationAppsQuery.status])

  const handleConnect = (app: any) => {
    let authUrl = addQueryStringParameter(app.authUrl as string, "web_redirect_url", `${WEB_APP_URL}/integrations/authentications`)
    authUrl = addQueryStringParameter(authUrl, 'session_token', apiClient.accessToken as string)
    authUrl = addQueryStringParameter(authUrl, 'account_id', apiClient.accountId as string)

    // @ts-ignore
    window.top.location = authUrl
  }

  const handleDisconnect = (integrationApp: any) => {
    if (typeof integrationApp.id === "string") {
      const apiCall = apiClient.deleteIntegration({ integrationId: integrationApp.id})

      apiCall.then((response) => {
        if ((response as any).ok) {
          connectedIntegrationAppsQuery.refetch()
          message.success("Integration disconnected!")
        }
      })
    } else {
      message.error("Invalid integration selected!")
    }
  }

  const integrationApps = connectedIntegrationAppsQuery.data?.connectedIntegrationApps || []

  const authenticationsList = map(integrationApps, (app) => {
    return React.createElement(AuthenticationListItemView, {
      key: app.name,
      integrationApp: app,
      onConnect: handleConnect,
      onDisconnect: handleDisconnect,
    })
  })


  return (
      <div className="page-content">
        <Card>
          <PageHeader
            title="Timenotes Browser Extension"
          />
        <p>
          Remember that browser extension is necessary to see time tracking buttons inside of an integrated tool and to be able to track time directly from tool's website. Without the extansion, it is only possible to track time for the integrated projects inside of Timenotes."
        </p>

        <br />

        <ExtensionLink />
      </Card>

      <PageHeader
        title="Users Integration authentication"
        titleHint="Team members’ permissions to projects and tasks from integration are also given on the basis of permissions in integrated tool so each member needs to authenticate own account from integration to see in Timenotes any projects, tasks."
      />

      <p>
        User integration authentication bonds your Timenotes account with your account from integrated tool (eg. Asana, Github).  It means that your Timenotes user will get the same accesses as you have in the tool and you’ll be able to see and track time in Timenotes for the same tasks, projects that you can access in the integrated tool.
      </p>

      <br />
      <br />

      {loaded ? (
        <div>
          { integrationApps.length > 0 ? (
            <>
              {authenticationsList}
            </>
          ) : (
            <Card>
              No integrations connected
            </Card>
          )}
        </div>
      ) : (
        <Skeleton />
      )}

    </div>

  )
}

export default AuthenticationsPage