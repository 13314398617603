import { useFormik } from "formik"
import useApiClient from "hooks/useApiClient"
import ImportHeader from "./components/import-header"
import NewImportForm from "./components/new-import-form"

const NewImportPage = () => {
  return (
    <>
      <ImportHeader step={0} />

      <div className="page-content">
        <NewImportForm />
      </div>
    </>
  )
}

export default NewImportPage
