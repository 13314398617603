import { useEffect, useState } from 'react'

import _sortBy from 'lodash/sortBy'

import { Menu, StatusBadge, message } from 'components/base'
import { Button, Card, Col, Divider, Modal, Row, Skeleton, Tooltip } from 'antd'
import { useQueryParams } from 'hooks/app-router'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import LogoImg from 'components/base/logo'
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown'
import { DeleteIcon, DisconnectIcon, ReconnectIcon, SyncIcon } from 'components/base/icons'
import { useDefaultDateFormat } from 'hooks/settings'
import AutoConnectNewProjectsSwitch from '../new-select-projects/components/auto-connect-new-projects-switch'
import { map } from 'lodash'
import { useIntegrationAccountQuery } from 'services/integrations/integration-accounts/use-integration-account-query'
import { useIntegrationAccountsConnectedProjectsQuery } from 'services/integrations/integration-accounts-projects/use-integration-accounts-connected-projects-query'
import useIntegrationAccountsActions from 'services/integrations/integration-accounts/use-integration-accounts-actions'
import useApiClient from 'hooks/useApiClient'
import moment from 'moment-timezone'
import { TApiIntegrationAccount, TApiProject } from '@timenotes/shared/src/services/api-client/types'
import { getApiErrorMessage } from '@timenotes/shared/src/services/api-client'
import { SettingOutlined } from '@ant-design/icons'

const HeaderWrapper = styled(Row)`
  align-items: center;
`

const AppNameSpan = styled.span`
  margin-left: 5px;
  text-transform: capitalize;
`

const ShowIntegrationAccountPage = () => {
  const history = useHistory()
  const apiClient = useApiClient()

  const { defaultDateFormat } = useDefaultDateFormat()

  const [loaded, setLoaded] = useState(false)

  const queryParams  = useQueryParams<{ 
    integrationAccountId: string 
  }>()

  const integrationAccountId = queryParams.integrationAccountId || ""

  const {
    query: integrationAccountQuery
  } = useIntegrationAccountQuery({
    integrationAccountId: integrationAccountId
  })

  const integrationAccount = integrationAccountQuery.data?.integrationAccount as TApiIntegrationAccount

  const {
    query: integrationAccountsConnectedProjectsQuery
  } = useIntegrationAccountsConnectedProjectsQuery({
    integrationAccountId: integrationAccountId
  })

  const projects = integrationAccountsConnectedProjectsQuery.data?.projects || []

  useEffect(() => {
    if (integrationAccountQuery.isSuccess && integrationAccountsConnectedProjectsQuery.isSuccess) {
      setLoaded(true)
    }
  }, [integrationAccountQuery.isSuccess, integrationAccountsConnectedProjectsQuery.isSuccess])

  const integrationAccountsActions = useIntegrationAccountsActions()

  const handleSynchronise = (): any => {
    integrationAccountsActions.synchroniseIntegrationAccount(integrationAccount).then((response) => {
      if (response.ok) {
        reloadPage()
      }
    })
  }

  const handleReconnect = (): any => {
    integrationAccountsActions.reconnectIntegrationAccount(integrationAccount).then((response) => {
      if (response.ok) {
        reloadPage()
      }
    })
  }

  const handleDisconnect = (): any => {
    integrationAccountsActions.disconnectIntegrationAccount(integrationAccount).then((response) => {
      if (response.ok) {
        reloadPage()
      }
    })
  }

  const handleDelete = (): any => {
    integrationAccountsActions.deleteIntegrationAccount(integrationAccount, () => {
      setTimeout(() => {
        history.push('/integrations/workspaces')
      }, 1000)
    })
  }

  const updateAutoSyncProjects = (newValue: boolean) => {
    const updateRequestBody = {
      integrationAccountId: integrationAccountId,
      integrationAccount: {
        autoSyncProjects: newValue
      }
    }

    return apiClient.updateIntegrationAccount(updateRequestBody).then((response) => {
      if (response.ok) {
        message.success("Auto sync updated!")
        reloadPage()
      } else {
        message.error(getApiErrorMessage(response, 'base'))
      }
    })
  }

  const reloadPage = ({ withLoading } = { withLoading: false}) => {
    integrationAccountQuery.refetch()
    integrationAccountsConnectedProjectsQuery.refetch()

    if (withLoading) {
      setLoaded(false)
    }
  }

  const handleNavigateToNewProject = () => {
    history.push(`/integrations/integration-account/${integrationAccountId}/projects/new`)
  }

  const handleExit = () => {
    history.push('/integrations')
  }

  const handleSynchroniseProject = (connectedProject: TApiProject): any => {
    const apiCall = apiClient.synchroniseProject(connectedProject.id)

    apiCall.then((response) => {
      if ((response as any).ok) {
        message.success('Project synchronisation triggered, it might take couple minutes...')

        reloadPage()
      } else {
        message.error(getApiErrorMessage(response, 'base'))
      }
    })
  }

  const handleReconnectProject = (connectedProject: TApiProject): any => {
    const apiCall = apiClient.reconnectProject(connectedProject.id)

    apiCall.then((response) => {
      if ((response as any).ok) {
        message.success('Project reconnected!')

        reloadPage()
      } else {
        message.error(getApiErrorMessage(response, 'base'))
      }
    })
  }

  const handleDisconnectProject = (connectedProject: TApiProject): any => {
    const apiCall = apiClient.disconnectProject(connectedProject.id)

    apiCall.then((response) => {
      if ((response as any).ok) {
        message.success('Project disconnected!')

        reloadPage()
      } else {
        message.error(getApiErrorMessage(response, 'base'))
      }
    })
  }

  const handleDeleteProject = (connectedProject: TApiProject): any => {
    Modal.confirm({
      title: "Are you sure you want to delete the project?",
      content: "It will also delete all the data logged in timenotes, please consider disconnecting it instead.",
      onOk: () => {
        const apiCall = apiClient.deleteProject(connectedProject.id)

        apiCall.then((response) => {
          if ((response as any).ok) {
            message.success('Project deleted!')

            reloadPage()
          } else {
            message.error(getApiErrorMessage(response, 'base'))
          }
        })
      }
    })
  }

  const workspace = integrationAccount as TApiIntegrationAccount
  const workspaceLastSyncAt = workspace?.lastSyncAt ? moment(workspace.lastSyncAt).format(defaultDateFormat) : 'not synchronised'

  return (
    <div className="page-content">
      {loaded ? (
        <div>
          <Card>
            <HeaderWrapper>
              <Col span={4}>
                <LogoImg name={workspace.service} />
                <AppNameSpan> {workspace.service} </AppNameSpan>
                <span> ({workspace.name}) </span>
              </Col>

              <Col span={4}>
                <StatusBadge status={!!workspace.userName} />
                <span> {!!workspace.userName ? "Connected" : "Disconnected"} </span>
              </Col>

              <Col span={15}>
              </Col>
              <Col span={1} style={{ textAlign: 'right' }}>
                <EllipsisDropdown
                  menu={(
                    <Menu>
                       {workspace.isConnected && (
                        <Menu.Item key={0} onClick={handleSynchronise}>
                          <SyncIcon /> Synchronise
                        </Menu.Item>
                      )}

                      {!workspace.isConnected && (
                        <Menu.Item key={1} onClick={handleReconnect}>
                          <ReconnectIcon /> Reconnect
                        </Menu.Item>
                      )}

                      { workspace.isConnected && (
                        <Menu.Item key={2} onClick={handleDisconnect}>
                          <DisconnectIcon /> Disconnect
                        </Menu.Item>
                      )}

                      <Menu.Item key={3} onClick={handleDelete}>
                        <DeleteIcon /> Delete
                      </Menu.Item>
                    </Menu>
                  )}
                />
              </Col>
            </HeaderWrapper>

            <br />

            <div>
              <p>Last sync: {workspaceLastSyncAt}</p>
              {workspace.isConnected && (
                <p> Connected by: {workspace.userName || 'n/a' } </p>
              )}

            </div>

          </Card>

          <Card>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <h2>Connected Projects</h2>

              {workspace.isConnected ? (
                <Button type="primary" onClick={handleNavigateToNewProject}>
                  Connect new project
                </Button>
              ) : (
                <Tooltip title="Can not connect new projects as this integration is disconnected. Please re-connect the integration workspace first!">
                  <Button onClick={handleNavigateToNewProject} disabled>
                    Connect new project
                  </Button>
                </Tooltip>
              )}
            </div>
            <AutoConnectNewProjectsSwitch
              value={workspace.autoSyncProjects}
              onChange={updateAutoSyncProjects}
            />

            <br />
            <br />

            {
              map(projects, (project) => (
                <div key={project.id} style={{ width: "100%" }}>
                  <Row>
                    <Divider style={{
                      marginTop: '5px',
                      marginBottom: '10px',
                    }}/>
  
                    <Col span={5}>
                      <span>{project.name}</span>
                    </Col>

                    <Col span={6}>
                      <StatusBadge status={project.connected } />
                      <span><i> {project.connected ? 'Connected' : 'Disconnected'} </i></span>
                    </Col>

                    <Col span={13} style={{ textAlign: 'right' }}>
                      {workspace.isConnected && (
                        <EllipsisDropdown
                          menu={(
                            <Menu>
                              <Menu.Item key={-1} onClick={() => history.push(`/projects/${project.id}`)}>
                                <SettingOutlined /> Manage project
                              </Menu.Item>

                              {project.connected && (
                                <Menu.Item key={0} onClick={() => { handleSynchroniseProject(project) }}>
                                  <SyncIcon /> Synchronise
                                </Menu.Item>
                              )}

                              {!project.connected && (
                                <Menu.Item key={1} onClick={() => { handleReconnectProject(project) }}>
                                  <DisconnectIcon /> Reconnect
                                </Menu.Item>
                              )}

                              {project.connected && (
                                <Menu.Item key={2} onClick={() => { handleDisconnectProject(project) }}>
                                  <DisconnectIcon /> Disconnect
                                </Menu.Item>
                              )}

                              <Menu.Item key={3} onClick={() => { handleDeleteProject(project) }}>
                                <DeleteIcon /> Delete
                              </Menu.Item>

                            </Menu>
                          )}
                        />
                      )}
                    </Col>
                  </Row>

               </div>
              ))
            }
          </Card>
        </div>
      ) : (
        <div>
          <Card>
            <Skeleton />
          </Card>

          <Card>
            <Skeleton />
          </Card>
        </div>
      )}
    </div>
  )
}

export default ShowIntegrationAccountPage