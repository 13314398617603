import styled from "styled-components"

const $colorBase = '#3a3aa3'
const $colorBaseSecondary= "#07c5b7"
const $colorBaseHover = "#3d6cc6"
const $colorDashboard = "#f9f9f9"
const $colorModal = "#334567"

const $colorError = "#df374c"
const $colorNotice = "#ffab03"
const $colorSuccess = "#4CCFA3"

/*White*/
const $colorLight = "#fff"
const $colorLightWhite = "#E5E5EB"
const $colorBgLightDark = "#F9F9FC"
const $colorBgLightDarkSecondary = "#B9B9C4"

const $colorBgMidDark = "#F7F7FA"

/*Black*/
const $colorDark = "#000"
const $colorLightDark = "#474D55"

const todayBorderColor = '#888'

const TimesheetStylesContainer = styled.div`
  width: 100%;

  // Calendar Table
  .calendar_table {
    table {
      background-color: transparent;
      border: inherit;
      table-layout: fixed;
      -webkit-user-select: none; /* Chrome all / Safari all */
      -moz-user-select: none;    /* Firefox all             */
      -ms-user-select: none;     /* IE 10+                  */
      user-select: none;

      thead {
        tr {
          th {
            width: 30px;
            height: 31px;
            font-size: 11px;
            font-weight: 600;
            text-align: center;
            color: ${$colorLightDark};
            line-height: 31px;
            white-space: nowrap;
            padding: 0px;
            @media(max-width: 1400px) {
              width: 3%;
              min-width: 30px;
            }
            &.col_weekend {
              color: ${$colorBgLightDarkSecondary};
            }
            &.col_person {
              width: 101px;
              padding-right: 4px;
              border: inherit;
              @media(max-width: 1400px) {
                width: initial !important;
              }
            }
            &.is_today {
              color: ${$colorSuccess};
              background-image: url('/img/current-timesheet-day.png');
              background-size: 31px;
              background-repeat: no-repeat;
              background-position: center;
              line-height: 27px;
              border-bottom: 1px solid ${todayBorderColor};
            }
            &.col_sum {
              width: 50px;
              margin-right: 4px;
              font-size: 12px;
              color: ${$colorSuccess};
              @media(max-width: 1400px) {
                display: none;
              }
            }
          }
        }
      }
      tbody {
        tr:first-child .col_person:first-of-type {
          border-top: 0;
          margin-top: 1px;
        }
        tr {
          &.sum_important_row {
            td {
              font-weight: bold;
              font-size: 9px;
              &.col_sum {
                font-size: 11px;
                color: ${$colorBaseSecondary};
              }
              &.col_person {
                color: ${$colorSuccess};
                &.col_sum {
                  font-size: 11px;
                }
                div {
                  float: left;
                  font-size: 11px;
                  &.sum_wide {
                    padding-left: 5px;
                    @media(min-width: 1401px) {
                      display: none;
                    }
                  }
                }
              }

              &.is_today {
                border-top-color: ${$colorLight};

                &.is_empty {
                  color: transparent;
                }
              }

              &.not_empty .icon_container svg {
                opacity: 0.4;
              }
            }
          }

          td {
            position: relative;
            background-clip: padding-box;
            width: 30px;
            height: 31px;
            padding: 0px;
            line-height: 31px;
            font-size: 11px;
            font-weight: 600;
            text-align: center;
            white-space: nowrap;
            background-color: ${$colorLight};
            color: ${$colorLightDark};
            border: solid 1px ${$colorLightWhite};
            @media(max-width: 1400px) {
              width: 3%;
              min-width: 30px;
            }

            &.col_weekend {
              background-color: ${$colorBgLightDark};
            }

            &.col_yesterday {
              border-right: 1px solid ${todayBorderColor};
            }

            &.col_today {
              border: 1px solid ${todayBorderColor};
            }

            &.without_border {
              border: none;
            }

            &.is_added {
              background-color: ${$colorBaseSecondary};
              color: ${$colorLight};
              cursor: pointer;

              &.not_clickable {
                cursor: auto;
              }

              &.overfull {
                background-color: ${$colorError};
              }

              div.icon_container span.absence_type_icon svg {
                opacity: 0.4;
                path {
                  fill: #fff;
                }
              }

            }
            &.col_person {
              background-color: transparent;
              border-left: none;
              width: 101px;
              padding-right: 4px;
              text-align: left;
              overflow: visible;
              @media(max-width: 1400px) {
                width: initial !important;
              }
              &.project_name a {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                border-right: none;
                &.archived {
                  color: ${$colorBgLightDarkSecondary};
                }
              }
              a, .row_title_wrapper {
                overflow: overlay;
                color: ${$colorLightDark};

                .name_user_tr {
                  position: relative;

                  .details_info_person {
                    position: absolute;
                    display: none;
                    top: 25px;
                    left: 80%;
                    z-index: 10;
                    border-radius: 4px;
                    background-color: #ffffff;
                    box-shadow: 0 0 10px 0 rgba(35, 36, 39, 0.15);
                    font-size: 11px;
                    padding: 0px 15px;

                    .data_full_name {
                      span {
                        color: ${$colorBaseSecondary};
                        display: none;
                        margin-left: 5px;
                        padding-left: 10px;
                        border-left: 1px solid #E5E5EB;
                        @media(max-width: 1400px) {
                          display: inline;
                        }
                      }
                    }
                  }
                  span {
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    &.full_name {
                      display: block;
                      @media(max-width: 1400px) {
                        display: none;
                      }
                    }
                    &.short_name {
                      display: none;
                      @media(max-width: 1400px) {
                        display: block;
                      }
                    }
                  }
                }
              }
            }
            &.not_enough {
              background-color: rgba(7, 197, 183, 0.5);

              div.icon_container span.absence_type_icon svg {
                opacity: 0.3;
              }
            }
            &.project-daily {
              color: ${$colorLightDark};
              cursor: pointer;
              font-size: 10px;
              font-weight: 500;
            }
            &.user-daily {
              cursor: pointer;
              font-size: 9px;
            }
            &.day-report {
              cursor: pointer;
              font-size: 9px;
            }
            &.col_sum {
              width: 50px;
              padding-right: 5px;
              position: relative;
              border-right: none;
              @media(max-width: 1400px) {
                display: none;
              }
              &:after {
                content: " ";
                position: absolute;
                top: -1px;
                right: 0px;
                height: calc(100% + 2px);
                width: 4px;
                background-color: ${$colorBgLightDark};
                border-left: solid 1px ${$colorLightWhite};
              }
              a {
                color: ${$colorLightDark};
              }
            }
            &.is_today {
              box-shadow: 0 6px 10px 0 rgba(35,36,39,0.15);
              position: relative;
            }
            &.is_empty {
              color: transparent;
            }
            div.icon_container {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;

              span.duration {
                position: absolute;
              }

              span.absence_type_icon svg {
                width: 20px;
                height: 20px;

                path {
                  fill: ${$colorBgLightDarkSecondary};
                }
              }

              span.tooltiptext {
                visibility: hidden;
                position: absolute;
                width: auto;
                background-color: ${$colorLight};
                color: $colorLightDark;
                text-align: center;
                border-radius: 6px;
                padding: 10px 15px;
                z-index: 1;
                box-shadow: 0 0 7px ${$colorBgLightDarkSecondary};
                top: 40px;
                font-size: 12px;
                font-weight: 500;

                &.top {
                  top: unset;
                  bottom: 40px;
                }

                span {
                  float: left;
                  clear: left;

                  &:nth-child(even){
                    font-weight: 600;
                  }
                }
              }

              &:hover {
                span.tooltiptext {
                  visibility: visible;
                }
              }
            }
            &:last-child,&:nth-last-child(2) {
              div.icon_container span.tooltiptext {
                right: 0;
              }
            }
          }
        }
      }
    }
  }

  .bottom_belt {
    &.modal_timesheets {
      a {
        margin-right: 0px;
        margin-left: 37px;
      }
    }
  }

  .timesheet_projects_table {
    .tooltiptext {
      visibility: hidden;
      position: absolute;
      width: auto;
      background-color: ${$colorLight};
      color: ${$colorLightDark};
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      z-index: 1;
      box-shadow: 0 0 7px ${$colorBgLightDarkSecondary};
      margin-top: 25px;
      margin-left: 7px;
      font-size: 12px;
      font-weight: 500;
      right: -20%;
      @media(max-width: 1400px) {
        right: 74%;
      }
    }
  }

  .timesheet_projects_table.archived {
    &:hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }

  .modal.modal_large.modal_with_tab.user_daily {
    .content {
      .empty-space .content {
        margin-top: 15px;
      }

      .scrollable {
        overflow: visible;
      }

      table tbody td .holidays_comment_tooltip {
        top: -6px;
        margin-left: 40px;
      }
    }
  }

  /* NEW TIMESHEETS */
  .timesheets_primary_row {
    .col_person {
      font-weight: 700;
    }
  }
  .timesheets_row {
    &:hover {
      td {
        background-color: #EBEBEE !important;
        color: #474D55 !important;
      }
    }

    .name_user_tr {
      position: unset !important;

      .details_info_person {
        left: 50% !important;
      }
    }

    .col_person {
      width: 100% !important;
      display: flex;
      border-bottom: 0;
    }

    .row_expander {
      height: 20px;
      min-width: 20px;
      width: 20px;
      margin-top: 4px;

      &.arrow_down {
        background-image: image-url('icons/arrow_bottom_dark.png');
        background-size: 20px;
      }
      &.arrow_up {
        background-image: url('../img/icon/arrow_top.png');
        background-size: 20px;
      }
    }

    &.timesheets_secondary_row {
      // display: none;

      .col_person {
        .row_expander {
          min-width: 40px;
          width: 40px;
        }
      }
    }

    .row_title_column {
      cursor: pointer;
    }
  }

  .timesheets_summary_row {
    .col_person {
      border-bottom: 0;
    }

    .row_expander {
      height: 20px;
      width: 20px;
      margin-top: 4px;
    }
  }

  .select_list {
    padding: 8px 10px !important;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(35, 36, 39, 0.15);
    border-radius: 4px;

    a {
      font-size: 12px !important;
      padding: 4px 0px;
      font-weight: 500;
      text-align: left;
      color: #474D55;
      display: block;
    }
  }

  .select_list > li {
    margin-bottom: 1px;
  }

  .first_level_list {
    display: none;
    right: 0;
    top: 35px;
    z-index: 10;
    position: absolute;
    width: 70px !important;
  }

  .first_level_list > li {
    position: relative;
    width: 70px;
  }

  .first_level_list li:hover > ul {
    display: inline;
  }

  .first_level_list li:hover > a {
    color: #07C5B7 !important;
  }

  .second_level_list {
    position: absolute;
    top: -7px;
    left: 58px;
    z-index: 10;
    display: none;
    width: 116px !important;
  }

  .second_level_list > li {
    position: relative;
  }

  .timesheets_left_head {
    float: left !important;
  }

  // Base Table
  .modal-content {
    table tbody tr td {
      font-size: 12px;
    }
  }

  table.table_inner {
    background-color: transparent;
    border-left: none;
    border-right: none;
    border-top: none;
    .color_project {
      width: 6px;
      height: 14px;
      float: left;
      display: block;
      margin: 2px 9px 29px 0px;

      -webkit-border-radius: 1px;
      -moz-border-radius: 1px;
      border-radius: 1px;
    }
    .company {
      font-size: 12px;
      font-weight: 500;
      color: ${$colorBgLightDarkSecondary};
    }
    tr {
      &:hover {
        .icon_large {
          display: block;
        }
      }
      .icon_large {
        display: none;
      }
    }
    th,
    td {
      padding: 13px 15px 13px 0px;
      &.box_icon_table {
        padding-right: 0px;
      }
    }
    tbody {
      td {
        .sum {
          font-weight: bold;
          border-top: 1px solid ${$colorLightWhite};
          display: inline-block;
          padding: 4px 18px 0px 18px;
          margin-left: -18px;
        }
        .info-detail {
          font-size: 14px;
          font-weight: 500;
          color: ${$colorBgLightDarkSecondary};
          display: block;
        }
        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 1.36;
          color: ${$colorLightDark};
          margin-bottom: 5px;
          &.project-list-origin {
            display: inline-block;
          }
        }
        .btn_more, .btn_less {
          color: ${$colorBaseSecondary};
          font-size: 14px;
          font-weight: 500;
          display: block;
          margin-top: 2px;
        }
        .btn_less {
          display: none;
        }
        .more {
          display: none;
        }
        .more-options-container {
          display: none;
        }
      }
    }
    &.clients_tab > tbody > tr {
      height: 65px;
    }
  }

  table.invoices {
    span.payment_icon_stop {
      height: 16px;
      width: 16px;
      position: relative;
      top: 3px;
      display: inline-block;
      margin-left: 10px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: image-url('icons/svg/ic_stop_sm_hover.svg');
    }
  }

  table.base_table {
    background-color: ${$colorLight};
    margin-bottom: 10px;

    tbody tr {
      &:hover {
        background-color: ${$colorDashboard};
      }
    }
  }

  table {
    width: 100%;
    background-color: ${$colorLight};
    border: solid 1px ${$colorLightWhite};

    thead {
      tr {
        th {
          font-size: 12px;
          font-weight: bold;
          color: ${$colorLightDark};
          padding: 13px 15px;
          border-bottom: solid 1px ${$colorLightWhite};
          a {
            color: ${$colorLightDark};

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    tbody {
      tr {
        &:hover {
          .icon_table {
            display: block;
          }
        }
        td {
          font-size: 14px;
          font-weight: 500;
          padding: 13px 15px;
          color: ${$colorLightDark};
          border-bottom: solid 1px ${$colorLightWhite};
          .team-text-greyed {
            color: ${$colorBgLightDarkSecondary};
            font-style: italic;
          }
        }

        span {
          opacity: 0.75;
        }

        .break-word {
          word-break: break-word;
        }

        .user-name-cell-wrap {
          width: 175px;
        }

        .duration-cell-wrap {
          width: 85px;
        }

        .time-cell-wrap {
          width: 150px;
        }

      }
    }

    .box_icon_table {
      min-width: 39px;
      width: 39px;
      max-width: 39px;
      padding: 0px;
      position: relative;

      &.box_icon_large {
        padding-top: 18px;
      }

      .icon_table {
        display: none;
        width: 39px;
        height: 40px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        color: ${$colorBgLightDarkSecondary};
        &.visible {
          display: block;
        }
        .tooltiptext {
          visibility: hidden;
          position: absolute;
          width: auto;
          background-color: ${$colorLight};
          color: ${$colorBaseSecondary};
          text-align: center;
          border-radius: 6px;
          padding: 5px;
          z-index: 1;
          box-shadow: 0 0 7px ${$colorBgLightDarkSecondary};
          opacity: 0;
          transition: opacity .6s;
          top: 30px;
          margin-left: -5px;
          &.tooltip-danger {
            color: ${$colorError};
          }
          &.tooltip-adjusted {
            top: 40px;
            margin-left: -11px;
          }
          &.holidays_comment_tooltip {
            top: 40px;
            margin-left: 15px;
            color: ${$colorLightDark};
            font-style: italic;
            font-size: 12px;
            width: max-content;
            max-width: 260px;
            padding: 6px 8px;
            border-radius: 4px;
          }
        }
        &:hover {
          .tooltiptext {
            visibility: visible;
            opacity: 1;
          }
        }
        &:hover {
          color: ${$colorBase};
        }
        &.icon_dollar {
          background-image: image-url("new/icon/dollar.png");
          background-position: center 0px;
          background-size: 22px;
          height: 22px;
          margin-top: 12px;
          margin-left: -20px;
          &:hover {
            background-position: center bottom;
          }
          &.project-details-timelogs {
            position: relative;
            right: 10px;
          }
        }
        &.icon_pen {
          background-image: image-url("new/icon/icon_pen_sprite.png");
          background-position: center 0px;
          background-size: 22px;
          height: 22px;
          margin-top: 12px;
          &:hover {
            background-position: center bottom;
          }
          &.project-details-timelogs {
            position: relative;
            right: 10px;
          }
        }
        &.icon_comment {
          background-image: image-url("icons/ic_table_comment_normal.svg");
          height: 29px;
          width: 29px;
          margin: -6px 0 -6px 10px;
          &:hover {
            background-image: image-url("icons/ic_table_comment_pressed.svg");
          }
        }
        &.icon_decline {
          background-image: image-url("icons/ic_decline_normal.svg");
          height: 29px;
          width: 29px;
          &:hover {
            background-image: image-url("icons/ic_decline_hover.svg");
          }
        }
        &.icon_decline {
          background-image: image-url("icons/ic_decline_normal.svg");
          background-position: center 0px;
          background-size: 23px;
          height: 23px;
          margin-top: 12px;
          &:hover {
            background-image: image-url("icons/ic_decline_hover.svg");
            background-position: center bottom;
            cursor: pointer;
          }
        }
        &.icon_details {
          background-image: image-url("icons/ic_table_info_normal.svg");
          height: 29px;
          width: 29px;
          margin: -6px 0 -6px 10px;
          &:hover {
            background-image: image-url("icons/ic_table_info_pressed.svg");
          }
        }
        &.icon_search {
          background-image: image-url("new/icon/ic_preview_normal.svg");
          background-position: center 0px;
          background-size: 22px;
          height: 22px;
          margin-top: 12px;
          &:hover {
            background-image: image-url("new/icon/ic_preview_hover.svg");
            background-position: center bottom;
            cursor: pointer;
          }
          &.project-details-timelogs {
            position: relative;
            right: 10px;
          }
        }
        &.icon_trash {
          background-image: image-url("new/icon/icon_tash_sprite.png");
          background-position: center 0px;
          background-size: 26px;
          height: 23px;
          margin-top: 10px;
          &:hover {
            background-position: center -26px;
          }
          &.project-details-timelogs {
            position: relative;
            right: 10px;
          }
        }
        &.icon_resend {
          background-image: image-url("new/icon/icon_cart_sprite.png");
          background-position: center 0px;
          background-size: 26px;
          height: 23px;
          margin-top: 9px;
          &:hover {
            background-position: center -26px;
          }
        }
        &.icon_download {
          background-image: image-url('icons/icon_download.png');
          &:hover {
            background-image: image-url('icons/icon_download_active.png');
          }
        }
        &.icon_ok {
          background-image: image-url("new/icon/icon_good_sprite.png");
          background-position: center top;
          height: 22px;
          background-size: 22px;
          margin-top: 12px;
          &:hover {
            background-position: center bottom;
          }
        }
        &.icon_stop {
          background-image: image-url("new/icon/icon_ignore_sprite.png");
          background-position: center top;
          height: 22px;
          background-size: 22px;
          margin-top: 12px;
          &:hover {
            background-position: center bottom;
          }
        }
      }
    }

    .sort_link {
      position: relative;
      &:hover {
        text-decoration: none;
        &:before {
          content: " ";
          background-image: image-url('icons/arrow_bottom_dark.png');
          top: 2px;
          right: -15px;
          position: absolute;
          background-position: center;
          background-size: contain;
          width: 13px;
          height: 15px;
        }
        &:after {
          content: " ";
          background-image: image-url('icons/arrow_bottom_dark.png');
          top: -3px;
          right: -15px;
          position: absolute;
          background-position: center;
          background-size: contain;
          width: 13px;
          height: 15px;

          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
      &.asc {
        position: relative;
        &:before {
          content: " ";
          background-image: image-url('icons/arrow_bottom_grey.png');
          top: 2px;
          right: -15px;
          position: absolute;
          background-position: center;
          background-size: contain;
          width: 13px;
          height: 15px;
        }
        &:after {
          content: " ";
          background-image: image-url('icons/arrow_bottom_dark.png');
          top: -3px;
          right: -15px;
          position: absolute;
          background-position: center;
          background-size: contain;
          width: 13px;
          height: 15px;

          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
      &.desc {
        position: relative;
        &:before {
          content: " ";
          background-image: image-url('icons/arrow_bottom_dark.png');
          top: 2px;
          right: -15px;
          position: absolute;
          background-position: center;
          background-size: contain;
          width: 13px;
          height: 15px;
        }
        &:after {
          content: " ";
          background-image: image-url('icons/arrow_bottom_grey.png');
          top: -3px;
          right: -15px;
          position: absolute;
          background-position: center;
          background-size: contain;
          width: 13px;
          height: 15px;
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
  }

  .pages_control {
    height: 30px;
    background-color: ${$colorLight};
    border: solid 1px ${$colorLightWhite};
    position: relative;
    padding: 0px 32px;
    display: inline-block;
    float: right;
    margin-bottom: 10px;
    margin-right: 100px;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;

    &.first-active {
      padding-left: 11px;
    }

    &.last-active {
      padding-right: 11px;
    }

    &.project-details {
      display: inline-block;
      margin-right: 55px;
    }

    .btn_control {
      width: 24px;
      height: 24px;
      background-color: ${$colorDashboard};
      position: absolute;
      bottom: 3px;
      top: 3px;

      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      &:hover {
        box-shadow: inset 0px 0px 3px rgba(0,0,0, 0.1);
      }

      &[disabled="disabled"] {
        &:after {
          background-image: image-url("icons/arrow_bottom_grey.png");
          cursor: not-allowed;
        }
      }

      &:after {
        content: " ";
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-image: image-url("icons/arrow_bottom_dark.png");
        background-size: 14px;
        background-position: center;
        background-repeat: no-repeat;
      }

      &.btn_next {
        right: 3px;

        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }
      &.btn_prev {
        left: 3px;

        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
    .pages_control_list {
      text-align: center;
      margin: 3px 0px;
      &.timers_day_date {
        min-width: 205px;
      }
      &.reports_day_date {
        min-width: 95px;
      }
      li {
        display: inline-block;
        margin: 0 4px;

        a, span {
          font-size: 12px;
          font-weight: 500;
          text-align: left;
          height: 24px;
          line-height: 24px;
          color: ${$colorLightDark};
          &.is_active {
            color: ${$colorBase};
          }
        }
      }
      &.project-details {
        li {
          a, span {
            &.is_active {
              color: ${$colorBaseSecondary};
            }
          }
        }
      }
    }
  }

  .icon_modal.icon_pen {
    min-width: 39px;
    width: 39px;
    height: 22px;
    padding: 0px;
    display: none;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    color: ${$colorBgLightDarkSecondary};

    &.show {
      display: inline-block;
    }

    &.hide {
      display: none;
    }

    &:hover {
      color: ${$colorBase};
      background-position: center bottom;
    }

    background-image: image-url("new/icon/icon_pen_sprite.png");
    background-position: center 0px;
    background-size: 22px;
  }

  .task-modal-title {
    display: inline-block;
    padding-top: 3px;
  }

  .task-modal-title-part {
    display: inline-block;
    font-weight: 500;
  }

  #time-logs-tab table tbody tr {
    &:last-child .box_icon_table a .icon_table .tooltiptext {
      top: -20px;
      margin-left: -1px;
    }
    &:last-child .box_icon_table a.icon_trash .tooltiptext {
      top: -20px;
    }
  }
`

export default TimesheetStylesContainer