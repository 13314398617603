import { Card } from 'antd'
import { Skeleton } from 'components/base'

const LoadingSkeleton = (): JSX.Element => (
  <Card>
    <Skeleton active={true} />
    <Skeleton active={true} />
    <Skeleton active={true} />
  </Card>
)

export default LoadingSkeleton