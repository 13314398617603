import { useState } from "react"
import styled from "styled-components"
import PageHeader from "components/layout/page-header"
import { message, Modal, Skeleton, Tabs } from "antd"
import { ArchiveSvg, DeleteSvg, MoreSvg, NewProjectSvg as NewProject } from "assets/svg/icon"
import { useProjectQuery } from "@timenotes/shared/src/services/projects/projects.hooks"
import { useHistory } from "react-router-dom"
import useApiClient from "hooks/useApiClient"
import useArchiveProjectMutation from "@timenotes/shared/src/services/projects/queries/use-archive-project-mutation"
import useRestoreProjectMutation from "@timenotes/shared/src/services/projects/queries/use-restore-project-mutation"
import useDeleteProjectMutation from "@timenotes/shared/src/services/projects/queries/use-delete-project-mutation"
import { useCurrentProjectsPermissionsQuery } from "@timenotes/shared/src/services/permissions/permissions.hooks"
import { TApiErrors, TApiProject } from "@timenotes/shared/src/services/api-client/types"
import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"
import { useCopyProjectModal } from "pages/projects/list/components/project-table-cell/CopyProjectModal"
import { CopyOutlined } from "@ant-design/icons"

const TopBarHeader = styled.div`
  width: 100%;
  height: 140px;
  padding: 20px 0px 0px 0px;
  background-color: white;
  box-shadow: 0px 0px 0px 0px #e5e5eB inset;
  border-bottom: 1px #e5e5eB inset;
`

const TopBarContent = styled.div`
  width: 100%;
  height: 50px;
`

const TopBarFooter = styled.div`
  width: 100%;
  height: 70px;
  bottom: 0;

  & .ant-tabs-nav {
    margin-bottom: 0px;
  }
`

const TopBarWrapper = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  margin: auto;

  position: relative;
  max-width: 1380px;
  width: 100%;
`

interface IProps {
  projectId: string
  activeSection?: string
  onChange?(newSection: string): void
}

const ProjectDetailsHeader = (props: IProps) => {
  const history = useHistory()
  const apiClient = useApiClient()

  const archiveProjectMutation = useArchiveProjectMutation({
    onSuccess: () => {
      message.success("Project archived successfully!")
    },
    onError: (errors: TApiErrors) => {
      message.error(getApiErrorMessage({ errors: errors}, 'base'))
    },
  })

  const deleteProjectMutation = useDeleteProjectMutation({
    onSuccess: () => {
      message.success("Project deleted successfully!")
      history.push('/projects')
    },
    onError: (errors: TApiErrors) => {
      message.error(getApiErrorMessage({ errors: errors}, 'base'))
    },
  })
  const restoreProjectMutation = useRestoreProjectMutation({
    onSuccess: () => {
      message.success("Project restored successfully!")
    },
    onError: (errors: TApiErrors) => {
      message.error(getApiErrorMessage({ errors: errors}, 'base'))
    },
  })

  const [activeSection, setActiveSection] = useState(props.activeSection || 'tasks')
  const currentProjectsPermissionsQuery  = useCurrentProjectsPermissionsQuery({
    params: {
      projectId: props.projectId
    }
  })

  const permissions = currentProjectsPermissionsQuery.data?.permissions

  const projectQuery = useProjectQuery({
    projectId: props.projectId
  })

  const navigateToSection = (sectionName: string) => {
    const projectId = props.projectId

    const MENU_MAP: { [key: string]: string } = {
      insights: `/projects/${projectId}/insights`,
      tasks: `/projects/${projectId}/tasks`,
      team: `/projects/${projectId}/team`,
      settings: `/projects/${projectId}/settings`,
    }

    history.push(MENU_MAP[sectionName])
  }

  const handleMenuClick = (section: any) => {
    const newSection = section

    setActiveSection(newSection)

    if (props.onChange) {
      props.onChange(newSection)
    }

    //TODO: LEAVE HERE OR extract to separate controller?
    navigateToSection(newSection)
  }

  const {
    copyProjectModal,
    setCopyProjectModalVisible,
  } = useCopyProjectModal({
    project: projectQuery.data?.project || {} as TApiProject,
    onSuccess: () => {
      setCopyProjectModalVisible(false)
      history.push('/projects')
      message.success('Project copied successfully!')
    }
  })

  if (!projectQuery.data?.project) {
    return <TopBarHeader style={{ height: '140px ' }}><Skeleton paragraph={{ rows: 2 }} /></TopBarHeader>
  }

  const project = projectQuery.data.project

  const projectName = project.name
  const projectColor = project.color
  const projectOrigin = project.origin

  const moreOptions = [
    {
      title: "Delete Project",
      Icon: DeleteSvg,
      callback: () => {
        Modal.confirm({
          title: `Are you sure you want to delete - '${project.name}'?`,
          content: `It will couse also deleteing all time logs \n assigned to this project.`,
          okText: "Delete",
          okType: "primary",
          okButtonProps: {
            danger: true,
          },
          cancelText: "Cancel",
          onOk() {
            deleteProjectMutation.mutate({ projectId: project.id })
          },
          onCancel() { },
          centered: true,
          width: "490px",
          style: { whiteSpace: "pre-line" },
        })
      },
    },
  ]

  if (!project.archivedAt) {
    moreOptions.unshift(
      {
        title: "Archive Project",
        Icon: ArchiveSvg,
        callback: () => {
          archiveProjectMutation.mutate({ projectId: project.id })
        },
      }
    )
  } else {
    moreOptions.unshift(
      {
        title: "Restore Project",
        Icon: ArchiveSvg,
        callback: () => {
          restoreProjectMutation.mutate({ projectId: project.id })
        },
      }
    )
  }

  moreOptions.unshift(
    {
      title: "Copy Project",
      Icon: CopyOutlined,
      callback: () => setCopyProjectModalVisible(true)
    }
  )


  return (
    <TopBarHeader>
      <TopBarContent>
        <div className="page-content" style={{ marginTop: '0px', paddingLeft: '12px', paddingRight: '12px' }}>
          <PageHeader
            title={`${projectName} ${!!project.archivedAt ? "(ARCHIVED)" : ""}`}
            color={projectColor}
            origin={projectOrigin}
            buttons={[
              {
                title: "",
                disabled: !permissions?.manageCurrentProject,
                disabledTooltip: 'You have no permissions to manage this project!',
                type: "text",
                icon: MoreSvg,
                options: moreOptions,
              },
            ]}
          />
        </div>
      </TopBarContent>
      <TopBarFooter>
        <div className="page-content" style={{
          marginTop: '0px', paddingLeft: '12px', paddingRight: '12px', height: '70px',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'
        }}>
          <div style={{ display: 'flex', height: '70px%', justifyContent: 'space-between' }} >
            <Tabs type="card" activeKey={activeSection} tabBarGutter={5} onChange={handleMenuClick}>
              {permissions?.manageCurrentProject && (
                <Tabs.TabPane tab="Insights" key="insights" />
              )}
              <Tabs.TabPane tab="Tasks" key="tasks" />
              <Tabs.TabPane tab="Team" key="team" />
            </Tabs>

            {permissions?.manageCurrentProject && (
              <Tabs type="card" activeKey={activeSection} tabBarGutter={5} onChange={handleMenuClick}>
                <Tabs.TabPane tab="Settings" key="settings" />
              </Tabs>
            )}
          </div>
        </div>
      </TopBarFooter>
      {copyProjectModal}
    </TopBarHeader>
  )
}

export default ProjectDetailsHeader
