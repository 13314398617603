import { Button, Card, Col, Divider, Form, Input, List, Row, Skeleton, Tag, message } from "antd"
import { DeleteIcon } from "components/base/icons"
import CardWrapper from "components/layout/card-wrapper"
import { useFormik } from "formik"
import { useGetApiCall } from "hooks/useApiCall"
import { useEffect, useState } from "react"
import ReactJson from "react-json-view"
import { PlusOutlined } from '@ant-design/icons'
import useKeyPress from "hooks/useKeyPress"
import { useContacts, useCreateContact, useDeleteContact } from "hooks/contacts.hooks"
import { TApiContact, TContactFormObject } from "services/api-client/contacts-api"
import { TApiResponse } from "services/api-client/types"
import ErrorMessage from "components/base/error-message"

const BillingNotificationsSection = () => {
  const {
    contacts,
    loading,
    fetchContacts,
  } = useContacts()

  const { createContact } = useCreateContact()
  const { deleteContact } = useDeleteContact()

  const handleCreate = async (contact: TContactFormObject) => {
    const response = await createContact(contact)

    if (response.ok) {
      fetchContacts()
    }

    return response
  }

  const handleDelete = async (contact: TApiContact) => {
    const response = await deleteContact(contact.id)

    if (response.ok) {
      fetchContacts()
      message.success('Contact deleted successfully!')
    }

    return response
  }

  return (
    <CardWrapper size="medium">
      <h2>Billing notifications</h2>
      <Divider />

      <p>Billing emails will be sent to primary Workspace Owner by default.</p>
      <p>You may add additional billing Contacts (for example, your accounting department). All billing-related emails such as invoices will be sent to this contact list.</p>


      {(loading && !contacts) ? (
        <Card>
          <Skeleton />
        </Card>
      ) : (
        <BillingContactsList
          contacts={contacts || []}
          onCreateSubmit={handleCreate}
          onDelete={handleDelete}
        />
      )}
    </CardWrapper>
  )
}

const BillingContactsList = ({
  contacts,
  onCreateSubmit,
  onDelete,
}: {
  contacts: TApiContact[]
  onCreateSubmit(contact: TContactFormObject): Promise<TApiResponse>
  onDelete(contact: TApiContact): Promise<TApiResponse>
}
) => {
  const [addVisible, setAddVisible] = useState(false)
  const setContacts = (a: any): void => { }

  const escPressed = useKeyPress('Escape')

  const formik = useFormik<TContactFormObject>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    onSubmit: async (values, formikHelper) => {
      const response = await onCreateSubmit(values)

      if (response.ok) {
        formikHelper.resetForm()
        setAddVisible(false)
      } else if (response.errors) {
        formikHelper.setErrors(response.errors)
      }
    }
  })

  useEffect(() => {
    if (escPressed && addVisible) {
      formik.resetForm()
      setAddVisible(false)
    }
  }, [escPressed])

  return (
    <>
      {contacts.length == 0 && (
        <Card>
          No billing contacts defined!
        </Card>
      )}
      {contacts.map((contact) => (
        <Card key={contact.email}>
          <Row>
            <Col span="12">
              <div>
                {contact.firstName} {contact.lastName}
              </div>
              <div style={{ position: 'absolute', top: '17px', fontSize: '0.7em', opacity: 0.7 }}>
                {contact.owner ? (
                  <span>workspace owner</span>
                ) : (
                  <span>billing contact</span>
                )}
              </div>
            </Col>
            <Col span="8">
              {contact.email}
              {!contact.confirmedAt && (
                <div style={{ position: 'absolute', top: '17px', fontSize: '0.7em', opacity: 0.7 }}>
                  <span style={{ color: 'orange' }}>Email not confirmed!</span>
                </div>
              )}
            </Col>
            <Col span="4">
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <DeleteIcon onClick={async () => { onDelete(contact) }} />
              </div>
            </Col>
          </Row>
        </Card>
      ))}

      {!addVisible && (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setAddVisible(true)}>
            Add
          </Button>
        </div>
      )}

      {addVisible && (
        <Form
          onSubmitCapture={formik.handleSubmit}
        >
          <Card>
            <Row>
              <Col span="12">
                <div style={{ display: 'flex', justifyContent: 'start', gap: '10px', paddingRight: '10px' }}>
                  <div>
                    <Input
                      value={formik.values.firstName}
                      onChange={formik.handleChange('firstName')}
                      placeholder="First name"
                      autoFocus={true}
                    />
                    <ErrorMessage msg={formik.errors.firstName} />
                  </div>
                  <div>
                    <Input
                      value={formik.values.lastName}
                      onChange={formik.handleChange('lastName')}
                      placeholder="Last name"
                    />

                    <ErrorMessage msg={formik.errors.lastName} />
                  </div>
                </div>
              </Col>
              <Col span="6">
                <Input
                  value={formik.values.email}
                  onChange={formik.handleChange('email')}
                  placeholder="Email"
                />
                <ErrorMessage msg={formik.errors.email} />
              </Col>
              <Col span="6">

                <div style={{ display: 'flex', justifyContent: 'end', gap: '10px' }}>
                  <Button type="text" onClick={() => {
                    formik.resetForm()
                    setAddVisible(false)
                  }}>
                    Cancel
                  </Button>
                  <Button htmlType="submit" type="primary" loading={formik.isSubmitting}>
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </Form>
      )}
    </>
  )
}

export default BillingNotificationsSection