import {Card, Checkbox, message} from "antd"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { TApiConfirmImportColumnsResponse, TApiImportConfirmProjectsResponse} from "services/timenotes/imports/imports.types"
import ImportHeader from "./components/import-header"
import ImportProjectsConfigurationTable from "./components/import-projects-configuration-table"
import ImportUsersConfigurationTable from "./components/import-users-configuration-table"

const ConfigureImportUsersPage = () => {
  const location = useLocation()
  const history = useHistory()

  const usersMatchings = location.state?.usersMatchings as TApiImportConfirmProjectsResponse['usersMatchings']

  const { importId } = useParams<{
    importId: string
  }>()

  if (!usersMatchings) {
    message.warning("Account import expired, please try again!")
    history.push('/imports/new')

    return null
  }

  return (
    <>
      <ImportHeader step={3} />

      <div className="page-content">
        <Card>
          <p><b>
            Please configure the users mapping between the CSV file and your Timenotes database to ensure data consistency.
          </b></p>

          <p>
            We have identified {usersMatchings.length} different users in your CSV file. We have tried to match those with existing Timenotes users. Please confirm the matching or decide to invite the new users to Timenotes or just ignore the time logs of those.
          </p>

        </Card>

        <ImportUsersConfigurationTable 
          importId={importId}
          usersMatchings={usersMatchings}
        />

      </div>
    </>
  )
}

export default ConfigureImportUsersPage
