import { Select, Form, message } from "antd"
import FormItemLabel from "antd/lib/form/FormItemLabel"
import { Formik } from "formik"
import { useState } from "react"
import { IBulkCreateInvitationsParams, TApiInvitation } from "services/api-client/invitations-api"
import _difference from 'lodash/difference'
const { Option } = Select

const validateEmail = (email: string): boolean => {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

interface IInvitationsForm {
  onChange(emails: string[]): void
}

const InvitationsForm = ({ onChange, ...props }: IInvitationsForm) => {

  const [emails, setEmails] = useState<string[]>([])

  const handleChange = (newEmails: string[]) => {
    const newEmail = _difference(newEmails, emails)[0]

    if (newEmail === undefined || validateEmail(newEmail)) {
      setEmails(newEmails)
      onChange(newEmails)
    } else {
      message.error(`${newEmail} is not a valid email address!`)
    }
  }

  return (
    <Formik<IBulkCreateInvitationsParams>
      initialValues={{ emails: [] }}
      onSubmit={(invitations) => {}}
    >
      {(formik) => (
        <Form
          layout="vertical"
        >

          <Form.Item
            label="Emails"
          >
            <Select<string[]>
              autoFocus={true}
              value={emails}
              mode="tags" 
              style={{ width: '100%' }} 
              onChange={handleChange} 
              tokenSeparators={[',']}
            />
            <div style={{ marginTop: '5px' }}>
              <p>Click enter to add new email addresses</p>
            </div>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )

}

export default InvitationsForm