import { DEFAULT_APPROVAL_REQUESTS_GROUPS } from "@timenotes/shared/src/services/approvals/approvals-consts"
import { TApiPeriod } from "@timenotes/shared/src/services/approvals/approvals-types"
import { COLORS } from "components/layout-components/timenotes-theme"
import { capitalize, sum } from "lodash"
import HorizontalBar from "pages/dashboard/components/horizontal-bar"

type PeriodProgressBarProps = {
  period: TApiPeriod
}

export default function PeriodProgressBar(props: PeriodProgressBarProps) {
  const summary = { ...props.period.summary }

  const keys = Object.keys(DEFAULT_APPROVAL_REQUESTS_GROUPS)

  const segmentColor = {
    'open': COLORS.primaryPurple,
    'pending': 'orange',
    'approved': COLORS.primaryGreen,
    'rejected': 'red',
  }

  const approvedTimesheets = summary.approved || 0
  const totalTimesheets = sum(Object.values(summary)) || 0
  const approvedPercentage = totalTimesheets !== 0 ? `(${Math.floor(approvedTimesheets/totalTimesheets*100).toFixed(0)}%)` : ''

  const segments: any[] = keys.map((key) => {
    return {
      title: `${key == 'open' ? 'Not submitted' : capitalize(key)} timesheets`,
      value: summary[key] || 0,
      color: segmentColor[key] || 'gray',
      tooltip: '',
    }
  })


  const formatValue = (value: number): string => {
    return `${value} members`
  }

  return (
    <div>
      <div>{approvedTimesheets} / {totalTimesheets} approved {approvedPercentage}</div>
      <HorizontalBar 
        displayTitle={false}
        segments={segments}
        formatValue={formatValue}
      />
    </div>
  )
}