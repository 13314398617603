import { Button, Checkbox, Col, Divider, Form, Input, Row, Select } from "antd"
import ErrorMessage from "components/base/error-message"
import { COUNTRY_LIST_ALPHA2 } from "consts/countries"
import { useFormik } from "formik"
import { map } from "lodash"
import { useState } from "react"
import { TApiBillingInfo } from "services/api-client/billing-infos-api"
import { TApiResponse } from "services/api-client/types"
import styled from "styled-components"

export interface IFormObject extends TApiBillingInfo {}

export interface BillingInfoFormProps {
  initialValues?: IFormObject
  onSubmit(values: IFormObject): Promise<TApiResponse>
}

const BillingInfoForm = (props: BillingInfoFormProps) => {

  const initialValues = props.initialValues
  const formik = useFormik<IFormObject>({
    enableReinitialize: true,
    initialValues: initialValues || {
      legalEntity: 'private_person',
      payer: '',
      address: '',
      country: '',
      zip: '',
      city: '',
      vatTerms: false,
    },
    onSubmit: async (values, formik) => {
      const response = await props.onSubmit(values)

      if (!response.ok && response.errors) {
        formik.setErrors(response.errors)
      }

      if (response.ok) {
        formik.resetForm({ values })
      }
    }
  })

  return (
    <Form onSubmitCapture={formik.handleSubmit} layout="vertical">

      <Form.Item
        label="Legal Entity"
      >
        <Select
          value={formik.values.legalEntity}
          onChange={(value) => formik.setFieldValue('legalEntity', value)}
          options={[
            { label: 'Company', value: 'company' },
            { label: 'Private Person', value: 'private_person' }
          ]}
        />
        <ErrorMessage msg={formik.errors.legalEntity} />
      </Form.Item>

      <Form.Item
        label="Payer Name"
      >
        <Input
          value={formik.values.payer}
          onChange={formik.handleChange('payer')}
          autoFocus={true}
        />
        <ErrorMessage msg={formik.errors.payer} />
      </Form.Item>

      <Form.Item
        label="VAT ID"
        tooltip="VAT ID is only required for Europe Union countries."
      >
        <Input
          value={formik.values.vatId || ""}
          onChange={formik.handleChange('vatId')}
          disabled={formik.values.legalEntity == 'private_person'}
        />
        <ErrorMessage msg={formik.errors.vatId} />
      </Form.Item>

      <Form.Item
        label="Additional Invoice Info"
        tooltip="This sentence will be added to the invoice document."
      >
        <Input
          value={formik.values.additionalInfo || ""}
          onChange={formik.handleChange('additionalInfo')}
          disabled={formik.values.legalEntity == 'private_person'}
        />
        <ErrorMessage msg={formik.errors.additionalInfo} />
      </Form.Item>

      <Form.Item
        label="Address"
      >
        <Input
          value={formik.values.address}
          onChange={formik.handleChange('address')}
        />
        <ErrorMessage msg={formik.errors.address} />
      </Form.Item>

      <Row>
        <Col span="8">
          <Form.Item
            label="Post Code"
          >
            <Input
              value={formik.values.zip}
              onChange={formik.handleChange('zip')}
            />
            <ErrorMessage msg={formik.errors.zip} />
          </Form.Item>
        </Col>

        <Col span="15" offset={1}>
          <Form.Item
            label="City"
          >
            <Input
              value={formik.values.city}
              onChange={formik.handleChange('city')}
            />
            <ErrorMessage msg={formik.errors.city} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Country"
      >
        <Select
          value={formik.values.country}
          onChange={(value) => formik.setFieldValue('country', value)}
          optionFilterProp="label"
          showSearch={true}
          // key, value reconstructing as array
          options={map(COUNTRY_LIST_ALPHA2, (countryName, countryCode) => { return { 
            label: countryName,
            value: countryCode,
          }})}
        />
        <ErrorMessage msg={formik.errors.legalEntity} />
      </Form.Item>

      { formik.values.country == 'PL' && (
        <>
          <Checkbox
            style={{ marginBottom: '15px' }}
            checked={formik.values.vatTerms}
            onChange={(e) => formik.setFieldValue('vatTerms',e.target.checked)}
          >
            <span style={{ fontSize: '9px', lineHeight: '0.5em' }}>
              I hereby accept electronic issue and transmission of VAT invoices, in conjunction with the Ordinance of the Minister of Finance of 17 December 2010 on electronic transmission of invoices, rules of their storage and presentation to the tax or fiscal audit authorities (Journal of Laws No. 249, item 1661).
            </span>
          </Checkbox>

          <ErrorMessage msg={formik.errors.vatTerms} />
        </>
      )}

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          htmlType="submit"
          type={formik.dirty ? 'primary' : 'default'}
          disabled={!formik.dirty}
          loading={formik.isSubmitting}
          onClick={() => {
            formik.submitForm()
          }}
        >
          Update
        </Button>
      </div>

    </Form>
  )
}

const CheckboxWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`

export default BillingInfoForm