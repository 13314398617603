import { useTopNavigationLinks } from "hooks/layout"
import ReactJson from "react-json-view"
import { useHistory, useParams } from "react-router-dom"
import SettingsProfilePage from "./settings-profile-page"
import { Router } from "pages/routes"
import { useAppRouter } from "hooks/app-router"
import SettingsWorkspacePage from "./settings-workspace-page"
import SettingsBillingPage from "./settings-billing-page"
import { useGetPagePermissions, usePagePermissions } from "hooks/permissions.hooks"

interface ISettingsPageParams {
  sectionKey: 'profile' | 'workspace' | 'billing'
}

const SettingsLayoutPage = () => {
  const { sectionKey } = useParams<ISettingsPageParams>()
  const appRouter = useAppRouter()
  const { permissions } = useGetPagePermissions()

  const memberLinks = [
    {
      label: 'PROFILE',
      urlMatcher: 'profile',
      onClick: () => { 
        appRouter.goToPath('settings/profile')
      },
    },
  ]

  const adminLinks = [
    {
      label: 'WORKSPACE',
      urlMatcher: 'workspace',
      onClick: () => {
        appRouter.goToPath('settings/workspace/general')
      },
    },
    {
      label: 'PLAN & BILLING',
      urlMatcher: 'billing',
      onClick: () => {
        appRouter.goToPath('settings/billing/plan')
      },
    },
    {
      label: 'DATA IMPORT',
      urlMatcher: 'imports',
      onClick: () => {
        appRouter.goToPath('imports/new')
      },
    },
  ]

  const navigationLinks = !permissions.manageSettings ? memberLinks : memberLinks.concat(adminLinks)
  useTopNavigationLinks(navigationLinks)

  let PageComponent = SettingsProfilePage

  if (sectionKey == 'workspace') {
    PageComponent = SettingsWorkspacePage
  }

  if (sectionKey == 'billing') {
    PageComponent = SettingsBillingPage
  }

  return <PageComponent />
}

export default SettingsLayoutPage
