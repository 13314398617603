import { Notifier } from '@airbrake/browser'

const errbitHost = process.env.REACT_APP_ERRBIR_HOST
//const errbitProjectId = process.env.REACT_APP_ERRBIR_PROJECT_ID
const errbitProjectKey = process.env.REACT_APP_ERRBIT_PROJECT_KEY
const errbitEnvironment = process.env.REACT_APP_ERRBIT_ENVIRONMENT
const errbitReporter = process.env.REACT_APP_ERRBIT_REPORTER

let errorNotifier = {
  notify: (message: any) => {
    console.error("Error notifier: ", message)
  }
}

if (errbitProjectKey) {
  errorNotifier = new Notifier({
    projectId: 1,
    projectKey: errbitProjectKey || "",
    host: errbitHost || 'https://errbit.prod.timenotes.io',
    remoteConfig: false,
    environment: errbitEnvironment || 'production',
  })
}

export default errorNotifier