import { Button, Modal, ModalProps } from "antd"
import { map } from "lodash"
import { Moment } from "moment-timezone"
import { TApiAbsence } from "services/api-client/types"
import AbsencesModalTable from "./absences-modal-table"
import { TApiAbsenceRequest } from "services/api-client/holidays-absence-requests-api"
import AbsenceRequestModal from "./absence-request-modal"
import { useState } from "react"
import { usePagePermissions } from "hooks/permissions.hooks"
import { useDefaultDateFormat } from "hooks/settings"

interface AbsencesListModalProps extends ModalProps {
  absences: TApiAbsenceRequest[]
  startDate?: Moment
  endDate?: Moment
  fetchAbsenceRequests(): void
}

const AbsencesListModal = (props: AbsencesListModalProps) => {
  const [newAbsenceRequestModalVisible, setNewAbsenceRequestModalVisible] = useState(false)
  const [editAbsenceRequestModalVisible, setEditAbsenceRequestModalVisible] = useState(false)

  const { defaultDateFormat } = useDefaultDateFormat()
  const datePeriodFormatted = props.startDate?.isSame(props.endDate, 'day') ? `${props.startDate.format(defaultDateFormat)}` : `${props.startDate?.format('DD.MM.YYYY')} - ${props.endDate?.format(defaultDateFormat)}`
  const { permissions } = usePagePermissions()

  return (
    <>
      <Modal
        {...props}
        width={"1200px"}
        title={`Absences list: ${datePeriodFormatted}`}
        footer={false}
        visible={props.visible && !newAbsenceRequestModalVisible && !editAbsenceRequestModalVisible}
      >
        <AbsencesModalTable 
          myAbsencesOnly={!permissions.manageAbsenceRequests}
          absences={props.absences}
          refresh={props.fetchAbsenceRequests}
          onEditAbsenceRequestVisibleChange={(visible) => {
            setEditAbsenceRequestModalVisible(visible)
          }}
        />

        <div style={{ 
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '20px',
        }}>
          <Button 
            type="primary"
            onClick={() => {
              setNewAbsenceRequestModalVisible(true)
            }}
          >
            Add absence
          </Button>
        </div>


      </Modal>

      <AbsenceRequestModal
        visible={newAbsenceRequestModalVisible}
        initialValues={
          {
            fromDate: props.startDate?.format('YYYY-MM-DD'),
            toDate: props.endDate?.format('YYYY-MM-DD'),
          }
        }
        onCancel={() => { 
          setNewAbsenceRequestModalVisible(false)
        }}
        onSubmit={() => {
          props.fetchAbsenceRequests()
          setNewAbsenceRequestModalVisible(false)
        }}
      />
    </>
  )
}

export default AbsencesListModal