import { Card, Skeleton } from "antd"
import PageHeader from "components/layout/page-header"
import PageTabsHeader from "components/layout/page-tabs-header"
import { Action } from "history"
import { useAppRouter } from "hooks/app-router"
import { useParams } from "react-router-dom"
import BillingInfoSection from "./billing/billing-info-section"
import BillingHistorySection from "./billing/billing-history-section"
import BillingNotificationsSection from "./billing/billing-notifications-section"
import BillingPaymentMethodSection from "./billing/billing-payment-method-section"
import BillingPlanSection from "./billing/billing-plan-sections"
import { usePagePermissionsCheck } from "hooks/permissions.hooks"

interface ISettingsBillingPageParams {
  activeTab: 'plan' | 'billing-info' | 'payment-method' | 'billing-notifications' | 'payment-history'
}

const SettingsBillingPage = () => {
  usePagePermissionsCheck('manageSettings')

  const { activeTab } = useParams<ISettingsBillingPageParams>()
  const appRouter = useAppRouter()

  const AcitveTabComponentMap = {
    'plan': () => <BillingPlanSection />,
    'billing-info': () => <BillingInfoSection />,
    'payment-method': () => <BillingPaymentMethodSection />,
    'billing-notifications': <BillingNotificationsSection />,
    'payment-history': () => <BillingHistorySection />,
  }
  const ActiveTabComponent = AcitveTabComponentMap[activeTab]

  const renderActiveTabComponent = () => {
    return typeof ActiveTabComponent === 'function' ? ActiveTabComponent() : ActiveTabComponent
  }

  return (
    <div>
      <PageTabsHeader
        title="Subscription Settings"
        tabs={[
          {
            label: 'Plan',
            urlMatcher: "settings/billing/plan",
            onClick: () => { 
              return appRouter.goToPath('settings/billing/plan') 
            },
          },
          {
            label: 'Billing Info',
            urlMatcher: "settings/billing/billing-info",
            onClick: () => { 
              return appRouter.goToPath('settings/billing/billing-info') 
            },
          },
          {
            label: 'Payment Method',
            urlMatcher: "settings/billing/payment-method",
            onClick: () => { 
              return appRouter.goToPath('settings/billing/payment-method') 
            },
          },
          {
            label: 'Billing Notifications',
            urlMatcher: "settings/billing/billing-notifications",
            onClick: () => { 
              return appRouter.goToPath('settings/billing/billing-notifications') 
            },
          },
          {
            label: 'Payment History',
            urlMatcher: "settings/billing/payment-history",
            onClick: () => { 
              return appRouter.goToPath('settings/billing/payment-history') 
            },
          },
       ]}
      />
      <div
        className="page-content"
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '100%'}}>
          {renderActiveTabComponent()}
        </div>
      </div>
    </div>
  )
}

export default SettingsBillingPage