import { UseQueryOptions, useQuery } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiParams, TApiUsersAccount } from "../../api-client/types"
import { TApiGenericError } from "../../timenotes-query/timenotes-query.types"

interface Params extends TApiParams {}

interface Result {
  usersAccount: TApiUsersAccount
}

export const useCurrentUserQuery = (options?: UseQueryOptions<any, any>) => {
  const apiClient = useApiClient()

  const query = useQuery<Result, TApiGenericError<any>>(
    ['current-user'] as const,
    async () => {
      const response = await apiClient.getUsersAccountsCurrent()

      if (!response.ok) {
        throw { 
          ...response.errors,
          status: response.status,
        } || {}
      }

      return {
        usersAccount: response.usersAccount
      }
    },
    {
      ...options,
      staleTime: options?.staleTime || Infinity,

      // Allow 2 retries for errors if its not 401 Auth error
      // in case of 401 -> dont retry even once!
      retry: (count, error) => {
        if (error.status == 401 ) {
          return false
        } else {
          if (count >= 2) {
            return false
          }
        }

        return true
      }
    }
  )

  return {
    currentUserQuery: query
  }
}