import { Button, Card, Col, Row, Select } from "antd"
import TeamFilter from "components/timenotes/filters/team-filter"
import UsersAccountSelect from "components/forms/users-account-select"
import { useFormik } from "formik"
import { isEmpty } from "lodash"
import { TApiHolidaysAbsenceRequestsFilters } from "services/api-client/holidays-absence-requests-api"
import AbsenceTypesFilter from "./absence-types-filter"
import AbsenceStatusesFilter from "./absence-statuses-filter"
import ReactJson from "react-json-view"

interface AbsencesFiltersProps {
  myAbsencesOnly?: boolean
  filters: TApiHolidaysAbsenceRequestsFilters
  onChange(filters: TApiHolidaysAbsenceRequestsFilters): void
}

const setIfNotEmpty = (values: string[]): string[] | undefined => {
  return isEmpty(values) ? undefined : values
}

const AbsencesFilters = (props: AbsencesFiltersProps) => {
  const formik = useFormik<TApiHolidaysAbsenceRequestsFilters>({
    enableReinitialize: true, // ensure filters changed in parent are also part of the change
    initialValues: props.filters,
    onSubmit: (values: TApiHolidaysAbsenceRequestsFilters) => {
      props.onChange(values)
    }
  })

  const handleClearFilters = () => {
    formik.setValues({
      period: props.filters.period,
    })
    formik.submitForm()
  }

  return (
    <form onSubmit={formik.handleSubmit} >
      <Row gutter={16}>
        {!props.myAbsencesOnly && (
          <Col span="3">
            <TeamFilter
              values={{
                memberHashIds: formik.values.usersAccountHashIds || [],
                groupHashIds: formik.values.membersGroupHashIds || [],
              }}
              onChange={(values) => {
                formik.setFieldValue('usersAccountHashIds', setIfNotEmpty(values.memberHashIds))
                formik.setFieldValue('membersGroupHashIds', setIfNotEmpty(values.groupHashIds))
                formik.submitForm()
              }}
              width={"100%"}
            />
          </Col>
        )}

        <Col span="3">
          <AbsenceTypesFilter 
            value={formik.values.absenceTypeIds || []}
            onChange={(absenceTypeIds) => { 
              formik.setFieldValue('absenceTypeIds', setIfNotEmpty(absenceTypeIds)) 
              formik.submitForm()
            }}
          />
        </Col>
        <Col span="3">
          <AbsenceStatusesFilter 
            value={formik.values.statuses || []}
            onChange={(statuses) => { 
              formik.setFieldValue('statuses', setIfNotEmpty(statuses)) 
              formik.submitForm()
            }}
          />
        </Col>

        <Col span={1}>
          { (props.filters.absenceTypeIds || props.filters.membersGroupHashIds || props.filters.usersAccountHashIds || props.filters.statuses) && (
            <Button
              type="text" 
              style={{ color: 'gray' }} 
              onClick={handleClearFilters}
            >
              Χ Clear filters
            </Button>
          )}
        </Col>
      </Row>
    </form>
  )
}

export default AbsencesFilters
