import { Input } from 'antd'
import { InPlaceInputWrapper } from 'components/base'
import moment, { Moment } from 'moment-timezone'
import React, { useEffect, useState } from 'react'

export interface TimeInputProps {
  style?: React.CSSProperties
  width?: string
  value: string
  autoFocus?: boolean
  onChange(value: string): void 
}

export function pad(num: number, size: number): string {
  let numb = num.toString();
  while (numb.length < size) numb = "0" + numb;

  return numb;
}

export const validateTimeFormat = (value: string): boolean => {
    if (value.match(/:/)) {
      let split = value.split(':')

      const hours = parseInt(split[0])
      const minutes = parseInt(split[1])

      if (isNaN(hours) || isNaN(minutes)) {
        return false
      }

      return true
    } else {
      return false
    }
}

export interface MomentTimeInputProps {
  style?: React.CSSProperties
  width?: string
  value: Moment
  onChange(value: Moment): void 
}

export const MomentTimeInput = (props: MomentTimeInputProps) => {

  return <TimeInput 
    style={props.style}
    width={props.width}
    // @ts-ignore
    value={props.value.format('HH:mm')}
    onChange={(value) => {
      const newMoment = moment(props.value)

      // @ts-ignore
      newMoment.set('h', value.split(":")[0])
      // @ts-ignore
      newMoment.set('m', value.split(":")[1])

      props.onChange(newMoment)
    }}
  />
}

const parseNewValue = (newValue: string): string | false => {
  let hours: number
  let minutes: number

  if (newValue.match(/:/)) {
    let split = newValue.split(':')

    hours = parseInt(split[0])
    minutes = parseInt(split[1])

    if (!isNaN(hours) && !isNaN(minutes) && hours >= 0 && hours <= 24 && minutes >= 0 && minutes <= 60) {
      return `${pad(hours,2)}:${pad(minutes,2)}`
    } else {
      return false
    }
  } else {
    return false
  }

  return false
}

const TimeInput = ({
  style,
  width,
  value, // always returns 00:00 time format
  autoFocus,
  onChange,
}: TimeInputProps ) => {

  const [text, setText] = useState<string>(value)
  const hasError: boolean = !validateTimeFormat(text)

  useEffect(() => {
    setText(value)
  }, [value])

  useEffect(() => {
    if (!hasError) {
    }
  }, [text])

  const handleOnChange = (newValue: string) => {
    const newParsedValue = parseNewValue(newValue)

    if (newParsedValue) {
      onChange(newParsedValue)
    } else {
      setText(parseNewValue(value) as string)
    }
  }

  return (
    <Input 
      style={{ 
        ...style,
        ...(hasError ? { border: '1px solid red'} : {})
      }}
      autoFocus={autoFocus}
      value={text}
      onBlur={() => { handleOnChange(text)}}
      onChange={(e) => { setText(e.target.value)}}
      onKeyDown={(e) => {
        if (e.key == "Enter") {
          handleOnChange(text)
        }
      }}
    />
  )
}

export const InPlaceTimeInput = (props: TimeInputProps) => {
  return (
    <InPlaceInputWrapper>
      <TimeInput {...props} />
    </InPlaceInputWrapper>
  )
}

export default TimeInput





