import {Card, Checkbox, message} from "antd"
import { useHistory, useLocation, useParams } from "react-router-dom"
import {TApiAccountImport} from "services/timenotes/imports/imports.types"
import ImportColumnsConfigurationTable from "./components/import-columns-configuration-table"
import ImportHeader from "./components/import-header"

const ConfigureImportColumnsPage = () => {
  const location = useLocation()
  const history = useHistory()

  const accountImport = location.state?.accountImport as TApiAccountImport
  const { importId } = useParams<{
    importId: string
  }>()

  if (!accountImport) {
    message.warning("Account import expired, please try again!")
    history.push('/imports/new')

    return null
  }

  return (
    <>
      <ImportHeader step={1} />

      <div className="page-content">
        <Card>
          <p><b>
            Please configure the columns mapping between the CSV file and your Timenotes database to ensure data consistency.
          </b></p>

          <p>
            Please select below how we should treat each column of your imported CSV file dataset. We display the first 2 rows for the preview so you can easily match the CSV file with the column in Timenotes. After matching is done, click Continue button to start the process. Please have in mind we need at least User, Duration, Project, Task and Date columns configured in order to proceed with the import.
          </p>

          <ImportColumnsConfigurationTable 
            importId={importId}
            accountImport={accountImport}
          />
          
        </Card>
      </div>
    </>
  )
}

export default ConfigureImportColumnsPage
