import { UseQueryOptions, useQuery } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { IGetAllTasksParams } from "../../api-client/tasks-api"
import { TApiProjectsUser } from "../../api-client/projects-users-api"
import { TApiGenericError, TApiGenericResponse } from "../../timenotes-query/timenotes-query.types"

interface IParams extends IGetAllTasksParams {
  projectId?: string
}

interface IResult {
  projectsUsers: TApiProjectsUser[]
}

const PER_PAGE = 1000

interface IOptions extends UseQueryOptions<IResult, TApiGenericError<null>> {}

export const useProjectsUsersQuery = (params: IParams, options?: IOptions) => {
  const apiClient = useApiClient()

  const requestParams = {
    projectId: params.projectId,
  }

  const query = useQuery<IResult, TApiGenericError<null>>(
    ['projects-users', requestParams],
    async () => {
      const response = await apiClient.getProjectsUsers(params.projectId, {
        perPage: params.perPage || PER_PAGE
      })

      if (!response.ok) { throw response.errors }

      return {
        projectsUsers: response.projectsUsers,
      }
    },
    {
      enabled: !!params.projectId,
      ...options
    }
  )

  return { 
    query,
  }
}