import { TImportProjectMapping, TApiConfirmImportColumnsResponse} from "./imports.types";

export const importProjectsMappingsFromProjectsMatchings = (projectsMatchings: TApiConfirmImportColumnsResponse['projectsMatchings']) => {
  const importProjectsMappings = projectsMatchings.reduce((acc: any, cur: any) => {
    const { projects, givenClientName, projectsOptions } = cur

    projects.forEach((project: any) => {
      const key = `${givenClientName}##${project.name}`

      acc.push({
        projectName: project.name,
        clientName: givenClientName,
        mapping: project.mapping,
        projectsOptions,
        key,
      })
    })
    return acc
  }, [])

  return importProjectsMappings as TImportProjectMapping[]
}

export const confirmProjectsPayloadFromProjectsMappings = (projectsMappings: TImportProjectMapping[]) => {
  return projectsMappings.reduce((payload, currentMapping) => {

    // @ts-ignore
    const currentMappingClientPayload = payload[currentMapping.clientName] || {}

    return { 
      ...payload,
      [currentMapping.clientName]: {
        ...currentMappingClientPayload,
        [currentMapping.projectName]: currentMapping.mapping,
      }
    }
  }, {})
}
