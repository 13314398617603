import React, { PropsWithChildren } from "react"
import { QueryClient, QueryClientProvider } from "react-query"

export const TimenotesQueryProvider = (props: PropsWithChildren) => {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
    </QueryClientProvider>
  )
}