import { TApiResponse, TApiParams, TApiPaymentMethod } from "./types"
import BaseApi, { getQueryString } from './base'

export interface IGetTokenResponse extends TApiResponse {
  token: string
}

export interface ICreatePaymentMethodResponse extends TApiResponse {
  paymentMethod: TApiPaymentMethod
}

class PaymentMethodsApi extends BaseApi {
  async getPaymentMethodToken (): Promise<IGetTokenResponse> {
    let response = await fetch(`${this.rootUrl}/payment_method/generate_token`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async createPaymentMethod (params: object): Promise<ICreatePaymentMethodResponse> {
    let response = await fetch(`${this.rootUrl}/payment_method`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        payment_method: params, // This is everything that Braintree returns
      }),
    })

    return await this.getResponseJson(response)
  }

  async getPaymentMethod(): Promise<ICreatePaymentMethodResponse> {
    let response = await fetch(`${this.rootUrl}/payment_method`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async deletePaymentMethod() {
    let response = await fetch(`${this.rootUrl}/payment_method`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
}

export default PaymentMethodsApi
