import { UseQueryOptions, useQuery } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiClient, TApiParams } from "../../api-client/types"
import { TApiGenericError } from "../../timenotes-query/timenotes-query.types"

interface Params extends TApiParams {
  includeNoClientOption?: boolean
}

interface Result {
  clients: TApiClient[]
  clientsOptions: {
    value: TApiClient
  }[]
}

export const useClientsQuery = (params?: Params, options?: UseQueryOptions<any, any>) => {
  const apiClient = useApiClient()

  const query = useQuery<Result, TApiGenericError<any>>(
    ['clients', (params || {})] as const,
    async () => {
      const response = await apiClient.getClients(params)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        clients: response.clients || [],
        clientsOptions: getClientsOptions(response.clients, !!params?.includeNoClientOption),
      }
    },
    {
      ...options
    }
  )

  return query
}

export const noClientOption = {
  value: {
    name: 'No client',
    id: 'no_client',
    hashId: 'no_client',
  }
}

const getClientsOptions = (clients: TApiClient[], includeNoClientOption: boolean) => {
  const clientsOptions = clients.map((client) => ({
    value: client
  }))

  if (includeNoClientOption) {
    return [...clientsOptions, noClientOption] as Result['clientsOptions']
  } else {
    return clientsOptions as Result['clientsOptions']
  }
}