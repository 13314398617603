import { Router } from "pages/routes"
import { useHistory, useLocation } from "react-router-dom"
import queryString from 'query-string'

export const useAppRouter = () => {
  const history = useHistory()
  const router = new Router(history)

  return router
}

export const useQueryParams = <T>() => {
  const { search } = useLocation()

  return queryString.parse(search) as any as Partial<T>
}
