import { Component } from 'react'
import { SettingOutlined } from '@ant-design/icons'
import { Drawer, Menu } from 'antd'
import { DIR_RTL } from 'consts/theme'
import AccountPanel from 'components/shared/account-panel'

export class NavPanel extends Component {
	state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
	};
	
	render() {
		return (
      <>
        <Menu mode="horizontal">
          <Menu.Item id="timenotes-account-panel" key="account" onClick={this.showDrawer} style={{ height: '100%' }}>
            <AccountPanel 
              visible={this.state.visible} 
              onClose={this.onClose}
            />
          </Menu.Item>
          { false && (
            <Menu.Item key="panel" onClick={this.showDrawer}>
              <a href><SettingOutlined className="nav-icon mr-0" /></a>
            </Menu.Item>
          )}
        </Menu>

        {false && (
          <Drawer
            title="Theme Config"
            placement={this.props.direction === DIR_RTL ? 'left' : 'right'}
            width={350}
            onClose={this.onClose}
            visible={this.state.visible}
          >
          </Drawer>
        )}
      </>
    );
	}
}

export default NavPanel