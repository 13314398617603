import SideNav from 'components/layout-components/SideNav'
import TopNav from 'components/layout-components/TopNav'
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav'
import PageHeader from 'components/layout-components/PageHeader'
import AppViews from 'views/app-views'
import {
  Layout,
  Grid,
} from "antd"

import navigationConfig from "consts/navigation-config"
import {
  SIDE_NAV_WIDTH, 
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR
} from 'consts/theme'
import utils from 'utils'
import { useThemeConfig } from 'services/layout/layout.hooks'

const { Content } = Layout
const { useBreakpoint } = Grid

export const AppLayout = ({ location }) => {
  const { themeConfig, toggleMobileNav } = useThemeConfig()
  const { navCollapsed, navType, direction } = themeConfig

  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)

  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg')

  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP

  const getLayoutGutter = () => {
    if(isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const getLayoutDirectionGutter = () => {
    if(direction === DIR_LTR) {
      return {paddingLeft: getLayoutGutter()}
    }  
    if(direction === DIR_RTL) {
      return {paddingRight: getLayoutGutter()}
    }
    return {paddingLeft: getLayoutGutter()}
  }

  return (
    <Layout>
      <Layout className="app-container">
          <>
          <HeaderNav isMobile={isMobile} {...themeConfig } toggleMobileNav={toggleMobileNav} /> 
          {(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo} { ...themeConfig } toggleMobileNav={toggleMobileNav} /> : null}
        </>

        {(isNavSide && !isMobile) ? <SideNav routeInfo={currentRouteInfo} { ...themeConfig } toggleMobileNav={toggleMobileNav} /> : null }
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content`} style={{  }}>

            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
            <Content>
              <AppViews />
            </Content>
          </div>
        </Layout>
      </Layout>
      {isMobile && <MobileNav {...themeConfig } toggleMobileNav={toggleMobileNav}/>}
    </Layout>
  )
}


export default AppLayout
