import React, { useEffect, useState } from 'react'
import _map from 'lodash/map'
import styled from 'styled-components'
import { Select } from 'antd'
import { delayFunction } from 'utils/functions'
import { find } from 'lodash'
import NewTaskModal from 'views/app-views/timer/Show/NewTaskModal'
import { useApiClient } from '@timenotes/shared/src/services/api-client/api-client.hooks'
import { TApiProject, TApiTask } from '@timenotes/shared/src/services/api-client/types'
import { ICreateTaskParams, IGetAllTasksParams } from '@timenotes/shared/src/services/api-client/tasks-api'

const CREATE_TASK_KEY = '#CREATE_TASK'

export interface TaskSelectProps {
  style?: React.CSSProperties
  width?: string
  value?: string
  project?: Pick<TApiProject, 'origin' | 'taskCreationPolicy'>
  projectId?: string
  disabled?: boolean
  allowClear?: boolean
  autoFocus?: boolean
  initialTask?: TApiTask

  onCreate?(task: ICreateTaskParams): void
  onChange?(value: string | undefined, task: TApiTask): void 
}

const Wrapper = styled.div`
  width: 100%;

  .ant-popover {
    width: 250px;
  }
`

const TaskSelect = ({
  disabled,
  projectId,
  value,
  width,
  onChange,
  onCreate,
  allowClear,
  autoFocus,
  initialTask,
  project,
}: TaskSelectProps): JSX.Element => {
  const apiClient = useApiClient()

  const [newTaskModalVisible, setNewTaskModalVisible] = useState(false)
  const [searchValue, setSearchValue] = useState<undefined | string>(undefined)

  const [tasks, setTasks] = useState<TApiTask[]>(initialTask ? [initialTask] : [])
  const isDisabled = disabled || !projectId

  useEffect(() => {
    if (projectId) {

      delayFunction('fetchTasksForSelect', () => {
        const params: IGetAllTasksParams = {
          filters: {
            name: searchValue,
          }
        }

        apiClient.getTasks(projectId, params).then((response) => {
          if (response.ok) {
            setTasks(response.tasks)
          }
        })
      }, 300)
    }
  }, [projectId, searchValue])

  const groupsOptions = _map(tasks, (task) => {
    return {
      value: task.id,
      label: task.name,
    }
  })

  if (onCreate && searchValue) {
    groupsOptions.push({
      value: CREATE_TASK_KEY,
      label: `Create new task - "${searchValue}"`
    })
  }

  const handleOnChange = !onChange ? () => {} : (taskId: string) => {
    if (onCreate && taskId == CREATE_TASK_KEY) {

      const groupOption = groupsOptions.find((option) => option.value == CREATE_TASK_KEY)

      if (groupOption) {
        groupOption.label = `${searchValue}`
      }

      if (project?.origin != 'timenotes' && project?.taskCreationPolicy != 'internal') {
        setNewTaskModalVisible(true)
      } else {
        onCreate({ name: `${searchValue}` } )
      }
    } else {
      const task: TApiTask = find(tasks, (task) => { return task.id == taskId }) as TApiTask
      onChange(taskId, task)
    }
  }

  return (
    <Wrapper className="task-select-wrapper">
      <Select
        style={{ width: width || '100%' }}
        allowClear={allowClear == false ? false : true }
        autoFocus={autoFocus}
        showSearch={true}
        onSearch={(value) => {
          setSearchValue(value)
        }}
        optionFilterProp='label'
        placeholder='Task'
        options={groupsOptions}
        onChange={handleOnChange}
        disabled={isDisabled}
        value={value}
      />

      { projectId && onCreate && (
        <NewTaskModal 
          visible={newTaskModalVisible}
          projectId={projectId}
          createTask={async (taskValues: ICreateTaskParams) => {
            return onCreate(taskValues)
          }}
          project={project}
          onClose={() => { 
            setNewTaskModalVisible(false)
            setSearchValue("")
          }}
          onSelect={() => {}}
        />
      )}
    </Wrapper>
  )
}

export default TaskSelect
