import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"
import { TApiProject } from "@timenotes/shared/src/services/api-client/types"
import { Checkbox, Divider, Input, message, Modal } from "antd"
import ErrorMessage from "components/base/error-message"
import { useFormik } from "formik"
import useApiClient from "hooks/useApiClient"
import { InputFieldWrapper } from "pages/projects/shared/components/project-form"
import { useState } from "react"

interface CopyProjectModalProps {
  visible: boolean,
  setIsVisible(visible: boolean): void
  project: TApiProject
  onSuccess: () => void | undefined
}

export const useCopyProjectModal = (props: Omit<CopyProjectModalProps, 'visible' | 'setIsVisible'>) => {
  const [isVisible, setIsVisible] = useState(false)

  return {
    copyProjectModal: <CopyProjectModal {...props} visible={isVisible} setIsVisible={setIsVisible} />,
    copyProjectModalVisible: isVisible,
    setCopyProjectModalVisible: setIsVisible,
  }
}

export default function CopyProjectModal(props: CopyProjectModalProps) {
  const apiClient = useApiClient()

  const {
    project,
    onSuccess,
    visible,
    setIsVisible,
  } = props

  const formik = useFormik({
    initialValues: {
      name: `${project.name} copy` || "",
      tasks: true,
      groups: true,
      users: true,
    },
    onSubmit: async (values) => {
      try {
        const response = await apiClient.post(`/projects/${project.id}/copy`, {
          copy: {
            name: values.name,
            elements: Object.keys(values).filter((key) => values[key as 'tasks' | 'groups' | 'users'] === true)
          },
        })

        if (response.ok) {
          onSuccess()
        } else {
          formik.setErrors(response.errors)

          if (response.errors.base) {
            message.error(getApiErrorMessage(response, 'base'))
          }
        }

      }
      catch (e) {
        console.error('Something went wrong!')
        console.error(e)
        message.error('Something went wrong!')
      }
      finally {
        formik.setSubmitting(false)
      }
    }
  })

  const isPrivateProject = !project.visibility

  return (
    <Modal
      visible={visible}
      onCancel={() => setIsVisible(false)}
      onOk={formik.submitForm}
      okText='Copy'
      okButtonProps={{
        loading: formik.isSubmitting
      }}
    >
      <>
        <h3>Copy project "{project.name}"</h3>
        <Divider />

        <InputFieldWrapper
          label="Name"
        >
          <Input
            autoFocus={true}
            onPressEnter={formik.submitForm}
            value={formik.values.name}
            onChange={formik.handleChange('name')}
          />
          <ErrorMessage msg={formik.errors.name} />
        </InputFieldWrapper>

        <p>Copying the project will duplicate all project settings by default. Tasks and team members will be copied based on the selected configuration below.
        Project time entries will not be duplicated.</p>

        <InputFieldWrapper
          label="Copy projects tasks:"
        >
          <Checkbox
            style={{ marginLeft: '10px'}}
            checked={formik.values.tasks}
            onChange={(e) => formik.setFieldValue('tasks', e.target.checked)}
          />
          <ErrorMessage msg={formik.errors.tasks} />
        </InputFieldWrapper>

        <br />

        <InputFieldWrapper
          label="Copy projects team:"
        >
          <Checkbox
            style={{ marginLeft: '10px'}}
            disabled={!isPrivateProject}
            checked={formik.values.users}
            onChange={(e) => {
              formik.setFieldValue('groups', e.target.checked)
              formik.setFieldValue('users', e.target.checked)
            }}
          />
          { !isPrivateProject && (
            <i style={{marginLeft: '10px'}}>Public project is visible for all the team members.</i>
          )}
          <ErrorMessage msg={formik.errors.users} />
          <ErrorMessage msg={formik.errors.groups} />
        </InputFieldWrapper>

      </>
    </Modal>
  )
}

