import { FC } from "react"
import styled from "styled-components"
import { Modal, ModalFuncProps } from 'antd'
import { ArchiveSvg, DeactivateSVG, DeleteSvg, EditSVG, MoreSvg, SecureSVG, SettingSvg } from "assets/svg/icon"
import {  PinningIcon } from "components/base/icons"
import { MoreMenuAction } from "./more-menu-action"
import { UserAddOutlined, MailOutlined } from '@ant-design/icons'

import _map from 'lodash/map'

const { confirm } = Modal

export const ICONS_MAP = {
  'activate': UserAddOutlined,
  'deactivate': DeactivateSVG,
  'secure': SecureSVG,
  'edit': EditSVG,
  'pinning': PinningIcon,
  'setting': SettingSvg,
  'archive': ArchiveSvg,
  'delete': DeleteSvg,
  'mail': MailOutlined,
  'more': MoreSvg,
}

export interface IIconActionItem {
  confirm?: ModalFuncProps
  action(): void
  active?: boolean
  icon: 'pinning' | 'setting' | 'archive' | 'delete' | 'more' | 'edit' | 'secure' | 'deactivate' | 'activate' | 'mail' 
}

export interface IActionItem extends IIconActionItem {
  title: string
}

export interface IActionsPanelProps {
  icons?: IIconActionItem[]
  actions?: IActionItem[]
}

const ActionPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const IconWrapper = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;

  &.indents {
    margin: 0 12px;
  }

  &:hover > svg > path,
  &:hover > svg > circle {
    fill: #07c5b7;
  }

  &.active > span > svg > path:first-child,
  &.active > span > svg > path:last-child {
    fill: #b9b9c4;
  }

  &.active-hover:hover > span > svg > path:first-child,
  &.active-hover:hover > span > svg > path:last-child {
    fill: #b9b9c4;
  }
`

const ActionsPanel: FC<IActionsPanelProps> = ({ icons, actions }) => {

  const iconsComponents: JSX.Element[] = _map(icons, (icon, index) => {
    const IconComponent = ICONS_MAP[icon.icon]
    return (
      <IconWrapper
        key={index}
        className={ icon.active ? "active" : ""}
        onClick={() => { 
          if (icon.confirm) {
            confirm({ 
              ...icon.confirm,
              onOk: () => { icon.action() }
            })
          } else {
            icon.action() 
          }
        }}
      >
        <IconComponent />
      </IconWrapper>
    )
  })

  return (
    <>
      <ActionPanelWrapper>
        {iconsComponents}
      </ActionPanelWrapper>
      <MoreMenuAction actions={actions} />
    </>
  )
}

export default ActionsPanel