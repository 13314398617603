import { useEffect, useState } from 'react'

import _filter from 'lodash/filter'
import { useTopNavigationLinks } from 'hooks/layout'
import { useHistory, useParams } from 'react-router-dom'
import { useProjectQuery } from '@timenotes/shared/src/services/projects/projects.hooks'
import LoadingSkeleton from './components/loading-skeleton'
import ProjectDetailsHeader from '../shared/components/project-details-header'
import ProjectForm from '../shared/components/project-form'
import { message } from 'antd'
import { useFormik } from 'formik'
import useUpdateProjectMutation from '@timenotes/shared/src/services/projects/queries/use-update-project-mutation'
import { TApiProject } from '@timenotes/shared/src/services/api-client/types'

const ProjectsSettingsPage = () => {
  const history = useHistory()

  useTopNavigationLinks([
    {
      label: "← BACK",
      onClick: () => {
        history.push('/projects')
      }
    }
  ])
 
  const [pageLoaded, setPageLoaded] = useState(false)

  const params = useParams<{
    projectId: string
  }>()
  const projectId = params.projectId as string

  const projectQuery = useProjectQuery({ projectId: projectId })
  const updateProjectMutation = useUpdateProjectMutation()

  useEffect(() => {
    if (projectQuery.isSuccess) {
      setPageLoaded(true)
    }
  }, [projectQuery.status])

  const project = projectQuery.data?.project

  const formik = useFormik<Partial<TApiProject>>({
    initialValues: project || {},
    enableReinitialize: true,
    onSubmit: (values) => {
      const params: Partial<TApiProject> & { projectId: string, clientName?: string } = {
        projectId: projectId,
        color: values.color,
        name: values.name,
        clientName: values.client?.name || '',
        visibility: values.visibility,
        taskCreator: values.taskCreator,
        billableEnabled: !!values.billableEnabled,
        billableRate: values.billableRate,
        taskCreationPolicy: values.taskCreationPolicy,
        defaultIsBillableTimeLogs: values.defaultIsBillableTimeLogs,
        budgetType: values.budgetType == 'money' ? 'money' : 'time',
        budgetEnabled: values.budgetEnabled,
        budgetMoney: values.budgetMoney,
        budgetTime: values.budgetTime,
        budgetAlertThreshold: values.budgetAlertThreshold,
        disallowOverbudget: values.disallowOverbudget,
        disallowUserOverbudget: values.disallowUserOverbudget,
      }

      updateProjectMutation.mutate(params)
    }
  })

  useEffect(() => {
    if (updateProjectMutation.isSuccess) {
      message.success('Project updated successfully!')
    }

    if (updateProjectMutation.isError) {
      formik.setErrors(updateProjectMutation.error)

      if (updateProjectMutation.error.base) {
        message.error(updateProjectMutation.error.base)
      }
    }
  }, [updateProjectMutation.status])

  return (
    <>
      <ProjectDetailsHeader
        projectId={projectId}
        activeSection="settings"
      />
      <div className="page-content">
        {( pageLoaded && project )? (
          <div style={{ marginTop: '20px' }}>
            <ProjectForm
              formik={formik}
            />
          </div>
        ) : (
          <LoadingSkeleton />
        )}
      </div>
    </>
  )
}

export default ProjectsSettingsPage