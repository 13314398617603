import { THEME_CONFIG } from "consts/theme-config"
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'

type ThemeConfigType = typeof THEME_CONFIG

type TThemeConfigContext = {
  themeConfig: ThemeConfigType,
  updateThemeConfig(newConfig: Partial<ThemeConfigType>): void
  toggleCollapsedNav(navState: boolean): void
  toggleMobileNav(navState: boolean): void
}

const ThemeConfigContext = createContext<TThemeConfigContext | undefined>(undefined)

const ThemeConfigProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [themeConfig, setThemeConfig] = useState<ThemeConfigType>({ ...THEME_CONFIG })

  const updateThemeConfig = (newConfig: Partial<ThemeConfigType>) => {
    setThemeConfig(prevConfig => ({
      ...prevConfig,
      ...newConfig
    }))
  }

  const toggleCollapsedNav = (nav: boolean) => {
    updateThemeConfig({ navCollapsed: nav})
  }

  const toggleMobileNav = (nav: boolean) => {
    updateThemeConfig({ mobileNav: nav})
  }

  return (
    <ThemeConfigContext.Provider value={{
      themeConfig: themeConfig, 
      updateThemeConfig,
      toggleCollapsedNav,
      toggleMobileNav,
    }}>
      {children}
    </ThemeConfigContext.Provider>
  )
}

const useThemeConfig = () => {
  const context = useContext(ThemeConfigContext)
  if (!context) {
    throw new Error('useThemeConfig must be used within a ThemeConfigProvider')
  }
  return {
    themeConfig: context.themeConfig,
    updateThemeConfig: context.updateThemeConfig,
    toggleCollapsedNav: context.toggleCollapsedNav,
    toggleMobileNav: context.toggleMobileNav,
  }
}

export { ThemeConfigProvider, useThemeConfig }
