import { Input, Table } from "antd"
import { FormikProps } from "formik"
import _pick from 'lodash/pick'
import _reduce from 'lodash/reduce'
import _find from 'lodash/find'
import _remove from 'lodash/remove'
import { produce } from 'immer'
import ErrorMessage from "components/base/error-message"
import { IUpdateUsersAccountParams, TApiAbsenceAllowance, TApiTeamMember } from "@timenotes/shared/src/services/api-client/users-accounts-api"

const TeamMemberAbsencesForm = ({ teamMember, onSubmit, formik }: {
  teamMember: TApiTeamMember
  onSubmit(updateParams: IUpdateUsersAccountParams): void,
  formik: FormikProps<Partial<IUpdateUsersAccountParams>>
}) => {
  const absenceAllowances = teamMember.absenceAllowances

  return (
    <>
      <Table<TApiAbsenceAllowance>
        dataSource={absenceAllowances}
        pagination={false}
        columns={[
          {
            title: 'Absence Type',
            render: (value, record) => (
              <span>{record.absenceType.name}</span>
            )
          },
          {
            title: 'Used in current period',
            render: (value, record) => (
              <span>{record.used || 0}</span>
            )
          },
          {
            title: 'Limit',
            render: (value, record) => {
              const absenceAllowance = _find(formik.values.absenceAllowances, (absenceAllowance) => absenceAllowance.absenceType?.id == record.absenceType.id)

              return (
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Input
                    type={'number'}
                    style={{ width: '65px' }}
                    placeholder={`(${absenceAllowance?.absenceType?.limit || '-'})`}
                    name={`absences-limit-${record.absenceType.id}`}
                    value={absenceAllowance?.value || ""}
                    onChange={(e) => {
                      const newValue = produce(formik.values.absenceAllowances, draft => {
                        const absenceToChange = _find(draft as TApiAbsenceAllowance[], (absenceAllowance) => absenceAllowance.absenceType?.id == record.absenceType.id)
                        if (absenceToChange) {
                          absenceToChange.value = parseFloat(e.target.value) || null
                        }

                        return draft
                      })

                      formik.setFieldValue('absenceAllowances', newValue)
                    }}
                    onSubmit={formik.submitForm}
                    onPressEnter={formik.submitForm}
                  />
                  <div style={{ marginLeft: '10px' }}>
                    days per {record.absenceType.period}
                  </div>
                </div>
              )
            }
          },
        ]}
      />
      <ErrorMessage msg={formik.errors.absenceAllowances} />
    </>
  )
}

export default TeamMemberAbsencesForm