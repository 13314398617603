import { TApiResponse, TApiParams, TApiPlan, TApiSession} from "./types"
import BaseApi, { getQueryString } from './base'

// Needed for not v2 api url 
const ROOT_API_URL = process.env.REACT_APP_API_URL || 'http://api.lvh.me'

export interface IGetPlansResponse extends TApiResponse {
  plans: Array<TApiPlan>
}

export interface ILoginReponse extends TApiResponse {
  session: TApiSession
}

export interface IRegisterEmailResponse extends TApiResponse {
  registration: {
    token: string
  }
}

class RegistrationsApi extends BaseApi {

  setSelectedPlanId(planId?: string) {
    if (planId !== undefined) {
      window.localStorage.setItem('selectedPlanId', planId)
    } else {
      window.localStorage.removeItem('selectedPlanId')
    }
  }

  getSelectedPlanId() {
    return window.localStorage.getItem('selectedPlanId')
  }

  async registerEmail({
    email,
  }: {
    email: string,
  }): Promise<IRegisterEmailResponse> {

    const selectedPlanId = this.getSelectedPlanId()
    let response = await this.post(`/registrations`, {
        email: email.toLowerCase(),
        // annual plan_id=5aaa1c0b-b2a2-4bb8-ad5c-2df942195fbe
        // monthly plan_id=76dd61d3-fb1e-4e5c-98b6-3ffe51b554bc,
        ...(selectedPlanId ? {
          plan_id: selectedPlanId,
        } : {})
      }
    )

    if (response.ok) {
      this.setSelectedPlanId(undefined)
    }

    if (response.status !== 200 && response.status !== 201) {
      console.log(
        'Looks like there was a problem. Status Code: ' + response.status
      )
    }

    return response
  }

  async fullfillRegistration({
    registration,
    user,
  }: {
    registration: {
      token: string,
    },
    user: {
      firstName: string,
      lastName: string,
      password: string,
      termsOfService: boolean,
      account: {
        name: string,
        additionalData?: object,
      }
    }
  }) {

    return this.post('/registrations/fulfill', {
      registration: {
        source: this.clientName,
        ...registration,
      },
      user,
    })
  }

  async login({
    email,
    password,
  }: {
    email: string,
    password: string,
  }): Promise<ILoginReponse> {
    let response = await this.post(`/sessions`, {
        session: {
          email: email.toLowerCase(),
          password,
        }
      }
    )

    if (response.status !== 200 && response.status !== 201) {
      console.log(
        'Looks like there was a problem. Status Code: ' + response.status
      )
    }

    return response
  }

  loginWithGoogle() {
    const requestParams = {
      action: 'sign_in',
      // web_redirect_url: 'http://localhost:3000/auth/auto-login', -> spec in case its needed
    }

    let queryString = ''
    queryString = getQueryString(this.paramsToSnakeCase({ ...requestParams }), true)

    window.location = `${ROOT_API_URL}/auth/google_oauth2${queryString}` as any
  }

  async requestPasswordReset({
    email,
  }: {
    email: string,
  }) {
    let response = await this.post(`/users/password_reset`, {
        email: email.toLowerCase(),
      }
    )

    if (response.status !== 200 && response.status !== 201) {
      console.log(
        'Looks like there was a problem. Status Code: ' + response.status
      )
    }

    return response
  }

  async confirmPasswordReset({
    resetToken,
    password,
    passwordConfirmation,
  }: {
    resetToken: string,
    password: string,
    passwordConfirmation: string,
  }) {
    return await this.patch(`/users/confirm_password`, {
      resetToken,
      user: {
        password,
        passwordConfirmation
      }
    })
  }

  registerWithGoogle(additionalDetails: null | undefined | object) {
    const selectedPlanId = this.getSelectedPlanId()
    const requestParams = {
      action: 'sign_up',
      ...(selectedPlanId ? {
        plan_id: selectedPlanId
      } : {})
    }

    if (!!additionalDetails) {
      requestParams['additionalDetails'] = additionalDetails
    }

    let queryString = ''
    queryString = getQueryString(this.paramsToSnakeCase({ ...requestParams }), true)

    window.location = `${ROOT_API_URL}/auth/google_oauth2${queryString}` as any
  }

}

export default RegistrationsApi
