import { Button, Card, DatePicker, Modal, Skeleton, Table, message } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useState } from "react"
import GenericButtonsList from "components/generic-buttons-list/generic-buttons-list"
import { DeleteSvg, EditSVG, MoreSvg } from "assets/svg/icon"
import { TGenericButton } from "components/generic-buttons-list/generic-buttons-list"
import useNoInitialEffect from "hooks/useNoInitialEffect"
import { GoogleOutlined } from '@ant-design/icons'
import moment from "moment-timezone"
import { useFreeDays } from "hooks/absences"
import FreeDayForm from "./free-day-form"
import FreeDaysGoogleImportForm from "./free-days-google-import-form"
import { useHistory } from "react-router-dom"
import { useQueryParams } from "hooks/app-router"
import { TApiFreeDay } from "@timenotes/shared/src/services/api-client/holidays-free-days-api"
import { TApiPagination } from "@timenotes/shared/src/services/api-client/types"
import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"

type TFreeDaysTableItem = TApiFreeDay

export interface FreeDaysTableProps {
  pagination?: TApiPagination
  onPaginationCurrentPageChange?(page: number): void
  refresh?(): void
  //onEditItemVisibleChange?(visible: boolean): void
}

const FreeDaysTable = (props: FreeDaysTableProps) => {
  const queryParams = useQueryParams<{ holidaysYear: string }>()
  const history = useHistory()

  const { freeDays, loading, filters, fetchFreeDays, deleteFreeDay, setFilters} = useFreeDays({
    filters: {
      year: queryParams.holidaysYear ? queryParams.holidaysYear : moment().year().toString(),
    }
  })

  useNoInitialEffect(() => {
    if (filters.year) {
      history.push({
        pathname: window.location.pathname,
        search: `?holidaysYear=${filters.year}`
      })
    } else {
      history.push(window.location.pathname)
    }
  }, [filters.year])

  const [importVisible, setImportVisible] = useState(false)

  const [newModalVisible, setNewModalVisible] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [freeDayToEdit, setFreeDayToEdit] = useState<TApiFreeDay | undefined>()

  const handleDelete = (id: string) => {
    deleteFreeDay(id).then((response) => {
      if (response.ok) {
        message.success("Holiday deleted!")
        fetchFreeDays()
      } else {
        message.error(getApiErrorMessage(response, 'base'))
      }
    })
  }

  const columns: ColumnsType<TFreeDaysTableItem> = [
    {
      title: 'Name',
      render: (value, record) => (
        <span>{record.name}</span>
      )
    },

    {
      title: 'Date',
      render: (value, record) => (
        <span>{moment(record.date).format("DD.MM.YYYY")}</span>
      )
    },

    {
      title: '',
      render: (value, record) => {
        let buttons: TGenericButton[] = [
          {
            title: '',
            icon: MoreSvg,
            type: 'text',
            options: [
              {
                title: 'Edit holiday',
                Icon: EditSVG,
                callback: () => {
                  setFreeDayToEdit(record)
                  setEditModalVisible(true)
                }
              },
              {
                title: 'Delete holiday',
                Icon: DeleteSvg,
                callback: () => {
                  Modal.confirm({
                    title: "Are you sure you want to delete this holiday?",
                    okText: 'Delete',
                    onOk: () => {
                      handleDelete(record.id)
                    }
                  })
                }
              },
            ],
          }
        ]

        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GenericButtonsList 
              buttons={buttons}
            />
          </div>
        )
      }
    }
  ]

  if (loading) {
    return (
      <div style={{ width: '100%', maxWidth: '800px' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '15px' }}>
          <Skeleton.Button style={{ width: '120px' }}/>
          <Skeleton.Button style={{ width: '120px' }}/>
          <Skeleton.Button style={{ width: '120px' }}/>
        </div>
        <br />
        <Card>
          <Skeleton />
          <Skeleton />
        </Card>
      </div>
    )
  }

  return (
    <div style={{ width: '100%', maxWidth: '800px' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '15px' }}>

        <DatePicker.YearPicker 
          value={moment(filters.year)}
          onChange={(momentValue) => {
            setFilters({
              year: momentValue?.year().toString()
            })
          }}
          allowClear={false}
        />

        <Button 
          type="default"
          onClick={() => setImportVisible(true)}
          icon={<GoogleOutlined />}
        >
          Import from Google
        </Button>

        <Button 
          type="primary"
          onClick={() => {
            setNewModalVisible(true)
          }}
        >
          Add holiday
        </Button>
      </div>

      <br />

      <Table<TFreeDaysTableItem>
        columns={columns}
        dataSource={freeDays}
        pagination={!!props.pagination && {
          pageSize: props.pagination.pageSize as number,
          current: props.pagination.currentPage as number,
          total: props.pagination.totalCount as number,
          showSizeChanger: false,
          onChange: (page) => {
            props.onPaginationCurrentPageChange && props.onPaginationCurrentPageChange(page)
          }
        }}
      />

      <Modal
        title="Add new holiday"
        visible={newModalVisible}
        onCancel={() => { setNewModalVisible(false) }}
        footer={false}
        destroyOnClose={true}
      >
        <FreeDayForm
          onSuccess={(response) => {
            fetchFreeDays()
            setNewModalVisible(false)
          }}
        />
      </Modal>

      <Modal
        title="Edit holiday"
        visible={editModalVisible}
        onCancel={() => { setEditModalVisible(false) }}
        footer={false}
        destroyOnClose={true}
      >
        <FreeDayForm
          initialValues={freeDayToEdit}
          onSuccess={(response) => {
            fetchFreeDays()
            setEditModalVisible(false)
          }}
        />
      </Modal>

      <Modal
        title="Import holidays from Google"
        visible={importVisible}
        onCancel={() => { setImportVisible(false) }}
        footer={false}
        destroyOnClose={true}
      >
        <FreeDaysGoogleImportForm 
          holidaysYear={filters.year as string}
        />
      </Modal>
 
   </div>
  )
}

export default FreeDaysTable