import { 
  ApiOutlined,
  BarChartOutlined,
  CoffeeOutlined,
  DashboardOutlined,
  ProjectOutlined,
  SettingOutlined,
  StockOutlined,
  ShoppingOutlined,
  TableOutlined,
  TagOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  AuditOutlined
} from '@ant-design/icons';

const dashBoardNavTree = [
  {
    key: 'home',
    path: `/timer`,
    title: 'Timer',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'activity',
    path: `/activities/dashboard`,
    title: 'Activity',
    icon: StockOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'Timesheet',
    path: `/timesheets`,
    title: 'Timesheet',
    icon: TableOutlined,
    breadcrumb: false,
    submenu: []
  },

  {
    key: 'reports',
    path: `/reports`,
    title: 'Reports',
    icon: BarChartOutlined,
    breadcrumb: false,
    submenu: []
  },

  {
    key: 'approvals',
    path: `/approvals`,
    title: 'Approvals',
    icon: AuditOutlined,
    breadcrumb: false,
    submenu: []
  },

  {
    key: 'projects',
    path: `/projects`,
    title: 'Projects',
    icon: ProjectOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'team',
    path: `/team/members`,
    title: 'Team',
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'absences',
    path: `/absences/calendar`,
    title: 'Absences',
    icon: CoffeeOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'clients',
    path: `/clients`,
    title: 'Clients',
    icon: ShoppingOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'tags',
    path: `/tags`,
    title: 'Tags',
    icon: TagOutlined,
    breadcrumb: false,
    submenu: []
  },
 
  {
    key: 'alerts',
    path: `/alerts`,
    title: 'Alerts',
    icon: ThunderboltOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'integrations',
    path: `/integrations/workspaces`,
    title: 'Integrations',
    icon: ApiOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'settings',
    path: `/settings/profile`,
    title: 'Settings',
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: []
  },
]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig