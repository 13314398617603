import { QueryClient, useMutation, useQueryClient } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiProject, TApiTask } from "../../api-client/types"
import { TApiGenericError, TApiGenericResponse } from "../../timenotes-query/timenotes-query.types"

interface Args {
  projectId: string
  taskId: string
}

interface Result {
  task: TApiTask
}

export const useBookmarkTaskMutation = (params: any = {}, options: any = {}) => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  const query = useMutation<Result, TApiGenericError<TApiTask>, Args>(
    ['bookmark-task'],
    async (args: Args) => {
      const response = await apiClient.bookmarkTask(args.projectId, args.taskId)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        task: response.task,
      }
    },
    {
      onSuccess: (...args) => {
        if (options.onSuccess) options.onSuccess(args)
        updateTaskCacheCallback(queryClient)
      },
      ...options,
    },
  )

  return query
}

export const useUnbookmarkTaskMutation = (params: any = {}, options: any = {}) => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  const query = useMutation<Result, TApiGenericError<TApiProject>, Args>(
    ['unbookmark-task'],
    async (args: Args) => {
      const response = await apiClient.unbookmarkTask(args.projectId, args.taskId)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        task: response.task,
      }
    },
    {
      onSuccess: (...args) => {
        if (options.onSuccess) options.onSuccess(args)
        updateTaskCacheCallback(queryClient)
      },
      ...options,
    },
  )

  return query
}

const updateTaskCacheCallback = (queryClient: QueryClient) => {
  return (data: Result, args: Args) => {
    queryClient.getQueriesData<TApiGenericResponse<TApiTask[]>>(['tasks', args.projectId]).forEach(([key, queryData]) => {
      if (queryData && Array.isArray(queryData.data)) {
        const currentTask = queryData.data.find((t) => t.id == data.task.id)

        if (currentTask) {
          queryClient.setQueryData<TApiGenericResponse<TApiTask[]>>(key, (oldData: any) => ({
            ...oldData,
            data: oldData.data.map((task: TApiTask) => {
              if (task.id == data.task.id) {
                return { 
                  ...data.task,
                  recentlyTracked: (data.task.recentlyTracked || task.recentlyTracked)
                }
              }

              return task
            })
          }))
        }
      }
    })
  }
}