import { TApiApprovalRequest, TApiApprovalRequestStatus } from "@timenotes/shared/src/services/approvals/approvals-types";
import { Tag } from "antd";
import { COLORS } from "components/layout-components/timenotes-theme";

type ApprovalStatusTagProps = React.ComponentProps<typeof Tag> & {
  status?: TApiApprovalRequestStatus
}


export default function ApprovalStatusTag(props: ApprovalStatusTagProps) {

  const status = props.status || 'open'

  const color: string | undefined = {
    open: undefined,
    approved: COLORS.primaryGreen,
    pending: 'orange',
    rejected: "#dd4444",
  }[status] || undefined

  const tagProps = {
    ...props,
  }

  return (
    <Tag
      color={color}
      {...tagProps}
    />
  )
}