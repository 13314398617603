import { Pagination } from "antd"
import { ProjectName } from "./project-table-cell/project-name/ProjectName"
import { TeamAvatarGroup } from "./project-table-cell/project-team/ProjectTeam"
import { TotalTime } from "./project-table-cell/project-total-time"
import BillableFlag from "components/base/billable-flag"
import { GenericTableParams } from "components/generic-table/generic-table"
import { usePagePermissions } from "hooks/permissions.hooks"
import GenericTable2 from "components/generic-table/generic-table2"
import { ProjectsActionsPanel } from "./project-table-cell/project-actions/project-actions-panel"
import { TApiProjectsSorting } from "@timenotes/shared/src/services/api-client/projects-api"
import { TApiPagination, TApiProject } from "@timenotes/shared/src/services/api-client/types"

interface IProps {
  sorting: TApiProjectsSorting
  projects: TApiProject[]
  goToProjectPath(projectId: string): void
  pagination: TApiPagination

  onCurrentPageChange(currentPage: number): void
  onSortingChange(sorting: TApiProjectsSorting): void
  onReload(): void
}

const ProjectsTable = (props: IProps) => {
  const {
    sorting,
    projects,
    goToProjectPath,
    pagination,

    onCurrentPageChange,
    onSortingChange,
    onReload,
  } = props

  const { permissions} = usePagePermissions()
  const canSetTimeLogBillable = !!permissions?.setTimeLogBillable

  const handleTableChange = (tableParams: GenericTableParams) => {
    if (tableParams.sorting && tableParams.sorting.key == 'projectName') {
      onSortingChange({
        name: tableParams.sorting.order
      })
    } else {
      onSortingChange({
        name: 'asc'
      })
    }
  }

  const COLUMNS = [
    {
      title: "Project",
      key: 'projectName',
      width: "35%",
      className: "grp",
      sorter: true,
      onCell: (record: any, index: any) => {
        return {
          style: {
            cursor: 'pointer'
          },
          onClick: () => {
            goToProjectPath(record.id)
          }
        }
      },

      render: (text: string, record: TApiProject, index: number) => (
        <div
          style={{
            padding: '5px',
          }}
        >
          <ProjectName
            data={{
              projectName: record.name,
              origin: record.origin,
              clientName: record.client?.name || "",
              isArchived: !!record.archivedAt,
              isConnected: (record.origin !== "timenotes" && !record.connected)
            }}
          />
        </div>
      )
    },
    {
      title: "Team",
      width: "20%",
      dataIndex: "team",

      render: (text: string, record: TApiProject, index: number) => {
        if (record.visibility == true) {
          return <div style={{ color: "#9D9DA7" }}>All members</div>
        } else {
          // TODO: Use extended TApiProject for the list?
          return <TeamAvatarGroup data={record.usersAccounts} maxCount={5} />
        }
      },
    },
    {
      title: "Tracked",
      width: "10%",
      dataIndex: "tracked",

      render: (text: string, record: TApiProject, index: number) => {
        return <TotalTime totalTime={record.totalTime || 0} />
      },
    },
    {
      title: "", // actions, no title of column displayed
      width: "10%",
      dataIndex: "store",

      render: (text: string, record: TApiProject, index: number) => {
        const onAction = async () => {
          onReload()
        }

        return (
          <ProjectsActionsPanel 
            project={record} 
            onAction={onAction} 
            goToProjectPath={goToProjectPath} 
          />
        )
      },
    },
  ]

  // If you can set time log as billable you can see and filter by it
  if (canSetTimeLogBillable) {
    // @ts-ignore
    COLUMNS.splice(3, 0, {
      title: 'IS BILLABLE',
      width: '5%',
      render: (text, record: TApiProject) => (
        <BillableFlag
          checked={record.billableEnabled === true ? true : false}
          clickable={false}
        />
      )
    })
  }

  return (
    <>
      <GenericTable2<TApiProject>
        tableProps={{
          showHeader: true,
          dataSource: projects,
          columns: COLUMNS,
          rowKey: 'id',
          pagination: false,
          onRow: (column, index) => {
            return { className: 'projects-table-row' }
          }
        }}

        tableParams={{
          selectedAll: false,
          selectedRowKeys: [],
          pagination: null,
          sorting: sorting ? {
            key: 'projectName',
            order: sorting.name,
          } : null
        }}

        rowColorSelector={(record) => {
          return record.color || '#E5E5EB'
        }}

        onChange={handleTableChange}
      />

      <br />

      <div style={{ display: 'flex', justifyContent: 'right' }} >
        <Pagination
          current={pagination.currentPage}
          pageSize={pagination.pageSize}
          total={pagination.totalCount}
          showSizeChanger={false}
          onChange={(page) => { 
            onCurrentPageChange(page)
          }}
        />
      </div>
    </>
  )
}

export default ProjectsTable