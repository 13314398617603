import { useRef } from "react"
import { message, Modal, ModalProps, Tabs } from "antd"
import styled from "styled-components"
import { Card } from "components/base"
import TeamMemberDetailsForm from "./team-member-details-form"
import TeamMemberRatesForm from "./team-member-rates-form"
import TeamMemberAbsencesForm from "./team-member-absences-form"
import { Formik, Form } from "formik"
import _omit from 'lodash/omit'
import _reduce from 'lodash/reduce'
import { IUpdateUsersAccountParams, TApiTeamMember } from "@timenotes/shared/src/services/api-client/users-accounts-api"
import { TApiResponse } from "@timenotes/shared/src/services/api-client/types"
import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"

const StyledModal = styled(Modal)`
  & .ant-modal-body {
    padding: 0px;
  }

  & .modal-content-section {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 24px;
  }

  & .ant-tabs-nav {
    margin-bottom: 0px !important;
  }

  & .ant-tabs-tabpane.ant-tabs-tabpane-active {
    background-color: #f9f9f9;
  }

  & .timenotes-tabs .ant-tabs-tab {
    background-color: #ECECEC;
  }

  & .timenotes-tabs .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #f9f9f9;
    border-bottom-color: #f9f9f9 !important;
  }
`

const TeamMemberModal = (props: ModalProps & { 
  teamMember?: TApiTeamMember
  onSubmit(params: IUpdateUsersAccountParams): Promise<TApiResponse>
} ) => {

  const formRef = useRef(null)

  if (!props.teamMember) {
    return null
  }

  const modalProps = _omit(props, ['teamMember'])
  const member = props.teamMember as TApiTeamMember
  const teamMemberCurrentLimits: IUpdateUsersAccountParams['absenceAllowances'] = member.absenceAllowances

  const isVisible = !!props.visible || !!props.teamMember

  return (
    <Formik<Partial<IUpdateUsersAccountParams>>
      initialValues={{
        firstName: member.firstName,
        lastName: member.lastName,
        role: member.role as 'member' | 'admin' | undefined,
        billableRate: member.billableRate,
        costRate: member.costRate ? member.costRate : undefined,
        notes: member.notes || "",
        absenceAllowances: teamMemberCurrentLimits,
      }}
      onSubmit={(values, formik) => { 
        props.onSubmit(values).then((response) => {
          if (!response.ok) {
            if (response.status == 422 && response.errors) {
              formik.setErrors(response.errors)
            } else {
              message.error(getApiErrorMessage(response, 'base'))
            }
          }

          return response
        })
      }}
    >
      {(formik) => (

        <StyledModal
          {...modalProps}
          width={600}
          title={null}
          okText="Update member"
          onOk={() => {
            const response = formik.submitForm()
          }}
        >
          {member && (
            <>
              <div className="modal-content-section">
                <h3>
                  Edit member profile
                </h3>
                <p>
                  <b>
                    {member.firstName} {member.lastName}
                  </b>
                </p>
              </div>

              <Form className="ant-form ant-form-vertical" onSubmit={formik.handleSubmit} ref={formRef}>

                <div style={{
                  width: '100%',
                  paddingLeft: '0px', paddingRight: '0px',
                  paddingBottom: '-1px',
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',
                }}
                  className="timenotes-tabs">
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>

                    <Tabs
                      tabBarExtraContent={({ left: (<div style={{ width: '30px' }} />) })}
                      type="card"
                      tabBarGutter={5}
                      style={{ width: '100%' }}
                      destroyInactiveTabPane={true}
                    >
                      <Tabs.TabPane tab="Details" key="details">
                        <div className="modal-content-section">
                          <Card>
                            <TeamMemberDetailsForm
                              teamMember={member}
                              onSubmit={props.onSubmit}
                              formik={formik}
                            />
                          </Card>
                        </div>
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Rates & Limits" key="rates">
                        <div className="modal-content-section">
                          <Card>
                            <TeamMemberRatesForm teamMember={member} onSubmit={props.onSubmit} formik={formik} />
                          </Card>
                        </div>
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Absences" key="absences">
                        <div className="modal-content-section">
                          <Card>
                            <h4>
                              Absences Allowance
                            </h4>
                            <p>
                              Customise absence limits for user in each absence type with defined limit.
                            </p>
                            <TeamMemberAbsencesForm teamMember={member} onSubmit={props.onSubmit} formik={formik} />
                          </Card>
                        </div>
                      </Tabs.TabPane>

                    </Tabs>
                  </div>
                </div>
              </Form>
            </>
          )}
        </StyledModal>
      )}
    </Formik>

  )
}

export default TeamMemberModal