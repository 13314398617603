import { Modal, Table, Tag, message } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useState } from "react"
import AbsenceIcon from "components/base/absence-icon"
import { getUsersAccountFullName } from "services/users-accounts"
import GenericButtonsList from "components/generic-buttons-list/generic-buttons-list"
import { DeleteSvg, EditSVG, MoreSvg } from "assets/svg/icon"
import { TGenericButton } from "components/generic-buttons-list/generic-buttons-list"
import AbsenceRequestModal, { IAbsenceRequestsFormObject } from "./absence-request-modal"
import useNoInitialEffect from "hooks/useNoInitialEffect"
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import moment from "moment-timezone"
import { IDropdownOption } from "components/layout/page-header"
import { usePagePermissions } from "hooks/permissions.hooks"
import { useDefaultDateFormat } from "hooks/settings"
import useApiClient from "hooks/useApiClient"
import { TApiAbsence, TApiPagination } from "@timenotes/shared/src/services/api-client/types"
import { TApiAbsenceRequest } from "@timenotes/shared/src/services/api-client/holidays-absence-requests-api"
import { getApiErrorMessage, handleResponseWithMessage } from "@timenotes/shared/src/services/api-client"

type TAbsenceTableItem = TApiAbsenceRequest | TApiAbsence

export interface TimesheetAbsencesTableProps {
  absences: TAbsenceTableItem[]
  pagination?: TApiPagination
  myAbsencesOnly?: boolean,
  onPaginationCurrentPageChange?(page: number): void
  refresh(): void
  onEditAbsenceRequestVisibleChange?(visible: boolean): void
}

const AbsencesModalTable = (props: TimesheetAbsencesTableProps) => {
  const apiClient = useApiClient()

  const [editAbsenceRequestVisible, setEditAbsenceRequestVisible] = useState(false)
  const [absenceRequestToEdit, setAbsenceRequestToEdit] = useState<TAbsenceTableItem | undefined>()

  const { permissions } = usePagePermissions()
  const { defaultDateFormat } = useDefaultDateFormat()

  useNoInitialEffect(() => {
    if (props.onEditAbsenceRequestVisibleChange) {
      props.onEditAbsenceRequestVisibleChange(editAbsenceRequestVisible)
    }
  }, [editAbsenceRequestVisible])

  const handleAbsenceRequestDelete = (id: string) => {
    apiClient.deleteHolidaysAbsenceRequest(id).then((response) => {
      if (response.ok) {
        message.success("Absence request deleted")
        props.refresh()
      } else {
        message.error(getApiErrorMessage(response, 'base'))
      }
    })
  }

  const handleAbsenceRequestApprove = (id: string) => {
    apiClient.approveHolidaysAbsenceRequest(id).then(handleResponseWithMessage('Absence request approved!')).then((response) => {
      props.refresh()
    })
  }

  const handleAbsenceRequestReject = (id: string) => {
    apiClient.rejectHolidaysAbsenceRequest(id).then(handleResponseWithMessage('Absence request rejected!')).then((response) => {
      props.refresh()
    })
  }

  const absenceRequestEditFormObject: IAbsenceRequestsFormObject = !absenceRequestToEdit ? {} : {
    id: absenceRequestToEdit.id,
    holidaysAbsenceTypeId: absenceRequestToEdit.absenceType.id,
    absenceType: absenceRequestToEdit.absenceType,
    fromDate: absenceRequestToEdit.fromDate,
    toDate: absenceRequestToEdit.toDate,
    comment: absenceRequestToEdit.comment,
    ...(props.myAbsencesOnly ? {} : {
      usersAccountId: (absenceRequestToEdit as TApiAbsenceRequest).usersAccount.id,
      usersAccount: (absenceRequestToEdit as TApiAbsenceRequest).usersAccount,
    })
  }

  const columns: ColumnsType<TAbsenceTableItem> = [
    ...(props.myAbsencesOnly ? [] : [
      {
        title: 'Team Member',
        render: (value: string, record: TAbsenceTableItem) => {
          if ('usersAccount' in record) {
            return (
              <span>{getUsersAccountFullName(record.usersAccount)}</span>
            )
          } else {
            return "No Team Member"
          }
        }
      }]),

    {
      title: 'Absence Type',
      render: (value, record: TAbsenceTableItem) => (
        <div style={{display: 'flex', alignItems: 'center' }}>
          <Tag color={record.absenceType.color} style={{height: '23px' }} />
          <AbsenceIcon icon={record.absenceType.icon} /> 
          <span style={{marginLeft: '5px', marginRight: '5px' }}>{record.absenceType.name}</span>
      </div>
      )
    },

    {
      title: 'Comment',
      render: (value, record) => (
        <span><i>{record.comment ? record.comment : 'No details'}</i></span>
      )
    },

    {
      title: 'Request Date',
      render: (value, record: TAbsenceTableItem) => (
        <span>{moment(record.createdAt).format(defaultDateFormat)}</span>
      )
    },

    {
      title: 'Absence Date',
      render: (value, record: TAbsenceTableItem) => (
        <span>{record.formattedRange}</span>
      )
    },

    {
      title: 'Status',
      render: (value, record: TAbsenceTableItem) => {
        const tagColors: any = {
          'approved': 'green',
          'rejected': 'red'
        }

        const verifier = record.verifier
        const StatusTag = <Tag color={tagColors[record.status] || '#aaa'}><b>{record.formattedStatus}</b> {verifier && `by ${getUsersAccountFullName(verifier)}`}</Tag>
        return StatusTag
      }
    },

    {
      title: '',
      render: (value, record: TAbsenceTableItem) => {
        let extraButtons: IDropdownOption[] = []

        if (!props.myAbsencesOnly) {
          if (record.status == 'rejected') {
            extraButtons = [
              {
                title: "Approve absence request",
                type: 'primary',
                size: 'small',
                Icon: CheckCircleOutlined,
                callback: () => {
                  handleAbsenceRequestApprove(record.id)
                }
              },
            ]
          }

          if (record.status == 'approved') {
            extraButtons = [
              {
                title: "Reject absence request",
                type: 'default',
                size: 'small',
                Icon: CloseCircleOutlined,
                callback: () => {
                  handleAbsenceRequestReject(record.id)
                }
              }]
          }
        }

        let buttons: TGenericButton[] = [
          {
            title: '',
            icon: MoreSvg,
            type: 'text',
            options: [
              {
                title: 'Edit absence request',
                Icon: EditSVG,
                callback: () => {
                  setAbsenceRequestToEdit(record)
                  setEditAbsenceRequestVisible(true)
                }
              },
              ...extraButtons,
              {
                title: 'Delete absence request',
                Icon: DeleteSvg,
                callback: () => {
                  Modal.confirm({
                    title: "Are you sure you want to delete this absence request?",
                    okText: 'Delete',
                    onOk: () => {
                      handleAbsenceRequestDelete(record.id)
                    }
                  })
                }
              },
            ],
          }
        ]

        if (!props.myAbsencesOnly) {
          if (record.status == 'waiting') {
            buttons = [
              {
                title: "Approve",
                type: 'primary',
                size: 'small',
                icon: CheckCircleOutlined,
                callback: () => {
                  handleAbsenceRequestApprove(record.id)
                }
              },
              {
                title: "Reject",
                type: 'default',
                size: 'small',
                icon: CloseCircleOutlined,
                callback: () => {
                  handleAbsenceRequestReject(record.id)
                }
              },
              ...buttons]
          }
        }

        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GenericButtonsList 
              buttons={buttons}
            />
          </div>
        )
      },
    }
  ]

  return (
    <>
      <Table<TAbsenceTableItem>
        columns={columns}
        dataSource={props.absences}
        pagination={!!props.pagination && {
          pageSize: props.pagination.pageSize as number,
          current: props.pagination.currentPage as number,
          total: props.pagination.totalCount as number,
          showSizeChanger: false,
          onChange: (page) => {
            props.onPaginationCurrentPageChange && props.onPaginationCurrentPageChange(page)
          }
        }}
      />

      <AbsenceRequestModal 
        myAbsencesOnly={props.myAbsencesOnly}
        visible={editAbsenceRequestVisible}
        initialValues={absenceRequestEditFormObject}
        onCancel={() => {
          setEditAbsenceRequestVisible(false)
        }}
        onSubmit={() => {
          setEditAbsenceRequestVisible(false)
          props.refresh()
        }}
      />
    </>
  )
}

export default AbsencesModalTable