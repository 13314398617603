import { TApiPeriodStatus } from "@timenotes/shared/src/services/approvals/approvals-types"
import { Select } from "antd"

export default function PeriodStatusSelect(props: React.ComponentProps<typeof Select>) {
  return (
    <Select
      style={{
        width: '190px',
      }}
      {...props}
      value={props.value || 'all'}
      onChange={(val, option) => {
        if (props.onChange) {
          props.onChange(val == 'all' ? undefined : val, option)
        }
      }}
      options={[
        {
          value: 'all',
          label: 'All statuses',
        },
        {
          value: 'pending',
          label: 'Waiting for approval',
        },
        {
          value: 'rejected',
          label: 'Rejected',
        },
        {
          value: 'open',
          label: "Waiting for submission",
        },
        {
          value: 'approved',
          label: 'Approved'
        }
      ]}
    />
  )
}
