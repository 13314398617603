import React, { useEffect, useState } from 'react'
import _map from 'lodash/map'
import { Select, Skeleton } from 'antd'
import { useMembersGroupsQuery } from '@timenotes/shared/src/services/members-groups/queries/use-members-groups-query'
import { TApiMembersGroup } from 'services/api-client/types'

export interface UsersAccountSelectProps extends React.ComponentProps<typeof Select<string>> {
  filter?(membersGroups: TApiMembersGroup[]): TApiMembersGroup[]
}

const MembersGroupSelect = (props: UsersAccountSelectProps): JSX.Element => {

  const [loaded, setLoaded] = useState(false)
  const membersGroupsQuery = useMembersGroupsQuery()

  useEffect(() => {
    if (membersGroupsQuery.isSuccess) {
      setLoaded(true)
    }
  }, [membersGroupsQuery.isSuccess])

  if (!loaded || !membersGroupsQuery.data) {
    return <Skeleton.Button />
  }

  // LOADED 

  const allMembersGroups = membersGroupsQuery.data.membersGroups

  const membersGroups = props.filter ? props.filter(allMembersGroups) : allMembersGroups

  const options = membersGroups.map((membersGroup) => {
    return {
      value: membersGroup.id,
      label: membersGroup.name,
    }
  })

  const defaultWidth = "100%"

  return (
    <Select
      options={options}
      optionFilterProp='label'
      { ...props }
      style={{
        width: defaultWidth,
        ...props.style
      }}
    />
  )
}

export default MembersGroupSelect