import {Card, message} from "antd"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { TApiImportsSummary} from "services/timenotes/imports/imports.types"
import ImportHeader from "./components/import-header"
import ImportSummaryTable from "./components/import-summary-table"

const ImportSummaryPage = () => {
  const location = useLocation()
  const history = useHistory()

  const importSummary = location.state?.summary as TApiImportsSummary

  const { importId } = useParams<{
    importId: string
  }>()

  if (!importSummary) {
    message.warning("Account import expired, please try again!")
    history.push('/imports/new')

    return null
  }

  return (
    <>
      <ImportHeader step={4} />

      <div className="page-content">
        <Card>
          <p><b>
            Import successfull!
          </b></p>

          <p>
            Import has been successful, following data records has been created:
          </p>

        </Card>

        <ImportSummaryTable
          importId={importId}
          summary={importSummary}
        />

      </div>
    </>
  )
}

export default ImportSummaryPage
