import { DatePicker, Select } from "antd"
import { PickerDateProps } from "antd/lib/date-picker/generatePicker"
import { useDefaultDateFormat } from "hooks/settings"
import moment, { Moment } from "moment-timezone"
import { TApiReportsDate } from "services/api-client/reports-api"

export interface IReportsDatePicker extends Omit<PickerDateProps<Moment>, 'onChange'> {
  reportsDate: TApiReportsDate
  onChange(value: TApiReportsDate): void
}

const TimespanPicker = ({
  timespan, 
  onChange
}: {
  timespan: TApiReportsDate['timespan'],
  onChange(value: TApiReportsDate['timespan']) : void, 
},
  ) => {
  return (
    <Select
      value={timespan}
      onChange={(value) => { onChange(value) }}
      style={{ width: '100px' }}
    >
      <Select.Option value={'day'}>Day</Select.Option>
      <Select.Option value={'week'}>Week</Select.Option>
      <Select.Option value={'month'}>Month</Select.Option>
      <Select.Option value={'year'}>Year</Select.Option>
      <Select.Option value={'custom'}>Custom</Select.Option>
    </Select>
  )
}


export const ReportsDatePickerInput = (props: IReportsDatePicker): JSX.Element => {
  const { defaultDateFormat } = useDefaultDateFormat()

  const timespan = props.reportsDate.timespan
  switch(timespan) {
    case 'day':
      return (
        <DatePicker 
          {...props}
          format={defaultDateFormat}
          value={moment(props.reportsDate.from, 'DD.MM.yyyyy')}
          onChange={(value) => {
            props.onChange({
              ...props.reportsDate,
              from: value?.format('DD.MM.yyyy') || moment().format('DD.MM.yyyy'),
            })
          }}
        />
      )
      break
    case 'week': 
      return (
          <DatePicker.WeekPicker
            {...props}
            format={defaultDateFormat}
            value={moment(props.reportsDate.from, 'DD.MM.yyyy')}
            onChange={(value) => {
              props.onChange({
                ...props.reportsDate,
                from: value?.format('DD.MM.yyyy') || moment().format('DD.MM.yyyy'),
              })
            }}
          />
      )
      break
    case 'month':
      return (
        <DatePicker.MonthPicker 
          {...props}
          format={'MMM yyyy'}
          value={moment(props.reportsDate.from, 'DD.MM.yyyy')}
          onChange={(value) => {
            props.onChange({
              ...props.reportsDate,
              from: value?.format('DD.MM.yyyy') || moment().format('DD.MM.yyyy'),
            })
          }}
        />
      )
      break
    case 'year':
      return (
        <DatePicker.YearPicker
          {...props}
          format={'yyyy'}
          value={moment(props.reportsDate.from, 'DD.MM.yyyy')}
          onChange={(value) => {
            props.onChange({
              ...props.reportsDate,
              from: value?.format('DD.MM.yyyy') || moment().format('DD.MM.yyyy'),
            })
          }}
        />
      )
      break
    case 'custom':
      return (
          <DatePicker.RangePicker
            format={defaultDateFormat}
            value={[moment(props.reportsDate.from, 'DD.MM.yyyy') ,moment(props.reportsDate.to, 'DD.MM.yyyy')]}
            onChange={(values) => {
              if (values) {
                props.onChange({
                  ...props.reportsDate,
                  from: values[0]?.format('DD.MM.yyyy') || moment().format('DD.MM.yyyy'),
                  to: values[1]?.format('DD.MM.yyyy') || undefined,
                })
            }
            }}
        />
      )
      break
    defaut: 
      return (
        <DatePicker
        />
      )
  }
}

const ReportsDatePicker = (props: IReportsDatePicker): JSX.Element => {

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
      <TimespanPicker 
        timespan={props.reportsDate.timespan}
        onChange={(value) => {
          props.onChange({
            ...props.reportsDate,
            timespan: value,
          })
        }}
      />
      <ReportsDatePickerInput 
        {...props} 
      />
    </div>
  )
}

export default ReportsDatePicker