import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP } from 'consts/theme'
import { APP_NAME } from 'consts/theme-config'
import utils from 'utils'
import { Grid } from 'antd'
import TimenotesTheme from './timenotes-theme'

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  if(isMobile && !props.mobileLogo) {
    return 0
  }
  if(isNavTop) {
    return 'auto'
  }
  if(navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`
  } else {
    return `${SIDE_NAV_WIDTH}px`
  }
}

const getLogo = (props) => {
  const { navCollapsed, logoType } = props;
  if(logoType === 'light') {

    if(navCollapsed) {
      return '/img/logo-sm-white.png'
    }
    return '/img/logo-white.png'
  }

  if (navCollapsed) {
    return '/img/logo-sm'
  }
  return '/img/logo'
}

const getLogoDisplay = (isMobile, mobileLogo) => {
  if(isMobile && !mobileLogo) {
    return 'd-none'
  } else {
    return 'logo'
  }
}

export const Logo = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  const targetLogo = getLogo(props) 
  return (
    <div
      className={getLogoDisplay(isMobile, props.mobileLogo)} 
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: `${getLogoWidthGutter(props, isMobile)}`,
        backgroundColor: TimenotesTheme.colors.primaryPurple,
      }}>
      <img 
        src={`${targetLogo}.png`} alt={`${APP_NAME} logo`}
        srcSet={`${targetLogo}.png 1x, ${targetLogo}@2x.png 2x`}
      />
    </div>
  )
}

export default Logo
