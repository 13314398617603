import { TApiClient, TApiResponse, TApiProject, TApiTask, TApiTag, TApiActiveTracker } from "./types"
import BaseApi, { getQueryString, parameterizeArray } from './base'

export interface ICreateActiveTrackerParams {
  taskId: string
  description?: string
  tagsIds?: Array<string>
}

export interface IActiveTrackerResponse extends TApiResponse {
  active_tracker: TApiActiveTracker
}

class ActiveTrackersApi extends BaseApi {
  async getActiveTracker() {
    let response = await fetch(`${this.rootUrl}/active_tracker`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async createActiveTracker(params: ICreateActiveTrackerParams): Promise<IActiveTrackerResponse> {
    let response = await fetch(`${this.rootUrl}/active_tracker`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        active_tracker: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async deleteActiveTracker() {
    let response = await fetch(`${this.rootUrl}/active_tracker`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

}

export default ActiveTrackersApi