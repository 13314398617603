import { FC } from "react"
import styled from "styled-components"
import { ArchiveSvg, DeleteSvg, MoreSvg } from "assets/svg/icon"
import { Dropdown, Menu, Modal } from "antd"
import { IActionItem, ICONS_MAP } from ".."
import _map from 'lodash/map'

const { confirm } = Modal

interface IProps {
  actions?: IActionItem[]
}

const MenuWrapper = styled.a`
  &:hover > svg > path,
  &:hover > svg > circle {
    fill: #07c5b7;
  }
`

//@ts-ignore
const ItemWrapper = styled(Menu.Item)`
  display: flex;
  align-items: center;
`

const Item = styled.a`
  display: flex !important;
  align-items: center;
`

const IconWrapper = styled.span`
  display: flex;
  margin-right: 5px;
`

export const MoreMenuAction: FC<IProps> = ({ actions }) => {

  const actionsList: JSX.Element[] = _map(actions, (action, index) => {
    const IconComponent = ICONS_MAP[action.icon]

    return (
      <ItemWrapper>
        <Item onClick={() => { 
          if (action.confirm) {
            confirm({
              ...action.confirm,
              onOk: () => { 
                action.action()
              }
            })
          } else {
            action.action()
          }
        }}>
          <IconWrapper>
            <IconComponent />
          </IconWrapper>
          <span>
            {action.title}
          </span>
        </Item>
      </ItemWrapper>
    )
  })

  const menu = (
    <Menu>
      {actionsList}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement="bottomCenter" trigger={["click"]}>
      <MenuWrapper>
        <MoreSvg />
      </MenuWrapper>
    </Dropdown>
  )
}
