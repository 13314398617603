import { Checkbox, DatePicker, Form } from "antd"
import { Formik, FormikConfig, FormikProps } from "formik"
import React from "react"
import UsersAccountSelect from "./users-account-select"
import IsBillableSelect from "./is-billable-select"
import TagsSelect from "./tags-select"
import DescriptionInput from "./description-input"
import { Moment } from "moment-timezone"
import ErrorMessage from "components/base/error-message"
import DurationInput from "./duration-input"
import ProjectSelect from "./project-select"
import TaskSelect from "./task-select"
import { TApiResponse } from "@timenotes/shared/src/services/api-client/types"

export type TimeRowFormItemProps = {
  usersAccountId?: string
  isBillable?: boolean
  tags?: string[]
  description?: string
  date?: Moment
  duration?: number
  projectId?: string
  taskId?: string
}

interface TimeRowFormState {
  clearAllTags: boolean
  clearAllDescriptions: boolean
}

export interface TimeRowFormProps {
  timeLog: TimeRowFormItemProps
  formikRef: React.Ref<FormikConfig<TimeRowFormItemProps>>
  onSubmit(timeLog: TimeRowFormItemProps): Promise<TApiResponse>
}

class TimeRowForm extends React.Component<TimeRowFormProps, TimeRowFormState> {

  constructor(props: TimeRowFormProps) {
    super(props)

    this.state = {
      // Filters field state
      clearAllTags: false,
      clearAllDescriptions: false,
    }
  }

  handleSubmit = (values: TimeRowFormItemProps) => {
    const result = this.props.onSubmit(values)

    return result.then((response) => {
      if (!response.ok) {
        // @ts-ignore
        this.props.formikRef.current.setErrors(response.errors)
      }

      return response
    })

  }

  handleClearAllTagsChange = (newValue: boolean, formik: FormikProps<TimeRowFormItemProps>) => {
    this.setState({ clearAllTags: newValue })

    if (newValue) {
      formik.setFieldValue('tags', [])
    } 
  }

  handleClearAllDescriptionsChange = (newValue: boolean, formik: FormikProps<TimeRowFormItemProps>) => {
    this.setState({ clearAllDescriptions: newValue })

    if (newValue) {
      formik.setFieldValue('description', "")
    } 
  }

  componentDidMount(): void {
  }

  render() {
    return (
      <Formik<TimeRowFormItemProps>
        initialValues={this.props.timeLog}
        onSubmit={this.handleSubmit}
        enableReinitialize={true}

        // @ts-ignore
        innerRef={this.props.formikRef}
      >
        {(formikProps) => (
          <Form
            labelCol={{ span: 6,  }}
            wrapperCol={{ span: 14, offset: 1 }}
          >

            <Form.Item
              label="Project"
            >
              <ProjectSelect
                style={{ width: "200px" }}
                value={formikProps.values.projectId}
                onChange={(value) => {
                  formikProps.setFieldValue('projectId', value)
                }}
              />
              <ErrorMessage msg={formikProps.errors.projectId} />
            </Form.Item>

            <Form.Item
              label="Task"
            >
              <TaskSelect
                projectId={formikProps.values.projectId}

                style={{ width: "200px" }}
                value={formikProps.values.taskId}
                onChange={(value) => {
                  formikProps.setFieldValue('taskId', value)
                }}
              />
              <ErrorMessage msg={formikProps.errors.taskId} />
            </Form.Item>

            <Form.Item
              label="Team Member"
            >
              <UsersAccountSelect 
                width="200px"
                value={formikProps.values.usersAccountId}
                onChange={(value) => {
                  formikProps.setFieldValue('usersAccountId', value)
                }}
              />
              <ErrorMessage msg={formikProps.errors.usersAccountId} />
            </Form.Item>


            <Form.Item
              label="Tags"
            >
              <TagsSelect
                disabled={this.state.clearAllTags}
                width="200px"
                values={formikProps.values.tags}
                onChange={(values) => {
                  formikProps.setFieldValue('tags', values.length == 0 ? undefined : values)
                }}
              />
              <ErrorMessage msg={formikProps.errors.tags} />
            </Form.Item>

            <Form.Item
              label="Clear Tags"
            >
              <Checkbox 
                name=""
                checked={this.state.clearAllTags}
                onChange={(e) => {
                  this.handleClearAllTagsChange(e.target.checked, formikProps)
                }}
              />
            </Form.Item>

            <Form.Item
              label="Date"
            >
              <DatePicker
                style={{ width: '200px'}}
                value={formikProps.values.date}
                onChange={(value) => {
                  formikProps.setFieldValue('date', (value === null) ? undefined : value)
                }}
              />
              <ErrorMessage msg={formikProps.errors.date} />
            </Form.Item>

            <Form.Item
              label="Duration"
            >
              <DurationInput
                width={"200px"}
                value={formikProps.values.duration}
                onChange={(value) => {
                  formikProps.setFieldValue('duration', (value === undefined) ? undefined : value)
                }}
              />
              <ErrorMessage msg={formikProps.errors.duration} />
            </Form.Item>

            <Form.Item
              label="Is Billable"
            >
              <IsBillableSelect
                width="200px"
                value={formikProps.values.isBillable}
                onChange={(value) => {
                  formikProps.setFieldValue('isBillable', value)
                }}
              />
              <ErrorMessage msg={formikProps.errors.isBillable} />
            </Form.Item>

            <Form.Item
              label="Description"
            >
              <DescriptionInput
                disabled={this.state.clearAllDescriptions}
                placeholder="Description"
                style={{ width: '200px'}}
                allowClear={true}
                value={formikProps.values.description}
                onChange={(e) => {
                  formikProps.setFieldValue('description', e.target.value == "" ? undefined : e.target.value)
                }}
              />
              <ErrorMessage msg={formikProps.errors.description} />
            </Form.Item>

            <Form.Item
              label="Clear Descriptions"
            >
              <Checkbox 
                name=""
                checked={this.state.clearAllDescriptions}
                onChange={(e) => {
                  this.handleClearAllDescriptionsChange(e.target.checked, formikProps)
                }}
              />
            </Form.Item>
          </Form>
        )}
      </Formik>
    )
  }

}

export default TimeRowForm