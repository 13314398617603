import { Button, Form, Select, Divider, Tooltip } from "antd"
import { useFormik } from "formik"
import ErrorMessage from "components/base/error-message"
import { IFormObject, WorkspaceSettingsFormProps } from "./workspace-settings-form"
import { InfoIcon } from "components/base/icons"
import SettingsCheckbox from "./settings-checkbox"
import { TApiHiddenPage } from "@timenotes/shared/src/services/api-client/settings-api"
import { filter } from "lodash"
import { CheckboxChangeEvent } from "antd/lib/checkbox"

const WorkspacePermissionsForm = (props: WorkspaceSettingsFormProps) => {
  const initialValues = props.initialValues
  const formik = useFormik<IFormObject>({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values, formik) => {
      const response = await props.onSubmit(values)

      if (!response.ok && response.errors) {
        formik.setErrors(response.errors)
      }

      if (response.ok) {
        formik.resetForm({ values })
      }
    }
  })

  const hiddenPageChecked = (page: TApiHiddenPage) => {
    return formik.values.hiddenPages.includes(page)
  }

  const setHiddenPageChecked = (page: TApiHiddenPage, checked: boolean) => {
    const hidenPagesWithoutPage = filter(formik.values.hiddenPages, (pageToFilter) => pageToFilter != page)

    if (checked) {
      formik.setFieldValue('hiddenPages', ([...hidenPagesWithoutPage, page]))
    } else {
      formik.setFieldValue('hiddenPages', hidenPagesWithoutPage)
    }
  }

  const getHiddenPageOnChangeHandler = (page: TApiHiddenPage) => {
    return (e: CheckboxChangeEvent) => {
      const checked = e.target.checked
      setHiddenPageChecked(page, checked)
    }
  }

  return (
    <Form 
      onSubmitCapture={formik.handleSubmit} 
      layout="vertical"
    >
      <Form.Item
        label="Who can create new projects?"
      >
        <Select
          value={formik.values.projectClientManager}
          onChange={(value) => { 
            formik.setFieldValue('projectClientManager', value)
          }}
          allowClear={false}
        >
          <Select.Option key="admin" value="admin">Admin</Select.Option>
          <Select.Option key="member" value="member">Member</Select.Option>
        </Select>

        <ErrorMessage msg={formik.errors.projectClientManager} />
      </Form.Item>

      <Form.Item
        label="Who can manage tags?"
      >
        <Select
          value={formik.values.tagsManager}
          onChange={(value) => { 
            formik.setFieldValue('tagsManager', value)
          }}
          allowClear={false}
        >
          <Select.Option key="admin" value="admin">Admin</Select.Option>
          <Select.Option key="member" value="member">Member</Select.Option>
        </Select>

        <ErrorMessage msg={formik.errors.tagsManager} />
      </Form.Item>

      <Form.Item
        label="Who can set individual time entries as billable?"
      >
        <Select
          value={formik.values.billableSetter}
          onChange={(value) => { 
            formik.setFieldValue('billableSetter', value)
          }}
          allowClear={false}
        >
          <Select.Option key="admin" value="admin">Admin</Select.Option>
          <Select.Option key="member" value="member">Member</Select.Option>
        </Select>

        <ErrorMessage msg={formik.errors.billableSetter} />
      </Form.Item>

      <Form.Item
        label="Can member see other team members time entries?"
      >
        <Select
          value={formik.values.memberTimelogsVisibility}
          onChange={(value) => { 
            formik.setFieldValue('memberTimelogsVisibility', value)
          }}
          allowClear={false}
        >
          <Select.Option key="own" value="own">
            <b>No</b> - he can see only his own time entries
          </Select.Option>
          <Select.Option key="projects" value="projects">
            <b>Partially</b> - can see others entries in his projects
          </Select.Option>
          <Select.Option key="all" value="all">
            <b>Yes</b> - all the team time entries in the workspace
          </Select.Option>
        </Select>

        <ErrorMessage msg={formik.errors.tagsManager} />
      </Form.Item>


      <Form.Item
        label="Is new project public or private by default?"
      >
        <Select
          value={formik.values.defaultProjectVisibility ? 'public' : 'private'}
          onChange={(value) => { 
            formik.setFieldValue('defaultProjectVisibility', value == 'public')
          }}
          allowClear={false}
        >
          <Select.Option key="private" value="public">Public</Select.Option>
          <Select.Option key="private" value="private">Private</Select.Option>
        </Select>

        <ErrorMessage msg={formik.errors.defaultProjectVisibility} />
      </Form.Item>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '5px',
        }}
      >
        <b>
          Hide pages from regular members?
        </b>
        <Tooltip
          title="Those pages will be hidden from regular members in the siderbar."
        >
          <InfoIcon />
        </Tooltip>
      </div>

      <SettingsCheckbox
        title={"Reports section"}
        checked={hiddenPageChecked('reports')}
        onChange={getHiddenPageOnChangeHandler('reports')}
      />

      <SettingsCheckbox
        title={"Team section"}
        checked={hiddenPageChecked('team')}
        onChange={getHiddenPageOnChangeHandler('team')}
      />

      <SettingsCheckbox
        title={"Clients section"}
        checked={hiddenPageChecked('clients')}
        onChange={getHiddenPageOnChangeHandler('clients')}
      />

      <SettingsCheckbox
        title={"Projects section"}
        checked={hiddenPageChecked('projects')}
        onChange={getHiddenPageOnChangeHandler('projects')}
      />

      <Divider />

      <SettingsCheckbox
        title="Allow time logs overlapping?"
        info="Check this one to allow team members to have two or more separate entries during the same time period."
        checked={formik.values.timeLogsOverlapping}
        onChange={(e) => {
          formik.setFieldValue('timeLogsOverlapping', e.target.checked)
        }}
      />

      <br />

      <SettingsCheckbox
        title="Is description mandatory for every time log?"
        info="Check this one to enforce team members to fill in description field for all the time logs."
          checked={formik.values.timeLogDescriptionMandatory}
          onChange={(e) => {
            formik.setFieldValue('timeLogDescriptionMandatory', e.target.checked)
          }}
      />

      <br />

      <SettingsCheckbox
        title="Is tag presence mandatory for every time log?"
        info="Check this one to enforce team members to add at least one tag per time log."
        checked={formik.values.timeLogTagsMandatory}
        onChange={(e) => {
          formik.setFieldValue('timeLogTagsMandatory', e.target.checked)
        }}
      />

      <br />

      <SettingsCheckbox
        title="Allow adding time entries for the future days?"
        info=""
        checked={formik.values.allowFutureLogs}
        onChange={(e) => {
          formik.setFieldValue('allowFutureLogs', e.target.checked)
        }}
      />

      <br />

      <div style={{ 
        marginTop: '20px',
        display: 'flex', 
        justifyContent: 'flex-end' 
      }}>
        <Button
          type={formik.dirty ? 'primary' : 'default'}
          htmlType="submit"
          disabled={!formik.dirty || formik.isSubmitting}
          loading={formik.isSubmitting}
          onClick={() => {
            formik.submitForm()
          }}
        >
          Update
        </Button>
      </div>
    </Form>
  )
}

export default WorkspacePermissionsForm
