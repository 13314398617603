import { useMutation, useQueryClient } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiTask } from "../../api-client/types"
import { TApiGenericError } from "../../timenotes-query/timenotes-query.types"
import usePeriodModel from "../hooks/use-period-model"
import { removeUndefinedValues } from "../../utils/remove-values"

interface Args {
  periodId: string
  usersAccountIds: string[]
  decision: 'approve' | 'reject' | 'cancel' | 'withdraw' | 'submit' | 'resubmit' 
  note?: string
}

interface UserActionArgs {
  periodId: string
  usersAccountId: string
  decision: Args['decision']
  note?: string
}

interface Result {
}

export default function useApprovalRequestsBulkUpdateStatusMutation(options?: any) {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  const {
    serializePeriod,
    parsePeriod,
  } = usePeriodModel()

  const query = useMutation<Result, TApiGenericError<TApiTask>, Args>(
    ['approval-requests', 'bulk-update'],
    async (args: Args) => {

      const requestParams = removeUndefinedValues({
        bulk: {
          decision: args.decision,
          usersAccountIds: args.usersAccountIds,
          note: args.note,
        }
      })

      const response = await apiClient.post(`/approvals/periods/${args.periodId}/requests/bulk_apply`, requestParams)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        // period: parsePeriod(response.approvalsPeriod) TODO: start receiving those
      }
    },
    {
      ...options,
      onSuccess: (data: Result, args: Args) => {
        queryClient.invalidateQueries('approval-request-groups', { exact: false })
        queryClient.invalidateQueries('approval-requests', { exact: false })
        queryClient.invalidateQueries('approval-request', { exact: false })

        if (options.onSuccess) {
          options.onSuccess(data, args)
        }
      },
    },
  )

  const examineUserApprovalRequest = ({ usersAccountId, periodId, decision, note }: UserActionArgs) => {
    return query.mutate({ 
      periodId,
      usersAccountIds: [usersAccountId],
      decision: decision,
      note: note,
    })
  }

  const bulkExamineUserApprovalRequests = ({ usersAccountIds, periodId, decision, note }: Args) => {
    return query.mutate({ 
      periodId,
      usersAccountIds: usersAccountIds,
      decision: decision,
      note: note,
    })
  }

  return {
    approvalRequestBulkUpdateStatusMutation: query,
    examineUserApprovalRequest,
    bulkExamineUserApprovalRequests,
  }
}