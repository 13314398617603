import React, {useEffect} from 'react'
import LoginForm from '../../components/login-form'
import { Card, Row, Col, Alert } from "antd";
import { useHistory } from 'react-router-dom'
import { backgroundStyle } from '../styles'
import { usePeekedInvitation } from 'hooks/confirm-invitations';
import useApiClient from 'hooks/useApiClient';

const Login = () => {
  // @ts-ignore
  const theme = 'light'
  const history = useHistory()
  const apiClient = useApiClient()

  const {
    peekedInvitation,
  } = usePeekedInvitation()

  // Always logout user on visiting (mounting) the login page if peeked invitation exists
  useEffect(() => {
    if (peekedInvitation) {
      apiClient.removeAccessCredentials()
    }
  }, [])

  return (
    <div className="h-100" style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card>
              <div className="my-4">
                <div className="text-center">
                  <img className="img-fluid" src={`/img/${theme === 'light' ? 'logo.png': 'logo-white.png'}`} alt="" />
                  <h3>Sign in</h3>
                  <p>Don't have an account yet?{" "}
                    <a
                      onClick={() => {
                        history.push('/auth/register')
                      }}
                    >
                      Sign Up
                    </a>
                  </p>

                  { peekedInvitation && (
                    <Alert 
                      style={{ 
                        marginBottom: '30px',
                      }}
                      message={`Sign in to ${peekedInvitation.email} account in order to accept the invitation to workspace "${peekedInvitation.accountName}"`}
                    />
                  )}


                </div>
                <Row justify="center">
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <LoginForm />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Login