import React from 'react'
import { Card, Steps, Step } from 'components/base'

export enum NewIntegrationStep {
  Authentication,
  SelectWorkspace,
  SelectProjects,
  BrowserExtension,
}

const NewIntegrationStepsView = ({ currentStep = NewIntegrationStep.Authentication }): JSX.Element => {
  return (
    <Card>
      <Steps current={currentStep}>
        <Step title="Authentication" />
        <Step title="Select workspace" />
        <Step title="Select projects" />
        <Step title="Browser Extension" />
      </Steps>
    </Card>
  )
}

export default NewIntegrationStepsView