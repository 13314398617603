import { UseQueryOptions, useQuery } from "react-query"
import { useApiClient } from "../api-client/api-client.hooks"
import { TApiGenericError } from "../timenotes-query/timenotes-query.types"
import { TApiPermissionMap, TApiPermissionObject } from "../api-client/permissions-api"

type TParams = {
  projectId?: string
}

type TResult = {
  permissions: TApiPermissionMap
}

type TError = TApiGenericError<{}>

export const useCurrentProjectsPermissionsQuery = ({
  params,
  options,
}: {
  params: TParams,
  options?: UseQueryOptions<TResult, TError>
} = { 
  params: {},
  options: {},
}) => {
  const apiClient = useApiClient()

  const query = useQuery<TResult, TError>(
    [`permissions/project/${params.projectId}}`] as const,
    async () => {
      const response = await apiClient.getCurrentProjectPermissions({ projectId: params.projectId })

      if (!response.ok) {
        throw response.errors
      }

      return {
        permissions: getPermissionsMapFromArray(response.permissions)
      }
    },
    {
      enabled: !!params.projectId,
      ...options,
      staleTime: options?.staleTime || 5 * 60 * 1000,
    }
  )

  return query
}

export const usePermissionsQuery = ({
  params,
  options,
}: {
  params: any,
  options?: UseQueryOptions<TResult, TError>
} = { 
  params: {},
  options: {},
}) => {
  const apiClient = useApiClient()

  const query = useQuery<TResult, TError>(
    [`permissions/workspace`] as const,
    async () => {
      const response = await apiClient.getAllPermissions()

      if (!response.ok) {
        throw response.errors
      }

      return {
        permissions: getPermissionsMapFromArray(response.permissions)
      }
    },
    {
      ...options,
      staleTime: options?.staleTime || Infinity,
    }
  )

  return query
}


export const getPermissionsMapFromArray = (data: Array<TApiPermissionObject>) => {
  return (data || []).reduce((hash, permissionObject) => {
    hash[permissionObject.label || permissionObject.name] = !!permissionObject?.can

    return hash as TApiPermissionMap
  }, {} as TApiPermissionMap)
}