import React, { useState } from 'react'
import _map from 'lodash/map'
import styled from 'styled-components'
import _isEmpty from 'lodash/isEmpty'
import { useMembersGroupsQuery } from '@timenotes/shared/src/services/members-groups/queries/use-members-groups-query'
import { useUsersAccountsForFiltersQuery } from '@timenotes/shared/src/services/users-accounts/queries/use-users-accounts-for-filters-query'
import FilterInput from './filter-input'
import RecordsSelect, { SelectAllLink } from '../records-select'
import { TApiUsersAccount } from 'services/api-client/types'

export interface TeamFilterProps {
  style?: React.CSSProperties
  width?: string
  values?: TTeamFilterValues
  onChange?(values: TTeamFilterValues): void 
}

export type TTeamFilterValues = {
  memberHashIds: string[]
  groupHashIds: string[]
}

const Wrapper = styled.div`
  .ant-popover {
    width: 250px;
  }
`

type TeamOption = {
  name: string
  resourceKey: string
}

const membersToOptions = (members: TApiUsersAccount[]) => {
  return _map(members, (member) => {
    return {
      value: {
        resourceKey: `member#${member.hashId}`,
        name: `${member.firstName} ${member.lastName}`,
      }
    }
  })
}

const TeamFilter = ({
  values,
  width,
  onChange,
 }: TeamFilterProps): JSX.Element => {

  const [showInactive, setShowInactive] = useState(false)

  const membersGroupsQuery = useMembersGroupsQuery({ 
    perPage: 1000,
  })
  const groups = membersGroupsQuery.data?.membersGroups || []

  const usersAccountsQuery = useUsersAccountsForFiltersQuery({ 
    perPage: 1000,
  })
  const members = (usersAccountsQuery.data?.usersAccounts || []) as TApiUsersAccount[]
  const inactiveMembersOptions = usersAccountsQuery.data?.inactiveOptions

  const membersOptions = membersToOptions(members.filter((m) => !!m.active))

  const groupsOptions = _map(groups, (group) => {
    return {
      value: {
        resourceKey: `group#${group.hashId}`,
        name: group.name,
      }
    }
  })

  const handleOnChange = (items: TeamOption[]): void => {
    if (!onChange) {
      return
    }

    let newTeamFilterValues: TTeamFilterValues = {
      memberHashIds: [],
      groupHashIds: [],
    }

    items.forEach((item) => {
      const splitValue = item.resourceKey.split("#")

      const key = splitValue[0]
      const id = splitValue[1]

      if (key == 'member') {
        newTeamFilterValues.memberHashIds.push(id)
      } else {
        newTeamFilterValues.groupHashIds.push(id)
      }
    })

    onChange(newTeamFilterValues)
  }

  const isLoading = usersAccountsQuery.isLoading || membersGroupsQuery.isLoading
  const selectedCount = ((values?.memberHashIds || []).length + (values?.groupHashIds || []).length)

  const inactiveUsersOption = !showInactive ? {
    label: <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div>
        {inactiveMembersOptions?.length} Inactive members hidden
      </div>
      <div>
        <SelectAllLink
          onClick={() => setShowInactive(true)}
        >
          Add to search
        </SelectAllLink>
      </div>
    </div>,
    options: []
  } : {
    label: "Inactive members",
    options: membersToOptions((inactiveMembersOptions || []).map((option) => option.value) as TApiUsersAccount[])
  }

  const usersOption = !usersAccountsQuery.data?.usersAccounts ? [] : [{
    label: 'Members',
    allFilters: true,
    options: membersOptions,
  }]

  const groupsOption = !membersGroupsQuery.data?.membersGroups ? [] : [{
    label: "Groups",
    allFilters: true,
    options: groupsOptions,
  }]

  const allOptions = [
    ...usersOption, 
    ...groupsOption,
    ...((inactiveMembersOptions || []).length > 0 ? [inactiveUsersOption] : [])
  ]

  const selectedItems = allOptions.flatMap((optionGroup) => optionGroup.options).flatMap((option) => option.value).filter((value) => {
    const splitValue = value.resourceKey.split("#")

    const key = splitValue[0]
    const hashId = splitValue[1]

    if (key == 'member') {
      return (values?.memberHashIds || []).includes(hashId)
    } else {
      return (values?.groupHashIds || []).includes(hashId)
    }
  }) 

  return (
    <FilterInput
      placeholder="Team"
      count={selectedCount}
    >
      <RecordsSelect<TeamOption>
        open={true}
        loading={isLoading}
        disabled={isLoading}
        style={{
          width: '300px',
        }}
        valueProp='resourceKey'
        labelProp='name'
        searchProp='name'
        placeholder='Member or Group'
        showSearch={true}
        mode='multiple'
        options={allOptions}

        onChange={handleOnChange as any}
        value={selectedItems as any}

        autoFocus={true}
        dropdownMatchSelectWidth={true}
        getPopupContainer={(node) => {
          return node
        }}
        filterInputMode={true}
      />
      <div className="options-wrapper"></div>
    </FilterInput>
 
  )
}

export default TeamFilter