import { TApiClient, TApiParams, TApiResponse, TApiProject } from "./types"
import BaseApi, { getQueryString } from './base'

export interface IGetIntegrationAccountProjectsAllResponse extends TApiResponse {
  projects: Array<TApiProject>
}

export interface bulkCreateExternalIntegrationsAccountProjectsParams extends TApiParams {
  projects: Array<object> // name originIdentifier additionalDetails
}

class IntegrationsAccountProjectsApi extends BaseApi {
  async getIntegrationsAccountProjectsAll (integrationAccountId: string): Promise<IGetIntegrationAccountProjectsAllResponse> {
    let response = await fetch(`${this.rootUrl}/integration_accounts/${integrationAccountId}/projects/all`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async getIntegrationsAccountProjectsAllWithExternal (integrationAccountId: string): Promise<IGetIntegrationAccountProjectsAllResponse> {
    let response = await fetch(`${this.rootUrl}/integration_accounts/${integrationAccountId}/projects/all_with_external`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async bulkCreateExternalIntegrationsAccountProjects (params: bulkCreateExternalIntegrationsAccountProjectsParams) {
    let response = await fetch(`${this.rootUrl}/projects`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        projects: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }
}

export default IntegrationsAccountProjectsApi
