import { Card, message, Skeleton } from "antd"
import PageHeader from "components/layout/page-header"
import { useState } from "react"
import { useAbsencesNavigationLinks } from "./shared"
import { useAbsenceRequests } from "hooks/absences"
import moment, { Moment } from "moment-timezone"
import useNoInitialEffect from "hooks/useNoInitialEffect"
import AbsencesFilters from "pages/absences/components/absences-filters"
import AbsenceRequestModal from "pages/absences/components/absence-request-modal"
import AbsencesModalTable from "pages/absences/components/absences-modal-table"
import MonthPicker from "components/base/month-picker"
import { usePagePermissionsCheck } from "hooks/permissions.hooks"
import { TApiPeriod } from "@timenotes/shared/src/services/api-client/reports-api"
import { useAbsenceRequestsExportColumns } from "@timenotes/shared/src/services/absences/queries/use-absence-requests-export-columns"
import useAbsencesRequestsExport from "./hooks/use-absences-requests-export"

const DATE_FORMAT = 'YYYY-MM-DD'

const AbsenceRequestsPage = (props: any) => {

  useAbsencesNavigationLinks(props.history)

  usePagePermissionsCheck('manageAbsenceRequests')

  const [newAbsenceRequestModalVisible, setNewAbsenceRequestModalVisible] = useState(false)

  const [filtersMonth, setFiltersMonth] = useState<string>(moment().format())
  const filtersMonthMoment = moment(filtersMonth)

  const setFiltersMonthMoment = (momentMonth: Moment) => {
    setFiltersMonth(momentMonth.format(DATE_FORMAT))
  }

  const {
    absenceRequests,
    pagination,
    fetchAbsenceRequests,
    loading,
    errors,
    filters,
    setFilters,
    filtersPeriod,
    setFiltersPeriod,
    setPaginationCurrentPage
  } = useAbsenceRequests({
    filters: {
      period: momentToMonthPeriod(filtersMonthMoment)
    }
  })

  const {
    absenceRequestsExportButtonItem,
    ExportAbsenceRequestsModal,
  }= useAbsencesRequestsExport({
    filters: filters
  })

  useNoInitialEffect(() => {
    setFiltersPeriod(momentToMonthPeriod(filtersMonthMoment))
  }, [filtersMonth])

  return (
    <div>
      <div className="page-content">
        <PageHeader
          title="Absence Requests"
          buttons={[
            {
              title: "Add absence",
              type: 'primary',
              callback: () => {
                setNewAbsenceRequestModalVisible(true)
               }
            },
            absenceRequestsExportButtonItem
          ]
          }
          customHeader={(
            <div
              style={{ display: 'flex', gap: '10px' }}
            >
              <MonthPicker
                value={filtersMonthMoment}
                onChange={(newMoment) => setFiltersMonthMoment(newMoment as Moment)}
              />
           </div>
          )}
        />

        <Card>
          <AbsencesFilters 
            filters={filters} 
            onChange={setFilters}
          />
       </Card>

        <div style={{ height: '25px' }} />

        {loading ? (
          <Card>
            <Skeleton />
            <Skeleton />
          </Card>
        ) : (
          <AbsencesModalTable 
            absences={absenceRequests}
            pagination={pagination}
            onPaginationCurrentPageChange={setPaginationCurrentPage}
            refresh={fetchAbsenceRequests}
          />
        )}

        <AbsenceRequestModal 
          visible={newAbsenceRequestModalVisible}
          onCancel={() => { 
            setNewAbsenceRequestModalVisible(false)
          }}
          onSubmit={() => {
            fetchAbsenceRequests()
            setNewAbsenceRequestModalVisible(false)
          }}
        />
      </div>
      {ExportAbsenceRequestsModal}
    </div>
  )
}

const momentToMonthPeriod = (momentDate: Moment): TApiPeriod => {
  return {
    from: momentDate.startOf('month').format(DATE_FORMAT),
    to: momentDate.endOf('month').format(DATE_FORMAT),
  }
}

export default AbsenceRequestsPage