import { NAV_TYPE_TOP } from 'consts/theme'
import utils from 'utils'
import MenuContent from './MenuContent'

export const TopNav = ({topNavColor, toggleMobileNav, localization = true}) => {
	const props = { topNavColor, localization, toggleMobileNav }
	return (
		<div className={`top-nav ${utils.getColorContrast(topNavColor)}`} style={{backgroundColor: topNavColor}}>
			<div className="top-nav-wrapper">
				<MenuContent
					type={NAV_TYPE_TOP} 
					{...props}
				/>
			</div>
		</div>
	)
}

export default TopNav
