const Cold = (props: React.ComponentProps<"svg">) => (
    <svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props} >
        <g id="Holidays-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="9.2.3-Create-absence-type-" transform="translate(-309.000000, -390.000000)">
                <g id="Group" transform="translate(216.000000, 284.000000)">
                    <g id="icon-/-application-/-dark-/-icon-1-copy-10" transform="translate(91.666667, 104.000000)">
                        <g id="baseline-ac_unit-24px">
                            <polygon id="Shape" fill="#474D55" fill-rule="nonzero" points="22 11 17.83 11 21.07 7.76 19.66 6.34 15 11 13 11 13 9 17.66 4.34 16.24 2.93 13 6.17 13 2 11 2 11 6.17 7.76 2.93 6.34 4.34 11 9 11 11 9 11 4.34 6.34 2.93 7.76 6.17 11 2 11 2 13 6.17 13 2.93 16.24 4.34 17.66 9 13 11 13 11 15 6.34 19.66 7.76 21.07 11 17.83 11 22 13 22 13 17.83 16.24 21.07 17.66 19.66 13 15 13 13 15 13 19.66 17.66 21.07 16.24 17.83 13 22 13"></polygon>
                            <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Cold