import { Card, Modal, Skeleton, message } from "antd"
import PageHeader from "components/layout/page-header"
import { useState } from "react"
import { useTopNavigationLinks } from "hooks/layout"
import { useClients } from "hooks/clients"
import ClientsTable from "pages/clients/components/clients-table"
import ClientForm from "pages/clients/components/client-form"
import { useGetPagePermissions } from "hooks/permissions.hooks"
import useEnsureUserCanDisplayPage from "services/pages/use-ensure-user-can-display-page"

const DATE_FORMAT = 'YYYY-MM-DD'

const ClientsPage = (props: any) => {
  useTopNavigationLinks([{
    label: 'CLIENTS',
    urlMatcher: "clients",
    onClick: () => {},
  }])

  const [newItemModalVisible, setNewItemModalVisible] = useState(false)

  const [statusFilter, setStatusFilter] = useState<string>('active')
  const { clients, loading, fetchClients, deleteClient } = useClients()

  useEnsureUserCanDisplayPage('clients')
  const { permissions, permissionsLoaded } = useGetPagePermissions()

  const pageLoading = loading || !permissionsLoaded

  return (
    <div className="page-content" style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: '800px', width: '100%' }}>
        <PageHeader
          title="Clients"
          buttons={[
            {
              title: "Add client",
              disabled: !permissions.createClients,
              disabledTooltip: 'You have no permissions to create clients!',
              type: 'primary',
              callback: () => {
                setNewItemModalVisible(true)
              }
            }
          ]}
       />

        <div style={{ height: '25px' }} />

        { pageLoading? (
          <Card>
            <Skeleton />
            <Skeleton />
          </Card>
        ) : (
          <ClientsTable
            clients={clients}
            refresh={fetchClients}
          />
        )}

        <Modal
          title={"Add client"}
          destroyOnClose={true}
          visible={newItemModalVisible}
          onCancel={() => { setNewItemModalVisible(false) }}
          footer={false}
        >
          <ClientForm
            onSuccess={(client) => {
              setNewItemModalVisible(false)
              message.success('New client added!')
              fetchClients()
            }}
          />
        </Modal>
      </div>
    </div>
  )
}

export default ClientsPage
