import PageHeader from "components/layout/page-header"
import useApprovalsNavigation from "./hooks/use-approvals-navigation"
import { DatePicker, Divider } from "antd"
import PeriodsTable from "pages/approvals/components/periods-table"
import { useMemo } from "react"
import moment, { Moment } from "moment-timezone"
import useSearchParams from "hooks/use-search-params"

export default function PeriodsPage() {
  useApprovalsNavigation()

  const [searchParams, setSearchParams] = useSearchParams<{ periodsYear?: string }>()

  const periodsYear = useMemo(() => moment.utc(searchParams.periodsYear), [searchParams.periodsYear])
  const setPeriodsYear = (date: Moment) => {
    setSearchParams({
      periodsYear: date.format('YYYY')
    })
  }

  return (
    <>
      <div className="page-content">
        <PageHeader
          title="Periods Management"
          customHeader={(
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                gap: '15px',
                width: '100%',
              }}
            >
              <DatePicker.YearPicker 
                value={periodsYear}
                onChange={setPeriodsYear}
                allowClear={false}
              />
            </div>
          )}
        />
        <p>
          By opening and closing periods you can control if team members can log time on a certain date and depending on the approval process status.
        </p>

        <Divider />

        <PeriodsTable 
          periodsYear={periodsYear}
        />
      </div>
    </>
  )
}