const Remote = (props: React.ComponentProps<"svg">) => (
    <svg width="23px" height="14px" viewBox="0 0 23 14" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <defs>
            <polygon id="path-1" points="0 0 24 0 24 24 0 24"></polygon>
        </defs>
        <g id="Holidays-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="9.2.3-Create-absence-type-" transform="translate(-308.000000, -297.000000)">
                <g id="Group" transform="translate(216.000000, 284.000000)">
                    <g id="Icon-/-application-/-dark-/-remote" transform="translate(91.666667, 8.000000)">
                        <g id="baseline-weekend-48px">
                            <mask id="mask-2" fill="white">
                                <use xlinkHref="#path-1"></use>
                            </mask>
                            <g id="a"></g>
                            <path d="M21,10 C19.895,10 19,10.895 19,12 L19,15 L5,15 L5,12 C5,10.895 4.105,10 3,10 C1.895,10 1,10.895 1,12 L1,17 C1,18.1 1.9,19 3,19 L21,19 C22.1,19 23,18.1 23,17 L23,12 C23,10.895 22.105,10 21,10 Z M18,5 L6,5 C4.9,5 4,5.9 4,7 L4,9.155 C5.16,9.57 6,10.67 6,11.97 L6,14 L18,14 L18,11.97 C18,10.67 18.84,9.57 20,9.155 L20,7 C20,5.9 19.1,5 18,5 Z" id="Shape" fill="#474D55" fill-rule="nonzero" mask="url(#mask-2)"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
export default Remote