import { useEffect, useRef, useState } from 'react'
import { Field, Formik, FormikProps, FieldProps } from 'formik'
import { Button, Card, Checkbox, Form, Input, Select, Spin } from 'antd'
import {toTitleCase} from 'utils/string-utils'
import FormItem from 'antd/lib/form/FormItem'
import {useTaskParents} from 'services/timenotes/tasks/tasks.hooks'
import {map} from 'lodash'
import ErrorMessage from 'components/base/error-message'
import { ICreateTaskParams } from '@timenotes/shared/src/services/api-client/tasks-api'

interface IProps {
  projectId: string
  name?: string
  projectOrigin: string
  taskCreationPolicy: string // 'internal' | 'external_mandatory' | 'external_optional'
  onFormSubmit(values: ICreateTaskParams): any
}

const TaskForm = (props: IProps) => {
  const formRef = useRef(null)

  const defaultExternalTaskType = props.projectOrigin != 'basecamp' ? null : 'to_do'
  const [externalTaskType, setExternalTaskType] = useState<null | string>(defaultExternalTaskType)

  const [externalParentIdOptions, setExternalParentIdOptions] = useState<any[]>([])

  const {
    taskParents,
    taskParentsLoading,
    getTaskParents,
    taskParentsError,
  } = useTaskParents()

  const isExternalProject = props.projectOrigin != 'timenotes'

  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      document.querySelector('input.autofocus')?.focus()
    }, 100)

    if (isExternalProject) {
      getTaskParents({ projectId: props.projectId, taskType: externalTaskType})
    }
  }, [])

  useEffect(() => {
    const externalParentIdOptionsArray = map(taskParents || [], (taskParent: any) => {
      return {
        value: taskParent.id,
        label: taskParent.name,
      }
    })

    const formik = formRef.current

    if (formik && formik.values.externalParentId == "" && externalParentIdOptionsArray.length > 0) {
      formik.setFieldValue('externalParentId', externalParentIdOptionsArray[0].value)
    }

    setExternalParentIdOptions(externalParentIdOptionsArray)
  }, [taskParents])

  useEffect(() => {
    // Clear external parent id as previous one is no longer valid
    formRef.current?.setFieldValue('externalParentId', '')
    getTaskParents({ projectId: props.projectId, taskType: externalTaskType})
  }, [externalTaskType])

  useEffect(() => {
    if (!!taskParentsError) {
      formRef.current?.setFieldValue('createExternal', false)
    }
  },[taskParentsError])

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={false}
      initialValues={{
        name: props.name || "",
        createExternal: (props.taskCreationPolicy == 'external_mandatory'),
        externalParentId: "",
        externalTaskType: externalTaskType,
      }}

      onSubmit={(values) => {
        // Send only name if it's timenotes internal project and all external info 
        // for the integration projects
        const sendValues = ((!isExternalProject || values.createExternal == false)? 
          {
            name: values.name
          } : 
          {
            name: values.name,
            createExternal: values.createExternal,
            additionalDetails: {
              parentId: `${values.externalParentId}`,
              taskType: values.externalTaskType,
            }
          }
        )

        const apiCall = props.onFormSubmit(sendValues)

        apiCall.then((response: any) => {
          // @ts-ignore
          if (response.ok) {
            // handled outside
          } else {
            // @ts-ignore
            const errorsArray = Object.keys(response.errors).map(key => {
              return {
                name: key,
                // @ts-ignore
                errors: response.errors[key],
              }
            })

            const errorsMap = {}
            errorsArray.forEach((errorField) => {
              // @ts-ignore
              errorsMap[errorField.name] = errorField.errors.join(' ')
            })

            formRef.current?.setErrors(errorsMap)
          }
        })

        return apiCall
      }}
    >
      {(formikProps: FormikProps<any>) => (
        <Form
          layout='vertical'
          onSubmitCapture={formikProps.handleSubmit}
        >
          <Field name="name">
            {({
              field, // { name, value, onChange, onBlur }
              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
              meta,
            }: FieldProps) => (
              <Input className={'autofocus'} type="text" name="name" placeholder='Enter the name of the new task..' 
                onChange={field.onChange('name')}
                value={formikProps.values.name}
              />
            )}
          </Field>
          <ErrorMessage msg={formikProps.errors.name as string || ""} />

          { isExternalProject && taskParentsError && (
            <>
              <br/>
              <br/>
              <Card>
                <span style={{ color: 'red' }}>Can not fetch integration data!</span>
              </Card>
            </>
          )}

          { isExternalProject && !taskParentsError && (
            <>
            <Checkbox 
              style={{
                marginTop: '18px',
              }}
              disabled={props.taskCreationPolicy == 'external_mandatory' || props.taskCreationPolicy == 'internal'}
              checked={formikProps.values.createExternal}
              onChange={(e) => {
                formikProps.setFieldValue('createExternal', e.target.checked)
              }}
            >
              Create task also in {toTitleCase(props.projectOrigin)} 
              { props.taskCreationPolicy == 'external_mandatory' && " - required by project policy" }
              { props.taskCreationPolicy == 'internal' && " - only Timenotes internal by project policy" }
            </Checkbox>

              { formikProps.values.createExternal && (
                <>
                  <br />
                  <br />

                  { props.projectOrigin == 'basecamp' && (

                    <FormItem
                      label="Task type"
                    >
                      <Select
                        value={formikProps.values.externalTaskType}
                        onChange={(value) => {
                          formikProps.setFieldValue('externalTaskType', value)
                          setExternalTaskType(value)
                        }}
                      >
                        <Select.Option value="to_do">To-do list</Select.Option>
                        <Select.Option value="card">Card table</Select.Option>
                      </Select>
                    </FormItem>
                  )}

                  <FormItem
                    label="Section"
                  >
                    { taskParentsLoading ? (
                      <Card
                        style={{
                          padding: '0px',
                        }}
                      >
                        <Spin />
                      </Card>
                    ) : (
                      <Select
                        showSearch
                        optionFilterProp="label"
                        value={formikProps.values.externalParentId}
                        onChange={(value) => {
                          formikProps.setFieldValue('externalParentId', value)
                        }}
                        options={externalParentIdOptions}
                      />

                    )}
                  </FormItem>

                </>
              )}
            </>
          )}

          <br />
          <br />

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default TaskForm
