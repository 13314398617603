import { useEffect, useState } from "react"
import { ApiErrors, TApiClient } from "services/api-client/types"
import useApiClient from "./useApiClient"

interface IUseClients {
  fetch?: boolean
}

export const useClients = (props?: IUseClients) => {
  const apiClient = useApiClient()
  const [clients, setClients] = useState<TApiClient[]>([])
  const [errors, setErrors] = useState<ApiErrors>({})
  const [loading, setLoading] = useState(true)
  const fetchOnUse = props?.fetch === undefined ? true : !!props.fetch

  const fetchClients = () => {
    const apiCall = apiClient.getClients({}).then((response) => {
      if (response.ok) {
        setClients(response.clients)
      } else {
        setErrors(response.errors || {})
      }

      setLoading(false)
    })
  }

  const deleteClient = (id: string) => {
    return apiClient.deleteClient(id)
  }

  useEffect(() => {
    if (fetchOnUse) {
      fetchClients()
    }
  }, [])

  return {
    clients,
    errors,
    loading,
    fetchClients,
    deleteClient,
  }
}