import { UseQueryOptions, useQuery } from "react-query"
import { IGetUsersAccountsOwnedParams, IGetUsersAccountsParams } from "../../api-client/users-accounts-api"
import { TApiUsersAccount } from "../../api-client/types"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiGenericError } from "../../timenotes-query/timenotes-query.types"


interface IUseUsersAccountsQueryParams extends IGetUsersAccountsOwnedParams {}

type Result = {
  usersAccounts: TApiUsersAccount[]
  activeOptions: {
    value: TApiUsersAccount
  }[]
  inactiveOptions: {
    value: TApiUsersAccount
  }[]
}

const PER_PAGE = 50

export const useUsersAccountsForFiltersQuery = (params: IUseUsersAccountsQueryParams, options: UseQueryOptions<Result, TApiGenericError<null>> = {}) => {
  const apiClient = useApiClient()

  const query = useQuery<Result, TApiGenericError<null>>(
    ['users-accounts-for-filters', params],
    async () => {
      const response = await apiClient.getUsersAccountsForFilters({
        perPage: PER_PAGE,
        ...params,
      })

      if (!response.ok) {
        throw response.errors
      }

      const usersAccounts = response.usersAccounts || []

      // @ts-ignore // API returns active true/false
      const activeOptions = usersAccounts.filter((p) => !!p.active).map((p) => ({ value: p }))
      // @ts-ignore
      const inactiveOptions = usersAccounts.filter((p) => !p.active).map((p) => ({ value: p }))

      return {
        usersAccounts: usersAccounts,
        activeOptions: activeOptions,
        inactiveOptions: inactiveOptions,
      }
    },
    {
      ...options
    }
  )

  return query
}
