import { QueryClient, useMutation, useQueryClient } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiProject, TApiTask } from "../../api-client/types"
import { TApiGenericError, TApiGenericResponse } from "../../timenotes-query/timenotes-query.types"

interface Args {
  ids: string[]
  filters?: any
}

interface Result {
}

export const useBulkDeleteTimeLogsMutation = (params?: any ) => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  const query = useMutation<Result, TApiGenericError<TApiTask>, Args>(
    ['time-logs', 'bulk-delete'],
    async (args: Args) => {
      const response = await apiClient.bulkDeleteTimeLogs({
        bulk: {
          ids: args.ids,
        },
        filters: {}
      })

      if (!response.ok) {
        throw response.errors || {}
      }

      return {}
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('time-logs', { exact: false })

      }
    },
  )

  return query
}

export default useBulkDeleteTimeLogsMutation