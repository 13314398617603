import { Table } from 'antd'
import _map from 'lodash/map'
import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import Time from 'utils/time'
import Duration from 'utils/duration'
import { TrackerCounter } from './dashboard-summary-table'

const SimpleTimeLogsTable = (props: {
  timeLogs: any[],
  tracker: any,
}) => {

  const renderTask = (record: any) => {
    return (
      <div className="task-wrapper" style={{ borderLeft: `8px solid ${record.project.color}` }}>
        <p className="task-name"> {record.task.name}</p>
        <span className="project-name">{record.project.name}</span>
      </div>
    )
  }

  const renderDescription = (record: any) => {
    const description = (record.description == null || record.description == "") ? "-" : `"${record.description}"`

    return (
      <span>{description}</span>
    )
  }

  const renderDuration = (record: any) => {
    if (record.recordType == 'Tracker') {
      return renderActivityTracker(record)
    }
    if (record.recordType == 'TimeLog') {
      const duration = Duration.fromMinutes(record.duration)

      return (
        <span>{duration.toDurationString()}</span>
      )
    }
  }

  const renderActivityTracker = (tracker: any) => {
    const worktime = tracker.worktime || 0
    const startedAtTime = Time.fromApiDate(tracker.startedAt, tracker.timeZone)

    return (
      <TrackerCounter
        startedAt={startedAtTime}
        worktime={worktime}

        render={(counterTime) => (
          <span style={{ color: "#EE7476", fontSize: '18px' }}>{counterTime}</span>
        )}
      />
    )
  }

  const renderPeriod = (record: any) => {
    if (record.recordType == 'Tracker') {
      return
    }

    const startTime = Time.fromApiDate(record.startAt, record.timeZone)
    const endTime = Time.fromApiDate(record.startAt, record.timeZone).addMinutes(record.duration)

    return (
      <span>{startTime.toDateTime()} - {endTime.toDateTime()}</span>
    )
  }

  const getTimeLogsData = () => {
    const timeLogs = _map(props.timeLogs, (timeLog) => {
      return {
        ...timeLog,
        recordType: 'TimeLog',
      }
    })

    if (!_isEmpty(props.tracker)) {
      const trackerCopy = _cloneDeep(props.tracker)
      trackerCopy.recordType = 'Tracker'

      timeLogs.unshift(trackerCopy)
    }

    return timeLogs
  }

  const columnsConfig = [
    {
      title: 'Task',
      render: renderTask,
      className: 'task-column',
    },
    {
      title: 'Description',
      render: renderDescription,
      className: 'description-column',
    },
    {
      title: 'Duration',
      render: renderDuration,
      className: 'duration-column',
    },
    {
      title: 'Period',
      render: renderPeriod,
      className: 'period-column'
    },
  ]

  return (
    <div className="simple-time-logs-table">
      <Table
        columns={columnsConfig}
        dataSource={getTimeLogsData()}
        pagination={false}
        showHeader={false}
      />
    </div>
  )
}

export default SimpleTimeLogsTable