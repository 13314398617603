
export const toTitleCase = (str: string) => {
  return str.replace(/_/,' ').replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export const toAmountWithCurrency = (amount: string | number | null, currencyIso: string): string => {
  const floatValue = amount === null ? 0.0 : parseFloat(`${amount}`)
  return `${floatValue.toFixed(2)} ${currencyIso}`
}