import { Card } from 'antd'
import HorizontalBar from './horizontal-bar'

const DashboardBreakdown = (props: {
  segments: {
    title: string,
    value: number,
    color: string,
    tooltip: string,
  }[]
}) => {
  const segments = props.segments

  return (
    <Card className="dashboard-breakdown">
      <span className="title">Breakdown by <b>projects</b></span>

      <div className="projects-bar">
        <HorizontalBar segments={segments} displayTitle={true} />
      </div>
    </Card>
  )
}

export default DashboardBreakdown