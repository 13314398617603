import { Radio, RadioChangeEvent } from "antd"
import { FC } from "react"

interface IProps {
  value?: 'active' | 'archived'
  onChange: (value?: 'active' | 'archived') => void
  style?: any
}

export const StateFilter: FC<IProps> = ({ value, onChange, style }) => {

  const onStateValueChange = (newValue: 'all' | 'active' | 'archived') => {
    if (newValue == 'all') {
      onChange(undefined)
    } else {
      onChange(newValue)
    }
  }

  const radioValue = value === undefined ? 'all' : value

  return (
    <Radio.Group
      value={radioValue}
      style={style ?? { width: "20%", marginLeft: "30px" }}
      onChange={(e: RadioChangeEvent) => onStateValueChange(e.target.value)}
    >
      <Radio.Button value={'all'}> All </Radio.Button>
      <Radio.Button value={'active'}> Active </Radio.Button>
      <Radio.Button value={'archived'}> Archived</Radio.Button>
    </Radio.Group>
  )
}