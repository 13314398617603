const Sunny = (props: React.ComponentProps<"svg">) => (
    <svg width="23px" height="23px" viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g id="Holidays-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="9.2.3-Create-absence-type-" transform="translate(-308.000000, -356.000000)">
                <g id="Group" transform="translate(216.000000, 284.000000)">
                    <g id="icon-/-application-/-dark-/-icon-1-copy-6" transform="translate(91.666667, 72.000000)">
                        <g id="baseline-wb_sunny-24px">
                            <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                            <path d="M6.76,4.84 L4.96,3.05 L3.55,4.46 L5.34,6.25 L6.76,4.84 Z M4,10.5 L1,10.5 L1,12.5 L4,12.5 L4,10.5 Z M13,0.55 L11,0.55 L11,3.5 L13,3.5 L13,0.55 Z M20.45,4.46 L19.04,3.05 L17.25,4.84 L18.66,6.25 L20.45,4.46 Z M17.24,18.16 L19.03,19.96 L20.44,18.55 L18.64,16.76 L17.24,18.16 Z M20,10.5 L20,12.5 L23,12.5 L23,10.5 L20,10.5 Z M12,5.5 C8.69,5.5 6,8.19 6,11.5 C6,14.81 8.69,17.5 12,17.5 C15.31,17.5 18,14.81 18,11.5 C18,8.19 15.31,5.5 12,5.5 Z M11,22.45 L13,22.45 L13,19.5 L11,19.5 L11,22.45 Z M3.55,18.54 L4.96,19.95 L6.75,18.15 L5.34,16.74 L3.55,18.54 Z" id="Shape" fill="#474D55" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
export default Sunny