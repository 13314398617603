import history from '../history'

export const addQueryStringParameter = (uri: string, key: string, value: string) => {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf('?') !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + "=" + value + '$2');
  }
  else {
    return uri + separator + key + "=" + value;
  }
}

export const buildUrl = (newUrl: string, params?: any): string => {
  const urlParams = {
    // access_token: apiClient.accessToken,
    // access_account_id: apiClient.accountId,
    ...(params || {}),
  }

  let paramsString = Object.keys(urlParams).map(key => key + '=' + urlParams[key]).join('&')
  let newHref = `${newUrl}?${paramsString}`;

  return newHref
}

export const goTo = (newUrl: string, params?: any): void => {
  let newHref = buildUrl(newUrl, params)

  (window as any).location.href = newHref;
}


export default history