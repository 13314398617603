import { Button, DatePicker, Modal, ModalProps, message, Form } from "antd"
import { omit } from "lodash"
import moment from "moment-timezone"
import { useFormik } from "formik"
import AbsenceTypeSelect from "./absence-type-select"
import ErrorMessage from "components/base/error-message"
import UsersAccountSelect from "components/forms/users-account-select"
import TextArea from "antd/lib/input/TextArea"
import { useEffect, useState } from "react"
import { useDefaultDateFormat } from "hooks/settings"
import { useCurrentUser } from "hooks/current-user"
import useApiClient from "hooks/useApiClient"
import { IUpdateHolidatsAbsenceRequestParams } from "@timenotes/shared/src/services/api-client/holidays-absence-requests-api"
import { TApiAbsenceType, TApiUsersAccount } from "@timenotes/shared/src/services/api-client/types"
import { TApiTeamMember } from "@timenotes/shared/src/services/api-client/users-accounts-api"
import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"

interface AbsenceRequestModalProps extends Omit<ModalProps, 'onCancel'> {
  onCancel?(): void
  onSubmit?(values: IAbsenceRequestsFormObject): void
  initialValues?: Partial<IAbsenceRequestsFormObject>
  myAbsencesOnly?: boolean
  //absences: TApiAbsence[]
  //startDate?: Moment
  //endDate?: Moment
}

export interface IAbsenceRequestsFormObject extends IUpdateHolidatsAbsenceRequestParams { 
  id?: string
  usersAccount?: TApiUsersAccount
  absenceType?: TApiAbsenceType
}

const AbsenceRequestModal = (props: AbsenceRequestModalProps) => {
  const apiClient = useApiClient()
  //const datePeriodFormatted = props.startDate?.isSame(props.endDate, 'day') ? `${props.startDate.format("DD.MM.YYYY")}` : `${props.startDate?.format('DD.MM.YYYY')} - ${props.endDate?.format('DD.MM.YYYY')}`

  const [selectedUsersAccount, setSelectedUsersAccount] = useState<TApiTeamMember | undefined>()
  const { defaultDateFormat } = useDefaultDateFormat()

  const { currentUser } = useCurrentUser()

  const formik = useFormik<IAbsenceRequestsFormObject>({
    enableReinitialize: true,
    initialValues: {
      ...props.initialValues,
      fromDate: props.initialValues?.fromDate || moment().format("YYYY-MM-DD"),
      toDate: props.initialValues?.toDate || moment().format("YYYY-MM-DD"),
      usersAccountId: props.initialValues?.usersAccountId || (currentUser ? currentUser.id : undefined),
      usersAccount: props.initialValues?.usersAccount || currentUser,
    },
    onSubmit: (values, { setSubmitting }) => {
      const requestParams = omit(values, ['usersAccount'])

      const apiCall = props.myAbsencesOnly ? 
        (values.id ? apiClient.updateHolidaysAbsence(values.id, requestParams) : apiClient.createHolidaysAbsence(requestParams)) : 
        (values.id ? apiClient.updateHolidaysAbsenceRequest(values.id, requestParams) : apiClient.createHolidaysAbsence(requestParams)
      )

      apiCall.then((response: TApiResponse) => {
        if (response.ok) {
          props.onSubmit && props.onSubmit(values)
          formik.resetForm()
        } else {
          const errors = {
            ...response.errors,
            holidaysAbsenceTypeId: response.errors?.absenceType,
            usersAccountId: response.errors?.usersAccount,
          }

          // @ts-ignore
          formik.setErrors(errors || {})

          if (response.errors?.base) {
            message.error(getApiErrorMessage(response, 'base'))
          }

          setSubmitting(false)
        }
      })
    },
  })

  useEffect(() => {
    if (!formik.values.id && props.visible && formik.values.usersAccountId) {
      apiClient.getUsersAccountListMember(formik.values.usersAccountId).then((response) => {
        if (response.ok) {
          setSelectedUsersAccount(response.usersAccount)
        }
      })
    }
  }, [formik.values.usersAccountId, props.visible])

  let daysAvailableComment = ""

  /* TODO: Revise if this comment makes any sense
  let daysAvailableComment = !formik.values.id && "Select user and absence type to see number of days available."

  if (!formik.values.id && selectedUsersAccount && formik.values.holidaysAbsenceTypeId) {
    const absenceAllowance = find(selectedUsersAccount.absenceAllowances, (absenceAllowance) => {
      return (absenceAllowance.absenceType.id == formik.values.holidaysAbsenceTypeId)
    })

    if (absenceAllowance) {

      let daysAvailable = absenceAllowance.absenceType.limit - (absenceAllowance.used || 0)
      if (daysAvailable < 0) {
        daysAvailable = 0
      }

      daysAvailableComment = `${getUsersAccountFullName(selectedUsersAccount)} has ${daysAvailable} out of ${absenceAllowance.absenceType.limit} days available in this ${absenceAllowance.absenceType.period}`
    } else {
      daysAvailableComment = `${getUsersAccountFullName(selectedUsersAccount)} can request unlimited days for "${formik.values.absenceType?.name}" absence type.`
    }
  }
  */

  return (
    <Modal
      {...props}
      onCancel={() => {
        if (props.onCancel) {
          formik.resetForm()
          props.onCancel()
        }
      }}
      title={formik.values.id ? 'Edit Absence Request' : "New Absence Request"}
      footer={false}
      destroyOnClose={true}
    >
      <Form onSubmitCapture={formik.handleSubmit} layout="vertical">

        <Form.Item
          label="Absence type"
        >
          <AbsenceTypeSelect
            autoFocus={true}
            value={formik.values.holidaysAbsenceTypeId}
            onChange={(id, absenceType) => {
              formik.setFieldValue('holidaysAbsenceTypeId', id)
              formik.setFieldValue('absenceType', absenceType)
            }}
            initialOption={formik.values.absenceType}
            autoSelectFirstOption={true}
          />
          <ErrorMessage msg={formik.errors.holidaysAbsenceTypeId} />
        </Form.Item>

        <Form.Item
          label="Period"
        >
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            value={[moment(formik.values.fromDate), moment(formik.values.toDate)]}
            format={defaultDateFormat}
            allowClear={false}
            onChange={(newRange) => {
              // @ts-ignore
              formik.setFieldValue('fromDate', newRange[0].format('YYYY-MM-DD'))
              // @ts-ignore
              formik.setFieldValue('toDate', newRange[1].format('YYYY-MM-DD'))
            }}
          />
          <ErrorMessage msg={formik.errors.fromDate || formik.errors.toDate} />
          <ErrorMessage msg={(formik.errors as any).daysCount } />

          {!formik.values.id && (
            <span>{daysAvailableComment}</span>
          )}
        </Form.Item>


        {!props.myAbsencesOnly && (
          <Form.Item
            label="Team Member"
          >
            <UsersAccountSelect
              allowClear={false}
              style={{ width: '100%' }}
              value={formik.values.usersAccountId}
              onChange={(newUsersAccountId) => {
                formik.setFieldValue('usersAccountId', newUsersAccountId)
              }}
              initialUsersAccount={formik.values.usersAccount}
            />
            <ErrorMessage msg={formik.errors.usersAccountId} />
          </Form.Item>
        )}

        <Form.Item
          label="Comment"
        >
          <TextArea
            placeholder="Comment"
            value={formik.values.comment}
            onChange={(e) => {
              formik.setFieldValue('comment', e.target.value)
            }}
          />
          <ErrorMessage msg={formik.errors.comment} />
        </Form.Item>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="primary"
          loading={formik.isSubmitting}
          onClick={() => {
            formik.submitForm()
          }}
        >
          {formik.values.id ? "Update" : "Create"}
        </Button>
      </div>

      </Form>
    </Modal>

  )
}

export default AbsenceRequestModal