import moment from "moment-timezone"
import { useEffect, useState } from "react"
import { useCurrentUser } from "./current-user"
import useApiClient from "./useApiClient"
import { TApiHolidaysAbsencesFilters, IGetHolidaysAbsencesParams } from "@timenotes/shared/src/services/api-client/holidays-absences-api"
import { ApiErrors, TApiAbsence, TApiAbsenceType, TApiPagination } from "@timenotes/shared/src/services/api-client/types"
import { TApiPeriod } from "@timenotes/shared/src/services/api-client/reports-api"
import { IGetHolidaysAbsenceRequestsResponse, TApiAbsenceRequest, TApiHolidaysAbsenceRequestsFilters } from "@timenotes/shared/src/services/api-client/holidays-absence-requests-api"
import { TApiFreeDay, TApiHolidaysFreeDaysFilters } from "@timenotes/shared/src/services/api-client/holidays-free-days-api"

export const useAbsences = ({ filters }: {
  filters: TApiHolidaysAbsencesFilters
}) => {
  const apiClient = useApiClient()

  const [absenceRequests, setAbsenceRequests] = useState<TApiAbsence[]>([])
  const [currentPage, setPaginationCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<TApiPagination | undefined>()
  const [errors, setErrors] = useState<ApiErrors>({})
  const [loading, setLoading] = useState(true)
  const [requestFilters, setRequestFilters] = useState<TApiHolidaysAbsencesFilters>(filters)

  const setFiltersPeriod = (period: TApiPeriod) => {
    setRequestFilters({
      ...requestFilters,
      period: period,
    })
  }

  const fetchAbsenceRequests = () => {
    const apiCall = apiClient.getHolidaysAbsences({ filters: requestFilters, page: currentPage }).then((response) => {
      if (response.ok) {
        setAbsenceRequests(response.absences)
        setPagination(response.meta.pagination)
      } else {
        setErrors(response.errors || {})
      }

      setLoading(false)
    })
  }

  useEffect(() => {
    // On filters change always change pagination current page to 1 as old pagination is no longer valid
    setPaginationCurrentPage(1)
    fetchAbsenceRequests()
  }, [requestFilters])

  useEffect(() => {
    fetchAbsenceRequests()
  }, [currentPage])

  return {
    absenceRequests,
    pagination,
    fetchAbsenceRequests,
    errors,
    loading,
    setPaginationCurrentPage,
    filters: requestFilters,
    setFilters: setRequestFilters,
    filtersPeriod: requestFilters.period,
    setFiltersPeriod: setFiltersPeriod,
  }
}

export const useAbsenceRequests = ({ 
  useMyAbsences,
  filters,
  all,
 }: {
  useMyAbsences?: boolean,
  filters: TApiHolidaysAbsenceRequestsFilters
  all?: boolean
}) => {
  const apiClient = useApiClient()

  const [absenceRequests, setAbsenceRequests] = useState<TApiAbsenceRequest[]>([])
  const [currentPage, setPaginationCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<TApiPagination | undefined>()
  const [errors, setErrors] = useState<ApiErrors>({})
  const [loading, setLoading] = useState(true)
  const [requestFilters, setRequestFilters] = useState<TApiHolidaysAbsenceRequestsFilters>(filters)
  const { currentUser } = useCurrentUser()

  const setFiltersPeriod = (period: TApiPeriod) => {
    setRequestFilters({
      ...requestFilters,
      period: period,
    })
  }

  const requestParams: IGetHolidaysAbsencesParams = {
    filters: requestFilters,
    page: currentPage
  }

  if (all) {
    requestParams.perPage = 1000
  }

  const apiCallRequest = useMyAbsences ?
    async () => {
      const res = await apiClient.getHolidaysAbsences(requestParams)

      const response: IGetHolidaysAbsenceRequestsResponse = {
        absenceRequests: res.absences.map((absence) => {
          return {
            ...absence,
            usersAccount: currentUser,
          }
        }),
        meta: res.meta,
        ok: res.ok,
        status: res.status,
      }
      
      return response
    }
    :
    () => apiClient.getHolidaysAbsenceRequests(requestParams)

  const fetchAbsenceRequests = () => {
    const apiCall = apiCallRequest().then((response) => {
      if (response.ok) {
        setAbsenceRequests(response.absenceRequests)
        setPagination(response.meta.pagination)
      } else {
        setErrors(response.errors || {})
      }

      setLoading(false)
    })
  }

  useEffect(() => {
    // On filters change always change pagination current page to 1 as old pagination is no longer valid
    setPaginationCurrentPage(1)
    fetchAbsenceRequests()
  }, [requestFilters])

  useEffect(() => {
    fetchAbsenceRequests()
  }, [currentPage])

  return {
    absenceRequests,
    pagination,
    fetchAbsenceRequests,
    errors,
    loading,
    setPaginationCurrentPage,
    filters: requestFilters,
    setFilters: setRequestFilters,
    filtersPeriod: requestFilters.period,
    setFiltersPeriod: setFiltersPeriod,
  }
}

export const useAbsenceTypes = () => {
  const apiClient = useApiClient()
  const [absenceTypes, setAbsenceTypes] = useState<TApiAbsenceType[]>([])
  const [errors, setErrors] = useState<ApiErrors>({})
  const [loading, setLoading] = useState(true)

  const fetchAbsenceTypes = () => {
    const apiCall = apiClient.getHolidaysAbsenceTypes({}).then((response) => {
      if (response.ok) {
        setAbsenceTypes(response.absenceTypes)
      } else {
        setErrors(response.errors || {})
      }

      setLoading(false)
    })
  }

  useEffect(() => {
    fetchAbsenceTypes()
  }, [])

  return {
    absenceTypes,
    errors,
    loading,
    fetchAbsenceTypes,
  }
}

export const useFreeDays = (props?: { filters: TApiHolidaysFreeDaysFilters }) => {
  const apiClient = useApiClient()
  const [freeDays, setFreeDays] = useState<TApiFreeDay[]>([])
  const [errors, setErrors] = useState<ApiErrors>({})
  const [loading, setLoading] = useState(true)

  const [filters, setFilters] = useState<TApiHolidaysFreeDaysFilters>(props?.filters || { year: moment().year().toString() })

  const fetchFreeDays = () => {
    const apiCall = apiClient.getHolidaysFreeDays({ filters }).then((response) => {
      if (response.ok) {
        setFreeDays(response.freeDays)
      } else {
        setErrors(response.errors || {})
      }

      setLoading(false)
    })
  }

  const deleteFreeDay = (freeDayId: string) => {
    return apiClient.deleteHolidaysFreeDay(freeDayId)
  }

  useEffect(() => {
    fetchFreeDays()
  }, [])

  useEffect(() => {
    fetchFreeDays()
  }, [filters])

  return {
    freeDays,
    errors,
    loading,
    filters,
    setFilters,
    fetchFreeDays,
    deleteFreeDay,
  }
}