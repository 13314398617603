import { Card, Modal, Skeleton } from "antd"
import PageHeader from "components/layout/page-header"
import { useState } from "react"
import { useAbsencesNavigationLinks } from "./shared"
import { ZoomInOutlined } from '@ant-design/icons'
import { useAbsences } from "hooks/absences"
import moment, { Moment } from "moment-timezone"
import useNoInitialEffect from "hooks/useNoInitialEffect"
import { TApiPeriod } from "services/api-client/reports-api"
import AbsencesFilters from "pages/absences/components/absences-filters"
import AbsenceRequestModal from "pages/absences/components/absence-request-modal"
import AbsencesModalTable from "pages/absences/components/absences-modal-table"
import CurrentAllowanceTable from "pages/absences/components/current-allowance-table"
import MonthPicker from "components/base/month-picker"

const DATE_FORMAT = 'YYYY-MM-DD'

const MyAbsences = (props: any) => {
  useAbsencesNavigationLinks(props.history)

  const [newAbsenceRequestModalVisible, setNewAbsenceRequestModalVisible] = useState(false)
  const [allowanceModalVisible, setAllowanceModalVisible] = useState(false)

  const [filtersMonth, setFiltersMonth] = useState<string>(moment().format())
  const filtersMonthMoment = moment(filtersMonth)

  const setFiltersMonthMoment = (momentMonth: Moment) => {
    setFiltersMonth(momentMonth.format(DATE_FORMAT))
  }

  const { 
    absenceRequests,
    pagination,
    fetchAbsenceRequests,
    loading,
    errors,
    filters,
    setFilters,
    filtersPeriod,
    setFiltersPeriod,
    setPaginationCurrentPage 
  } = useAbsences({
    filters: {
      period: momentToMonthPeriod(filtersMonthMoment)
    }
  })

  useNoInitialEffect(() => {
    setFiltersPeriod(momentToMonthPeriod(filtersMonthMoment))
  }, [filtersMonth])

  return (
    <div>
      <div className="page-content">
        <PageHeader
          title="My Absences"
          buttons={[
            {
              title: "Check allowance",
              type: 'default',
              icon: ZoomInOutlined,
              callback: () => {
                setAllowanceModalVisible(true)
              }
            },
            {
              title: "Add absence",
              type: 'primary',
              callback: () => {
                setNewAbsenceRequestModalVisible(true)
               }
            }
          ]
          }
          customHeader={(
            <div
              style={{ display: 'flex', gap: '10px' }}
            >
              <MonthPicker
                value={filtersMonthMoment}
                onChange={(newMoment) => setFiltersMonthMoment(newMoment as Moment)}
              />
            </div>
          )}
        />

        <Card>
          <AbsencesFilters 
            myAbsencesOnly={true}
            filters={filters} 
            onChange={setFilters}
          />
       </Card>

        <div style={{ height: '25px' }} />

        {loading ? (
          <Card>
            <Skeleton />
            <Skeleton />
          </Card>
        ) : (
          <AbsencesModalTable 
            myAbsencesOnly={true}
            absences={absenceRequests}
            pagination={pagination}
            onPaginationCurrentPageChange={setPaginationCurrentPage}
            refresh={fetchAbsenceRequests}
          />
        )}

        <AbsenceRequestModal 
          myAbsencesOnly={true}
          visible={newAbsenceRequestModalVisible}
          onCancel={() => { 
            setNewAbsenceRequestModalVisible(false)
          }}
          onSubmit={() => {
            fetchAbsenceRequests()
            setNewAbsenceRequestModalVisible(false)
          }}
        />

        <Modal 
          title={"Current Allowance"}
          width="800px"
          destroyOnClose={true}
          visible={allowanceModalVisible}
          onCancel={() => { setAllowanceModalVisible(false)}}
          footer={false}
        >
          <CurrentAllowanceTable />
        </Modal>
      </div>
    </div>
  )
}

const momentToMonthPeriod = (momentDate: Moment): TApiPeriod => {
  return {
    from: momentDate.startOf('month').format(DATE_FORMAT),
    to: momentDate.endOf('month').format(DATE_FORMAT),
  }
}

export default MyAbsences