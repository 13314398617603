import { Modal } from "antd"
import React from "react"
import TimeLogForm from "./time-log-form"
import { TTimeLogFormObject, useTimeLogFormik } from "hooks/time-logs.hooks"
import { TApiTimeLog } from "@timenotes/shared/src/services/api-client/types"

interface TimeLogFormModalProps extends React.ComponentProps<typeof Modal>{
  timeLog: TTimeLogFormObject,
  onSuccess?(timeLog: TApiTimeLog): void
}

const TimeLogFormModal = (props: TimeLogFormModalProps) => {

  const handleOnCancel = () => {
    if (props.onCancel) {
      props.onCancel(null as any)
    }

    formik.resetForm()
  }

  const formik = useTimeLogFormik({
    timeLog: props.timeLog,
    onSubmit: () => {
      return true
    },
    onSuccess: (timeLog) => {
      if (props.onSuccess) props.onSuccess(timeLog)
      handleOnCancel()
    }
  })

  const handleOnOk = async () => {
    const response = await formik.submitForm()
  }

  return (
    <Modal
      destroyOnClose={true}
      title={
        <h1> {formik.values.id ? 'Edit' : 'Create' } Time Log </h1>
      }
      {...props}

      onOk={handleOnOk}
      okText={formik.values.id ? 'Update' : 'Create'}
      onCancel={handleOnCancel}
    >
      {props.timeLog && (
        <TimeLogForm
          formik={formik}
        />
      )}
    </Modal>


  )
}

export default TimeLogFormModal