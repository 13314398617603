import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from './theme'

export const APP_NAME = 'Timenotes'
export const AUTH_PREFIX_PATH = '/auth'

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR
};
