import { Alert, Button, Card, message } from "antd"
import PageHeader from "components/layout/page-header"
import ProfileForm from "pages/settings/components/profile-form"
import { useCurrentUser } from "hooks/current-user"
import useApiClient from "hooks/useApiClient"
import { useState } from "react"
import { TApiUsersAccount } from "services/api-client/types"

const SettingsProfilePage = () => {
  const { currentUser, loading, updateCurrentUser } = useCurrentUser()
  const apiClient = useApiClient()

  const [forceHideEmailConfirmationAlert, setForceHideEmailConfirmationAlert] = useState(false)

  const handleResendEmailConfirmation = async () => {
    const response = await apiClient.resendEmailConfirmation()

    if (response.ok) {
      message.success("Email confirmation email has been sent. Check your inbox to confirm your email.")
      setForceHideEmailConfirmationAlert(true)
    }
  }


  const handleSubmit = async (user: TApiUsersAccount) => {
    const response = await updateCurrentUser(user)

    if (response.ok) {
      message.success('User profile updated!')
    }

    return response
  }

  if (loading || !currentUser) {
    return null
  }

  return (
    <>
      {(!currentUser.user?.confirmedAt && !forceHideEmailConfirmationAlert )&& (
        <Alert
          message={(
            <>
              Your email is not confirmed. Please go to your inbox and confirm the email to ensure access to your account!
              <Button
                type="link"
                onClick={handleResendEmailConfirmation}
              >
                Resend confirmation email
              </Button>
            </>
          )}
          type="error"
          style={{
            margin: '20px',
            marginTop: '20px',
            marginBottom: '20px',
            textAlign: 'center',
          }}
        />
      )}

      <div
        className="page-content"
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div>
          <PageHeader
            title="Profile Settings"
          />
          <Card
            style={{ width: '450px' }}
          >
            <ProfileForm
              initialValues={currentUser}
              onSubmit={handleSubmit}
            />
          </Card>
        </div>
      </div>
    </>
  )
}

export default SettingsProfilePage