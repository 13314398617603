import { useTimeLogFormik } from "@timenotes/shared/src/services/time-logs/time-logs.hooks"
import { ColumnType } from "antd/lib/table"
import { TApiProject, TApiTimeLog, TApiUsersAccount } from "services/api-client/types"
import ProjectSelect from "../projects/project-select"
import { Badge, DatePicker, Input, Popover, Tooltip, message } from "antd"
import TaskSelect from "../tasks/task-select"
import TimeLogTaskSelect from "./time-log-task-select"
import { useEffect, useRef, useState } from "react"
import TextArea, { TextAreaRef } from "antd/lib/input/TextArea"
import { defer, isEmpty, isEqual, update } from "lodash"
import ErrorMessage from "components/base/error-message"
import TagsSelectIcon from "../tags/tags-select-icon"
import useNoInitialEffect from "hooks/useNoInitialEffect"
import { useNewTagModal } from "../tags/new-tag-modal"
import BillableFlag from "components/base/billable-flag"
import { useCurrentProjectsPermissionsQuery, usePermissionsQuery } from "@timenotes/shared/src/services/permissions/permissions.hooks"
import { useProjectQuery } from "@timenotes/shared/src/services/projects/projects.hooks"
import styled from "styled-components"
import DurationInput from "./duration-input"
import TimePicker from "./time-picker"
import moment, { Moment } from "moment-timezone"
import { useDefaultDateFormat, useDefaultTimeFormat } from "hooks/settings"
import { IUpdateFieldHistory } from '@timenotes/shared/src/services/time-logs/time-logs.hooks'
import Duration from "utils/duration"
import DatePickerPopover from "./date-picker-popover"
import useFormikGenericErrorMessage from "services/utils/hooks/use-formik-generic-error-message"
import { isOverride } from "mobx/dist/internal"
import { PlayCircleFilled, PlayCircleOutlined, StarFilled, StarOutlined, StopOutlined } from "@ant-design/icons"
import GenericButtonsList from "components/generic-buttons-list/generic-buttons-list"
import { MoreSvg } from "assets/svg/icon"
import { useBookmarkTaskMutation, useUnbookmarkTaskMutation } from "@timenotes/shared/src/services/tasks/queries/use-bookmark-task-mutation"
import useQueryGenericErrorMessage from "services/utils/hooks/use-query-generic-error-message"
import { useDeleteTimeLogMutation } from "@timenotes/shared/src/services/time-logs/queries/use-delete-time-log-mutation"
import { DeleteIcon } from "components/base/icons"
import { TApiTeamMember } from "services/api-client/users-accounts-api"
import GenericTable from "components/generic-table/generic-table"
import useStartActiveTrackerMutation from "@timenotes/shared/src/services/timer/queries/use-start-active-tracker-mutation"
import UsersAccountSelect from "../users-accounts/users-account-select"
import UsersAccountColumn from "../columns/users-account-column"

type TTimeLogFormikReturnType = ReturnType<typeof useTimeLogFormik>
type TFormik = TTimeLogFormikReturnType['formik']
type TOnSuccessCallback = (timeLog: TApiTimeLog) => void

const ContentColumnWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 4px;

  .users-account-select, .users-account-select input, .ant-select-selection-item {
    cursor: pointer !important;
  }

  .users-account-select {
    border-radius: 10px;
  }

  .users-account-select:hover {
    background-color: #eee;
  }

  .time-log-task-select-wrapper {
    flex-grow: 1;
    flex-shrink: 1;
    // width: calc(100% - 1px);

    min-width: 160px !important;

    div {
      // white-space: nowrap; /* Text won't wrap into the next line */
      //overflow: hidden;    /* Hide the overflow */
      //text-overflow: ellipsis; /* Add ellipsis for overflow */
    }
  }

  .description-column-wrapper {
    flex-grow: 1;
    flex-shrink: 1;
    //width: calc(100% - 1px);

    min-width: 120px !important;

    display: flex;

  }

  .description-column-wrapper textarea, .description-column-wrapper .description-placeholder{
    flex-grow: 1;
    min-width: 0;
  }

  .description-column-wrapper .description-placeholder {
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;

    :hover {
      background-color: #eee;
    }
  }


`

const DurationFocusWrapper = styled.div`
  cursor: pointer;
  border-radius: 4px;
  width: 110px;

  &.short {
    width: 65px;
  }

  input, .ant-select-selection-placeholder {
    font-size: 20px;
  }

  .ant-select-selection-placeholder {
    margin-top: 5px;
  }

  &:hover input:not(.active) {
    cursor: pointer;
    background-color: #eee;
  }
`

const TimePickerWrapper = styled(DurationFocusWrapper)`
  width: 100px;

  .ant-picker {
    padding: 2px 6px;
  }
`

const DescriptionFocusWrapper = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;

  textarea.ant-input-borderless {
    //width: calc(200px + 1px)
  }
`

const useTimeLogsColumns = ({
  skipTracker,
  noQueryMode,
  refetch,
}: {
  skipTracker?: boolean,
  noQueryMode?: boolean,
  refetch?(): void,
}) => {

  const permissionsQuery = usePermissionsQuery()

  // TODO: Ideally this should be returned from the backend current project permissions scope
  const canSetTimeLogUser = !!permissionsQuery.data?.permissions.setTimeLogUser
 
  const shortTags = !!skipTracker
  const enableUsersAccount = !!skipTracker

  const startTrackerMutation = useStartActiveTrackerMutation()

  useNoInitialEffect(() => {
    if (startTrackerMutation.isSuccess) {
      message.success('Tracker started successfully!')
    }
  }, [startTrackerMutation.isLoading])

  const { defaultTimeFormat, defaultTimeFormatKey } = useDefaultTimeFormat()
  const { defaultDateFormat } = useDefaultDateFormat()

  const onSuccessRef = useRef<TOnSuccessCallback>((timeLog) => {})

  const onSuccess = (timeLog: TApiTimeLog) => {
    if (onSuccessRef.current) {
      return onSuccessRef.current(timeLog)
    }
  }

  const setOnSuccess = (fn: TOnSuccessCallback) => {
    onSuccessRef.current = fn
  }

  const bookmarkMutation = useBookmarkTaskMutation()
  const unbookmarkMutation = useUnbookmarkTaskMutation()
  const deleteTimeLogMutation = useDeleteTimeLogMutation()

  useQueryGenericErrorMessage(startTrackerMutation)
  useQueryGenericErrorMessage(bookmarkMutation)
  useQueryGenericErrorMessage(unbookmarkMutation)
  useQueryGenericErrorMessage(deleteTimeLogMutation)

  useNoInitialEffect(() => {
    if (bookmarkMutation.isSuccess) {
      message.success('Task bookmarked!')
      if (refetch) refetch()
    }
  }, [bookmarkMutation.isSuccess])

  useNoInitialEffect(() => {
    if (unbookmarkMutation.isSuccess) {
      message.success('Task unbookmarked!')
      if (refetch) refetch()
    }
  }, [unbookmarkMutation.isSuccess])

  useNoInitialEffect(() => {
    if (deleteTimeLogMutation.isSuccess) {
      message.success('Time log deleted!')
      if (refetch) refetch()
    }
  }, [deleteTimeLogMutation.isSuccess])

  const { 
    formik,
    setNewDuration,
    setNewFromDate,
    setNewToDate,
    setUpdateFieldHistory,
  } = useTimeLogFormik({
    initialValues: {},
    onSuccess: (values) => {
      onSuccess(values)
      if (refetch) refetch()
    }
  })

  // Display generic error message with the first error from the backend
  useFormikGenericErrorMessage(formik)
  // and reset the formik from take over, so the API record valid values are being display again
  useEffect(() => {
    if (formik.errors) {
      formik.setValues({})
    }
  }, [formik.errors])

  const takeOverFormik = (timeLog: TApiTimeLog, updateFieldHistory?: IUpdateFieldHistory) => {
    if (formik.values.id !== timeLog.id) {
      formik.setValues({
        ...timeLog,
        from: moment.utc(timeLog.startAt),
        to: moment.utc(timeLog.startAt).add(timeLog.duration, 'm'),
      })

      // Set custom update field history or clear it during take over
      if (updateFieldHistory) {
        setUpdateFieldHistory(updateFieldHistory)
      } else {
        setUpdateFieldHistory({
          lastUpdateField: undefined,
          previousLastUpdateField: undefined,
        })
      }
    }
  }

  const columns: ColumnType<TApiTimeLog>[] = [
    {
      title: '',
      width: '100%',
      render: (_, record) => {
        return (
          <ContentColumnWrapper>
            {ProjectsTask(_, record)}
            {DescriptionColumn(_, record)}
            { enableUsersAccount && canSetTimeLogUser && 
              <UsersAccountColumn 
                formik={formik}
                record={record}
                takeOverFormik={takeOverFormik}
              />
            }
            {TagsColumn(_, record)}
          </ContentColumnWrapper>
        )
      }
    },
    {
      title: '',
      width: '36px',
      render: BillableColumn,
    },
    {
      title: '',
      render: DurationColumn,
    },
    {
      title: '',
      width: '200px',
      render: TimeColumn,
    },
    {
      title: '',
      width: skipTracker ? '30px' : '98px',
      render: TimeLogActions,
      className: skipTracker ? 'timesheet-table-actions' : 'day-table-actions',
    }
  ]

  function ProjectsTask(_: any, record: TApiTimeLog) {
    return (
      <TimeLogTaskSelect
        timeLog={record}
        onSuccess={refetch}
      />
    )
  }

  function DescriptionColumn(_: any, record: TApiTimeLog) {
    const inputRef = useRef<TextAreaRef>()

    const isActive = (record.id == formik.values?.id)
    const value = isActive ? (formik.values.description || "") : record.description
    const [isFocus, setIsFocus] = useState(false)
    const [isHover, setIsHover] = useState(false)
    const [disabled, setDisabled] = useState(false)

    // Trigger form submit on losing focus, the most reliable state based place for it
    useEffect(() => {
      if (!isFocus) {
        submitValue()
      }
    }, [isFocus])

    const submitValue = () => {
      if (isActive && !formik.isSubmitting && formik.values.description !== record.description) {
        formik.submitForm()
        setIsFocus(false)
        inputRef.current?.blur()
      } else {
        setIsFocus(false)
        inputRef.current?.blur()
      }
    }

    const handleOnFocus = (e: any) => {
      setIsFocus(true)
      // Take over the formik
      takeOverFormik(record)
    }

    const handleOnChange = (e: any) => {
      if (isActive) {
        formik.setFieldValue('description', e.target.value)
      }
    }

    const handleOnBlur = () => {
      setIsFocus(false)
    }

    const noFocusText = (
      <div
        className="description-placeholder"
        onClick={(e) => {
          if (!isFocus) {
            setIsFocus(true)
          }
        }}
      >
        {record.description || 'No description'}
      </div>
    )

    return (
      <DescriptionFocusWrapper className="description-column-wrapper">
        <Popover
          getPopupContainer={(node) => node}
          trigger={"click"}
          visible={isFocus}
          destroyTooltipOnHide={true}
          onVisibleChange={(visible) => {
            if (!visible) setIsFocus(visible)
          }}
          content={(
            <>
              <TextArea
                ref={inputRef}
                style={{
                  resize: 'none',
                  cursor: 'pointer',
                  width: '400px',
                  backgroundColor: (isHover && !isFocus) ? '#eee' : undefined,
                }}
                onKeyDown={(e) => {
                  if (isActive && e.key == 'Escape' && formik.dirty) {
                    formik.setFieldValue('description', record.description)
                    // needed to ensure handle blur has updated formik values already
                    setIsFocus(false)
                  }

                  if (e.key == 'Enter') {
                    submitValue()
                    e.preventDefault()
                  }
                }}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                placeholder="No description"
                onFocus={handleOnFocus}
                autoFocus={true}
                rows={3}
                disabled={disabled} // isActive && formik.isSubmitting}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                value={value}
                bordered={isFocus}
              />
              <ErrorMessage msg={isActive ? formik.errors?.description : undefined} />
            </>
          )}
        />
        {noFocusText}
      </DescriptionFocusWrapper>
    )
  }

  function TagsColumn(_: any, record: TApiTimeLog) {
    const inputRef = useRef<typeof TagsSelectIcon>()

    const isActive = (record.id == formik.values?.id)

    const value = isActive ? (formik.values.tags || []) : record.tags

    const [isCreatingTag, setIsCreatingTag] = useState(false)

    const newTagModal = useNewTagModal()

    const [isFocus, setIsFocus] = useState(false)
    const [isHover, setIsHover] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [open, setOpen] = useState(false)

    useNoInitialEffect(() => {
      if (!open && !isCreatingTag) {
        formik.submitForm()
      } else {
        handleOnFocus(1)
      }
    }, [open])

    const handleOnFocus = (e: any) => {
      setIsFocus(true)
      // Take over the formik
      takeOverFormik(record)
    }

    const handleOnChange = (tags: TApiTag[]) => {
      if (isActive) {
        formik.setFieldValue('tags', tags)
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          height: '36px',
        }}
      >
        <TagsSelectIcon
          placement="bottomRight"
          ref={inputRef}
          value={value || []}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          displayMode={shortTags ? "icon" : "first-tag" }
          open={open}
          setOpen={setOpen}
          onCreate={(search) => {
            setIsCreatingTag(true)
            setOpen(false)

            newTagModal.setOnSuccess((tag) => {
              handleOnChange([...(formik.values.tags || []), tag])
              formik.submitForm()
              setIsCreatingTag(false)
            })
          }}
        />

      </div>
    )
  }

  function BillableColumn(_: any, record: TApiTimeLog) {
    const isActive = (record.id == formik.values?.id)
    const value = isActive ? (!!formik.values.isBillable) : !!record.isBillable

    const projectQuery = useProjectQuery({
      projectId: record.project.id
    })

    const project: TApiProject = {
      ...record.project,
      ...projectQuery.data?.project,
    }

    // TODO: Refactor in the best place based on future use cases
    const permissionsQuery = usePermissionsQuery()
    const projectPermissionsQuery = useCurrentProjectsPermissionsQuery({
      params: {
        projectId: project.id
      }
    })

    // TODO: Ideally this should be returned from the backend current project permissions scope
    const canSetTimeLogUser = !!permissionsQuery.data?.permissions.setTimeLogUser
    const canSetTimeLogBillable = !!permissionsQuery.data?.permissions.setTimeLogBillable
    const isProjectBillableAtAll = !!project.billableEnabled
    const projectTimeLogIsBillableDefault = (!!project.billableEnabled && !!project.defaultIsBillableTimeLogs)

    const handleOnChange = (newValue: boolean) => {
      takeOverFormik(record)
      formik.setFieldValue('isBillable', newValue)
      formik.submitForm()
    }

    return (
      <BillableFlag
        onChange={handleOnChange}
        checked={value}
        disabled={!canSetTimeLogBillable || !isProjectBillableAtAll}
        disabledText={
          canSetTimeLogBillable ? "This project is not billable." : "You have no permissions to change the time log billable flag."
        }
      />
    )
  }


  function DurationColumn(_: any, record: TApiTimeLog) {
    const inputRef = useRef<DurationInput>()

    const isActive = (record.id == formik.values?.id)
    const value = isActive ? (formik.values.duration || 0) : record.duration
    const [isFocus, setIsFocus] = useState(false)
    const [isHover, setIsHover] = useState(false)
    const [disabled, setDisabled] = useState(false)


    // Trigger form submit on losing focus, the most reliable state based place for it
    useEffect(() => {
      if (!isFocus) {
        submitValue()
      }
    }, [isFocus])

    const submitValue = () => {
      if (isActive && !formik.isSubmitting && formik.values.duration !== record.duration) {
        formik.submitForm()
        setIsFocus(false)
        inputRef.current?.blur()
      } else {
        setIsFocus(false)
        inputRef.current?.blur()
      }
    }

    const handleOnFocus = (e: any) => {
      setIsFocus(true)
      // Take over the formik
      takeOverFormik(record)
    }

    const handleOnChange = (value: number) => {
      if (isActive) {
        setNewDuration(value)
      }
    }

    const handleOnBlur = () => {
      setIsFocus(false)
    }

    return (
      <DurationFocusWrapper>
        <DurationInput
          className={isFocus ? 'active' : ''}
          ref={inputRef}
          value={value}
          onChange={handleOnChange}
          onKeyDown={(e) => {
            if (isActive && e.key == 'Escape') {
              formik.setValues({}) // detaching from formik!
              setIsFocus(false)
              e.stopPropagation()
              e.preventDefault()
            }

            if (e.key == 'Enter') {
              setIsFocus(false)
              e.preventDefault()
            }
          }}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onFocus={handleOnFocus}
          disabled={disabled}
          onBlur={handleOnBlur}
          bordered={isFocus}
        />
        <ErrorMessage msg={isActive ? formik.errors.duration : undefined} />
      </DurationFocusWrapper>
    )
  }

  function TimeColumn(_: any, record: TApiTimeLog) {
    const isActive = record.id == formik.values?.id

    const [open, setOpen] = useState(false)

    useEffect(() => {
      if (open) {
        takeOverFormik(record)
      }
    }, [open])

    const [isFocus, setIsFocus] = useState(false)

    const recordFrom = moment.utc(record.startAt)
    const recordTo = moment.utc(record.startAt).add(record.duration, 'm')

    const dateValue = isActive ? formik.values.from : recordFrom
    const fromValue = isActive ? formik.values.from : recordFrom
    const toValue = isActive ? formik.values.to : recordTo

    const endsNextDay = recordTo.get('d') - recordFrom.get('d') !== 0

    // Trigger form submit on losing focus, the most reliable state based place for it
    useEffect(() => {
      if (!isFocus) {
        submitValue()
      }
    }, [isFocus])

    useNoInitialEffect(() => {
      if (!open) {
        submitValue()
      }
    }, [open])

    const submitValue = () => {
      if (isActive && !formik.isSubmitting && (!isEqual(formik.values.from, recordFrom) || !isEqual(formik.values.to, recordTo))) {
        formik.submitForm()
        if (document.activeElement) document.activeElement.blur()
      } else {
        if (document.activeElement) document.activeElement.blur()
      }
    }

    const handleFocus = () => {
      setIsFocus(true)
      takeOverFormik(record)
    }

    const handleOnChangeDate = (value: Moment) => {
      const newFrom = moment.utc(formik.values.from)
      newFrom.set('year', value.get('year'))
      newFrom.set('month', value.get('month'))
      newFrom.set('date', value.get('date'))

      formik.setFieldValue('from', newFrom)
      setOpen(false)
    }

    const handleOnChangeFrom = (from: Moment) => {
      setNewFromDate(from)
      setIsFocus(false)
    }

    const handleOnChangeTo = (to: Moment) => {
      setNewToDate(to)
      setIsFocus(false)
    }

    const handleOnBlur = () => {
      setIsFocus(false)
    }

    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
          }}
        >
          <TimePickerWrapper
            className={defaultTimeFormatKey == '24h' ? 'short' : ''}
          >
            <TimePicker
              value={fromValue}
              allowClear={false}
              suffixIcon={false}
              bordered={isFocus}
              format={defaultTimeFormat}
              onFocus={handleFocus}
              onChange={handleOnChangeFrom}
              onBlur={handleOnBlur}
            />
          </TimePickerWrapper>
          <div>-</div>
          <TimePickerWrapper
            className={defaultTimeFormatKey == '24h' ? 'short' : ''}
          >
            <Badge
              count={endsNextDay ? '+1 day' : undefined}
            >
              <TimePicker
                value={toValue}
                allowClear={false}
                suffixIcon={false}
                bordered={isFocus}
                format={defaultTimeFormat}
                onFocus={handleFocus}
                onChange={handleOnChangeTo}
                onBlur={handleOnBlur}
              />
            </Badge>
          </TimePickerWrapper>
        </div>
        <div>
          <DatePickerPopover
            value={dateValue}
            onChange={handleOnChangeDate}
            open={open}
            setOpen={setOpen}
          />
        </div>

      </div>
    )
  }
  function TimeLogActions(_: any, record: TApiTimeLog) {

    const [isActive, setIsActive] = useState(false)

    const bookmarkingAction = record.bookmarked ? {
      title: 'Unbookmark task',
      Icon: StarFilled,
      callback: () => {
        unbookmarkMutation.mutate({
          projectId: record.project.id,
          taskId: record.task.id,
        })
      }
    } : {
      title: 'Bookmark task',
      Icon: StarOutlined,
      callback: () => {
        bookmarkMutation.mutate({
          projectId: record.project.id,
          taskId: record.task.id,
        })
      }
    }

    const deleteAction = {
      title: 'Delete time log',
      Icon: DeleteIcon,
      callback: () => {
        deleteTimeLogMutation.mutate({
          timeLogId: record.id,
        })
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {!skipTracker && (
          <PlayCircleOutlined
            style={{
              fontSize: '30px',
              marginRight: '10px',
              cursor: 'pointer',
            }}
            onClick={() => {
              startTrackerMutation.mutate(record)
            }}
          />
        )}

        <div
          style={{
            paddingBottom: '4px',
          }}
        >
          <GenericButtonsList
            buttons={[{
              title: '',
              icon: MoreSvg,
              type: 'text',
              options: [bookmarkingAction, deleteAction]
            }]}
          />
        </div>
      </div>
    )
  }

  return { 
    columns, 
    cleanEdit: () => formik.setValues({})
  }
}

export default useTimeLogsColumns