import { useGetApiCall } from "./useApiCall"
import useApiClient from "./useApiClient"

export const useBillingInfo = () => {
  const apiClient = useApiClient()
  const { data, loading, request } = useGetApiCall(() => apiClient.getBillingInfo() )

  return {
    billingInfo: data?.billingInfo,
    loading,
    fetchBillingInfo: request,
  }
}