import { Tag } from "antd"
import ProjectSelect from "./project-select"
import { useState } from "react"
import { useTimer } from "@timenotes/shared/src/services/timer/timer.context"
import { TApiProject } from "services/api-client/types"
import LogoImg from "components/base/logo"
import { AppName } from "consts"
import { TIMENOTES_COLORS } from "components/forms/color-picker-select"


const ProjectSelectPill = (props: React.ComponentProps<typeof ProjectSelect>) => {
  // Display initial project
  if (props.value) {
    const project = props.value

    let handleOnClick = !props.disabled ? props.onClear : undefined

    if (project) {
      return (
        <div 
          style={{ 
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            backgroundColor: '#eee',
            borderRadius: '5px',
            paddingLeft: '10px',
            paddingRight: '0px',
            marginRight: '10px'
          }}
        >
          <LogoImg name={project.origin as AppName || 'timenotes'} />
          <Tag
            tabIndex={0}
            color={project.color}
            onClick={handleOnClick}
            onClose={handleOnClick}
            onFocus={handleOnClick}
            closable={!props.disabled}
            style={{
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
              cursor: 'pointer',
              fontSize: '14px',
              marginLeft: '5px',
              paddingTop: '5px',
              paddingBottom: '5px',
              marginRight: '0px'
            }}
          >
            {project.name}
          </Tag>
        </div>
      )
    }
  }

  return (
    <ProjectSelect  { ...props } />
  )
}

export default ProjectSelectPill