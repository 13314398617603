import { useEffect, useState } from 'react'
import { MailOutlined } from '@ant-design/icons'
import { Button, Form, Input, Divider, message, Checkbox } from "antd"
import { useFormik } from 'formik'
import ErrorMessage from 'components/base/error-message'
import useApiClient from 'hooks/useApiClient'
import { useLoginSuccessRedirect } from 'hooks/auth.hooks'
import { useQueryParams } from 'hooks/app-router'
import GoogleButton from './google-button'
import { getApiErrorMessage } from '@timenotes/shared/src/services/api-client'
import { getOnboardingCookieData } from 'services/utils/functions/onboarding-cookie-data'
import reportGtagConversion from 'services/analytics/report-gtag-conversion'

export const RegisterForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const apiClient = useApiClient()
  const queryParams = useQueryParams<{
    'registration[token]': string
    'user[first_name]': string
    'user[last_name]': string
    'user[email]': string
  }>()

  const queryCredentials = {
    registrationToken: queryParams['registration[token]'],
    userFirstName: queryParams['user[first_name]'],
    userLastName: queryParams['user[last_name]'],
    userEmail: queryParams['user[email]'],
  }

  const onboardingCookieData = getOnboardingCookieData()
  const additionalData = onboardingCookieData ? onboardingCookieData : undefined

  useEffect(() => {
    if (queryCredentials.registrationToken) {
      setRegistrationToken(queryCredentials.registrationToken)
    }
  }, [])

  const [registrationToken, setRegistrationToken] = useState<undefined | string>()
  const loginSuccessRedirect = useLoginSuccessRedirect()

  const onGoogleSignUp = () => {
    apiClient.registerWithGoogle(additionalData)
  }

  const tokenFormik = useFormik({
    initialValues: {
      email: queryCredentials.userEmail || ''
    },
    onSubmit: async (values) => {
      const response = await apiClient.registerEmail({
        email: tokenFormik.values.email,
      })

      if (response.ok) {
        setRegistrationToken(response.registration.token)
      } else {
        const errorMessage = getApiErrorMessage(response, 'email')
        message.error(errorMessage)
      }
    }
  })

  const registrationFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      registrationToken: registrationToken || '',
      firstName: queryCredentials.userFirstName || '',
      lastName: queryCredentials.userLastName || '',
      password: '',
      email: tokenFormik.values.email,
      termsOfService: false,
      accountName: '',
    },
    onSubmit: async (values) => {
      if (isSubmitting) return // do not allow submit twice
      setIsSubmitting(true)

      try {
        const response = await apiClient.fullfillRegistration({
          registration: {
            token: values.registrationToken
          },
          user: {
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            termsOfService: values.termsOfService,
            account: {
              name: values.accountName,
              additionalData: additionalData,
            }
          }
        })

        if (response.ok) {
          message.success("Account created!")
          apiClient.setAccessCredentials({
            accessToken: response.session.token
          })

          // Report GTAG conversion
          if (additionalData?.gclid) {
            reportGtagConversion(additionalData.gclid)
          }

          loginSuccessRedirect()
        } else {
          if (response.errors) {
            if (response.errors?.base) {
              message.error(response.errors.base)
            }

            registrationFormik.setErrors(response.errors || {})
          }
        }
      }

      finally {
        setIsSubmitting(false)
      }
    }
  })


  if (!registrationToken) {
    // Email input flow
    return (
      <>
        <Form layout="vertical" name="register-form" onSubmitCapture={tokenFormik.handleSubmit}>
          <Form.Item
            name="email"
            label="Email"
          >
            <Input
              autoFocus={true}
              prefix={<MailOutlined className="text-primary" />}
              onChange={tokenFormik.handleChange('email')}
            />
            <ErrorMessage msg={tokenFormik.errors.email} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Sign Up
            </Button>
          </Form.Item>
        </Form>

        <div>
          <Divider>
            <span className="text-muted font-size-base font-weight-normal">or sign up with</span>
          </Divider>
          <div className="d-flex justify-content-center">
            <GoogleButton
              onClick={() => onGoogleSignUp()}
            >
              Sign up with Google
            </GoogleButton>
          </div>
        </div>

      </>
    )
  }

  return (
    <>
      <Form layout="vertical" name="register-form" onSubmitCapture={registrationFormik.handleSubmit}>

        <Form.Item
          name="accountName"
          label="Workspace name"
        >
          <Input
            autoFocus={true}
            onChange={registrationFormik.handleChange('accountName')}
          />
          { /* @ts-ignore */}
          <ErrorMessage msg={registrationFormik.errors.name} />
        </Form.Item>

        <Form.Item
          name="firstName"
          label="First name"
        >
          <Input
            value={registrationFormik.values.firstName}
            onChange={registrationFormik.handleChange('firstName')}
          />
          <ErrorMessage msg={registrationFormik.errors.firstName} />
        </Form.Item>

        <Form.Item
          name="lastName"
          label="Last name"
        >
          <Input
            value={registrationFormik.values.lastName}
            onChange={registrationFormik.handleChange('lastName')}
          />
          <ErrorMessage msg={registrationFormik.errors.lastName} />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
        >
          <Input
            value={registrationFormik.values.email}
            disabled={true}
          />
          <ErrorMessage msg={registrationFormik.errors.email} />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
        >
          <Input
            type="password"
            onChange={registrationFormik.handleChange('password')}
          />
          <ErrorMessage msg={registrationFormik.errors.password} />
        </Form.Item>

        <Form.Item
          name="termsOfService"
          label="Terms of service"
        >
          <Checkbox
            onChange={(e) => {
              registrationFormik.setFieldValue('termsOfService', e.target.checked)
            }}
          >
            Accept terms of service
          </Checkbox>
          <ErrorMessage msg={registrationFormik.errors.termsOfService} />
        </Form.Item>

        <Form.Item>
          <Button 
            disabled={isSubmitting}
            type="primary" 
            htmlType="submit" 
            block
          >
            Create workspace
          </Button>
        </Form.Item>
      </Form>

      <Divider />

      <Button
        onClick={() => {
          window.location.reload()
        }}
      >
        Back
      </Button>
    </>

  )
}

export default RegisterForm
