import React from 'react'
import _map from 'lodash/map'
import { noClientOption, useClientsQuery } from '@timenotes/shared/src/services/clients/queries/use-clients-query'
import FilterInput from './filter-input'
import RecordsSelect from '../records-select'
import { TApiClient } from '@timenotes/shared/src/services/api-client/types'

export interface ClientsFilterProps {
  style?: React.CSSProperties
  values?: string[]
  onChange?(values: string[]): void 
}

const ClientsFilter = ({
  values,
  onChange,
 }: ClientsFilterProps): JSX.Element => {

  const clientsQuery = useClientsQuery({
    includeNoClientOption: true
  })
  const clients = clientsQuery.data?.clients || [] 

  const handleOnChange = (clients: TApiClient[]) => {
    onChange && onChange(clients.map((c) => c.hashId))
  }

  const allClients = [...(clientsQuery.data?.clients || []), noClientOption.value]
  const selectedClients = allClients.filter((client) => {
    return values?.includes(client.hashId)
  })

  const isLoading = clientsQuery.isLoading

  return (
    <FilterInput
      placeholder='Client'
      count={values?.length}
    >
      <RecordsSelect<TApiClient>
        open={true}
        loading={isLoading}
        disabled={isLoading}
        style={{
          width: '300px',
        }}
        valueProp='hashId'
        labelProp='name'
        searchProp='name'
        placeholder='Client'
        showSearch={true}
        mode='multiple'
        options={[{
          label: 'Clients',
          allFilters: true,
          options: clientsQuery.data?.clientsOptions || [],
        }]}

        value={selectedClients as unknown as TApiClient}
        onChange={handleOnChange as unknown as any}

        autoFocus={true}
        dropdownMatchSelectWidth={true}
        getPopupContainer={(node) => {
          return node
        }}
        filterInputMode={true}
      />
    </FilterInput>
  )
}

export default ClientsFilter