import PageLoadingSpin from "components/layout/page-loading-spin";
import { useEffect } from "react";
import useApprovalsNavigation from "./hooks/use-approvals-navigation";

export default function ApprovalsRootRedirectPage() {
  const links = useApprovalsNavigation()

  useEffect(() => {
    links[0].onClick()
  }, [])

  return (
    <PageLoadingSpin />
  )
}