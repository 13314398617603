import React, { useEffect, useState } from 'react'
import { TApiProject } from 'services/api-client/types';
import _map from 'lodash/map'
import styled from 'styled-components';
import { Badge, Select } from 'antd';
import { find } from 'lodash';
import useApiClient from 'hooks/useApiClient';

export interface ProjectSelectProps {
  style?: React.CSSProperties
  width?: string
  value?: string
  allowClear?: boolean
  autoFocus?: boolean
  initialProject?: TApiProject
  placeholder?: string
  onChange?(value: string, project: TApiProject): void 
}

const Wrapper = styled.div`
  width: 100%;

  .ant-popover {
    width: 250px;
  }
`

const ProjectSelect = ({
  value,
  width,
  placeholder,
  allowClear,
  autoFocus,
  initialProject,
  onChange,
 }: ProjectSelectProps): JSX.Element => {
  const apiClient = useApiClient()
  const [projects, setProjects] = useState<TApiProject[]>(initialProject ? [initialProject] : [])

  useEffect(() => {
    apiClient.getProjects({ perPage: 200 }).then((response) => {
      if (response.ok) {
        setProjects(response.projects)
      }
    })
  }, [])

  const groupsOptions = _map(projects, (project) => {
    return {
      value: project.id,
      name: project.name,
      label: (
        <span>
          <Badge color={project.color} />
          {project.name}
        </span>
      ),
      color: project.color,
    }
  })

  const handleOnChange = !onChange ? () => {} : (projectId: string) => {
    const project: TApiProject = find(projects, (project) => { return project.id == projectId}) as TApiProject
    onChange(projectId, project)
  }

  return (
    <Wrapper className="project-select-wrapper">
      <Select
        style={{ width: width || '100%' }}
        getPopupContainer={(node) => {return node.closest('.project-select-wrapper')} }
        allowClear={allowClear == false ? false : true }
        autoFocus={autoFocus}
        placeholder={ placeholder || 'Project' }
        showSearch={true}
        optionFilterProp='name'
        options={groupsOptions}
        onChange={handleOnChange}
        value={value}
      />
    </Wrapper>
  )
}

export default ProjectSelect