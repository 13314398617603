import { TApiResponse, TApiParams, TApiTask } from "./types"
import BaseApi, { getQueryString, parameterizeArray } from './base'
import _omit from 'lodash/omit'
import _cloneDeep from 'lodash/cloneDeep'
import { ITaskItem } from "models/TaskItem"

export type TApiTasksFilters = {
  name?: string
  state?: 'archived' | 'active'
}

export type TApiTasksSorting = {
  name?: 'desc' | 'asc'
}

export interface IGetAllTasksParams extends TApiParams {
  filters?: TApiTasksFilters
  sorting?: TApiTasksSorting
}

export interface IGetTasksResponse extends TApiResponse {
  tasks: Array<TApiTask>
}

export interface IGetCommonTasksResponse extends TApiResponse {
  bookmarked: Array<TApiTask>
  recentlyTracked: Array<TApiTask>
}

export interface ICreateTaskParams {
  name: string
  state?: 'active' | 'archived'
  createExternal?: boolean
  additionalDetails?: {
    parentId: string,
    taskType: null | 'to_do' | 'card'
  }
}

export interface ICreateTaskResponse extends TApiResponse {
  task: TApiTask
}

export interface IUpdateTaskParams extends ICreateTaskParams {
}

export interface IUpdateTaskResponse extends ICreateTaskResponse {
}

class TasksApi extends BaseApi {
  async getAllTasks(projectId: string, params?: IGetAllTasksParams): Promise<IGetTasksResponse> {
    let queryParams = (params === undefined) ? params : { q: { s: this.prepareSortingParams(params.sorting) }, ..._omit(params, ['sorting'])}
    const query = getQueryString(this.paramsToSnakeCase(queryParams), true)

    let response = await fetch(`${this.rootUrl}/projects/${projectId}/tasks/all${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async getTasks(projectId: string, requestParams?: IGetAllTasksParams): Promise<IGetTasksResponse> {
    let params = _cloneDeep(requestParams) || {}

    if (params && params.filters && params.filters.hasOwnProperty('name')) {
      if (!params.filters.name) {
        params.filters.name = ""
      }
    }

    if (!params?.perPage) {
      params.perPage = 100
    }

    const query = getQueryString(this.paramsToSnakeCase(params), true)

    let response = await fetch(`${this.rootUrl}/projects/${projectId}/tasks${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async getCommonTasks(projectId: string): Promise<IGetCommonTasksResponse> {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}/tasks/common`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async bookmarkTask(projectId: string, taskId: string) {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}/tasks/${taskId}/bookmark`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async unbookmarkTask(projectId: string, taskId: string) {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}/tasks/${taskId}/unbookmark`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async createTask(projectId: string, params: ICreateTaskParams): Promise<ICreateTaskResponse> {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}/tasks`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        task: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async updateTask(projectId: string, taskId: string, params: Partial<TApiTask>): Promise<IUpdateTaskResponse> {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}/tasks/${taskId}`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        task: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async archiveTask(projectId: string, taskId: string): Promise<IUpdateTaskResponse> {
    return this.updateTask(projectId, taskId, {
      state: 'archived',
    })
  }


  async deleteTask(projectId: string, taskId: string): Promise<TApiResponse> {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}/tasks/${taskId}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

}

export default TasksApi
