import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import Flex from 'components/shared-components/Flex'
import { Table } from 'antd'
import HorizontalBar from './horizontal-bar'

import _sumBy from 'lodash/sumBy'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import _groupBy from 'lodash/groupBy'
import _clone from 'lodash/'

import { formatProjectsToBarSegments } from './horizontal-bar'

import Convert from 'utils/convert'
import Time, { formatTimeCounter } from 'utils/time'
import Duration from 'utils/duration'
import { formatTimeInMinutes } from '@timenotes/shared/src/services/time-logs/utils/time-formatters'
import useClassState from 'services/utils/hooks/use-class-state'

type TState = {
  worktime: number,
  startedAt: Time,
  timeNow: Time,
}

export const TrackerCounter = (props: {
  worktime: number,
  startedAt: Time,
  render: React.FC,
}) => {
  const [state, setState] = useClassState<TState>({
    worktime: props.worktime,
    startedAt: props.startedAt,
    timeNow: new Time()
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setState({ timeNow: new Time() })
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])


  const toNowSecondsCounter = formatTimeCounter(state.startedAt.toNowSeconds() + (state.worktime * 60))

  return props.render(toNowSecondsCounter)
}

const DashboardSummaryTable = (props: {
  userColumnTitle?: string,
  activityColumnTitle?: string,
  totalColumnTitle?: string,
  activitiesData: any[],
  title?: string,
  active?: boolean,
  selectUserActivity?(activity: object): void
}) => {

  const title = props.title || "Dashboard Summary Table"
  const userColumnTitle = props.userColumnTitle || "MEMBER"
  const activityColumnTitle = props.activityColumnTitle || "WORKING ON"
  const totalColumnTitle = props.totalColumnTitle || "TOTAL (TODAY)"

  const renderUser = (record: any) => {
    return (
      <span>{record.name}</span>
    )
  }

  const renderActivityTimeLog = (timeLog: any) => {

    const durationTime = Time.fromApiDate(timeLog.startAt, timeLog.timeZone)
    const duration = Duration.fromMinutes(timeLog.duration)

    return (
      <Flex flexDirection="column" justifyContent="center" style={{ height: '100%'}}>
        <span className="time-log-duration"> { duration.toDurationString()} </span>
        <span className="time-log-to-now"> { durationTime.toNowDurationString() } </span>
      </Flex>
    )
  }

  const renderActivityTracker = (tracker: any) => {
    const worktime = tracker.worktime || 0
    const startedAtTime = Time.fromApiDate(tracker.startedAt, tracker.timeZone)

    return (
      <Flex flexDirection="column" justifyContent="center" style={{ height: "100%" }}>
        <TrackerCounter
          startedAt={startedAtTime}
          worktime={worktime}

          render={(counterTime: any) => (
            <span className="tracker-counter"> { counterTime } </span>
          )}
        />
      </Flex>
    )
  }

  const renderActivity = (record: any) =>  {
    const { selectUserActivity } = props

    const hasTracker = !_isEmpty(record.tracker)

    const firstTimeLog = record.timeLogs[0]
    const firstRecord = hasTracker ? record.tracker : firstTimeLog

    if (_isEmpty(firstRecord)) {
      return (<div />)
    }

    return (
      <Flex style={{ width: '100%' }}>
        <Flex flexDirection="column" justifyContent="center" style={{ width: "80%", height: "100%" }}>
          <span className="task-name"> { firstRecord.task.name } </span>
          <span className="project-name"> { firstRecord.project.name } </span>
          <span><a onClick={ () => { selectUserActivity(record) }}> {moreCountLink(record.timeLogs)}</a></span>
        </Flex>

        <Flex flexDirection="column" style={{ width: "20%", textAlign: 'right', marginRight: '20px' }}>
          { hasTracker ? renderActivityTracker(record.tracker) : renderActivityTimeLog(firstTimeLog) }
        </Flex>

      </Flex>
    )
  }

  const renderTotal = (record: any) => {
    const projectSummaries = getProjectsFromTimeLogs(record.timeLogs)
    const segments = formatProjectsToBarSegments(projectSummaries)

    const totalDuration = _sumBy(record.timeLogs, 'duration')


    return (
      <Flex>
        <span className="total">{formatTimeInMinutes(totalDuration)}</span>
        <HorizontalBar segments={segments} displayTitle={false} />
      </Flex>
    )
  }

  const getProjectsFromTimeLogs = (timeLogs: any[]) => {
    const projectTimeLogsHash = _groupBy(timeLogs, (timeLog) => {
      return timeLog.project.id
    })

    const projects = _map(projectTimeLogsHash, (projectTimeLogs, id) => {
      const project = projectTimeLogs[0].project
      const projectWorktime = _sumBy(projectTimeLogs, (t) => t.duration)

      return {
        worktime: projectWorktime,
        ...project,
      }
    })

    return projects
  }

  const getActivitiesData = () => {
    return _map(props.activitiesData, (userActivity) => {
      return {
        key: userActivity.id,
        name: userActivity.name,
        timeLogs: userActivity.timeLogs,
        tracker: userActivity.tracker,
        lastActivityAt: userActivity.lastActivityAt,
      }
    })
  }

  const moreCountLink = (timeLogs: any[]) => {
    const timeLogsCount = timeLogs.length
    const count = props.active ? timeLogsCount : (timeLogsCount - 1)

    return count == 0 ? 'more' : `${count} more`
  }

  const data = getActivitiesData()

  const columnsConfig = [
    {
      title: userColumnTitle,
      render: renderUser,
      className: 'user-cell',
      sorter: (a: any, b: any) => {
        const result = a.name.localeCompare(b.name)
        return result
      }
    },
    {
      title: activityColumnTitle,
      render: renderActivity,
      className: 'activity-cell',
      sorter: props.activityColumnTitle == "" ? undefined : (a: any, b: any) => {
        const result = a.lastActivityAt - b.lastActivityAt
        return result
      }
    },
    {
      title: totalColumnTitle,
      render: renderTotal,
      className: 'summary-cell',
      sorter: (a: any, b: any) => {
        const result = _sumBy(a.timeLogs, 'duration') - _sumBy(b.timeLogs, 'duration')
        return result
      }
    },
  ]

  return (
    <div className="dashboard-summary-table">
      <h2> {props.title} </h2>

      <Table
        columns={columnsConfig}
        dataSource={data}
        pagination={false}
      />

    </div>
  )
}

export default DashboardSummaryTable