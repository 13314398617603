import { Tag, Tooltip, message } from "antd"
import LogoImg, { logoMap } from "components/base/logo"
import { toDurationString } from "components/forms/duration-input"
import Icon from "components/util-components/Icon"
import { AppName } from "consts"
import { useLineClampTrimmed } from "hooks/html.hooks"
import { useDefaultCurrencyIso, useDefaultDateFormat, useDefaultTimeFormat, useSettings } from "hooks/settings"
import { isNull, isNumber, map, set } from "lodash"
import { string } from "mobx-state-tree/dist/internal"
import moment from "moment-timezone"
import { useEffect, useRef, useState } from "react"
import { TApiTag } from "services/api-client/types"
import styled from "styled-components"
import { toAmountWithCurrency } from "utils/string-utils"

const LineClampDescriptionWrapper = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
`


export const TeamMemberColumn = ({ 
  firstName, 
  lastName 
}: {
firstName: string,
  lastName: string,
}) => {
  return (
    <span style={{ textTransform: 'capitalize' }}>
      {firstName} {lastName}
    </span>
  )
}

export const ClientNameColumn = ({ 
  name, 
}: {
  name: string,
}) => {
  return (
    <span style={{ textTransform: 'capitalize' }}>
      {name ? (
        <span>{name}</span>
      ) : (
        <i style={{ opacity: 0.5 }}>no client </i>
      )}
    </span>
  )
}

export const ProjectsTaskColumn = (props: {
  projectName: string,
  projectOrigin: string,
  taskName: string,
}) => {
  const { projectName, projectOrigin, taskName } = props

  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      <span style={{ textTransform: 'capitalize' }}>
        <b>{taskName}</b>
      </span>
      <span>
        {projectName} {projectOrigin ? `(${projectOrigin})` : ""}
      </span>
    </div>
  )
}



export const ProjectNameColumn = ({ 
  name, 
  origin,
}: {
  name: string,
  origin?: string,
}) => {

  const originIcon = (origin && Object.keys(logoMap).includes(origin)) ? (
    <LogoImg name={origin as AppName} style={{ width: '15px', height: '15px', marginRight: '10px' }} />
  ) : null

  return (
    <span style={{ textTransform: 'capitalize' }}>
      {originIcon}
      {name} 
    </span>
  )
}

export const TaskNameColumn = ({ 
  name, 
}: {
  name: string,
}) => {
  return (
    <span style={{ textTransform: 'capitalize' }}>
      {name}
    </span>
  )
}

export const TimeLogDescriptionColumn = ({ 
  description, 
}: {
  description: string | null,
}) => {
  const {
    isTrimmed,
    elRef,
  } = useLineClampTrimmed()

  return (
    <LineClampDescriptionWrapper>
      <Tooltip 
        title={isTrimmed ? description : undefined}
      >
        <div className="time-log-description-column" ref={elRef}>
          {description}
        </div>
      </Tooltip>
    </LineClampDescriptionWrapper>
  )
}

export const TagsColumn = ({ 
  tags,
}: {
  tags: TApiTag[],
}) => {
  return (
    <span style={{ textTransform: 'capitalize' }}>
      {map(tags, (tag) => {
        return (
          <Tag color={tag.color} key={tag.name} style={{ marginBottom: '5px' }}>
            {tag.name}
          </Tag>
        )
      })}
    </span>
  )
}

export const DurationColumn = ({ 
  duration, 
}: {
  duration: number | string,
}) => {
  const durationNumber = isNumber(duration) ? duration : Number(duration)
  const durationString = toDurationString(durationNumber)

  return (
    <span>
      {durationString}
    </span>
  )
}

export const TimeLogTimeColumn = ({ 
  startAt, 
  endAt,
  skipDate,
}: {
  startAt: string,
  endAt: string,
  skipDate?: boolean,
}) => {


  const { defaultDateFormat } = useDefaultDateFormat()
  const { defaultTimeFormat } = useDefaultTimeFormat()

  // TODO - move to global configuration
  const HOUR_FORMAT = defaultTimeFormat
  const DATE_FORMAT= defaultDateFormat

  const momentStartAt  = moment.utc(startAt)
  const momentEndAt = moment.utc(endAt)

  const formatedStartAt = momentStartAt.format(HOUR_FORMAT)
  const formatedEndAt = momentEndAt.format(HOUR_FORMAT)
  const formatedDay = momentStartAt.format(DATE_FORMAT)

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      textTransform: 'capitalize',
    }}>
      <span>{formatedStartAt} - {formatedEndAt}</span>
      {!skipDate && (
        <span style={{ opacity: '0.7' }}>
          { formatedDay }
        </span>
      )}
    </div>
  )
}

export const CostColumn = ({
  costAmount,
}: {
  costAmount: number | null
}) => {
  const { defaultCurrencyIso }= useDefaultCurrencyIso()

  return (
    <span>
      {toAmountWithCurrency(costAmount, defaultCurrencyIso)}
    </span>
  )
}

export const BillableAmountColumn = ({ 
  billableAmount, 
  isBillable,
}: {
  billableAmount: number | null,
  isBillable?: boolean
}) => {

  const { defaultCurrencyIso } = useDefaultCurrencyIso()

  if (isBillable !== undefined && isBillable == false) {
    return (
      <span>
        <i>Non billable</i>
      </span>
    )
  }

  return (
    <span>
      {toAmountWithCurrency(billableAmount, defaultCurrencyIso)}
    </span>
  )
}

