import styled from 'styled-components'
import { Button } from 'components/base'

import ExtensionLogo from 'assets/logos/extension.png'

const ExtensionLinkWrapper = styled.div`
  display: flex;
  align-items: center;

  & img {
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }
`

const ExtensionLinkView = () => (
  <ExtensionLinkWrapper>
    <img src={ExtensionLogo} />

    <Button href="https://chrome.google.com/webstore/detail/timenotes-time-tracker/goleoncgpfpkngmfhalghaopbfooppbd" target="_blank">
      Install Timenotes Chrome browser extension!
    </Button>
  </ExtensionLinkWrapper>

)

export default ExtensionLinkView