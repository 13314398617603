import { UseQueryOptions, useQuery } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiParams } from "../../api-client/types"
import { TApiGenericError } from "../../timenotes-query/timenotes-query.types"
import { TApiSetting } from "../../api-client/settings-api"

interface Params extends TApiParams {}

interface Result {
  settings: TApiSetting
  weekStartDayNumber: number
}

const WEEK_MAPPING = {
  'sunday': 0,
  'monday': 1,
  'tuesday': 2,
  'wednesday': 3,
  'thursday': 4,
  'friday': 5,
  'saturday': 6,
}

export const useSettingsQuery = (options?: UseQueryOptions<any, any>) => {
  const apiClient = useApiClient()

  const query = useQuery<Result, TApiGenericError<any>>(
    ['settings'] as const,
    async () => {
      const response = await apiClient.getSettings()

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        settings: {
          ...response.setting,
        },
        weekStartDayNumber: WEEK_MAPPING[response.setting.weekStart] as number,
      }
    },
    {
      ...options,
      staleTime: options?.staleTime || Infinity
    }
  )

  return {
    settingsQuery: query,
    timeFormat: (query.data?.settings.timeFormat || "24h") == '24h' ? 'HH:mm' : 'h:mm A'
  }
}
