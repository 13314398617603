import moment from "moment-timezone"

export default function getDaysBetweenDatesArray(startDate: any, endDate: any) {
  var dates = []
  
  var currDate = moment(startDate).startOf('day')
  var lastDate = moment(endDate).startOf('day')

  dates.push(currDate.clone())
  
  while(currDate.add(1, 'days').diff(lastDate) <= 0) {
      dates.push(currDate.clone())
  }
  
  return dates
}
