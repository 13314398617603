export type AppName = 'asana' | 'basecamp' | 'todoist' | 'trello' | 'github'

export type IntegrationAppInterface = {
  name: AppName,
}

export const IntegrationApp = {
  Asana: { name: <AppName>'asana' },
  Basecamp: { name: <AppName>'basecamp'},
  Github: { name: <AppName>'github'},
  Todoist: { name: <AppName>'todoist'},
  Trello: { name: <AppName>'trello'}
}

export const DATE_FORMAT = "DD-MM-YYYY"


export const SITE_URL = process.env.REACT_APP_SITE_URL
export const API_URL = process.env.REACT_APP_API_URL
export const WEB_APP_URL = process.env.REACT_APP_WEB_APP_URL

