import React from "react"
import styled from "styled-components"
import LogoImg from "components/base/logo"
import ArchivedLabel from "components/base/archived-label"

interface IProps {
  data: {
    projectName: string
    origin: string
    clientName: string | null
    isArchived: boolean | null
    isConnected: boolean
  }
}

enum IconType {
  ASANA = "asana",
  TODOIST = "todoist",
  TIMENOTES = "timenotes",
  BASECAMP = "basecamp",
  GITHUB = "github",
  TRELLO = "trello",
}

const ProjectNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
`

const ProjectNameTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
`

const ProjectNameTitle = styled.h4`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #474d55;
  display: flex;
  justify-content: center;
  margin: 0;
`

const Customer = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #9d9da7;
  margin: 0;
`

const Icon = styled.div`
  margin-left: 8px;
`

const DisconnectedLabel = styled.div`
  padding: 5px 8px;
  background-color: rgba(222, 68, 54, 0.1);
  margin-bottom: 8px;
  border-radius: 4px;
`

const DisconnectedText = styled.p`
  color: #ee7476;
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`

export const ProjectName = (data: IProps) => {
  const { projectName, origin, clientName, isArchived, isConnected } = data.data

  const setDisconnectedOrigin = origin[0].toUpperCase() + origin.slice(1)

  const setIcon = (type: string) => {
    const data: any = {
      [IconType.ASANA]: <LogoImg name={IconType.ASANA} style={{ width: "18px", height: "18px" }} />,
      [IconType.BASECAMP]: <LogoImg name={IconType.BASECAMP} style={{ width: "18px", height: "18px" }} />,
      [IconType.GITHUB]: <LogoImg name={IconType.GITHUB} style={{ width: "18px", height: "18px" }} />,
      [IconType.TODOIST]: <LogoImg name={IconType.TODOIST} style={{ width: "18px", height: "18px" }} />,
      [IconType.TRELLO]: <LogoImg name={IconType.TRELLO} style={{ width: "18px", height: "18px" }} />,
      // [IconType.TIMENOTES]: <LogoImg name={IconType.TIMENOTES} style={{ width: "18px", height: "18px" }} />,
    }
    return data[type]
  }

  return (
    <React.Fragment>
      <ProjectNameWrapper>
        {isConnected && (
          <DisconnectedLabel>
            <DisconnectedText>{`${setDisconnectedOrigin} project was disconnected from Timenotes`}</DisconnectedText>
          </DisconnectedLabel>
        )}
        <ProjectNameTitleWrapper>
          <ProjectNameTitle>
            {projectName}
            <Icon>{setIcon(origin)}</Icon>
          </ProjectNameTitle>
          <ArchivedLabel 
            isArchived={!!isArchived} 
          />

       </ProjectNameTitleWrapper>
        <Customer>{clientName ?? null}</Customer>
      </ProjectNameWrapper>
    </React.Fragment>
  )
}
