import { Col, Form, Row, Select } from "antd";
import Search from "antd/lib/input/Search";
import { Radio } from "components/base";
import { useFormik, FormikProps, FormikConfig } from "formik";
import { TApiUsersAccountsFilters } from "services/api-client/users-accounts-api";
import { delayFunction } from "utils/functions";

export type TTeamMembersListFilters = TApiUsersAccountsFilters & {
  displayInvitations: boolean
}

export const useTeamMembersFiltersFormik = (props: Partial<FormikConfig<TTeamMembersListFilters>>) => {
  const formik = useFormik<TTeamMembersListFilters>({
    initialValues: {
      state: 'active',
      name: '',
      role: undefined,
      displayInvitations: false,
    },
    onSubmit: props.onSubmit || ((values) => {})
  })

  return formik
}

const TeamMembersFilters = ({
  formik, 
}: {
  formik: FormikProps<TTeamMembersListFilters>
}) => {
  const stateFilterValue = formik.values.displayInvitations ? 'invitations' : formik.values.state
  const roleFilterValue = formik.values.role || 'all'

  return (
    <Form>
      <Row>
        <Col span='8'>
          <Search
            disabled={formik.values.displayInvitations}
            value={formik.values.name}
            onChange={(e) => {
              formik.setFieldValue('name', e.target.value)
              // Delay and reset search on text change for 0.75 second
              delayFunction('search-on-team-filter', () => { formik.submitForm() }, 750)
            }}
            onSearch={formik.submitForm}
          />
        </Col>

        <Col span="1" />

        <Col span="5">
          <Radio.Group
            value={stateFilterValue}
            onChange={(e) => { 
              const newState = e.target.value

              if (newState == 'invitations') {
                formik.setFieldValue('displayInvitations', true)
              } else {
                formik.setFieldValue('displayInvitations', false)
                formik.setFieldValue('state', newState)
              }

              formik.submitForm() 
            }}
          >
            <Radio.Button value="active">Active </Radio.Button>
            <Radio.Button value="inactive">Inactive</Radio.Button>
            <Radio.Button value="invitations">Invitations</Radio.Button>
          </Radio.Group>

        </Col >

        <Col span="2">
          <Select
            disabled={formik.values.displayInvitations}
            value={roleFilterValue}
            onChange={(value) => { 
              if (value == 'all') {
                formik.setFieldValue('role', undefined)
              } else {
                formik.setFieldValue('role', value)
              }
              formik.submitForm() 
            }}
            placeholder="Role"
            style={{ width: '100px' }}
          >
            <Select.Option value="all">All</Select.Option>
            <Select.Option value="admin">Admin</Select.Option>
            <Select.Option value="member">Member</Select.Option>
          </Select>

        </Col>
      </Row>
    </Form>
  )
}

export default TeamMembersFilters