import { Card, Divider } from "antd"
import PageHeader from "components/layout/page-header"
import moment from "moment-timezone"
import useApprovalsNavigation from "./hooks/use-approvals-navigation"
import { usePeriodByDateQuery } from "@timenotes/shared/src/services/approvals/queries/use-period-by-date-query"
import { useApprovalRequestsGroupsQuery } from "@timenotes/shared/src/services/approvals/queries/use-approval-request-groups-query"
import ApprovalRequestsTable from "./components/approval-requests-table"
import PeriodStatusDropdown from "./components/period-status-dropdown"
import PeriodStatusSelect from "./components/period-status-select"
import { TApiApprovalRequestStatus, TApiPeriodStatus } from "@timenotes/shared/src/services/approvals/approvals-types"
import PeriodNavigation from "./components/period-navigation"
import { useEffect } from "react"
import { useQueryParams } from "hooks/app-router"
import useSearchParams from "hooks/use-search-params"
import useNoInitialEffect from "hooks/useNoInitialEffect"

const ApprovalsPeriodsPage = () => {
  useApprovalsNavigation()

  const [searchParams, setSearchParams] = useSearchParams<{
    periodDate?: string,
    status?: TApiApprovalRequestStatus
  }>()

  const { 
    periodQuery, 
    period,

    periodDate,
    setPeriodDate,
  } = usePeriodByDateQuery({
    date: searchParams.periodDate ? moment(searchParams.periodDate) : undefined
  }, {
    keepPreviousData: true,
  })

  useNoInitialEffect(() => {
    setSearchParams({
      periodDate: periodDate.format('YYYY-MM-DD')
    })
  }, [JSON.stringify(periodDate)])

  const {
    approvalRequestGroups,
    approvalRequestGroupsQuery,
    approvalRequestGroupsFilters,
    setApprovalRequestGroupsFilters,
  } = useApprovalRequestsGroupsQuery({
    periodId: period?.id,
    filters: {
      status: searchParams.status
    }
  })

  const setFiltersStatus = (status?: TApiApprovalRequestStatus) => {
    setApprovalRequestGroupsFilters({ status: status })
  }

  useNoInitialEffect(() => {
    setSearchParams({
      status: approvalRequestGroupsFilters?.status
    })
  }, [JSON.stringify(approvalRequestGroupsFilters?.status)])

  const isLoading = periodQuery.isLoading || approvalRequestGroupsQuery.isFetching

  const renderTableForStatus = (status: TApiApprovalRequestStatus) => {
    // Do not render the table status if status filter is active and does not equal the current filter
    if (!!approvalRequestGroupsFilters?.status && approvalRequestGroupsFilters.status != status) {
      return null
    }

    let statusLabel = {
      'pending': "Waiting for approval",
      'rejected': 'Rejected',
      'approved': "Approved",
      'open': "Waiting for submission",
    }[status]

    return (
      <>
        <Divider />

        <h3>{statusLabel}</h3>

        <ApprovalRequestsTable
          isLoading={isLoading}
          approvalRequests={approvalRequestGroups[status]}
          status={status}
        />
      </>
    )
  }

  return (
    <>
      <div className="page-content">
        <PageHeader
          title="Period Approvals"
          titleHint={
            "Your workspace is configured to work with 'monthly' periods. All the timesheets can be reviewed and approved in the 'monthly' intervals. You can change the settings, including locking individual periods in the workspace settings section. All the approved time logs per team member will be locked and can not be modified, without reopening."
          }
          customHeader={(

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                gap: '15px',
                width: '100%',
              }}
            >
              <PeriodStatusDropdown period={period} isLoading={isLoading} />

              <PeriodNavigation 
                period={period}
                onPeriodDateChange={setPeriodDate}
              />
           </div>
          )}
        />

        <Card>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: '15px',
              width: '100%',
            }}
          >
            <PeriodStatusSelect 
              value={approvalRequestGroupsFilters?.status}
              onChange={(statusValue) => setFiltersStatus(statusValue)}
            />
         </div>


        </Card>

        {renderTableForStatus('pending')}
        {renderTableForStatus('rejected')}
        {renderTableForStatus('open')}
        {renderTableForStatus('approved')}

      </div>
    </>
  )
}

export default ApprovalsPeriodsPage