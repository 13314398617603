import { Card, Form, Popover, message } from "antd"
import ProjectSelect from "../projects/project-select"
import TaskSelect from "../tasks/task-select"
import { useEffect, useState } from "react"
import { TApiProject, TApiTask, TApiTimeLog } from "services/api-client/types"
import styled from "styled-components"
import LogoImg from "components/base/logo"
import FormItem from "antd/lib/form/FormItem"
import useOnKeyDown from "hooks/use-on-key-down"
import { useNewProjectModal } from "../projects/new-project-modal-context"
import useNoInitialEffect from "hooks/useNoInitialEffect"
import { useNewTaskModal } from "../tasks/new-task-modal-context"
import ReactJson from "react-json-view"
import { useTimeLogFormik } from "@timenotes/shared/src/services/time-logs/time-logs.hooks"
import useFormikGenericErrorMessage from "services/utils/hooks/use-formik-generic-error-message"

type TimeLogTaskSelect = {
  timeLog: TApiTimeLog
  projectSelectProps?: React.ComponentProps<typeof ProjectSelect>
  taskSelectProps?: React.ComponentProps<typeof TaskSelect>
  onSuccess?(timeLog: TApiTimeLog): void
}

const Wrapper = styled.div`
  & .time-log-task-select-wrapper {
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
  }

  &:hover .time-log-task-select-wrapper, .time-log-task-select-wrapper.open {
    background-color: #eee;
  }
`

const TimeLogTaskSelect = (props: TimeLogTaskSelect) => {
  const [open, setOpen] = useState(false)

  const newProjectModal = useNewProjectModal()
  const newTaskModal = useNewTaskModal()

  // Deterimine that this particular component is the one creating new project
  // and taking action on closing the new project modal
  const [isCreatingNewProject, setIsCreatingNewProject] = useState(false)

  const { formik } = useTimeLogFormik({
    initialValues: props.timeLog,
    onSuccess: (newTimeLog) => {
      if (props.onSuccess) props.onSuccess(newTimeLog)
      setOpen(false)
    }
  })

  useFormikGenericErrorMessage(formik)

  // Enable reinitialize changes too much
  useEffect(() => {
    formik.setValues(props.timeLog)
  }, [props.timeLog])

  useNoInitialEffect(() => {
    if (!newProjectModal.visible && isCreatingNewProject) {
      setIsCreatingNewProject(false)
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [newProjectModal.visible])

  // Deterimine that this particular component is the one creating new task
  // and taking action on closing the new project modal
  const [isCreatingNewTask, setIsCreatingNewTask] = useState(false)

  useNoInitialEffect(() => {
    if (!newTaskModal.visible && isCreatingNewTask) {
      setIsCreatingNewTask(false)
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [newTaskModal.visible])

  useOnKeyDown(['Escape'], () => {
    if (open) setOpen(false)
  })

  const project = props.timeLog.project
  const task = props.timeLog.task

  if (!project || !task) {
    return "No project"
  }

  const popoverView = (
    <Form
      layout="vertical"
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >

      <FormItem
        label="Project"
      >
        <ProjectSelect
          value={formik.values.project}
          onChange={(newProject) => {
            formik.setFieldValue('project', newProject)
            formik.setFieldValue('task', undefined)
            message.warning("Remember to assign a task from the new project!")
          }}
          onCreate={() => {
            setIsCreatingNewProject(true)
          }}
          {...props.projectSelectProps}
        />
      </FormItem>

      <FormItem
        label="Task"
      >
        <TaskSelect
          project={formik.values.project}
          value={formik.values.task}
          onCreate={() => {
            setIsCreatingNewTask(true)

            newTaskModal.setOnSuccess((task) => {
              // trigger below on change again as default behaviour but also ensure popup does not repear by
              // marking creating new task to false
              setIsCreatingNewTask(false)
              formik.setFieldValue('task', task)
              formik.submitForm()
            })
          }}
          onChange={(task) => {
            formik.setFieldValue('task', task)
            formik.submitForm()
          }}
          {...props.taskSelectProps}
        />
      </FormItem>
    </Form>
  )

  const view = (
    <Wrapper>
      <div className={open ? 'time-log-task-select-wrapper open' : 'time-log-task-select-wrapper'}>
        <div>
          <b>
            <span>{task.name}</span>
          </b>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          gap: '5px',
        }}>
          <LogoImg name={project.origin as any} size="12px" /> <span>{project.name}</span>
        </div>
      </div>
    </Wrapper>
  )

  return (
    <Popover
      placement='bottom'
      visible={open}
      trigger={'click'}
      getPopupContainer={(node) => {
        const modal = document.querySelector('.ant-modal-root')
        if (modal) return modal
        return node
      }}
      onVisibleChange={setOpen}
      destroyTooltipOnHide={true}
      overlayInnerStyle={{
        width: '300px'
      }}
      content={popoverView}
    >
      {view}
    </Popover>
  )
}

export default TimeLogTaskSelect