import { TApiTimeLog } from "@timenotes/shared/src/services/api-client/types"
import { useTimeLogsQuery } from "@timenotes/shared/src/services/time-logs/queries/use-time-logs-query"
import { Button } from "antd"
import TimeLogsTable from "components/timenotes/time-logs/time-logs-table"
import TimeLogForm, { TimeLogFormProps } from "components/timenotes/timer/time-log-form"
import { map, max } from "lodash"
import moment, { Moment } from "moment-timezone"
import { useEffect, useState } from "react"

interface BaseProps {
  date: Moment
  skipHeader?: boolean
  skipTracker?: boolean
  onLatestEndAtChange?(date: Moment): void
  initialValues?: TimeLogFormProps['initialValues']

  timeLogs?: TApiTimeLog[]
  onUpdate?(): void
}

interface WithExternalTimeLogs {
  timeLogs?: TApiTimeLog[]
  onUpdate?(): void
  isLoading?: boolean
}

interface WithoutExternalTimeLogs {
  timeLogs?: never
  onUpdate?: never
  isLoading?: never
}

// Ensure if timeLogs prop is provided, onUpdate also needs to be added 
type Props = BaseProps & (WithExternalTimeLogs | WithoutExternalTimeLogs)

const TimerTable = (props: Props) => {
  const isExternalData = (props.timeLogs !== undefined)

  const date = props.date

  const skipTracker = !!props.skipTracker
  const [formVisible, setFormVisible] = useState(false)

  const [formDate, setFormDate] = useState(moment(date).utc().set({ hour: 8, minute: 0, second: 0}))

  const timeLogsQuery = useTimeLogsQuery({
    params: {
      filters: {
        from: props.date.format('YYYY.MM.DD'),
        to: props.date.format('YYYY.MM.DD'),
      }
    },
    options: {
      enabled: !isExternalData
    }
  })

  const isLoading = isExternalData ? !!props.isLoading : timeLogsQuery.isLoading
  const timeLogs = isExternalData ? (props.timeLogs || []) : timeLogsQuery.data?.timeLogs
  const onUpdate = isExternalData ? props.onUpdate : timeLogsQuery.refetch

  useEffect(() =>{
    const latestEndAt = max(map(timeLogsQuery.data?.timeLogs, (timeLog) => {
      const endAt = moment(timeLog.startAt).utc().add(timeLog.duration, 'm')

      return endAt
    }))

    if (latestEndAt && (latestEndAt.get('date') - date.get('date') == 0)) {
      setFormDate(latestEndAt)
    }
  }, [timeLogsQuery.data?.timeLogs])

  useEffect(() => {
      if (props.onLatestEndAtChange) props.onLatestEndAtChange(formDate)
    }
  , [formDate])

  return (
    <div>
      { !props.skipHeader && (
        <div className="timesheet-table-day-header">
          <div style={{ overflow: 'hidden' }}>
            <div style={{ float: 'left' }}>
              <h3>{date.format('ddd, D MMM')}</h3>
            </div>

            {!formVisible && (
              <Button
                className="timesheet-table-add-time-log-button"
                size="small"
                style={{ float: 'right' }}
                onClick={() => {
                  setFormVisible(true)
                }}
              >
                Add time log
              </Button>
            )}
          </div>

          {formVisible && (
            <div>
              <TimeLogForm 
                mode="manual"
                enableUsersAccountSelect={false}
                date={formDate}
                initialValues={props.initialValues}
                onSuccess={onUpdate}
              />
            </div>
          )}
        </div>
      )}

      <TimeLogsTable
        noQueryMode
        loading={isLoading}
        dataSource={timeLogs}
        refetch={onUpdate}
        skipTracker={skipTracker}
      />
    </div>
  )
}
export default TimerTable