import { useEffect } from "react"
import { TApiHiddenPage } from "../api-client/settings-api"
import { usePermissionsQuery } from "../permissions/permissions.hooks"
import { getHiddenPagePermissionName, storeGetHiddenPages } from "./hidden-pages.utils"

export default function useCanDisplayPage(page: TApiHiddenPage) {
  const permissionName = getHiddenPagePermissionName(page)

  const permissionsQuery = usePermissionsQuery()

  if (permissionsQuery.isFetched) {
    const canDisplay = permissionsQuery.data.permissions[permissionName]
    if (!canDisplay) return false
  }

  return true
}