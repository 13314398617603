import { UseQueryOptions, useQuery } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiMembersGroup, TApiParams } from "../../api-client/types"
import { TApiGenericError, TApiGenericResponse } from "../../timenotes-query/timenotes-query.types"
import { TApiProjectsGroup } from "../../api-client/projects-groups-api"

interface Params extends TApiParams {
  projectId: string
}

interface Result {
  projectsGroups: TApiProjectsGroup[]
}

export const useProjectsGroupsQuery = (params: Params, options?: UseQueryOptions<any, any>) => {
  const apiClient = useApiClient()

  const query = useQuery<Result, TApiGenericError<any>>(
    ['projects-groups', (params || {})] as const,
    async () => {
      const response = await apiClient.getProjectsGroups(params.projectId)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        projectsGroups: response.projectsGroups || []
      }
    },
    {
      ...options
    }
  )

  return { query }
}

export default useProjectsGroupsQuery