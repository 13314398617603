import { Card, Modal, Select, Skeleton, message } from "antd"
import PageHeader from "components/layout/page-header"
import { useEffect, useState } from "react"
import { useTopNavigationLinks } from "hooks/layout"
import { useTags } from "hooks/tags"
import TagsTable from "pages/tags/components/tags-table"
import TagForm from "pages/tags/components/tag-form"
import { useGetPagePermissions } from "hooks/permissions.hooks"

const TagsPage = () => {
  useTopNavigationLinks([{
    label: 'TAGS',
    urlMatcher: "tags",
    onClick: () => {},
  }])
  const [newItemModalVisible, setNewItemModalVisible] = useState(false)

  const [statusFilter, setStatusFilter] = useState<string>('active')
  const { tags, filters, loading, fetchTags, deleteTag, setFilters } = useTags({
    filters: {
      statuses: [statusFilter]
    }
  })

  const { permissions, permissionsLoaded } = useGetPagePermissions()

  useEffect(() => {
    setFilters({
      statuses: [statusFilter]
    })
  }, [statusFilter])

  const pageLoading = loading || !permissionsLoaded

  return (
    <div className="page-content" style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: '800px', width: '100%' }}>
        <PageHeader
          title="Tags"
          buttons={[
            {
              title: "Add tag",
              disabled: !permissions?.manageTags,
              disabledTooltip: 'You have no permissions to add tags!',
              type: 'primary',
              callback: () => {
                setNewItemModalVisible(true)
              }
            }
          ]}
          customHeader={(
            <Select
              style={{ width: '100px' }}
              value={statusFilter}
              onChange={(newValue) => {
                setStatusFilter(newValue)
              }}
            >
              <Select.Option value='active' key="active">Active</Select.Option>
              <Select.Option value='archived' key="archived">Archived</Select.Option>
            </Select>
          )}
        />

        <div style={{ height: '25px' }} />

        {pageLoading ? (
          <Card>
            <Skeleton />
            <Skeleton />
          </Card>
        ) : (
          <TagsTable
            tags={tags}
            refresh={fetchTags}
          />
        )}

        <Modal
          title={"Add tag"}
          destroyOnClose={true}
          visible={newItemModalVisible}
          onCancel={() => { setNewItemModalVisible(false) }}
          footer={false}
        >
          <TagForm
            onSuccess={(tag, addAnother) => {
              if (!addAnother) {
                setNewItemModalVisible(false)
              }

              message.success('New tag added!')
              fetchTags()
            }}
          />
        </Modal>
      </div>
    </div>
  )
}

export default TagsPage
