import { TopNavLinkItem } from "components/layout/top-nav-links"
import { useTopNavigationLinks } from "hooks/layout"
import { usePagePermissions } from "hooks/permissions.hooks"
import { useHistory, useParams } from "react-router-dom"

export default function useApprovalsNavigation() {
  const history = useHistory()
  const queryParams = useParams<{
    periodId?: string
  }>() // periodId?: string

  const { permissions } = usePagePermissions()
  const canManageApprovals = !!permissions?.manageApprovals

  let links: TopNavLinkItem[] = []
  if (canManageApprovals) {
    links = [
      {
        label: 'PERIODS',
        urlMatcher: "approvals/periods",
        onClick: () => {
          history.push('/approvals/periods')
        },
      },
      {
        label: 'PERIOD APPROVALS',
        urlMatcher: "approvals/period-overview",
        onClick: () => {
          history.push('/approvals/period-overview')
        },
      },
      {
        label: 'MEMBER APPROVALS',
        urlMatcher: "approvals/members",
        onClick: () => {
          history.push('/approvals/members')
        },
      }]
  } else {
    links = [
      {
        label: 'APPROVALS',
        urlMatcher: "approvals/members",
        onClick: () => {
          history.push('/approvals/members')
        },
      },
    ]
  }

  if (queryParams.periodId) {
    links = [
      {
        label: "← BACK",
        onClick: () => history.push('/approvals')
      }
    ]
  }

  return useTopNavigationLinks(links)
}