import RegisterForm from '../../components/register-form'
import { Card, Row, Col } from "antd"
import { backgroundStyle } from '../styles'
import { useHistory } from 'react-router-dom'
import useApiClient from 'hooks/useApiClient'
import {useQueryParams} from 'hooks/app-router'

const RegisterPage = (props: any) => {
  // @ts-ignore
  const theme = 'light'
  const history = useHistory()
  const apiClient = useApiClient()

  const queryParams = useQueryParams<{
    'plan_id': string
  }>()

  const selectedPlanId = queryParams.plan_id
  if (selectedPlanId) {
    apiClient.setSelectedPlanId(selectedPlanId)
  }

  return (
    <div className="h-100" style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card>
              <div className="my-2">
                <div className="text-center">
                  <img className="img-fluid" src={`/img/${theme === 'light' ? 'logo.png': 'logo-white.png'}`} alt="" />
                  <h3>Create a new account:</h3>
                  <p>Have an account already?{" "}
                    <a
                      onClick={() => {
                        history.push('/auth/login')
                      }}
                    >
                      Sign in
                    </a>
                  </p>

                </div>
                <Row justify="center">
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <RegisterForm {...props}/>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default RegisterPage
