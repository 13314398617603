import styled from "styled-components"
import { ArchiveSvg } from "assets/svg/icon"

const ArchivedLabelWrapper = styled.div`
  max-height: 25px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 7px;
  background: rgba(37, 37, 37, 0.2);
  border-radius: 4px;
  margin-left: 15px;
  /* color: #474d55; */
`

const ArchivedText = styled.p`
  display: inline-block;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  color: #1e2023;
  margin-left: 3px;
`


const ArchivedLabel = ({
  isArchived,
  text,
}: {
  isArchived?: boolean
  text?: string
}) => {
  const labelText = text || 'Archived'

  return (
    <>
      {isArchived && (
        <ArchivedLabelWrapper>
          <ArchiveSvg />
          <ArchivedText>{labelText}</ArchivedText>
        </ArchivedLabelWrapper>
      )}
    </>
  )
}

export default ArchivedLabel