import { useApiClient } from "@timenotes/shared/src/services/api-client/api-client.hooks"
import { TApiIntegrationAccount } from "@timenotes/shared/src/services/api-client/types"
import { TApiGenericError } from "@timenotes/shared/src/services/timenotes-query/timenotes-query.types"
import { map } from "lodash"
import { UseQueryOptions, useQuery } from "react-query"

interface IParams {
  integrationId?: string
}

interface IResult {
  integrationAccounts: TApiIntegrationAccount[]
}

interface IOptions extends UseQueryOptions<IResult, TApiGenericError<null>> {}

export const useIntegrationAccountsQuery = (params: IParams = {}, options?: IOptions) => {
  const apiClient = useApiClient()

  const requestParams = {
    integrationId: params.integrationId || null, 
  }

  const query = useQuery<IResult, TApiGenericError<null>>(
    ['integration-accounts', requestParams],
    async () => {
      const response = await apiClient.getAllIntegrationAccounts(requestParams)

      if (!response.ok) { throw response.errors }

      return {
        integrationAccounts: map(response.integrationAccounts, (intAccount) => {
          return {
            ...intAccount,
            isConnected: !!(intAccount.status == 'connected')
          }
        }),
      }
    },
    {
      ...options
    }
  )

  return { 
    query,
  }
}