import { TApiResponse, TApiParams } from "./types"
import BaseApi from './base'
import _map from 'lodash/map'
import _snakeCase from 'lodash/snakeCase'
import _camelCase from 'lodash/camelCase'
import { forEach } from "lodash"
import { API_HIDDEN_PAGES, getHiddenPagePermissionName } from "../hidden-pages/hidden-pages.utils"

export type TApiPermissionMap = {
  [key in TApiPerrmissionKey]: boolean
}

export type TApiPermissionObject = {
  name: TApiPerrmissionKey
  can?: null | boolean
  label?: TApiPerrmissionKey
  object?: {
    type: string
    id?: null | string
  }
}

export type TApiTeamPermissionKey = 'transferAccountOwnership' |
  'enableUser' | 'inviteUser' | 'filterUsers' | 'filterMembersGroups' |
  'resendInvitation' | 'editInvitation' | 'deleteInvitation' |
  'manageMembersGroups' | 'edit' | 'editSensitive' | 'editRole' | 
  'delete' | 'disable' |
  // absences
  'manageAbsenceTypes' | 'manageAbsenceRequests'
  // approvals
  | 'manageApprovals'
  // projects
  | 'createProjects'
  // clients
  | 'manage' | 'manageClients' | 'createClients' 
  // Tags
  | 'manageTags'
  // Setttings
  | 'manageSettings'
  // Alerts
  | 'manageAlerts'
  // Projects
  | 'manageProjectBillable'
  | 'manageProjectBudget'
  // Current Project Management
  | 'manageCurrentProject' | 'manageCurrentProjectBudget' | 'manageCurrentProjectBillable' | 'createCurrentProjectTasks'
  // Display pages
  | 'displayPages' | 'displayReportsPage' | 'displayTeamPage' | 'displayClientsPage' | 'displayProjectsPage'

export type TApiReportsPermissionKey =
  'viewAccessibleProjectsTimeLogs' |
  'setTimeLogUser' |
  'setTimeLogBillable' |
  'filterBillableTimelogs' |
  'create'

export type TApiPerrmissionKey = TApiTeamPermissionKey | TApiReportsPermissionKey

export type TApiTeamPermissionObject = TApiPermissionObject & {
  name: TApiTeamPermissionKey
}

export type TApiReportsPermissionObject = TApiPermissionObject & {
  name: TApiReportsPermissionKey
}

export interface IGetPermissionsCheckParams extends TApiParams {
  permissions: Array<TApiPermissionObject>
}

export interface IGetPermissionsCheckResponse extends TApiResponse {
  permissions: Array<TApiPermissionObject>
}

export interface IGetTeamPermissionsCheckResponse extends TApiResponse {
  permissions: Array<TApiTeamPermissionObject>
}

export interface IGetReportsPermissionsCheckResponse extends TApiResponse {
  permissions: Array<TApiReportsPermissionObject>
}

export type TApiPermissionScope = 'team' | 'reports' | 'timesheets' | 'projects' | 'currentProject'

const getPermsions = (keys: string[], object?: TApiPermissionObject['object']): TApiPermissionObject[] => {
  const permisions = _map(keys, (key) => {
    return {
      name: key as TApiPerrmissionKey,
      object: object || {
        type: 'Account'
      }
    }
  })

  return permisions
}


class PermissionsApi extends BaseApi {

  async getPermissions(permissionScope: TApiPermissionScope, args?: object) {
    if (permissionScope != 'currentProject') {
      return this.getAllPermissions()
    } else {
      return this.getCurrentProjectPermissions(args as any)
    }
  }

  async getAllPermissions(): Promise<IGetPermissionsCheckResponse> {
    let params: TApiPermissionObject[] = getPermsions([
      // Account permisions
      'transferAccountOwnership',
      'enableUser',
      'inviteUser',
      'filterUsers',
      'filterMembersGroups',
      'setTimeLogBillable',
      // Absences
      'manageAbsenceTypes',
      'manageAbsenceRequests',
      // Tags,
      'manageTags',
      // Alerts,
      'manageAlerts',
      // Reports
      'viewAccessibleProjectsTimeLogs', // can potentially see other users
      'setTimeLogBillable', // if user can set it up -> he can also filter it
      'filterBillableTimelogs', // TODO: fix Timelogs to TimeLogs -> used to check if user can see billable amount
    ])

    params.push({
      // @ts-ignore -> diff API name then our label
      name: 'manageBillable',
      label: 'manageProjectBillable',
      object: {
        type: "Project",
      }
   })

    params.push({
      // @ts-ignore -> diff API name then our label
      name: 'manageBudget',
      // Adding label makes it return from the backend and actually changes the permission check name
      label: 'manageProjectBudget',
      object: {
        type: "Project",
      }
    })

    params = params.concat(getPermsions([
      'create', // can potentially edit other users tasks
    ],
      {
        type: 'Project'
      }
    ))

    params = params.concat(getPermsions([
      'resendInvitation',
      'editInvitation',
      'deleteInvitation',
    ],
      {
        type: 'Invitation',
      }
    ))

    params.push({
      name: 'manageMembersGroups',
      object: {
        type: "MembersGroup"
      }
    })

    // Projects
    params.push({
      name: 'create',
      // Adding label makes it return from the backend and actually changes the permission check name
      label: 'createProjects',
      object: {
        type: "Project"
      }
    })

    // CLIENTS

    params.push({
      name: 'manage',
      // Adding label makes it return from the backend and actually changes the permission check name
      label: 'manageClients',
      object: {
        type: "Client"
      }
    })

    params.push({
      name: 'create',
      // Adding label makes it return from the backend and actually changes the permission check name
      label: 'createClients',
      object: {
        type: "Client"
      }
    })

    // SETTINGS 

    params.push({
      name: 'manage',
      // Adding label makes it return from the backend and actually changes the permission check name
      label: 'manageSettings',
      object: {
        type: "Setting"
      }
    })

    params = params.concat(getPermsions([
      'edit',
      'editSensitive',
      'editRole',
      'delete',
      'disable',
      // Reports
      'setTimeLogUser', // can potentially edit other users tasks
    ],
      {
        type: 'UsersAccount'
      }
    ))

    // APPROVALS

    params.push({
      name: 'manage',
      // Adding label makes it return from the backend and actually changes the permission check name
      label: 'manageApprovals',
      object: {
        type: "Approvals::Period"
      }
    })

    forEach(API_HIDDEN_PAGES, (page) => {
      // Display pages
      params.push({
        name: 'displayPages',
        // Adding label makes it return from the backend and actually changes the permission check name
        label: getHiddenPagePermissionName(page),
        object: {
          type: "String",
          id: page,
        }
      })
    })

    return this.getPermissionsCheck(params).then((response) => {
      return response
    })
  }

  async getCurrentProjectPermissions(args: any): Promise<IGetPermissionsCheckResponse> {
    const projectId = args.projectId
    let params: TApiPermissionObject[] = getPermsions([])

    params.push({
      // @ts-ignore
      name: 'manage_project',
      // Adding label makes it return from the backend and actually changes the permission check name
      label: 'manageCurrentProject',
      object: {
        type: "Project",
        id: projectId
      }
    })

    params.push({
      // @ts-ignore -> diff API name then our label
      name: 'manageBudget',
      // Adding label makes it return from the backend and actually changes the permission check name
      label: 'manageCurrentProjectBudget',
      object: {
        type: "Project",
        id: projectId
      }
    })

    params.push({
      // @ts-ignore -> diff API name then our label
      name: 'createTask',
      // Adding label makes it return from the backend and actually changes the permission check name
      label: 'createCurrentProjectTasks',
      object: {
        type: "Project",
        id: projectId
      }
    })

    params.push({
      // @ts-ignore -> diff API name then our label
      name: 'manageBillable',
      // Adding label makes it return from the backend and actually changes the permission check name
      label: 'manageCurrentProjectBillable',
      object: {
        type: "Project",
        id: projectId
      }
    })

    return this.getPermissionsCheck(params).then((response) => {
      return response
    })
  }

  async getPermissionsCheck(params: Array<TApiPermissionObject>): Promise<IGetPermissionsCheckResponse> {
    const requestParams = _map(params, (apiPermissionObject) => {
      return {
        ...apiPermissionObject,
        name: _snakeCase(apiPermissionObject.name),
      }
    })

    let response = await fetch(`${this.rootUrl}/permissions/check`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        permissions: this.paramsToSnakeCase(requestParams)
      }),
    })

    let responseBody = await this.getResponseJson(response)

    const responsePermissions = _map(responseBody.permissions, (apiPermissionObject) => {
      return {
        ...apiPermissionObject,
        name: _camelCase(apiPermissionObject.name),
      }
    })

    return { 
      ...responseBody,
      permissions: responsePermissions,
    }
  }
}

export default PermissionsApi
