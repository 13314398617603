import { useApiClient } from "@timenotes/shared/src/services/api-client/api-client.hooks"
import { TApiGenericError } from "@timenotes/shared/src/services/timenotes-query/timenotes-query.types"
import { UseQueryOptions, useQuery } from "react-query"
import { TApiIntegrationAccount } from "services/api-client/types"

interface IParams {
  integrationAccountId?: string
}

interface IResult {
  integrationAccount: TApiIntegrationAccount
}

interface IOptions extends UseQueryOptions<IResult, TApiGenericError<null>> {}

export const useIntegrationAccountQuery = (params: IParams = {}, options?: IOptions) => {
  const apiClient = useApiClient()

  const requestParams = {
    integrationAccountId: params.integrationAccountId || '', 
  }

  const query = useQuery<IResult, TApiGenericError<null>>(
    ['integration-accounts', params.integrationAccountId, requestParams],
    async () => {
      const response = await apiClient.getIntegrationAccount(requestParams)

      if (!response.ok) { throw response.errors }

      return {
        integrationAccount: {
            ...response.integrationAccount,
            isConnected: !!(response.integrationAccount.status == 'connected')
          }
      }
    },
    {
      enabled: !!params.integrationAccountId,
      ...options,
    }
  )

  return { 
    query,
  }
}