import { toAmountWithCurrency } from 'utils/string-utils'
import DashboardNumberCard from './dashboard-number-card';
import { useDefaultCurrencyIso } from 'hooks/settings';

export const ProjectBillableCard = ({ projectInsights }: { projectInsights: any }): JSX.Element => {

  const { defaultCurrencyIso } = useDefaultCurrencyIso()

  // @ts-ignore
  const billableAmount = parseFloat(projectInsights.totals.billableAmount)
  const costAmount = parseFloat(projectInsights.totals.costAmount)
  const profitAmount = billableAmount - costAmount

  return (
    <DashboardNumberCard
      color={profitAmount > 0 ? undefined : 'red'}
      title="TOTAL PROFIT"
      numberFormatted={toAmountWithCurrency(profitAmount, defaultCurrencyIso)}
      description1={`Billable revenue: ${toAmountWithCurrency(billableAmount, defaultCurrencyIso)}`}
      description2={`Total cost: ${toAmountWithCurrency(costAmount, defaultCurrencyIso)}`} />
  )
}

export default ProjectBillableCard