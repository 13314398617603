import { UseQueryOptions, useQuery } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiMembersGroup, TApiParams } from "../../api-client/types"
import { TApiGenericError, TApiGenericResponse } from "../../timenotes-query/timenotes-query.types"

interface Params extends TApiParams {}

interface Result {
  membersGroups: TApiMembersGroup[]
}

const PER_PAGE = 1000

export const useMembersGroupsListQuery = (params: Params = {}, options?: UseQueryOptions<any, any>) => {
  const apiClient = useApiClient()

  const query = useQuery<Result, TApiGenericError<any>>(
    ['members-groups-list', (params || {})] as const,
    async () => {

      const requestParams = {
        ...params,
        perPage: params.perPage || PER_PAGE
      }

      const response = await apiClient.getMembersGroupsList(requestParams)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        membersGroups: response.membersGroups || [],
      }
    },
    {
      ...options
    }
  )

  return query
}