import { asanaLogo, basecampLogo, githubLogo, timenotesLogo, todoistLogo, trelloLogo } from 'assets/logos'
import { AppName } from 'consts'

type logoMapInterface = {
  [key in AppName | 'timenotes']: string
}

export const logoMap: logoMapInterface = {
  'asana': asanaLogo,
  'basecamp': basecampLogo,
  'todoist': todoistLogo,
  'trello': trelloLogo,
  'github': githubLogo,
  'timenotes': timenotesLogo,
}

const LogoImg = ({ name, style, size }: { name: AppName, style?: React.CSSProperties, size?: string }): JSX.Element => {

  const logoAsset = logoMap[name]

  if (!logoAsset) {
    console.error(`No Logo asset found for the name ${name}`)

    return <></>
  }

  const imgStyle = style || {}

  if (size) {
    imgStyle['width'] = size
    imgStyle['height'] = size
  }

  return (
    <img src={logoAsset} style={{ width: '24px', height: '24px', ...imgStyle }} />
  )
}

export default LogoImg