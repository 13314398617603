import { Avatar, Divider, Dropdown } from "antd"
import { Button, Card, Row } from "components/base"
import TimenotesTheme from "components/layout-components/timenotes-theme"
import OutsideAlerter from "components/view-utils/outside-alerter"
import { useEffect } from "react"
import styled from "styled-components"
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import { FolderAddOutlined } from '@ant-design/icons'
import useApiClient from "hooks/useApiClient"
import { useHistory } from "react-router-dom"
import { useApiCall } from "hooks/useApiCall"
import PageLoadingSpin from "components/layout/page-loading-spin"
import { useIntercomShutdown } from "services/timenotes-intercom-provider"
import { useCurrentUser } from "hooks/current-user"
import { TApiUsersAccount } from "@timenotes/shared/src/services/api-client/types"

const usersAccountDecorator = (usersAccount: TApiUsersAccount) => {
  const username = `${usersAccount.firstName} ${usersAccount.lastName}`
  const initials = username.split(' ').map((word) => { return word[0] }).join('')
  const workspaceName = `${usersAccount.account?.name}`

  return {
    ...usersAccount,
    username,
    initials,
    workspaceName
  }
}

const CurrentUsersAccountView = ({ usersAccount }: { usersAccount: TApiUsersAccount } ) => {
  const { username, initials, workspaceName } = usersAccountDecorator(usersAccount)

  const history = useHistory()

  const handleAccountSettingsLinkClick = () => {
    history.push('/settings/workspace/general')
  }

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '200px', marginBottom: '10px' }}>
      <Avatar size="large" style={{ backgroundColor: TimenotesTheme.colors.primaryPurple }}>
        {initials}
      </Avatar>
      <span style={{ marginTop: '10px' }}><b>{username}</b></span>
      <span style={{ fontWeight: 'normal', marginBottom: '10px' }}>{workspaceName}</span>
      <Button
        onClick={handleAccountSettingsLinkClick}
      >
        Account Settings
      </Button>
    </div>
  )
}

const PanelUsersAccountListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;

  :hover {
    background-color: #f7f7f7;
  }
`

const PanelUsersAccountListItem = ({ usersAccount }: { usersAccount: TApiUsersAccount }) => {
  const { username, initials, workspaceName } = usersAccountDecorator(usersAccount)

  return (
    <PanelUsersAccountListItemWrapper>
      <Avatar style={{ marginRight: '5px', backgroundColor: "#ddd"}}>
        {initials}
      </Avatar>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ marginTop: '10px', fontSize: '14px' }}><b>{username}</b></span>
        <span style={{ fontWeight: 'normal', marginBottom: '10px' }}>{workspaceName}</span>
      </div>
    </PanelUsersAccountListItemWrapper>
  )
}

const PanelContent = () => {
  const shutdown = useIntercomShutdown()
  const history = useHistory()
  const apiClient = useApiClient()

  const {
    data: ownedUsersAccounts,
    loading: ownedUsersAccountsLoading,
    request: fetchOwnedUsersAccounts,
  } = useApiCall(async () => {
    return (await apiClient.getUsersAccountsOwned()).usersAccounts
  })

  const {
    currentUser
  } = useCurrentUser()

  useEffect(() => {
    fetchOwnedUsersAccounts()
  }, [])

  const onLogout = async () => {
    shutdown()
    await apiClient.logout()
    window.location.pathname = '/auth/login'
  }

  const switchToAccount = (accountId: string) => {
    // TODO: move to the client
    apiClient.accountId = accountId
    window.localStorage.setItem('accountId', accountId)
    window.location.reload()
  }

  const onCreateNewWorkspace = () => {
    history.push('/auth/create-account')
  }

  const loaded = !!currentUser?.id

  if (!loaded) {
    return null
  }

  const currentUsersAccount = currentUser

  const usersAccounts = _filter((ownedUsersAccounts || []), (usersAccount) => {
    return usersAccount.id !== currentUsersAccount.id
  })

  return (
    <Card>
      <CurrentUsersAccountView usersAccount={currentUsersAccount} />
      { ownedUsersAccountsLoading ? (
        <PageLoadingSpin />
      ) : (
        <>
          <Divider style={{ marginTop: '10px', marginBottom: '0px' }} />

          <ul>
            { usersAccounts.length != 0 && (
              <>
                { _map(usersAccounts, (ua) => { return (
                  <li onClick={() => { switchToAccount(ua.account?.id as string )}}>
                    <PanelUsersAccountListItem usersAccount={ua} />
                  </li>
                )})}
              </>
            )}

            <li>
              <PanelUsersAccountListItemWrapper 
                onClick={onCreateNewWorkspace}
                style={{ height: '40px' }}
              >
                <FolderAddOutlined />
                Create new workspace
              </PanelUsersAccountListItemWrapper>
            </li>
          </ul>
        </>
      )}

      <div style={{ display: "flex", justifyContent: 'center', marginTop: '10px' }}>
        <Button
          onClick={onLogout}
          style={{
            width: '100%'
          }}
        >
          Log out
        </Button>
      </div>
    </Card>
  )
}

const PanelInfoDiv = styled('div')`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  padding-bottom: 5px;
  justify-content: center;

  p {
    text-align: right;
    display: block;
    margin-bottom: 0px;
  }

  p.panel-info-username  {
    font-size: 14px;
    color: #474D55;
  }

  p.panel-info-workspace-name {
    font-size: 12px;
    color: #B9B9C4;
  }
`

const PanelInfo = () => {
  const { 
    currentUser,
    loading,
  } = useCurrentUser()

  const loaded = !!currentUser?.id

  if (!loaded) {
    return null
  }

  // IF SOMETHING IS NOT LOADED - RETURN NULL
  if (!currentUser?.firstName) {
    console.error("Attempt to render users account panel when not loaded!")
    return null
  }

  const { username, initials, workspaceName } = usersAccountDecorator(currentUser)

  return (
    <Row style={{ height: '100%' }}>
      <PanelInfoDiv>
        <p className="panel-info-username">{username}</p>
        <p className="panel-info-workspace-name">{workspaceName}</p>
      </PanelInfoDiv>
      <div style={{ height: '100%'}}>
        <Avatar style={{ marginLeft: '15px', marginBottom: '10px', backgroundColor: TimenotesTheme.colors.primaryPurple }}>
          {initials || ".."}
        </Avatar>
      </div>
    </Row>
  )
}

export interface IAccountPanel {
  visible: boolean
  onClose(): void
}

const AccountPanel = ({ visible, onClose }: IAccountPanel ) => {
  return (
    <OutsideAlerter
      onClick={onClose}
      getInnerNode={() => { return document.getElementById('timenotes-account-panel') as HTMLElement }}
      wrapperStyle={{ height: '100%' }}
    >
      <Dropdown
        visible={visible}
        overlay={<PanelContent />}
        getPopupContainer={() => { return document.getElementById('timenotes-account-panel') as HTMLElement }}
      >
        <PanelInfo />
      </Dropdown>
    </OutsideAlerter>
  )
}

export default AccountPanel