import { UseQueryOptions, useQuery } from "react-query"
import { TApiProject } from "../../api-client/types"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiGenericError } from "../../timenotes-query/timenotes-query.types"
import { IGetProjectsListParams } from "../../api-client/projects-api"

interface useProjectsForFiltersQueryParams extends IGetProjectsListParams {}

type Result = {
  projects: TApiProject[]
  activeOptions: {
    value: TApiProject
  }[],
  archivedOptions: {
    value: TApiProject
  }[],
}
const PER_PAGE = 50

export const useProjectsForFiltersQuery = (params: useProjectsForFiltersQueryParams, options: UseQueryOptions<Result, TApiGenericError<null>> = {}) => {
  const apiClient = useApiClient()

  const query = useQuery<Result, TApiGenericError<null>>(
    ['projects-for-filters', params],
    async () => {
      const response = await apiClient.get('/projects/batch', { 
        perPage: PER_PAGE,
        ...params, 
      })

      if (!response.ok) {
        throw response.errors
      }

      const projects = response.projects || []

      // @ts-ignore // API returns active true/false
      const activeOptions = projects.filter((p: TApiProject) => !!p.active)
      // @ts-ignore
      const archivedOptions = projects.filter((p: TApiProject) => !p.active)

      return {
        projects: response.projects,
        activeOptions: activeOptions.map((p) => ({ value: p })),
        archivedOptions: archivedOptions.map((p) => ({ value: p })),
      }
    },
    {
      ...options
    }
  )

  return query
}

export default useProjectsForFiltersQuery