import { TApiApprovalRequest, TApiApprovalRequestStatus } from "@timenotes/shared/src/services/approvals/approvals-types"
import { ColumnType } from "antd/lib/table"
import { toDurationString } from "components/forms/duration-input"
import { TGenericButton } from "components/generic-buttons-list/generic-buttons-list"
import GenericTable, { GenericTableParams } from "components/generic-table/generic-table"
import { COLORS } from "components/layout-components/timenotes-theme"
import { useHistory } from "react-router-dom"
import ApprovalRequestProgressBar from "./approval-request-progress-bar"
import useApprovalRequestsBulkUpdateStatusMutation from "@timenotes/shared/src/services/approvals/queries/use-approval-requests-bulk-update-status-mutation"
import { useEffect, useState } from "react"
import { Tag } from "antd"
import { useCurrentUser } from "hooks/current-user"
import { TApiUsersAccount } from "@timenotes/shared/src/services/api-client/types"
import ApprovalRequestButtons from "./approval-request-buttons"
import { useDefaultDateFormat } from "hooks/settings"
import ApprovalNotesIcon from "./approval-notes-icon"
import ApprovalStatusTag from "./approval-status-tag"
import { capitalize } from "lodash"

interface ApprovalRequestsTableProps {
  isSingleUserTable?: boolean
  status?: TApiApprovalRequestStatus
  approvalRequests: TApiApprovalRequest[]
  isLoading: boolean
}

export default function ApprovalRequestsTable(props: ApprovalRequestsTableProps) {
  const { currentUser }: { currentUser: TApiUsersAccount } = useCurrentUser()

  const isSingleUserTable = !!props.isSingleUserTable

  const {
    status,
    approvalRequests,
    isLoading,
  } = props

  const approvalPeriods = approvalRequests
  const history = useHistory()

  const { defaultDateFormat } = useDefaultDateFormat()

  const {
    examineUserApprovalRequest,
    bulkExamineUserApprovalRequests,
  } = useApprovalRequestsBulkUpdateStatusMutation({
    onSuccess: () => {
      setSelectedRowKeys([])
    }
  })

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [bulkButtons, setBulkButtons] = useState<TGenericButton[]>([])

  const [approvalsPeriodId, setApprovalsPeriodId] = useState<string>("")

  useEffect(() => { const approvalsPeriodId = approvalRequests[0]?.approvalsPeriodId as string

    if (approvalsPeriodId) {
      setApprovalsPeriodId(approvalsPeriodId)
    }
  }, [approvalRequests])

  const bulkExamineSelected = (status: 'approve' | 'reject' | 'cancel' | 'withdraw' | 'submit' | 'resubmit') => {
    bulkExamineUserApprovalRequests({
      usersAccountIds: selectedRowKeys,
      periodId: approvalsPeriodId,
      decision: status,
    })
  }

  const FIRST_COLUMN = isSingleUserTable ? {
    title: 'Timespan',
    width: '185px',
    render: (text: any, record: TApiApprovalRequest) => (
      <span>
        {record.approvalsPeriod?.timespanString}
      </span>
    )
  } : {
    title: 'Team Member',
    width: '230px',
    render: (text: any, record: TApiApprovalRequest) => (
      <span>
        {record.usersAccount.firstName} {record.usersAccount.lastName}
      </span>
    )
  }

  const COLUMNS: ColumnType<TApiApprovalRequest>[] = [
    FIRST_COLUMN,
   {
      title: 'Status',
      width: '240px',
      key: 'status',
      dataIndex: 'status',
      render: (t, record) => {
        // @ts-ignore
        let title = {
          approved: `Approved by ${record.verifier?.firstName} ${record.verifier?.lastName}`,
          rejected: `Rejected by ${record.verifier?.firstName} ${record.verifier?.lastName}`,
        }[record.status] || capitalize(record.status)

        let tag = <ApprovalStatusTag status={record.status}>{title}</ApprovalStatusTag>

        return (
          <>
            {tag}
            <span style={{ position: 'absolute', paddingTop: '4px' }}><ApprovalNotesIcon notes={record.notes as Array} /></span>
          </>
        )
      },
    },
    {
      title: 'Hours',
      width: '400px',
      render: (text, record) => (
        <div 
          style={{
            display: 'flex'
          }}
        >
          <span
            style={{
              width: '260px'
            }}
          >
            {toDurationString(record.duration)} / {toDurationString(record.limit)}
          </span>
          <ApprovalRequestProgressBar approvalRequest={record} />
        </div>
      )
    },
    {
      //title: 'Actions',
      //width: '15%',
      key: 'actions_',
      render: (t, record) => {

        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <ApprovalRequestButtons
              withDetailsLink
              approvalRequest={record}
            />
          </div>
        )
      }
    }
  ]

  const tableParams: GenericTableParams = {
    pagination: null,
    sorting: null,
    selectedAll: false,
    selectedRowKeys: selectedRowKeys,
  }

  useEffect(() => {
    let buttons: TGenericButton[] = []

    // No bulk for single user table
    if (!isSingleUserTable) {
      if (status == 'pending') {
        buttons = [
          {
            title: 'Approve',
            callback: () => {
              bulkExamineSelected('approve')
            },
            size: 'small',
            type: 'primary',
          },
          {
            title: 'Reject',
            callback: () => {
              bulkExamineSelected('reject')
            },
            size: 'small',
            danger: true,
          },
        ]
      }

      if (status == 'approved') {
        buttons = [
          {
            title: 'Withdraw',
            callback: () => {
              bulkExamineSelected('withdraw')
            },
            size: 'small',
            danger: true,
          },
        ]
      }

      if (status == 'open') {
        buttons = [
          {
            title: 'Submit',
            callback: () => {
              bulkExamineSelected('submit')
            },
            size: 'small',
            type: 'primary',
          },
        ]
      }

      if (status == 'rejected') {
        buttons = [
          {
            title: 'Resubmit',
            callback: () => {
              bulkExamineSelected('resubmit')
            },
            size: 'small',
            type: 'primary',
          },
        ]
      }

    }
    setBulkButtons(buttons)
  }, [status, selectedRowKeys.join(','), approvalsPeriodId])

  const onChange = (params: any) => { 
    setSelectedRowKeys(params.selectedRowKeys)
  }

  return (
    <GenericTable<any>
      tableProps={{
        loading: isLoading,
        style: {
          marginTop: '20px',
        },
        dataSource: approvalPeriods,
        columns: COLUMNS,
        rowKey: (record) => { return isSingleUserTable ? record.approvalsPeriodId : record.usersAccount.id },
      }}

      tableParams={tableParams}

      rowColorSelector={(record) => {
        // @ts-ignore
        return {
          rejected: 'red',
          approved: COLORS.primaryGreen,
        }[record.status] || '#E5E5EB'
      }}

      bulkButtons={isSingleUserTable ? undefined : bulkButtons}

      onChange={onChange}
    />

  )
}