import { TApiApprovalRequest } from "@timenotes/shared/src/services/approvals/approvals-types"
import { TIMENOTES_COLORS } from "components/forms/color-picker-select"
import { COLORS } from "components/layout-components/timenotes-theme"
import ColorPicker from "components/shared-components/ColorPicker"
import HorizontalBar from "pages/dashboard/components/horizontal-bar"

interface ApprovalRequestProgressBarProps {
  approvalRequest: TApiApprovalRequest
}

export default function ApprovalRequestProgressBar(props: ApprovalRequestProgressBarProps) {
  const { 
    approvalRequest,
  } = props

  const duration = approvalRequest.duration
  const expectedDuration = approvalRequest.limit

  // Left segment duration is equal to difference left in period or 0 if duration is higher than expected period!
  const durationLeftPerPeriod = (expectedDuration > duration) ? (expectedDuration - duration) : 0

  let durationColor = COLORS.primaryGreen

  const durationPercentage = duration/expectedDuration
  if (durationPercentage < 0.40 || durationPercentage > 1.1) durationColor = "#D76666"

  const segments = [
    {
      title: 'Total time logs duration in period',
      value: duration,
      color: durationColor,
      tooltip: 'Total time logs duration in period',
    },
    {
      title: 'Duration budget not used in period',
      value: durationLeftPerPeriod,
      color: '#CDCDCD',
      tooltip: 'Duration budget not used in period',
    }
  ]

  return (
    <HorizontalBar 
      segments={segments}
    />
  )
}