import { ReactNode, createContext, useContext, useRef, useState } from "react"
import { Modal, message } from "antd"
import React from "react"
import TagForm from "pages/tags/components/tag-form"
import { TApiProject, TApiTag } from "services/api-client/types"
import NewProjectModal from "./new-project-modal"

type TInitialValues = React.ComponentProps<typeof NewProjectModal>['initialValues']
type TOnSuccessCallback = (project: TApiProject) => void

export type NewProjectModalContextProps = {
  visible: boolean
  setVisible(v: boolean): void

  initialValues: TInitialValues
  setInitialValues(values: TInitialValues): void

  onSuccess: TOnSuccessCallback
  setOnSuccess(fn: TOnSuccessCallback): void
}

export type NewProjectModalContextProviderProps = {
  children: ReactNode
}

const NewProjectModalContext = createContext<NewProjectModalContextProps | undefined>(undefined)

export const NewProjectModalContextProvider : React.FC<NewProjectModalContextProviderProps> = (props) => {
  const { children } = props

  const onSuccessRef = useRef<TOnSuccessCallback>((project) => { message.success(`New project "${project.name}" added!`) })

  const onSuccess = (project: TApiProject) => {
    if (onSuccessRef.current) {
      return onSuccessRef.current(project)
    }
  }

  const setOnSuccess = (fn: TOnSuccessCallback) => {
    onSuccessRef.current = fn
  }

  const [visible, setVisible] = useState(false)
  const [initialValues, setInitialValues] = useState({})

  const modalProps = {
    visible: visible,
    setVisible: setVisible,
    initialValues: initialValues,
    setInitialValues: setInitialValues,
    onSuccess,
    setOnSuccess,
  }

  return (
    <NewProjectModalContext.Provider value={modalProps}>
      <>
        {children}
        <NewProjectModal {...modalProps}/>
      </>
    </NewProjectModalContext.Provider>
  )
}

export const useNewProjectModal = (): NewProjectModalContextProps => {
  const context = useContext(NewProjectModalContext)
  if (!context) {
    throw new Error('useNewProjectModal should be used within a NewProjectModalContext provider')
  }

  return context
}