import { TApiResponse, TApiParams, TApiUsersAccount, TApiAbsenceType, TApiAbsence, TApiPagination } from "./types"
import BaseApi, { getQueryString } from './base'
import { TApiPeriod } from "./reports-api"
import { omit, omitBy } from "lodash"

export type TApiAbsenceRequest = TApiAbsence & {
  usersAccount: TApiUsersAccount
}

export type TApiHolidaysAbsenceRequestsFilters = {
  statuses?: Array<string>
  usersAccountHashIds?: Array<string>
  membersGroupHashIds?: Array<string>
  absenceTypeIds?: Array<string>
  period: TApiPeriod
}

export interface IGetHolidaysAbsenceRequestsParams extends TApiParams {
  filters?: TApiHolidaysAbsenceRequestsFilters
}

export interface IGetHolidaysAbsenceRequestsResponse extends TApiResponse {
  absenceRequests: Array<TApiAbsenceRequest>
  meta: {
    pagination: TApiPagination
  }
}

export interface IUpdateHolidatsAbsenceRequestParams {
  holidaysAbsenceTypeId?: string
  usersAccountId?: string
  fromDate?: string
  toDate?: string
  comment?: string
}

class HolidaysAbsenceRequestsApi extends BaseApi {
  async getHolidaysAbsenceRequests (params: IGetHolidaysAbsenceRequestsParams): Promise<IGetHolidaysAbsenceRequestsResponse> {
    let filters: any = {}

    if (params?.filters?.period) {
      filters = { ...params.filters, ...params.filters.period }
      delete filters.period
    }

    const requestParams = {
      filters: filters,
      ...omit(params, ['filters'])
    }

    const query = getQueryString(this.paramsToSnakeCase(requestParams), true)

    let response = await fetch(`${this.rootUrl}/holidays/absence_requests${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async getHolidaysAbsenceRequestsExport(params: IGetHolidaysAbsenceRequestsParams): Promise<IGetHolidaysAbsenceRequestsResponse> {
    let filters: any = {}

    if (params?.filters?.period) {
      filters = { ...params.filters, ...params.filters.period }
      delete filters.period
    }

    const requestParams = {
      filters: filters,
      ...omit(params, ['filters'])
    }

    let response = await fetch(`${this.rootUrl}/holidays/absence_requests/export`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(this.paramsToSnakeCase(requestParams)),
    })

    this.handleBlobDownload(response, `timenotes-absence-requsts.${params.export?.type}`)
  }


  async updateHolidaysAbsenceRequest (absenceRequestId: string, params: IUpdateHolidatsAbsenceRequestParams) {
    let response = await fetch(`${this.rootUrl}/holidays/absence_requests/${absenceRequestId}`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        holidays_absence: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async deleteHolidaysAbsenceRequest (absenceRequestId: string) {
    let response = await fetch(`${this.rootUrl}/holidays/absence_requests/${absenceRequestId}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async approveHolidaysAbsenceRequest (absenceRequestId: string) {
    let response = await fetch(`${this.rootUrl}/holidays/absence_requests/${absenceRequestId}/approve`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async rejectHolidaysAbsenceRequest (absenceRequestId: string) {
    let response = await fetch(`${this.rootUrl}/holidays/absence_requests/${absenceRequestId}/reject`, {
      method: 'PATCH',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
}

export default HolidaysAbsenceRequestsApi