import { TApiResponse, TApiPaymentMethod, TApiPlan, TApiInvoice } from "./types"
import BaseApi from './base'

export type TApiPayment = {
  amount: number
  createdAt: Date
  errorMessage: null | string
  id: string
  status: string
}

export type TApiSubscriptionPeriod = {
  activatedAt: string // datetime
  createdAt: string // datetime
  expiresAt: string // datetime
  formattedPeriod: string
  invoice: null | TApiInvoice
  paymentMethod: null | TApiPaymentMethod
  payments: Array<TApiPayment>
  plan: TApiPlan
  state: string
  status: string
  trial: boolean
}

export interface IGetSubscriptionPeriodsCurrentResponse extends TApiResponse {
  subscriptionPeriod: TApiSubscriptionPeriod
}

export interface IGetSubscriptionPeriodsResponse extends TApiResponse {
  subscriptionPeriods: Array<TApiSubscriptionPeriod>
}

class SubscriptionPeriodsApi extends BaseApi {
  async getSubscriptionPeriodsCurrent (): Promise<IGetSubscriptionPeriodsCurrentResponse> {
    let response = await fetch(`${this.rootUrl}/subscription_periods/current`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async getSubscriptionPeriods (): Promise<IGetSubscriptionPeriodsResponse> {
    let response = await fetch(`${this.rootUrl}/subscription_periods?per_page=1000`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
}

export default SubscriptionPeriodsApi
