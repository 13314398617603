import {Card, Checkbox, message} from "antd"
import { useHistory, useLocation, useParams } from "react-router-dom"
import {TApiAccountImport, TApiConfirmImportColumnsResponse} from "services/timenotes/imports/imports.types"
import ImportColumnsConfigurationTable from "./components/import-columns-configuration-table"
import ImportHeader from "./components/import-header"
import ImportProjectsConfigurationTable from "./components/import-projects-configuration-table"

const ConfigureImportProjectsPage = () => {
  const location = useLocation()
  const history = useHistory()

  const projectsMatchings = location.state?.projectsMatchings as TApiConfirmImportColumnsResponse['projectsMatchings']

  const totalProjects = projectsMatchings.reduce((total, currentMatching) => {
    return total + currentMatching.projects.length
  }, 0)

  const { importId } = useParams<{
    importId: string
  }>()

  if (!projectsMatchings) {
    message.warning("Account import expired, please try again!")
    history.push('/imports/new')

    return null
  }

  return (
    <>
      <ImportHeader step={2} />

      <div className="page-content">
        <Card>
          <p><b>
            Please configure the projects mapping between the CSV file and your Timenotes database to ensure data consistency.
          </b></p>

          <p>
             We have identified {totalProjects} different projects in your CSV file. We have tried to match those with existing Timenotes project. Please confirm the matching or decide to create a new private or public project for the ones we did not match in timenotes yet.
          </p>

        </Card>

        <ImportProjectsConfigurationTable 
          importId={importId}
          projectsMatchings={projectsMatchings}
        />

      </div>

    </>
  )
}

export default ConfigureImportProjectsPage
