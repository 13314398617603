import { Redirect, Route, Switch, useHistory } from "react-router-dom"
import { useEffect, useState } from "react"
import PageLoadingSpin from "components/layout/page-loading-spin"
import { useTopNavigationLinks } from "hooks/layout"
import {useGetPagePermissions} from "hooks/permissions.hooks"
import {TopNavLinkItem} from "components/layout/top-nav-links"
import AuthenticationsPage from "./authentications/page"
import NewIntegrationAuthenticationPage from "pages/integrations/new-authentication/page"
import IntegrationsListPage from "./list/workspaces-list-page"
import NewIntegrationPage from "pages/integrations/new/new-integration-page"
import NewSelectWorkspacePage from "pages/integrations/new-select-workspace/new-integration-select-workspace-page"
import NewSelectProjectsPage from "pages/integrations/new-select-projects/page"
import NewIntegrationSettingsPage from "pages/integrations/new-settings/page"
import ShowIntegrationAccountPage from "pages/integrations/show-integration-account/page"
import NewIntegrationSelectProjectPage from "pages/integrations/integration-account-new-projects/page"

const IntegrationsRoutes = () => {
  const history = useHistory()

  const { permissions, permissionsLoaded } = useGetPagePermissions()
  const canManageIntegrations = !!permissions?.manageSettings

  const memberLinks: TopNavLinkItem[] = [
   {
      label: 'USER AUTHENTICATION',
      urlMatcher: "integrations/authentications",
      onClick: () => { 
        history.push(`/integrations/authentications`)
      },
    }
  ]

  const adminLinks: TopNavLinkItem[] = [
    {
      label: 'INTEGRATION WORKSPACES',
      urlMatcher: /integrations\/workspaces|integrations\/int|integrations\/new/,
      onClick: () => { 
        history.push(`/integrations/workspaces`)
       },
    },
  ]

  const links = canManageIntegrations ? adminLinks.concat(memberLinks) : memberLinks
  useTopNavigationLinks(links)

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(permissionsLoaded)
 }, [])

  if (!loaded) {
    return <PageLoadingSpin />
  }

  return (
    <Switch>

      <Route exact path={`/integrations/authentications`} component={AuthenticationsPage} />
      <Route path={`/integrations/new-authentication`} component={NewIntegrationAuthenticationPage} />

      { canManageIntegrations && (
        <>
          <Route exact path={`/integrations/workspaces`} component={IntegrationsListPage} />
          <Route exact path={`/integrations/new`} component={NewIntegrationPage} />
          <Route path={`/integrations/new-authentication`} component={NewIntegrationAuthenticationPage} />
          <Route exact path={`/integrations/new-select-workspace`} component={NewSelectWorkspacePage} />
          <Route exact path={`/integrations/new-select-projects`} component={NewSelectProjectsPage} />
          <Route exact path={`/integrations/new-settings`} component={NewIntegrationSettingsPage} />
          <Route exact path={`/integrations/integration-account`} component={ShowIntegrationAccountPage} />
          <Route exact path={`/integrations/integration-account/:integrationAccountId/projects/new`} component={NewIntegrationSelectProjectPage} />
        </>
      )}

      <Route path={'/integrations'}>
        <Redirect to={'/integrations/authentications'} />
      </Route>
 
   </Switch>
  )
}

export default IntegrationsRoutes
