import { TApiHiddenPage } from "@timenotes/shared/src/services/api-client/settings-api"
import useCanDisplayPage from "@timenotes/shared/src/services/hidden-pages/use-can-display-page"
import { message } from "antd"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"

export default function useEnsureUserCanDisplayPage(page: TApiHiddenPage) {
  const history = useHistory()
  const canDisplayPage = useCanDisplayPage(page)

  useEffect(() => {
    if (!canDisplayPage) {
      history.push("/")
      message.warn('You have no permissions to display this page!')
    }
  }, [canDisplayPage])
}