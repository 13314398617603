import React, { useState } from 'react'
import _map from 'lodash/map'
import { Badge } from 'antd'
import { groupBy, isEmpty } from 'lodash'
import { LockOutlined } from '@ant-design/icons'
import { usePagePermissions } from 'hooks/permissions.hooks'
import RecordsSelect from '../records-select'
import { useProjectsQuery } from '@timenotes/shared/src/services/projects/projects.hooks'
import LogoImg from 'components/base/logo'
import { AppName } from 'consts'
import { useNewProjectModal } from './new-project-modal-context'
import { TApiProject } from '@timenotes/shared/src/services/api-client/types'
import { default as mapProjectOptionsShared } from '@timenotes/shared/src/services/projects/utils/map-project-options'

// For old time compatibility imports
export const mapProjectOptions = mapProjectOptionsShared

const ProjectSelect = (selectProps: Partial<React.ComponentProps<typeof RecordsSelect<TApiProject>>>) => {

  const newProjectModal = useNewProjectModal()

  const { permissions } = usePagePermissions()
  const [searchValue, setSearchValue] = useState("")

  const canCreateProjects = permissions.createProjects
  const projectsQuery = useProjectsQuery({}, { 
    enabled: true
  })

  const isDisabled = projectsQuery.isLoading || projectsQuery.isError

  const style = { 
    width: "100%",
    ...selectProps.style
  }

  const apiOptions = !projectsQuery.isSuccess ? [] : mapProjectOptions(projectsQuery.data.data)

  const createProjectLabel = isEmpty(searchValue) ? `Create new project` : `Create new project - "${searchValue}"`
  const createProjectOption = {
    label: canCreateProjects ? "Create a new project" : "Can't create a new project",
    options: [{
     disabled: !canCreateProjects,
      value: {
        id: 'CREATE_NEW',
        name: createProjectLabel,
      } as TApiProject
    }]
  }
  const options = [...apiOptions, createProjectOption]

  const renderProjectItem = ({ project }: { project: TApiProject }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <LogoImg 
        name={project.origin as AppName || 'timenotes'}
        size="12px"
      />
      <Badge status={"success"} color={project.color || "green"} text={project.name} />
    </div>
  )

  const renderCreateNewProjectLabel = () => (
    <>
      {canCreateProjects ? (
        <span>{createProjectLabel}</span>
      ) : (
        <i><LockOutlined /> You have no permissions to create new projects</i>
      )}
    </>
  )

  // IT IS POSSIBLE TO CREATE PURE LABEL WITH EMPTY OPTIONS TO DISPLAY A INFORMATIVE TEXT ABOUT PAGINATION TOTAL SIZE

  return (
    <>
      <RecordsSelect<TApiProject>
        loading={projectsQuery.isFetching}
        dropdownRender={(menu) => (menu)}
        placeholder="Start typing or click to select the project"
        autoFocus={true}
        disabled={isDisabled}
        style={style}
        showSearch={true}
        onSearch={(searchValue) => { setSearchValue(searchValue) }}
        searchValue={searchValue}
        options={options}
        valueProp="id"
        searchProp="name"
        labelProp={(project) => {
          if (project.id == 'CREATE_NEW') {
            return renderCreateNewProjectLabel()
          }
          return renderProjectItem({ project })
        }}

        {...selectProps}

        onCreate={() => { 
          newProjectModal.setInitialValues({
            name: searchValue
          })
          newProjectModal.setVisible(true)

          newProjectModal.setOnSuccess((project) => {
            if (selectProps.onChange) {
              selectProps.onChange(project, undefined as any)
            }
          })

          if (selectProps.onCreate) selectProps.onCreate(searchValue)
        }}
 
      />
    </>
  )
}
export default ProjectSelect