import { Button, Form, Input, message } from "antd"

import { useHistory } from "react-router-dom"
import useApiClient from 'hooks/useApiClient'
import { useFormik } from 'formik'
import ErrorMessage from 'components/base/error-message'
import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"

export const CreateAccountForm = () => {
  const history = useHistory()
  const apiClient = useApiClient()

  const formik = useFormik<{
    name: string,
    firstName: string,
    lastName: string,
  }>({
    initialValues: {
      name: '',
      firstName: '',
      lastName: '',
    },
    onSubmit: async (values) => {
      const response = await apiClient.createAccount(values)

      if (response.ok) {
        apiClient.setAccessCredentials({
          accountId: response.account.id,
        })

        history.push('/timer')
      } else {
        const baseError = getApiErrorMessage(response, 'base')
        if (baseError) message.error(baseError)
      }
    }
  })

  return (
    <>
      <Form 
        layout="vertical" 
        name="login-form"
        onSubmitCapture={formik.handleSubmit}
      >

        <Form.Item 
          name="name" 
          label="Workspace name" 
        >
          <Input 
            autoFocus={true}
            onChange={formik.handleChange('name')}
          />
          <ErrorMessage msg={formik.errors.name} />
        </Form.Item>

        <Form.Item 
          name="firstName" 
          label="User first name" 
        >
          <Input 
            value={formik.values.firstName}
            onChange={(e) => {
              formik.setFieldValue('firstName', e.target.value)
            }}
          />
          { /* @ts-ignore */ }
          <ErrorMessage msg={formik.errors.user?.firstName} />
        </Form.Item>

        <Form.Item 
          name="lastName" 
          label="User last name" 
        >
          <Input 
            value={formik.values.lastName}
            onChange={(e) => {
              formik.setFieldValue('lastName',e.target.value)
            }}
          />
          { /* @ts-ignore */ }
          <ErrorMessage msg={formik.errors.user?.lastName} />
        </Form.Item>

        <Form.Item>
          <Button 
            type="primary" 
            htmlType="submit" 
            block
            loading={formik.isSubmitting}
          >
            Create workspace
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default CreateAccountForm
