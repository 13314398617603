import { Modal, Table, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import _map from 'lodash/map'
import ActionsPanel, { IActionItem, IActionsPanelProps } from "components/shared/actions-panel"
import { TApiMembersGroup } from "services/api-client/types"
import { TeamAvatarGroup } from "pages/projects/list/components/project-table-cell/project-team/ProjectTeam"
import { InfoIcon } from "components/base/icons"

interface IGetMembersGroupsActions {
  (membersGroup: TApiMembersGroup): IActionItem[]
}

const MembersGroupsTable = ({
  membersGroups,
  allowAction,
  editGroup,
  getMembersGroupsActions
}: {
  allowAction: boolean,
  editGroup(group: TApiMembersGroup): void,
  membersGroups: TApiMembersGroup[],
  getMembersGroupsActions: IGetMembersGroupsActions,
}) => {

  const displayPermissionsModal = (membersGroup?: TApiMembersGroup) => {
    Modal.info({
      title: 'You have no permissions to manage the groups section',
      content: 'If you are expected to be able to manage the groups, ask other admin user of the workspace to grant you the admin role.',
    })
  }

  const onClick  = allowAction ? editGroup : displayPermissionsModal

  const COLUMNS: ColumnsType<TApiMembersGroup> = [
    {
      title: 'NAME',
      key: 'name',
      onCell: (record) => ({ onClick: () => { onClick(record) } }),
      sorter: (a,b) => { return a.name.localeCompare(b.name) },
      width: '10%',
      render: (t, record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <b>{record.name}</b>
          </div>
        )
      }
    },
    {
      title: 'MEMBERS',
      key: 'usersAccounts',
      onCell: (record) => ({ onClick: () => { onClick(record) } }),
      sorter: (a,b) => { return (a.usersAccounts?.length || 0)- (b.usersAccounts?.length || 0) },
      width: '10%',
      render: (t, record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            { (record.usersAccounts && record.usersAccounts?.length > 0) ? (
              <TeamAvatarGroup data={record.usersAccounts} maxCount={5} />
            ) : (
              <span><i>no members added</i></span>

            )}
          </div>
        )
      }
    },

    {
      title: 'PROJECTS',
      key: 'projects',
      onCell: (record) => ({ onClick: () => { onClick(record) } }),
      sorter: (a, b) => { return (a.projects?.length || 0) - (b.projects?.length || 0) },
      width: '30%',
      render: (t, record) => {
        return (
          <div>
            {(record.projects && record.projects?.length > 0) ? (
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
                {
                  _map((record.projects || []), project => (
                    <Tag key={project.id} color={project.color} style={{ marginBottom: '5px', marginTop: '5px' }}>{project.name}</Tag>
                  ))
                }
              </div>
            ) : (
              <span><i>no projects assigned</i></span>

          )}
          </div>
        )
      }
    },

    {
      title: '', // Actions
      width: '10%',
      render: (t: string, record) => {
        const actions = getMembersGroupsActions(record)
        return (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: '10px' }}>
            { allowAction ? (
              <ActionsPanel actions={actions} />
            ) : (
              <div onClick={() => { displayPermissionsModal() }}>
                <InfoIcon />
              </div>
            )}
          </div>
        )
      }
    },
  ]

  return (
    <>
      <Table<TApiMembersGroup>
        rowClassName={'table-clickable-row'}
        tableLayout="fixed"
        dataSource={membersGroups || []}
        columns={COLUMNS}
        pagination={false}
      />
    </>
  )
}

export default MembersGroupsTable