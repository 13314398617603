import { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { Card, Skeleton } from 'antd'
import PageHeader from 'components/layout/page-header'
import ProjectsList from '../new-select-projects/components/projects-list'
import { useIntegrationAccountQuery } from 'services/integrations/integration-accounts/use-integration-account-query'
import { TApiIntegrationAccount, TApiIntegrationAccountsProject, TApiProject } from 'services/api-client/types'
import { useIntegrationAccountsExternalProjectsQuery } from 'services/integrations/integration-accounts-projects/use-integration-accounts-external-projects-query'
import useIntegrationAccountsActions from 'services/integrations/integration-accounts/use-integration-accounts-actions'

const NewIntegrationSelectProjectPage = (props: any) => {
  const [loaded, setLoaded] = useState(false)
  const history = useHistory()

  const params = useParams<{
    integrationAccountId: string
  }>()

  const integrationAccountId = params.integrationAccountId || ""

  const {
    query: integrationAccountQuery
  } = useIntegrationAccountQuery({
    integrationAccountId: integrationAccountId
  })

  const integrationAccount = integrationAccountQuery.data?.integrationAccount as TApiIntegrationAccount

  const {
    query: integrationAccountsProjectsQuery
  } = useIntegrationAccountsExternalProjectsQuery ({
    integrationAccountId: integrationAccountId
  })

  const projects = integrationAccountsProjectsQuery.data?.integrationAccountsProjects || []

  useEffect(() => {
    if (integrationAccountQuery.isSuccess, integrationAccountsProjectsQuery.isSuccess) {
      setLoaded(true)
    }
  }, [integrationAccountQuery.isSuccess, integrationAccountsProjectsQuery.isSuccess])

  const integrationAccountActions = useIntegrationAccountsActions()

  const handleNavigateToNewProject = () => {
    history.push(`/integrations/new-select-projects?integrationAccountId=${integrationAccountId}`)
  }

  const handleCreateProjects = (projects: Array<TApiIntegrationAccountsProject>) => {
    if (projects.length == 0) {
      history.push(`/integrations/integration-account?integrationAccountId=${integrationAccountId}`)
    } else {
      integrationAccountActions.createIntegrationAccountProjects(integrationAccountId, projects).then((response) => {
        if (response.ok) {
          history.push(`/integrations/integration-account?integrationAccountId=${integrationAccountId}`)
        }
      })
    }
  }

  const handleExit = () => {
    history.push('/integrations')
  }

  return (
      <div className="page-content">
        <Card>

        { (loaded) ? (
          <>
            <PageHeader
              title={`Select projects from workspace "${integrationAccount?.name}" which you’d like to connect`}
            />

            <ProjectsList
              projects={projects}
              onSubmit={handleCreateProjects}
              submitText="Connect"
            />
          </>
        ) : (
          <Skeleton />
        )}
        </Card>
     </div>
  )
}

export default NewIntegrationSelectProjectPage