import { usePermissionsQuery } from "@timenotes/shared/src/services/permissions/permissions.hooks"
import { useTimeLogFormik } from "@timenotes/shared/src/services/time-logs/time-logs.hooks"
import { Tooltip } from "antd"
import { FormikBag } from "formik"
import { useState } from "react"
import { TApiTimeLog, TApiUsersAccount } from "services/api-client/types"
import UsersAccountSelect from "../users-accounts/users-account-select"

interface UsersAccountColumnProps {
  record: TApiTimeLog
  takeOverFormik(record: TApiTimeLog): void
  formik: ReturnType<typeof useTimeLogFormik>['formik']
}

const UsersAccountColumn = (props: UsersAccountColumnProps) => {
  const { record, formik, takeOverFormik } = props

  const permissionsQuery = usePermissionsQuery()

  const isActive = (record.id == formik.values?.id)
  const value = isActive ? (formik.values.usersAccount || "") : record.usersAccount
  const [isFocus, setIsFocus] = useState(false)

  const canSetTimeLogUser = !!permissionsQuery.data?.permissions.setTimeLogUser

  const handleOnFocus = () => {
    setIsFocus(true)
    takeOverFormik(record)
  }

  const handleOnChange = (newMember: TApiUsersAccount) => {
    formik.setFieldValue('usersAccount', newMember)
    formik.submitForm()

    if (document.activeElement) document.activeElement.blur()
  }

  return (
    <Tooltip
      title={!canSetTimeLogUser ? 'You have no permissions to log time as other team members.' : undefined}
    >
      <UsersAccountSelect
        className="users-account-select"
        showArrow={false}
        bordered={isFocus}
        onFocus={handleOnFocus}
        onBlur={() => setIsFocus(false)}
        value={value}
        autoFocus={false}
        allowClear={false}
        disabled={!canSetTimeLogUser}
        onChange={handleOnChange}
      />
    </Tooltip>
  )
}

export default UsersAccountColumn
