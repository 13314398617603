import useBulkCopyTimeLogsMutation from "@timenotes/shared/src/services/time-logs/queries/use-bulk-copy-time-logs-mutation"
import { Checkbox, DatePicker, Modal, ModalProps, Radio, message } from "antd"
import Flex from "components/shared-components/Flex"
import { useFormik } from "formik"
import { useDefaultDateFormat } from "hooks/settings"
import useApiClient from "hooks/useApiClient"
import moment from "moment-timezone"
import { useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import useQueryGenericErrorMessage from "services/utils/hooks/use-query-generic-error-message"

type useBulkCopyProps = ModalProps & {
  ids: string[]
}

export const useBulkCopyTimeLogs = ({ ids, ...props }: useBulkCopyProps) => {
  const [open, setOpen] = useState(false)
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  const { defaultDateFormat } = useDefaultDateFormat()

  const bulkCopyTimeLogsMutation = useBulkCopyTimeLogsMutation()

  useEffect(() => {
    if (open && ids.length == 0) {
      setOpen(false)
      message.error('No time logs selected for bulk delete!')
    }
  }, [open])

  useEffect(() => {
    if (bulkCopyTimeLogsMutation.isSuccess) {
      if (props.onOk) props.onOk(undefined as any)
      setOpen(false)
    }
  }, [bulkCopyTimeLogsMutation.isLoading])

  useQueryGenericErrorMessage(bulkCopyTimeLogsMutation)

  useEffect(() => {
    if (open && ids.length == 0) {
      setOpen(false)
      message.error('No time logs selected for bulk copy!')
    }
  }, [open])

  const handleBulkCopy = async () => {
    const startDate = formik.values.startDate.format('YYYY-MM-DD')
    const endDate = formik.values.endDate.format('YYYY-MM-DD')
    const singleDate = formik.values.singleDate.format('YYYY-MM-DD')
    const isSingleDate = formik.values.isSingleDate

    bulkCopyTimeLogsMutation.mutate({
      timeLogsIds: ids,
      startDate: isSingleDate ? singleDate : startDate,
      endDate: isSingleDate ? singleDate : endDate,
      onlyWorkingDays: formik.values.onlyWorkingDays,
    })
  }

  const formik = useFormik({
    initialValues: {
      startDate: moment(),
      endDate: moment(),
      singleDate: moment(),
      isSingleDate: true,
      onlyWorkingDays: false,
    },
    onSubmit: () => {
      handleBulkCopy()
    }
  })

  const BulkCopyModal = (
    <Modal 
      visible={open}
      okText="Copy"

      confirmLoading={bulkCopyTimeLogsMutation.isLoading}
      title={`Copy ${ids.length} selected time logs into day(s)`}
      {...props}

      onOk={handleBulkCopy}
      onCancel={(e) => {
        if (props.onCancel) props.onCancel(e)
        setOpen(false)
      }}
    >
      <>
        <Radio 
          checked={formik.values.isSingleDate} 
          onChange={(checked) => { 
            formik.setFieldValue('isSingleDate', checked)
          }}
        >
          Single day
        </Radio>
        <DatePicker 
          allowClear={false} 
          value={formik.values.singleDate} 
          onChange={(value) => { 
            formik.setFieldValue('singleDate', value) 
          }} 
          format={defaultDateFormat} 
          disabled={!formik.values.isSingleDate} 
        />
        <br />
        <br />

        <Radio 
          checked={!formik.values.isSingleDate} 
          onChange={(checked) => { 
            formik.setFieldValue('isSingleDate', !checked)
          }}
        >
          Multiple days from
        </Radio>

        <DatePicker 
          allowClear={false} 
          value={formik.values.startDate} 
          onChange={(val) => {
            formik.setFieldValue('startDate', val)
          }}
          format={defaultDateFormat}
          disabled={formik.values.isSingleDate} 
        />
         to 
        <DatePicker 
          allowClear={false} 
          value={formik.values.endDate} 
          onChange={(val) => {
            formik.setFieldValue('endDate', val)
          }} 
          format={defaultDateFormat} 
          disabled={formik.values.isSingleDate} 
        />

        <div 
          style={{ 
            height: '20px',
            marginTop: '10px',
          }}
        >
          {!formik.values.isSingleDate && (
            <Flex 
              justifyContent="start"
            >
              <Checkbox 
                style={{ marginRight: '30px' }}
                checked={formik.values.onlyWorkingDays} 
                onChange={(e) => { 
                  formik.setFieldValue('onlyWorkingDays', e.target.checked) 
                }} 
                disabled={formik.values.isSingleDate}
              > 
                  Log time only into the workspace work week days in this period.
              </Checkbox>
            </Flex>
          )}
        </div>
      </>
    </Modal>
  )

  return {
    modal: BulkCopyModal,
    open: open,
    setOpen: setOpen,
  }
}