import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"
import { TApiIntegrationAccount, TApiIntegrationAccountsProject, TApiResponse } from "@timenotes/shared/src/services/api-client/types"
import { Modal, message } from "antd"
import useApiClient from "hooks/useApiClient"
import { map } from "lodash"

const useIntegrationAccountsActions = () => {
  const apiClient = useApiClient()

  const synchroniseIntegrationAccount = (integrationAccount: TApiIntegrationAccount): Promise<TApiResponse> => {
    const apiCall = apiClient.synchroniseIntegrationAccount({
      integrationAccountId: integrationAccount.id,
    })

    apiCall.then((response) => {
      if ((response as any).ok) {
        message.loading('Workspace synchronisation triggered, it might take couple minutes...')
      } else {
        message.error(getApiErrorMessage(response, 'base'))
      }
    })

    return apiCall
  }

  const reconnectIntegrationAccount = (integrationAccount: TApiIntegrationAccount): Promise<TApiResponse> => {
    const apiCall = apiClient.reconnectIntegrationAccount({
      integrationAccountId: integrationAccount.id,
    })

    message.loading("Reconnect triggered! It might take couple minutes...")

    apiCall.then((response) => {
      if ((response as any).ok) {
        message.success('Workspace reconnected!')
      } else {
        message.error(getApiErrorMessage(response, 'base'))
      }
    })

    return apiCall
  }

  const disconnectIntegrationAccount = (integrationAccount: TApiIntegrationAccount): Promise<TApiResponse> => {
    const apiCall = apiClient.disconnectIntegrationAccount({
      integrationAccountId: integrationAccount.id
    })

    apiCall.then((response) => {
      if ((response as any).ok) {
        message.success('Workspace disconnected!')
      } else {
        message.error(getApiErrorMessage(response, 'base'))
      }
    })

    return apiCall
  }

  const deleteIntegrationAccount = (integrationAccount: TApiIntegrationAccount, callback: any): any => {
    Modal.confirm({
      title: "Are you sure you want to delete the integration workspace?",
      onOk: () => {
        const apiCall = apiClient.deleteIntegrationAccount({
          integrationAccountId: integrationAccount.id,
        })

        apiCall.then((response) => {
          if ((response as any).ok) {
            message.success('Workspace deleted!')
            callback()
          } else {
            message.error(getApiErrorMessage(response, 'base'))
          }
        })
      }
    })
  }

  const createIntegrationAccountProjects = async (integrationAccountId: string, projects: TApiIntegrationAccountsProject[]) => {
    const requestBody = {
      integrationAccountId: integrationAccountId,
      projects: map(projects, (project) => {
        return {
          name: project.name,
          origin_identifier: project.originIdentifier
        }
      })
    }

    const response = await apiClient.createIntegrationAccountProjects({ params: requestBody })
    if (response.ok) {
      return response
    } else {
      message.error(getApiErrorMessage(response, 'base'))
    }

    return response
  }
 
  return {
    synchroniseIntegrationAccount,
    reconnectIntegrationAccount,
    disconnectIntegrationAccount,
    deleteIntegrationAccount,
    createIntegrationAccountProjects,
  }
}

export default useIntegrationAccountsActions