import React from 'react'
import { TApiTag } from 'services/api-client/types';
import _map from 'lodash/map'
import styled from 'styled-components';
import { useTagsQuery } from '@timenotes/shared/src/services/tags/tags.hooks';
import RecordsSelect from '../records-select';
import FilterInput from './filter-input';

export interface TagsFilterProps {
  style?: React.CSSProperties
  width?: string
  values?: string[]
  disabled?: boolean
  children?: React.ReactChild
  onChange?(values: string[], tags: TApiTag[]): void 
}

const Wrapper = styled.div`
  .ant-popover {
    width: 250px;
  }
`

const TagsFilter = ({
  values,
  width,
  disabled,
  onChange,
  children,
 }: TagsFilterProps): JSX.Element => {

  const tagsQuery = useTagsQuery({})
  const tags = tagsQuery.data?.data || []
  const isLoading = tagsQuery.isFetching

  const allTags = tags
  const handleOnChange = !onChange ? undefined : (tags: TApiTag[]) => {
    onChange(tags.map((t) => t.id), tags)
  }

  const selectedTags = allTags.filter((tag) => {
    return values?.includes(tag.id)
  })

  const tagsOptions = _map(tags, (tag) => ({ value: tag }))

  const selectOptions = [{
    label: 'Tags',
    allFilters: true,
    options: tagsOptions
  }]

  return (
    <FilterInput
      placeholder="Tag"
      count={values?.length}
    >
      <RecordsSelect<TApiTag>
        open={true}
        loading={isLoading}
        disabled={isLoading}
        style={{
          width: '300px',
        }}
        valueProp='id'
        labelProp='name'
        searchProp='name'
        placeholder='Tag'
        showSearch={true}
        mode='multiple'
        options={selectOptions}
        onChange={handleOnChange as any}
        value={selectedTags as any}

        autoFocus={true}
        dropdownMatchSelectWidth={true}
        getPopupContainer={(node) => {
          return node
        }}
        filterInputMode={true}
      />
      <div className="options-wrapper"></div>
    </FilterInput>
  )
}

export default TagsFilter