import { useMutation, useQueryClient } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiProject, TApiTask } from "../../api-client/types"
import { TApiGenericError, TApiGenericResponse } from "../../timenotes-query/timenotes-query.types"
import { ICreateProjectParams } from "../../api-client/projects-api"

interface Args extends TApiProject {}

interface Result {
  project: TApiProject
}

export const useCreateProjectMutation = (options: any = {}) => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  const query = useMutation<Result, TApiGenericError<TApiTask>, Args>(
    ['project', 'create'],
    async (args: Args) => {
      const createParams = projectToCreateParams(args)
      const response = await apiClient.createProject(createParams)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        project: response.project
      }
    },
    {
      ...options,
      onSuccess: (data: Result, args: Args) => {
        queryClient.invalidateQueries('projects', { exact: false })

        if (options.onSuccess) {
          options.onSuccess(data, args)
        }
      },
    },
  )

  return query
}

const projectToCreateParams = (project: TApiProject): ICreateProjectParams => {
  const params = {
    color: project.color,
    name: project.name,
    clientName: project.client?.name,
    visibility: project.visibility,
    taskCreator: project.taskCreator,
    billableEnabled: !!project.billableEnabled,
    billableRate: project.billableRate,
    taskCreationPolicy: project.taskCreationPolicy,
    defaultIsBillableTimeLogs: project.defaultIsBillableTimeLogs,
    budgetType: project.budgetType == 'money' ? 'money' : 'time',
    budgetEnabled: project.budgetEnabled || false,
    budgetMoney: project.budgetMoney || 0,
    budgetTime: project.budgetTime || 0,
    budgetAlertThreshold: project.budgetAlertThreshold || 70,
    disallowOverbudget: project.disallowOverbudget === undefined ? false : !!project.disallowOverbudget,
    disallowUserOverbudget: project.disallowUserOverbudget === undefined ? true : !!project.disallowUserOverbudget,
  }

  return params
}


export default useCreateProjectMutation