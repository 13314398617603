import React, { ReactNode, createContext, useContext } from "react"
import ApiClient from "."

export type TTimenotesApiClientContext = {
  apiClient: ApiClient,
}

export type TTimenotesApiClientContextProps = {
  children: ReactNode
  rootUrl: string
  clientName: string
  apiClient?: ApiClient
}

const TimenotesApiClientContext = createContext<TTimenotesApiClientContext | undefined>(undefined)

export const TimenotesApiClientProvider: React.FC<TTimenotesApiClientContextProps> = (props) => {
  const { rootUrl, clientName, children, apiClient: givenApiClient } = props

  const apiClient = givenApiClient || new ApiClient(rootUrl, clientName)

  return (
    <TimenotesApiClientContext.Provider value={{
      apiClient: apiClient,
    }}>
      {children}
    </TimenotesApiClientContext.Provider>
  )
}

export const useApiClient = (): ApiClient => {
  const context = useContext(TimenotesApiClientContext)
  if (!context) {
    throw new Error('useApiClient must be used within a TimenotesApiClientProvider')
  }

  return context.apiClient
}