import { Col, Divider, List, Modal, Row, Typography } from "antd"
import Table, {ColumnType} from "antd/lib/table"
import {TFailedImportColumn, TFailedImportRow} from "services/timenotes/imports/imports.types"
import {toTitleCase} from "utils/string-utils"


const ImportFailedRowsModal = (props: React.ComponentProps<typeof Modal> & {
  failedColumns?: TFailedImportColumn[]
  clearFailedColumns(): void
}) => {
  const { 
    failedColumns,
    clearFailedColumns,
  } = props

  const columns: ColumnType<TFailedImportColumn>[] = [
    {
      title: 'Row number',
      render: (value, record) => record.rowIndex || "?",
    },
    {
      title: 'Column name',
      render: (value, record) => toTitleCase(record.columnName),
    },
    {
      title: 'Configured mapping',
      render: (value, record) => record.optionMapping,
    },
    {
      title: 'Column value',
      render: (value, record) => record.value,
    },
    {
      title: 'Error',
      render: (value, record) => <span style={{ color: 'red' }}>{record.reasonDescription}</span>,
    },
  ]

  return (
    <Modal  
      width={800}
      visible={!!failedColumns}
      onOk={clearFailedColumns}
      onCancel={clearFailedColumns}
      cancelButtonProps={{ style: { display: 'none' }}}
      title="Import failed!"
      {...props}
    >

      <p><b>We could not import the data properly</b></p>
      <p>
        We had problems with importing the data according to your configuration. Please check below errors and adjust the mapping below. In case the configuration is correct, please adjust the import CSV file and start the new import with a fixed file.
      </p>
      <Divider />

      <Table 
        dataSource={failedColumns}
        columns={columns}
        pagination={false}
      />

    </Modal>
  )
}

export default ImportFailedRowsModal
