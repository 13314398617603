import { TApiResponse, TApiParams } from "./types"
import BaseApi, { getQueryString } from './base'

export type TApiBillingInfo = {
  additionalInfo?: string
  address: string
  city: string
  country: string
  legalEntity: string
  payer: string
  vatId?: string
  vatTerms: boolean
  zip: string
}

export interface IGetBillingInfoResponse extends TApiResponse {
  billingInfo: TApiBillingInfo
}

class BillingInfosApi extends BaseApi {
  async getBillingInfo (): Promise<IGetBillingInfoResponse> {
    let response = await fetch(`${this.rootUrl}/billing_info`, {
      method: 'GET',
      headers: this.headers,
    })

    const result = await this.getResponseJson(response)
    return result
  }

  async updateBillingInfo(params: Partial<TApiBillingInfo>) {

    const requestBody = this.paramsToSnakeCase(params)

    let response = await fetch(`${this.rootUrl}/billing_info`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        billing_info: requestBody,
      }),
    })

    return await this.getResponseJson(response)
  }


}

export default BillingInfosApi
