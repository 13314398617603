import { Button, Form, Select, Row, Col } from "antd"
import { map } from "lodash"
import { useFormik } from "formik"
import ErrorMessage from "components/base/error-message"
import UsersAccountsMultiSelect from "components/forms/users-accounts-multi-select"
import DurationInput from "components/forms/duration-input"
import ProjectSelect from "components/forms/project-select"
import ClientSelect from "components/forms/client-select"
import { usePagePermissions } from "hooks/permissions.hooks"
import { useCurrentUser } from "hooks/current-user"
import useApiClient from "hooks/useApiClient"
import { ICreateAlertParams, TApiAlert } from "@timenotes/shared/src/services/api-client/alerts-api"
import { TApiClient, TApiProject } from "@timenotes/shared/src/services/api-client/types"

export interface IFormObject extends Omit<TApiAlert, 'id'> {
  id?: string
}

export interface AlertFormProps {
  initialValues?: IFormObject
  onSubmit?(values: IFormObject): void
  onSuccess?(response: TApiAlert): void
}

const AlertForm = (props: AlertFormProps) => {
  const apiClient = useApiClient()
  const { permissions } = usePagePermissions()
  const { currentUser } = useCurrentUser()

  const canManageUsers = permissions.manageAlerts

  const initialValues = props.initialValues || {
    notificationTarget: 'me',
    usersAccounts: [],
    loggingType: 'less_than',
    loggingValue: 60,
    scopeTargetType: 'account',
    scopeTarget: { id: '', name: ''}, // can be null due to account target type
    period: 'month',
  }

  const formik = useFormik<IFormObject>({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: (values, { setSubmitting }) => {

      const callValues: ICreateAlertParams = {
        scopeTargetId: values.scopeTarget?.id,
        usersAccountIds: map(values.usersAccounts, (usersAccount) => { return usersAccount.id }),
        ...values,
        loggingValue: values.loggingValue || 0,
      }

      if (!canManageUsers) {
        callValues.usersAccountIds = [currentUser.id]
      }

      const apiCall = values.id ? apiClient.updateAlert(values.id, callValues) : apiClient.createAlert(callValues)

      apiCall.then((response) => {
        if (response.ok) {
          props.onSubmit && props.onSubmit(values)
          props.onSuccess && props.onSuccess(response)
          formik.resetForm()
        } else {
          const errors = {
            ...response.errors,
          }

          formik.setErrors(errors || {})
          setSubmitting(false)
        }
      })
    },
  })

  return (
    <Form onSubmitCapture={formik.handleSubmit} layout="vertical">

      <Form.Item
        label="Send Alert To"
      >
        <Select
          value={canManageUsers ? formik.values.notificationTarget : 'me'}
          disabled={!canManageUsers}
          onChange={(value) => {
            formik.setFieldValue('notificationTarget', value)
          }}
          autoFocus={true}
        >
          <Select.Option value="me">Me</Select.Option>
          <Select.Option value="all_admins">All Admins</Select.Option>
        </Select>
        <ErrorMessage msg={formik.errors.notificationTarget} />
      </Form.Item>

      { canManageUsers && (

        <Form.Item
          label="When (Who?)"
        >
          <UsersAccountsMultiSelect
            placeholder="All members"
            style={{ width: '100%'}}
            value={map(formik.values.usersAccounts, (usersAccount) => { return usersAccount.id })}
            initialUsersAccounts={formik.values.usersAccounts}
            onChange={(ids, objects) => {
              formik.setFieldValue('usersAccounts', objects)
            }}
          />
        
          { /* @ts-ignore */ }
          <ErrorMessage msg={formik.errors.usersAccounts } />
        </Form.Item>
      )}

      <Row>
        <Col span="11">
          <Form.Item
            label="Logged"
          >
            <Select
              value={formik.values.loggingType}
              onChange={(value) => {
                formik.setFieldValue('loggingType', value)
              }}
            >
              <Select.Option value="less_than">Less than</Select.Option>
              <Select.Option value="more_than">More than</Select.Option>
            </Select>

            <ErrorMessage msg={formik.errors.loggingType } />
          </Form.Item>
        </Col>

        <Col span="11" offset="2">
          <Form.Item
            label="Time"
          >
            <DurationInput
              placeholder="0m"
              width="100%"
              value={formik.values.loggingValue}
              onChange={(value) => {
                formik.setFieldValue('loggingValue', value)
              }}
            />
            <ErrorMessage msg={formik.errors.loggingValue } />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span="11">
          <Form.Item
            label="In"
          >
            <Select
              value={formik.values.scopeTargetType}
              onChange={(value) => {
                formik.setFieldValue('scopeTarget', {})
                formik.setFieldValue('scopeTargetType', value)
              }}
            >
              <Select.Option value="project">Project </Select.Option>
              <Select.Option value="client">Client </Select.Option>
              <Select.Option value="account">Total </Select.Option>
            </Select>

            <ErrorMessage msg={formik.errors.scopeTargetType} />
          </Form.Item>
        </Col>

        <Col span="11" offset="2">
          { formik.values.scopeTargetType == 'account' && (
            <span></span>
          )}
          { formik.values.scopeTargetType != 'account' && (formik.values.scopeTargetType == 'project' ? (
            <Form.Item
              label="Project"
            >
              <ProjectSelect 
                placeholder="Select project"
                value={formik.values.scopeTarget?.id || ""}
                initialProject={formik.values.scopeTarget as TApiProject || undefined}
                onChange={(value, project) => {
                  formik.setFieldValue('scopeTarget', project)
                }}
              />
              { /* @ts-ignore */ }
              <ErrorMessage msg={formik.errors.scopeTargetId} />
            </Form.Item>
          ) : (
            <Form.Item
              label="Client"
            >
              <ClientSelect
                placeholder="Select client"
                value={formik.values.scopeTarget?.id || ""}
                initialClient={formik.values.scopeTarget as TApiClient || undefined}
                onChange={(value, client) => {
                  formik.setFieldValue('scopeTarget', client)
                }}
              />
              { /* @ts-ignore */ }
              <ErrorMessage msg={formik.errors.scopeTargetId} />
            </Form.Item>
          ))}
        </Col>
      </Row>

      <Form.Item
        label="Period"
      >
        <Select
          value={formik.values.period}
          onChange={(value) => {
            formik.setFieldValue('period', value)
          }}
        >
          <Select.Option value="day">Day</Select.Option>
          <Select.Option value="week">Week</Select.Option>
          <Select.Option value="month">Month</Select.Option>
          <Select.Option value="total">Total</Select.Option>
        </Select>
        <ErrorMessage msg={formik.errors.period} />
      </Form.Item>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="primary"
          loading={formik.isSubmitting}
          onClick={() => {
            formik.submitForm()
          }}
        >
          {formik.values.id ? "Update" : "Create"}
        </Button>
      </div>

    </Form>
  )
}

export default AlertForm