import { TApiClient, TApiResponse } from "./types"
import BaseApi, { getQueryString } from './base'
import { TApiReportsFilters } from "./reports-api"
import moment, { Moment } from "moment-timezone"
import { produce } from "immer"

const DATE_FORMAT= 'DD.MM.YYYY'

export interface IBulkModifyTimeLogsParams {
  timeLog: {
    projectId?: string
    taskId?: string
    startAt?: string
    duration?: number
    date?: string
    origin?: string
    timeFormat?: string
    usersAccountId?: string
    description?: string
    isBillable?: boolean
    tags?: Array<string>
  }
  filters: null | TApiReportsFilters
  bulk?: TApiBulk
}

export type TApiBulk = {
  ids?: Array<string>
  all?: boolean
}

export interface IBulkDeleteTimeLogsParams {
  filters: null | TApiReportsFilters
  bulk?: TApiBulk
}

export interface IBulkUpdateRatesParams {
  filters: null | TApiReportsFilters
  bulk?: TApiBulk
  timeLog: {
    billableRate?: number
    costRate?: number
  }
}

export interface IBulkCopyTimeLogsParams {
  timeLogsIds: Array<string>
  onlyWorkingDays?: boolean
  startDate: Date
  endDate: Date
}

class BulksTimeLogsApi extends BaseApi {

  async bulkModifyTimeLogs(params: IBulkModifyTimeLogsParams): Promise<TApiResponse> {

    // @ts-ignore
    const requestParams: any = produce(params, draft => {
      if (draft.timeLog.date) {

        const date = draft.timeLog.date
        if (moment.isMoment(date)) {
          draft.timeLog.date = draft.timeLog.date.format(DATE_FORMAT)
        } // else its a string and we just keep it
      }

      return draft
    })

    let response = await fetch(`${this.rootUrl}/bulks/time_logs/modify`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify(this.paramsToSnakeCase(requestParams)),
    })

    return await this.getResponseJson(response)
  }

  async bulkDeleteTimeLogs(params: IBulkDeleteTimeLogsParams): Promise<TApiResponse> {
    let response = await fetch(`${this.rootUrl}/bulks/time_logs/remove`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify(this.paramsToSnakeCase(params)),
    })

    return await this.getResponseJson(response)
  }

  async bulkRecalculateTimeLogs(params: IBulkDeleteTimeLogsParams): Promise<TApiResponse> {
    let response = await fetch(`${this.rootUrl}/bulks/time_logs/recalculate_rates`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify(this.paramsToSnakeCase(params)),
    })

    return await this.getResponseJson(response)
  }

  async bulkUpdateRates(params: IBulkDeleteTimeLogsParams): Promise<TApiResponse> {
    let response = await fetch(`${this.rootUrl}/bulks/time_logs/update_rates`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify(this.paramsToSnakeCase(params)),
    })

    return await this.getResponseJson(response)
  }

  async bulkCopyTimeLogs (params: IBulkCopyTimeLogsParams) {
    let response = await fetch(`${this.rootUrl}/bulks/time_logs/copy`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(this.paramsToSnakeCase(params)),
    })

    return await this.getResponseJson(response)
  }
}

export default BulksTimeLogsApi