import { Button, Card, Col, Divider, Dropdown, Form, Input, List, Row, Select } from "antd"
import FormItem from "antd/lib/form/FormItem"
import ErrorMessage from "components/base/error-message"
import useApiClient from "hooks/useApiClient"
import { useNewImportFormik } from "services/timenotes/imports/imports.hooks"

const NewImportForm = () => {
  const formik = useNewImportFormik()
  const apiClient = useApiClient()

  const handleSampleCSV = () => {
    apiClient.getImportsSampleFile()
  }

  const separatorOptions = [
    {
      value: ',',
      label: ',',
    },
    {
      value: ';',
      label: ';',
    },
    {
      value: ':',
      label: ':',
    }
  ]

  const importFieldsInfo = {

    projects: {
      title: 'Projects Import',
      fields: [
        {
          name: 'Project Name',
          mandatory: true,
          info: "will create a project in case one does not exist with option to choose between private and public project type",
        },
     ]
    },
 
    tasks: {
      title: 'Tasks Import',
      fields: [
        {
          name: 'Project Name',
          mandatory: true,
          info: "will create a project in case one does not exist with option to choose between private and public project type",
        },
        {
          name: 'Task Name',
          mandatory: true,
          info: "will create a task in the project in case one does not exist",
        },
     ]
    },

    users: {
      title: 'Users Import',
      fields: [
        {
          name: 'Email',
          mandatory: true,
          info: "will allow to invite new user by the email in case one is not a member of the workspace",
        },
      ]
    },

    timeLogs: {
      title: 'Time Entries Import',
      fields: [

        {
          name: 'Project Name',
          mandatory: true,
          info: "will create a project in case one does not exist with option to choose between private and public project type",
        },

        {
          name: 'Email',
          mandatory: true,
          info: "will allow to invite new user by the email in case one is not a member of the workspace",
        },


        {
          name: 'Task Name',
          mandatory: true,
          info: "will create a task in the project in case one does not exist",
        },


        {
          name: 'Date',
          mandatory: true,
          info: "import will allow to select exact date format from various options",
        },

        {
          name: 'Time Entry Duration',
          mandatory: true,
          info: "import will allow to select exact duration format from supported options",
        },

      ]
    },



  }

  return (
    <Card>
      <Form 
        layout="vertical"
        onSubmitCapture={formik.handleSubmit} 
      >

        <h2>
          Import Form
        </h2>
        <Divider />

        <Row>
          <Col span="4">
            <FormItem label="CSV File">
              <Input
                type="file"
                onChange={(event) => {
                  if (event.currentTarget.files === null) 
                    return
                  formik.setFieldValue("file", event.currentTarget.files[0]);
                }}

              />
              <ErrorMessage msg={formik.errors?.file as string} />
            </FormItem>
          </Col>

          <Col span="2" offset={1}>
            <FormItem label="Separator">
              <Select
                options={separatorOptions}
                value={formik.values.separator}
                onChange={(value) => { formik.setFieldValue('separator', value) }}
              />
              <ErrorMessage msg={formik.errors?.file as string} />
            </FormItem>
          </Col>
       </Row>
        <Row>
          <Col span="2">
            <Button
              type="primary"
              onClick={formik.submitForm}
              loading={formik.isSubmitting}
            >
              Start Import
            </Button>
          </Col>
        </Row>

        <br />
        <br />
 
        <h2> Instructions </h2>
        <Divider />

        <Row>
          <Col span="24">
            <p>
              Please have in mind that CSV file needs to be formatted properly for import to work. In case of adding new data to Timenotes, like new projects or users, we will allow you to match it with existing data or add new records and guide you through the import process.
            </p>

            <Button
              type="link"
              onClick={handleSampleCSV}
            >
              Download Sample CSV Import File
            </Button>

            <br />
            <br />

            <p>
              <b>Following columns are manadatory in order to import different data records:</b>
            </p>

            <InfoList {...importFieldsInfo.timeLogs } />

          </Col>
        </Row>
      </Form>
    </Card>
  )
}

type TInfoField = {
  name: string,
  mandatory: boolean,
  info: string,
}

const InfoList = ({
  title,
  fields,
}: {
  title: string,
  fields: TInfoField[]
}) => {
  return (
    <>
      <List 
        header={<b>{title}</b>}
        bordered
        dataSource={fields}
        renderItem={(field: TInfoField) => (
          <div
            style={{
              padding: '20px',
            }}
          >
            <b>{field.name}</b> - 
            { field.mandatory ? " mandatory " : " optional " } - {" "}
            {field.info}
          </div>
        )}
      />
      <br />
    </>
  )
}

export default NewImportForm
