import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import TagManager from 'react-gtm-module'

const GTAG_ID = process.env.REACT_APP_GTAG_ID

if (GTAG_ID && GTAG_ID != "") {
  const tagManagerArgs = {
    gtmId: GTAG_ID
  }

  TagManager.initialize(tagManagerArgs)

  window.TagManager = TagManager
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
