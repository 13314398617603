import { Card, Skeleton, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import AbsenceIcon from "components/base/absence-icon"
import { useAbsenceTypes } from "hooks/absences"
import { useCurrentUser } from "hooks/current-user"
import useApiClient from "hooks/useApiClient"
import { find, map } from "lodash"
import { useEffect, useState } from "react"
import { TApiAbsenceType, TApiUsersAccount } from "services/api-client/types"
import { TApiAbsenceAllowance, TApiTeamMember } from "services/api-client/users-accounts-api"

type TAllowanceItem = TApiAbsenceType & {
  allowance?: TApiAbsenceAllowance
}

const CurrentAllowanceTable = () => {
  const apiClient = useApiClient()
  const { currentUser } = useCurrentUser()

  const currentUsersAccount = currentUser as TApiUsersAccount

  const [currentTeamMember, setCurrentTeamMember] = useState<TApiTeamMember | undefined>()

  const { absenceTypes, loading } = useAbsenceTypes()

  useEffect(() => {
    apiClient.getUsersAccountListMember(currentUsersAccount.id).then((response) => {
      if (response.ok) {
        setCurrentTeamMember(response.usersAccount)
      }
    })
  }, [currentUsersAccount.id])

  if (!currentTeamMember) {
    return <Card><Skeleton /></Card>
  }

  const allowances = currentTeamMember.absenceAllowances

  const allowanceItems = map(absenceTypes, (absenceType) => {
    return {
      ...absenceType,
      allowance: find(allowances, (allowance) => { return allowance.absenceType.id == absenceType.id})
    }
  })

  const columns: ColumnsType<TAllowanceItem> = [
    {
      title: 'type',
      render: (value, record) => (
        <div style={{ display: 'flex', gap: '8px', color: record.color }}>
          <AbsenceIcon icon={record.icon} color={record.color} />
          <span>{record.name}</span>
        </div>
      ),
    },
    {
      title: 'Limit',
      render: (value, record) => {
        if (record.limitless) {
          return "No limit"
        }

        // If record (AbsenceType) is not limitless it basically means allowance exists here
        const allowance = record.allowance as TApiAbsenceAllowance

        return (
          <div>{allowance.limit || record.limit} days per {record.period}</div>
        )
      },
    },
    {
      title: 'still available',
      render: (value, record) => {
        if (record.limitless) {
          return "Unlimited"
        }

        // If record (AbsenceType) is not limitless it basically means allowance exists here
        const allowance = record.allowance as TApiAbsenceAllowance

        return (
          <div>{record.limit - (allowance.used || 0)} days</div>
        )
      },
    },
    {
      title: 'Requires approval?',
      render: (value, record) => {
        return (
          <span>
            {record.approval ? "Yes" : "No"}
          </span>
        )
      }
    }
  ]

  if (loading) {
    return <Card>
      <Skeleton />
      <Skeleton />
    </Card>
  }

  return (
    <Table
      columns={columns}
      dataSource={allowanceItems}
      pagination={false}
    />
  )
}

export default CurrentAllowanceTable