import { ColumnsType } from "antd/lib/table"
import _map from 'lodash/map'
import { TApiReportsDetailedRow } from "services/api-client/reports-api"
import GenericTable, { GenericTableParams } from "components/generic-table/generic-table"
import { TApiGrouping, TApiTimeLog } from "services/api-client/types"
import { ClientNameColumn, DurationColumn, ProjectNameColumn, TaskNameColumn, TeamMemberColumn } from "components/table-columns/table-columns"

// This table is used only for timesheet table grouping different than no_group - direct time logs
const TimesheetCellTable = ({ 
  timeRows,
  tableParams,
  groupingPrimary,
  onChange,
} : { 
  groupingPrimary: TApiGrouping['primary'],
  timeRows: TApiReportsDetailedRow[],
  tableParams: GenericTableParams,
  onChange(params: GenericTableParams): void,
 }) => {
  const COLUMN_WIDTH = undefined

  let COLUMNS: ColumnsType<TApiReportsDetailedRow> = []

  // TEAM MEMBER
  // id (usersAccount), firstName, lastName, duration, costAmount, billableAmount

  // Client
  // id, name, duration, costAmount, billableAmount

  // Project
  // id, name, duration, color, costAmount, billableAmount


  if (groupingPrimary == 'user') {
    COLUMNS = [
      {
        key: 'userName',
        title: 'Member',
        width: COLUMN_WIDTH,
        render: (t, record) => (<TeamMemberColumn firstName={record.firstName as string} lastName={record.lastName as string} />),
      },
   ]
  }

   if (groupingPrimary == 'client') {
    COLUMNS = [
      {
        key: 'clientName',
        title: 'Client',
        width: COLUMN_WIDTH,
        render: (t, record) => (<ClientNameColumn name={record.name as string} />),
      },
    ]
  }

  if (groupingPrimary == 'project') {
    COLUMNS = [
      {
        key: 'projectName',
        title: 'Project',
        width: COLUMN_WIDTH,
        render: (t, record) => (<ProjectNameColumn name={record.name as string} />),
      },
    ]
  }

  if (groupingPrimary == 'task') {
    COLUMNS = [
      {
        key: 'projectName',
        title: 'Project',
        width: COLUMN_WIDTH,
        render: (t, record) => (<ProjectNameColumn name={record.project.name} origin={record.project.origin} />),
     },
      {
        key: 'taskName',
        title: 'Task',
        width: COLUMN_WIDTH,
        render: (t, record) => (<TaskNameColumn name={record.name as string} />),
      },
    ]
  }

  COLUMNS.push({
    key: 'totalTime',
    title: 'Duration',
    width: COLUMN_WIDTH,
    render: (t, record) => (<DurationColumn duration={record.duration} />),
  },
  )

  return (
    <div>
      <GenericTable<TApiReportsDetailedRow>
        tableProps={{
          dataSource: timeRows,
          columns: COLUMNS,
          rowKey: (record) => { return record.hashId || record.id },
        }}

        tableParams={tableParams}
        rowColorSelector={(record) => {
          // @ts-ignore
          return record.color || record.project?.color || '#E5E5EB'
        }}

        onChange={onChange}
      />
   </div>
  )
}

export default TimesheetCellTable