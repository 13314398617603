import { useEffect, useState } from 'react'
import { Card, Skeleton, message } from 'antd'
import { map } from 'lodash'
import WorkspaceListItem, { WorkspaceListItemViewInterface } from './components/workpace-list-item'
import PageHeader from 'components/layout/page-header'
import { useHistory } from 'react-router-dom'
import { useIntegrationAccountsQuery } from 'services/integrations/integration-accounts/use-integration-accounts-query'
import moment from 'moment-timezone'
import useIntegrationAccountsActions from 'services/integrations/integration-accounts/use-integration-accounts-actions'
import { TApiIntegrationAccount } from '@timenotes/shared/src/services/api-client/types'

const IntegrationsListPage = () => {
  const [loaded, setLoaded] = useState(false)
  const history = useHistory()

  const { 
    query: integrationAccountsQuery
  } = useIntegrationAccountsQuery()

  const actions = useIntegrationAccountsActions()

  const integrationAccounts: TApiIntegrationAccount[] = integrationAccountsQuery.data?.integrationAccounts || []

  useEffect(() => {
    if (!loaded && integrationAccountsQuery.isSuccess && !integrationAccountsQuery.isFetching) {

      setLoaded(true)

      if (integrationAccountsQuery.data.integrationAccounts.length == 0) {
        handleNavigateToNewIntegration()
      }
    }
  }, [integrationAccountsQuery.isFetching, integrationAccountsQuery.isSuccess])

  const reloadPage = () => {
    integrationAccountsQuery.refetch()
  }

  const handleNavigateToNewIntegration = () => {
    history.push('/integrations/new')
  }

  const handleNavigateToShowIntegrationAccount = (integrationAccountId: string ) => {
    history.push(`/integrations/integration-account?integrationAccountId=${integrationAccountId}`)
  }

  const handleSynchronise = (integrationAccount: TApiIntegrationAccount): any => {
    actions.synchroniseIntegrationAccount(integrationAccount).then((response) => {
      if (response.ok) {
        reloadPage()
      }
    })
 }

  const handleReconnect = (integrationAccount: TApiIntegrationAccount): any => {
    actions.reconnectIntegrationAccount(integrationAccount).then((response) => {
      if (response.ok) {
        reloadPage()
      }
    })
  }

  const handleDisconnect = (integrationAccount: TApiIntegrationAccount): any => {
    actions.disconnectIntegrationAccount(integrationAccount).then((response) => {
      if (response.ok) {
        reloadPage()
      }
    })
  }

  const handleDelete = (integrationAccount: TApiIntegrationAccount): any => {
    actions.deleteIntegrationAccount(integrationAccount, () => {
      reloadPage()
    })
  }

  const getWorkspacesProps = (): Array<WorkspaceListItemViewInterface> => {
    const props = map(integrationAccounts, (integrationAccount) => {
      return {
        workspaceId: integrationAccount.id as string,
        workspaceName: integrationAccount.name,
        // @ts-ignore
        workspaceIntegrationApp: { name: integrationAccount.service },
        isConnected: !!(integrationAccount.status == 'connected'),
        connectionOwnerName: integrationAccount.userName || "",
        lastSyncAt: moment(integrationAccount.lastSyncAt),

        onClick: handleNavigateToShowIntegrationAccount,
        onSynchronise: () => { handleSynchronise(integrationAccount) },
        onReconnect: () => { handleReconnect(integrationAccount) },
        onDisconnect: () => { handleDisconnect(integrationAccount) },
        onDelete: () => { handleDelete(integrationAccount) },
      }
    })

    return props
  }

  return (
    <div className="page-content">
      <PageHeader
        title="Connected integration workspaces"
        titleHint="Below integration workspaces are connected to Timenotes. You can track time for projects from these workspaces. Team members’ permissions to projects and tasks from integration are given on the basis of your settings in the integrated tool so each member needs to authenticate their account from integration to see projects assigned to them."
        buttonLabel="Connect New Workspace"
        buttonCallback={handleNavigateToNewIntegration}
      />

      <div style={{ height: '25px' }} />

      {loaded ? (
        <>
          {map(getWorkspacesProps(), (workspace) => (
            <WorkspaceListItem
              {...workspace}
            />
          ))}
        </>
      ) : (
        <Card>
          <Skeleton />
        </Card>
      )}

    </div>
  )
}

export default IntegrationsListPage