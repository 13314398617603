import { TApiResponse, TApiParams, TApiUsersAccount } from "./types"
import BaseApi, { getQueryString } from './base'
import { cloneDeep } from "lodash"

export type TApiProjectsUser = {
  id: null | string
  accessSource: "group" | "manual"
  billableRate: null | number
  budgetTime: null | number
  role: string
  usersAccount: TApiUsersAccount
}

export interface IGetProjectsUsersResponse extends TApiResponse {
  projectsUsers: Array<TApiProjectsUser>
}

export interface ICreateProjectsUserParams extends TApiParams {
  usersAccountId: string
  projectRole: string
  billableRate: number | null
  budgetTime: number | null
}

export interface ICreateProjectsUserResponse extends TApiResponse {
  projectsUser: TApiProjectsUser
}

class ProjectsUsersApi extends BaseApi {
  async getProjectsUsers (projectId: string, params?: TApiParams): Promise<IGetProjectsUsersResponse> {
    let responseParams = { 
      ...params,
    }

    if (!responseParams?.perPage) {
      responseParams.perPage = 1000
    }

    const query = getQueryString(this.paramsToSnakeCase(responseParams), true)

    let response = await fetch(`${this.rootUrl}/projects/${projectId}/projects_users${query}`, {
      method: 'GET',
      headers: this.headers,
    })


    // TRANSLATE BUDGET TIME FROM MINUTES TO HOURS FORMAT
    const json = await this.getResponseJson(response)
    if (json.projectsUsers) {
      json.projectsUsers = json.projectsUsers.map((puser) => {
        if (puser.budgetTime !== null && puser.budgetTime !== undefined) {
          puser.budgetTime = puser.budgetTime / 60
        } 

        return puser
      })
    }

    return json
  }

  async createProjectsUser (projectId: string, params: ICreateProjectsUserParams): Promise<ICreateProjectsUserResponse> {
    const requestParams = cloneDeep(params)

    // TRANSLATE BUDGET TIME FROM HOURS TO MINUTES FORMAT
    if (requestParams.budgetTime !== undefined && requestParams.budgetTime !== null) {
      requestParams.budgetTime = requestParams.budgetTime * 60
    }


    let response = await fetch(`${this.rootUrl}/projects/${projectId}/projects_users`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        projects_user: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async updateProjectsUser (projectId: string, projectsUserId: string, params: Partial<ICreateProjectsUserParams>) {
    const requestParams = cloneDeep(params)

    // TRANSLATE BUDGET TIME FROM HOURS TO MINUTES FORMAT
    if (requestParams.budgetTime !== undefined && requestParams.budgetTime !== null) {
      requestParams.budgetTime = requestParams.budgetTime * 60
    }

    let response = await fetch(`${this.rootUrl}/projects/${projectId}/projects_users/${projectsUserId}`, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        projects_user: this.paramsToSnakeCase(requestParams),
      }),
    })

    return await this.getResponseJson(response)
  }

  async deleteProjectsUser (projectId: string, projectsUserId: string) {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}/projects_users/${projectsUserId}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
}

export default ProjectsUsersApi