import React, { useEffect, useState } from 'react'
import _map from 'lodash/map'
import styled from 'styled-components'
import { Input, Select } from 'antd'
import Duration from 'utils/duration'
import _filter from 'lodash/filter'
import { parseDurationStringToMinutes } from 'utils/parseDurationStringToMinutes'

function durationsArray() {
  const items: number[] = [
    5,
    10,
    15,
    30,
    45,
    60,
    90,
    120,
    180,
    240,
  ]

  return items;
}

export const toDurationString = (number: number): string => {
  const durationString = (new Duration(number as any).toDurationString() as string)

  return durationString
}

export interface DurationInputProps {
  style?: React.CSSProperties
  width?: string

  value: number | undefined
  autoFocus?: boolean
  allowClear?: boolean
  placeholder?: string
  onChange(value: number | undefined): void 
  onEnter?(): void
}

const Wrapper = styled.div`
  .ant-popover {
    width: 250px;
  }

  .ant-select-dropdown-empty {
    display: none !important;
  }
`

type TDurationInputOption = {
  label: string
  value: number
}

const DEFAULT_WIDTH = '170px'

const DEFAULT_AUTOCOMPLETE = durationsArray()

const DurationInput = ({
  value,
  width,
  autoFocus,
  allowClear,
  placeholder,
  onChange,
  onEnter,
 }: DurationInputProps): JSX.Element => {

  const [inputValue, setInputValue] = useState<string | undefined>(value === undefined ? undefined : toDurationString(value))

  useEffect(() => {
    if (parseDurationStringToMinutes(inputValue || "") != value) {
      setInputValue(value === undefined ? undefined : toDurationString(value))
    }
  }, [value])

  const currentValueLabel = value ? toDurationString(value) : undefined

  let durationsOptions: TDurationInputOption[] = _map(durationsArray(), (duration) => {
    return {
      value: duration,
      label: toDurationString(duration),
    }
  })

  if (inputValue != undefined && inputValue != "") {
    durationsOptions = []
  }
  
  const handleOnSelect = (value: number, option: any) => {
    setInputValue(option.label)
  }

  const handleOnSearch = (value: string) => {
    setInputValue(value)
  }

  const handleOnBlur = () => {
    if (value !== undefined) {
      const durationStringFromValue = toDurationString(value)
      if (durationStringFromValue !== inputValue) {
        setInputValue(durationStringFromValue)
      } 
    }
  }

  // Whenever input value changes, the onChange value should also change if its valid
  useEffect(() => {
    if (inputValue !== undefined && inputValue !== "") {
      const parsedDuration = parseDurationStringToMinutes(inputValue as string)
      onChange(parsedDuration)
    } else {
      onChange(undefined)
    }
  }, [inputValue])

  const renderInput = () => {

    const style: any = {}

    // This means there is an input introduced but it has incorrect value which cant be parsed
    if (inputValue !== "" && inputValue !== undefined && value == undefined) {
      style['borderColor'] = 'red'
      style['boxShadowColor'] = 'red'
    }

    return (
      <div>
        <Input
          onKeyDown={onEnter ? (e) => {
            if ( e.key == "Enter") { onEnter() }
           } : undefined }
          style={{
            width: width || DEFAULT_WIDTH,
            ...style,
          }}
          autoFocus={autoFocus}
          placeholder={ placeholder || "Duration"}
          allowClear={allowClear === undefined ? true : allowClear }
          value={inputValue}
          onChange={(e) => { handleOnSearch(e.target.value) }}
          onBlur={handleOnBlur}
        />
      </div>
    )
  }

  return (
    <Wrapper className='duration-input-wrapper'>
        <Select
          getPopupContainer={(wrapperNode) => { return wrapperNode.closest('.duration-input-wrapper')}}
          dropdownClassName='duration-input-dropdown'
          getRawInputElement={renderInput}
          placeholder={ placeholder || "Duration"}
          style={{ 
            width: width || DEFAULT_WIDTH,
          }}

          options={durationsOptions}
          dropdownStyle={{
            width: width || DEFAULT_WIDTH
          }}

          notFoundContent={<div></div>}
          allowClear={false}
          showSearch={false}
          showArrow={false}
          value={value}
          onChange={handleOnSelect}
          filterOption={false}
        />
    </Wrapper>
  )
}

export default DurationInput