import asana from './asana.png'
import basecamp from './basecamp.png'
import github from './github.png'
import todoist from './todoist.png'
import timenotes from './timenotes.png'
import trello from './trello.png'
import extension from './extension.png'

export const asanaLogo = asana
export const basecampLogo = basecamp
export const githubLogo = github
export const todoistLogo = todoist
export const trelloLogo = trello
export const extensionLogo = extension
export const timenotesLogo = timenotes