import { ReactNode, createContext, useContext, useRef, useState } from "react"
import { Modal, message } from "antd"
import React from "react"
import TagForm from "pages/tags/components/tag-form"
import { TApiTask, TApiTag, TApiProject } from "services/api-client/types"
// TODO: old but ok component
import NewTaskModal from "views/app-views/timer/Show/NewTaskModal"
import { ICreateTaskParams } from "services/api-client/tasks-api"
import useApiClient from "hooks/useApiClient"

type TInitialValues = Partial<TApiTask>
type TOnSuccessCallback = (task: TApiTask) => void

export type NewTaskModalContextProps = {
  visible: boolean
  setVisible(v: boolean): void

  initialValues: TInitialValues
  setInitialValues(values: TInitialValues): void

  project?: TApiProject
  setProject(project: TApiProject): void

  onSuccess: TOnSuccessCallback
  setOnSuccess(fn: TOnSuccessCallback): void
}

export type NewTaskModalContextProviderProps = {
  children: ReactNode
}

const NewTaskModalContext = createContext<NewTaskModalContextProps | undefined>(undefined)

export const NewTaskModalContextProvider : React.FC<NewTaskModalContextProviderProps> = (props) => {
  const { children } = props

  const onSuccessRef = useRef<TOnSuccessCallback>((task) => { message.success(`New task "${task.name}" added!`) })

  const onSuccess = (task: TApiTask) => {
    if (onSuccessRef.current) {
      return onSuccessRef.current(task)
    }
  }

  const setOnSuccess = (fn: TOnSuccessCallback) => {
    onSuccessRef.current = fn
  }

  const [visible, setVisible] = useState(false)
  const [project, setProject] = useState<TApiProject | undefined>()
  const [initialValues, setInitialValues] = useState<TInitialValues>({})
  const apiClient = useApiClient()

  const modalProps = {
    visible: visible,
    setVisible: setVisible,
    initialValues: initialValues,
    setInitialValues: setInitialValues,
    project,
    setProject,
    onSuccess,
    setOnSuccess,
  }

  const handleCreateTask = async (params: ICreateTaskParams) => {
    const response = await apiClient.createTask(project?.id || "", params)

    return { response, task: response.task }
  }

  return (
    <NewTaskModalContext.Provider value={modalProps}>
      <>
        {children}
        <NewTaskModal 
          name={modalProps.initialValues?.name || ""}
          createTask={handleCreateTask}
          {...modalProps} 
        />
      </>
    </NewTaskModalContext.Provider>
  )
}

export const useNewTaskModal = (): NewTaskModalContextProps => {
  const context = useContext(NewTaskModalContext)
  if (!context) {
    throw new Error('useNewTaskModal should be used within a NewTaskModalContext provider')
  }

  return context
}