import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { TApiPeriod } from "@timenotes/shared/src/services/approvals/approvals-types"
import useSettings from "@timenotes/shared/src/services/settings/queries/use-settings"
import { Button, Skeleton } from "antd"
import moment, { Moment } from "moment-timezone"

interface PeriodNavigationProps {
  period?: TApiPeriod
  onPeriodDateChange(newDate: Moment): void
}

export default function PeriodNavigation(props: PeriodNavigationProps) {
  const period = props.period

  const goPrevious = () => {
    props.onPeriodDateChange(moment(period?.startDate).subtract(1, 'day'))
  }

  const goNext = () => {
    props.onPeriodDateChange(moment(period?.endDate).add(1, 'day'))
  }

  const {
    approvalsPeriod
  } = useSettings()

  // Preset width to avoid shaking
  let width = '190px' // required for custom timespan
  /*
  if (approvalsPeriod == 'monthly') {
    width = '150px' // can be smaller for monthly timespans
  }
  */

  return (
    <Button.Group>
      <Button
        onClick={goPrevious}
      >
        <LeftOutlined style={{ color: '#ababab' }} />
      </Button>
      <Button
        loading={!period}
        style={{
          width: width
        }}
      >
        {period ? (
          <span>
            {period.timespanString}
          </span>
        ): (
          <span></span>
        )}
      </Button>
      <Button
        onClick={goNext}
      >
        <RightOutlined style={{ color: '#ababab' }} />
      </Button>
    </Button.Group>

  )
}