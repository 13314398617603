import { useQuery, useQueryClient } from "react-query"
import { useApiClient } from "../api-client/api-client.hooks"
import { FormikConfig, FormikHelpers, useFormik } from "formik"
import { TimeLogFormItemProps } from "./time-logs.types"
import { TQueryOptions } from "../timenotes-query/timenotes-query.types"
import { useEffect, useState } from "react"
import moment, { Moment } from "moment-timezone"
import TimeLogPeriodCalculator from "./time-log-period-calculator"
import { map, omit } from "lodash"
import { ICreateTimeLogParams } from "../api-client/time-logs-api"
import { TApiTimeLog } from "../api-client/types"

export const timeLogToRequestParams = (timeLog: TimeLogFormItemProps) => {
  const date = timeLog.date || timeLog.from || undefined

  const params = {
    isBillable: timeLog.isBillable !== undefined ? timeLog.isBillable : undefined,
    duration: timeLog.duration,
    description: timeLog.description,
    projectId: timeLog.project?.id,
    taskId: timeLog.task?.id,
    usersAccountId: timeLog.usersAccount?.id,
    date: date ? date.format('YYYY-MM-DD') : undefined,
    startAt: timeLog.from ? timeLog.from.format("HH:mm") : undefined,
    tags: (timeLog.tags !== undefined) ? map(timeLog.tags, tag => tag.id) : undefined,
  } as ICreateTimeLogParams

  return params
}

const getChangedValues = <T extends Record<string, any>>(values: T, initialValues: T) => {
  return Object.entries(values).reduce((acc: Partial<T>, [key, value]) => {
    const hasChanged = initialValues[key as keyof T] !== value

    if (hasChanged) {
      acc[key as keyof T] = value
    }

    return acc;
  }, {})
};

export interface IUpdateFieldHistory {
  lastUpdateField: string | undefined
  previousLastUpdateField: string | undefined
}

export interface IUseTimeLogFormik {
  initialValues?: TimeLogFormItemProps
  onSuccess?(timeLog: TApiTimeLog): void
  resetOnSuccess?: boolean
  onSubmit?(values: TimeLogFormItemProps, formik: FormikHelpers<TimeLogFormItemProps>): void
}

export const useTimeLogFormik = ({ initialValues, onSuccess, onSubmit, resetOnSuccess: propsResetOnSuccess }: IUseTimeLogFormik = {}) => {
  const [updateFieldHistory, setUpdateFieldHistory] = useState<IUpdateFieldHistory>({ lastUpdateField: undefined, previousLastUpdateField: undefined })
  const apiClient = useApiClient()

  const queryClient = useQueryClient()

  // Default handle submit
  const handleSubmit = async (values: TimeLogFormItemProps, formik: FormikHelpers<TimeLogFormItemProps>) => {
    const createTimeLogParams = timeLogToRequestParams(values)

    const createCall = (params: ICreateTimeLogParams) => {
      return apiClient.createTimeLog(params)
    }

    const updateCall = (params: Partial<ICreateTimeLogParams>) => {
      return apiClient.updateTimeLog(values.id, params)
    }

    const apiCall = values.id ? updateCall : createCall
    const resetOnSuccess = propsResetOnSuccess || !values.id // reset on success by default for create requests, but not for updates

    const result = apiCall(createTimeLogParams).then((response) => {

      // ERROR HANDLING -----
      if (!response.ok) {
        const errors = { ...response.errors }

        if (errors.task) {
          errors.taskId = errors.task
        }

        formik.setErrors(errors)

     } else {
        if (resetOnSuccess) formik.resetForm()
        queryClient.invalidateQueries('time-logs', { exact: false })

        if (onSuccess) {
          // TODO: this values is a hack, should be fixed on backend!
          onSuccess(response.timeLog || values as TApiTimeLog)
        }
      }

      return response
    })

    return result
  }

  const formik = useFormik<TimeLogFormItemProps>({
    initialValues: initialValues || {},
    onSubmit: onSubmit || handleSubmit
  })

  const setNewDuration = (value: number) => {
    const newValue = (value === undefined) ? "" : `${value}`

    const periodCalculator = new TimeLogPeriodCalculator({
      startAt: moment(formik.values.from),
      duration: formik.values.duration,
      ...updateFieldHistory,
    })

    periodCalculator.setDuration(newValue)

    setUpdateFieldHistory({
      lastUpdateField: periodCalculator.timeLog.lastUpdateField,
      previousLastUpdateField: periodCalculator.timeLog.previousLastUpdateField,
    })

    formik.setFieldValue('from', periodCalculator.timeLog.startAt)
    formik.setFieldValue('to', periodCalculator.getEndAt())
    formik.setFieldValue('duration', periodCalculator.timeLog.duration)
  }


  const setNewFromDate = (value: Moment) => {
    const newDate = moment(value)

    const periodCalculator = new TimeLogPeriodCalculator({
      startAt: moment(formik.values.from),
      duration: formik.values.duration,
      ...updateFieldHistory,
    })

    periodCalculator.setStartAt(value.format("HH:mm"))

    setUpdateFieldHistory({
      lastUpdateField: periodCalculator.timeLog.lastUpdateField,
      previousLastUpdateField: periodCalculator.timeLog.previousLastUpdateField,
    })

    formik.setFieldValue('from', periodCalculator.timeLog.startAt)
    formik.setFieldValue('to', periodCalculator.getEndAt())
    formik.setFieldValue('duration', periodCalculator.timeLog.duration)
  }

  const setNewToDate = (value: Moment) => {
    const newDate = moment(value)

    const periodCalculator = new TimeLogPeriodCalculator({
      startAt: moment(formik.values.from),
      duration: formik.values.duration,
      ...updateFieldHistory,
    })

    periodCalculator.setEndAt(value.format("HH:mm"))

    setUpdateFieldHistory({
      lastUpdateField: periodCalculator.timeLog.lastUpdateField,
      previousLastUpdateField: periodCalculator.timeLog.previousLastUpdateField,
    })

    formik.setFieldValue('from', periodCalculator.timeLog.startAt)
    formik.setFieldValue('to', periodCalculator.getEndAt())
    formik.setFieldValue('duration', periodCalculator.timeLog.duration)
  }

  return {
    formik,
    setNewFromDate,
    setNewToDate,
    setNewDuration,
    updateFieldHistory,
    setUpdateFieldHistory,
    endsNextDay: (formik.values.to && formik.values.from) && (formik.values.to.get('d') - formik.values.from.get('d') != 0)
  }
}