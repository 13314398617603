const Plane = (props: React.ComponentProps<"svg">) => (
    <svg width="19px" height="20px" viewBox="0 0 19 20" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g id="Holidays-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="9.2.1-Holidays-Absence-ADMIN" transform="translate(-387.000000, -186.000000)">
                <g id="Group-2" transform="translate(383.000000, 138.000000)">
                    <g id="icon-/-application-/-dark-/-delegation" transform="translate(2.000000, 46.000000)">
                        <g id="baseline-airplanemode_active-24px">
                            <path d="M21,16 L21,14 L13,9 L13,3.5 C13,2.67 12.33,2 11.5,2 C10.67,2 10,2.67 10,3.5 L10,9 L2,14 L2,16 L10,13.5 L10,19 L8,20.5 L8,22 L11.5,21 L15,22 L15,20.5 L13,19 L13,13.5 L21,16 Z" id="Shape" fill="#474D55" fill-rule="nonzero"></path>
                            <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
export default Plane