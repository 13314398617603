import { Skeleton, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useState } from "react"
import { TApiAbsence } from "services/api-client/types"
import { CarOutlined } from '@ant-design/icons'
import AbsenceIcon from "components/base/absence-icon"
import { TTimesheetAbsence } from "services/api-client/timesheets-api"

export interface TimesheetAbsencesTableProps {
  absences: TTimesheetAbsence[]
}

const TimesheetAbsencesTable = (props: TimesheetAbsencesTableProps) => {
  const columns: ColumnsType<TTimesheetAbsence> = [
    {
      title: 'Absence Type',
      render: (value, record: TTimesheetAbsence) => (
        <div style={{display: 'flex'}}><AbsenceIcon icon={record.icon} /> <span style={{marginLeft: '5px' }}>{record.type}</span></div>
      )
    },
    {
      title: 'Absence Date',
      render: (value, record: TTimesheetAbsence) => (
        <span>{record.dateRange}</span>
      )
    },
    {
      title: 'Status',
      render: (value, record: TTimesheetAbsence) => (
        <span>Approved</span>
      )
    }
  ]

  return (
    <Table<TTimesheetAbsence>
      columns={columns}
      dataSource={props.absences}
      pagination={false}
    />
  )
}

export default TimesheetAbsencesTable