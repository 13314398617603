import { SnippetsOutlined } from "@ant-design/icons"
import { Divider, Modal, Table, TableColumnType } from "antd"
import { useDefaultDateFormat, useDefaultTimeFormat } from "hooks/settings"
import moment from "moment-timezone"
import { getUsersAccountFullName } from "services/users-accounts"
import ApprovalStatusTag from "./approval-status-tag"

type ApprovalNotesIconProps = {
  notes: any[]
}

export default function ApprovalNotesIcon(props: ApprovalNotesIconProps) {
  const {
    notes
  } = props

  const { defaultDateFormat } = useDefaultDateFormat()
  const { defaultTimeFormat } = useDefaultTimeFormat()

  const hasNotes = notes.length > 0
  if (!hasNotes) return null

  const COLUMNS: TableColumnType<any>[] = [
    {
      title: 'Date',
      width: '170px',
      render: (_, note) => (moment.utc(note.createdAt).format(`${defaultDateFormat} ${defaultTimeFormat}`))
    },
    {
      title: "User",
      width: '200px',
      render: (_, note) => (getUsersAccountFullName(note.author))
    },
    {
      title: 'Action',
      width: '100px',
      render: (_, note) => {
        const status = `${note.title}`.toLowerCase()

        if (!note.title) return null

        return (
          <ApprovalStatusTag status={status}>{note.title}</ApprovalStatusTag>
        )
      }

    },
    {
      title: "Notes",
      render: (_, note) => note.content || <i>-</i>
    }
  ]


  const onClick = () => {
    Modal.info({
      title: 'Approval Notes History',
      okText: 'Close',
      width: '60%',
      content: (
        <>
          <Divider />

          <Table
            pagination={false}
            columns={COLUMNS}
            dataSource={notes}
          />
        </>
      )
    })
  }

  return (
    <SnippetsOutlined
      style={{ 
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  )
}