import { TApiResponse, TApiParams } from "./types"
import BaseApi, { getQueryString } from './base'

export type TApiAccount = {
  id: string
  name: string
  subscriptionValid: boolean
  discountCredits: string
}

export interface IGetAccountsCurrentResponse extends TApiResponse {
  account: TApiAccount
}

export interface IGetAccountsListResponse extends TApiResponse {
  accounts: Array<TApiAccount>
}

class AccountsApi extends BaseApi {

  async createAccount({ 
    name,
    firstName,
    lastName,
  }: {
    name: string,
    firstName: string,
    lastName: string,
  }): Promise<IGetAccountsCurrentResponse> {
    const response = await this.post('/accounts', {
      account: {
        name: name,
        source: this.clientName,
      },
      users_account: {
        firstName: firstName,
        lastName: lastName,
      },
    })

    return response
  } 

  async getAccountsCurrent(): Promise<IGetAccountsCurrentResponse> {
    let response = await fetch(`${this.rootUrl}/accounts/current`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async updateAccountsCurrent(): Promise<IGetAccountsCurrentResponse> {

    let response = await fetch(`${this.rootUrl}/accounts/current`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
  async getAccountsList(): Promise<IGetAccountsListResponse> {
    let response = await fetch(`${this.rootUrl}/accounts`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
}

export default AccountsApi
