import React, { useEffect, useState } from 'react'
import { TApiUsersAccount } from 'services/api-client/types'
import _map from 'lodash/map'
import styled from 'styled-components'
import { Select } from 'antd'
import { find } from 'lodash'
import useApiClient from 'hooks/useApiClient'

export interface UsersAccountSelectProps {
  style?: React.CSSProperties
  width?: string

  value: string | undefined
  allowClear?: boolean
  showArrow?: boolean
  initialUsersAccount?: TApiUsersAccount
  onChange(value: string, usersAccount: TApiUsersAccount): void 
  autoFocus?: boolean
  selectProps?: Omit<React.ComponentProps<typeof Select>, 'onChange' | 'value' | 'defaultValue'>
}

const Wrapper = styled.div`
  .ant-popover {
    width: 250px;
  }
`

type TUsersAccountOption = {
  label: string
  value: string
}

const DEFAULT_WIDTH = '170px'

const UsersAccountSelect = ({
  value,
  width,
  style,
  onChange,
  allowClear,
  showArrow,
  initialUsersAccount,
  autoFocus,
  selectProps,
 }: UsersAccountSelectProps): JSX.Element => {
  const apiClient = useApiClient()

  const [usersAccounts, setUsersAccounts] = useState<TApiUsersAccount[]>(initialUsersAccount ? [initialUsersAccount] : [])

  useEffect(() => {
    apiClient.getUsersAccountsScoped().then((response) => {
      if (response.ok) {
        setUsersAccounts(response.usersAccounts)
      }
    })
  }, [])

  const groupsOptions = _map(usersAccounts, (usersAccount) => {
    return {
      value: usersAccount.id,
      label: `${usersAccount.firstName} ${usersAccount.lastName}`,
    }
  })

  const handleOnChange = !onChange ? () => {} : (usersAccountId: string) => {
    const usersAccount: TApiUsersAccount= find(usersAccounts, (usersAccount) => { return usersAccount.id == usersAccountId}) as TApiUsersAccount
    onChange(usersAccountId, usersAccount)
  }

  return (
    <Wrapper 
      className="users-account-select-wrapper" 
      style={{
          width: width || DEFAULT_WIDTH,
          ...style,
      }}
    >
      <Select
        autoFocus={autoFocus}
        defaultOpen={autoFocus}
        placeholder="Select member"
        getPopupContainer={(node) => {return node.closest('.users-account-select-wrapper')} }
        showArrow={showArrow === undefined ? true : showArrow}
        style={{ width: '100%' }}
        options={[
          {
            label: 'Members',
            options: groupsOptions,
          }
        ]}

        allowClear={allowClear !== undefined ? allowClear : true }

        value={value}
        onChange={handleOnChange}

        // Default options needed to make Select searchable
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => {
          const selectOption = option as TUsersAccountOption

          if (selectOption) {
            return selectOption.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } else {
            return false
          }
        }}
        {...selectProps}
      />
    </Wrapper>
  )
}

export default UsersAccountSelect
