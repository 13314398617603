import { UseQueryOptions, useQuery } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiParams } from "../../api-client/types"
import { TApiGenericError } from "../../timenotes-query/timenotes-query.types"
import moment, { Moment } from "moment-timezone"
import React, { useEffect, useMemo, useState } from "react"
import { map, omitBy } from "lodash"
import { TApiApprovalRequest, TApiApprovalRequestStatus, TApiPeriod, TApiPeriodRaw, TApiPeriodStatus } from "../approvals-types"
import useApprovalRequestModel from "../hooks/use-approval-request-model"
import { DEFAULT_APPROVAL_REQUESTS_GROUPS } from "../approvals-consts"
import { removeUndefinedValues } from "../../utils/remove-values"
import usePeriodModel from "../hooks/use-period-model"

interface Params extends TApiParams {
  // NOT CREATED INTERNALLY AS IT CAN NOT BE DETERMINED AS INTERNAL DEFAULT ANYWAY, NEVER
  // SO THAT IT IS NOT INTERNALLY CONTROLLED FIELD,
  filters?: {
    status?: TApiApprovalRequestStatus
    usersAccountId?: string
    year: string
  }
}

interface Result {
  approvalRequests: TApiApprovalRequest[]
}

export const useApprovalRequestsQuery = (params?: Params, options?: UseQueryOptions<any, any>) => {
  const apiClient = useApiClient()

  const {
    parseApprovalRequest,
  } = useApprovalRequestModel()

  const paramsFilters = useMemo(() => params?.filters, [JSON.stringify(params?.filters)])
  const [filters,  setFilters] = useState<Params["filters"] | undefined >(paramsFilters)

  useEffect(() => {
    setFilters(paramsFilters)
  }, [paramsFilters])

  const requestParams = removeUndefinedValues({
    perPage: params?.perPage || 1000,
    filters: filters
  })

  const query = useQuery<Result, TApiGenericError<any>>(
    ['approval-requests', (requestParams || {})] as const,
    async () => {
      const response = await apiClient.get(`/approvals/periods/requests`, requestParams)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        approvalRequests: map(response.approvalsRequests, parseApprovalRequest)
      }
    },
    {
      ...options,
    }
  )

  const updateFilters = (filters: Partial<Params['filters']>) => {
    setFilters((val) => {
      return {
        ...val,
        ...filters,
      }
    })
  }

  return {
    approvalRequestsQuery: query,
    approvalRequests: query.data?.approvalRequests || [],
    approvalRequestsFilters: filters,
    updateApprovalRequestsFilters: updateFilters,
    setApprovalRequestsFilters: setFilters,
  }
}