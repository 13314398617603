import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"
import { Button, Modal, message } from "antd"
import useApiClient from "hooks/useApiClient"
import { useHistory } from "react-router-dom"

const DeleteWorkspaceButton = () => {
  const apiClient = useApiClient()
  const history = useHistory()

  const deleteWorkspace = async () => {
    const response = await apiClient.delete('/accounts/remove')

    if (response.ok) {
      message.success('Workspace deleted successfully!')
      window.location.pathname = '/auth/select-account'
    } else {
      message.error(getApiErrorMessage(response, 'base'))
    }
  }

  const handleDelete = () => {
    Modal.confirm({
      width: '600px',
      title: 'Are you sure you want to delete this workspace?',
      content: (
        <div>
          <br/>
          <p>Clicking delete will cancel your current subscription and automatically delete all the data related to this workspace from Timenotes servers.</p>
          <p>This action happens instantly and can not be reverted!</p>
          <p>Please remember to export all your data in case you might need it in the future.</p>
        </div>
      ),
      onOk: deleteWorkspace,

      okText: 'Delete',
      okButtonProps: {
        type: 'default',
      },
      cancelButtonProps: {
        type: 'text',
      }
    })
  }

  return (
    <Button
      type="ghost"
      onClick={handleDelete}
    >
      Delete workspace
    </Button>
  )
}

export default DeleteWorkspaceButton