import { addQueryStringParameter } from 'utils/history'
import { useQueryParams } from 'hooks/app-router'
import NewIntegrationStepsView, { NewIntegrationStep } from '../shared/components/new-integration-steps'
import PageHeader from 'components/layout/page-header'
import AuthenticateIntegrationAppView from './components/authenticate-integration-app'
import { Card, Skeleton } from 'antd'
import { WEB_APP_URL } from 'pages/settings/components/const'
import { useAvailableIntegrationAppsQuery } from 'services/integrations/integration-apps/use-available-integration-apps-query'
import { keyBy } from 'lodash'
import { TApiIntegrationApp } from 'services/api-client/types'
import useApiClient from 'hooks/useApiClient'

const NewIntegrationAuthenticationPage = () => {
  const queryParams = useQueryParams<{
    integrationAppName: string
  }>()

  const apiClient = useApiClient()
  const integrationAppName = queryParams.integrationAppName || ""

  const {
    query: availableIntegrationAppsQuery
  } = useAvailableIntegrationAppsQuery()

  const availableIntegrationApps = availableIntegrationAppsQuery.data?.integrationApps || []
  const availableIntegrationAppsMap = keyBy(availableIntegrationApps, 'service')

  const integrationApp = availableIntegrationAppsMap[integrationAppName]

  const handleAppClick = (app: TApiIntegrationApp) => {
    let authUrl = addQueryStringParameter(app.authUrl as string, "web_redirect_url", `${WEB_APP_URL}/integrations/new-select-workspace`)
    authUrl = addQueryStringParameter(authUrl, 'session_token', apiClient.accessToken as string)
    authUrl = addQueryStringParameter(authUrl, 'account_id', apiClient.accountId as string)

    // @ts-ignore
    window.top.location = authUrl
  }

  const appName = integrationApp?.service

  return (
    <div className="page-content">
      <NewIntegrationStepsView currentStep={NewIntegrationStep.Authentication} />

      <Card>
        {availableIntegrationAppsQuery.isSuccess ? (
          <>

            <PageHeader
              title="Authentication"
            />

            <p>
              Connect your timenotes account with your account in {appName} via authentication process.  It’s a necessary for Timenotes to be able to access your {appName} workspaces, projects and tasks and finish connecting integration workspace process.  Your ${appName} account settings also determine your permissions to projects and tasks in Timenotes.
            </p>

            <AuthenticateIntegrationAppView
              integrationApp={integrationApp}
              onAppClick={handleAppClick}
            />
          </>
        ) : (
          <Skeleton />
        )}

      </Card>
    </div>
  )
}

export default NewIntegrationAuthenticationPage