import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"
import { Alert, message, Form, Input, Button} from "antd"
import ErrorMessage from "components/base/error-message"
import { useFormik } from "formik"
import { useLoginSuccessRedirect } from "hooks/auth.hooks"
import { useAuthorizedConfirmInvitation, usePeekedInvitation } from "hooks/confirm-invitations"
import useApiClient from "hooks/useApiClient"
import { useHistory } from "react-router-dom"
import AuthLayout from "views/auth-views/components/auth-layout"

const AuthConfirmInvitationPage = () => {
  const history = useHistory()
  const apiClient = useApiClient()
  apiClient.loadAccessCredentials()
  const loginSuccessRedirect = useLoginSuccessRedirect()

  const {
    peekedInvitation,
  } = usePeekedInvitation()

  const peekedInvitationToken = peekedInvitation?.token


  const {
    authorizedConfirmInvitation,
    authorizedConfirmInvitationLoading,
  } = useAuthorizedConfirmInvitation()

  const goToTimenotes = () => {
    window.localStorage.removeItem('peekedInvitationJSON')
    loginSuccessRedirect()
  }

  const formik = useFormik<{
    firstName: string
    lastName: string
  }>({
    initialValues: {
      firstName: peekedInvitation?.firstName || "",
      lastName: peekedInvitation?.lastName || "",
    },
    onSubmit: async (values, formik) => {
      const response = await authorizedConfirmInvitation({
        firstName: values.firstName,
        lastName: values.lastName,
        token: peekedInvitation?.token as string,
      })

      if (response.ok) {
        message.success('Invitation accepted!')
        window.localStorage.removeItem('peekedInvitationJSON')
        history.push(`/timer?account_id=${peekedInvitation?.accountId}`)
      } else {
        formik.setErrors(response.errors || {})

        if (response.errors?.base) {
          message.error(getApiErrorMessage(response, 'base'))
        }
      }
    }
  })


  if (!peekedInvitation || !peekedInvitationToken) {
    message.error("Invalid invitation!")
    history.push("/auth/login")
  }

  if (!apiClient.accessToken) {
    history.push('/auth/login')
  }

  return (
    <AuthLayout>
      {peekedInvitation && (
        <Alert
          style={{
            marginBottom: '30px',
          }}
          message={`Accept the invitation for ${peekedInvitation.email} to workspace "${peekedInvitation.accountName}"`}
        />
      )}

      <Form onSubmitCapture={formik.handleSubmit} layout="vertical">

        <Form.Item label="First name">
          <Input 
            value={formik.values.firstName}
            onChange={formik.handleChange('firstName')}
          />
          <ErrorMessage msg={formik.errors?.firstName} />
        </Form.Item>

        <Form.Item label="Last name">
          <Input 
            value={formik.values.lastName}
            onChange={formik.handleChange('lastName')}
          />
          <ErrorMessage msg={formik.errors?.lastName} />
        </Form.Item>

        <Button
          type="primary"
          onClick={() => formik.submitForm() }
        >
          Confirm
        </Button>

        <Button
          type="default"
          style={{
            marginLeft: '10px',
          }}
          onClick={() => {
            goToTimenotes()
          }}
        >
          Skip and go back to Timenotes
        </Button>


      </Form>

    </AuthLayout>
  )
}

export default AuthConfirmInvitationPage
