import { TopNavLinkItem } from "components/layout/top-nav-links"
import React, { useContext, useEffect, useMemo, useState } from "react"

const defaultValue: {
  links: TopNavLinkItem[]
  setLinks(links: TopNavLinkItem[]): void
} = {
  links: [],
  setLinks: () => {}
}

const LayoutContext = React.createContext(defaultValue)

export const LayoutContextProvider = (props: React.PropsWithChildren) => {
  const [links, setLinks] = useState<TopNavLinkItem[]>([])

  return <LayoutContext.Provider
    value={{
      links: links,
      setLinks: setLinks,
    }}
  >
    {props.children}
  </LayoutContext.Provider>
} 

export const useLayoutContext = () => {
  const layoutContext = useContext(LayoutContext)
  return layoutContext
}

export const useTopNavigationLinks = (links: TopNavLinkItem[]) => {
  const layout = useLayoutContext()

  useEffect(() => {
    // Setup Nav Links on component mount
    layout.setLinks(links)

    return () => {
      // Cleanup nav links on component unmount
      layout.setLinks([])
    }
  }, [JSON.stringify(links)])

  return links
}