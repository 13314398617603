import { UseQueryOptions, useQuery } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiParams } from "../../api-client/types"
import { TApiGenericError } from "../../timenotes-query/timenotes-query.types"
import { TApiApprovalRequest } from "../approvals-types"
import useApprovalRequestModel from "../hooks/use-approval-request-model"
import { removeUndefinedValues } from "../../utils/remove-values"

interface Params extends TApiParams {
  usersAccountId?: string
  periodId?: string
}

interface Result {
  approvalRequest: TApiApprovalRequest
}

export const useApprovalRequestDetailsQuery = (params?: Params, options?: UseQueryOptions<any, any>) => {
  const apiClient = useApiClient()

  const {
    parseApprovalRequest,
  } = useApprovalRequestModel()

  const requestParams = removeUndefinedValues({
    filters: {
      usersAccountId: params.usersAccountId,
    }
  })

  const query = useQuery<Result, TApiGenericError<any>>(
    ['approval-request', ({ ...requestParams, periodId: params.periodId } || {})] as const,
    async () => {
      const response = await apiClient.get(`/approvals/periods/${params.periodId}/requests/user_summary`, requestParams)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
        approvalRequest: parseApprovalRequest(response.approvalsRequest)
      }
    },
    {
      ...options,
      enabled: options?.enabled !== undefined ? options.enabled : (!!params.periodId && !!params.usersAccountId)
    }
  )

  return {
    approvalRequestDetailsQuery: query,
    approvalRequest: query.data?.approvalRequest,
  }
}
