import { Card, Skeleton } from 'antd'
import IntegrationAppListView from './components/integration-app-list'
import { useHistory } from 'react-router-dom'
import PageHeader from 'components/layout/page-header'
import { useAvailableIntegrationAppsQuery } from 'services/integrations/integration-apps/use-available-integration-apps-query'
import { TApiIntegrationApp } from '@timenotes/shared/src/services/api-client/types'

const NewIntegrationPage = () => {
  const history = useHistory()

  const { 
    query: availableIntegrationAppsQuery
  } = useAvailableIntegrationAppsQuery()

  const loaded = availableIntegrationAppsQuery.isSuccess

  const handleAppClick = (app: TApiIntegrationApp) => {
    if (app.isConnected) {
      history.push(`/integrations/new-select-workspace?integrationAppId=${app.id}`)
    } else {
      history.push(`/integrations/new-authentication?integrationAppName=${app.service}`)
    }
  }

  return (
    <>
      { loaded ? (
        <div className="page-content">
          <PageHeader
            title="Connect Integration Workspace"
          />

          <Card>
            <p>
              Connecting integration workspace enables time tracking for projects that you are working on in your projects management tool.<br />
              Team members’ permissions to projects and tasks from integration are given on the basis of your settings in the integrated tool.
            </p>
            <IntegrationAppListView
              integrationApps={availableIntegrationAppsQuery.data.integrationApps}
              onAppClick={handleAppClick}
            />
          </Card>
        </div>
      ) : (
        <Card>
          <Skeleton />
        </Card>
      )}
    </>
  )
}

export default NewIntegrationPage