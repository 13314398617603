import { Select, Form, message, Input, Modal } from "antd"
import { Formik } from "formik"
import _difference from 'lodash/difference'
import _map from 'lodash/map'
import _find from 'lodash/find'
import ErrorMessage from "components/base/error-message"
import { TApiMembersGroup } from "@timenotes/shared/src/services/api-client/types"
import { TApiTeamMember } from "@timenotes/shared/src/services/api-client/users-accounts-api"
import { ICreateMembersGroupParams, ICreateMembersGroupResponse } from "@timenotes/shared/src/services/api-client/members-groups-api"
import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"
const { Option } = Select

interface IGroupForm {
  group?: TApiMembersGroup
  workspaceTeamMembers: TApiTeamMember[]
  visible: boolean
  close(): void
  onSubmit(group: ICreateMembersGroupParams): Promise<ICreateMembersGroupResponse>
}

type TTeamMemberSelectValue = {
  label: string // First & Last Name
  value: string // usersAccountId
}

const GroupForm = ({ group, visible, close, onSubmit, ...props }: IGroupForm) => {

  const isUpdate = !!group?.name

  const initialValues: ICreateMembersGroupParams = isUpdate ? {
    name: group.name,
    usersAccountsIds: _map(group.usersAccounts, (usersAccount) => usersAccount.id )
  } : {
    name: '',
    usersAccountsIds: [],
  }

  return (

    <Formik<ICreateMembersGroupParams>
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, formik) => {
        return onSubmit(values).then((response) => {
          if (response.ok) {
            formik.resetForm()
            close()
          } else {
            if (!response.ok) {
              if (response.status == 422 && response.errors) {
                formik.setErrors(response.errors)
              } else {
                message.error(getApiErrorMessage(response, 'base'))
              }
            }
          }
        })
      }}
    >
      {(formik) => (
        <Modal
          destroyOnClose={true}
          visible={visible}
          onCancel={() => { 
            formik.resetForm()
            close()
          }}
          onOk={() => {
            formik.submitForm()
          }}
          okText={ isUpdate ? "Update group" : "Create group" }
          title={ isUpdate ? `Update group "${group.name}"` : 'Create group'}
        >

        <Form
          layout="vertical"
        >
          <Form.Item
            label="Name"
          >
            <Input
              placeholder="e.g. Management"
              autoFocus={true}
              value={formik.values.name || ""}
              style={{ width: '100%' }} 
              onPressEnter={formik.submitForm}
              onChange={(e) => { formik.setFieldValue('name', e.target.value) }} 
            />
            <ErrorMessage msg={formik.errors.name} />
          </Form.Item>

          <Form.Item
            label="Members"
          >
            <Select<string[]>
              placeholder={"Start typing to select team members"}
              showArrow={true}
              value={formik.values.usersAccountsIds}
              style={{ width: '100%' }} 
              onChange={(values, options) => {
                 formik.setFieldValue('usersAccountsIds', _map(options, (option) => (option as TTeamMemberSelectValue).value))
              }} 
              options={
                _map(props.workspaceTeamMembers, (teamMember) => {
                  return {
                    value: teamMember.id,
                    label: `${teamMember.firstName} ${teamMember.lastName}`,
                  }
                })
              }
              mode="multiple"
              optionFilterProp="label"
            />
            
          </Form.Item>

        </Form>
        </Modal>
      )}
    </Formik>
  )

}

export default GroupForm