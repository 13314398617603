import { useMutation, useQueryClient } from "react-query"
import { useApiClient } from "../../api-client/api-client.hooks"
import { TApiTask } from "../../api-client/types"
import { TApiGenericError, TApiGenericResponse } from "../../timenotes-query/timenotes-query.types"

interface Args {
  projectId: string
}

interface Result {
}

export const useRestoreProjectMutation = (options: any = {}) => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  const query = useMutation<Result, TApiGenericError<TApiTask>, Args>(
    ['project', 'restore'],
    async (args: Args) => {
      const response = await apiClient.restoreProject(args.projectId)

      if (!response.ok) {
        throw response.errors || {}
      }

      return {
      }
    },
    {
      ...options,
      onSuccess: (data: Result, args: Args) => {
        queryClient.invalidateQueries('projects', { exact: false })
        queryClient.invalidateQueries(['project', args.projectId], { exact: false })

        if (options.onSuccess) {
          options.onSuccess(data, args)
        }
      },
    },
  )

  return query
}


export default useRestoreProjectMutation