import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"
import { Button, Divider, Spin } from "antd"
import { useQueryParams } from "hooks/app-router"
import { useApiCall } from "hooks/useApiCall"
import useApiClient from "hooks/useApiClient"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import AuthLayout from "views/auth-views/components/auth-layout"

const ConfirmContactEmailPage = () => {
  const history = useHistory()
  const apiClient = useApiClient()

  const queryParams = useQueryParams<{
    confirmation_token: string
  }>()

  const confirmationToken = queryParams.confirmation_token

  const {
    request: confirmEmail,
    data: response,
    loading
  } = useApiCall(async (confirmationToken: string) => {
    return await apiClient.confirmContactEmail(confirmationToken)
  })

  useEffect(() => {
    if (confirmationToken) {
      confirmEmail(confirmationToken)
    }
  }, [])

  return (
    <AuthLayout>
      { (confirmationToken) ? (
        <>
        { (loading || response === undefined) ? (
          <Spin />
        ) : (
          <>
            { response && response.ok ? (
              <div>
                <h1> Email confirmed!</h1>
                <Divider />
                <Button
                  type="primary"
                  onClick={() => {
                    history.push('/app')
                  }}
                >
                  Sign in to your Timenotes account!
                </Button>
              </div>
            ) : (
              <div>
                <h2>
                  { getApiErrorMessage(response, 'confirmationToken') }
                </h2>
                <Divider />
                <Button
                  onClick={() => {
                    history.push('/auth/login')
                  }}
                >
                  Go back to Timenotes
                </Button>
              </div>
            )}
          </>
        )}
        </>
      ) : (
        <span>Incorrect EMAIL Confirmation URL</span>
      )}
    </AuthLayout>
  )

}

export default ConfirmContactEmailPage