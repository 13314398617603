export function removeValues<T>(obj: T, valueToRemove: any): Partial<T> {
  const result: Partial<T> = {};

  for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const element = obj[key];

          if (element === valueToRemove) {
              continue;
          }

          if (element && typeof element === 'object' && !Array.isArray(element)) {
              result[key] = removeValues(element, valueToRemove) as any;
          } else {
              result[key] = element;
          }
      }
  }

  return result;
}

export function removeNullValues<T>(obj: T) { return removeValues(obj, null) }
export function removeUndefinedValues<T>(obj: T) { return removeValues(obj, undefined) }