import { useLocation, useHistory } from 'react-router-dom'
import { useMemo, useEffect } from 'react'

type SetSearchParams<T> = (params: Partial<T>, options?: { replace?: boolean }) => void

const useSearchParams = <T extends Record<string, string>>(initialValues?: T) => {
  const location = useLocation()
  const history = useHistory()

  const searchParams = useMemo(() => {
    const params: Partial<T> = initialValues ? { ...initialValues } : {}
    const searchParams = new URLSearchParams(location.search)

    searchParams.forEach((value, key) => {
      params[key as keyof T] = value
    })

    return params as T
  }, [location.search, initialValues])

  const setSearchParams: SetSearchParams<T> = (params, options = {}) => {
    const newSearchParams = new URLSearchParams(location.search)

    Object.entries(params).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        newSearchParams.delete(key)
      } else {
        newSearchParams.set(key, value)
      }
    })

    if (initialValues && newSearchParams.toString() === '') {
      Object.entries(initialValues).forEach(([key, value]) => {
        newSearchParams.set(key, value)
      })
    }

    const newLocation = {
      pathname: location.pathname,
      search: newSearchParams.toString(),
    }

    if (options.replace) {
      history.replace(newLocation)
    } else {
      history.push(newLocation)
    }
  }

  useEffect(() => {
    if (initialValues && !location.search) {
      setSearchParams(initialValues, { replace: true })
    }
  }, [location.search, initialValues, setSearchParams])

  return [searchParams, setSearchParams] as [T, SetSearchParams<T>]
}

export default useSearchParams