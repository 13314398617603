import moment, { Moment } from "moment-timezone";
import { TApiApprovalRequest, TApiPeriod, TApiPeriodRaw } from "../approvals-types";
import usePeriodModel from "./use-period-model";

const API_DATE_FORMAT = 'YYYY-MM-DD'

export default function useApprovalRequestModel() {

  const {
    parsePeriod
  } = usePeriodModel()

  function parseApprovalRequest(requestRaw: TApiApprovalRequest): TApiApprovalRequest {
    const approvalRequest: TApiApprovalRequest = {
      ...requestRaw,
      approvalsPeriod: requestRaw.approvalsPeriod ? parsePeriod(requestRaw.approvalsPeriod as unknown as TApiPeriodRaw) : undefined,
    }

    return approvalRequest
  }

  return {
    parseApprovalRequest,
  }
}