import { useApiClient } from "@timenotes/shared/src/services/api-client/api-client.hooks"
import { TApiGenericError } from "@timenotes/shared/src/services/timenotes-query/timenotes-query.types"
import { map, sortBy } from "lodash"
import { UseQueryOptions, useQuery } from "react-query"
import { TApiProject } from "services/api-client/types"

interface IParams {
  integrationAccountId?: string
}

interface IResult {
  projects: TApiProject[]
}

interface IOptions extends UseQueryOptions<IResult, TApiGenericError<null>> {}

export const useIntegrationAccountsConnectedProjectsQuery = (params: IParams = {}, options?: IOptions) => {
  const apiClient = useApiClient()

  const requestParams = {
    integrationAccountId: params.integrationAccountId || "", 
  }

  const query = useQuery<IResult, TApiGenericError<null>>(
    ['integration-accounts-projects-connected', requestParams],
    async () => {
      const response = await apiClient.getConnectedIntegrationAccountProjects(requestParams)

      if (!response.ok) { throw response.errors }

      return {
        projects: sortBy(map(response.projects, (project) => {
          return {
            ...project,
            connected: !!project.connected
          }
        }), (project) => project.name),
      }
    },
    {
      ...options
    }
  )

  return { 
    query,
  }
}