import { useApiClient } from "@timenotes/shared/src/services/api-client/api-client.hooks"
import { TApiGenericError } from "@timenotes/shared/src/services/timenotes-query/timenotes-query.types"
import { map } from "lodash"
import { UseQueryOptions, useQuery } from "react-query"
import { TApiIntegrationApp } from "services/api-client/types"

interface IParams {
}

interface IResult {
  integrationApps: TApiIntegrationApp[]
}

interface IOptions extends UseQueryOptions<IResult, TApiGenericError<null>> {}

export const useAvailableIntegrationAppsQuery = (params: IParams = {}, options?: IOptions) => {
  const apiClient = useApiClient()
  const requestParams = {}

  const query = useQuery<IResult, TApiGenericError<null>>(
    ['available-integration-apps', requestParams],
    async () => {
      const response = await apiClient.getAvailableIntegrationApps(requestParams)

      if (!response.ok) { throw response.errors }

      return {
        integrationApps: map(response.integrations, (int) => {
          return {
            ...int,
            isConnected: !!int.id,
          }
        }),
      }
    },
    {
      ...options
    }
  )

  return { 
    query,
  }
}