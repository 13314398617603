const Star = (props: React.ComponentProps<"svg">) => (
    <svg width="20px" height="19px" viewBox="0 0 20 19" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props} >
        <g id="Holidays-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="9.2.3-Create-absence-type-" transform="translate(-347.000000, -358.000000)">
                <g id="Group" transform="translate(216.000000, 284.000000)">
                    <g id="icon-/-application-/-dark-/-icon-1-copy-7" transform="translate(129.000000, 72.000000)">
                        <g id="baseline-star_border-24px">
                            <path d="M22,9.24 L14.81,8.62 L12,2 L9.19,8.63 L2,9.24 L7.46,13.97 L5.82,21 L12,17.27 L18.18,21 L16.55,13.97 L22,9.24 Z M12,15.4 L8.24,17.67 L9.24,13.39 L5.92,10.51 L10.3,10.13 L12,6.1 L13.71,10.14 L18.09,10.52 L14.77,13.4 L15.77,17.68 L12,15.4 Z" id="Shape" fill="#474D55" fill-rule="nonzero"></path>
                            <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Star