import { FormikHelpers } from "formik"
import { IUseTimeLogFormik, timeLogToRequestParams, useTimeLogFormik } from "../time-logs.hooks"
import { TimeLogFormItemProps } from "../time-logs.types"
import useBulkUpdateTimeLogsMutation from "../queries/use-bulk-update-time-logs-mutation"
import { IBulkModifyTimeLogsParams } from "../../api-client/bulks-time-logs-api"
import { useEffect } from "react"

type Props = IUseTimeLogFormik & {
  onSuccess?(): void
  params: {
    filters?: IBulkModifyTimeLogsParams['filters'],
    bulk?: IBulkModifyTimeLogsParams['bulk'],
  }
}

const useBulkUpdateTimeLogsFormik = (props: Props) => {
  const params = props.params

  const bulkUpdate = useBulkUpdateTimeLogsMutation()

  const onSubmit = (timeLog: TimeLogFormItemProps, formik: FormikHelpers<TimeLogFormItemProps>) => {
    const requestTimeLog = timeLogToRequestParams(timeLog)

    bulkUpdate.mutate({
      timeLog: requestTimeLog,
      filters: params.filters,
      bulk: params.bulk,
    })
  } 

  useEffect(() => {
    formik.setSubmitting(false)

    if (bulkUpdate.isError) {
      formik.setErrors(bulkUpdate.error as any)
    }

    if (bulkUpdate.isSuccess) {
      if (props.onSuccess) props.onSuccess()
    }
  }, [bulkUpdate.isLoading])

  const bulkUpdateFormikParams = useTimeLogFormik({
    ...props,
    onSubmit,
  })

  const formik = bulkUpdateFormikParams.formik

  return bulkUpdateFormikParams
}

export default useBulkUpdateTimeLogsFormik