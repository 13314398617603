import { ExclamationCircleOutlined, ExclamationOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { COLORS } from "components/layout-components/timenotes-theme"
import styled from "styled-components"

interface ErrorMessageProps {
  msg?: string
  tooltip?: boolean
}

const ErrorMsgWrap = styled.div`
  position: relative;
  color: red;

  &.tooltip-error-message .msg {
    top: 2px;
    background-color: ${COLORS.backgroundGrey};
    border: 1px solid #efefef;
    border-radius: 10px;
    padding: 7px;
    z-index: 1000;
  }

  & .msg {
    position: absolute;
  }
`

const ErrorMessage = ({ msg, tooltip }: ErrorMessageProps ) => {

  if (!msg)
    return null

  return (
    <ErrorMsgWrap 
      className={tooltip ? 'tooltip-error-message' : ''} 
    >
      <div className="msg">
        <ExclamationCircleOutlined />
        <span
          style={{
            marginLeft: '5px'
          }}
        > 
          { msg } 
        </span>
      </div>
    </ErrorMsgWrap>
  )
}

export const ErrorMessageTooltip = ({ msg }: ErrorMessageProps) => {
  return (
    <ErrorMessage msg={msg} tooltip={true} />
  )
}

export default ErrorMessage