import { usePermissionsQuery } from "@timenotes/shared/src/services/permissions/permissions.hooks"
import { message } from "antd"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { TApiPermissionScope, TApiPerrmissionKey } from "services/api-client/permissions-api"

export const useGetPagePermissions = (scope: TApiPermissionScope = 'team') => {
  const [loaded, setLoaded] = useState(false)

  const permissionsQuery = usePermissionsQuery()

  return {
    permissions: permissionsQuery.data?.permissions,
    permissionsLoaded: permissionsQuery.isFetched,
  }
}

export const usePagePermissions = () => {
  const permissionsQuery = usePermissionsQuery()

  return {
    permissions: permissionsQuery.data?.permissions,
    loaded: permissionsQuery.isSuccess,
  }
}

export const useFiltersPermissions = () => {
  const { permissions, loaded } = usePagePermissions()
  if (!loaded) throw 'Permissions not loaded before usage!'

  return {
    canFilterByTeam: !!permissions?.viewAccessibleProjectsTimeLogs,
    canFilterByIsBillable: !!permissions?.setTimeLogBillable,
  }
}

export const useTimeLogPermissions = () => {
  const { permissions, loaded } = usePagePermissions()
  if (!loaded) throw 'Permissions not loaded before usage!'

  if (!loaded) throw 'Permissions not loaded before usage!'

  return {
    canEditTimeLogIsBillable: !!permissions?.setTimeLogBillable,
    canManageOtherTimeLogs: !!permissions?.setTimeLogUser,
  }
}

export const usePagePermissionsCheck = (permission: TApiPerrmissionKey | TApiPerrmissionKey[]) => {
  // Check page permissions
  const { permissions, loaded: permissionsLoaded } = usePagePermissions()
  const history = useHistory()

  const redirectIfNoPermissions = () => {
    let hasPermissions = false

    if (Array.isArray(permission)) {
      throw "Array implementation missing!"
    } else {
      // @ts-ignore
      hasPermissions = permissions[permission]
    }

    if (permissionsLoaded && !hasPermissions) {
      message.warn('You have no permissions to visit this page!')
      history.push('/timer')
    }
  }

  redirectIfNoPermissions()
}
