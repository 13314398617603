import { TApiResponse, TApiParams, TApiMembersGroup } from "./types"
import BaseApi, { getQueryString } from './base'

export type TApiProjectsGroup = {
  id: string
  membersGroup: TApiMembersGroup
}

export interface IGetProjectsGroupsResponse extends TApiResponse {
  projectsGroups: Array<TApiProjectsGroup>
}

export interface ICreateProjectsGroupResponse extends TApiResponse {
  projectsGroup: TApiProjectsGroup
}

export interface ICreateProjectsGroupParams {
  membersGroupId: string
}

class ProjectsGroupsApi extends BaseApi {
  async getProjectsGroups(projectId: string, params?: TApiParams): Promise<IGetProjectsGroupsResponse> {
    let responseParams = { 
      ...params,
    }

    if (!responseParams?.perPage) {
      responseParams.perPage = 1000
    }

    const query = getQueryString(this.paramsToSnakeCase(responseParams), true)

    let response = await fetch(`${this.rootUrl}/projects/${projectId}/projects_groups${query}`, {
      method: 'GET',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }

  async createProjectsGroup(projectId: string, params: ICreateProjectsGroupParams): Promise<ICreateProjectsGroupResponse> {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}/projects_groups`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        projects_group: this.paramsToSnakeCase(params),
      }),
    })

    return await this.getResponseJson(response)
  }

  async deleteProjectsGroup (projectId: string, projectsGroupId: string) {
    let response = await fetch(`${this.rootUrl}/projects/${projectId}/projects_groups/${projectsGroupId}`, {
      method: 'DELETE',
      headers: this.headers,
    })

    return await this.getResponseJson(response)
  }
}

export default ProjectsGroupsApi