import { FC, useState } from "react"

import _map from "lodash/map"
import _filter from "lodash/filter"

import { Button } from "components/base"
import { Popover } from "antd"
import UsersAccountSelect from "components/timenotes/users-accounts/users-account-select"
import { TApiUsersAccount } from "services/api-client/types"

interface IProps {
  filter?(usersAccounts: TApiUsersAccount[]): TApiUsersAccount[]
  disabled?: boolean
  style?: any
  width?: string
  selectProps?: React.ComponentProps<typeof UsersAccountSelect>
}

export const ProjectsMemberSelect: FC<IProps> = ({ disabled, width, style, filter, selectProps }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <Popover
      trigger={"click"}
      visible={isVisible}
      destroyTooltipOnHide={true}
      onVisibleChange={(visible) => {
        if (!visible) {
          setIsVisible(false)
        }
      }}
      placement='bottom'
      content={(
        <UsersAccountSelect 
          filter={filter}
          style={{
            width: '250px'
          }}
          open={true}
          autoFocus={true}
          {...selectProps}
       />
     )}
    >
      <Button
        disabled={disabled}
        disabledTooltip="Everyone from your workspace has access to this project, members can’t be added."
        onClick={() => setIsVisible(true) }
      >
        Add member
      </Button>
    </Popover>
  )
}

export default ProjectsMemberSelect