import { FC } from "react"
import styled from "styled-components"
import { SettingSvg } from "assets/svg/icon"
import { PinningIcon } from "components/base/icons"
import { MoreMenuAction } from "./more-menu-action"
import { Divider, Input, message, Modal } from "antd"
import useApiClient from "hooks/useApiClient"
import { useHistory } from "react-router-dom"
import { getApiErrorMessage } from "@timenotes/shared/src/services/api-client"
import { TApiProject } from "@timenotes/shared/src/services/api-client/types"
import { useFormik } from "formik"
import ErrorMessage from "components/base/error-message"
import { InputFieldWrapper } from "pages/projects/shared/components/project-form"
import { useCopyProjectModal } from "../CopyProjectModal"

interface IProps {
  project: TApiProject
  goToProjectPath: (projectId: string) => void
  onAction?(): void
}

const ActionPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const IconWrapper = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;

  &.indents {
    margin: 0 12px;
  }

  &:hover > svg > path,
  &:hover > svg > circle {
    fill: #07c5b7;
  }

  &.pinned > span > svg > path:first-child,
  &.pinned > span > svg > path:last-child {
    fill: #b9b9c4;
  }

  &.pinned-hover:hover > span > svg > path:first-child,
  &.pinned-hover:hover > span > svg > path:last-child {
    fill: #b9b9c4;
  }
`

export const ProjectsActionsPanel: FC<IProps> = ({ project, goToProjectPath, onAction }) => {
  const apiClient = useApiClient()
  const history = useHistory()

  const { id, pinned, name } = project

  const isArchived = !!project.archivedAt

  const goToProjectsSettings = () => {
    history.push(`/projects/${project.id}/settings`)
  }

  const triggerPin = () => {
    return apiClient.pinProject(project.id)
  }

  const triggerUnpin = () => {
    return apiClient.unpinProject(project.id)
  }

  const triggerArchive = () => {
    return apiClient.archiveProject(project.id)
  }

  const triggerRestore = () => {
    return apiClient.restoreProject(project.id)
  }

  const triggerDelete = () => {
    return apiClient.deleteProject(project.id)
  }

  const {
    copyProjectModal,
    setCopyProjectModalVisible,
  } = useCopyProjectModal({
    project: project,
    onSuccess: onAction ? () => {
      setCopyProjectModalVisible(false)
      message.success('Project copied successfully!')

      onAction()
    }
      : (() => {}),
  })

  const onCopy = async () => {
    setCopyProjectModalVisible(true)
  }

  const onPin = () => {
    return triggerPin().then((response) => {
      if (response.ok) {
        message.success("Project pinned successfuly!")
        if (onAction) {
          onAction()
        }
      } else {
        const errorMessage = getApiErrorMessage(response, "pinned")
        message.error(errorMessage)
      }

    return response
  })}

  const onUnpin = () => { return triggerUnpin().then((response) => {
    if (response.ok) {
      message.success("Project pin removed successfuly!")
      if (onAction) {
        onAction()
      }
    } else {
      const errorMessage = getApiErrorMessage(response, "pinned")
      message.error(errorMessage)
    }

    return response
  })}

  const onArchive = () => { return triggerArchive().then((response) => {
    if (response.ok) {
      message.success("Project archived successfuly!")
      if (onAction) {
        onAction()
      }
    } else {
      const errorMessage = getApiErrorMessage(response, "status")
      message.error(errorMessage)
    }

    return response
  })}

  const onRestore= () => { return triggerRestore().then((response) => {
    if (response.ok) {
      message.success("Project restored successfuly!")
      if (onAction) {
        onAction()
      }
    } else {
      const errorMessage = getApiErrorMessage(response, "status")
      message.error(errorMessage)
    }

    return response
  })}

  const onDelete = () => { return triggerDelete().then((response) => {
    if (response.ok) {
      message.success("Project deleted successfuly!")
      if (onAction) {
        onAction()
      }
    } else {
      const errorMessage = getApiErrorMessage(response, "base")
      message.error(errorMessage)
    }

    return response
  })}

  return (
    <ActionPanelWrapper>
      <IconWrapper
        className={pinned ? "pinned" : "pinned-hover"}
        onClick={() => (pinned ? onUnpin() : onPin())}
      >
        <PinningIcon />
      </IconWrapper>
      <IconWrapper className={"indents"} onClick={() => 
        goToProjectsSettings()
      }>
        <SettingSvg />
      </IconWrapper>
      <IconWrapper
        style={{
          paddingTop: '5px',
        }}
      >
        <MoreMenuAction
          isArchived={isArchived}
          triggerCopy={onCopy}
          triggerArchive={onArchive}
          triggerRestore={onRestore}
          triggerDelete={onDelete}
          projectName={name}
        />
     </IconWrapper>
     { copyProjectModal }
    </ActionPanelWrapper>
  )
}