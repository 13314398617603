import { Card } from "antd"

export default function DashboardNumberCard({ title, numberFormatted, description1, description2, color }: {
  title: string,
  numberFormatted: string,
  description1?: string,
  description2?: string 
  color?: string,
 }) {
 const numberColor = color || '#3A3AA3'

 return (
   <Card style={{ display: 'flex', height: '144px', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
     <h5>{ title }</h5>
     <h4 style={{ color: numberColor, fontSize: '22px' }}>{numberFormatted}</h4>

     { description1 && (
       <div>{description1 } </div>
     )}

     { description2 && (
       <div>{description2 } </div>
     )}
   </Card>
 )
}